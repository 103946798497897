<div class="logo rc2-font--family--regular">
  <recall2-icon-logo-recall [rc2IconStyles]="'rc2-icon--size--52'"></recall2-icon-logo-recall>
  ReCall 2
</div>

<h1
  class=" rc2-font--family--bold rc2-font--size--20"
  data-testid="welcome-title"
>
  {{ 'welcome-page.title' | translate }}
</h1>

<div
  class="content rc2-font--size--16"
  data-testid="welcome-content"
>
  {{ 'welcome-page.content' | translate }}
</div>

<div class="button-container rc2-mar--top--3xl">
  <create-notification-button></create-notification-button>
</div>
