import type { ActivatedRoute } from '@angular/router';

import type { DownloadButtonConfig } from '../../buttons/components/recall2-button-download/models/download-button-config.model';
import type {
  ExpandableContentDefinition,
  IColumnDefinition,
  IExpandableContentDefinitionV2,
} from '../../dynamic-content/models/dynamic-content.model';
import type { IRecall2FilterParam, Recall2FilterV2Config } from '../../overlay/models/filter.model';
import type { IRecall2Sort } from '../models/recall2-sort';
import { Recall2TableFacade } from './recall2-table-facade';

export class Recall2TableFacadeBuilder<T = unknown> extends Recall2TableFacade<T> {
  constructor(
    protected tableId: string,
    protected locale: string,
    sortInTable = false,
  ) {
    super(tableId, locale, sortInTable);
  }

  withHighlighting(
    route: ActivatedRoute,
    highlightQueryParamIdentifier = 'highlight',
    highlightingClass = 'highlighted',
  ): Recall2TableFacadeBuilder<T> {
    this.route = route;
    this.highlightQueryParamIdentifier = highlightQueryParamIdentifier;
    this.highlightingClass = highlightingClass;
    return this;
  }

  withExpansion(route: ActivatedRoute, expansionQueryParamIdentifier = 'expand'): Recall2TableFacadeBuilder<T> {
    this.route = route;
    this.expansionQueryParamIdentifier = expansionQueryParamIdentifier;
    return this;
  }

  withScrolling(scrollQueryParamIdentifier = 'scroll'): Recall2TableFacadeBuilder<T> {
    this.scrollQueryParamIdentifier = scrollQueryParamIdentifier;
    return this;
  }

  withSpinner(startAnimationOn: string[], stopAnimationOn: string[], src: string): Recall2TableFacadeBuilder<T> {
    this.startAnimationOn = startAnimationOn;
    this.stopAnimationOn = stopAnimationOn;
    this.src = src;
    return this;
  }

  withPagination(): Recall2TableFacadeBuilder<T> {
    this.paginationModel = {
      paginationSelectedPageSize: 0,
      paginationPageElements: 0,
      paginationTotalElements: 0,
      paginationTotalPages: 0,
      paginationPageNumber: 0,
      currentSelectedPage: 0,
    };
    return this;
  }

  withExpandableContendDefinition(
    expandableContentDefinition: IExpandableContentDefinitionV2 | ExpandableContentDefinition,
    rowExpandableOnClick = true,
  ): Recall2TableFacadeBuilder<T> {
    this.expandableContentDefinition = expandableContentDefinition;
    this.isRowExpandable = true;
    this.rowExpandableOnClick = rowExpandableOnClick;

    return this;
  }

  withFilter(activeFilterResults: IRecall2FilterParam[]): Recall2TableFacadeBuilder<T> {
    this.activeFilterResults = activeFilterResults;
    return this;
  }

  withSort(defaultSorting: IRecall2Sort[]): Recall2TableFacadeBuilder<T> {
    this.defaultSorting = defaultSorting;
    return this;
  }

  withColumnDefinition(columnDefinitions: IColumnDefinition[]): Recall2TableFacadeBuilder<T> {
    if (columnDefinitions) {
      this.columnDefinitions = columnDefinitions;
    }
    return this;
  }

  withMoreFilters(moreFilterConfig: Recall2FilterV2Config[]): Recall2TableFacadeBuilder<T> {
    if (moreFilterConfig) {
      this.moreFilterConfig = moreFilterConfig;
    }
    return this;
  }

  withDownloadButton(downloadButtonConfig: DownloadButtonConfig): Recall2TableFacadeBuilder<T> {
    if (downloadButtonConfig) {
      this.downloadConfig = downloadButtonConfig;
    }
    return this;
  }

  build(): Recall2TableFacadeBuilder<T> {
    if (this.highlightingClass && this.highlightQueryParamIdentifier) {
      super.initHighlighting();
    }
    if (this.expansionQueryParamIdentifier) {
      super.initExpansion();
    }
    if (this.scrollQueryParamIdentifier) {
      super.initScrolling();
    }
    if (this.expandableContentDefinition) {
      this.initExpandableContendDefinition();
    }
    if (this.paginationModel) {
      super.initPagination();
    }
    if (this.src && this.startAnimationOn && this.startAnimationOn) {
      super.initSpinner();
    }
    if (this.activeFilterResults) {
      super.initFilter();
    }
    if (this.defaultSorting) {
      super.initSort();
    }
    if (this.columnDefinitions) {
      super.initColumnDefinition();
    }
    if (this.moreFilterConfig) {
      super.initMoreFilters();
    }
    if (this.downloadConfig) {
      super.initDownloadConfig();
    }
    return this;
  }
}
