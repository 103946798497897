<div class="info-background">
  <div class="info-icon">
    <em class="icon i-info"></em>
  </div>
  <div class="unauthorized-error-message">
    {{ 'errorhandling.unauthorized-error-message' | translate }}
  </div>
  <div class="login-btn">
    <recall2-button-secondary
      (click)="reload()"
      [iconClass]="'icon-i67-reload'"
    >
      {{ 'errorhandling.unauthorized-login-btn-label' | translate }}
    </recall2-button-secondary>
  </div>
</div>
