import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-user',
  templateUrl: './recall2-icon-user.component.html',
  standalone: true,
})
export class Recall2IconUserComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i13-user');
  }
}
