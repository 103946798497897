import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import type { TooltipPosition } from '@angular/material/tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { EStatus } from '../status/model/status.model';
import { Recall2StatusComponent } from '../status/status.component';

@Component({
  selector: 'recall2-badge',
  templateUrl: './recall2-badge.component.html',
  styleUrls: ['./recall2-badge.component.scss'],
  standalone: true,
  imports: [TranslateModule, Recall2StatusComponent, MatTooltipModule, NgIf],
})
export class Recall2BadgeComponent {
  @Input() objectTypeTranslationKey: string;
  @Input() objectId: number | string;
  @Input() content: string;
  @Input() badgeToolTipPosition = 'above' as TooltipPosition;
  @Input() status: EStatus;
  @Input() isTopic? = false;
  @Input() isCampaign? = false;
}
