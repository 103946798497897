import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { UserService } from '../../rest-api/user/user.service';
import { GetUserFailedAction, GetUserSuccessAction } from './user.actions';
import type { IUserState, UserData } from './user.state';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  constructor(
    private userService: UserService,
    private store: Store<IUserState>,
  ) {}

  getUser(): Observable<UserData> {
    return this.userService.getCurrentUser$().pipe(
      tap((userData: UserData) => {
        userData
          ? this.store.dispatch(new GetUserSuccessAction(userData))
          : this.store.dispatch(
              new GetUserFailedAction(new HttpErrorResponse({ error: 'Auth error', statusText: 'GetUser failed!' })),
            );
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.dispatch(new GetUserFailedAction(error));
        return throwError(() => error);
      }),
    );
  }
}
