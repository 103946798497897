<div
  class="row rc2-mar--no rc2-justify--space-between rc2-pad--xs rc2-border rc2-border--danger rc2-color--danger rc2-font--body--m"
>
  <div class="col-md-11 rc2-pad--no rc2-pad--right--xs">
    <span class="rc2-font--bold rc2-display--block">{{'notification.attention.title' | translate}}</span>
    {{'notification.attention.description' | translate}}
  </div>
  <div
    *ngIf="showIcon"
    class="col-md-1 rc2-pad--no rc2-line--height--0 rc2-text-align--right"
  >
    <recall2-icon-warning class="rc2-font--size--30"></recall2-icon-warning>
  </div>
</div>
