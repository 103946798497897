import type { OnInit } from '@angular/core';
import { Directive, HostListener, Optional } from '@angular/core';

import type { IRecall2FilterParam } from '../../../overlay/models/filter.model';
import { TableColumnFilterComponent } from '../../components/table-column-filter/table-column-filter.component';
import { TableColumnFiltersDirective } from '../table-column-filters/table-column-filters.directive';

@Directive({
  selector: '[appTableColumnFilterHeader]',
  standalone: true,
})
export class TableColumnFilterHeaderDirective implements OnInit {
  @HostListener('click') onClick(): void {
    this.tableColumnFilterComponent.openFilter(filterChange => this.onFilterChange(filterChange));
  }

  constructor(
    @Optional() public tableColumnFilterComponent: TableColumnFilterComponent,
    @Optional() public tableColumnFiltersDirective: TableColumnFiltersDirective,
  ) {}

  ngOnInit(): void {
    this.tableColumnFiltersDirective?.registerFilterColumn(this);
  }

  onFilterChange(filterChange: IRecall2FilterParam): void {
    this.tableColumnFiltersDirective.onFilterChange(filterChange);
  }
}
