export const apiCampaignUrl = '/api-campaign-campaign';
export const apiUserManagementUserUrl = '/api-usermgmt-user';
export const apiCommitteeMeetingUrl = '/api-committee-meeting';
export const coordinationApiUrl = '/api-campaign-coordination';
export const apiVINSearchUrl = '/campaigns-coordination/vin-search';
export const apiVINNewSearchUrl = '/campaigns/vin-search';
export const apiPreliminaryAttachmentUrl = 'api-preliminary-attachment';
export const apiTopicAttachmentUrl = 'api-topic-attachment';
export const apiVINSearchCampaignsCount = '/campaigns-count';
export const apiVINSearchCampaignsNotFound = '/vins-not-found';
