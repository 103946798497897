
<recall2-icon-user class="rc2-icon--size--26"></recall2-icon-user>
<div
  data-testid="my-data-text"
  class="
    rc2-font--size--12
    rc2-font--family--bold
    rc2-text-align--center
    rc2-line--height--15
    rc2-mar--top--3xs"
>
  {{ "nav-main.my-data" | translate }}
</div>

<div class="my-data-menu rc2-font--size--14">
  <a
    recall2AppsLink
    [attr.data-testid]="'my-data-menu-profile'"
    [targetApp]="APP_NAME"
    [urlPath]="USER_PROFILE_URL"
  >
    {{ "nav-main.my-data-menu.profile" | translate }}
  </a>
  <hr>
  <a
    [attr.data-testid]="'my-data-menu-logout'"
    (click)="onLogout()"
  >
    {{ "nav-main.my-data-menu.logout" | translate }}
    <recall2-icon-close class="rc2-icon--size--20 rc2-color--black"></recall2-icon-close>
  </a>
</div>
