import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EThreeStepCheckboxStates } from '@recall2/ui/form/model';

@Pipe({
  name: 'threeStateTranslation',
  standalone: true,
})
export class ThreeStateTranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: EThreeStepCheckboxStates): string {
    if (value === EThreeStepCheckboxStates.SELECTED) {
      return this.translateService.instant('general.true');
    } else if (value === EThreeStepCheckboxStates.UNSELECTED) {
      return '-';
    }

    return this.translateService.instant('general.false');
  }
}
