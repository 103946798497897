import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { TranslateLoader } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppsService } from '../../../navigation/services/apps.service';

@Injectable({
  providedIn: 'root',
})
export class HttpTranslateLoader implements TranslateLoader {
  constructor(
    private client: HttpClient,
    private appsService: AppsService,
  ) {}

  getTranslation(lang: string): Observable<Record<string, string>> {
    const appLocales = this.fetch(`/translations/${this.appsService.getCurrentApp().id}/${lang}.json`);
    const sharedLocales = this.fetch(`/translations/shared/${lang}.json`);
    return combineLatest([appLocales, sharedLocales]).pipe(
      map(([appLocalesRes, sharedLocalesRes]) => ({ ...sharedLocalesRes, ...appLocalesRes })),
    );
  }

  private fetch(url: string): Observable<Record<string, string>> {
    return this.client.get<Record<string, string>>(url).pipe(
      catchError(err => {
        console.warn(`Error while fetching translations from ${url}:`, err);
        return of({});
      }),
    );
  }
}
