import type { AfterViewInit } from '@angular/core';
import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

import { WidthExtractor } from '../service/width-extractor.service';

@Directive({
  selector: '[recall2MeasureMenuItem]',
  standalone: true,
})
export class Recall2MeasureMenuItemDirective implements AfterViewInit {
  @Output() widthChanged = new EventEmitter<number>();

  constructor(
    private widthExtractor: WidthExtractor,
    private el: ElementRef,
  ) {}

  ngAfterViewInit(): void {
    const width = this.widthExtractor.getClientWidth(this.el.nativeElement);

    this.widthChanged.next(width);
  }
}
