import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Recall2ButtonSecondaryComponent } from '../../../buttons';

@Component({
  selector: 'recall2-concurrent-notification',
  templateUrl: './concurrent-notification.component.html',
  styleUrls: ['./concurrent-notification.component.css'],
  standalone: true,
  imports: [Recall2ButtonSecondaryComponent, TranslateModule],
})
export class ConcurrentNotificationComponent {
  errorMessage = '';
  objectType = 'topics';

  constructor(
    translationService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { errorMessage: string; objectType: string },
  ) {
    this.objectType = data.objectType || this.objectType;
    this.errorMessage =
      data && data.errorMessage
        ? data.errorMessage
        : translationService.instant('errorhandling.concurrent-edit-error-message', {
            objectType: data.objectType,
          });
  }

  reload(): void {
    window.location.reload();
  }
}
