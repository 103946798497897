import { Injectable } from '@angular/core';

import type { Icon } from '../icons/collection/icons.model';

@Injectable({
  providedIn: 'root',
})
export class SVGIconsRegistry {
  private registry = new Map<string, string>();

  public registerIcons(icons: Icon[]): void {
    for (const icon of icons) {
      this.registry.set(icon.name, icon.data);
    }
  }

  public getIcon(iconName: string): string | undefined {
    return this.registry.get(iconName);
  }
}
