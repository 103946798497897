<div
  data-testid="open-tab-condition-check"
  class="rc2-line--height--0"
  *ngIf="data[affectedPropertyName] === threeStepCheckboxStateSelected"
>
  <recall2-icon-open-tab
    id="icon-open-tab"
    class="rc2-font--size--30 rc2-icon--color--blue rc2-icon--cursor--pointer"
    recall2AppsLink
    [targetApp]="targetApp"
    [urlPath]="urlPath"
    [queryParams]="getQueryParams()"
    [openInNewTab]="true"
    *ngIf="data[propertyName]"
  >
  </recall2-icon-open-tab>
</div>
<div
  data-testid="open-tab-condition-check"
  class="rc2-line--height--0"
  *ngIf="!data[affectedPropertyName]"
>
  <recall2-icon-open-tab
    id="icon-open-tab"
    class="rc2-font--size--30 rc2-icon--color--blue rc2-icon--cursor--pointer"
    recall2AppsLink
    [targetApp]="targetApp"
    [urlPath]="linkUrlPath"
    [beforeRedirectFn]="beforeRedirectFn"
    [queryParams]="getQueryParams()"
    [openInNewTab]="true"
    *ngIf="data[propertyName]"
  >
  </recall2-icon-open-tab>
</div>
