import type { TemplateRef } from '@angular/core';
import type { Recall2FilterV2Config } from '@recall2/ui/overlay';
import { EFilterTemplates, ENumberFilterComparator } from '@recall2/ui/overlay';

import { affectedManufacturers } from './affected-manufacturers';
import { NotificationOverviewTableColumns } from './notification-overview-table-columns.enum';
import { NotificationOverviewTableFilters } from './notification-overview-table-filters.enum';
import { selectableValues } from './table-filter';

export const notificationOverviewTableFiltersConfiguration = (
  statusFilterTemplate: TemplateRef<unknown>,
): Recall2FilterV2Config[] => [
  {
    identifier: NotificationOverviewTableColumns.ID,
    translationKey: 'notifications.id',
    type: EFilterTemplates.NUMBER,
  },
  {
    identifier: NotificationOverviewTableColumns.Title,
    translationKey: 'notification.title',
    type: EFilterTemplates.TEXT,
  },
  {
    identifier: NotificationOverviewTableColumns.Reporter,
    translationKey: 'notification.reporter',
    type: EFilterTemplates.TEXT,
  },
  {
    identifier: NotificationOverviewTableColumns.Editor,
    translationKey: 'notification.activeEditor',
    type: EFilterTemplates.TEXT,
  },
  {
    identifier: NotificationOverviewTableColumns.ReceivedDate,
    translationKey: 'notifications.received',
    type: EFilterTemplates.DATE,
  },
  {
    identifier: NotificationOverviewTableColumns.DueTo,
    translationKey: 'notifications.due',
    type: EFilterTemplates.DATE,
  },
  {
    identifier: NotificationOverviewTableColumns.Status,
    translationKey: 'notifications.status',
    type: EFilterTemplates.CUSTOM_SELECT,
    itemsList: selectableValues,
    customTemplate: statusFilterTemplate,
  },
  {
    identifier: NotificationOverviewTableFilters.TopicId,
    translationKey: 'filter.label.key.linked.topic.id',
    type: EFilterTemplates.NUMBER,
    group: { key: 'description', translationKey: 'filter.label.key.header.description' },
    allowedComparators: [ENumberFilterComparator.EQUALS],
  },
  {
    identifier: NotificationOverviewTableFilters.AffectedComponent,
    translationKey: 'filter.label.key.notification.affected.component',
    type: EFilterTemplates.TEXT,
    group: { key: 'description', translationKey: 'filter.label.key.header.description' },
  },
  {
    identifier: NotificationOverviewTableFilters.PartNumber,
    translationKey: 'filter.label.key.notification.part.number',
    type: EFilterTemplates.TEXT,
    group: { key: 'description', translationKey: 'filter.label.key.header.description' },
  },
  {
    identifier: NotificationOverviewTableFilters.SupplierFault,
    translationKey: 'filter.label.key.notification.supplier.fault',
    type: EFilterTemplates.RADIO,
    group: { key: 'measure', translationKey: 'filter.label.key.measure' },
    itemsList: [
      { translationKey: 'common.filter.yes', value: 'SELECTED' },
      { translationKey: 'common.filter.no', value: 'DESELECTED' },
      { translationKey: 'common.filter.unselected', value: 'UNSELECTED' },
    ],
  },
  {
    identifier: NotificationOverviewTableFilters.Supplier,
    translationKey: 'filter.label.key.notification.supplier',
    type: EFilterTemplates.TEXT,
    group: { key: 'measure', translationKey: 'filter.label.key.measure' },
  },
  {
    identifier: NotificationOverviewTableFilters.AffectedManufacturer,
    translationKey: 'filter.label.key.notification.affected.manufacturer',
    type: EFilterTemplates.SELECT,
    itemsList: affectedManufacturers,
    group: { key: 'measure', translationKey: 'filter.label.key.measure' },
  },
  {
    identifier: NotificationOverviewTableFilters.Persons,
    translationKey: 'filter.label.key.notification.persons',
    type: EFilterTemplates.TEXT,
    group: { key: 'persons', translationKey: 'filter.label.key.persons' },
  },
];
