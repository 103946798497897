import type { IAutoSuggestConfiguration } from '@recall2/ui/auto-suggest';
import type { EThreeStepCheckboxStates } from '@recall2/ui/form/model';

import type { IUser } from './user.model';

export interface InvolvedUser extends IUser {
  id?: number;
  role?: string;
  subjectArea?: string;
  attended?: EThreeStepCheckboxStates;
  isEditable?: boolean;
  corporation?: string;
  entitledToVote?: boolean;
  committeeTranscriber?: boolean;
  roles?: string[];
  isDeleting?: boolean;
  syncAgendaItem?: boolean;
}

/**
 * @deprecated
 */
export interface IEditableInvolvedUser extends InvolvedUser {
  isEditable?: boolean;
}

export interface IInvolvedUserAutoSuggestProperties {
  configuration?: IAutoSuggestConfiguration;
}

export enum EInvolvedUserTableType {
  DEFAULT = 'default',
  EXTENDED = 'extended',
}
