import type { NotificationActions } from './notification.actions';
import { ENotificationActions } from './notification.actions';
import type { INotificationState } from './notification.state';
import { initialNotificationState } from './notification.state';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export const notificationReducer = (state: INotificationState, action: NotificationActions): INotificationState => {
  if (state === undefined) {
    state = initialNotificationState;
  }

  switch (action.type) {
    case ENotificationActions.GetPageableDataForOverviewTableSuccess:
      return {
        ...state,
        notificationList: {
          ...state.notificationList,
          notificationPage: action.notificationPage,
        },
      };
    case ENotificationActions.SaveCurrentNotification:
      return {
        ...state,
        currentNotification: null,
      };
    case ENotificationActions.UpdateCurrentNotification:
    case ENotificationActions.SaveCurrentNotificationSuccess:
      return {
        ...state,
        currentNotification: action.notification,
      };
    default:
      return state;
  }
};
