import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, HostListener, Input } from '@angular/core';

import { Recall2IconArrowRightComponent } from '../../icons/recall2-icon-arrow-right/recall2-icon-arrow-right.component';

@Component({
  selector: 'recall2-slider-area',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, Recall2IconArrowRightComponent],
})
export class ReCall2SliderComponent {
  @Input() heading: string;
  isOpen = false;
  show = false;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  onClick(target: EventTarget): void {
    if (this.isOpen && !this.clickedInside(target)) {
      this.close();
    }
  }

  open(): void {
    setTimeout(() => {
      this.show = true;
      this.isOpen = true;
    });
  }

  close(): void {
    this.show = false;
    this.isOpen = false;
  }

  toggle(): void {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  clickedInside(target: EventTarget): boolean {
    const sliderNode = this.elementRef.nativeElement.querySelector('.slider-content');
    return sliderNode === target || sliderNode.contains(target);
  }
}
