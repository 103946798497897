import { NgClass } from '@angular/common';
import type { AfterViewInit, OnDestroy } from '@angular/core';
import { ChangeDetectorRef, Component, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MarkEmptyPipe } from '../../../../pipes';
import type { WithTableService } from '../../../../tables/models/recall2-table';
import { ETableType } from '../../../../tables/models/recall2-table';
import type { Recall2TableService } from '../../../../tables/services/recall2-table.service';
import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';

/**
 * Can be used for all text fields. InCase of single line text with ellipsis required, give normal-ellipsis as additionalCssClass
 */
@Component({
  selector: 'text-cell',
  templateUrl: './text-cell.component.html',
  standalone: true,
  imports: [NgClass, MarkEmptyPipe],
})
export class TextCellComponent
  extends AbstractGenericCellViewWithData
  implements ITextCellBinding, AfterViewInit, WithTableService, OnDestroy
{
  propertyName: string;
  additionalCssClasses?: string[];
  type: ETableType;
  tableTypes = ETableType;
  tableService: Recall2TableService;
  private destroyed$ = new Subject<void>();

  constructor(
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.ngZone.runOutsideAngular(() => {
      this.tableService
        .getTableType()
        .pipe(
          takeUntil(this.destroyed$),
          filter(response => !!response),
        )
        .subscribe((type: ETableType) => {
          this.type = type;
          this.cdr.detectChanges();
        });
    });
  }

  setTableService(tableService: Recall2TableService): void {
    this.tableService = tableService;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}

export class TextCellBinding implements ITextCellBinding {
  constructor(
    public propertyName: string,
    public additionalCssClasses?: string[],
  ) {}
}

interface ITextCellBinding {
  propertyName: string;
  additionalCssClasses?: string[];
}
