import { UpperCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';

@Component({
  selector: 'id-cell',
  templateUrl: './id-cell.component.html',
  standalone: true,
  imports: [TranslateModule, UpperCasePipe],
})
export class IdCellComponent extends AbstractGenericCellViewWithData {
  propertyName = 'id';
  translationKey: string;
}

export class IdCellBinding implements IDCellBinding {
  constructor(
    public translationKey: string,
    public propertyName = 'id',
  ) {}
}

interface IDCellBinding {
  translationKey: string;
  propertyName?: string;
}
