<div class="table-wrapper">
  <div class="rc2-display--flex" [ngClass]="{ 'rc2-mar--bottom--s': showMoreFilters || hasActiveFilters }">
    <ng-content select="[download-button]"></ng-content>
    <app-more-filters *ngIf="showMoreFilters" [filtersConfig]="filterConfig" [tableId]="tableKey"></app-more-filters>
    <app-active-filters [tableId]="tableKey" [filtersConfig]="filterConfig"></app-active-filters>
  </div>

  <table
    [attr.data-cy]="tableKey + '_table'"
    multiTemplateDataRows
    mat-table
    [dataSource]="data"
    appTableSort
    appTableColumnFilters
    [sortedColumn]="sorting$ | async"
    [activeFilters$]="activeFilters$"
    [filterConfigurationList]="filterConfig"
    (sortChange)="onSortChange($event)"
    (filterChange)="onFilterChange($event)"
  >
    <caption class="rc2-display--none">
      Table component with filtering, sorting and pagination. Also allows expandable rows and custom templates
    </caption>
    <ng-container *ngFor="let column of tableConfig; trackBy: trackByFn" [matColumnDef]="column.id">
      <th
        mat-header-cell
        *matHeaderCellDef
        [attr.data-cy]="tableKey + '_' + column.id + '_th_column'"
        [ngClass]="column.columnSize"
      >
        <ng-container *ngIf="column.customHeader">
          <ng-container *ngTemplateOutlet="column.customHeader; context: { $implicit: column, data: data }">
          </ng-container>
        </ng-container>
        <app-table-header
          *ngIf="!column.customHeader"
          [column]="column.id"
          [isSortVisible]="data?.length > 1 && column.isSortable"
          [isFilterVisible]="column.isFilterable"
        >
          {{ column.headerTranslationKey | translate }}
        </app-table-header>
      </th>

      <td
        mat-cell
        *matCellDef="let element; let i = dataIndex"
        [attr.data-cy]="tableKey + '_' + column.id + '_td_column'"
        [ngClass]="column.columnSize"
        matTooltipPosition="above"
        matTooltipClass="rc2-tooltip"
        [matTooltip]="column.customTemplate ? null : element[column.id]"
      >
        <div
          *ngIf="column.id === 'expand' && (expandConditionFn | functionToBoolean: element)"
          mat-icon-button
          aria-label="expand row"
          (click)="toggleRow(element, $event)"
          class="rc2-display--flex rc2-justify--flex-end"
        >
          <recall2-svg-icon
            *ngIf="expandedElement === element"
            name="arrow-up"
            size="medium"
            class="rc2-icon--color--primary rc2-cursor--pointer"
          ></recall2-svg-icon>
          <recall2-svg-icon
            *ngIf="expandedElement !== element"
            name="arrow-down"
            class="rc2-icon--color--primary rc2-cursor--pointer"
            size="medium"
          ></recall2-svg-icon>
        </div>
        <ng-container
          *ngTemplateOutlet="
            column.customTemplate ?? defaultColumn;
            context: { $implicit: element | nestedProperty: column.id, row: element, index: i }
          "
        >
        </ng-container>
      </td>
      <td
        mat-footer-cell
        *matFooterCellDef
        [attr.data-cy]="tableKey + '_' + column.id + '_footer_td_column'"
        [ngClass]="column.columnSize"
      >
        <ng-container *ngTemplateOutlet="column.footerTemplate"> </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div
          *ngIf="element === expandedElement"
          class="element-detail"
          [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'"
        >
          <ng-container *ngTemplateOutlet="expandedTemplate; context: { $implicit: element }"> </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns" [ngClass]="{ 'size-s': size === 'small' }"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: displayedColumns"
      class="element-row"
      [ngClass]="{ 'size-s': size === 'small' }"
      [class.expanded-row]="expandedElement === element"
      (click)="toggleRow(element, $event)"
    ></tr>
    <ng-container *ngIf="expandedTemplate">
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
    </ng-container>

    <ng-container *ngIf="hasFooter">
      <tr mat-footer-row *matFooterRowDef="displayedColumns" [ngClass]="{ 'size-s': size === 'small', 'hide-footer': !data?.length }"></tr>
    </ng-container>

    <tr
      data-cy="table-nodata"
      class="empty-data--filters"
      [class]="{
        'empty-data': !hasActiveFilters,
        'empty-data--filters': hasActiveFilters,
        'size-s': size === 'small'
      }"
      *matNoDataRow
    >
      <ng-container *ngIf="hasActiveFilters; then emptyDataFilters; else emptyData"> </ng-container>
    </tr>
  </table>

  <app-overview-table-paginator
    *ngIf="isPageable && size !== 'small'"
    data-cy="table-paginator"
    [length]="tableService.totalElements$ | async"
    [pageIndex]="tableService.page"
    [totalPages]="tableService.totalPages$ | async"
    [pageSize]="tableService.pageSize$ | async"
    [arePageButtonsDisabled]="isLoading"
    [showPageSize]="showPageSize"
    (elementsPerPage)="onPageSizeChange($event)"
    (page)="onPageChange($event)"
  ></app-overview-table-paginator>
</div>

<ng-template #defaultColumn let-item>{{ item }} </ng-template>

<ng-template #emptyData>
  <td class="rc2-font--body--m">
    {{ emptyDataTranslationKey | translate }}
  </td>
</ng-template>

<ng-template #emptyDataFilters>
  <td class="rc2-font--body--m">
    <div class="row">
      <div class="col">
        <h6 class="rc2-font--size--16 rc2-font--family--bold">
          {{ emptyDataFilterTranslationKey | translate }}
        </h6>
        <p class="rc2-mar--top--xxs rc2-font--size--14">{{ emptyDataFilterHintTranslationKey | translate }}</p>
      </div>
    </div>
  </td>
</ng-template>
