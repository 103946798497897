import { Injectable } from '@angular/core';
import type { NavigationExtras } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { ENotificationSendAction } from '../models/notification/send-action.enum';
import { EFormType } from './../models/form/formType';
import { baseRouteNotification, routeCreate } from './notification-routes.constants';
import { RouterHistory } from './router-history/router-history';

@Injectable({ providedIn: 'root' })
export class NotificationRoutingService {
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
  ) {}

  public navigateToOverviewAndHighlight(notificationId: number): Promise<boolean> {
    return this.router.navigate([baseRouteNotification], {
      relativeTo: this.activeRoute,
      queryParams: {
        highlight: notificationId,
        scroll: notificationId,
      },
      queryParamsHandling: 'merge',
    });
  }

  public navigateToCreateForm(): Promise<boolean> {
    RouterHistory.setBackJumpAddress(this.activeRoute.snapshot.url);

    return this.router.navigate([baseRouteNotification, routeCreate, EFormType.description], {
      queryParams: {
        openNotification: null,
        highlight: null,
        scroll: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  public navigateToEditForm(notificationId: number): Promise<boolean> {
    RouterHistory.setBackJumpAddress(this.activeRoute.snapshot.url);

    return this.router.navigate([baseRouteNotification, notificationId, EFormType.description], {});
  }
  public navigateToBaseRouteNotification(notificationId?: number, expand = false): Promise<boolean> {
    const queryParams = notificationId ? { scroll: notificationId, highlight: notificationId } : {};
    if (expand) {
      queryParams['expand'] = notificationId;
    }
    return this.router.navigate([baseRouteNotification], { queryParams });
  }

  public navigateToSummary(notificationId: number): Promise<boolean> {
    return this.router.navigate([baseRouteNotification, notificationId, EFormType.summary]);
  }

  public navigateToForwardToReviewerForm(notificationId: number): Promise<boolean> {
    RouterHistory.setPrecedingAddress(this.router.url);

    return this.router.navigate([baseRouteNotification, notificationId, ENotificationSendAction.FORWARD]);
  }

  public navigateToForwardToManufacturerForm(notificationId: number): void {
    RouterHistory.setPrecedingAddress(this.router.url);

    this.router.navigate([baseRouteNotification, notificationId, ENotificationSendAction.MANUFACTURER]);
  }

  public navigateToReworkForm(notificationId: number): Promise<boolean> {
    return this.router.navigate([baseRouteNotification, notificationId, ENotificationSendAction.REWORK]);
  }

  public navigateToReworkAPSForm(notificationId: number): Promise<boolean> {
    return this.router.navigate([baseRouteNotification, notificationId, ENotificationSendAction.REWORK_APS]);
  }

  public navigateToCreateTopic(notificationId: number): void {
    RouterHistory.setBackJumpAddress(this.activeRoute.snapshot.url);

    window.location.href = `${environment.jumpAddress.topic}/topics/create?notificationId=${notificationId}`;
  }

  public navigateToLinkTopic(notificationId: number): void {
    RouterHistory.setBackJumpAddress(this.activeRoute.snapshot.url);

    window.location.href = `${environment.jumpAddress.topic}/topics/link?notificationId=${notificationId}`;
  }

  public navigateToTransfer(notificationId: number): Promise<boolean> {
    return this.router.navigate([baseRouteNotification, notificationId, ENotificationSendAction.TRANSFER]);
  }

  public navigateToPrecedingAddress(notificationId?: number): Promise<boolean> {
    let precedingAddress = RouterHistory.popPrecedingAddress();

    if (!precedingAddress) {
      precedingAddress = baseRouteNotification;
    }

    let navigationExtras: NavigationExtras = {};
    if (notificationId) {
      navigationExtras = {
        queryParams: { scroll: notificationId, highlight: notificationId },
        queryParamsHandling: 'merge',
      };
    }

    return this.router.navigateByUrl(precedingAddress, navigationExtras);
  }

  public isCreteNotification(): boolean {
    return this.router.url.includes('/notifications/create');
  }
}
