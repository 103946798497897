import { FormControl } from '@angular/forms';
import type { InputTextProperty } from '@recall2/ui/form/model';

import type { IEditableInputTableTranslationConfig } from '../editable-input-table/i-editable-input-table-translation-config';
import { VehicleFields } from './../model/vehicleFields';

export const vehiclesAffectedFieldProperty: InputTextProperty = {
  name: VehicleFields.VehiclesAffected,
  required: false,
  hasTooltip: true,
  control: new FormControl(''),
  htmlValidators: { maxLength: 1024 },
  hasTitle: true,
  translationKey: 'notifications.form.vehicles.carLimitation',
};

export const affectedVehiclesCountFieldProperty: InputTextProperty = {
  name: VehicleFields.AffectedVehiclesCount,
  required: false,
  hasTooltip: true,
  control: new FormControl(''),
  htmlValidators: { maxLength: 128 },
  hasTitle: true,
  translationKey: 'notifications.form.vehicles.affectedCount',
};

export const marketsAndRegionsFieldProperty: InputTextProperty = {
  name: VehicleFields.MarketsAndRegions,
  required: false,
  hasTooltip: true,
  control: new FormControl(''),
  htmlValidators: { maxLength: 128 },
  hasTitle: true,
  translationKey: 'notifications.form.vehicles.markets',
};

export const commentVehiclesFieldProperty: InputTextProperty = {
  name: VehicleFields.CommentVehicles,
  required: false,
  hasTooltip: true,
  control: new FormControl(''),
  htmlValidators: { maxLength: 2048 },
  hasTitle: true,
  translationKey: 'notifications.form.vehicles.commentVehicles',
};

export const tableTranslationsProductionPeriod: IEditableInputTableTranslationConfig = {
  firstColumnInputDefaultTextTranslation: 'notifications.form.vehicles.productionPeriodFrom',
  firstColumnTitle: 'notifications.form.vehicles.productionPeriodFrom',
  secondColumnInputDefaultTextTranslation: 'notifications.form.vehicles.productionPeriodTo',
  secondColumnTitle: 'notifications.form.vehicles.productionPeriodTo',
  titleTranslationKey: 'notifications.form.vehicles.productionPeriod',
  inputLimit: 64,
};

export const tableTranslationsVehicleNumberRange: IEditableInputTableTranslationConfig = {
  firstColumnInputDefaultTextTranslation: 'notifications.form.vehicles.vin.firstVin',
  firstColumnTitle: 'notifications.form.vehicles.vin.firstVin',
  secondColumnInputDefaultTextTranslation: 'notifications.form.vehicles.vin.lastVin',
  secondColumnTitle: 'notifications.form.vehicles.vin.lastVin',
  titleTranslationKey: 'notifications.form.vehicles.vin',
  inputLimit: 17,
};
