import { NgIf } from '@angular/common';
import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { Recall2SelectComponent } from '../../../../form/components/recall2-select';
import type { SelectProperty } from '../../../../form/model';
import { SelectOption } from '../../../../form/model';
import type { IRecall2FilterSelectableValue, IRecall2FilterUserRolesParam } from '../../../models/filter.model';
import {
  EFilterTemplates,
  EUserRolesFilterComparator,
  IRecall2FilterUserRolesConfig,
} from '../../../models/filter.model';

const MANUFACTURER_LEVEL = 'manufacturer';
const ACTIVE_ROLES = 'active_roles';

@Component({
  selector: 'recall2-filter-user-roles',
  templateUrl: './recall2-filter-user-roles.component.html',
  styleUrls: ['./recall2-filter-user-roles.component.scss'],
  standalone: true,
  imports: [Recall2SelectComponent, NgIf],
})
export class Recall2FilterUserRolesComponent implements OnChanges {
  @Input() filterConfig: IRecall2FilterUserRolesConfig;
  @Output() filterChanged = new EventEmitter<IRecall2FilterUserRolesParam>();

  levelProperty: SelectProperty;
  manufacturerProperty: SelectProperty;
  roleProperty: SelectProperty;
  roleStatusProperty: SelectProperty;

  showManufacturer = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.filterConfig && changes.filterConfig.currentValue) {
      this.initProps();
    }
  }

  onLevelChange(level: string): void {
    if (level === MANUFACTURER_LEVEL) {
      this.showManufacturer = true;
    } else {
      this.showManufacturer = false;
      this.manufacturerProperty.control.reset();
    }

    this.updateFilterValue();
  }

  onSelectChange(): void {
    this.updateFilterValue();
  }

  private initProps(): void {
    this.levelProperty = this.buildSelectProperty(
      'user-roles-level',
      'recall2-filter.level',
      this.mapSelectableList(this.filterConfig.levels),
    );
    this.manufacturerProperty = this.buildSelectProperty(
      'user-roles-manufacturer',
      'recall2-filter.manufacturer',
      this.mapSelectableList(this.filterConfig.manufacturers),
    );
    this.roleProperty = this.buildSelectProperty(
      'user-roles-roles',
      'recall2-filter.roles',
      this.mapSelectableList(this.filterConfig.roles),
    );
    this.roleStatusProperty = this.buildSelectProperty(
      'user-roles-status',
      'recall2-filter.status',
      this.mapSelectableList(this.filterConfig.statusRoles),
    );
  }

  private buildSelectProperty(name: string, translationKey: string, optionsList: SelectOption[]): SelectProperty {
    return {
      name,
      required: false,
      translationKey,
      control: new FormControl(),
      hasTooltip: false,
      optionsList,
    } as SelectProperty;
  }

  private mapSelectableList(selectableList: IRecall2FilterSelectableValue[]): SelectOption[] {
    return selectableList.map(item => new SelectOption(item.translationKey, item.value));
  }

  private updateFilterValue(): void {
    const value = this.getFilterValue();
    const filterParam = value ? this.buildFilterParam(value) : null;
    this.filterChanged.emit(filterParam);
  }

  private getFilterValue(): string | null {
    const level = this.levelProperty.control.value;
    const role = this.roleProperty.control.value;
    const status = this.roleStatusProperty.control.value;
    const manufacturer = this.manufacturerProperty.control.value;

    if (level && role && status && (level !== MANUFACTURER_LEVEL || manufacturer)) {
      const manufacturerFormatted = manufacturer ?? 'null';
      return `${level}:${manufacturerFormatted}:${role}`;
    }

    return null;
  }

  private buildFilterParam(value: string): IRecall2FilterUserRolesParam {
    const status = this.roleStatusProperty.control.value;
    return {
      identifier: status,
      translationKey:
        status === ACTIVE_ROLES ? 'recall2-filter.user-roles-active-roles' : 'recall2-filter.user-roles-inactive-roles',
      type: EFilterTemplates.USER_ROLES,
      comparator: EUserRolesFilterComparator.EQUALS,
      value: [value],
    };
  }
}
