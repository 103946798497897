<span
  class="topic-id-condition-check"
  *ngIf="data.affected === threeStepCheckboxStateSelected"
>
  <span
    class="manufacturer-topic-id rc2-font--body--m rc2-pad--right--l rc2-color--grey-900"
    *ngIf="data[propertyName]"
  >
    {{ data[propertyName] }}
  </span>
  <span
    class="none-manufacturer-topic-id rc2-font--body--m rc2-pad--right--l rc2-color--grey-900"
    id="placeholder"
    *ngIf="!data[propertyName]"
  >
    -
  </span>
</span>
