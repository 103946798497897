import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'markEmpty',
  standalone: true,
})
export class MarkEmptyPipe implements PipeTransform {
  transform(input: string | string[] | any[] | number) {
    if (!input) {
      return '-';
    }
    input = input + '';
    if (input.trim().length === 0 || input === '0') {
      return '-';
    }
    return input;
  }
}
