import { AsyncPipe } from '@angular/common';
import { Component, Input } from '@angular/core';

import type { ObjectEvent } from '../../models/object-event';
import { EventDescriptionPipe } from '../../pipes/event-description.pipe';
import { EventIconComponent } from '../event-icon/event-icon.component';

@Component({
  selector: 'recall2-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  standalone: true,
  imports: [EventDescriptionPipe, EventIconComponent, AsyncPipe],
})
export class DescriptionComponent {
  @Input()
  item: Pick<ObjectEvent, 'paramsJson' | 'type'>;
}
