import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2ButtonSecondaryComponent } from '../../../buttons';

@Component({
  selector: 'recall2-unauthorized-error-modal',
  templateUrl: './unauthorized-error-modal.component.html',
  styleUrls: ['./unauthorized-error-modal.component.scss'],
  standalone: true,
  imports: [TranslateModule, Recall2ButtonSecondaryComponent],
})
export class UnauthorizedErrorModalComponent implements OnInit {
  ngOnInit(): void {
    setTimeout(() => this.reload(), 10_000);
  }

  reload(): void {
    window.location.reload();
  }
}
