<div class="rc2-pad--top--xs"></div>
<recall2-attachment
  [files]="files$ | async"
  [loading]="uploadInProgress$ | async"
  (newFiles)="onNewFiles($event)"
  (delete)="onDelete($event)"
  (download)="onDownload($event)"
  (downloadAll)="onDownloadAll()"
>
</recall2-attachment>
