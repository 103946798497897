<div class="comment">
  <recall2-icon-user
    *ngIf="!isSystemUser"
    [rc2IconStyles]="'rc2-icon--size--30'"
    class="user-icon"
    [ngClass]="{
        'user-icon-self': userType === 'CURRENT',
        'user-icon-other': userType === 'OTHER',
        'user-icon-system': userType === 'SYSTEM'
        }"
  >
  </recall2-icon-user>
  <recall2-icon-logo-recall
    *ngIf="isSystemUser"
    [rc2IconStyles]="'rc2-icon--size--30'"
    class="user-icon user-icon-system"
  >
  </recall2-icon-logo-recall>
  <div
    class="speech-bubble"
    [ngClass]="{
        'speech-bubble-self': userType === 'CURRENT',
        'speech-bubble-other': userType === 'OTHER',
        'speech-bubble-system': userType === 'SYSTEM',
        'rc2-font--size--14': true
        }"
  >
    <span
      *ngIf="isSystemUser"
      [innerHTML]="comment | eventDescription: params | async"
    ></span>
    <span
      *ngIf="!isSystemUser"
      [innerHTML]="comment"
    ></span>
  </div>
</div>
