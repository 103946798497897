import type { EnvironmentProviders } from '@angular/core';
import { makeEnvironmentProviders } from '@angular/core';

import { APP_BASE_URL, FILE_INFORMATION_URL } from './services/injection-tokens';

export type ProvideRecall2Attachment = {
  baseUrl: string;
  fileInformationUrl?: string;
};

export function provideRecall2Attachment({
  baseUrl,
  fileInformationUrl = null,
}: ProvideRecall2Attachment): EnvironmentProviders[] {
  return [
    makeEnvironmentProviders([
      {
        provide: APP_BASE_URL,
        useValue: baseUrl,
      },
      {
        provide: FILE_INFORMATION_URL,
        useValue: fileInformationUrl,
      },
    ]),
  ];
}
