<form [formGroup]="brandForm">

  <div>
    <div class="row mar-no">
      <div class="col-md-4 pad-left-erase">
        <div
          *ngIf="!updateAssignedList"
          class="label mar-small expanded h6"
          [ngClass]="{'rc2-color--danger': submitted && !selection.selected.length}"
        >
          {{ 'notifications.form.measure.affectedBrands.search.label' | translate }}*
        </div>
        <div
          *ngIf="updateAssignedList"
          class="label mar-small expanded h6"
          [ngClass]="{'rc2-color--danger': submitted && !selection.selected.length}"
        >
          {{ 'notifications.formManufacturerSelector.forwardManufacturer.search.label' | translate }}*
        </div>
      </div>
      <div class="col-md-3"></div>
      <div class="col-md-5 wrapper">
        <label for="search">
          <input
            placeholder="{{ 'notifications.form.measure.affectedBrands.search.placeholder' | translate }}"
            id="search"
            type="text"
            (keyup)="onSearch()"
            formControlName="searchedBrand"
          />
          <span class="icon i-filter i-filter-position"></span>
        </label>
      </div>
    </div>

    <!--  ---------------------------------------------------Table -------------------------------------------- -->
    <div class="row mar-no">
      <div
        class="table expanded is-elevate"
        [ngClass]="{
          'rc2-border rc2-border--danger': submitted && !selection.selected.length
        }"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          class="table__body mat-elevation-z8"
        >
          <caption></caption>
          <!-- checkbox column -->
          <ng-container matColumnDef="{{ displayedColumns[0] }}">
            <th
              *matHeaderCellDef
              id="selectAllAffectedManufacturers"
            >
              <mat-checkbox
                *ngIf="allowMultipleSelections"
                [checked]="selection.hasValue() && isAllSelected()"
                (change)="$event ? masterToggle() : null"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td
              *matCellDef="let row"
              mat-cell
            >
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="selection.toggle(row); updateBrandManufacturerList()"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- brand column -->
          <ng-container matColumnDef="{{ displayedColumns[1] }}">
            <th
              *matHeaderCellDef
              mat-sort-header
              id="affectedBrand"
            >
              <span class="icon i-menu-drop-down i-menu-drop-down-small"></span>
              {{ 'notifications.form.measure.affectedBrands.listHeader.brand' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              <recall2-brand-logo
                rc2IconStyles="rc2-icon--size--30"
                [brandId]="element.brand.id"
              ></recall2-brand-logo>
              {{ element.brand.name }}
            </td>
          </ng-container>

          <!-- manufacturer column -->
          <ng-container matColumnDef="{{ displayedColumns[2] }}">
            <th
              *matHeaderCellDef
              mat-sort-header
              id="affectedManufacturer"
            >
              <span class="icon i-menu-drop-down i-menu-drop-down-small"></span>
              {{ 'notifications.form.measure.affectedBrands.listHeader.manufacturer' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let element"
            >
              {{ element.manufacturer.name }}
            </td>
          </ng-container>

          <!-- header column -->
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            [ngClass]="{'pre-selected-brand': row.preSelected}"
          ></tr>
        </table>

        <!-- MAT TABLE -->
      </div>
      <div
        *ngIf="submitted && !selection.selected.length"
        class="invalid-feedback"
      >
        <span class="icon i-notice-important"></span>{{ 'notifications.form.emptyField.message' | translate }}
      </div>


      <!-- end of table -->
    </div>
  </div>
</form>
