import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'recall2-button-base',
  template: `<div style="color:darkred; font-size:16px;">
    This is just a <b>BaseButton</b>. Use: <br />
    <em style="font-size: 10px; font-weight: bold"> < recall-button-[primary|secondary|tertiary] > </em>
  </div>`,
  styleUrls: ['./recall2-button-base.component.scss'],
  standalone: true,
})
export class Recall2ButtonBaseComponent {
  @Input() iconClass: string;
  @Input() forceAnimation = false;
  @Input() rc2ButtonStyles: string;
  @Input() isDisabled = false;
  @Input() isAnimating = false;

  @HostBinding('class.not-allowed-cursor')
  get notAllowedCursorClass(): boolean {
    return this.isDisabled;
  }

  onClick(event: Event): void {
    if (this.isDisabled || this.forceAnimation) {
      event.stopPropagation();
    }
  }
}
