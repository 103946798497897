<div class="rc2-page-with-slider-wrapper rc2-overview-wrapper">
  <recall2-slider-area
    #slider
    [heading]="sliderTitleKey() | translate"
  >
    <div
      outer-page
      class="slider-outer-page rc2-pad--top--m rc2-pad--left--l rc2-pad--right--l"
    >
      <div class="row mar-no">
        <div class="col-md-7 rc2-pad--left--0 rc2-pad--top--xxs rc2-pad--bottom--xl">
          <h2>
            {{ 'notifications.form.header.create-notification' | translate }}
          </h2>
        </div>
        <div class="col-md-5 rc2-pad--right--0 header-right-side rc2-pad--bottom--xl">
          <ng-container *ngIf="currentNotification?.id">
            <recall2-badge
              [objectId]="currentNotification.id"
              [objectTypeTranslationKey]="'recall2-badge.header.notification'"
              [content]="currentNotification.title"
              [status]="$any(currentNotification.status)"
            >
              <recall2-icon-objekt-meldung [rc2IconStyles]="'rc2-icon--size--24 rc2-icon--color--red'">
              </recall2-icon-objekt-meldung>
            </recall2-badge>

            <recall2-icon-comment
              class="rc2-icon--cursor--pointer rc2-pad--left--xl"
              (click)="toggleSlider(displayedComponent.History)"
              [rc2IconStyles]="'rc2-icon--size--30'"
            ></recall2-icon-comment>
          </ng-container>

          <recall2-icon-attachment
            class="rc2-icon--cursor--pointer rc2-pad--left--xl"
            (click)="toggleSlider(displayedComponent.Attachment)"
            [rc2IconStyles]="'rc2-icon--size--30'"
          ></recall2-icon-attachment>
        </div>
      </div>
      <div class="row mar-no rc2-pad--bottom--xl">
        <div class="col-md-12 pad-no">
          <recall2-navigation-stepper [steps]="currentNavigationSteps$ | async"></recall2-navigation-stepper>
        </div>
      </div>
      <router-outlet *ngIf="currentNotification"></router-outlet>
      <recall2-floating-bar id="notification-form-floating-bar">
        <div class="action-bar">
          <recall2-button-tertiary
            *ngIf="hasNextFormPage()"
            id="notification-button-next-page"
            class="action-bar__button--left"
            (click)="jumpToNextPage()"
            [iconClass]="'icon-i30-forward'"
          >
            {{ 'notifications.form.category.' + getNextFormPage() | translate }}
          </recall2-button-tertiary>
          <recall2-button-secondary
            *ngIf="isSummaryActive() && notificationCanBeAction(notificationStatus.TO_REVISE_REVIEWER) && !statusContainRevisionGs"
            id="notification-button-discard"
            class="action-bar__button--left"
            [iconClass]="'icon-i62-incorrect rc2-font--size--16'"
            (click)="onDiscardNotification()"
            [forceAnimation]="isSending"
          >
            {{ 'notifications.form.button.discard' | translate }}
          </recall2-button-secondary>
          <recall2-button-secondary
            *ngIf="isSummaryActive() && notificationCanBeAction(notificationStatus.DRAFT)"
            id="notification-button-delete"
            class="action-bar__button--left"
            [iconClass]="'icon-i56-delete rc2-font--size--16'"
            [forceAnimation]="isSending"
            (click)="onDeleteNotification()"
          >
            {{ 'notifications.form.button.delete' | translate }}
          </recall2-button-secondary>

          <recall2-button-secondary
            *ngIf="isSummaryActive() && !notificationCanBeAction(notificationStatus.DISCARDED)"
            id="notification-button-send"
            class="action-bar__button"
            [iconClass]="'icon-i46-mail-summary rc2-font--size--16'"
            [forceAnimation]="isSending"
            (click)="onSendNotificationToReview()"
          >
            {{ 'notifications.form.button.send' | translate }}
          </recall2-button-secondary>

          <recall2-button-secondary
            *ngIf="isSummaryActive() && notificationCanBeAction(notificationStatus.DISCARDED)"
            id="notification-button-send"
            class="action-bar__button"
            [iconClass]="'icon-i102-return rc2-font--size--16'"
            [forceAnimation]="isSending"
            (click)="onReactivateNotification()"
          >
            {{ 'notifications.form.button.reactivate' | translate }}
          </recall2-button-secondary>

          <recall2-button-tertiary
            id="notification-button-close"
            class="action-bar__button"
            [iconClass]="'icon-i90-unselect'"
            (click)="onCancel($event)"
          >
            {{ 'notifications.form.button.close' | translate }}
          </recall2-button-tertiary>

          <recall2-button-primary
            id="notification-button-save"
            class="action-bar__button"
            [iconClass]="'icon-i66-save'"
            (click)="saveNotification()"
            [forceAnimation]="isSaving"
          >
            {{ 'notifications.form.button.save' | translate }}
          </recall2-button-primary>
        </div>
      </recall2-floating-bar>
    </div>
    <slider-content
      slider-content
      [objectId]="currentNotification?.id"
      [display]="displayedSliderComponent"
    >
    </slider-content>
  </recall2-slider-area>
</div>
