import { NgClass } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';

import { Recall2AccordionToggleComponent } from '../accordion-toggle/recall2-accordion-toggle.component';
import type { ReCall2AccordionProperties } from '../model/recall2-accordion.properties';
import { AccordionClassification } from '../model/recall2-accordion-classification';
import { AccordionSettings } from '../model/recall2-accordion-settings';
import { AccordionType } from '../model/recall2-accordion-type';

@Component({
  selector: 'recall2-accordion-base',
  templateUrl: './recall2-accordion-base.component.html',
  styleUrls: ['./recall2-accordion-base.component.scss'],
  standalone: true,
  imports: [NgClass, MatExpansionModule, Recall2AccordionToggleComponent],
})
export class Recall2AccordionBaseComponent extends AccordionSettings implements OnInit {
  @ViewChild('accordion')
  accordion: MatExpansionPanel;

  accordionType = AccordionType.H3;
  accordionBaseProperties: ReCall2AccordionProperties;
  accordionClassification: AccordionClassification = AccordionClassification.Primary;

  ngOnInit(): void {
    this.accordionClassification = this.getClassificationByType(this.accordionType);
  }

  expand(): void {
    this.accordion.open();
  }

  onToggle(matExpansionPanel: MatExpansionPanel): void {
    if (matExpansionPanel.expanded) {
      this.close.emit();
    }

    matExpansionPanel.toggle();
  }

  preventToggle(matExpansionPanel: MatExpansionPanel, event: Event): void {
    event.stopPropagation();

    matExpansionPanel.toggle();
  }

  get headerBaseStyling(): string {
    return this.accordionType === AccordionType.H3 ? 'header--primary' : 'header--secondary';
  }

  getHeaderStateStyling(isExpanded: boolean): string {
    return isExpanded ? 'header--expanded' : 'header--collapsed';
  }

  private getClassificationByType(accordionType: AccordionType): AccordionClassification {
    switch (accordionType) {
      case AccordionType.H3: {
        return AccordionClassification.Primary;
      }

      case AccordionType.H4:
      case AccordionType.H5: {
        return AccordionClassification.Secondary;
      }
    }
  }
}
