import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-part-select',
  templateUrl: './recall2-icon-part-select.component.html',
  standalone: true,
})
export class Recall2IconPartSelectComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i92-part-select');
  }
}
