<div class="add-new-user-component">
  <!-- Form Header -->
  <div class="row rc2-mar--no header-row-height">
    <div class="col-md-11 pad-bottom-erase">
      <h2 class="header-title">{{"user.create.header.title" | translate}}</h2>
    </div>

    <div class="col-md-1 rc2-text-align--right">
      <recall2-icon-close-window
        (click)="onClose()"
        [rc2IconStyles]="'rc2-icon--color--blue rc2-icon--cursor--pointer rc2-icon--size--24'"
      >
      </recall2-icon-close-window>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 pad-bottom-large">
      <h5 class="header-subtitle rc2-font--size--14">{{"user.create.header.subtitle" | translate}}</h5>
    </div>
  </div>

  <div class="row pad-bottom-large">
    <div class="col-md-6">
      <recall2-input-text
        [property]="lastNameProperty"
        [isFormSubmitted]="isFormSubmitted"
      ></recall2-input-text>
    </div>
    <div class="col-md-6">
      <recall2-input-text
        [property]="firstNameProperty"
        [isFormSubmitted]="isFormSubmitted"
      ></recall2-input-text>
    </div>
  </div>

  <div class="row pad-bottom-large">
    <div class="col-md-6">
      <recall2-input-text
        [property]="vwUserIdProperty"
        [isFormSubmitted]="isFormSubmitted"
      ></recall2-input-text>
    </div>
    <div class="col-md-6">
      <recall2-input-text
        [property]="emailProperty"
        [isFormSubmitted]="isFormSubmitted"
      ></recall2-input-text>
    </div>
  </div>

  <div class="row pad-bottom-large">
    <div class="col-md-6">
      <recall2-input-text
        [property]="phoneProperty"
        [isFormSubmitted]="isFormSubmitted"
      ></recall2-input-text>
    </div>
    <div class="col-md-6">
      <recall2-input-text
        [property]="departmentProperty"
        [isFormSubmitted]="isFormSubmitted"
      ></recall2-input-text>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 recommend-text">
      <p class="required-fields"><span>* </span>{{ "ui.form.general.requiredField.hint" | translate }}</p>
    </div>
  </div>

  <!-------- Form Actions ---->
  <div class="row rc2-mar--no">
    <div class="rc2-display--flex rc2-justify--flex-end">
      <recall2-button-tertiary
        (click)="onClose()"
        [iconClass]="'icon-i90-unselect'"
        class="rc2-pad--bottom--s"
        id="create-user-button-close"
      >
        {{ "ui.form.actions.button.close" | translate }}
      </recall2-button-tertiary>
      <recall2-button-primary
        *ngIf="userCreate"
        (click)="onSubmit($event)"
        [iconClass]="'icon-i66-save'"
        [forceAnimation]="isAnimation"
        class="rc2-pad--bottom--s"
        id="create-user-button-add"
      >
        {{ "ui.form.actions.button.add.user" | translate }}
      </recall2-button-primary>
    </div>
  </div>
</div>
