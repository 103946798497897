import type { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';

import { Recall2NewRelicService } from './recall2-new-relic.service';

// eslint-disable-next-line @angular-eslint/use-injectable-provided-in
@Injectable()
export class Recall2NewRelicErrorHandler implements ErrorHandler {
  constructor(private recall2NewRelicService: Recall2NewRelicService) {}

  handleError(error: unknown): void {
    console.error(error);

    this.recall2NewRelicService.noticeError(error);
  }
}
