<div class="rc2-bg--petrol-50 rc2-pad--xl">
  <recall2-card class="rc2-display--flex rc2-mar--top--xl">
    <ng-container header>
      <div class="rc2-display--flex rc2-justify--space-between">
        <app-title-section
          data-cy="admin-panel-container-card"
          titleClass="h6"
          [title]="'admin-panel.card.title' | translate"
        >
        </app-title-section>

        <div data-cy="search-field">
          <recall2-input-text
            data-cy="admin-panel-search-applications-field"
            [property]="searchProperty"
            [isClearable]="true"
          >
            <recall2-svg-icon
              content-left
              name="search"
              size="medium"
              class="rc2-icon--color--grey--1000 rc2-icon--size--18"
            ></recall2-svg-icon>
          </recall2-input-text>
        </div>
      </div>
    </ng-container>

    <div
      class="rc2-mar--top--xl"
      body
    >
      <div class="navigation-box-container">
        <app-admin-panel-item *ngFor="let item of (filteredPanelItems$ | async)" [item]="item"></app-admin-panel-item>
      </div>
    </div>
  </recall2-card>
</div>
