import { Injectable } from '@angular/core';

import { NotificationStatus } from '../../models/notification/notification-status.enum';

@Injectable({ providedIn: 'root' })
export class NotificationPermissionService {
  private readonly editableStatus: NotificationStatus[] = [
    NotificationStatus.DISCARDED,
    NotificationStatus.DRAFT,
    NotificationStatus.TO_REVISE_BRAND_OFFICE,
    NotificationStatus.TO_REVISE_REVIEWER,
    NotificationStatus.TRANSFERRED,
  ];

  canEditNotification(status: NotificationStatus): boolean {
    return this.editableStatus.includes(status);
  }
}
