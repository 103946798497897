import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import { ENumberFilterComparator } from '../../models/filter.model';

@Pipe({
  name: 'numberFilterComparatorTranslateKey',
  standalone: true,
})
export class NumberFilterComparatorTranslateKeyPipe implements PipeTransform {
  transform(comparator: ENumberFilterComparator): string {
    switch (comparator) {
      case ENumberFilterComparator.LESS:
        return 'recall2-filter.number.comparator.less';
      case ENumberFilterComparator.GREATER:
        return 'recall2-filter.number.comparator.greater';
      case ENumberFilterComparator.EQUALS:
        return 'recall2-filter.number.comparator.equals';
      default:
        return;
    }
  }
}
