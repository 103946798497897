<mat-form-field
  appearance="fill"
  [id]="property.name"
  class="autocomplete-form-field"
  [class.has-label]="property?.translationKey"
  floatLabel="always"
>
  <label
    [id]="property.name + '_label'"
    class="autocomplete-label"
    data-cy="autocomplete-label"
    [ngClass]="property?.disabled ? 'rc2-color--grey-400-secondary' : 'rc2-color--grey-800-primary'"
    *ngIf="property?.translationKey"
    >{{ property.translationKey | translate }}
    <span
      *ngIf="property?.required"
      class="rc2-font--body--m"
      [ngClass]="property?.disabled ? 'rc2-color--grey-400-secondary' : 'rc2-color--danger'"
      >*</span
    >
    <recall2-icon-help
      *ngIf="property.tooltipKey"
      [matTooltip]="property.tooltipKey | translate"
      matTooltipClass="rc2-tooltip"
      matTooltipPosition="above"
    >
    </recall2-icon-help>
  </label>
  <input
    matInput
    type="text"
    [name]="property.name + '_input'"
    data-cy="autocomplete-input"
    #trigger="matAutocompleteTrigger"
    [placeholder]="property.placeholderKey | translate"
    maxlength="{{ property?.htmlValidators?.maxLength }}"
    [formControl]="property.control"
    [matAutocomplete]="autocompleteList"
    [required]="property?.required"
    [readonly]="property?.disabled"
    [class.uppercase]="property.control.value && property?.upperCase"
    [class.autocomplete-disabled]="property?.disabled"
  />
  <ng-container *ngIf="property.showValidations">
    <div
      *ngIf="property.control.errors"
      [id]="'error_feedback_message'"
      data-cy="error_feedback_message"
      class="error-message"
    >
      <recall2-icon-invalid></recall2-icon-invalid>
      <span *ngIf="property.control.errors?.required">
        {{ 'form.error.generic.required' | translate }}
      </span>
      <span *ngIf="property.control.errors?.pattern">
        {{ 'form.error.generic.pattern' | translate }}
      </span>
      <span *ngIf="property.control.errors?.notUnique">
        {{ 'form.error.generic.notUnique' | translate }}
      </span>
      <span *ngIf="property.control.errors?.codeBlocked">
        {{ 'form.error.generic.codeBlocked' | translate }}
      </span>
    </div>
  </ng-container>
  <div matSuffix class="autocomplete-arrow-wrapper" (click)="openOrClosePanel($event, trigger)">
    <div class="button-wrapper" [class.arrows-position]="property.control.errors">
      <button
        type="button"
        mat-button
        *ngIf="property.control.value && isClearable && !property?.disabled"
        matSuffix
        mat-icon-button
        (click)="onDelete()"
      >
        <recall2-svg-icon
          class="rc2-icon--cursor--pointer"
          data-cy="autocomplete-clear-icon"
          name="clear"
          size="medium"
          (click)="onDelete()"
        >
        </recall2-svg-icon>
      </button>
      <div *ngIf="property.arrowIcon">
        <recall2-icon-arrow-down
          class="rc2-font--size--18"
          data-cy="autocomplete-arrow-down-icon"
          *ngIf="!isOpened"
          [ngClass]="property?.disabled ? 'rc2-color--grey-400-secondary' : 'rc2-color--grey-600'"
        ></recall2-icon-arrow-down>
        <recall2-icon-arrow-up
          data-cy="autocomplete-arrow-up-icon"
          class="rc2-color--primary rc2-font--size--18"
          *ngIf="isOpened"
        ></recall2-icon-arrow-up>
      </div>
    </div>
  </div>

  <mat-autocomplete
    [id]="property.name + '_autocomplete'"
    #autocompleteList="matAutocomplete"
    [displayWith]="displayHelper"
    (opened)="onAutocompleteOpened()"
    (closed)="onAutocompleteClosed()"
  >
    <mat-option
      *ngIf="
        (property.overlayItemsKey && (autocompleteOptions$ | async)?.length > 0) ||
        (autocompleteGroups$ | async)?.length > 0
      "
      [id]="property.name + '_overlay-items'"
      class="overlay-title"
      data-cy="'autocomplete-option-overlay-title'"
    >
      <mat-label data-cy="autocomplete-option-overlay-title-label" class="overlay-title__label">
        {{ property.overlayItemsKey | translate }}
      </mat-label>
    </mat-option>
    <mat-option
      *ngIf="property.overlayNoItemsKey && !property.optionsList"
      [id]="property.name + '_overlay-no-items'"
      class="overlay-title"
      data-cy="'autocomplete-option-overlay-title-no-items'"
    >
      <mat-label data-cy="autocomplete-option-overlay-title-no-items-label" class="overlay-title__no-items">
        {{ property.overlayNoItemsKey | translate }}
      </mat-label>
    </mat-option>
    <app-ghost-skeleton *ngIf="loading$ | async" [schema]="GhostSkeletonSchema.Options"></app-ghost-skeleton>
    <ng-container *ngIf="(loading$ | async) === false">
      <ng-container *ngIf="isGroupedData">
        <mat-optgroup
          *ngFor="let item of autocompleteGroups$ | async"
          [label]="item.group"
          data-cy="autocomplete-group"
        >
          <mat-option
            *ngFor="let option of item.options; trackBy: trackOption"
            [value]="option"
            data-cy="autocomplete-option"
          >
            <ng-template
              [ngTemplateOutlet]="optionTemplate"
              [ngTemplateOutletContext]="{ $implicit: option }"
            ></ng-template>
          </mat-option>
        </mat-optgroup>
      </ng-container>

      <ng-container *ngIf="!isGroupedData">
        <mat-option
          *ngFor="let option of autocompleteOptions$ | async; trackBy: trackOption"
          [value]="option"
          (click)="onOptionSelected(option)"
          data-cy="autocomplete-option"
        >
          <ng-template
            [ngTemplateOutlet]="optionTemplate"
            [ngTemplateOutletContext]="{ $implicit: option }"
          ></ng-template>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>

<ng-template #optionTemplate let-option>
  <ng-container *ngIf="itemTemplate">
    <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: option }"> </ng-template>
  </ng-container>
  <ng-container *ngIf="!itemTemplate"
    ><span [innerHTML]="option.key | autocompleteHighlight: toHighlight"></span>
  </ng-container>
</ng-template>
