<div class="table-header-container">
  <div class="rc2-display--flex rc2-align-items--center table-header-text">
    <ng-content></ng-content>
  </div>
  <div class="rc2-display--flex">
    <app-table-sort
      *ngIf="isSortVisible"
      [column]="column"
      [sortOrder]="sortOrder"
      appTableSortHeader
    ></app-table-sort>
    <app-table-column-filter
      *ngIf="isFilterVisible"
      [column]="column"
      appTableColumnFilterHeader
    ></app-table-column-filter>
  </div>
</div>
