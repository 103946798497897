<div
  class="title"
  [ngClass]="titleClass"
>
  <span data-cy="title-section-title">{{ title }}</span>
  <span
    *ngIf="count"
    data-cy="title-section-count"
  >
    ({{ count }})
  </span>
</div>
<div
  *ngIf="subtitle"
  class="subtitle"
  data-cy="title-section-subtitle"
>
  {{ subtitle }}
</div>
