import { NgClass, NgIf } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Recall2IconHelpComponent } from '../../../icons/recall2-icon-help/recall2-icon-help.component';
import { Recall2IconInvalidComponent } from '../../../icons/recall2-icon-invalid/recall2-icon-invalid.component';
import { Recall2IconRequiredComponent } from '../../../icons/recall2-icon-required/recall2-icon-required.component';
import { Recall2AllowedCharactersDirective } from '../../directives/recall2-allowed-characters';
import { Recall2MinRowSizeDirective } from '../../directives/recall2-autosize';
import { getCounter } from '../../helper';
import { Recall2InputTextComponent } from '../recall2-input-text/recall2-input-text.component';

@Component({
  selector: 'recall2-textarea',
  standalone: true,
  templateUrl: './recall2-textarea.component.html',
  styleUrls: ['../recall2-input-text/recall2-input-text.component.scss', './recall2-textarea.component.scss'],
  imports: [
    NgClass,
    NgIf,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule,
    Recall2IconHelpComponent,
    Recall2IconRequiredComponent,
    Recall2IconInvalidComponent,
    Recall2AllowedCharactersDirective,
    Recall2MinRowSizeDirective,
  ],
})
export class Recall2TextareaComponent extends Recall2InputTextComponent implements OnInit, OnDestroy {
  @Input() minRows: number;
  @Input() showCounter = true;

  counter = '';

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.setCounter();
    this.watchValueChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private watchValueChanges(): void {
    this.property.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.setCounter());
  }

  private setCounter(): void {
    this.counter = getCounter(this.property.control.value?.length, this.property.htmlValidators?.maxLength);
  }
}
