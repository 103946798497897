import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { PageDTO } from '@recall2/ui/pagination';
import type { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import type { UserData } from '../../user/store/user.state';

@Injectable({
  providedIn: 'root',
})
export class AutoSuggestService {
  private readonly maxPageSize = 100;
  constructor(private _http: HttpClient) {}

  searchUsersByString(searchTerm: string, pageSize: number = this.maxPageSize): Observable<PageDTO<UserData>> {
    let httpParams = new HttpParams();
    if (searchTerm) {
      httpParams = httpParams.append('searchString', searchTerm);
    }
    httpParams = httpParams.append('page-size', pageSize.toString());
    const url = `${environment.apiUrlUser}/users/search-user`;
    return this._http.get<PageDTO<UserData>>(url, { params: httpParams });
  }
}
