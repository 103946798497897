import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { arrayAdd, arrayRemove, arrayUpdate } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import type { AttachmentFileForDownloadList } from '@recall2/ui/attachment';
import { download, fileExtension, toBlob } from '@recall2/ui/attachment';
import type { Page } from '@recall2/ui/core/api/models';
import { ToastService } from '@recall2/ui/toast';
import type { Observable } from 'rxjs';
import { filter } from 'rxjs';
import { delay, first, map, mergeMap, tap } from 'rxjs/operators';

import type { AttachmentType } from '../../enums';
import { AttachmentStatus } from '../../enums/attachment-status.enum';
import type {
  AttachmentFile,
  AttachmentFileDTO,
  AttachmentUploadInformation,
  CampaignId,
  VerificationTaskId,
} from '../../models';
import { AttachmentsService } from '../../services';
import { AttachmentStore } from './attachment.store';
import type { Upload } from './attachment.utils';
import { uploadPipe, UploadState } from './attachment.utils';

@Injectable({ providedIn: 'root' })
export class AttachmentStoreService {
  constructor(
    private attachmentStore: AttachmentStore,
    private attachmentService: AttachmentsService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private http: HttpClient,
  ) {}

  getAttachments(attachmentObjectType: AttachmentType, objectId: CampaignId | VerificationTaskId): void {
    this.loadAttachments(attachmentObjectType, objectId);
  }

  getRowItemAttachments(attachmentObjectType: AttachmentType, objectId: string | number): void {
    this.loadAttachments(attachmentObjectType, objectId, true);
  }

  getRowItemAttachmentsForPhase1(objectId: string | number, apiUrl: string, type?: AttachmentType): void {
    this.loadAttachmentsForPhase1(objectId, apiUrl, type);
  }

  clearAttachments(): void {
    this.attachmentStore.reset();
  }

  clearRowItemAttachments(): void {
    this.attachmentStore.update({ rowItemAttachments: [] });
  }

  uploadAttachment(attachmentType: AttachmentType, file: File, objectId?: CampaignId | VerificationTaskId): void {
    this.attachmentStore.setLoading(true);
    this.attachmentService
      .getUploadInformation()
      .pipe(
        first(),
        tap(uploadInfo => {
          this.attachmentStore.setLoading(false);
          if (uploadInfo.rule.maxSize < file.size) {
            this.attachmentStore.setError('max size exceeded');
          }
        }),
        // delay for discard race condition
        delay(300),
        mergeMap(uploadInfo => {
          return this.uploadAttachmentToServer(uploadInfo, file);
        }),
      )
      .subscribe({
        next: uploadKey => {
          this.saveAttachments(attachmentType, objectId, uploadKey);
        },
        error: error => {
          this.attachmentStore.setError(error);
          this.attachmentStore.setLoading(false);
        },
      });
  }

  private loadAttachments(
    attachmentObjectType: AttachmentType,
    objectId: CampaignId | VerificationTaskId,
    isRowItemAttachments = false,
  ): void {
    this.attachmentStore.setLoading(true);
    this.attachmentService
      .getAttachments(attachmentObjectType, objectId)
      .pipe(first())
      .subscribe({
        next: ({ content }: Page<AttachmentFile>) => {
          content.forEach((attachment: AttachmentFile) => {
            attachment.persisted = true;
            attachment.progress = 100;
            attachment.temporaryKey = attachment.key;
          });

          this.attachmentStore.update(
            isRowItemAttachments ? { rowItemAttachments: content } : { attachments: content },
          );

          this.attachmentStore.setLoading(false);
        },
        error: error => {
          this.attachmentStore.setError(error);
          this.attachmentStore.setLoading(false);
        },
      });
  }

  private loadAttachmentsForPhase1(
    objectId: CampaignId | VerificationTaskId,
    apiUrl: string,
    type?: AttachmentType,
  ): void {
    this.attachmentStore.setLoading(true);
    this.attachmentService
      .getAttachmentsForPhase1(objectId, apiUrl, type)
      .pipe(first())
      .subscribe({
        next: ({ attachments }) => {
          attachments.forEach((attachment: AttachmentFile) => {
            attachment.persisted = true;
            attachment.progress = 100;
            attachment.temporaryKey = attachment.key;
          });

          this.attachmentStore.update({ rowItemAttachments: attachments });
          this.attachmentStore.setLoading(false);
        },
        error: error => {
          this.attachmentStore.setError(error);
          this.attachmentStore.setLoading(false);
        },
      });
  }

  deletePersistedAttachment(
    attachmentType: AttachmentType,
    objectId: CampaignId | VerificationTaskId,
    attachmentId: string,
  ): void {
    this.attachmentStore.setLoading(true);
    this.attachmentService
      .deletePersistedAttachment(attachmentType, objectId, attachmentId)
      .pipe(first())
      .subscribe({
        next: _ => {
          this.attachmentStore.setLoading(false);
          this.attachmentStore.update(({ attachments }) => ({
            attachments: arrayRemove(attachments, attachmentId, 'id'),
          }));
        },
        error: error => {
          this.attachmentStore.setError(error);
          this.attachmentStore.setLoading(false);
        },
      });
  }

  deleteTemporalAttachment(key: string): void {
    this.attachmentStore.setLoading(true);
    this.attachmentService
      .deleteTemporalAttachment(key)
      .pipe(first())
      .subscribe({
        next: _ => {
          this.attachmentStore.setLoading(false);
          this.attachmentStore.update(({ attachments }) => ({
            attachments: arrayRemove(attachments, key, 'temporaryKey'),
          }));
        },
        error: error => {
          this.attachmentStore.setError(error);
          this.attachmentStore.setLoading(false);
        },
      });
  }

  deletePersistedOrphanAttachment(attachmentType: AttachmentType, attachmentId: string): void {
    this.attachmentStore.setLoading(true);
    this.attachmentService
      .deletePersistedOrphanAttachment(attachmentType, attachmentId)
      .pipe(first())
      .subscribe({
        next: _ => {
          this.attachmentStore.setLoading(false);
          this.attachmentStore.update(({ attachments }) => ({
            attachments: arrayRemove(attachments, attachmentId, 'id'),
          }));
        },
        error: error => {
          this.attachmentStore.setError(error);
          this.attachmentStore.setLoading(false);
        },
      });
  }

  saveAttachments(
    attachmentObjectType: AttachmentType,
    objectId?: CampaignId | VerificationTaskId,
    temporaryKey?: string,
  ): void {
    const attachmentsToSave = this.getAttachmentFilesToDTO(temporaryKey);
    if (attachmentsToSave.length > 0) {
      this.attachmentStore.setLoading(true);
      this.attachmentService
        .saveAttachments(attachmentObjectType, attachmentsToSave, objectId)
        .pipe(
          first(),
          map(attachments => attachments.map(attachment => ({ ...attachment, persisted: true, progress: 100 }))),
        )
        .subscribe({
          next: savedAttachments => {
            this.attachmentStore.setLoading(false);

            let attachments = [];

            attachments = temporaryKey
              ? [
                  ...savedAttachments,
                  ...this.attachmentStore
                    .getValue()
                    .attachments.filter(attachment => attachment.temporaryKey !== temporaryKey),
                ]
              : [
                  ...savedAttachments,
                  ...this.attachmentStore.getValue().attachments.filter(attachment => attachment.persisted),
                ];

            this.attachmentStore.update({
              attachments,
            });
          },
          error: error => {
            this.attachmentStore.setError(error);
            this.attachmentStore.setLoading(false);
          },
        });
    }
  }

  getUrlToDownload(file: AttachmentFile): void {
    this.attachmentService
      .getDownloadInformation(file.persisted ? file.key : file.temporaryKey)
      .pipe(first())
      .subscribe({
        next: url => {
          if (url.status === AttachmentStatus.Clean) {
            this.downloadAttachment(url.url, file.filename);
          } else {
            this.toastService.error({
              title: `${this.translateService.instant('shared.attachments.unable-to-download')}: ${file.filename}`,
              content: this.translateService.instant(`attachments.status.${url.status}`),
            });
          }
        },
        error: error => this.attachmentStore.setError(error),
      });
  }

  downloadAttachment(url: string, filename?: string): void {
    this.attachmentService
      .downloadAttachment(url)
      .pipe(first())
      .subscribe(
        (file: Blob) => {
          download(file, filename);
        },
        error => {
          this.attachmentStore.setError(error);
        },
      );
  }

  downloadAttachmentForPhase1(url: string, file: AttachmentFile): void {
    this.attachmentService
      .downloadAttachmentForPhase1(url, file)
      .pipe(first())
      .subscribe(
        (res: AttachmentFileForDownloadList) => {
          download(toBlob(res.attachments[0].data), file.filename);
        },
        error => {
          this.attachmentStore.setError(error);
        },
      );
  }

  fileToAttachmentFile(uploadInfo: AttachmentUploadInformation, file: File): AttachmentFile {
    return {
      temporaryKey: uploadInfo.key,
      filename: file.name,
      extension: fileExtension(file),
      size: file.size,
      type: file.type,
      url: uploadInfo.url,
      persisted: false,
    };
  }

  getAttachmentFilesToDTO(temporaryKey?: string): AttachmentFileDTO[] {
    let attachedFiles: AttachmentFile[] = [];

    attachedFiles = temporaryKey
      ? this.attachmentStore
          .getValue()
          .attachments.filter(attachment => !attachment.persisted && attachment.temporaryKey === temporaryKey)
      : this.attachmentStore.getValue().attachments.filter(attachment => !attachment.persisted);

    return attachedFiles.map(attachment => {
      return {
        extension: attachment.extension,
        filename: attachment.filename,
        size: attachment.size,
        temporaryKey: attachment.temporaryKey,
      };
    });
  }

  private uploadAttachmentToServer(uploadInfo: AttachmentUploadInformation, attachment: File): Observable<string> {
    return this.http
      .put(uploadInfo.url, attachment, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        uploadPipe(),
        tap((upload: Upload) => {
          if (upload.state === UploadState.Pending) {
            this.attachmentStore.update(({ attachments }) => ({
              attachments: arrayAdd(attachments, this.fileToAttachmentFile(uploadInfo, attachment)),
            }));

            return;
          }

          if (upload.state === UploadState.InProgress) {
            this.attachmentStore.update(({ attachments }) => ({
              attachments: arrayUpdate(
                attachments,
                uploadInfo.key,
                {
                  progress: upload.progress,
                  size: Math.round(attachment.size * (upload.loaded / upload.total)),
                },
                'temporaryKey',
              ),
            }));

            return;
          }
        }),
        filter((upload: Upload) => upload.state === UploadState.Done),
        map(() => uploadInfo.key),
      );
  }
}
