import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-return',
  templateUrl: './recall2-icon-return.component.html',
  standalone: true,
})
export class Recall2IconReturnComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i102-return');
  }
}
