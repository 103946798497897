import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { DueDateStatus } from '../core/api/enums/due-date-status.enum';
import { CustomDatePipe } from '../pipes';
import { DueDate } from './models/due-date';

@Component({
  selector: 'recall2-deadline',
  templateUrl: './recall2-deadline.component.html',
  styleUrls: ['./recall2-deadline.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf, NgClass, CustomDatePipe],
})
export class Recall2DeadlineComponent {
  @Input() dueDate: DueDate;
  @Input() labelTranslationKey?: string;
  @Input() ignoredStatus?: DueDateStatus;

  get isApproaching(): boolean {
    return !this.isStatusIgnored(DueDateStatus.APPROACHING) && this.dueDate.status === DueDateStatus.APPROACHING;
  }

  get isOverdue(): boolean {
    return !this.isStatusIgnored(DueDateStatus.OVERDUE) && this.dueDate.status === DueDateStatus.OVERDUE;
  }

  get isNone(): boolean {
    return !this.isStatusIgnored(DueDateStatus.NONE) && this.dueDate.status === DueDateStatus.NONE;
  }

  private isStatusIgnored(status: DueDateStatus): boolean {
    return status === this.ignoredStatus;
  }
}
