import { LanguageCode } from '../../enums/language-code.enum';

/**
 * Given a language string obtained from the browser or user profile
 * Return the language locale
 */
export const getLanguageLocale = (lang: string): LanguageCode => {
  if (LanguageCode.German.includes(lang)) {
    return LanguageCode.German;
  } else if (LanguageCode.EnglishGB.includes(lang)) {
    return LanguageCode.EnglishGB;
  } else if (LanguageCode.EnglishUS.includes(lang)) {
    return LanguageCode.EnglishUS;
  } else if (LanguageCode.Spanish.includes(lang)) {
    return LanguageCode.Spanish;
  } else {
    return LanguageCode.EnglishUS;
  }
};
