import { NgClass, NgIf } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import { Recall2InputTextComponent } from '../../../form/components/recall2-input-text/recall2-input-text.component';
import { InputTextProperty } from '../../../form/model';
import { Recall2IconArrowDownComponent, Recall2IconArrowUpComponent } from '../../../icons';

@Component({
  selector: 'recall2-auto-suggest-input',
  templateUrl: './recall2-auto-suggest-input.component.html',
  standalone: true,
  imports: [Recall2InputTextComponent, Recall2IconArrowDownComponent, Recall2IconArrowUpComponent, NgClass, NgIf],
})
export class Recall2AutoSuggestInputComponent implements OnInit, OnDestroy {
  static activeInstance?: Recall2AutoSuggestInputComponent;
  static keyDelay = 500;

  @Input() isFormSubmitted = false;
  @Input() property = new InputTextProperty(
    'autoSuggest',
    false,
    'autoSuggest',
    { maxLength: 50 },
    true,
    new FormControl('', []),
    true,
  );
  @Input() isDisabled = false;

  @Output() isArrowDown = new EventEmitter<boolean>();
  @Output() changeSearchTerm = new EventEmitter<string>();

  @ViewChild('recallInputText') inputText: Recall2InputTextComponent;

  private destroyed$ = new Subject<void>();
  private lastValue = '';
  private searchTermSubject = new Subject<string>();

  isArrowIconDown = true;

  get errorType(): string {
    return Object.keys(this.property.control.errors || {})[0] || '';
  }

  ngOnInit(): void {
    this.initSearchTermListener();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    if (Recall2AutoSuggestInputComponent.activeInstance === this) {
      Recall2AutoSuggestInputComponent.activeInstance = undefined;
    }
  }

  onInputKeyUp(): void {
    const searchTerm = this.property.control.value || '';
    this.searchTermSubject.next(searchTerm);
  }

  onInputFocus(): void {
    if (this.isArrowIconDown) {
      this.toggleArrowIcon();
    }
  }

  toggleArrowIcon(): void {
    if (this.isDisabled) {
      return;
    }

    if (this.isArrowIconDown) {
      this.closeActiveInput();
    }

    this.isArrowIconDown = !this.isArrowIconDown;
    this.isArrowDown.emit(this.isArrowIconDown);

    Recall2AutoSuggestInputComponent.activeInstance = this.isArrowIconDown ? undefined : this;

    if (!this.isArrowIconDown) {
      this.emitSearchTerm();
    }
  }

  setInputTextFocus(): void {
    this.inputText.setFocus();
  }

  private initSearchTermListener(): void {
    this.searchTermSubject
      .pipe(
        takeUntil(this.destroyed$),
        map(term => term.trim()),
        filter(term => this.hasDistinctValue(term)),
        debounceTime(Recall2AutoSuggestInputComponent.keyDelay),
        distinctUntilChanged(),
      )
      .subscribe(search => this.changeSearchTerm.emit(search));
  }

  private hasDistinctValue(term: string): boolean {
    if (this.lastValue === term) {
      return false;
    }
    this.lastValue = term;
    return true;
  }

  private emitSearchTerm(): void {
    this.lastValue = this.property.control.value || '';
    this.changeSearchTerm.emit(this.lastValue);
  }

  private closeActiveInput(): void {
    const activeInstance = Recall2AutoSuggestInputComponent.activeInstance;
    if (activeInstance && activeInstance !== this && !activeInstance.isArrowIconDown) {
      activeInstance.toggleArrowIcon();
    }
  }
}
