import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2AllowedCharactersDirective } from '../form/directives/recall2-allowed-characters';
import { AllowedCharactersOption } from '../form/model';
import { PaginationModel } from './model/pagination.model';

@Component({
  selector: 'recall2-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  standalone: true,
  imports: [FormsModule, TranslateModule, NgFor, NgIf, Recall2AllowedCharactersDirective],
})
export class PaginationComponent {
  @Input() paginationModel: PaginationModel;
  @Output() updatePaginationValues = new EventEmitter<PaginationModel>();

  readonly itemsPerPageList: number[] = [10, 20, 40, 60, 80, 100];
  readonly pageInputAllowedCharacters = AllowedCharactersOption.Integers;

  updateItemsPerPage(): void {
    this.paginationModel.paginationPageNumber = 1;
    this.paginationModel.currentSelectedPage = 1;
    this.updatePaginationValues.emit(this.paginationModel);
  }

  updateCurrentPage(): void {
    this.doNavigateToPage(this.paginationModel.paginationPageNumber);
  }

  navigateToPreviousPage(): void {
    this.paginationModel.paginationPageNumber--;
    this.updateCurrentPage();
  }

  navigateToNextPage(): void {
    this.paginationModel.paginationPageNumber++;
    this.updateCurrentPage();
  }

  doNavigateToPage(pageNumber: number): void {
    if (pageNumber < 1) {
      pageNumber = 1;
    }
    if (pageNumber > this.paginationModel.paginationTotalPages) {
      pageNumber = this.paginationModel.paginationTotalPages;
    }

    this.paginationModel.currentSelectedPage = pageNumber;
    this.paginationModel.paginationPageNumber = pageNumber;

    this.updatePaginationValues.emit(this.paginationModel);
  }

  isPaginationPageFirst(): boolean {
    let isFirst = false;
    if (this.paginationModel.currentSelectedPage === 1) {
      isFirst = true;
    }
    return isFirst;
  }

  isPaginationPageLast(): boolean {
    return this.paginationModel.currentSelectedPage === this.paginationModel.paginationTotalPages;
  }

  getElementSumFromFirst(): number {
    let value = this.paginationModel.paginationSelectedPageSize * (this.paginationModel.paginationPageNumber - 1) + 1;
    if (value < 0) {
      value = 0;
    }
    if (value > this.paginationModel.paginationTotalElements) {
      value = this.paginationModel.paginationTotalElements;
    }
    return value;
  }
}
