import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { iconArrowLeftMedium, iconLaunchSmall, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';

@Component({
  selector: 'recall2-link',
  templateUrl: './recall2-link.component.html',
  styleUrls: ['./recall2-link.component.scss'],
  host: {
    class: 'rc2-display--flex',
  },
  standalone: true,
  imports: [SVGIconModule, TranslateModule, NgIf, NgClass],
})
export class ReCall2LinkComponent {
  @Input() isHeaderBackNavigation = false;
  @Input() linkText: string;

  constructor(iconsRegistry: SVGIconsRegistry) {
    iconsRegistry.registerIcons([iconLaunchSmall, iconArrowLeftMedium]);
  }
}
