import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { PageDTO } from '../../../../pagination';
import type { IAutoSuggestCommon } from '../../../models/auto-suggest-common';
import type { DirectoryUser, DirectoryUserRequest } from '../models/users';

const DIRECTORY_SERVICE_PATH = '/api-usermgmt-user/directory-service';

@Injectable({ providedIn: 'root' })
export class DirectorySearchService {
  constructor(private httpClient: HttpClient) {}

  getDepartments(searchTerm?: string): Observable<PageDTO<IAutoSuggestCommon>> {
    let httpParams = new HttpParams();
    if (searchTerm) {
      httpParams = httpParams.append('name', searchTerm);
    }
    return this.httpClient.get<PageDTO<IAutoSuggestCommon>>(`${DIRECTORY_SERVICE_PATH}/departments`, {
      params: httpParams,
    });
  }

  getUsers(request: DirectoryUserRequest): Observable<DirectoryUser[]> {
    return this.httpClient.post<DirectoryUser[]>(`${DIRECTORY_SERVICE_PATH}/search`, request);
  }
}
