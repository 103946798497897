import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-logo-ducati',
  templateUrl: './recall2-icon-logo-ducati.component.html',
  styleUrls: ['./recall2-icon-logo-ducati.component.scss'],
  standalone: true,
})
export class Recall2IconLogoDucatiComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i114-logo-ducati');
  }
}
