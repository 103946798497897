import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import { Recall2IconArrowDownComponent, Recall2IconArrowUpComponent } from '../../../icons';

@Component({
  selector: 'recall2-accordion-toggle',
  templateUrl: './recall2-accordion-toggle.component.html',
  styleUrls: ['./recall2-accordion-toggle.component.scss'],
  standalone: true,
  imports: [NgIf, Recall2IconArrowUpComponent, Recall2IconArrowDownComponent],
})
export class Recall2AccordionToggleComponent {
  @Input() isExpanded: boolean;
}
