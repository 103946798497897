import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@recall2/globals';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  cookies = ['XSRF-TOKEN'];

  constructor(@Inject(WINDOW) private window: Window) {}

  logout(): void {
    const userLanguage = this.window.localStorage.getItem('userLanguage');
    this.cookies.forEach(cookie => this.cleanCookie(cookie));
    this.window.localStorage.clear();
    this.window.sessionStorage.clear();
    this.window.localStorage.setItem('userLanguage', userLanguage);
  }

  private cleanCookie(name: string): void {
    this.window.document.cookie = `${name}=' ';Path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
