export enum ETableHeader {
  ICON = 'icon',
  ID = 'id',
  TITLE = 'title',
  REPORTER = 'reporter',
  EDITOR = 'activeEditor',
  RECEIVED_DATE = 'receivedDate',
  DUE_TO = 'dueTo',
  STATUS = 'status',
  LINK_COUNT = 'linkCount',
  ATTACHMENT_COUNT = 'attachmentCount',
  EDIT_ICON = 'editIcon',
  ARROW_ICON = 'arrowIcon',
}
