import { Component, Input } from '@angular/core';

@Component({
  selector: 'recall2-toggle-element',
  template: '',
  standalone: true,
})
export class Recall2ToggleElementComponent {
  @Input() value: string;
  @Input() label: string;
  @Input() icon?: string;
  @Input() size: 'small' | 'medium';
}
