import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';

import type { EventType, SystemNotificationEventType } from '../models/object-event';

@Injectable({
  providedIn: 'root',
})
export class DescriptionTranslateService {
  constructor(private translateService: TranslateService) {}

  /**
   * Returns an observable of the translated event description.
   * Emits a new value on every language change. The translation key
   * is based on the eventType.
   *
   * @param eventType Type of the history event
   * @returns Observable of the translated event description string
   */
  translate(eventType: EventType | SystemNotificationEventType): Observable<string> {
    return this.translateService.stream(`history.event-types.${eventType}`);
  }
}
