<div (click)="onClick($event)" [class.primary]="isPrimary">
  <button
    #downloadbutton
    (click)="downloadFile()"
    id="{{ id }}"
    data-cy="download-button"
    class="rc2-font--body--l rc2-display--flex rc2-align-items--center btn btn--download {{rc2ButtonStyles}}"
    [ngClass]="{'spinner spinner-box': forceAnimation, 'disabled': isDisabled}"
    [disabled]="isDisabled"
  >
    <span
      class="button-content"
      [style.visibility]="forceAnimation ? 'hidden' : 'visible'"
    >
      <recall2-icon-download
        *ngIf="showIcon"
        [rc2IconStyles]="'rc2-icon--size--24 rc2-pad--no'">
      </recall2-icon-download>
      <span>{{title}}</span>
    </span>
  </button>
</div>
