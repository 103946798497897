import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'userName',
  standalone: true,
})
export class UserNamePipe implements PipeTransform {
  transform(userId: string, lastName: string, firstName: string): string {
    return `${userId}, ${lastName}, ${firstName}`;
  }
}
