import { ERole } from '../../../navbar/models/role.model';

export const vinSearchRoles: ERole[] = [
  ERole.CAMPAIGN_COORDINATOR_DEALER,
  ERole.CAMPAIGN_COORDINATOR_DEALER_VIEWER,
  ERole.CAMPAIGN_COORDINATOR_IMPORTER,
  ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER,
  ERole.CAMPAIGN_COORDINATOR_BRAND,
  ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
  ERole.CAMPAIGN_AUTHOR,
  ERole.CAMPAIGN_AUTHOR_VIEWER,
  ERole.COORDINATOR_TOPIC,
  ERole.COORDINATOR_TOPIC_VIEWER,
  ERole.COORDINATOR_BRAND_OFFICE,
  ERole.COORDINATOR_BRAND_OFFICE_VIEWER,
  ERole.COORDINATOR_CLEARING,
  ERole.COORDINATOR_CLEARING_VIEWER,
  ERole.COMMITTEE_MEMBER,
  ERole.COMMITTEE_MEMBER_VIEWER,
];
