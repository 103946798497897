import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-arrow-right',
  templateUrl: './recall2-icon-arrow-right.component.html',
  styleUrls: ['./recall2-icon-arrow-right.component.scss'],
  standalone: true,
})
export class Recall2IconArrowRightComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i04-arrow-1-right');
  }
}
