<div class="rc2-overview-wrapper pad-bottom-erase">
  <h2 class="rc2-mar--bottom--xxl">{{'notifications.form.releaseModal.title'| translate}}</h2>
  <div class="flexible rc2-mar--bottom--m">
    <div>
      <p class="rc2-mar--bottom--m">
        {{ 'notifications.form.releaseModal.subtitle.part1' | translate}}
        <strong>{{ 'notifications.form.releaseModal.subtitle.part2' | translate}}</strong>
        {{ 'notifications.form.releaseModal.subtitle.part3' | translate}}
      </p>
      <recall2-select
        class="recall2-select"
        [isFormSubmitted]="pushedSubmit"
        [property]="selectManufacturerProperty"
      >
      </recall2-select>
    </div>

    <recall2-notification-warning class="recall2-notification-warning"></recall2-notification-warning>
  </div>
  <h4>{{'notifications.form.category.comments' | translate}}</h4>
  <recall2-notification-comment
    [isRequired]="false"
    [isFormSubmitted]="false"
    (onValueChanged)="setComment($event)"
  ></recall2-notification-comment>
  <recall2-comments-and-history
    #commentsAndHistoryComponent
    [objectType]="'NOTIFICATION'"
    [objectId]="notificationId$ | async"
    [commentsDisabled]="true"
  >
  </recall2-comments-and-history>
  <hr class="rc2-mar--bottom--m rc2-mar--top--m">
  <recall2-footnote-required class="rc2-display--block rc2-mar--bottom--4xl"></recall2-footnote-required>

  <recall2-floating-bar>
    <div class="actions">
      <recall2-button-tertiary
        class="rc2-mar--right--s"
        [iconClass]="'icon-i90-unselect'"
        (click)="onClose()"
      >
        {{'notifications.form.button.close' | translate}}
      </recall2-button-tertiary>
      <recall2-button-primary
        [iconClass]="'icon-i46-mail-summary'"
        (click)="onSubmit()"
      >
        {{'notifications.form.releaseModal.sendButton' | translate}}
      </recall2-button-primary>
    </div>
  </recall2-floating-bar>
</div>
