import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-urgency-four',
  templateUrl: './recall2-icon-urgency-four.component.html',
  styleUrls: ['./recall2-icon-urgency-four.component.scss'],
  standalone: true,
})
export class Recall2IconUrgencyFourComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i88-urgency-4');
  }
}
