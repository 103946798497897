import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '@recall2/ui/user-flow';

import type { IUserState, UserData } from './user.state';

const userStateSelector = createFeatureSelector<IUserState>('user');

export const UserSelectors = {
  root: userStateSelector,
  getUser: createSelector(userStateSelector, (state: IUserState) => state.user),
  isKnownUser: createSelector(
    userStateSelector,
    (state: IUserState) => new UserState(isKnownUser(state.user), isUserActive(state.user)),
  ),
};

const isUserActive = (user: UserData): boolean => (user != undefined ? user.active : false);
const isKnownUser = (user: UserData): boolean => {
  if (user === null) {
    return false;
  }
  if (user === undefined) {
    return;
  }
  return !user.unknownUser;
};
