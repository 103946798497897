import { NgFor, NgIf } from '@angular/common';
import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Recall2LoadingSpinnerComponent } from '../../../loading-spinner';
import type { ObjectEvent } from '../../models/object-event';
import { ObjectType } from '../../models/object-event';
import { ObjectHistoryService } from '../../services/object-history.service';
import { CommentInputComponent } from '../comment-input/comment-input.component';
import { ObjectEventComponent } from '../object-event/object-event.component';
import type { CommentsAndHistoryRefreshable } from './comments-and-history-refreshable';

@Component({
  selector: 'recall2-comments-and-history',
  templateUrl: './comments-and-history.component.html',
  styleUrls: ['./comments-and-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommentInputComponent, ObjectEventComponent, Recall2LoadingSpinnerComponent, NgFor, NgIf],
})
export class Recall2CommentsAndHistoryComponent implements OnChanges, CommentsAndHistoryRefreshable {
  showSpinner = false;
  items: ObjectEvent[] = [];
  @Input() commentsDisabled = false;
  @Input() objectType: ObjectType;
  @Input() objectId: number;
  @Input() commentRequired = false;
  @Input() isFormSubmitted = false;
  @Input() verificationTaskType: ObjectType | string;

  @ViewChild('commentInput')
  commentInput: CommentInputComponent;

  @Output() commentAdded = new EventEmitter<void>();

  constructor(
    private service: ObjectHistoryService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectId) {
      this.loadItems();
    }
  }

  loadItems(): void {
    if (!this.objectId) {
      return;
    }

    this.showSpinner = true;
    this.service.getItems(this.objectType, this.objectId, this.verificationTaskType).subscribe({
      next: (newItems: ObjectEvent[]) => {
        // TODO: BE is currently returning an array, but should be update to return a List with Security Wrapper.
        // this.items is default to an empty array for now to allow Notification to be sent to Überarbeiten.
        this.items = newItems ? newItems : [];
        this.showSpinner = false;
        this.cdr.markForCheck();
      },
      error: () => {
        this.items = [];
        this.showSpinner = false;
        this.cdr.markForCheck();
      },
    });
  }

  async onAddComment(comment: string): Promise<boolean> {
    return firstValueFrom(
      this.service.addComment(this.objectType, this.objectId, comment, this.verificationTaskType).pipe(
        tap(newItem => {
          this.items = [newItem, ...this.items];
          this.commentAdded.emit();
          this.cdr.markForCheck();
        }),
        map(() => true),
        catchError(_ => of(false)),
      ),
    );
  }

  refresh(): void {
    this.loadItems();
  }

  setFocus(): void {
    this.commentInput.setFocus();
    this.cdr.markForCheck();
  }

  async submitOpenComment(): Promise<boolean> {
    const value = this.commentInput.getInputValue();
    if (!value || value === '') {
      return false;
    }
    this.isFormSubmitted = true;
    if (!this.commentInput.isInputValid()) {
      return false;
    }
    this.commentInput.clearInput();
    return this.onAddComment(value);
  }
}
