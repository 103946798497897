import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import type { UserFlowConfig } from '@recall2/ui/user-flow';
import { KnownUserProvider, USER_FLOW_CONFIG } from '@recall2/ui/user-flow';

import { environment } from '../../../environments/environment';
import { AppKnownUserProviderService } from './app-known-user-provider.service';

const userFlowConfig = {
  unknownUserLink: environment.unknownUserUrl,
  inactiveRolesLink: environment.inactiveUserUrl,
} as UserFlowConfig;

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    {
      provide: KnownUserProvider,
      useClass: AppKnownUserProviderService,
    },
    { provide: USER_FLOW_CONFIG, useValue: userFlowConfig },
  ],
})
export class KnownUserProviderModule {}
