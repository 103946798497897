import type { EnvironmentProviders } from '@angular/core';
import { APP_INITIALIZER, makeEnvironmentProviders } from '@angular/core';
import { AppTranslationService, getLanguageLocale } from '@recall2/ui/i18n';
import { firstValueFrom } from 'rxjs';

import type { UserData } from '../user/store/user.state';
import { UserStoreService } from '../user/store/user.store.service';

export const appInitializer =
  (userStoreService: UserStoreService, appTranslationService: AppTranslationService) => async (): Promise<UserData> => {
    try {
      const user = await firstValueFrom(userStoreService.getUser()).catch(() => {
        return null;
      });
      const userLang = getLanguageLocale(user?.language);
      await appTranslationService.initAsync(userLang);

      return user;
    } catch (error) {
      console.error(error);
    }
  };

export function provideAuth(): EnvironmentProviders[] {
  return [
    makeEnvironmentProviders([
      {
        provide: APP_INITIALIZER,
        multi: true,
        useFactory: appInitializer,
        deps: [UserStoreService, AppTranslationService],
      },
    ]),
  ];
}
