export enum EManufacturerAffectedTableHeader {
  AFFECTED = 'affected',
  QUORUM = 'quorum',
  MANUFACTURER_NAME = 'manufacturerName',
  COMMENT = 'comment',
  TOPIC_ID = 'topicId',
  STATE = 'state',
  LINK_TOPIC = 'linkTopic',
  ROUTE_TO_TOPIC = 'routeToTopic',
}
