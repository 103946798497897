import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KnownUserProviderModule } from './known-user-provider/known-user-provider.module';
import { mainRoutes } from './main-routes';

@NgModule({
  declarations: [],
  imports: [
    KnownUserProviderModule,
    RouterModule.forRoot(mainRoutes, {
      preloadingStrategy: PreloadAllModules,
      useHash: false,
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule, StoreModule, EffectsModule],
})
export class RoutingModule {}
