<form [formGroup]="registerForm">
  <div class="row rc2-mar--bottom--xl rc2-align-items--flex-end">
    <div class="col-md-8">
      <recall2-textarea
        [id]="'notification-form-measure-field-' + MeasureFormField.DescriptionMeasureProd"
        [property]="fieldProperties[MeasureFormField.DescriptionMeasureProd]"
        [isFormSubmitted]="(currentFieldErrors$ | async).get(MeasureFormField.DescriptionMeasureProd)"
        [minRows]="6"
      >
      </recall2-textarea>
    </div>

    <div class="col-md-4">
      <div class="row spacing-between-checkbox-and-date">
        <recall2-three-step-checkbox
          class="recall2-three-step-checkbox"
          [id]="'notification-form-measure-field-' + MeasureFormField.MeasureImplemented"
          [property]="fieldProperties[MeasureFormField.MeasureImplemented]"
          (onChange)="updateNotificationMeasureImplemented()"
          [isFormSubmitted]="(currentFieldErrors$ | async).get(MeasureFormField.MeasureImplemented)"
          [labelAlignment]="labelAlignment.TOP"
        >
        </recall2-three-step-checkbox>
      </div>
      <div
        class="row"
        *ngIf="fieldProperties[MeasureFormField.MeasureImplemented].control.value === threeStepCheckboxStates.SELECTED"
      >
        <recall2-datepicker
          [id]="'notification-form-measure-field-' + MeasureFormField.ImplementationDate"
          [property]="fieldProperties[MeasureFormField.ImplementationDate]"
          [isFormSubmitted]="(currentFieldErrors$ | async).get(MeasureFormField.ImplementationDate)"
        >
        </recall2-datepicker>
      </div>
    </div>
  </div>
  <div class="row rc2-mar--bottom--xl rc2-align-items--flex-end">
    <div class="col-md-8">
      <recall2-textarea
        [id]="'notification-form-measure-field-' + MeasureFormField.DescriptionMeasureAfterSales"
        [property]="fieldProperties[MeasureFormField.DescriptionMeasureAfterSales]"
        [isFormSubmitted]="(currentFieldErrors$ | async).get(MeasureFormField.DescriptionMeasureAfterSales)"
        [minRows]="14"
      >
      </recall2-textarea>
    </div>
    <div class="col-md-4">
      <div class="row spacing-between-checkbox-and-date">
        <recall2-three-step-checkbox
          class="recall2-three-step-checkbox"
          [id]="'notification-form-measure-field-' + MeasureFormField.MeasureAvailable"
          [property]="fieldProperties[MeasureFormField.MeasureAvailable]"
          (onChange)="updateNotificationMeasureAvailable()"
          [isFormSubmitted]="(currentFieldErrors$ | async).get(MeasureFormField.MeasureAvailable)"
          [labelAlignment]="labelAlignment.TOP"
        >
        </recall2-three-step-checkbox>
      </div>
      <div
        class="row"
        *ngIf="fieldProperties[MeasureFormField.MeasureAvailable].control.value === threeStepCheckboxStates.SELECTED"
      >
        <recall2-datepicker
          [id]="'notification-form-measure-field-' + MeasureFormField.AvailabilityDate"
          [property]="fieldProperties[MeasureFormField.AvailabilityDate]"
          [isFormSubmitted]="(currentFieldErrors$ | async).get(MeasureFormField.AvailabilityDate)"
        >
        </recall2-datepicker>
      </div>
      <div class="row spacing-service spacing-between-checkbox-and-date">
        <recall2-three-step-checkbox
          class="recall2-three-step-checkbox"
          [id]="'notification-form-measure-field-' + MeasureFormField.WarehouseUpdated"
          [property]="fieldProperties[MeasureFormField.WarehouseUpdated]"
          (onChange)="updateNotificationWarehouseUpdated()"
          [isFormSubmitted]="(currentFieldErrors$ | async).get(MeasureFormField.WarehouseUpdated)"
          [labelAlignment]="labelAlignment.TOP"
        >
        </recall2-three-step-checkbox>
      </div>
      <div
        class="row"
        *ngIf="fieldProperties[MeasureFormField.WarehouseUpdated].control.value === threeStepCheckboxStates.SELECTED"
      >
        <recall2-input-text
          [id]="'notification-form-measure-field-' + MeasureFormField.WarehouseUpdateNumber"
          [property]="fieldProperties[MeasureFormField.WarehouseUpdateNumber]"
          [isFormSubmitted]="(currentFieldErrors$ | async).get(MeasureFormField.WarehouseUpdateNumber)"
        >
        </recall2-input-text>
      </div>
    </div>
  </div>
  <div class="row spacing-between-supplier-and-manufacturer rc2-mar--bottom--xl">
    <div class="col-md-3">
      <recall2-three-step-checkbox
        class="recall2-three-step-checkbox"
        [id]="'notification-form-measure-field-' + MeasureFormField.SupplierError"
        [property]="fieldProperties[MeasureFormField.SupplierError]"
        [labelAlignment]="labelAlignment.TOP"
        (onChange)="updateNotificationSupplierErrorUpdated()"
      >
      </recall2-three-step-checkbox>
    </div>
    <div
      *ngIf="fieldProperties[MeasureFormField.SupplierError].control.value === threeStepCheckboxStates.SELECTED"
      class="col-md-3"
    >
      <recall2-input-text
        [id]="'notification-form-measure-field-' + MeasureFormField.Supplier"
        [property]="fieldProperties[MeasureFormField.Supplier]"
      >
      </recall2-input-text>
    </div>
  </div>

  <div class="row spacing-between-manufacturer-and-required">
    <div class="col-md-8">
      <form-brand-selector
        [currentNotification]="currentNotification"
        [allowMultipleSelections]="true"
        [disablePreSelections]="false"
        [updateAssignedList]="false"
        [preSelectedBrandManufacturers]="currentNotification.brandManufacturers"
        (updateBrandManufacturers)="updateBrandManufacturers($event)"
        [submitted]="(currentFieldErrors$ | async).get(MeasureFormField.BrandManufacturers)"
      >
      </form-brand-selector>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4 rc2-font--body--s rc2-color--grey-900 rc2-pad--bottom--4xl">
      <p>*) {{ 'notifications.form.requiredField.hint' | translate }}</p>
    </div>
  </div>
</form>
