export class DatePickerDay {
  readonly date: Date;
  readonly day: number;
  readonly isToday: boolean;

  constructor(
    date: Date,
    public isDisabled: boolean,
    public isSelected: boolean,
    public isLastMonth: boolean = false,
  ) {
    this.date = new Date(date.valueOf());
    this.day = this.date.getDate();
    this.isToday = this.date.toDateString() === new Date().toDateString();
  }
}
