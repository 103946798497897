import type { AfterContentInit, OnInit } from '@angular/core';
import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[recall2MinRowSize]',
  standalone: true,
})
export class Recall2MinRowSizeDirective implements AfterContentInit, OnInit {
  @Input() minRows: number;

  private textAreaElement: HTMLTextAreaElement;

  constructor(public element: ElementRef) {}

  ngOnInit(): void {
    this.setTextAreaElement();
  }

  ngAfterContentInit(): void {
    this.adjustTextAreaHeight();
  }

  private setTextAreaElement(): void {
    if (this.element.nativeElement.tagName !== 'TEXTAREA') {
      console.warn('recall2-autosize: directive is not assigned to textarea element');
      return;
    }

    this.textAreaElement = this.element.nativeElement;
  }

  private adjustTextAreaHeight(): void {
    if (!this.textAreaElement) {
      return;
    }

    const lineHeight = this.getLineHeight();
    let height = this.textAreaElement.scrollHeight;

    const rowsCount = height / lineHeight;

    if (this.minRows && this.minRows >= rowsCount) {
      height = this.minRows * lineHeight;
    }

    const styleAttribute = `height: ${height}px`;

    this.textAreaElement.setAttribute('style', styleAttribute);
  }

  private getLineHeight(): number {
    let lineHeight = Number.parseInt(this.textAreaElement.style.lineHeight, 10);

    if (Number.isNaN(lineHeight) && window.getComputedStyle) {
      const styles = window.getComputedStyle(this.textAreaElement);
      lineHeight = Number.parseInt(styles.lineHeight, 10);
    }

    return lineHeight;
  }
}
