import { Injectable } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SafeNavigationService {
  constructor(private router: Router) {}

  handleNavigationErrors(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationError)).subscribe(() => {
      this.router.navigate(['']);
    });
  }
}
