import { ActivityGroupName, ActivityType } from '../enums';
import type { ActivityData, ActivityOverview } from '../models';

const createActivityMock = (activityData: ActivityData): ActivityOverview => {
  return {
    author: {
      id: '8846b102-f9d5-6c25-9815-65321d2c8df4',
      vwUserId: 'EZMZIMR',
      firstName: 'Tester12',
      lastName: 'Account12',
    },
    happenedOn: '07.11.2022 - 12:55',
    groupId: '12',
    groupName: ActivityGroupName.Other,
    activities: [activityData],
  };
};

export const activityOverviewLabelMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbef7',
  name: ActivityType.CampaignDataLabelCreated,
  data: {
    label: 'test label',
  },
});

export const activityOverviewAuthorityNumberMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbef6',
  name: ActivityType.AuthorityNumberCreated,
  data: {
    authorityNumber: '124231SDS3',
    countryName: 'Germany',
  },
});

export const activityOverviewCountryExceptionMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbef2',
  name: ActivityType.CampaignCountryExceptionRuleRemoved,
  data: {
    country: 'DEU',
  },
});

export const activityOverviewCriterionTitleMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbee32',
  name: ActivityType.CriterionTitlesChanged,
  data: {
    criterionCode: 'T2',
    newTitles: [
      {
        language: 'el-GR',
        title: 'hfghfg',
      },
      {
        language: 'en-US',
        title: 'test',
      },
    ],
  },
});

export const activityOverviewCriterionTitleDeletedMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbee32',
  name: ActivityType.CriterionTitlesDeleted,
  data: {
    criterionCode: 'T2',
    oldTitles: [
      {
        language: 'el-GR',
        title: 'hfghfg',
      },
      {
        language: 'en-US',
        title: 'test',
      },
    ],
  },
});

export const activityOverviewCampaignTitleMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbee32',
  name: ActivityType.CampaignTitlesChangedEvent,
  data: {
    newTitles: [
      {
        language: 'el-GR',
        title: 'hfghfg',
      },
      {
        language: 'en-US',
        title: 'test',
      },
    ],
  },
});

export const activityOverviewCampaignTitleDeletedMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbee32',
  name: ActivityType.CampaignTitlesDeletedEvent,
  data: {
    oldTitles: [
      {
        language: 'el-GR',
        title: 'hfghfg',
      },
      {
        language: 'en-US',
        title: 'test',
      },
    ],
  },
});

export const activityOverviewWrongDateMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbef8',
  name: ActivityType.CampaignGlobalConfigurationStartDateChanged,
  data: {
    dueTo: {
      oldValue: '2021-12-24',
      newValue: '2021-12-23',
    },
  },
});

export const activityOverviewGlobalDateMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbef8',
  name: ActivityType.CampaignGlobalConfigurationStartDateChanged,
  data: {
    startDate: {
      oldValue: '2021-12-24',
      newValue: '2021-12-23',
    },
  },
});

export const activityOverviewCountryExceptionDateMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbef8',
  name: ActivityType.CampaignCountryExceptionRuleClaimEndDateChanged,
  data: {
    claimDate: {
      oldValue: '2021-12-24',
      newValue: '2021-12-23',
    },
    countryName: 'DEU',
  },
});

export const activityOverviewCountryExceptionDateWithCommentMock: ActivityOverview = createActivityMock({
  id: '1a96ecbf-693f-404b-a44f-8da45a0bbef8',
  name: ActivityType.CampaignCountryExceptionRuleStartDateChanged,
  data: {
    claimDate: {
      oldValue: '2021-12-24',
      newValue: '2021-12-23',
    },
    countryName: 'DEU',
    comment: 'test comment',
  },
});
