import { InjectionToken } from '@angular/core';

import type { Recall2NewRelicSetting } from '../models/recall2-new-relic-setting';

export const RECALL2_NEW_RELIC_SETTING_TOKEN = new InjectionToken<Recall2NewRelicSetting>(
  'RECALL2_NEW_RELIC_SETTING_TOKEN',
  {
    factory: (): Recall2NewRelicSetting => ({
      accountID: '',
      trustKey: '',
      agentID: '',
      licenseKey: '',
      applicationID: '',
    }),
  },
);
