import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { EThreeStepCheckboxStates } from '../model';

export const ThreeStepCheckboxValidators = {
  requiredSelectedOrDeselected: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalid =
        !control.value ||
        control.value === EThreeStepCheckboxStates.UNSELECTED ||
        control.value === EThreeStepCheckboxStates.MIXED;
      return invalid ? { ThreeStepCheckboxValidators: { requiredSelectedOrDeselected: true } } : null;
    };
  },
};
