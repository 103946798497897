import type { OnInit } from '@angular/core';
import { Directive, HostListener, Optional } from '@angular/core';

import { TableSortComponent } from '../../components/table-sort/table-sort.component';
import { TableSortDirective } from './table-sort.directive';

@Directive({
  selector: '[appTableSortHeader]',
  standalone: true,
})
export class TableSortHeaderDirective implements OnInit {
  @HostListener('click') onClick(): void {
    this.tableSortDirective.sort(this);
  }

  constructor(
    @Optional() public tableSortComponent: TableSortComponent,
    @Optional() public tableSortDirective: TableSortDirective,
  ) {}

  ngOnInit(): void {
    this.tableSortDirective.registerSortHeaderDirective(this);
  }
}
