import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'commaList',
  standalone: true,
})
export class CommaListPipe implements PipeTransform {
  transform(list: string[] | number[] | Date[] | boolean[]): string {
    return list.join(', ');
  }
}
