import { NgClass, NgIf } from '@angular/common';
import type { AfterViewInit, OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import type { WithTableService } from '../../../../tables/models/recall2-table';
import { TextCellComponent } from '../text-cell/text-cell.component';

@Component({
  selector: 'text-cell',
  templateUrl: './decision-cell.component.html',
  standalone: true,
  imports: [NgClass, NgIf, TranslateModule],
})
export class DecisionTextCellComponent
  extends TextCellComponent
  implements IDecisionTextCellBinding, AfterViewInit, WithTableService, OnDestroy
{
  propertyName: string;
  additionalCssClasses?: string[];
  optionPropertyName: string;
}

export class DecisionTextCellBinding implements IDecisionTextCellBinding {
  constructor(
    public propertyName: string,
    public optionPropertyName?: string,
    public additionalCssClasses?: string[],
  ) {}
}

interface IDecisionTextCellBinding {
  propertyName: string;
  optionPropertyName?: string;
  additionalCssClasses?: string[];
}
