import { DatePipe } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import type { IRecall2FilterParam } from '../../overlay/models/filter.model';
import { EDateFilterComparator } from '../../overlay/models/filter.model';

@Pipe({
  name: 'filterOptionDateKey',
  standalone: true,
})
export class FilterOptionDateKeyPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe,
    private translateService: TranslateService,
  ) {}
  transform(filterParam: IRecall2FilterParam): Observable<string> {
    let translationKey = ' ';
    if (filterParam.comparator === EDateFilterComparator.NEWER) {
      translationKey = 'active-filters.from-date';
    } else if (filterParam.comparator === EDateFilterComparator.OLDER) {
      translationKey = 'active-filters.until-date';
    }

    return this.translateService.stream(translationKey).pipe(
      map(label => {
        const mappedValues = filterParam.value.map(val => this.datePipe.transform(val, 'dd.MM.yyyy')).join(' - ');
        return `${label} ${mappedValues}`;
      }),
    );
  }
}
