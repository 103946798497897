<div class="title">
  <h5 data-cy="modalTitle">
    {{ data.title | translate }}
  </h5>
  <recall2-svg-icon
    name="close"
    size="medium"
    class="rc2-icon--color--primary"
    (click)="onCancel()"
  >
  </recall2-svg-icon>
</div>
<div class="rc2-font--body--m">
  <p
    data-cy="modalContent"
    class="rc2-mar--right--5xl"
  >
    {{ data.content | translate }}
  </p>
</div>
<div class="buttons rc2-mar--top--5xl">
  <recall2-button-tertiary
    data-cy="cancelButton"
    class="rc2-mar--right--s"
    (click)="onCancel()"
  >
    <span>{{ data.cancelButtonKey | translate }}</span>
  </recall2-button-tertiary>
  <recall2-button-primary
    [ngClass]="{ 'delete-button': data.isDelete }"
    data-cy="submitButton"
    (click)="onConfirm($event)"
  >
    <span>{{ data.submitButtonKey | translate }}</span>
  </recall2-button-primary>
</div>
