<div class="rc2-line--height--0">
  <recall2-icon-link
    id="link-icon"
    class="rc2-icon--size--30 rc2-icon--color--blue rc2-icon--cursor--pointer"
    recall2AppsLink
    [targetApp]="targetApp"
    [urlPath]="urlPath"
    [queryParams]="{ clearingId: currentClearingId, manufacturerId: currentManufacturerId }"
    [openInNewTab]="false"
    *ngIf="data[propertyName] === propertyState"
  >
  </recall2-icon-link>
</div>
