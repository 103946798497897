import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';

const BASE_LEVEL_TRANSLATION_KEY = 'general.level.';
const BASE_ROLE_TRANSLATION_KEY = 'shared.role.';
const BASE_MANUFACTURER_TRANSLATION_KEY = 'general.manufacturerId.';

@Pipe({
  name: 'userRoles',
  standalone: true,
})
export class UserRolesPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): Observable<string> {
    const [level, manufacturer, role] = value.split(':');
    const levelKey = `${BASE_LEVEL_TRANSLATION_KEY}${level}`;
    const roleKey = `${BASE_ROLE_TRANSLATION_KEY}${role.toLowerCase().replace(/_/g, '.')}`;

    const keys = [levelKey, roleKey];
    if (manufacturer !== 'null') {
      keys.push(`${BASE_MANUFACTURER_TRANSLATION_KEY}${manufacturer}`);
    }

    return this.translateService.stream(keys).pipe(
      map(translations => {
        const [levelTranslated, roleTranslated, manufacturerTranslated] = Object.values(translations);
        const manufacturerFormatted = manufacturerTranslated ? ` - ${manufacturerTranslated}` : '';
        return `${levelTranslated}${manufacturerFormatted} - ${roleTranslated}`;
      }),
    );
  }
}
