import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import type {
  EAutocompleteFilterComparator,
  EBooleanFilterComparator,
  ESelectFilterComparator,
  ETextFilterComparator,
  EUserRolesFilterComparator,
  IRecall2DynamicFilterGroup,
  IRecall2FilterDefinition,
  IRecall2FilterItem,
  IRecall2FilterParam,
  IRecall2FilterRadioConfig,
  IRecall2FilterSelectV2Config,
  Recall2FilterV2Config,
} from '../../overlay/models/filter.model';
import {
  EBackendFilterTemplates,
  EDateFilterComparator,
  EFilterTemplates,
  ENumberFilterComparator,
  IRecall2Comparator,
  Recall2FilterIconParam,
} from '../../overlay/models/filter.model';
import { compareObjects } from '../../utils';

export class Recall2TableFilterService {
  private _latestChangedFilter: BehaviorSubject<IRecall2FilterParam> = new BehaviorSubject(null);
  private _triggerFilterIcon: BehaviorSubject<Recall2FilterIconParam> = new BehaviorSubject(
    new Recall2FilterIconParam(true, '', false),
  );
  private _activeFilters: BehaviorSubject<IRecall2FilterParam[]> = new BehaviorSubject(null);
  private _searchString: BehaviorSubject<string> = new BehaviorSubject('');
  private _body: BehaviorSubject<IRecall2FilterItem[]> = new BehaviorSubject([]);
  private _moreFiltersConfig = new BehaviorSubject<Recall2FilterV2Config[]>(null);

  activeFilterResults: IRecall2FilterParam[] = [];
  searchString = '';
  body: IRecall2FilterItem[] = [];
  tableIdentifier: string;

  constructor(@Inject(LOCALE_ID) private locale: string) {}

  getTableId(): string {
    return this.tableIdentifier;
  }

  setTableId(value: string): void {
    this.tableIdentifier = value;
  }

  setActiveFilterResults(value: IRecall2FilterParam[]): void {
    this.activeFilterResults = value;
    this.searchString = this.setFilterForHttpParam(this.activeFilterResults);
    this.body = this.getBodyFilters();
    this._searchString.next(this.searchString);
    this._body.next(this.body);
    this._activeFilters.next(this.activeFilterResults);
  }

  getActiveFilters(): BehaviorSubject<IRecall2FilterParam[]> {
    return this._activeFilters;
  }

  getSearchString(): BehaviorSubject<string> {
    return this._searchString;
  }

  getBody(): BehaviorSubject<IRecall2FilterItem[]> {
    return this._body;
  }

  getLatestChangedFilter(): BehaviorSubject<IRecall2FilterParam> {
    return this._latestChangedFilter;
  }

  setLatestChangedFilter(value: IRecall2FilterParam): void {
    this._latestChangedFilter.next(value);
  }

  onTriggerFilterIcon(): BehaviorSubject<Recall2FilterIconParam> {
    return this._triggerFilterIcon;
  }

  setMoreFiltersConfig(moreFiltersConfig: Recall2FilterV2Config[]): void {
    this._moreFiltersConfig.next(moreFiltersConfig);
  }

  getMoreFiltersConfig(): BehaviorSubject<Recall2FilterV2Config[]> {
    return this._moreFiltersConfig;
  }

  setFilterForHttpParam(activeFilterResults): string {
    let newSearchString = '';
    activeFilterResults.forEach(item => {
      switch (item.type) {
        case EFilterTemplates.NUMBER: {
          newSearchString = this.concatNumberFilter(newSearchString, item);
          break;
        }
        case EFilterTemplates.TEXT: {
          newSearchString = this.concatTextFilter(newSearchString, item);
          break;
        }
        case EFilterTemplates.DATE: {
          newSearchString = this.concatDateFilter(newSearchString, item);
          break;
        }
        case EFilterTemplates.SELECT: {
          newSearchString = this.concatSelectFilter(newSearchString, item);
          break;
        }
      }
    });
    return newSearchString;
  }

  getBodyFilters(): IRecall2FilterItem[] {
    const body: IRecall2FilterItem[] = [];
    if (this.activeFilterResults && this.activeFilterResults.length > 0) {
      this.activeFilterResults.forEach(item => {
        if (item.value && item.value.length > 0) {
          switch (item.type) {
            case EFilterTemplates.TEXT:
            case EFilterTemplates.SELECT:
            case EFilterTemplates.BOOLEAN:
            case EFilterTemplates.RADIO:
              body.push(this.getBodyItem(item, item.value));
              break;
            case EFilterTemplates.NUMBER:
              body.push(this.getBodyItem(item, item.value, this.mapComparator(item.comparator)));
              break;
            case EFilterTemplates.DATE:
              body.push(...this.getDateFilter(item));
              break;
          }
        }
      });
    }

    return body;
  }

  concatNumberFilter(searchString: string, item: IRecall2FilterParam): string {
    if (item.value[0] !== undefined) {
      switch (item.comparator) {
        case ENumberFilterComparator.EQUALS: {
          searchString = item.identifier + ':' + item.value[0] + ';';

          break;
        }
        case ENumberFilterComparator.LESS: {
          searchString = item.identifier + '<' + item.value[0] + ';';

          break;
        }
        case ENumberFilterComparator.GREATER: {
          searchString = item.identifier + '>' + item.value[0] + ';';

          break;
        }
        // No default
      }
    }
    return searchString;
  }

  concatTextFilter(searchString: string, item: IRecall2FilterParam): string {
    if (item.value[0]) {
      item.value.forEach(val => {
        searchString += item.identifier + ':' + val + ';';
      });
    }
    return searchString;
  }

  concatDateFilter(searchString: string, item: IRecall2FilterParam): string {
    if (item.value[0]) {
      switch (item.comparator) {
        case EDateFilterComparator.OLDER: {
          searchString += item.identifier + '<' + this.castDateToYearMonthDay(item.value[0].toString()) + ';';

          break;
        }
        case EDateFilterComparator.NEWER: {
          searchString += item.identifier + '>' + this.castDateToYearMonthDay(item.value[0].toString()) + ';';

          break;
        }
        case EDateFilterComparator.BETWEEN: {
          item.value.forEach((_val, index) => {
            if (index === 0) {
              const greaterDate = new Date(item.value[index] as Date);
              greaterDate.setDate(greaterDate.getDate());
              searchString += item.identifier + '>=' + this.castDateToYearMonthDay(greaterDate.toString());
            } else if (index === 1) {
              const lessDate = new Date(item.value[index] as Date);
              lessDate.setDate(lessDate.getDate() + 1);
              searchString += ';' + item.identifier + '<' + this.castDateToYearMonthDay(lessDate.toString()) + ';';
            }
          });

          break;
        }
        // No default
      }
    }
    return searchString;
  }

  castDateToYearMonthDay(value: string): string {
    return new DatePipe(this.locale).transform(value, 'yyyy-MM-dd');
  }

  concatSelectFilter(searchString: string, item: IRecall2FilterParam): string {
    if (item.value && item.value.length > 0) {
      const { identifier, value } = item;
      searchString += `${identifier}:${value.join(',')};`;
    }
    return searchString;
  }

  updateFilter(result: IRecall2FilterParam): void {
    this.setLatestChangedFilter(result);
    if (result.value.length === 0) {
      this._triggerFilterIcon.next(new Recall2FilterIconParam(false, result.identifier, false));
    }
  }

  updateActiveFilters(activeFiltersFromSummary: IRecall2FilterParam[]): void {
    if (activeFiltersFromSummary) {
      activeFiltersFromSummary.forEach(aFilter => {
        const index = this.activeFilterResults.findIndex(it => it.identifier === aFilter.identifier);

        if (index >= 0) {
          this.activeFilterResults[index].comparator = aFilter.comparator;
          this.activeFilterResults[index].value = aFilter.value;
          this.activeFilterResults[index].labels = aFilter.labels;
          this._triggerFilterIcon.next(
            new Recall2FilterIconParam(true, this.activeFilterResults[index].identifier, true),
          );
        } else {
          // Scenario when cached filter config belongs to overlay extra filters

          this.activeFilterResults.push(aFilter);
        }

        const newSearchString = this.setFilterForHttpParam(this.activeFilterResults);
        if (newSearchString !== this.searchString) {
          this.searchString = newSearchString;
          this._searchString.next(this.searchString);
        }
      });
      this.checkAndEmitBody();
    }
  }

  removeValueOfFilter(filterParamId: string, textValue?: string): void {
    const filterParamToRemove = this.activeFilterResults.find(filterParam => filterParam.identifier === filterParamId);

    if (filterParamToRemove.type === EFilterTemplates.TEXT) {
      // eslint-disable-next-line unicorn/prefer-array-index-of
      const valueToRemoveIndex = filterParamToRemove.value.findIndex(oneValue => oneValue === textValue);

      const newValue = [...filterParamToRemove.value];
      newValue.splice(valueToRemoveIndex, 1);

      if (filterParamToRemove.labels) {
        const newLabels = [...filterParamToRemove.labels];
        newLabels.splice(valueToRemoveIndex, 1);
        this.updateFilter({ ...filterParamToRemove, value: newValue as string[], labels: newLabels });
      } else {
        this.updateFilter({ ...filterParamToRemove, value: newValue as string[] });
      }
    } else {
      this.updateFilter({ ...filterParamToRemove, value: [] });
    }
  }

  clearActiveFilters(activeFiltersFromSummary: IRecall2FilterParam[]): void {
    if (activeFiltersFromSummary) {
      activeFiltersFromSummary.forEach(aFilter => {
        const index = this.activeFilterResults.findIndex(it => it.identifier === aFilter.identifier);
        switch (this.activeFilterResults[index].type) {
          case EFilterTemplates.NUMBER:
            this.activeFilterResults[index].comparator = ENumberFilterComparator.EQUALS;
            break;
          case EFilterTemplates.DATE:
            this.activeFilterResults[index].comparator = EDateFilterComparator.BETWEEN;
            break;
        }
        this.activeFilterResults[index].value = [];
        this._triggerFilterIcon.next(
          new Recall2FilterIconParam(false, this.activeFilterResults[index].identifier, false),
        );
        const newSearchString = this.setFilterForHttpParam(this.activeFilterResults);
        if (newSearchString !== this.searchString) {
          this.searchString = newSearchString;
          this._searchString.next(this.searchString);
        }
      });
      this.checkAndEmitBody();
    }
  }

  checkAndEmitBody(): void {
    const newBody = this.getBodyFilters();
    if (!compareObjects(this.body, newBody)) {
      this.body = newBody;
      this._body.next(this.body);
    }
  }

  private getBodyItem(
    item,
    value: string[] | number[] | Date[] | boolean[],
    comparator: IRecall2Comparator = IRecall2Comparator.EQUAL,
  ): IRecall2FilterItem {
    return { identifier: item.identifier, value: value, comparator };
  }

  private mapComparator(
    comparator:
      | EDateFilterComparator
      | ENumberFilterComparator
      | ETextFilterComparator
      | ESelectFilterComparator
      | EBooleanFilterComparator
      | EAutocompleteFilterComparator
      | EUserRolesFilterComparator,
  ): IRecall2Comparator {
    switch (comparator) {
      case ENumberFilterComparator.EQUALS: {
        return IRecall2Comparator.EQUAL;
      }
      case ENumberFilterComparator.LESS:
      case EDateFilterComparator.OLDER: {
        return IRecall2Comparator.LOWER;
      }
      case ENumberFilterComparator.GREATER:
      case EDateFilterComparator.NEWER: {
        return IRecall2Comparator.GREATER;
      }
      // No default
    }
  }

  private getDateFilter(item): IRecall2FilterItem[] {
    switch (item.comparator) {
      case EDateFilterComparator.OLDER:
      case EDateFilterComparator.NEWER:
        return [
          this.getBodyItem(
            item,
            [this.castDateToYearMonthDay(item.value[0].toString())],
            this.mapComparator(item.comparator),
          ),
        ];
      case EDateFilterComparator.BETWEEN:
        let minDate: string;
        let maxDate: string;
        item.value.forEach((_, index) => {
          const date = new Date(item.value[index]);
          if (index === 0) {
            date.setDate(date.getDate());
            minDate = this.castDateToYearMonthDay(date.toString());
          } else if (index === 1) {
            date.setDate(date.getDate() + 1);
            maxDate = this.castDateToYearMonthDay(date.toString());
          }
        });

        const greaterItem: IRecall2FilterItem = this.getBodyItem(item, [minDate], IRecall2Comparator.GREATER_EQUAL);
        const lessItem: IRecall2FilterItem = this.getBodyItem(item, [maxDate], IRecall2Comparator.LOWER);
        return [greaterItem, lessItem];
    }
  }

  mapExtraFilters(filterGroupsList: IRecall2DynamicFilterGroup[]): Recall2FilterV2Config[] {
    if (!filterGroupsList) {
      return [];
    }

    const filtersList: Recall2FilterV2Config[] = [];

    filterGroupsList.forEach(group => {
      if (group.filterDefinitions) {
        group.filterDefinitions.forEach(filterDefinition =>
          filtersList.push(this.mapFilterDefinition(filterDefinition, group.translationKey)),
        );
      }
    });

    return filtersList;
  }

  private mapFilterDefinition(
    filterDefinition: IRecall2FilterDefinition,
    groupTranslationKey: string,
  ): Recall2FilterV2Config {
    const { identifier, type, translationKey, values: itemsList, allowedComparators } = filterDefinition;

    const filterConfig: Partial<Recall2FilterV2Config> = { identifier, translationKey, allowedComparators };

    switch (type) {
      case EBackendFilterTemplates.NUMBER:
        filterConfig.type = EFilterTemplates.NUMBER;
        break;
      case EBackendFilterTemplates.TEXT:
        filterConfig.type = EFilterTemplates.TEXT;
        break;
      case EBackendFilterTemplates.DATE:
        filterConfig.type = EFilterTemplates.DATE;
        break;
      case EBackendFilterTemplates.BOOLEAN:
        filterConfig.type = EFilterTemplates.BOOLEAN;
        (filterConfig as IRecall2FilterRadioConfig).itemsList =
          itemsList && itemsList.length > 0
            ? itemsList.map(item => ({ translationKey: item.value, value: item.key }))
            : [
                { translationKey: 'common.filter.yes', value: 'true' },
                { translationKey: 'common.filter.no', value: 'false' },
              ];
        break;
      case EBackendFilterTemplates.RADIO:
        filterConfig.type = EFilterTemplates.RADIO;
        // TODO TO DELETE. VALUES WILL COME FROM BACKEND
        (filterConfig as IRecall2FilterRadioConfig).itemsList =
          itemsList && itemsList.length > 0
            ? itemsList.map(item => ({ translationKey: item.value, value: item.key }))
            : [
                { translationKey: 'common.filter.yes', value: 'true' },
                { translationKey: 'common.filter.no', value: 'false' },
              ];
        break;
      case EBackendFilterTemplates.SELECT:
        filterConfig.type = EFilterTemplates.SELECT;
        (filterConfig as IRecall2FilterSelectV2Config).itemsList = itemsList
          ? itemsList.map(item => ({ translationKey: item.value, value: item.key }))
          : [];
        (filterConfig as IRecall2FilterSelectV2Config).selectedItemsIds = [];
        (filterConfig as IRecall2FilterSelectV2Config).placeholderKey = translationKey;
        break;
      default:
        break;
    }

    filterConfig.group = { key: groupTranslationKey, translationKey: groupTranslationKey };

    return filterConfig as Recall2FilterV2Config;
  }
}
