<mat-button-toggle-group
  [value]="selectedValue"
  [ngClass]="{'range-switcher-mode': isRangeSwitcherMode}"
>
  <mat-button-toggle
    *ngFor="let el of toggleElements"
    [value]="el.value"
    (change)="onChangeValue($event.value)"
  >
    <recall2-svg-icon
      *ngIf="el.icon"
      class="rc2-color--primary"
      [name]="el.icon"
      [size]="el.size"
    ></recall2-svg-icon>
    <span class="button-content rc2-pad--left--3xs">{{el.label}}</span>
  </mat-button-toggle>
</mat-button-toggle-group>
