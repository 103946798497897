import type { BrandManufacturer } from '@recall2/ui/brand-manufacturer';
import type { IPart } from '@recall2/ui/core/models';
import type { DueDate } from '@recall2/ui/deadline';
import type { EThreeStepCheckboxStates } from '@recall2/ui/form/model';
import type { InvolvedUser } from '@recall2/user';

import type { UserData } from '../../../user/store/user.state';
import type { IVehicleRange } from '../vehicle.range';
import type { ActionPermissions } from './action-permission';
import type { NotificationStatus } from './notification-status.enum';

export interface INotificationForList {
  createdAt?: Date;
  receivedDate?: Date;
  dueTo?: DueDate;
  id?: number;
  role?: string;
  status?: NotificationStatus;
  title?: string;
  users?: INotificationEditors;
  userFirstName?: string;
  userLastName?: string;
  isExpanded?: boolean;
  attachmentCount?: number;
  linkCount?: number;
  type?: string;
  actionPermissions?: ActionPermissions;
}

export interface INotification {
  id?: number;
  title?: string;
  status?: NotificationStatus;
  parts?: IPart[];
  issue?: string;
  cause?: string;
  effect?: string;
  createdAt?: Date;
  descriptionMeasureProd?: string;
  descriptionMeasureAfterSales?: string;
  measureImplemented?: EThreeStepCheckboxStates;
  implementationDate?: Date;
  measureAvailable?: EThreeStepCheckboxStates;
  availabilityDate?: Date;
  warehouseUpdated?: EThreeStepCheckboxStates;
  warehouseUpdateNumber?: string;
  supplierError?: EThreeStepCheckboxStates;
  supplier?: string;
  brandManufacturers?: BrandManufacturer[];
  vehiclesAffected?: string;
  prodDateRangesAffected?: IProdDatePeriod[];
  vehicleRange?: IVehicleRange[];
  affectedVehiclesCount?: string;
  marketsAndRegions?: string;
  commentVehicles?: string;
  reviewers?: UserData[];
  reporter?: UserData;
  involvedUsers?: InvolvedUser[];
  assignedBrandManufacturers?: BrandManufacturer[];
}

export interface IProdDatePeriod {
  id?: number;
  firstProdDateAffected?: string;
  lastProdDateAffected?: string;
}

export interface INotificationEditors {
  reporter: INotificationEditor;
  activeEditor: INotificationEditor;
}

export interface INotificationEditor {
  vwUserId: string;
  firstName: string;
  lastName: string;
  brandManufacturerId: number;
  manufacturer: string;
  role: UserRole;
}

export interface IUpdateOverview {
  notification: INotificationForList;
  action: EOpenOrHighlight;
}

export interface ICreatedAt {
  createdAt: Date;
}

export enum EOpenOrHighlight {
  OPEN,
  HIGHLIGHT,
}

export enum UserRole {
  REPORTER = 'REPORTER',
  REVIEWER = 'REVIEWER',
  COORDINATOR_BRAND_OFFICE = 'COORDINATOR_BRAND_OFFICE',
}

export enum ModalType {
  REWORK = 'REWORK',
  REVISE_BRAND_OFFICE = 'REVISE_BRAND_OFFICE',
  RELEASE = 'RELEASE',
  FORWARD_MANUFACTURER = 'FORWARD_MANUFACTURER',
}
