<div
  class="rc2-ellipsis"
  *ngIf="data && subjectArea && !data.isEditable"
  [title]="data[subjectArea]"
>
  <span
    *ngIf="showExpertUser()"
    [title]="data[subjectArea]"
    data-testid="expert"
  >{{ data[subjectArea] }}</span>
  <span
    *ngIf="!showExpertUser()"
    [title]="getTranslationKey(data[role]) | translate"
    data-testid="none-expert"
  >
    {{getTranslationKey(data[role]) | translate}}
  </span>
</div>
<div
  *ngIf="data?.isEditable"
  class="rc2-mar--right--xxs"
>
  <recall2-input-text
    [property]="subjectAreaInput"
    (onChange)="updateSubjectArea()"
  ></recall2-input-text>
</div>
