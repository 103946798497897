import { App, createFullyQualifiedUrl } from '@recall2/ui/navigation';

export const baseEnvironment = {
  timeoutUntilSyncForAcceptedNotificationFromTopic: 500,
  apiUrlNotification: '/api-preliminary-notification',
  apiUrlUser: '/api-preliminary-user',
  apiUrlBrand: '/api-preliminary-brand',
  apiUrlAttachment: '/api-preliminary-attachment',
  apiUrlDownloadAps: '/api-preliminary-download-aps',
  jumpAddress: {
    topic: '/topic',
    apiUrlTopicService: '/api-topic-topic',
  },
  NOTIFICATION_APP_OVERVIEW_URI: '/notifications',
  unknownUserUrl: createFullyQualifiedUrl(App.Preliminary, '/landing'),
  inactiveUserUrl: createFullyQualifiedUrl(App.Preliminary, '/myserve'),
};
