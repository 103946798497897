<form [formGroup]="registerForm">
  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-4">
      <recall2-input-text
        id="notification-form-description-title-input"
        data-cy="notification-form-description-title-input"
        [property]="fieldProperties[DescriptionFormField.Title]"
        [isFormSubmitted]="(currentFieldErrors$ | async).get(DescriptionFormField.Title)"
      ></recall2-input-text>
    </div>
  </div>

  <editable-input-table
    id="partsTable"
    [initialData]="currentNotification.parts"
    [keys]="partsKeys"
    (tableDataChange)="onPartsTableDataChange($event)"
    [translations]="tableTranslations"
    [tableType]="tableType"
    [disabled]="true"
    [isFormSubmitted]="(currentFieldErrors$ | async).get('parts')"
  >
  </editable-input-table>

  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-8">
      <recall2-textarea
        id="notification-form-description-issue-input"
        [minRows]="defaultMinRows"
        [property]="fieldProperties[DescriptionFormField.Issue]"
        [isFormSubmitted]="(currentFieldErrors$ | async).get(DescriptionFormField.Issue)"
      ></recall2-textarea>
    </div>
  </div>

  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-8">
      <recall2-textarea
        id="notification-form-description-cause-input"
        [minRows]="defaultMinRows"
        [property]="fieldProperties[DescriptionFormField.Cause]"
        [isFormSubmitted]="(currentFieldErrors$ | async).get(DescriptionFormField.Cause)"
      ></recall2-textarea>
    </div>
  </div>

  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-8">
      <recall2-textarea
        id="notification-form-description-effect-input"
        [minRows]="defaultMinRows"
        [property]="fieldProperties[DescriptionFormField.Effect]"
        [isFormSubmitted]="(currentFieldErrors$ | async).get(DescriptionFormField.Effect)"
      ></recall2-textarea>
    </div>
  </div>

  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-4 rc2-font--body--s rc2-color--grey-900">
      <p>*) {{ "notifications.form.requiredField.hint" | translate }}</p>
    </div>
  </div>
</form>
