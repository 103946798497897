import type { ConnectedPosition } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EOverlayTemplates, Recall2OverlayComponent } from '@recall2/ui/overlay';

import { AttachmentType } from '../../enums';
import type { AttachmentFile } from '../../models';
import { AttachmentQuery, AttachmentStoreService } from '../../store';
import { TableAttachmentsListComponent } from './components/table-attachments-list/table-attachments-list.component';

@Component({
  selector: 'app-table-attachments',
  templateUrl: './table-attachments.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TableAttachmentsListComponent, AsyncPipe, Recall2OverlayComponent],
})
export class TableAttachmentsComponent {
  @Input() itemsCount: number;
  @Input() itemId: number;
  @Input() attachmentType: AttachmentType;
  @Input() apiUrl: string;
  @Input() files?: AttachmentFile[];

  readonly overlayNested: EOverlayTemplates = EOverlayTemplates.NESTED;
  readonly attachmentsOverlayPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetY: 10,
    offsetX: 16,
  };

  constructor(
    private attachmentStoreService: AttachmentStoreService,
    public attachmentsQuery: AttachmentQuery,
  ) {}

  onAttachmentCountEnter(): void {
    if (this.files) {
      return;
    }
    this.attachmentStoreService.clearRowItemAttachments();
    if (this.apiUrl) {
      this.attachmentStoreService.getRowItemAttachmentsForPhase1(this.itemId, this.apiUrl, this.attachmentType);
      return;
    }
    if (this.itemId && this.itemsCount) {
      this.attachmentStoreService.getRowItemAttachments(this.attachmentType, this.itemId);
    }
  }

  downloadSingleFile(file: AttachmentFile): void {
    if (!this.files) {
      if (this.apiUrl && this.attachmentType) {
        const url = `${this.apiUrl}/attachments/${this.attachmentType}/${this.itemId}/download`;
        this.attachmentStoreService.downloadAttachmentForPhase1(url, file);
      } else if (this.apiUrl) {
        const url = `${this.apiUrl}/attachments/${this.itemId}/download`;
        this.attachmentStoreService.downloadAttachmentForPhase1(url, file);
      } else {
        this.attachmentStoreService.getUrlToDownload(file);
      }
    } else {
      this.attachmentStoreService.downloadAttachment(file.url, file.filename);
    }
  }

  downloadAllFiles(): void {
    if (!this.files) {
      this.attachmentsQuery.rowItemAttachments.forEach(attachment => this.downloadSingleFile(attachment));
    } else {
      this.files.forEach(file => this.downloadSingleFile(file));
    }
  }
}
