import { NgClass, NgFor, NgTemplateOutlet } from '@angular/common';
import type { OnChanges, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { iconArrowLeftFilledSmall, iconArrowRightFilledSmall, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';

import { Recall2ButtonTertiaryComponent } from '../../../buttons/components/recall2-button-tertiary/recall2-button-tertiary.component';
import { Recall2SVGIconButtonDirective } from '../../../buttons/directives/recall2-svg-icon-button/recall2-svg-icon-button.directive';
import { TablePaginatorLeftElementDirective } from '../../directives/table-paginator-left-element/table-paginator-left-element.directive';
import { TablePaginatorRightElementDirective } from '../../directives/table-paginator-right-element/table-paginator-right-element.directive';
import type { TablePageEvent } from '../../models/table-page-event.model';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgTemplateOutlet,
    Recall2ButtonTertiaryComponent,
    Recall2SVGIconButtonDirective,
    SVGIconModule,
    TablePaginatorLeftElementDirective,
    TablePaginatorRightElementDirective,
    NgClass,
  ],
})
export class TablePaginatorComponent implements OnChanges {
  @Input() length: number;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() arePageButtonsDisabled: boolean;
  @Input() iconColor = '$rc2-petrol-900';
  @Output() page = new EventEmitter<TablePageEvent>();
  @ContentChildren(TablePaginatorLeftElementDirective, { read: TemplateRef }) paginatorLeftElementTemplates: QueryList<
    TemplateRef<TablePaginatorLeftElementDirective>
  >;
  @ContentChildren(TablePaginatorRightElementDirective, { read: TemplateRef })
  paginatorRightElementTemplates: QueryList<TemplateRef<TablePaginatorRightElementDirective>>;

  isPreviousClickDisabled: boolean;
  isNextClickDisabled: boolean;

  constructor(svgIconRegistry: SVGIconsRegistry) {
    svgIconRegistry.registerIcons([iconArrowLeftFilledSmall, iconArrowRightFilledSmall]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const pageSize =
      changes.pageSize && changes.pageSize.currentValue !== changes.pageSize.previousValue
        ? changes.pageSize.currentValue
        : this.pageSize;
    const pageIndex =
      changes.pageIndex && changes.pageIndex.currentValue !== changes.pageIndex.previousValue
        ? changes.pageIndex.currentValue
        : this.pageIndex;
    const length =
      // eslint-disable-next-line unicorn/explicit-length-check
      changes.length && changes.length.currentValue !== changes.length.previousValue
        ? changes.length.currentValue
        : this.length;

    const lastPageIndex = Math.ceil(length / pageSize) - 1;

    this.isPreviousClickDisabled = pageIndex === 0;
    this.isNextClickDisabled = pageIndex === lastPageIndex;
  }

  onPreviousPageClick(): void {
    this.page.next({
      length: this.length,
      pageIndex: this.pageIndex - 1,
      pageSize: this.pageSize,
      previousPageIndex: this.pageIndex,
    });
  }

  onNextPageClick(): void {
    this.page.next({
      length: this.length,
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      previousPageIndex: this.pageIndex,
    });
  }
}
