<ng-container *ngIf="filterConfiguration">
  <div
    class="icon-wrapper rc2-display--flex rc2-align-items--center rc2-justify--center"
    [ngClass]="{'click-status': isFilterOpen}"
  >
    <recall2-svg-icon
      class="coloured-icon"
      *ngIf="activeFilter?.value?.length > 0 || isFilterOpen; else unfilledFilterIcon"
      name="operation-filter-filled"
      size="small"
      data-cy="table-filter-selected"
    ></recall2-svg-icon>

    <ng-template #unfilledFilterIcon>
      <recall2-svg-icon
        name="operation-filter"
        size="small"
        data-cy="table-filter"
      ></recall2-svg-icon>
    </ng-template>
  </div>

</ng-container>
