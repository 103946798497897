import { Component } from '@angular/core';

import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';

@Component({
  selector: 'icon-cell',
  templateUrl: './icon-cell.component.html',
  standalone: true,
})
export class IconCellComponent extends AbstractGenericCellViewWithData {
  iconCssClasses: string[];
}

export class IconCellBinding implements IIconBinding {
  constructor(public iconCssClasses: string[]) {}
}

interface IIconBinding {
  iconCssClasses: string[];
}
