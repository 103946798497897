<ul>
  <li *ngFor="let message of messages">
    <div class="text-content">
      <p
        *ngIf="message.filename"
        class="file-name rc2-ellipsis"
      >
        {{ message.filename }}
      </p>
      <p class="error-message">{{ ERROR_TYPE_TO_TRANSLATE_KEY[message.type] | translate }}</p>
    </div>
  </li>
</ul>
