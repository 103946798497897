import { baseEnvironment } from './environment.base';
import { newRelic } from './environment.credentials';

export const environment = {
  ...baseEnvironment,
  production: false,
  enableCognoCheck: true,
  newRelic: {
    enabled: true,
    setting: {
      accountID: newRelic.accountID,
      trustKey: newRelic.trustKey,
      agentID: newRelic.agentID,
      licenseKey: newRelic.licenseKey,
      applicationID: newRelic.applicationID,
    },
  },
};
