<div class="language-modal rc2-display--flex flex-column">
  <div class="rc2-display--flex rc2-justify--space-between rc2-pad--bottom--3xl">
    <h5>{{ 'header.language-modal.title' | translate }}</h5>
    <recall2-svg-icon
      name="close"
      size="medium"
      (click)="onClose($event)"
      class="close-icon rc2-color--primary"
    ></recall2-svg-icon>
  </div>
  <div class="rc2-display--flex flex-row rc2-justify--space-between">
    <ng-container *ngFor="let lang of languages; let i = index">
      <recall2-button-tertiary [attr.data-cy-value]="'button-language-' + i" (click)="onChangeLanguage($event, lang)">
        {{ 'header.language-modal.' + lang | translate }}
      </recall2-button-tertiary>
    </ng-container>
  </div>
</div>
