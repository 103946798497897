<div class="is-full-width">
  <div
    class="row radio-button-group-header"
    *ngIf="property.translationKey"
  >
    <div class="col-md-10">
      <label
        class="radio-button-group-label"
        [id]="property.name + '_label'"
        [ngClass]="{ 'is-danger': isFormSubmitted && property.control.errors }"
      >
        {{ property.translationKey + ".label" | translate }}

        <recall2-icon-required *ngIf="property.required"
          [id]="property.name + '_required-mark'"></recall2-icon-required>

      </label>
    </div>
    <div class="col-md-2">
      <recall2-icon-help
        *ngIf="property.hasTooltip"
        id="{{ property.name }}_help_icon"
        matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
        matTooltipPosition="above"
        matTooltipClass="rc2-tooltip"
      >
      </recall2-icon-help>
    </div>
  </div>
  <ng-container *ngFor="let radioButton of property.options; index as i">
  <div
      class="radio-field"
      [ngClass]="{
        'show-horizontal': property.showHorizontal === true,
        'radio-container rc2-cursor--pointer': radioContainerClass,
        'active': radioContainerClass && radioButton.value === property.control?.value,
        'disabled': radioButton.disabled || property.disabled
      }">
      <input
        type="radio"
        [formControl]="property.control"
        [attr.data-cy]="'radiobutton-' + radioButton.value"
        [value]="radioButton.value"
        [id]="property.name + '_input_' + radioButton.value"
        [name]="property.name"
        (change)="onRadioButtonChange(radioButton)"
      />
      <label
        for="{{ property.name + '_input_' + radioButton.value }}"
        class="radio-button-input-label no-mar"
        [attr.data-cy]="'radiobutton-label-' + radioButton.value"
        [id]="property.name + '_label_' + radioButton.value"
      >
        {{ (radioButton.rawText ?? radioButton.translationKey + ".label") | translate }}
      </label>
    </div>

    <ng-container *ngIf="template">
      <ng-container *ngTemplateOutlet="template; context: {$implicit: i}">
      </ng-container>
    </ng-container>
  </ng-container>
</div>
