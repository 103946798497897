import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import type { ActivatedRouteSnapshot, CanDeactivateFn, NavigationExtras, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import type { Observer } from 'rxjs';
import { Observable } from 'rxjs';

import { EXTERNAL_URL } from '../external-url/external-url';
import { ReCall2FormCancelModalComponent } from '../overlay/components/modal/form-cancel-modal/form-cancel-modal.component';
import type { DestructiveAction } from './models/destructive-action.model';

function isUrlInBlacklist(url: string): boolean {
  return (
    url.endsWith('clearings') ||
    url.endsWith('topics') ||
    url.endsWith('committees') ||
    url.endsWith('meetings') ||
    url.endsWith('agenda-items') ||
    url.endsWith('user-management') ||
    url.endsWith('notifications') ||
    url.endsWith('welcome') ||
    url.endsWith('landing') ||
    url.endsWith('verification-tasks/topic') ||
    url.endsWith('user-management/profile') ||
    url.includes('meetings/form/') ||
    url.includes(EXTERNAL_URL)
  );
}

export const destructiveActionGuard: CanDeactivateFn<DestructiveAction> = (
  component: DestructiveAction,
  _currentRoute: ActivatedRouteSnapshot,
  _currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot,
): Observable<boolean> | boolean => {
  const dialog: MatDialog = inject(MatDialog);
  const router: Router = inject(Router);

  let nextStateUrl = '';
  let externalUrl = '';

  const isUrlNotInBlackListAndUserHasChanges = (component: DestructiveAction): boolean => {
    return !isUrlInBlacklist(nextStateUrl.split('?')[0]) && component.hasChanges();
  };

  const dialogObservable = (cancelModalTitle: string): Observable<boolean> => {
    return new Observable((modalDialogObserver: Observer<boolean>) => {
      dialog
        .open(ReCall2FormCancelModalComponent, {
          panelClass: 'form-cancel-modal',
          backdropClass: 'modal-backdrop',
          data: {
            title: cancelModalTitle,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            submitCallback: () => {} /* don't remove */,
          },
        })
        .afterClosed()
        .subscribe({
          next: (isModalDialogConfirmed: boolean) => {
            checkIfObserverIsClosed(modalDialogObserver, isModalDialogConfirmed);
          },
          error: () => {
            modalDialogObserver.next(false);
            modalDialogObserver.complete();
          },
        });
    });
  };

  const checkIfObserverIsClosed = (observer: Observer<boolean>, isModalDialogConfirmed: boolean): void => {
    /*
     * If the user has selected the OK option in the modal dialog should in case the observable
     * is closed retry the navigation
     */
    if (observer['closed'] && isModalDialogConfirmed) {
      /*
       * If the target url is an external url (not in the same app) should navigate
       * providing the target app url
       */
      if (nextStateUrl.includes(EXTERNAL_URL)) {
        const navigationExtras: NavigationExtras = {
          state: { externalUrl },
          skipLocationChange: true,
        };
        router.navigate(['/', EXTERNAL_URL], navigationExtras);
      } else {
        router.navigateByUrl(nextStateUrl);
      }
    } else {
      observer.next(isModalDialogConfirmed);
      observer.complete();
    }
  };

  const getExternalUrlFromExtras = (): string => {
    if (
      router.getCurrentNavigation() &&
      router.getCurrentNavigation().extras.state &&
      router.getCurrentNavigation().extras.state.externalUrl
    ) {
      return router.getCurrentNavigation().extras.state.externalUrl;
    }
  };

  nextStateUrl = nextState.url;
  externalUrl = getExternalUrlFromExtras();

  /*
   *  If e.g. add new expert or link a topic, the changes must be saved
   *  and the user should be allowed to navigate.
   */
  if (isUrlNotInBlackListAndUserHasChanges(component)) {
    return component.saveCurrentFormData();
  } else if (component.hasChanges()) {
    /* If the url is in the blacklist and the form has changes should ask user to navigate */
    return dialogObservable(component.cancelModalTitle);
  }

  return true;
};
