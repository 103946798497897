<recall2-datepicker
  #datePickerFromInput
  [isValidateDateDisabled]="false"
  [property]="fromDateProperty"
  data-cy="filter-date-range-from"
  class="rc2-pad--right--xs"
  (dateChange)="fromDateChange($event)"
></recall2-datepicker>

<recall2-datepicker
  #datePickerToInput
  [isValidateDateDisabled]="false"
  [property]="toDateProperty"
  data-cy="filter-date-range-to"
  (dateChange)="toDateChange($event)"
></recall2-datepicker>
