import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(bytes: number): any {
    if (+bytes === 0) {
      return '0 MB';
    }
    const kilobytes = bytes / 1000;
    if (kilobytes < 1000) {
      return kilobytes.toFixed(1) + ' KB';
    }
    const megabytes = kilobytes / 1024;
    if (megabytes < 1000) {
      return megabytes.toFixed(1) + ' MB';
    }
    return (megabytes / 1000).toFixed(1) + ' GB';
  }
}
