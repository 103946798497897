<div
  *ngIf="statusSpec[status]"
  class="status-component"
>
  <div
    class="status-icon"
    [ngSwitch]="statusSpec[status].iconType"
  >
    <recall2-icon-status
      *ngSwitchCase="'FILLED'"
      [rc2IconStyles]="'rc2-icon--size--30'"
      [ngClass]="getColorClass(statusSpec[status].color)"
    >
    </recall2-icon-status>
    <recall2-icon-status-unfilled
      *ngSwitchCase="'HOLLOW'"
      [rc2IconStyles]="'rc2-icon--size--30'"
      [ngClass]="getColorClass(statusSpec[status].color)"
    >
    </recall2-icon-status-unfilled>
  </div>
  <div class="status-description rc2-font--size--12">
    {{ 'history.status-change.statusChangedTo' | translate }}
    <span class="status-text">{{ statusSpec[status].translationKey | translate }}</span>
  </div>
</div>
