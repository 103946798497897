<div
  class="rc2-badge"
  id="badge-{{objectId}}"
>
  <div class="rc2-badge--left-column">
    <div class="badge-icon">
      <ng-content></ng-content>
    </div>
    <div class="badge-id">
      <div class="badge-id--title rc2-ellipsis rc2-font--size--12">{{objectTypeTranslationKey | translate}}</div>
      <div class="badge-id--text rc2-ellipsis rc2-font--size--10">{{ objectId }}</div>
    </div>
  </div>
  <div
    class="rc2-badge--tag rc2-ellipsis rc2-font--size--14"
    [matTooltipPosition]="badgeToolTipPosition"
    [matTooltip]="content"
    matTooltipClass="rc2-tooltip"
  >
    {{content}}
  </div>
  <recall2-status
    *ngIf="status"
    class="rc2-badge--right-column"
    [currentStatus]="status"
    [isCampaign]="isCampaign"
    [isTopic]="isTopic"
  >
  </recall2-status>
</div>
