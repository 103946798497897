import type { DatePickerDay } from './datepicker-day';

export class DatePickerWeek {
  calendarWeek: number;

  constructor(public days: DatePickerDay[]) {
    this.calendarWeek = this.getWeekNumber(days[0].date);
  }

  // see https://stackoverflow.com/a/6117889
  private getWeekNumber(date: Date): number {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    return Math.ceil(((+d - +yearStart) / 86_400_000 + 1) / 7);
  }
}
