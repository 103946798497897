<div class="rc2-full--width">
  <div class="row">
    <div class="col-md-12 rc2-display--flex">
      <div class="header-content">
        <ng-content select="[header]"></ng-content>
      </div>
      <ng-container *ngTemplateOutlet="expandableButton"></ng-container>
    </div>
  </div>
  <div [class.rc2-mar--top--xl]="isExpanded">
    <ng-content
      select="[body]"
      *ngIf="!isExpandable || (isExpandable && isExpanded)"
    ></ng-content>
  </div>
</div>

<ng-template #expandableButton>
  <div
    data-cy="expandable-trigger"
    *ngIf="isExpandable"
    class="trigger-wrapper"
  >
    <recall2-svg-icon
      class="rc2-icon--color--primary"
      size="medium"
      [name]="isExpanded ? 'arrow-up' : 'arrow-down'"
      (click)="toggleExpand()"
    ></recall2-svg-icon>
  </div>
</ng-template>
