<ng-container *ngIf="!property.readOnly; else readonly">
  <label
    *ngIf="property.hasTitle"
    id="{{ property.name }}_label"
    class="title-label mar-bottom-erase"
    data-testid="title-label"
    >{{ property.translationKey + '.label' | translate }}
    <recall2-icon-required *ngIf="property.required"></recall2-icon-required>
  </label>
  <recall2-icon-help
    *ngIf="property.hasTooltip"
    id="{{ property.name }}_help_icon"
    matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
    matTooltipPosition="above"
    matTooltipClass="rc2-tooltip"
  >
  </recall2-icon-help>
  <input
    #inputNumber
    id="{{ property.name }}"
    data-testid="main-input"
    [formControl]="property.control"
    placeholder="{{ property.translationKey + '.placeholder' | translate }}"
    type="number"
    class="rc2-ellipsis"
    [ngClass]="{
      'error-input': showErrors
    }"
    (change)="change()"
    (keydown)="onKeyDownForNumbers($event)"
    (keyup)="keyup($event)"
    (focus)="focus($event)"
    (blur)="blur($event)"
    (input)="onInput()"
    (focusout)="focusout($event)"
    autocomplete="off"
    [recall2AllowedCharacters]="property.allowedCharacters"
  />
  <div class="rc2-position--relative">
    <div class="overlay-icon-right" *ngIf="!showErrors">
      <ng-content></ng-content>
    </div>
  </div>
  <p id="error_feedback_message" class="error-message" *ngIf="showErrors">
    {{ 'form.error.generic.' + firstErrorType | translate }}
  </p>
</ng-container>

<ng-template #readonly>
  <label
    *ngIf="property.hasTitle"
    for="{{ property.name }}"
    class="title-label mar-bottom-erase"
    data-testid="title-label-readonly"
    >{{ property.translationKey + '.label' | translate }}
  </label>
  <label class="readonly-label-multiline" data-testid="main-content-readonly">
    {{ property.control.value }}
  </label>
</ng-template>
