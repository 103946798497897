import type { OnChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-table-paginator-summary',
  templateUrl: './table-paginator-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class TablePaginatorSummaryComponent implements OnChanges {
  @Input() length: number;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  currentItems: string;

  ngOnChanges(): void {
    this.updateSummary();
  }

  private updateSummary(): void {
    let lastItemNumberForPage = (this.pageIndex + 1) * this.pageSize;
    if (lastItemNumberForPage > this.length) {
      lastItemNumberForPage = this.length;
    }
    this.currentItems = `${this.pageIndex * this.pageSize + 1}-${lastItemNumberForPage}`;
  }
}
