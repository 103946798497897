import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { UserState } from '@recall2/ui/user-flow';
import { KnownUserProvider } from '@recall2/ui/user-flow';
import type { Observable } from 'rxjs';
import { skipWhile } from 'rxjs/operators';

import { UserSelectors } from '../../user/store/user.selectors';
import type { UserData } from '../../user/store/user.state';

@Injectable()
export class AppKnownUserProviderService extends KnownUserProvider {
  constructor(private store: Store<UserData>) {
    super();
  }

  isKnownUser(): Observable<UserState> {
    return this.store.select(UserSelectors.isKnownUser).pipe(skipWhile(userState => userState.isKnown === undefined));
  }
}
