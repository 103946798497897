import type { TemplateRef } from '@angular/core';
import type { FormControl, ValidatorFn } from '@angular/forms';

import type {
  AutocompleteGroup,
  AutocompleteOption,
  CheckboxProperty,
  InputTextProperty,
  IReCall2FormProperty,
  RadioButtonV2GroupProperty,
} from '../../form/model';
import type { Recall2SortingOrder } from '../../tables/models/recall2-sort';

export enum EBackendFilterTemplates {
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  TEXT = 'STRING',
  SELECT = 'MULTI_SELECT',
  BOOLEAN = 'BOOLEAN',
  RADIO = 'RADIO',
}

export enum EFilterTemplates {
  DATE,
  NUMBER,
  TEXT,
  SELECT,
  BOOLEAN,
  RADIO,
  YEAR,
  AUTOCOMPLETE,
  USER_ROLES,
  CUSTOM_SELECT,
}

export type FilterComparator =
  | EDateFilterComparator
  | ENumberFilterComparator
  | ETextFilterComparator
  | ESelectFilterComparator
  | EBooleanFilterComparator
  | EAutocompleteFilterComparator
  | EUserRolesFilterComparator;

export interface IRecall2FilterParam {
  identifier: string;
  labels?: string[];
  translationKey: string;
  type: EFilterTemplates;
  comparator: FilterComparator;
  value: string[] | number[] | Date[] | boolean[];
  allowedComparators?: string[];
}

export interface IRecall2FilterSelectParam extends IRecall2FilterParam {
  identifier: string;
  translationKey: string;
  type: EFilterTemplates;
  comparator: ESelectFilterComparator;
  selectableValues: IRecall2FilterSelectableValue[];
  value: string[];
}

export class Recall2FilterSelectParam implements IRecall2FilterSelectParam {
  constructor(
    public identifier: string,
    public translationKey: string,
    public selectableValues: IRecall2FilterSelectableValue[],
    public type: EFilterTemplates = EFilterTemplates.SELECT,
    public comparator: ESelectFilterComparator = ESelectFilterComparator.EQUALS,
    public value: string[] = [],
  ) {}
}

export interface IRecall2FilterNumberParam extends IRecall2FilterParam {
  identifier: string;
  translationKey: string;
  type: EFilterTemplates;
  comparator: ENumberFilterComparator;
  value: number[];
}

export class Recall2FilterNumberParam implements IRecall2FilterNumberParam {
  constructor(
    public identifier: string,
    public translationKey: string,
    public type: EFilterTemplates = EFilterTemplates.NUMBER,
    public comparator: ENumberFilterComparator = ENumberFilterComparator.EQUALS,
    public value: number[] = [],
  ) {}
}

export interface IRecall2FilterDateParam extends IRecall2FilterParam {
  identifier: string;
  translationKey: string;
  type: EFilterTemplates;
  comparator: EDateFilterComparator;
  value: Date[];
}

export class Recall2FilterDateParam implements IRecall2FilterDateParam {
  constructor(
    public identifier: string,
    public translationKey: string,
    public type: EFilterTemplates = EFilterTemplates.DATE,
    public comparator: EDateFilterComparator = EDateFilterComparator.BETWEEN,
    public value: Date[] = [],
  ) {}
}

export interface IFilterDateV2Option {
  key: string;
  value: string;
  disabled?: boolean;
}

export class Recall2FilterDateV2Property implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public defaultValue: string,
    public optionList: IFilterDateV2Option[] = [],
    public hasTooltip: boolean,
    public control: FormControl,
    public readOnly?: boolean,
  ) {}
}

export class Recall2FilterDateV2Param implements IRecall2FilterDateParam {
  constructor(
    public identifier: string,
    public translationKey: string,
    public type: EFilterTemplates = EFilterTemplates.DATE,
    public comparator: EDateFilterComparator = EDateFilterComparator.BETWEEN,
    public value: Date[] = [],
  ) {}
}

export interface IRecall2FilterTextParam extends IRecall2FilterParam {
  identifier: string;
  translationKey: string;
  type: EFilterTemplates;
  comparator: ETextFilterComparator;
  value: string[];
}

export class Recall2FilterTextParam implements IRecall2FilterTextParam {
  constructor(
    public identifier: string,
    public translationKey: string,
    public type: EFilterTemplates = EFilterTemplates.TEXT,
    public comparator: ETextFilterComparator = ETextFilterComparator.EQUALS,
    public value: string[] = [],
  ) {}
}

export interface IRecall2FilterBooleanParam extends IRecall2FilterParam {
  identifier: string;
  translationKey: string;
  type: EFilterTemplates;
  comparator: EBooleanFilterComparator;
  value: boolean[];
}

export interface IRecall2FilterRadioParam extends IRecall2FilterParam {
  identifier: string;
  translationKey: string;
  type: EFilterTemplates;
  comparator: EBooleanFilterComparator;
  value: string[];
}

export class Recall2FilterBooleanParam implements IRecall2FilterBooleanParam {
  constructor(
    public identifier: string,
    public translationKey: string,
    public type: EFilterTemplates = EFilterTemplates.BOOLEAN,
    public comparator: EBooleanFilterComparator = EBooleanFilterComparator.EQUALS,
    public value: boolean[] = [],
  ) {}
}

export interface IRecall2FilterInputProperty {
  inputTextFilterProperty: InputTextProperty;
  isEditIconShown: boolean;
}

export class Recall2FilterInputProperty implements IRecall2FilterInputProperty {
  constructor(
    public inputTextFilterProperty: InputTextProperty,
    public isEditIconShown: boolean,
  ) {}
}

export interface IRecall2FilterAutocompleteParam extends IRecall2FilterParam {
  identifier: string;
  translationKey: string;
  placeholderKey: string;
  type: EFilterTemplates;
  comparator: EAutocompleteFilterComparator;
  items: AutocompleteGroup[] | AutocompleteOption[];
  value: string[];
}

export class Recall2FilterAutocompleteParam implements IRecall2FilterAutocompleteParam {
  public type: EFilterTemplates = EFilterTemplates.AUTOCOMPLETE;
  public comparator: EAutocompleteFilterComparator = EAutocompleteFilterComparator.EQUALS;

  constructor(
    public identifier: string,
    public translationKey: string,
    public placeholderKey: string,
    public items: AutocompleteGroup[] | AutocompleteOption[],
    public value: string[] = [],
  ) {}
}

export interface IRecall2FilterUserRolesParam extends IRecall2FilterParam {
  identifier: string;
  translationKey: string;
  type: EFilterTemplates;
  comparator: EUserRolesFilterComparator;
  value: string[];
}

export class Recall2FilterUserRolesParam implements IRecall2FilterUserRolesParam {
  public type: EFilterTemplates = EFilterTemplates.USER_ROLES;
  public comparator: EUserRolesFilterComparator = EUserRolesFilterComparator.EQUALS;

  constructor(
    public identifier: string,
    public translationKey: string,
    public value: string[] = [],
  ) {}
}

export enum EDateFilterComparator {
  OLDER = 'OLDER',
  NEWER = 'NEWER',
  BETWEEN = 'BETWEEN',
  GREATER_EQUAL = 'GREATER_EQUAL',
  LOWER_EQUAL = 'LOWER_EQUAL',
}

export enum ENumberFilterComparator {
  EQUALS = 'EQUALS',
  LESS = 'LESS',
  GREATER = 'GREATER',
}

export enum EFilterYesOrNo {
  YES = 'YES',
  NO = 'NO',
}

export enum EFilterSelectedOrDeselectedOrUnselected {
  SELECTED = 'SELECTED',
  DESELECTED = 'DESELECTED',
  UNSELECTED = 'UNSELECTED',
}

export enum ETextFilterComparator {
  EQUALS = 'EQUALS',
}

export enum ESelectFilterComparator {
  EQUALS = 'EQUALS',
}

export enum EBooleanFilterComparator {
  EQUALS = 'EQUALS',
}

export enum EAutocompleteFilterComparator {
  EQUALS = 'EQUALS',
}

export enum EUserRolesFilterComparator {
  EQUALS = 'EQUALS',
}

export interface IRecall2FilterSelectableValue {
  iconStyle?: string;
  value: string;
  translationKey: string;
  notTranslate?: boolean;
}

export class Recall2FilterSelectableValue implements IRecall2FilterSelectableValue {
  constructor(
    public iconStyle: string,
    public value: string,
    public translationKey: string,
  ) {}
}

export interface IRecall2FilterHeaderParam {
  filterIdentifier: string;
  filterTranslationKey: string;
  isActive: boolean;
}

export class Recall2FilterHeaderParam implements IRecall2FilterHeaderParam {
  constructor(
    public filterIdentifier: string,
    public filterTranslationKey: string,
    public isActive: boolean,
  ) {}
}

export class Recall2FilterIconParam {
  constructor(
    public display: boolean,
    public filterIdentifier: string,
    public withBlueBackground?: boolean,
  ) {}
}

// TODO: Clean ALL Models
export class FilterDataBinding {
  constructor(
    // public injectedServices: InjectedServiceParams,
    public currentSelectedFilter: IRecall2FilterParam,
    public selectableValues?: IRecall2FilterSelectableValue[],
  ) {}
}

export enum IRecall2Comparator {
  GREATER_EQUAL = 'GREATER_EQUAL',
  GREATER = 'GREATER',
  LOWER = 'LOWER',
  EQUAL = 'EQUAL',
}

export interface IRecall2FilterItem {
  identifier: string;
  value: string[] | number[] | Date[] | boolean[];
  comparator: IRecall2Comparator;
}

export interface IRecall2SortItem {
  identifier: string;
  value: Recall2SortingOrder;
}

export interface IRecall2FilterRequest {
  filter: IRecall2FilterItem[];
  sort: IRecall2SortItem[];
  page: number;
  pageSize: number;
}
export interface IRecall2FilterV2Config extends Omit<IRecall2FilterParam, 'comparator' | 'value'> {
  comparator?: FilterComparator;
  allowedComparators?: string[];
  group?: { key: string; translationKey: string };
  showHint?: boolean;
  position?: 'start' | 'center' | 'end';
  customTemplate?: TemplateRef<unknown>;
}

export interface IRecall2FilterDateV2Config extends IRecall2FilterV2Config {
  type: EFilterTemplates.DATE;
  comparator?: EDateFilterComparator;
}

export interface IRecall2FilterNumberV2Config extends IRecall2FilterV2Config {
  type: EFilterTemplates.NUMBER;
  comparator?: ENumberFilterComparator;
  position?: 'start' | 'center' | 'end';
}

export interface IRecall2FilterTextV2Config extends IRecall2FilterV2Config {
  type: EFilterTemplates.TEXT;
  validators?: ValidatorFn | ValidatorFn[];
}

export interface IRecall2FilterSelectV2Config extends IRecall2FilterV2Config {
  type: EFilterTemplates.SELECT | EFilterTemplates.CUSTOM_SELECT;
  itemsList: IRecall2FilterSelectableValue[];
  propertyList?: CheckboxProperty[];
  selectedItemsIds?: string[];
  placeholderKey?: string;
}

export interface IRecall2FilterBooleanV2Config extends IRecall2FilterV2Config {
  type: EFilterTemplates.BOOLEAN;
  itemsList?: IRecall2FilterSelectableValue[];
  property?: RadioButtonV2GroupProperty;
  placeholderKey?: string;
}

export interface IRecall2FilterRadioConfig extends IRecall2FilterV2Config {
  type: EFilterTemplates.RADIO;
  itemsList?: IRecall2FilterSelectableValue[];
  property?: RadioButtonV2GroupProperty;
  placeholderKey?: string;
}

export interface IRecall2FilterYearConfig extends IRecall2FilterV2Config {
  type: EFilterTemplates.YEAR;
}

export interface IRecall2FilterAutocompleteConfig extends IRecall2FilterV2Config {
  type: EFilterTemplates.AUTOCOMPLETE;
  placeholderKey: string;
  items: AutocompleteGroup[] | AutocompleteOption[];
}

export interface IRecall2FilterUserRolesConfig extends IRecall2FilterV2Config {
  type: EFilterTemplates.USER_ROLES;
  levels: IRecall2FilterSelectableValue[];
  manufacturers: IRecall2FilterSelectableValue[];
  roles: IRecall2FilterSelectableValue[];
  statusRoles: IRecall2FilterSelectableValue[];
}

export type Recall2FilterV2Config =
  | IRecall2FilterDateV2Config
  | IRecall2FilterNumberV2Config
  | IRecall2FilterTextV2Config
  | IRecall2FilterSelectV2Config
  | IRecall2FilterBooleanV2Config
  | IRecall2FilterRadioConfig
  | IRecall2FilterYearConfig
  | IRecall2FilterAutocompleteConfig
  | IRecall2FilterUserRolesConfig;

export interface IRecall2FilterDefinition {
  identifier: string;
  type: EBackendFilterTemplates;
  translationKey: string;
  allowedComparators?: string[];
  /**
   * _key_: data to filter by; _value_: data to be displayed
   */
  values?: { key: string; value: string }[];
}

export interface IRecall2DynamicFilterGroup {
  translationKey: string;
  filterDefinitions: IRecall2FilterDefinition[];
}
