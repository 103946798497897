<label *ngIf="labelTranslationKey">{{ labelTranslationKey | translate }}</label>

<ng-container *ngIf="dueDate?.date; else emptyDate">
  <span [ngClass]="{ approaching: isApproaching, overdue: isOverdue, default: isNone }">
    {{ dueDate.date | customDate }}
  </span>
</ng-container>

<ng-template #emptyDate>
  <div>-</div>
</ng-template>
