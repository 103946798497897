import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { ProtectrHttpInterceptor } from './interceptors/protectr-http-interceptor';
import { userReducer } from './store/user.reducer';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature('user', userReducer)],
  exports: [StoreModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProtectrHttpInterceptor,
      multi: true,
    },
  ],
})
export class UserModule {}
