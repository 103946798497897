import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from './recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-topic-urgency-two',
  templateUrl: './recall2-icon-base/recall2-icon-base.component.html',
  standalone: true,
})
export class Recall2IconTopicUrgencyTwoComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i107-topic-urgency-2');
  }
}
