import type { FlexibleConnectedPositionStrategy, OverlayRef } from '@angular/cdk/overlay';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import type { ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { Directive, ElementRef, HostListener, Input, NgZone, TemplateRef } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { getComputedSizes } from '../../utils/dom/dom.utils';
import { Recall2OverlayContainerComponent } from '../components/recall2-overlay-container/recall2-overlay-container.component';
import { EOverlayTriggerAction } from '../models/trigger-action.model';

@Directive({
  selector: '[customOverlay]',
  standalone: true,
})
export class Recall2OverlayRendererDirective implements OnInit, OnDestroy {
  // Template to be rendered in the overlay
  @Input() overlayTemplate: TemplateRef<any>;
  @Input() overlayTriggerAction: EOverlayTriggerAction;
  @Input() overlayTableFilter: boolean;
  @Input() position: ConnectedPosition;
  @Input() appRootSelector = 'app-root';
  @Input() overlayPositionWithPush = true;
  overlayRef: OverlayRef;
  overlayNode;
  appRootNode;
  private wasInside = false;
  private destroyed$ = new Subject<void>();

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
    private _ngZone: NgZone,
  ) {}

  /**
   * Init life cycle event handler
   */
  ngOnInit(): void {
    this.appRootNode = document.getElementsByTagName(this.appRootSelector)[0];
    this.subscribeToClicksOutsideAngularZone();
  }

  /**
   * This method will be called whenever mouse enters in the Host element
   * i.e. where this directive is applied
   * This method will show the recall2Overlay by instantiating the Recall2OverlayContainerComponent and attaching to the overlay
   */
  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.overlayTriggerAction !== EOverlayTriggerAction.OnHover) {
      return;
    }
    this.createOverlay();
    this.openOverlay();
  }

  @HostListener('click')
  onClick(): void {
    if (this.overlayTriggerAction !== EOverlayTriggerAction.OnClick) {
      return;
    }
    // Re-create the overlay for each click to re-calculate the overlay position, there are some async issues and the
    // values read by getComputedSizes() were returning 0

    if (this.overlayRef) {
      this.closeOverlay();
      return;
    }
    this.createOverlay();
    this.triggerOverlay();
    this.wasInside = true;
  }

  onDocumentClick(event: any): void {
    this.overlayNode = this.overlayRef.overlayElement;
    const targetNode = event.target;
    const bodyNode = document.querySelectorAll('body')[0];
    const condition1 = this.overlayNode.contains(targetNode);
    const condition2 =
      !this.overlayNode.contains(targetNode) &&
      !this.appRootNode.contains(targetNode) &&
      !targetNode.isSameNode(bodyNode);
    const condition3 = this.wasInside;
    if (condition1 || condition2 || condition3) {
      this.wasInside = false;
      return;
    }
    this.closeOverlay();
  }

  triggerOverlay(): void {
    // attach the component if it has not already attached to the overlay
    if (this.overlayRef) {
      if (this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      } else {
        this.openOverlay();
      }
    }
  }

  subscribeToClicksOutsideAngularZone(): void {
    this._ngZone.runOutsideAngular(() => {
      fromEvent(document, 'click')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(event => {
          if (this.overlayRef) {
            this._ngZone.run(() => {
              this.onDocumentClick(event);
            });
          }
        });
    });
  }

  private createOverlay(): void {
    this.closeOverlay();

    let positionStrategy: FlexibleConnectedPositionStrategy;

    if (this.overlayTableFilter) {
      const elementRefSizes = getComputedSizes(this._elementRef.nativeElement);
      const connection = this.getElementForConnection();
      const filterIconWidth = 20;

      positionStrategy = this._overlayPositionBuilder.flexibleConnectedTo(connection.element).withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: elementRefSizes.left - connection.offsetX - filterIconWidth,
          offsetY: 0,
        },
      ]);
    } else {
      positionStrategy = this._overlayPositionBuilder.flexibleConnectedTo(this._elementRef).withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
          offsetY: 10,
        },
      ]);
    }

    if (this.position) {
      positionStrategy = this._overlayPositionBuilder
        .flexibleConnectedTo(this._elementRef)
        .withPush(this.overlayPositionWithPush)
        .withPositions([this.position]);
    }

    const overlayConfig = new OverlayConfig({
      scrollStrategy: this.overlayPositionWithPush
        ? this._overlay.scrollStrategies.block()
        : this._overlay.scrollStrategies.reposition(),
      positionStrategy,
    });

    this.overlayRef = this._overlay.create(overlayConfig);
  }

  /**
   * This method will close the tooltip by detaching the component from the overlay
   */
  public closeOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

  private openOverlay(): void {
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const templateRef: ComponentRef<Recall2OverlayContainerComponent> = this.overlayRef.attach(
        new ComponentPortal(Recall2OverlayContainerComponent),
      );
      templateRef.instance.overlayTemplate = this.overlayTemplate;
    }
  }

  /**
   * Destroy lifecycle event handler
   * This method will make sure to close the tooltip
   * It will be needed in case when app is navigating to different page
   * and user is still seeing the tooltip; In that case we do not want to hang around the
   * tooltip after the page [on which tooltip visible] is destroyed
   * Example - While on My Machines page, select "QuickSearch" and while data is being loaded for QS,
   * mouse hover on a machine node; tooltip is visible; Having this method will ensure to close/dispose the tooltip
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.closeOverlay();
  }

  private getElementForConnection(): { offsetX: number; element: Element } {
    const tbody = this._elementRef.nativeElement.closest('table').querySelector('tbody');

    return {
      element: tbody,
      offsetX: getComputedSizes(tbody).left,
    };
  }
}
