import type { OnDestroy } from '@angular/core';
import { Directive, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { iconWarningMedium, SVGIconsRegistry } from '@recall2/icons';
import { Subject } from 'rxjs';

import type { DateProperty, InputNumberProperty } from '../form/model';

@Directive({
  standalone: true,
})
export abstract class Recall2DatepickerCommonComponent<T extends InputNumberProperty | DateProperty>
  implements OnDestroy
{
  @Input() property: T;

  isOpen = false;
  inputHasFocus = false;
  protected destroyed$ = new Subject<void>();

  @ViewChild('input') input: ElementRef;
  @ViewChild('calendar', { read: ElementRef }) calendarIcon: ElementRef;

  constructor(protected iconsRegistry: SVGIconsRegistry) {
    iconsRegistry.registerIcons([iconWarningMedium]);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  @HostListener('keydown.enter')
  onPressEnter(): void {
    this.isOpen = false;
  }

  @HostListener('keydown.tab')
  @HostListener('keydown.shift.tab')
  onPressTab(): void {
    this.isOpen = false;
  }

  onInputFocus(): void {
    this.inputHasFocus = true;
    this.isOpen = true;
  }

  setInputFocus(): void {
    this.input.nativeElement.focus();
  }

  onClickOutside(event: MouseEvent): void {
    if (!this.hasClickedOnInput(event)) {
      this.isOpen = false;
      this.input.nativeElement.blur();
    }

    if (this.hasClickedOnCalendarIcon(event)) {
      event.stopPropagation();
    }
  }

  /**
   * It only gets executed when the datepicker is closed,
   * due to the StopPropagation of the onClickOutside method
   */
  onClickCalendarIcon(): void {
    this.input.nativeElement.focus();
  }

  protected setControlAsDirty(): void {
    if (!this.property.control.dirty) {
      this.property.control.markAsDirty();
    }
  }

  private hasClickedOnInput(event: MouseEvent): boolean {
    return this.input.nativeElement.contains(event.target);
  }

  private hasClickedOnCalendarIcon(event: MouseEvent): boolean {
    return this.calendarIcon.nativeElement.contains(event.target);
  }
}
