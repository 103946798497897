import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import {
  iconOperationFilterFilledSmall,
  iconOperationFilterSmall,
  SVGIconModule,
  SVGIconsRegistry,
} from '@recall2/icons';
import { filter, take, tap } from 'rxjs/operators';

import type { IRecall2FilterParam, Recall2FilterV2Config } from '../../../overlay/models/filter.model';
import { Recall2TableFilterOverlayService } from '../../services/recall2-table-filter-overlay.service';
import { getHeaderParentElement } from '../../utils/table-columns.utils';

const FILTER_OVERLAY_OFFSET = { offsetX: 0, offsetY: 24 };

@Component({
  selector: 'app-table-column-filter',
  templateUrl: './table-column-filter.component.html',
  styleUrls: ['./table-column-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class.actionable-column]': 'true' },
  standalone: true,
  imports: [NgIf, NgClass, SVGIconModule],
})
export class TableColumnFilterComponent {
  @Input() column: string;

  filterConfiguration: Recall2FilterV2Config;
  activeFilter: IRecall2FilterParam;

  isFilterOpen = false;

  constructor(
    iconsRegistry: SVGIconsRegistry,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private filterOverlayService: Recall2TableFilterOverlayService,
  ) {
    iconsRegistry.registerIcons([iconOperationFilterFilledSmall, iconOperationFilterSmall]);
  }

  setFilterConfiguration(filterConfig: Recall2FilterV2Config): void {
    this.filterConfiguration = filterConfig;
  }

  setActiveFilter(filterParam: IRecall2FilterParam): void {
    this.activeFilter = filterParam;
    this.cdr.detectChanges();
  }

  openFilter(onFilterChangeCallback: (filterChange: IRecall2FilterParam) => void): void {
    this.toggleIsFilterOpen(true);

    this.filterOverlayService
      .openFilter(
        this.filterConfiguration,
        this.activeFilter,
        getHeaderParentElement(this.elementRef),
        this.elementRef,
        FILTER_OVERLAY_OFFSET,
      )
      .pipe(
        take(1),
        tap(() => this.toggleIsFilterOpen(false)),
        filter(filterChange => !!filterChange),
      )
      .subscribe(filterChange => onFilterChangeCallback(filterChange));
  }

  private toggleIsFilterOpen(value: boolean): void {
    this.isFilterOpen = value;
    this.cdr.detectChanges();
  }
}
