import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import type {
  IRecall2FilterRadioConfig,
  IRecall2FilterSelectV2Config,
  Recall2FilterV2Config,
} from '../../../overlay/models/filter.model';

@Pipe({ name: 'filterOptionTranslationKey', standalone: true })
export class FilterOptionTranslationKeyPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: string[] | number[] | Date[] | boolean[],
    identifier: string,
    filterConfigList: Recall2FilterV2Config[],
  ): Observable<string> {
    const filterConfigForParam: IRecall2FilterSelectV2Config | IRecall2FilterRadioConfig = filterConfigList?.find(
      config => config.identifier === identifier,
    ) as IRecall2FilterSelectV2Config | IRecall2FilterRadioConfig;

    const mappedValues = value.map(
      val => filterConfigForParam?.itemsList.find(item => item.value === val)?.translationKey,
    );

    return this.translateService
      .stream(mappedValues)
      .pipe(map(mappedValueArray => Object.values(mappedValueArray).join(', ')));
  }
}
