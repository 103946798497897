import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2AccordionBaseComponent } from '../../accordion-base/recall2-accordion-base.component';
import { AccordionSettings } from '../../model/recall2-accordion-settings';
import { AccordionType } from './../../model/recall2-accordion-type';

@Component({
  templateUrl: './recall2-accordion-simple-base.component.html',
  styleUrls: ['./recall2-accordion-simple-base.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, TranslateModule, Recall2AccordionBaseComponent],
})
export class Recall2AccordionSimpleBaseComponent extends AccordionSettings {
  public accordionType: AccordionType = AccordionType.H3;

  @ViewChild('accordionBase')
  accordionBase: Recall2AccordionBaseComponent;

  expand(): void {
    this.accordionBase.expand();
  }
}
