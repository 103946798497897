import { NgClass, NgIf } from '@angular/common';
import type { OnDestroy } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import type { Download } from '../../../core/models/download.model';
import { Recall2IconDownloadComponent } from '../../../icons';
import type { IRecall2FilterItem, IRecall2SortItem } from '../../../overlay/models/filter.model';
import { Recall2ButtonBaseComponent } from '../../recall2-button-base.component';
import { DownloadType } from './enums/download-type.enum';
import { DownloadRequestOptionsExtraParams } from './models/download-request-options.model';
import { DownloadService } from './services/download/download.service';

@Component({
  selector: 'recall2-button-download',
  templateUrl: './recall2-button-download.component.html',
  styleUrls: ['./recall2-button-download.component.scss', '../../recall2-button-base.component.scss'],
  standalone: true,
  imports: [Recall2IconDownloadComponent, NgClass, NgIf],
})
export class Recall2ButtonDownloadComponent extends Recall2ButtonBaseComponent implements OnDestroy {
  @Input() filter: IRecall2FilterItem[];
  @Input() sort: IRecall2SortItem[];
  @Input() id = '';
  @Input() title = '';
  @Input() url: string;
  @Input() downloadType: DownloadType;
  @Input() extraParams: DownloadRequestOptionsExtraParams = {};
  @Input() isPrimary: boolean;
  @Input() showIcon = true;
  @Input() sortQuery = '';
  @Input() filterQuery = '';
  @Output() errorEventOutput = new EventEmitter<string>();
  @Output() downloadedEventOutput = new EventEmitter<Download>();
  @Output() downloadEventType = new EventEmitter<boolean>();

  forceAnimation = false;
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private readonly downloadService: DownloadService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  downloadFile(): void {
    if (this.forceAnimation) {
      return;
    }
    if (this.downloadType === DownloadType.EVENT) {
      this.downloadEventType.emit(true);
      return;
    }
    this.forceAnimation = true;
    const downloadLink = document.createElement('a');
    this.downloadBlob()
      .pipe(
        takeUntil(this.destroyed$),
        finalize(() => this.cdr.markForCheck()),
      )
      .subscribe({
        next: response => {
          downloadLink.href = window.URL.createObjectURL(response.content);
          downloadLink.setAttribute('download', response.filename);
          document.body.append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
          this.downloadedEventOutput.emit(response);
          this.forceAnimation = false;
        },
        error: err => {
          console.warn(err);
          this.errorEventOutput.emit(`Backend returned error: ${err.error.message || 'server error.'}`);
          this.forceAnimation = false;
        },
      });
  }

  private downloadBlob(): Observable<Download> {
    if (this.sortQuery || this.filterQuery) {
      return this.downloadService.downloadFileQueryParams(this.url, this.sortQuery, this.filterQuery);
    }

    return this.downloadType === DownloadType.GET
      ? this.downloadService.downloadFileGET(this.url, {
          filter: this.filter,
          sort: this.sort,
          extraParams: this.extraParams,
        })
      : this.downloadService.downloadFilePOST(this.url, {
          filter: this.filter,
          sort: this.sort,
        });
  }
}
