export enum MeasureFormField {
  DescriptionMeasureProd = 'descriptionMeasureProd',
  ImplementationDate = 'implementationDate',
  DescriptionMeasureAfterSales = 'descriptionMeasureAfterSales',
  AvailabilityDate = 'availabilityDate',
  WarehouseUpdateNumber = 'warehouseUpdateNumber',
  Supplier = 'supplier',
  MeasureImplemented = 'measureImplemented',
  MeasureAvailable = 'measureAvailable',
  WarehouseUpdated = 'warehouseUpdated',
  SupplierError = 'supplierError',
  BrandManufacturers = 'brandManufacturers',
}
