import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AbstractGenericCellViewWithData } from '@recall2/ui/dynamic-content';
import type { CheckboxProperty } from '@recall2/ui/form/model';
import type { Recall2TableService, WithTableService } from '@recall2/ui/tables';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import type { InvolvedUser } from '../../../model/involved-user.model';
import { Recall2CheckboxComponent } from '../../../../../../frontend-core/src/lib/form/components/recall2-checkbox/recall2-checkbox.component';

@Component({
    selector: 'committee-transcriber-cell',
    templateUrl: './committee-transcriber.cell.html',
    styleUrls: ['./committee-transcriber.cell.scss'],
    standalone: true,
    imports: [Recall2CheckboxComponent],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CommitteeTranscriberCell
  extends AbstractGenericCellViewWithData<InvolvedUser>
  implements WithTableService, AfterViewInit, OnDestroy, OnInit
{
  public static readonly selector = 'recall2-involved-user-committee-transcriber-cell';
  public static readonly changeEvent = 'onChange';

  private tableService: Recall2TableService;
  private destroyed$ = new Subject<void>();
  private disableCheckBox;

  public checkboxProperty: CheckboxProperty = {
    name: 'committeeTranscriberCheckbox',
    required: false,
    translationKey: undefined,
    isChecked: false,
    hasTooltip: false,
    control: new FormControl(),
  };

  ngOnInit(): void {
    if (this.data.committeeTranscriber) {
      this.updateCheckboxValue();
    }
  }

  ngAfterViewInit(): void {
    if (this.disableCheckBox) {
      this.checkboxProperty.control.disable();
    }
    if (this.data.committeeTranscriber) {
      this.updateCheckboxValue();
    }

    this.tableService
      .getTableData()
      .pipe(
        takeUntil(this.destroyed$),
        filter(users => Array.isArray(users)),
      )
      .subscribe((users: InvolvedUser[]) => {
        const cellUser = users.find((u: InvolvedUser) => u.vwUserId === this.data.vwUserId);

        if (cellUser) {
          this.checkboxProperty.control.setValue(cellUser.committeeTranscriber);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateCheckboxValue(): void {
    this.checkboxProperty.isChecked = this.data.committeeTranscriber;
    this.checkboxProperty.control.setValue(this.data.committeeTranscriber);
    this.checkboxProperty.control.updateValueAndValidity();
  }

  public setTableService(tableService: Recall2TableService): void {
    this.tableService = tableService;
    this.tableService.registerCellEvent<InvolvedUser>(
      CommitteeTranscriberCell.selector,
      CommitteeTranscriberCell.changeEvent,
    );
  }

  public onChange(control: FormControl): void {
    this.data.committeeTranscriber = control.value;

    this.tableService.triggerCellEvent<InvolvedUser>(
      CommitteeTranscriberCell.selector,
      CommitteeTranscriberCell.changeEvent,
      this.data,
    );
  }
}

export class CommitteeTranscriberCellDataBinding implements ICommitteeTranscriberCellBinding {
  constructor(
    public propertyName?: string,
    public additionalCssClasses?: string[],
    public disableCheckBox?: boolean,
  ) {}
}

interface ICommitteeTranscriberCellBinding {
  propertyName?: string;
  additionalCssClasses?: string[];
}
