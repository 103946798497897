import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { AbstractControl, FormGroup } from '@angular/forms';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Recall2InputTextComponent } from '@recall2/ui/form/components/recall2-input-text';
import { Recall2TextareaComponent } from '@recall2/ui/form/components/recall2-textarea';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type { INotification, IProdDatePeriod } from '../../../models/notification/notification';
import type { IVehicleRange } from '../../../models/vehicle.range';
import { EditableInputTableComponent } from '../editable-input-table/editable-input-table.component';
import { DataService } from '../service/data/data.service';
import { VehicleFields } from './../model/vehicleFields';
import {
  affectedVehiclesCountFieldProperty,
  commentVehiclesFieldProperty,
  marketsAndRegionsFieldProperty,
  tableTranslationsProductionPeriod,
  tableTranslationsVehicleNumberRange,
  vehiclesAffectedFieldProperty,
} from './notification-vehicle.definition';

@Component({
  selector: 'app-notification-vehicle',
  templateUrl: './notification-vehicle.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, Recall2TextareaComponent, EditableInputTableComponent, Recall2InputTextComponent],
})
export class NotificationVehicleComponent implements OnInit, OnDestroy {
  public readonly src = 'notification-vehicle.components.ts';
  public readonly VehicleFields = VehicleFields;
  public readonly minRows = 5;

  public fieldProperties = {
    [VehicleFields.AffectedVehiclesCount]: affectedVehiclesCountFieldProperty,
    [VehicleFields.VehiclesAffected]: vehiclesAffectedFieldProperty,
    [VehicleFields.MarketsAndRegions]: marketsAndRegionsFieldProperty,
    [VehicleFields.CommentVehicles]: commentVehiclesFieldProperty,
  };

  public formGroup: FormGroup;

  public tableTranslationsProductionPeriod = tableTranslationsProductionPeriod;
  public tableTranslationsVehicleNumberRange = tableTranslationsVehicleNumberRange;

  public currentNotification: INotification;

  private currentNotification$: Observable<INotification> = this.formData.currentNotification$;

  public prodDateRangesAffectedKeys: (keyof IProdDatePeriod)[] = [
    'id',
    'firstProdDateAffected',
    'lastProdDateAffected',
  ];
  public vehicleRangeKeys: (keyof IVehicleRange)[] = ['id', 'firstVin', 'lastVin'];
  private destroyed$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private formData: DataService,
  ) {}

  public ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      [VehicleFields.VehiclesAffected]: this.getFieldControl(VehicleFields.VehiclesAffected),
      [VehicleFields.AffectedVehiclesCount]: this.getFieldControl(VehicleFields.AffectedVehiclesCount),
      [VehicleFields.MarketsAndRegions]: this.getFieldControl(VehicleFields.MarketsAndRegions),
      [VehicleFields.CommentVehicles]: this.getFieldControl(VehicleFields.CommentVehicles),
    });

    this.setupValueChangeHandling();

    this.getCurrentNotification();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private getFieldControl(fieldName: string): AbstractControl {
    return this.fieldProperties[fieldName].control;
  }

  private getCurrentNotification(): void {
    this.currentNotification$.pipe(takeUntil(this.destroyed$)).subscribe((currentNotification: INotification) => {
      this.currentNotification = currentNotification;

      this.fillFormFields(currentNotification);
    });
  }

  private setupValueChangeHandling(): void {
    Object.values(VehicleFields).forEach((formField: string) => {
      this.formGroup
        .get(formField)
        .valueChanges.pipe(takeUntil(this.destroyed$))
        .subscribe(value => {
          this.currentNotification[formField] = value;

          this.updateCurrentNotification();
        });
    });
  }

  private fillFormFields(newNotification: INotification): void {
    Object.values(VehicleFields).forEach((formField: string) => {
      this.formGroup.get(formField).setValue(newNotification[formField], { emitEvent: false });
    });
  }

  public onProductionPeriodChange(newValues: IProdDatePeriod[]): void {
    this.currentNotification.prodDateRangesAffected = newValues;
    this.updateCurrentNotification();
  }

  public onVehicleNumberRangeChange(newValues: IVehicleRange[]) {
    this.currentNotification.vehicleRange = newValues;
    this.updateCurrentNotification();
  }

  private updateCurrentNotification(): void {
    this.formData.updateCurrentNotification(this.currentNotification);
  }
}
