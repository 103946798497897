<div class="info-background">
  <div class="info-icon">
    <em class="icon icon-i33-warning"></em>
  </div>
  <div
    data-testid="error-message"
    class="concurrent-edit-error-message"
    [innerHTML]="errorMessage"
  >
  </div>
  <div class="reload-btn">
    <recall2-button-secondary
      (click)="reload()"
      [iconClass]="'icon-i67-reload'"
    >
      {{ 'errorhandling.reload-topic-btn-label' | translate: { objectType: objectType } }}
    </recall2-button-secondary>
  </div>
</div>
