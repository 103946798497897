import { lastValueFrom } from 'rxjs';

import type { Recall2NewRelicService } from '../new-relic/services/recall2-new-relic.service';
import type { Recall2FeatureFlagService } from './services/feature-flag.service';

export function recall2FeatureFlagInitializer(
  featureFlagService: Recall2FeatureFlagService,
  newRelic: Recall2NewRelicService,
) {
  return async (): Promise<void> => {
    try {
      await lastValueFrom(featureFlagService.initialise());
    } catch (error) {
      newRelic.noticeError(error);
    }
  };
}
