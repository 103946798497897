export interface IUser {
  vwUserId: string;
  firstName: string;
  lastName: string;
  department?: string;
  tel?: string;
  email?: string;
}

export enum EUserType {
  EDITOR = 'editor',
  USER = 'user',
}
