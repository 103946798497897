import { NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';

import {
  Recall2IconAddComponent,
  Recall2IconDoubleArrowsComponent,
  Recall2IconEditComponent,
  Recall2IconForwardComponent,
  Recall2IconInfoComponent,
  Recall2IconLinkComponent,
  Recall2IconMailSummaryComponent,
  Recall2IconMoveComponent,
  Recall2IconPartSelectComponent,
  Recall2IconReturnComponent,
  Recall2IconUnlinkComponent,
  Recall2IconWarningComponent,
} from '../../../icons';
import { EventType } from '../../models/object-event';

@Component({
  selector: 'recall2-event-icon',
  templateUrl: './event-icon.component.html',
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    Recall2IconDoubleArrowsComponent,
    Recall2IconAddComponent,
    Recall2IconInfoComponent,
    Recall2IconWarningComponent,
    Recall2IconMoveComponent,
    Recall2IconEditComponent,
    Recall2IconUnlinkComponent,
    Recall2IconMailSummaryComponent,
    Recall2IconReturnComponent,
    Recall2IconLinkComponent,
    Recall2IconForwardComponent,
    Recall2IconPartSelectComponent,
    NgTemplateOutlet,
  ],
})
export class EventIconComponent {
  @Input() eventType: EventType;
}
