import { SelectionModel } from '@angular/cdk/collections';
import { NgClass, NgIf } from '@angular/common';
import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import type { BrandManufacturer } from '@recall2/ui/brand-manufacturer';
import { Recall2BrandLogoComponent } from '@recall2/ui/img-logo';
import type { Observable } from 'rxjs';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, switchMap, takeUntil } from 'rxjs/operators';

import { BrandService } from '../../../rest-api/brand/brand.service';
import { INotification } from '../../models/notification/notification';

interface IBrandManufacturer extends BrandManufacturer {
  preSelected?: boolean;
}

@Component({
  selector: 'form-brand-selector',
  templateUrl: './form-brand-selector.component.html',
  styleUrls: ['./form-brand-selector.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCheckboxModule,
    MatTableModule,
    Recall2BrandLogoComponent,
  ],
})
export class FormBrandSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() updateBrandManufacturers = new EventEmitter<BrandManufacturer[]>();

  @Input() allowMultipleSelections: boolean;
  @Input() disablePreSelections: boolean;
  @Input() updateAssignedList: boolean;
  @Input() submitted: boolean;
  @Input() preSelectedBrandManufacturers: IBrandManufacturer[] = [];
  @Input() currentNotification: INotification;
  @ViewChild(MatSort) sort: MatSort;

  public brandForm: FormGroup;
  public brandManufacturerList: IBrandManufacturer[] = [];
  public searchTerms = new Subject<string>();
  public preSelectedEntries: BrandManufacturer[];
  private destroyed$ = new Subject<void>();

  public displayedColumns = ['select', 'brand', 'manufacturer'];
  public dataSource = new MatTableDataSource<BrandManufacturer>();
  public selection: SelectionModel<BrandManufacturer>;

  readonly src = 'form-brand-selector.component';

  constructor(
    private formBuilder: FormBuilder,
    private brandService: BrandService,
  ) {}

  ngOnInit(): void {
    this.brandForm = this.formBuilder.group({
      brandManufacturers: [''],
      searchedBrand: [''],
    });
    this.selection = new SelectionModel<BrandManufacturer>(this.allowMultipleSelections, []);

    this.subscribeToData();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  disabledPreSelectedRows(): void {
    for (const selectedBrand of this.preSelectedEntries) {
      const index: number = this.brandManufacturerList.findIndex(brand => brand.id === selectedBrand.id);
      this.brandManufacturerList[index].preSelected = true;

      this.dataSource = new MatTableDataSource<BrandManufacturer>(this.brandManufacturerList);
    }
  }

  sortingDataAccessor(): void {
    this.dataSource.sortingDataAccessor = (item, property): string => {
      switch (property) {
        case this.displayedColumns[1]:
          return item.brand.name;
        case this.displayedColumns[2]:
          return item.manufacturer.name;
        default:
          return item[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  onSearch(): void {
    this.searchTerms.next(this.brandForm.controls.searchedBrand.value);
  }

  searchBrand(term: string): Observable<BrandManufacturer[]> {
    const patt = new RegExp('^.*' + term.toLowerCase() + '.*$'); // ^.*foo.*$  // regexp to check if the string contains this string
    const resultList = this.brandManufacturerList.filter(brandManufacturer => {
      return (
        patt.test(brandManufacturer.brand.name.toLowerCase()) ||
        patt.test(brandManufacturer.manufacturer.name.toLowerCase())
      );
    });
    return of(resultList);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
      if (this.disablePreSelections) {
        for (const row of this.preSelectedEntries) {
          const findManufacturer = this.dataSource.data.find(dataRow => dataRow.id === row.id);
          if (findManufacturer) {
            this.selection.select(findManufacturer);
          }
        }
      }
    } else {
      for (const row of this.dataSource.data) {
        this.selection.select(row);
      }
    }

    this.updateBrandManufacturerList();
  }

  updateBrandManufacturerList(): void {
    this.updateBrandManufacturers.emit(this.selection.selected);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private subscribeToData(): void {
    this.brandService
      .getBrandManufacturerForList(this.currentNotification.createdAt)
      .pipe(first())
      .subscribe(brandManufacturers => {
        this.brandManufacturerList = brandManufacturers;
        this.dataSource = new MatTableDataSource<IBrandManufacturer>(brandManufacturers);
        this.sortingDataAccessor();

        this.preSelectRows();

        if (this.disablePreSelections) {
          this.disabledPreSelectedRows();
        }
      });

    this.searchTerms
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => {
          return this.searchBrand(term);
        }),
      )
      .subscribe(newBrandManufacturerList => {
        this.dataSource = new MatTableDataSource<IBrandManufacturer>(newBrandManufacturerList);
        this.sortingDataAccessor();
      });
  }

  private preSelectRows(): void {
    this.preSelectedEntries = [...this.preSelectedBrandManufacturers];
    const selectedRows: BrandManufacturer[] = [];
    for (const it of this.preSelectedBrandManufacturers) {
      const findManufacturer = this.dataSource.data.find(manufacturer => it.id === manufacturer.id);
      if (findManufacturer) {
        selectedRows.push(findManufacturer);
      }
    }
    this.selection.select(...selectedRows);
  }
}
