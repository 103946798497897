import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import { Recall2IconLogoRecallComponent, Recall2IconUserComponent } from '../../../icons';
import { EventParams, UserType } from '../../models/object-event';
import { EventDescriptionPipe } from '../../pipes/event-description.pipe';

@Component({
  selector: 'recall2-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  standalone: true,
  imports: [Recall2IconUserComponent, Recall2IconLogoRecallComponent, NgIf, NgClass, AsyncPipe, EventDescriptionPipe],
})
export class CommentComponent {
  @Input() comment: string;
  @Input() userType: UserType;
  @Input() params: EventParams;

  get isSystemUser(): boolean {
    return this.userType === 'SYSTEM';
  }
}
