import type { Recall2TableService } from '../services/recall2-table.service';
import type { Recall2TableFilterService } from '../services/recall2-table-filter.service';
import type { Recall2TableSortService } from '../services/recall2-table-sort.service';

export interface WithTableService {
  setTableService(tableService: Recall2TableService);
}

export interface WithFilterService {
  setFilterService(filterService: Recall2TableFilterService);
}

export interface WithSortService {
  setTSortService(sortService: Recall2TableSortService);
}

export enum ETableType {
  Object = 'Object',
  Context = 'Context',
}
