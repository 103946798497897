import { CommonModule, NgClass } from '@angular/common';
import type { AfterViewChecked, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Component, NgZone } from '@angular/core';
import type { AbstractControlOptions, ValidatorFn } from '@angular/forms';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { filter, Subject, takeUntil } from 'rxjs';

import { AbstractGenericCellViewWithData } from '../../../dynamic-content/models/dynamic-content.model';
import { Recall2ThreeStepCheckboxComponent } from '../../../form/components/recall2-three-step-checkbox';
import type { EThreeStepCheckboxStates } from '../../../form/model';
import { ThreeStepCheckboxProperty } from '../../../form/model';
import type { WithTableService } from '../../../tables/models/recall2-table';
import type { Recall2TableService } from '../../../tables/services/recall2-table.service';

@Component({
  selector: 'three-step-cell',
  templateUrl: './three-step.cell.html',
  standalone: true,
  imports: [
    Recall2ThreeStepCheckboxComponent,
    NgClass,
    CommonModule,
    TranslateModule,
    MatTableModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class ThreeStepCellComponent
  extends AbstractGenericCellViewWithData
  implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy, WithTableService, IThreeStepBinding
{
  static readonly selector = 'three-step-cell';
  static readonly selected = 'selected';
  static readonly isFormSubmitted = 'isFormSubmitted';
  propertyName: string;
  readonly: boolean;
  property: ThreeStepCheckboxProperty;
  readOnlyByPropertyName: string;
  isSubmitted = false;
  private oldValue: EThreeStepCheckboxStates;
  private tableService: Recall2TableService;
  private validator?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null;
  private destroyed$ = new Subject<void>();

  constructor(private ngZone: NgZone) {
    super();
  }

  ngOnInit(): void {
    if (this.data[this.readOnlyByPropertyName]) {
      this.readonly = true;
    }
    this.property = new ThreeStepCheckboxProperty(
      this.propertyName,
      false,
      'three-step-checkbox.translation',
      false,
      new FormControl({ value: this.data[this.propertyName], disabled: this.readonly }, this.validator),
      false,
    );
  }

  ngAfterViewInit(): void {
    const formSubmittedEvent = this.tableService.getCellEvent<boolean>(
      ThreeStepCellComponent.selector,
      ThreeStepCellComponent.isFormSubmitted,
    );
    formSubmittedEvent
      .pipe(
        takeUntil(this.destroyed$),
        filter(value => typeof value === 'boolean'),
      )
      .subscribe(value => {
        // for multi sub-pages form validation update this variable in a new task
        setTimeout(() => {
          this.isSubmitted = value;
        });
      });
  }

  ngAfterViewChecked(): void {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        if (this.data && this.data[this.propertyName] && this.oldValue !== this.data[this.propertyName]) {
          this.ngZone.run(() => {
            this.property.control = new FormControl(
              { value: this.data[this.propertyName], disabled: this.readonly },
              this.validator,
            );
          });
          this.oldValue = this.data[this.propertyName];
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateValue(newValue: EThreeStepCheckboxStates): void {
    this.data[this.propertyName] = newValue;
    this.oldValue = newValue;
    this.tableService.triggerCellEvent<EThreeStepCheckboxStates>(
      ThreeStepCellComponent.selector,
      ThreeStepCellComponent.selected,
      newValue,
    );
  }

  setTableService(tableService: Recall2TableService): void {
    this.tableService = tableService;
    this.tableService.registerCellEvent<EThreeStepCheckboxStates>(
      ThreeStepCellComponent.selector,
      ThreeStepCellComponent.selected,
    );
    this.tableService.registerCellEvent<boolean>(
      ThreeStepCellComponent.selector,
      ThreeStepCellComponent.isFormSubmitted,
    );
  }
}

export class ThreeStepCellDataBinding implements IThreeStepBinding {
  constructor(
    public propertyName: string,
    public readonly: boolean,
    public readOnlyByPropertyName?: string,
    public validator?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
  ) {}
}

interface IThreeStepBinding {
  propertyName: string;
  readonly: boolean;
  readOnlyByPropertyName?: string;
}
