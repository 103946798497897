import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { Manufacturer } from '../../models/manufacturer';

@Component({
  selector: 'recall2-choose-manufacturer',
  templateUrl: './choose-manufacturer.component.html',
  styleUrls: ['./choose-manufacturer.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, NgClass, MatTooltipModule, TranslateModule],
})
export class ChooseManufacturerComponent {
  @Input() manufacturerEntries: Manufacturer[];
  @Input() selectedManufacturerEntries: Manufacturer[] = [];
  @Input() defaultManufacturerEntry: Manufacturer;
  @Input() forwardedManufacturerIds: number[] = [];
  @Output() updateManufacturer = new EventEmitter<Manufacturer>();

  constructor(private readonly cdr: ChangeDetectorRef) {}

  selectManufacturer(id: number): void {
    this.manufacturerEntries.forEach(entry => {
      if (entry.id === id && !this.isDefaultEntry(id) && !this.isDisabledManufacturer(id)) {
        this.updateManufacturer.emit(entry);
      }
    });

    this.cdr.markForCheck();
  }

  isSelected(id: number): boolean {
    let result = false;

    this.selectedManufacturerEntries.forEach(entry => {
      if (entry.id === id) {
        result = true;
      }
    });

    return result;
  }

  isDefaultEntry(id: number, defaultManufacturerEntry: Manufacturer = this.defaultManufacturerEntry): boolean {
    if (
      defaultManufacturerEntry !== undefined &&
      defaultManufacturerEntry !== null &&
      defaultManufacturerEntry.id === id
    ) {
      return true;
    }

    return false;
  }

  private isDisabledManufacturer(id: number): boolean {
    return this.forwardedManufacturerIds.includes(id);
  }
}
