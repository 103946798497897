<ng-container *ngIf="!property.readOnly; else readonly">
  <div class="wrapper-label" *ngIf="property.hasTitle">
    <label
      id="{{ property.name }}_label"
      class="title-label mar-bottom-erase rc2-ellipsis"
      data-testid="title-label"
      [class.title-label--disabled]="property.control.disabled || isDisabled"
    >
      {{ property.translationKey + '.label' | translate }}
    </label>
    <recall2-icon-required
      *ngIf="property.required"
      [isDisabled]="property.control.disabled || isDisabled"
    ></recall2-icon-required>
    <recall2-icon-help
      *ngIf="property.hasTooltip"
      id="{{ property.name }}_help_icon"
      matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
      matTooltipPosition="above"
      [matTooltipClass]="tooltipClass"
    >
    </recall2-icon-help>
  </div>

  <div class="rc2-position--relative">
    <div #contentLeft class="content-left">
      <ng-content select="[content-left]"></ng-content>
    </div>
  </div>
  <input
    #inputText
    id="{{ property.name }}"
    data-testid="main-input"
    [formControl]="property.control"
    placeholder="{{ property.translationKey + '.placeholder' | translate }}"
    type="text"
    maxlength="{{ property?.htmlValidators?.maxLength }}"
    class="rc2-font--body--m rc2-ellipsis"
    [ngClass]="{
      'error-input': showErrors && !property.showErrorOnTooltip,
      'show-error-input-on-tooltip': showErrors && property.showErrorOnTooltip,
      'with-icon': icon.children.length > 0,
      'with-content-left': contentLeft.children.length > 0
    }"
    [recall2UpperCase]="isUpperCase"
    [recall2AllowedCharacters]="property.allowedCharacters"
    (change)="change()"
    (keydown)="keydown($event)"
    (keypress)="keypress($event)"
    (keyup)="keyup($event)"
    (focus)="focus($event)"
    (blur)="blur($event)"
    (focusout)="focusout($event)"
    autocomplete="off"
    [attr.disabled]="isDisabled ? 'disabled' : null"
  />
  <div class="rc2-position--relative">
    <div #icon class="overlay-icon-right">
      <ng-content></ng-content>
    </div>
  </div>
  <div *ngIf="property.control.value && isClearable && !isDisabled" class="rc2-position--relative">
    <div matSuffix class="clear-wrapper">
      <recall2-svg-icon
        class="rc2-icon--cursor--pointer"
        data-cy="input-clear-icon"
        name="clear"
        size="medium"
        (click)="onDelete($event)"
      >
      </recall2-svg-icon>
    </div>
  </div>

  <ng-container *ngIf="showErrors">
    <p *ngIf="!property.showErrorOnTooltip; else showErrorOnTooltip" id="error_feedback_message" class="error-message">
      <recall2-icon-invalid></recall2-icon-invalid>
      {{ 'form.error.generic.' + firstErrorType | translate }}
    </p>
  </ng-container>
</ng-container>

<ng-template #readonly>
  <label
    *ngIf="property.hasTitle"
    for="{{ property.name }}"
    class="title-label mar-bottom-erase"
    data-testid="title-label-readonly"
  >
    {{ property.translationKey + '.label' | translate }}
  </label>
  <label class="readonly-label" data-testid="main-content-readonly">
    {{ property.control.value }}
  </label>
</ng-template>

<ng-template #showErrorOnTooltip>
  <p class="show-error-input-on-tooltip-warning-icon">
    <recall2-svg-icon
      matTooltipPosition="above"
      matTooltip="{{ 'form.error.generic.required' | translate }}"
      matTooltipClass="rc2-tooltip-error"
      name="warning"
      size="medium"
      class="rc2-color--danger"
    ></recall2-svg-icon>
  </p>
</ng-template>
