import { ConnectedPosition } from '@angular/cdk/overlay';
import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';

import { App } from '../navigation/models/app';
import { Recall2LoadingSpinnerComponent } from '../loading-spinner';
import { MarkEmptyPipe } from '../pipes';
import { Recall2AttachmentTemplateComponent } from './components/object/recall2-attachment-template/recall2-attachment-template.component';
import { Recall2ObjectTemplateComponent } from './components/object/recall2-object-template/recall2-object-template.component';
import { Recall2OverlayContainerComponent } from './components/recall2-overlay-container/recall2-overlay-container.component';
import { Recall2OverlayRendererDirective } from './directives/recall2-overlay-renderer.directive';
import { EOverlayIconType } from './models/overlay-icon-type.model';
import { EOverlayTemplates } from './models/overlay-template.model';
import { EOverlayTriggerAction } from './models/trigger-action.model';

@Component({
  selector: 'recall2-overlay',
  templateUrl: './recall2-overlay.component.html',
  host: {
    'data-cy': 'overlay',
  },
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    MarkEmptyPipe,
    Recall2LoadingSpinnerComponent,
    Recall2AttachmentTemplateComponent,
    Recall2ObjectTemplateComponent,
    Recall2OverlayContainerComponent,
    Recall2OverlayRendererDirective,
  ],
})
export class Recall2OverlayComponent {
  @Input() iconType: EOverlayIconType;
  @Input() itemsCount;
  @Input() position: ConnectedPosition;
  @Input() itemsList = [];
  @Input() isSpinnerShowing;
  @Input() targetApp: App;
  @Input() overlayTemplateType: EOverlayTemplates;
  @Input() urlPath: string;
  @Input() overlayTriggerAction: EOverlayTriggerAction = EOverlayTriggerAction.OnHover;
  @Input() downloadAllAttachments?: () => void;

  @Output() closeTemplate: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(Recall2OverlayRendererDirective) overlayDirective;
  @HostBinding('class') public classes = 'rc2-display--inline--block rc2-vertical-align--middle';
  readonly overlayTemplateTypes = EOverlayTemplates;
  isMouseOnTemplate = false;

  onMouseLeftParent(): void {
    if (this.overlayTriggerAction !== EOverlayTriggerAction.OnHover) {
      return;
    }
    setTimeout(() => {
      if (!this.isMouseOnTemplate) {
        this.overlayDirective.closeOverlay();
        this.closeTemplate.emit();
      }
    }, 100);
  }

  onMouseLeftTemplate(): void {
    if (this.overlayTriggerAction !== EOverlayTriggerAction.OnHover) {
      return;
    }
    this.overlayDirective.closeOverlay();
    this.isMouseOnTemplate = false;
    this.closeTemplate.emit();
  }

  onMouseEnteredTemplate(): void {
    this.isMouseOnTemplate = true;
  }
}
