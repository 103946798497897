import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import type { AutocompleteGroup, AutocompleteOption } from '../../../form/model';
import type { IRecall2FilterAutocompleteConfig, Recall2FilterV2Config } from '../../../overlay/models/filter.model';

@Pipe({ name: 'filterAutocompleteOptionTranslationKey', standalone: true })
export class FilterAutocompleteOptionTranslationKeyPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: string[] | number[] | Date[] | boolean[],
    identifier: string,
    filterConfigList: Recall2FilterV2Config[],
  ): Observable<string> {
    const filterConfigForParam: IRecall2FilterAutocompleteConfig = filterConfigList.find(
      config => config.identifier === identifier,
    ) as IRecall2FilterAutocompleteConfig;

    if (!filterConfigForParam.items || filterConfigForParam.items.length === 0) {
      return of('');
    }

    let values: string[];

    if (Object.prototype.hasOwnProperty.call(filterConfigForParam.items[0], 'group')) {
      const options: AutocompleteOption[] = (filterConfigForParam.items as AutocompleteGroup[]).reduce(
        (previousValue, currentValue) => {
          return [...previousValue, ...currentValue.options];
        },
        [],
      );

      values = value.map(val => options.find(item => item.value === val)?.key);
    } else {
      values = value.map(
        val => (filterConfigForParam.items as AutocompleteOption[]).find(item => item.value === val)?.key,
      );
    }

    return this.translateService
      .stream(values)
      .pipe(map(mappedValueArray => Object.values(mappedValueArray).join(', ')));
  }
}
