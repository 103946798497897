import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import type { AfterViewInit, OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import type { ValidatorFn } from '@angular/forms';
import { FormControl, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Recall2ButtonPrimaryComponent } from '../../../buttons/components/recall2-button-primary/recall2-button-primary.component';
import { Recall2ChipComponent } from '../../../form/components/recall2-chip';
import { Recall2InputTextComponent } from '../../../form/components/recall2-input-text';
import { Recall2RadioButtonGroupV2Component } from '../../../form/components/recall2-radio-button-group-v2';
import type { RadioButtonV2GroupProperty, RadioButtonV2Property } from '../../../form/model';
import { CheckboxProperty, InputNumberProperty, InputTextProperty } from '../../../form/model';
import {
  Recall2FilterAutocompleteComponent,
  Recall2FilterCheckboxComponent,
  Recall2FilterDateComponent,
  Recall2FilterNumberComponent,
  Recall2FilterYearComponent,
} from '../../../overlay';
import type {
  IRecall2FilterAutocompleteParam,
  IRecall2FilterNumberParam,
  IRecall2FilterParam,
  IRecall2FilterRadioConfig,
  IRecall2FilterSelectableValue,
  IRecall2FilterSelectV2Config,
  IRecall2FilterV2Config,
  Recall2FilterV2Config,
} from '../../../overlay/models/filter.model';
import {
  EBooleanFilterComparator,
  EFilterTemplates,
  ESelectFilterComparator,
  ETextFilterComparator,
} from '../../../overlay/models/filter.model';
import { isDateFilterButtonEnabled } from '../../utils/table-filters.utils';

const DEFAULT_TEXT_FILTER_VALIDATOR = [
  Validators.pattern(/^[\d-_ ,.:A-Za-zÀ-ÿĄąĆćČčĎďĐđĘęĚěĞğİıŁłŃńŇňŐőŒœŘřŚśŠšţŤťŰűŸŹźŻżŽžǧ]+$/),
];

@Component({
  selector: 'recall2-table-filter-overlay-layout',
  templateUrl: './table-filter-overlay-layout.component.html',
  styleUrls: ['./table-filter-overlay-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Recall2RadioButtonGroupV2Component,
    Recall2ButtonPrimaryComponent,
    Recall2InputTextComponent,
    Recall2FilterNumberComponent,
    Recall2FilterYearComponent,
    Recall2FilterAutocompleteComponent,
    Recall2FilterDateComponent,
    Recall2FilterCheckboxComponent,
    Recall2ChipComponent,
    TranslateModule,
    NgTemplateOutlet,
    NgClass,
    NgIf,
    NgFor,
    NgSwitch,
    NgSwitchCase,
  ],
})
export class TableFilterOverlayLayoutComponent implements AfterViewInit, OnDestroy {
  readonly EFilterTemplates = EFilterTemplates;

  filterConfiguration: Recall2FilterV2Config;
  filterParam: IRecall2FilterParam;
  textProperty: InputTextProperty;
  yearProperty: InputNumberProperty;
  checkboxesList: CheckboxProperty[];
  radioButtonGroup: RadioButtonV2GroupProperty;
  MIN_YEAR = 1970;
  MAX_YEAR = new Date().getFullYear();
  isButtonEnabled = true;

  @ViewChild(Recall2InputTextComponent) inputTextComponent: Recall2InputTextComponent;
  @Output() filterUpdate = new EventEmitter<IRecall2FilterParam>();

  private hasRemovedTextValue = false;
  private hasRemovedYearValue = false;
  private hasComponentEmitted = false;
  private destroy$ = new Subject<void>();

  constructor(private cdr: ChangeDetectorRef) {}

  setFilterConfigurationAndActiveFilter(
    filterConfiguration: Recall2FilterV2Config,
    activeFilter: IRecall2FilterParam,
  ): void {
    this.filterConfiguration = filterConfiguration;

    this.filterParam = activeFilter ? { ...activeFilter } : this.generateDefaultFilterParam(filterConfiguration);

    this.setInitialFields();
  }

  ngAfterViewInit(): void {
    if (this.filterConfiguration?.type === EFilterTemplates.TEXT) {
      this.inputTextComponent.setFocus();
    }
  }

  onDateFilterChange(filter: IRecall2FilterParam): void {
    this.filterParam = filter;
    this.setIsDateFilterButtonEnabled();
  }

  onClickApply(): void {
    switch (this.filterConfiguration.type) {
      case EFilterTemplates.SELECT:
      case EFilterTemplates.CUSTOM_SELECT:
        this.loadSelectFilterParam();
        break;
      case EFilterTemplates.TEXT:
        this.loadTextFilterParam();
        break;
      case EFilterTemplates.RADIO:
        this.loadRadioFilterParam();
        break;
      case EFilterTemplates.YEAR:
        this.loadYearFilterParam();
        break;
    }

    this.filterUpdate.emit(this.filterParam);
    this.hasComponentEmitted = true;
  }

  removeTextValue(valueIndex: number): void {
    this.filterParam.value = (this.filterParam.value as string[]).filter((_value, index) => index !== valueIndex);

    this.hasRemovedTextValue = true;
    this.setIsTextFilterButtonEnabled();
  }

  ngOnDestroy(): void {
    if (!this.hasComponentEmitted) {
      this.filterUpdate.emit();
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  removeYearValue(valueIndex: number): void {
    this.filterParam.value = (this.filterParam.value as string[]).filter((_value, index) => index !== valueIndex);

    this.hasRemovedYearValue = true;
    this.setIsYearFilterButtonEnabled();
  }

  onFilterNumberChange(filterParam: IRecall2FilterNumberParam): void {
    this.filterParam = filterParam;
    this.setIsNumberFilterButtonEnabled();
  }

  onFilterAutoCompleteChange(filterParam: IRecall2FilterAutocompleteParam): void {
    this.filterParam = filterParam;
    this.setIsAutocompleteFilterButtonEnabled();
  }

  private generateDefaultFilterParam(filterConfig: IRecall2FilterV2Config): IRecall2FilterParam {
    if (filterConfig.type === EFilterTemplates.AUTOCOMPLETE) {
      return { ...filterConfig, comparator: null, value: [] };
    }

    const { identifier, type, translationKey } = filterConfig;

    return { identifier, type, translationKey, comparator: null, value: [] };
  }

  private setInitialFields(): void {
    switch (this.filterConfiguration.type) {
      case EFilterTemplates.SELECT:
      case EFilterTemplates.CUSTOM_SELECT:
        this.setInitialSelectValues();
        break;
      case EFilterTemplates.DATE:
        this.setIsDateFilterButtonEnabled();
        break;
      case EFilterTemplates.TEXT:
        this.setInitialTextValues();
        break;
      case EFilterTemplates.NUMBER:
        this.setIsNumberFilterButtonEnabled();
        break;
      case EFilterTemplates.AUTOCOMPLETE:
        this.setIsAutocompleteFilterButtonEnabled();
        break;
      case EFilterTemplates.RADIO:
        this.setInitialRadioValues();
        break;
      case EFilterTemplates.YEAR:
        this.setInitialYearValues();
        break;
    }
  }

  private setInitialTextValues(): void {
    this.textProperty = this.getTextProperty();

    this.isButtonEnabled = false;

    this.textProperty.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setIsTextFilterButtonEnabled();
    });
  }

  private setInitialYearValues(): void {
    this.yearProperty = this.getYearProperty();

    this.isButtonEnabled = false;

    this.yearProperty.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setIsYearFilterButtonEnabled();
    });
  }

  private setInitialSelectValues(): void {
    const filterParamValue = this.filterParam.value as string[];

    this.checkboxesList = (this.filterConfiguration as IRecall2FilterSelectV2Config).itemsList.map(element =>
      this.getCheckboxProperty(element, filterParamValue.includes(element.value)),
    );
  }

  private setInitialRadioValues(): void {
    const radioProperties = (this.filterConfiguration as IRecall2FilterRadioConfig).itemsList.map(item =>
      this.getRadioProperty(item),
    );

    this.radioButtonGroup = this.getRadioButtonV2GroupProperty(radioProperties, this.filterParam.value[0] as string);

    this.setIsRadioFilterButtonEnabled();

    this.radioButtonGroup.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.setIsRadioFilterButtonEnabled();
    });
  }

  private loadTextFilterParam(): void {
    const textValue = this.textProperty.control.value;

    this.filterParam.comparator = ETextFilterComparator.EQUALS;

    if (textValue.length > 0) {
      this.filterParam.value = [...this.filterParam.value, textValue];
    }
  }

  private loadYearFilterParam(): void {
    const numberValue = this.yearProperty.control.value;

    this.filterParam.comparator = ETextFilterComparator.EQUALS;

    if (Number(numberValue)) {
      this.filterParam.value = [...this.filterParam.value, numberValue];
    }
  }

  private loadSelectFilterParam(): void {
    this.filterParam.comparator = ESelectFilterComparator.EQUALS;
    this.filterParam.value = this.checkboxesList.filter(prop => prop.control.value).map(property => property.name);
  }

  private loadRadioFilterParam(): void {
    this.filterParam.comparator = EBooleanFilterComparator.EQUALS;
    this.filterParam.value = [this.radioButtonGroup.control.value];
  }

  private getTextProperty(): InputTextProperty {
    let validators: ValidatorFn | ValidatorFn[] = DEFAULT_TEXT_FILTER_VALIDATOR;

    if (this.filterConfiguration.type === EFilterTemplates.TEXT && this.filterConfiguration?.validators) {
      validators = this.filterConfiguration.validators;
    }

    return new InputTextProperty(
      'text-input',
      false,
      'shared.table-filters.text-property',
      null,
      null,
      new FormControl('', validators),
      false,
    );
  }

  private getYearProperty(): InputNumberProperty {
    return new InputNumberProperty(
      'year-input',
      false,
      'shared.table-filters.year-property',
      null,
      null,
      new FormControl(''),
      false,
    );
  }

  private getCheckboxProperty(item: IRecall2FilterSelectableValue, isChecked: boolean): CheckboxProperty {
    return new CheckboxProperty(
      item.value,
      false,
      item.translationKey,
      false,
      false,
      new FormControl(isChecked),
      item.value,
    );
  }

  private getRadioProperty(item: IRecall2FilterSelectableValue): RadioButtonV2Property {
    return item.notTranslate
      ? {
          translationKey: item.translationKey,
          rawText: item.translationKey,
          value: item.value,
        }
      : {
          translationKey: item.translationKey,
          value: item.value,
        };
  }

  private getRadioButtonV2GroupProperty(options: RadioButtonV2Property[], value: string): RadioButtonV2GroupProperty {
    return { name: 'radio-filter', control: new FormControl(value), options, translationKey: null };
  }

  private setIsTextFilterButtonEnabled(): void {
    this.isButtonEnabled =
      (this.textProperty?.control?.value?.trim().length > 0 && this.textProperty?.control?.valid) ||
      this.hasRemovedTextValue;
  }

  private setIsYearFilterButtonEnabled(): void {
    const year = this.yearProperty.control.value;
    this.isButtonEnabled = (year <= this.MAX_YEAR && year >= this.MIN_YEAR) || this.hasRemovedYearValue;
  }

  private setIsNumberFilterButtonEnabled(): void {
    this.isButtonEnabled = !!this.filterParam.comparator && Number.parseFloat(this.filterParam.value.toString()) >= 0;
  }

  private setIsAutocompleteFilterButtonEnabled(): void {
    this.isButtonEnabled = this.filterParam.value.length > 0;
  }

  private setIsDateFilterButtonEnabled(): void {
    this.isButtonEnabled = isDateFilterButtonEnabled(this.filterParam);
    this.cdr.detectChanges();
  }

  private setIsRadioFilterButtonEnabled(): void {
    this.isButtonEnabled = this.radioButtonGroup.control.value !== null;
  }
}
