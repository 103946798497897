import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import type { LanguageCode } from '../../../i18n/enums/language-code.enum';
import type { ERole } from '../../../navbar/models/role.model';
import type { AuthUser } from '../../models/auth-user.model';
import { AuthUserType } from '../../models/auth-user-type.enum';
import type { AuthUserStoreState } from './auth-user.store';
import { AuthUserStore } from './auth-user.store';

@Injectable({
  providedIn: 'root',
})
export class AuthUserQuery extends Query<AuthUserStoreState> {
  // eslint-disable-next-line unicorn/consistent-function-scoping
  language$ = this.select(state => state.user.personalInfo.language);

  constructor(protected store: AuthUserStore) {
    super(store);
  }

  // The user is set to undefined but since an APP_INITIALIZER is used to load the user before the
  // AppComponent is initialized, "getValue().user" will never return undefined, it will
  // always return the proper data. There's no need to check if it's different from undefined
  get user(): AuthUser {
    return this.getValue().user;
  }

  get userLanguage(): LanguageCode {
    return this.user.personalInfo.language;
  }

  get roles(): ERole[] {
    return this.getValue().roles;
  }

  isGrpUser(): boolean {
    return this.user.userIdentifier.userType === AuthUserType.grp;
  }

  hasRole(role: ERole): boolean {
    return this.roles.includes(role);
  }

  /**
   * Checks whether the user has all the given roles.
   */
  hasRoles(roles: ERole[]): boolean {
    if (roles.length === 0) {
      return false;
    }
    return this.roles.filter(role => !roles.includes(role)).length === this.roles.length - roles.length;
  }
}
