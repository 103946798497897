import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { ReCall2AccordionProperties } from './recall2-accordion.properties';
import { AccordionType } from './recall2-accordion-type';
import type { Expandable } from './recall2-expandable.interface';

@Directive()
export abstract class AccordionSettings implements Expandable {
  public readonly typeDefinition = AccordionType;

  @Input()
  public abstract accordionType: AccordionType;

  @Input()
  public accordionBaseProperties: ReCall2AccordionProperties;

  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public open: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onCloseCompleted: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public onOpenCompleted: EventEmitter<void> = new EventEmitter<void>();

  public abstract expand(): void;
}
