import { NgClass, NgIf } from '@angular/common';
import type { AfterViewChecked, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import type { FormControl } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import {
  Recall2IconSelectComponent,
  Recall2IconStatusComponent,
  Recall2IconStatusUnfilledComponent,
} from '../../../../icons';
import { Recall2FilterCheckboxProperty } from './models/recall2-filter-checkbox.model';

@Component({
  selector: 'recall2-filter-checkbox',
  templateUrl: './recall2-filter-checkbox.component.html',
  styleUrls: ['./recall2-filter-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'filter-checkbox',
  },
  standalone: true,
  imports: [
    Recall2IconSelectComponent,
    FormsModule,
    NgClass,
    ReactiveFormsModule,
    TranslateModule,
    NgIf,
    Recall2IconStatusComponent,
    Recall2IconStatusUnfilledComponent,
  ],
})
export class Recall2FilterCheckboxComponent implements OnInit, AfterViewChecked {
  @Input() property: Recall2FilterCheckboxProperty;
  @Input() showIconStatus = false;
  @Output() changeCheckbox = new EventEmitter<FormControl>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    if (this.property.isChecked) {
      this.property.control.setValue(this.property.isChecked);
    }
  }

  change(): void {
    this.changeCheckbox.emit(this.property.control);
    this.property.isChecked = !this.property.isChecked;
  }
}
