import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-unselect',
  templateUrl: './recall2-icon-unselect.component.html',
  standalone: true,
})
export class Recall2IconUnselectComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i90-unselect');
  }
}
