/* eslint-disable unicorn/consistent-function-scoping */
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import type { LanguagesStoreState } from './languages.store';
import { LanguagesStore } from './languages.store';

@Injectable({
  providedIn: 'root',
})
export class LanguagesQuery extends Query<LanguagesStoreState> {
  constructor(protected store: LanguagesStore) {
    super(store);
  }

  languages$ = this.select(state => state.languages);
}
