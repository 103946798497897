import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2IconLogoRecallComponent } from '@recall2/ui/icons';
import { TabTitleService } from '@recall2/ui/tab-title';

import { CreateNotificationButtonComponent } from '../components/create-notification-button/create-notification-button.component';

@Component({
  selector: 'welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  standalone: true,
  imports: [TranslateModule, CreateNotificationButtonComponent, Recall2IconLogoRecallComponent],
})
export class WelcomePageComponent implements OnInit {
  constructor(private tabTitleService: TabTitleService) {}

  ngOnInit(): void {
    this.tabTitleService.setTitleByKey('preliminary.welcome.tab-title');
  }
}
