import type { ConnectedPosition } from '@angular/cdk/overlay';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import type { ValidationErrors } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SVGIconsRegistry } from '@recall2/icons';

import { Recall2ButtonPrimaryComponent, Recall2ButtonTertiaryComponent } from '../buttons';
import { Recall2DatepickerCommonComponent } from '../datepicker/recall2-datepicker-common.component';
import { InputNumberProperty } from '../form/model';
import { Recall2IconCalendarComponent, Recall2IconInvalidComponent, Recall2IconRequiredComponent } from '../icons';

@Component({
  selector: 'recall2-year-picker',
  templateUrl: './recall2-year-picker.component.html',
  styleUrls: ['./recall2-year-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatTooltipModule,
    FormsModule,
    CdkOverlayOrigin,
    ReactiveFormsModule,
    CdkConnectedOverlay,
    NgFor,
    NgClass,
    Recall2ButtonTertiaryComponent,
    Recall2ButtonPrimaryComponent,
    TranslateModule,
    Recall2IconRequiredComponent,
    Recall2IconInvalidComponent,
    Recall2IconCalendarComponent,
  ],
})
export class Recall2YearPickerComponent
  extends Recall2DatepickerCommonComponent<InputNumberProperty>
  implements OnInit
{
  @Input() property: InputNumberProperty;
  @Input() isDisabled: boolean;
  @Input() isFormSubmitted: boolean;

  @ViewChild('input') input: ElementRef;
  @ViewChild('calendar', { read: ElementRef }) calendarIcon: ElementRef;

  isOpen = false;
  inputHasFocus = false;
  selected = false;
  years = [];
  selectedYear = new Date().getFullYear();
  overlayWidth = 312;
  overlayHeight = 314;
  MIN_YEAR = 1970;
  position: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      offsetY: -24,
      offsetX: 328,
    },
  ];

  constructor(
    protected iconsRegistry: SVGIconsRegistry,
    private cdr: ChangeDetectorRef,
    protected elementRef: ElementRef,
  ) {
    super(iconsRegistry);
  }

  ngOnInit(): void {
    this.years = this.getYearsArray();
  }

  get errors(): ValidationErrors {
    return this.property.control.errors;
  }

  get showErrors(): boolean {
    if (typeof this.isFormSubmitted === 'undefined') {
      return this.property.control.invalid && (this.property.control.dirty || this.property.control.touched);
    }

    return this.isFormSubmitted && !!this.errors;
  }

  onSelectYear(selectedYear: number, event: MouseEvent): void {
    this.selectedYear = selectedYear;
    this.setControlAsDirty();
    this.cdr.detectChanges();
    this.onClickOutside(event);
    this.property.control.setValue(selectedYear);
    this.input.nativeElement.focus();
    this.isOpen = false;
  }

  onCurrentYearSelection(event: MouseEvent): void {
    this.selectedYear = new Date().getFullYear();
    this.onSelectYear(this.selectedYear, event);
  }

  private getYearsArray(): number[] {
    const currentYear = new Date().getFullYear();
    const firstYear = this.MIN_YEAR;
    const years: number[] = [];

    for (let i = firstYear; i <= currentYear; i++) {
      years.push(i);
    }

    return years;
  }
}
