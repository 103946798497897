import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AppsLinkDirective } from '../../../navigation/components/apps-link/apps-link.directive';
import { App } from '../../../navigation/models/app';
import { Recall2IconCloseComponent, Recall2IconUserComponent } from '../../../icons';

@Component({
  selector: 'recall2-my-data-menu',
  templateUrl: './my-data-menu.component.html',
  styleUrls: ['./my-data-menu.component.scss'],
  standalone: true,
  imports: [AppsLinkDirective, TranslateModule, Recall2IconCloseComponent, Recall2IconUserComponent],
})
export class MyDataMenuComponent {
  readonly USER_PROFILE_URL = '/profile';
  readonly APP_NAME = App.UserManagement;

  @Output() logout = new EventEmitter<void>();

  onLogout(): void {
    this.logout.emit();
  }
}
