import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { iconCloseMedium, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';

import { Recall2ButtonPrimaryComponent, Recall2ButtonTertiaryComponent } from '../../../buttons';
import { CommonModalData } from '../../models/common-modal-data.model';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, SVGIconModule, Recall2ButtonPrimaryComponent, Recall2ButtonTertiaryComponent, NgClass],
})
export class CommonModalComponent {
  constructor(
    public dialogRef: MatDialogRef<CommonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonModalData,
    private iconsRegistry: SVGIconsRegistry,
  ) {
    this.iconsRegistry.registerIcons([iconCloseMedium]);
  }

  onConfirm(event: Event): void {
    event.stopPropagation();
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
