  <div
    *ngIf="property.hasLabel && labelAlignment === alignment.TOP"
    class="row"
  >
    <label
      id="{{ property.name }}_label"
      class="mar-bottom-erase left checkbox-input-label-top"
      [ngClass]="{
                    'is-danger': isFormSubmitted && property.control.errors &&
                    !property.control.disabled,
                    'selected-deselected-checkbox-label':
                     property.control.value === checkboxStates.DESELECTED &&
                     property.control.value !== checkboxStates.MIXED,
                    'disabled-label': property.control.disabled
                }"
    >
      {{ property.translationKey + '.label' | translate }}
      <recall2-icon-required *ngIf="property.required"></recall2-icon-required>
    </label>
    <recall2-icon-help
      *ngIf="property.hasTooltip"
      id="{{ property.name }}_help_icon"
      matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
      matTooltipPosition="above"
      matTooltipClass="rc2-tooltip"
    >
    </recall2-icon-help>
  </div>
  <div class="row">
    <div *ngIf="property.hasLabel && labelAlignment === alignment.LEFT">
      <label
        id="{{ property.name }}_label"
        class="mar-bottom-erase left checkbox-input-label-left"
        [ngClass]="{
                    'is-danger': isFormSubmitted && property.control.errors &&
                    !property.control.disabled,
                    'selected-deselected-checkbox-label':
                     property.control.value === checkboxStates.DESELECTED &&
                     property.control.value !== checkboxStates.MIXED,
                    'disabled-label': property.control.disabled
                  }"
      >
        {{ property.translationKey + '.label' | translate }}
        <recall2-icon-required *ngIf="property.required"></recall2-icon-required>
      </label>
      <recall2-icon-help
        *ngIf="property.hasTooltip"
        class="pad-right-small"
        id="{{ property.name }}_help_icon"
        matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
        matTooltipPosition="above"
        matTooltipClass="rc2-tooltip"
      >
      </recall2-icon-help>
    </div>
    <div class="col-checkboxes">
      <div
        class="input-checkbox checkmark-unselected"
        id="{{ property.name }}_UNSELECTED"
        (click)="onClick(checkboxStates.UNSELECTED)"
        [ngClass]="{
                    'checkmark-unselected-hover': !property.control.disabled,
                    'disableDiv-unselected': property.control.disabled,
                    selected: property.control.value === checkboxStates.UNSELECTED || property.control.value === checkboxStates.MIXED,
                    danger: isFormSubmitted && property.control.errors &&
                    !property.control.disabled
                }"
      >
        <span *ngIf="property.control.value === checkboxStates.MIXED">
          <recall2-icon-part-select [rc2IconStyles]="'rc2-font--size--20'"></recall2-icon-part-select>
        </span>
      </div>
      <div
        class="input-checkbox checkmark-selected"
        id="{{ property.name }}_SELECTED"
        (click)="onClick(checkboxStates.SELECTED)"
        [ngClass]="{
                    'checkmark-selected-hover' : !property.control.disabled,
                    'disableDiv-selected': property.control.disabled,
                    selected: property.control.value === checkboxStates.SELECTED ||  property.control.value === checkboxStates.MIXED,
                    danger: isFormSubmitted && property.control.errors &&
                    !property.control.disabled
                  }"
      >
        <span *ngIf="!(property.control.value === checkboxStates.MIXED)">
          <recall2-icon-select [rc2IconStyles]="'rc2-font--size--20'"></recall2-icon-select>
        </span>
        <span *ngIf="property.control.value === checkboxStates.MIXED">
          <recall2-icon-part-select [rc2IconStyles]="'rc2-font--size--20'"></recall2-icon-part-select>
        </span>
      </div>
      <div
        class="input-checkbox checkmark-deselected"
        id="{{ property.name }}_DESELECTED"
        (click)="onClick(checkboxStates.DESELECTED)"
        [ngClass]="{
                    'checkmark-deselected-hover' : !property.control.disabled,
                    'disableDiv-deselected': property.control.disabled,
                    selected: property.control.value === checkboxStates.DESELECTED || property.control.value === checkboxStates.MIXED,
                    danger: isFormSubmitted && property.control.errors &&
                    !property.control.disabled
                  }"
      >
        <span *ngIf="property.control.value !== checkboxStates.MIXED">
          <recall2-icon-unselect [rc2IconStyles]="'rc2-font--size--20'"></recall2-icon-unselect>
        </span>
        <span *ngIf="property.control.value === checkboxStates.MIXED">
          <recall2-icon-part-select [rc2IconStyles]="'rc2-font--size--20'"></recall2-icon-part-select>
        </span>
      </div>
    </div>
    <div class="icon-container">
      <recall2-icon-warning
        *ngIf="isFormSubmitted && property.control.errors &&
              !property.control.disabled"
        id="{{ property.name }}_warning"
        [rc2IconStyles]="'rc2-font--size--20'"
        class="is-danger warning-padding"
      ></recall2-icon-warning>
    </div>
    <div *ngIf="property.hasLabel && labelAlignment === alignment.RIGHT">
      <label
        id="{{ property.name }}_label"
        class="mar-bottom-erase left checkbox-input-label-right"
        [ngClass]="{
                    'is-danger': isFormSubmitted && property.control.errors &&
                    !property.control.disabled,
                    'selected-deselected-checkbox-label':
                     property.control.value === checkboxStates.DESELECTED && property.control.value !== checkboxStates.MIXED,
                    'disabled-label': property.control.disabled
                }"
      >
        {{ property.translationKey + '.label' | translate }}
        <recall2-icon-required *ngIf="property.required"></recall2-icon-required>
      </label>
      <recall2-icon-help
        *ngIf="property.hasTooltip"
        id="{{ property.name }}_help_icon"
        matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
        matTooltipPosition="above"
        matTooltipClass="rc2-tooltip"
      >
      </recall2-icon-help>
    </div>
  </div>
