import { Injectable } from '@angular/core';

import type { InitialTableConfig } from './table.service';
import { TableService } from './table.service';

@Injectable({ providedIn: 'root' })
export class TableServiceFactory {
  tableServiceInstances: Map<string, TableService> = new Map<string, TableService>();

  has(tableId: string): boolean {
    return this.tableServiceInstances.has(tableId);
  }

  get(tableId: string): TableService {
    return this.tableServiceInstances.get(tableId);
  }

  add(tableId: string, initialTableConfig?: InitialTableConfig): TableService {
    if (this.has(tableId)) {
      return this.get(tableId);
    }

    const tableService = new TableService(tableId, initialTableConfig);
    this.tableServiceInstances.set(tableId, tableService);

    return tableService;
  }

  delete(tableId: string): boolean {
    if (this.has(tableId)) {
      const tableService = this.get(tableId);

      tableService.onDestroy();
    }

    return this.tableServiceInstances.delete(tableId);
  }
}
