import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RECALL2_FEATURE_FLAG_BASE_API_URL } from '../../feature-flag/base-api-url';
import {
  ShowConcurrentNotificationAction,
  ShowErrorPopupAction,
  ShowUnauthorizedErrorPopupAction,
} from '../store/errorhandling.actions';
import type { IErrorState } from '../store/errorhandling.state';

const NOT_FOUND = 404;
const BAD_REQUEST = 400;
const UNAUTHORIZED = 401;
const CONFLICT = 409;

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  private store: Store<IErrorState> = inject(Store);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError(error => {
        this.showPopupAction(req, error);

        return throwError(() => error);
      }),
    );
  }

  private notATranslationRequest(req: HttpRequest<unknown>): boolean {
    return !req.url.includes('/translation');
  }

  private isFeatureFlagAPI(req: HttpRequest<unknown>): boolean {
    return req.url.includes(RECALL2_FEATURE_FLAG_BASE_API_URL);
  }

  private shouldDisplayError(error: HttpErrorResponse, req: HttpRequest<unknown>): boolean {
    return error instanceof HttpErrorResponse && this.notATranslationRequest(req);
  }

  private showPopupAction(req: HttpRequest<unknown>, error: HttpErrorResponse): void {
    if (this.isFeatureFlagAPI(req)) {
      return;
    }

    if (error.status === UNAUTHORIZED) {
      this.store.dispatch(new ShowUnauthorizedErrorPopupAction());
      return;
    }

    if (error.status === CONFLICT) {
      const objectType = req.url?.split('/')[2].replace(/[_-]/g, ' ');
      this.store.dispatch(new ShowConcurrentNotificationAction(null, objectType));
      return;
    }

    if (error.status === NOT_FOUND && error.error && error.error.message && error.error.message.messageId) {
      this.store.dispatch(new ShowErrorPopupAction(error.error.message.messageId, error.error.message?.messageParams));
      return;
    }

    if (error.status === BAD_REQUEST && error.error && error.error.i18nKey) {
      this.store.dispatch(new ShowErrorPopupAction(error.error.i18nKey, error.error.i18nParams));
      return;
    }

    if (this.shouldDisplayError(error, req)) {
      this.store.dispatch(new ShowErrorPopupAction());
      return;
    }
  }
}
