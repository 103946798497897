import { Component } from '@angular/core';

import { Recall2ImgBaseComponent } from '../recall2-img-base/recall2-img-base.component';

@Component({
  selector: 'recall2-img-bugatti',
  templateUrl: './recall2-img-bugatti.component.html',
  standalone: true,
})
export class Recall2ImgBugattiComponent extends Recall2ImgBaseComponent {}
