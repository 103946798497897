<div class="table-wrapper">
  <div class="rc2-mar--no">
    <div class="pad-bottom-erase">
      <!-- Table definition -->
      <table
        #table
        [dataSource]="tableData"
        cdkDropList
        [cdkDropListData]="tableData"
        (cdkDropListDropped)="onListDrop($event)"
        [cdkDropListDisabled]="!hasDragAndDrop"
        class="mar-no is-full-width"
        mat-table
        [trackBy]="trackBy ? trackByMethod : null"
        [attr.table-type]="type"
        multiTemplateDataRows
      >
        <caption></caption>
        <ng-container *ngFor="let column of columnDefinitions;">
          <ng-container [matColumnDef]="column.id">
            <th
              *matHeaderCellDef
              [ngClass]="{
                'rc2-color--primary':isSortingEnabled || activeFilters?.length > 0
                }"
              class="rc2-font--body--s rc2-color--grey-900 rc2-position--relative {{column.columnSize}} "
              mat-header-cell
              id="th-for-id-{{column?.id}}"
            >
              <ng-container *ngIf="!column.dynamicCellHeaderComponent">
                {{column?.headerTranslationKey | translate}}
              </ng-container>
              <ng-container *ngIf="column.dynamicCellHeaderComponent">
                <recall2-dynamic-content
                  [component]="column.dynamicCellHeaderComponent"
                  [dataBinding]="column?.headerDataBinding"
                  [tableFacade]="tableFacade"
                >
                </recall2-dynamic-content>
              </ng-container>
              <span *ngIf="column.isSortable && isSortingEnabled">
                <recall2-icon-sort-down-inactive
                  *ngIf="getSortingOrder(column.id) === sortingState.DESC && !isPrimarySorting(column.id)"
                  (click)="applySorting(column.id, sortingState.ASC)"
                  [rc2IconStyles]="'rc2-icon--size--30'"
                ></recall2-icon-sort-down-inactive>
                <recall2-icon-sort-up-inactive
                  *ngIf="getSortingOrder(column.id) === sortingState.ASC && !isPrimarySorting(column.id)"
                  (click)="applySorting(column.id, sortingState.DESC)"
                  [rc2IconStyles]="'rc2-icon--size--30'"
                ></recall2-icon-sort-up-inactive>
                <recall2-icon-sort-down-active
                  *ngIf="getSortingOrder(column.id) === sortingState.DESC && isPrimarySorting(column.id)"
                  (click)="applySorting(column.id, sortingState.ASC)"
                  [rc2IconStyles]="'rc2-icon--size--30'"
                ></recall2-icon-sort-down-active>
                <recall2-icon-sort-up-active
                  *ngIf="getSortingOrder(column.id) === sortingState.ASC && isPrimarySorting(column.id)"
                  (click)="applySorting(column.id, sortingState.DESC)"
                  [rc2IconStyles]="'rc2-icon--size--30'"
                ></recall2-icon-sort-up-active>
              </span>
            </th>
            <td
              *matCellDef="let element; let i = dataIndex;"
              class="rc2-font--body--m {{column.columnSize}}"
              mat-cell
              id="td-for-id-{{element?.id}}"
            >
              <ng-container *ngIf="column?.dynamicCellComponent">
                <recall2-dynamic-content
                  [index]="i"
                  [component]="column.dynamicCellComponent"
                  [data]="element"
                  [replaceData]="column.replaceData"
                  [dataBinding]="column.dataBinding"
                  [tableFacade]="tableFacade"
                >
                </recall2-dynamic-content>
              </ng-container>
            </td>
          </ng-container>
        </ng-container>

        <!-- ExpandableContent Column -->
        <ng-container matColumnDef="expandableContent">
          <td
            *matCellDef="let element; let i = dataIndex;"
            mat-cell
            [colSpan]="columnDefinitions.length"
            class="expandable-content rc2-overflow--hidden pad-no"
            id="td-for-expandable-id-{{element?.id}}"
          >
            <div
              *ngIf="expandableContentDefinition && expandedObjectIdSet?.has(element.id)"
              [@detailExpand]="expandedObjectIdSet?.has(element.id) ? 'expanded' : 'collapsed'"
            >
              <recall2-dynamic-content
                [component]="expandableContentDefinition?.expandableContentComponent"
                [data]="element"
                [dataBinding]="expandableContentDefinition?.dataBinding"
                [tableFacade]="tableFacade"
                [index]="i"
              >
              </recall2-dynamic-content>
            </div>
          </td>
        </ng-container>

        <tr
          *matHeaderRowDef="$any(displayedColumns)"
          mat-header-row
          class="rc2-border--bottom--grey--200-tertiary rc2-align-items--center"
          [ngClass]="{
            'header-row-height': type ==tableTypes.Object
            }"
          id="header-for-id-{{displayedColumns?.id}}"
        ></tr>
        <tr
          *matRowDef="let element; columns: $any(displayedColumns)"
          mat-row
          cdkDrag
          [cdkDragData]="element"
          [cdkDragDisabled]="isElementDraggable && !isElementDraggable(element) || false"
          class="rc2-border--bottom--grey--200-tertiary rc2-align-items--center"
          [attr.scrollId]="element?.id"
          (click)="triggerExpansion(element)"
          [ngClass]="{
            highlighted: highlightedObjectIdSet?.has(element.id),
            focused: element.id && (expandedObjectIdSet?.has(element.id) || expansionQueryParamIdentifier == element.id),
            'selected': element?.affected === threeStepCheckboxStates.SELECTED && selectionEnable,
            'deselected': element?.affected === threeStepCheckboxStates.DESELECTED && selectionEnable,
            'row-height': type ===tableTypes.Object
          }"
          id="row-for-id-{{element?.id}}"
        ></tr>
        <tr
          *matRowDef="let element; columns: ['expandableContent']"
          mat-row
          [ngClass]="{'expanded' : expandedObjectIdSet?.has(element.id)}"
          class="expandable-content-row pad-no"
          id="row-for-expandable-id-{{element?.id}}"
        ></tr>

      </table>
    </div>
  </div>
</div>
