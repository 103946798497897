import type { OnDestroy, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppTranslationService } from '@recall2/ui/i18n';
import { ReCall2FormCancelModalComponent } from '@recall2/ui/overlay';
import { TabTitleService } from '@recall2/ui/tab-title';
import type { IUser } from '@recall2/user';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../../../rest-api/notification/notification.service';
import type { IAppState } from '../../../../store/app/app.state';
import { pathParamNotificationId } from '../../../routing/notification-routes.constants';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';
import type { NotificationConfirmComponent } from '../notification-confirm/notification-confirm.component';

@Injectable({ providedIn: 'root' })
export abstract class NotificationBaseComponent implements OnInit, OnDestroy {
  readonly src = 'notification-base.component.ts';

  abstract confirmComponent: NotificationConfirmComponent;

  selectedUser: IUser;
  comment: string;
  currentLocale: string;
  notificationId: number;
  isSending: boolean;
  showErrors: boolean;
  protected destroyed$ = new Subject<void>();

  constructor(
    private modal: MatDialog,
    public store: Store<IAppState>,
    public notificationService: NotificationService,
    public routingService: NotificationRoutingService,
    public activatedRoute: ActivatedRoute,
    protected tabTitleService: TabTitleService,
    private translationService: AppTranslationService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(takeUntil(this.destroyed$))
      .subscribe(params => (this.notificationId = +params.get(pathParamNotificationId)));
    this.translationService.currentLanguage$.pipe(takeUntil(this.destroyed$)).subscribe(currentLocale => {
      this.currentLocale = currentLocale;
    });
    this.setBrowserTabTitle();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  setSelectedUser(user: IUser): void {
    this.selectedUser = user;
  }

  setComment(comment: string): void {
    this.comment = comment;
  }

  abstract isSubmitOk(): boolean;

  confirmResetAction(title: string, callback: () => void): void {
    this.modal.open(ReCall2FormCancelModalComponent, {
      panelClass: 'recall2-form-cancel-modal',
      backdropClass: 'modal-backdrop',
      data: {
        submitCallback: () => callback(),
        title,
      },
    });
  }

  async performAction<T>(action$: () => Observable<T>, navigateCallback: (data: T) => void): Promise<void> {
    this.showErrors = true;
    if (this.isSubmitOk()) {
      this.isSending = true;
      action$()
        .pipe(first())
        .subscribe(
          data => {
            this.isSending = false;
            navigateCallback(data);
          },
          () => (this.isSending = false),
        );
    }
  }

  private setBrowserTabTitle(): void {
    this.notificationService
      .getNotificationForId(this.notificationId)
      .subscribe(notification =>
        this.tabTitleService.setTitleByKeyWithIdName(
          'preliminary.notification.tab-title',
          '' + notification.id,
          notification.title,
        ),
      );
  }
}
