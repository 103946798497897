<div
  class="rc2-display--flex rc2-align-items--center rc2-mar--no"
  [title]="nameForToolTip"
>
  <div
    class="rc2-display--inline--block rc2-vertical-align--middle rc2-pad--right--3xs"
    *ngIf="name"
  >
    <recall2-brand-logo
      [brandId]="brandId"
      [rc2IconStyles]="logoSize"
    >
    </recall2-brand-logo>
  </div>
  <span
    class="rc2-vertical-align--middle rc2-font--body--m rc2-ellipsis {{ additionalCssClasses?.join(' ') }}"
    data-testid="manufacturerName"
  >
    {{name | markEmpty}}
  </span>
</div>
