import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2IconHelpComponent } from '../../../icons/recall2-icon-help/recall2-icon-help.component';
import { Recall2IconRequiredComponent } from '../../../icons/recall2-icon-required/recall2-icon-required.component';
import type { RadioButtonV2Property } from '../../model';
import { RadioButtonV2GroupProperty } from '../../model';

interface CustomContext {
  $implicit: number;
}

@Component({
  selector: 'recall2-radio-button-group-v2',
  standalone: true,
  templateUrl: './recall2-radio-button-group-v2.component.html',
  styleUrls: ['./recall2-radio-button-group-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgClass,
    NgFor,
    NgTemplateOutlet,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule,
    Recall2IconHelpComponent,
    Recall2IconRequiredComponent,
  ],
})
export class Recall2RadioButtonGroupV2Component {
  @Input() property: RadioButtonV2GroupProperty;
  @Input() isFormSubmitted = false;
  @Input() template: TemplateRef<CustomContext>;
  @Input() radioContainerClass = false;
  @Output() radioChanged = new EventEmitter<string | boolean>();

  onRadioButtonChange(radioButtonSelected: RadioButtonV2Property): void {
    this.radioChanged.emit(radioButtonSelected.value);
  }
}
