import { DOCUMENT } from '@angular/common';
import type { OnChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, Input, Optional } from '@angular/core';

import { SVGIconsRegistry } from '../svg-icons-registry.service';

@Component({
  selector: 'recall2-svg-icon',
  template: '',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SVGIconComponent implements OnChanges {
  private svgIcon!: SVGElement | null;

  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() name = '';
  @Input() dataCy = '';

  private sizeMap = new Map([
    ['small', '16px'],
    ['medium', '24px'],
    ['large', '32px'],
  ]);

  constructor(
    private element: ElementRef,
    private svgRegistry: SVGIconsRegistry,
    @Optional() @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnChanges(): void {
    if (this.svgIcon) {
      this.svgIcon.remove();
    }
    const name = `${this.name}-${this.size}`;
    const svgData = this.svgRegistry.getIcon(name);
    if (!svgData) {
      console.warn(`Icon '${name}' not found, did you add it to the Icon registry?`);
      return;
    }
    this.svgIcon = this.svgElementFromString(svgData);
    this.element.nativeElement.append(this.svgIcon);
  }

  private svgElementFromString(svgContent: string): SVGElement | null {
    const div = this.document.createElement('DIV');
    div.innerHTML = svgContent;
    const element = div.querySelector('svg');
    element?.setAttribute('width', <string>this.sizeMap.get(this.size));
    element?.setAttribute('height', <string>this.sizeMap.get(this.size));
    element?.setAttribute('fill', 'currentColor');
    element?.setAttribute('data-cy', this.dataCy);
    return element;
  }
}
