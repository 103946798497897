import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { FieldSorting } from '../../../core/api/models/sortable-request-options';
import { TableSortingOrder } from '../../models/table-sorting-order.enum';
import type { TableSortHeaderDirective } from './table-sort-header.directive';

@Directive({
  selector: '[appTableSort]',
  standalone: true,
})
export class TableSortDirective {
  private sortHeaderDirectivesList: TableSortHeaderDirective[] = [];

  @Input()
  sortedColumn: FieldSorting;

  @Output()
  sortChange = new EventEmitter<FieldSorting>();

  sort(sortHeader: TableSortHeaderDirective): void {
    if (sortHeader.tableSortComponent.currentSortOrder === TableSortingOrder.None) {
      this.setCurrentSelected(null);
      this.sortChange.emit(null);
      return;
    }

    const sortChange = {
      field: sortHeader.tableSortComponent.column,
      orderBy: sortHeader.tableSortComponent.currentSortOrder,
    };
    this.setCurrentSelected(sortChange);
    this.sortChange.emit(sortChange);
  }

  registerSortHeaderDirective(sortHeader: TableSortHeaderDirective): void {
    this.sortHeaderDirectivesList.push(sortHeader);

    if (this.sortedColumn?.field === sortHeader.tableSortComponent.column) {
      this.setCurrentSelected(this.sortedColumn);
    }
  }

  setCurrentSelected(sortChange: FieldSorting): void {
    this.sortHeaderDirectivesList.forEach((sortHeader: TableSortHeaderDirective) => {
      sortHeader.tableSortComponent.updateSelected(sortChange);
    });
  }
}
