<ng-container [ngSwitch]="filterConfiguration?.type">
  <div *ngSwitchCase="EFilterTemplates.TEXT">
    <ng-container *ngTemplateOutlet="text"></ng-container>
  </div>
  <div *ngSwitchCase="EFilterTemplates.NUMBER">
    <ng-container *ngTemplateOutlet="number"></ng-container>
  </div>
  <div *ngSwitchCase="EFilterTemplates.DATE">
    <ng-container *ngTemplateOutlet="date"></ng-container>
  </div>
  <div *ngSwitchCase="EFilterTemplates.SELECT" class="checkboxes-container">
    <ng-container *ngTemplateOutlet="select"></ng-container>
  </div>
  <div *ngSwitchCase="EFilterTemplates.CUSTOM_SELECT" class="checkboxes-container">
    <ng-container *ngTemplateOutlet="customSelect"></ng-container>
  </div>
  <div *ngSwitchCase="EFilterTemplates.RADIO">
    <ng-container *ngTemplateOutlet="radio"></ng-container>
  </div>
  <div *ngSwitchCase="EFilterTemplates.YEAR">
    <ng-container *ngTemplateOutlet="year"></ng-container>
  </div>
  <div *ngSwitchCase="EFilterTemplates.AUTOCOMPLETE">
    <ng-container *ngTemplateOutlet="autocomplete"></ng-container>
  </div>
</ng-container>

<div class="button-container" [ngClass]="{ 'with-box-shadow': filterConfiguration?.type === EFilterTemplates.SELECT }">
  <recall2-button-primary
    (click)="onClickApply()"
    [isDisabled]="!isButtonEnabled"
    data-cy="filters-overlay-apply-button"
  >
    {{ 'shared.apply' | translate }}
  </recall2-button-primary>
</div>

<!--TEMPLATES-->

<ng-template #text>
  <recall2-input-text
    data-cy="filters-overlay-text-input"
    [property]="textProperty"
    [ngClass]="{ 'extra-margin': !filterParam.value.length }"
  ></recall2-input-text>
  <div *ngIf="filterParam.value.length" class="chips-container" data-cy="filters-overlay-chips">
    <recall2-chip *ngFor="let val of $any(filterParam.value); let i = index" (remove)="removeTextValue(i)">
      {{ val }}
    </recall2-chip>
  </div>
</ng-template>

<ng-template #number>
  <div class="filter-number-container">
    <recall2-filter-number
      data-cy="filters-overlay-number"
      (selectedFilter)="onFilterNumberChange($event)"
      [emitFilterChangeOnDestroy]="false"
      [currentSelectedFilter]="$any(filterParam)"
    ></recall2-filter-number>
  </div>
</ng-template>

<ng-template #autocomplete>
  <div class="rc2-mar--bottom--s">
    <recall2-filter-autocomplete
      data-cy="filters-overlay-autocomplete"
      [currentFilterSelected]="$any(filterParam)"
      (filterSelected)="onFilterAutoCompleteChange($event)"
    ></recall2-filter-autocomplete>
  </div>
</ng-template>

<ng-template #customSelect>
  <recall2-filter-checkbox
    data-cy="filters-overlay-checkboxes"
    *ngFor="let checkbox of checkboxesList"
    [property]="checkbox"
  >
    <ng-container *ngIf="filterConfiguration.customTemplate">
      <ng-container *ngTemplateOutlet="filterConfiguration.customTemplate; context: { $implicit: checkbox }">
      </ng-container>
    </ng-container>
  </recall2-filter-checkbox>
</ng-template>

<ng-template #select>
  <recall2-filter-checkbox
    data-cy="filters-overlay-checkboxes"
    *ngFor="let checkbox of checkboxesList"
    [property]="checkbox"
  ></recall2-filter-checkbox>
</ng-template>

<ng-template #radio>
  <div class="rc2-mar--bottom--s">
    <recall2-radio-button-group-v2
      data-cy="filters-overlay-radio"
      [property]="radioButtonGroup"
    ></recall2-radio-button-group-v2>
  </div>
</ng-template>

<ng-template #date>
  <recall2-filter-date
    data-cy="filters-overlay-date-range"
    [currentSelectedFilter]="$any(filterParam)"
    (filterChanged)="onDateFilterChange($event)"
  ></recall2-filter-date>
</ng-template>

<ng-template #year>
  <recall2-filter-year data-cy="filters-overlay-year" [selectedYear]="yearProperty"></recall2-filter-year>
  <div *ngIf="filterParam.value.length" class="chips-container" data-cy="filters-overlay-chips">
    <recall2-chip *ngFor="let val of $any(filterParam.value); let i = index" (remove)="removeYearValue(i)">
      {{ val }}
    </recall2-chip>
  </div>
</ng-template>
