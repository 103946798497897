import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MoreFiltersScrollService {
  private scrollSubject$ = new Subject();

  scroll$ = this.scrollSubject$.asObservable();

  sendScrollEvent(): void {
    this.scrollSubject$.next(null);
  }
}
