import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-close-window',
  templateUrl: './recall2-icon-close-window.component.html',
  styleUrls: ['./recall2-icon-close-window.component.scss'],
  standalone: true,
})
export class Recall2IconCloseWindowComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i31-close-window');
  }
}
