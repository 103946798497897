import type { Provider } from '@angular/core';

import { AppsService } from '../navigation/services/apps.service';
import type { UserFlowConfig } from './guard/user-flow-guard.service';
import { USER_FLOW_CONFIG, UserFlowGuard } from './guard/user-flow-guard.service';
import { KnownUserProvider } from './services/known.user-provider/known-user-provider.service';

export const userFlowGuardFactory = (
  config: UserFlowConfig,
  knownUserProvider: KnownUserProvider,
  appsService: AppsService,
): UserFlowGuard => {
  return new UserFlowGuard(config, knownUserProvider, appsService);
};

export const USER_FLOW_GUARD_PROVIDER: Provider = {
  provide: UserFlowGuard,
  useFactory: userFlowGuardFactory,
  deps: [USER_FLOW_CONFIG, KnownUserProvider, AppsService],
};
