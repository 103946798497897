import { AsyncPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { iconArrowDownMedium, iconArrowUpMedium, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type { FieldSorting } from '../core/api/models/sortable-request-options';
import type { IRecall2FilterParam, Recall2FilterV2Config } from '../overlay/models/filter.model';
import { FunctionToBooleanPipe } from '../pipes/function-to-boolean/function-to-boolean.pipe';
import { NestedPropertyPipe } from '../pipes/nested-property/nested-property.pipe';
import { ActiveFiltersComponent } from './components/active-filters/active-filters.component';
import { MoreFiltersComponent } from './components/more-filters/more-filters.component';
import { OverviewTablePaginatorComponent } from './components/overview-table-paginator/overview-table-paginator.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableColumnFiltersDirective } from './directives/table-column-filters/table-column-filters.directive';
import { TableSortDirective } from './directives/table-sort/table-sort.directive';
import type { TablePageEvent } from './models/table-page-event.model';
import type { TableService } from './services/table.service';
import { TableServiceFactory } from './services/table-factory.service';
import { detailExpandAnimation } from './table.animation';
import type { TableColumnConfig } from './table-column-config.interface';

const DEFAULT_SCROLL_TIMEOUT = 1000;

interface CustomContext {
  $implicit: number;
}

@Component({
  selector: 'recall2-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [detailExpandAnimation],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MoreFiltersComponent,
    ActiveFiltersComponent,
    MatTableModule,
    TableSortDirective,
    TableColumnFiltersDirective,
    NgFor,
    NgTemplateOutlet,
    TableHeaderComponent,
    MatTooltipModule,
    SVGIconModule,
    OverviewTablePaginatorComponent,
    AsyncPipe,
    TranslateModule,
    NestedPropertyPipe,
    FunctionToBooleanPipe,
  ],
})
export class TableComponent<T> implements OnChanges, OnDestroy {
  @Input() set config(tableConfig: TableColumnConfig[]) {
    this.tableConfig = tableConfig;
    this.displayedColumns = tableConfig.map(item => item.id);
    this.hasFooter = tableConfig.some(item => !!item.footerTemplate);
  }
  @Input() set tableId(tableKey: string) {
    this.initTable(tableKey);
  }
  @Input() data: T[] = [];
  @Input() filterConfig: Recall2FilterV2Config[] = [];
  @Input() expandedTemplate: TemplateRef<CustomContext>;
  @Input() emptyDataTranslationKey = 'common.table.no-data';
  @Input() emptyDataFilterTranslationKey = 'common.table.no-data.filter.title';
  @Input() emptyDataFilterHintTranslationKey = 'common.table.no-data.filter.hint';
  @Input() isLoading = false;
  @Input() isPageable = false;
  @Input() showMoreFilters = true;
  @Input() showPageSize = true;
  @Input() expandConditionFn = (_: T): boolean => true;
  @Input() isHighlightEnabled = false;
  @Input() size: 'small' | 'medium' = 'medium';

  tableService: TableService;
  activeFilters$: Observable<IRecall2FilterParam[]>;
  hasActiveFilters: boolean;
  sorting$: Observable<FieldSorting>;
  displayedColumns: string[] = [];
  tableConfig: TableColumnConfig[];
  tableKey: string;
  expandedElement: T;
  hasFooter = false;

  private destroy$ = new Subject<void>();
  private checkedParams = false;

  constructor(
    private iconsRegistry: SVGIconsRegistry,
    private tableFactory: TableServiceFactory,
  ) {
    this.iconsRegistry.registerIcons([iconArrowDownMedium, iconArrowUpMedium]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isHighlightEnabled && changes.data?.currentValue?.length > 0) {
      this.watchQueryParams();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSortChange(sortChange: FieldSorting): void {
    this.tableService.updateSort(sortChange);
    this.expandedElement = null;
  }

  onFilterChange(filterChange: IRecall2FilterParam): void {
    this.tableService.addFilter(filterChange, true);
    this.expandedElement = null;
  }

  onPageSizeChange(elements: number): void {
    this.tableService.updatePageSize(elements);
    this.expandedElement = null;
  }

  onPageChange(pageEvent: TablePageEvent): void {
    this.tableService.updateCurrentPage(pageEvent.pageIndex);
    this.expandedElement = null;
  }

  toggleRow(element: T, event: MouseEvent): void {
    if (this.displayedColumns.includes('expand') && this.expandConditionFn(element)) {
      this.expandedElement = this.expandedElement === element ? null : element;
      event.stopPropagation();
    }
  }

  trackByFn(_index: number, item: TableColumnConfig): string {
    return item.id;
  }

  private initTable(tableKey: string): void {
    this.tableKey = tableKey;
    this.tableService = this.tableFactory.add(tableKey);
    this.activeFilters$ = this.tableService.activeFilters$.pipe(takeUntil(this.destroy$));
    this.sorting$ = this.tableService.activeSort$.pipe(takeUntil(this.destroy$));

    this.watchClearFilters();
    this.tableService.activeFilters$.pipe(takeUntil(this.destroy$)).subscribe(filters => {
      this.hasActiveFilters = filters.length > 0;
    });
  }

  private watchQueryParams(): void {
    const urlParams = this.getUrlParams();
    const id = urlParams.get('highlight') ?? urlParams.get('scroll') ?? urlParams.get('expand');
    this.highlightItem(id);
    this.scrollToItem();
  }

  private watchClearFilters(): void {
    const urlParams = this.getUrlParams();
    const isClearFilter = urlParams.get('clearfilter') === 'true';

    if (isClearFilter && !this.checkedParams) {
      this.tableService.activeFilters$.next([]);
      this.checkedParams = true;
    }
  }

  private highlightItem(id: string): void {
    const item: T = this.data.find(element => element['id'] === +id);

    if (item) {
      this.toggleRow(item, new MouseEvent('click'));
    }
  }

  private scrollToItem(): void {
    setTimeout(() => {
      const expandedRow = document.querySelector('.expanded-row');

      if (expandedRow) {
        expandedRow.scrollIntoView({ behavior: 'smooth' });
      }
    }, DEFAULT_SCROLL_TIMEOUT);
  }

  private getUrlParams(): URLSearchParams {
    const queryParams: string = location.search;
    return new URLSearchParams(queryParams);
  }
}
