import { Component } from '@angular/core';

import { Recall2ImgBaseComponent } from '../recall2-img-base/recall2-img-base.component';

@Component({
  selector: 'recall2-img-bentley',
  templateUrl: './recall2-img-bentley.component.html',
  standalone: true,
})
export class Recall2ImgBentleyComponent extends Recall2ImgBaseComponent {}
