<div
  (clickOutside)="onClickedElsewhere()"
  class="date-picker"
>
  <div class="date-picker-content">
    <button
      [disabled]="!clickOnPreviousMonthPossible()"
      [class.hidden]="showYearSelector"
      class="icon-button rc2-font--size--24"
      (click)="showPreviousMonth()"
      data-testId="guic-date-picker-prev-month"
    >
      <recall2-svg-icon
        name="arrow-left"
        size="medium"
      ></recall2-svg-icon>
    </button>
    <div class="header year-selector-toggle" (click)="toggleYearSelector()">
      <span
        class="rc2-font--family--bold"
        data-testId="guic-date-picker-month"
      >
        {{displayedDate | date: 'MMMM yyyy':undefined:getLocale()}}
      </span>
      <recall2-svg-icon *ngIf="!showYearSelector"
        class="year-selector-toggle-icon"
        name="arrow-down"
        size="medium"
      ></recall2-svg-icon>
      <recall2-svg-icon *ngIf="showYearSelector"
        class="year-selector-toggle-icon"
        name="arrow-up"
        size="medium"
      ></recall2-svg-icon>
    </div>
    <button
      [disabled]="!clickOnNextMonthPossible()"
      [class.hidden]="showYearSelector"
      class="icon-button rc2-font--size--24"
      (click)="showNextMonth()"
      data-testId="guic-date-picker-next-month"
    >
      <recall2-svg-icon
        name="arrow-right"
        size="medium"
      ></recall2-svg-icon>
    </button>
  </div>
  <ng-container [ngTemplateOutlet]="yearSelector"></ng-container>
  <div class="date-picker-grid" *ngIf="!showYearSelector">
    <div
      class="date-picker-headline rc2-font--family--bold"
      *ngFor="let headerVal of columnHeadersTranslationKeys; let i = index"
      [ngClass]="{'calendar-week': i === 0}"
    >
      {{headerVal | translate}}
    </div>

    <ng-container *ngFor="let week of displayedWeeks">
      <div
        class="calendar-week rc2-font--family--bold"
        data-testId="guic-date-picker-calendar-week"
      >
        {{week.calendarWeek}}
      </div>
      <div
        data-testId="guic-date-picker-day"
        class="date-picker-day rc2-font--family--regular"
        *ngFor="let day of week.days"
        (mouseenter)="hover(day)"
        (mouseleave)="hover(undefined)"
        (click)="selectDay(day, $event)"
        [ngClass]="buildClasses(day)"
      >
        <div class="highlight-wrapper">{{day.day}}</div>
      </div>
    </ng-container>
  </div>

  <div class="action-buttons-container" *ngIf="!showYearSelector">
    <recall2-button-tertiary (click)="onClickCancel()">
      {{ 'shared.date-picker.cancel-button' | translate }}
    </recall2-button-tertiary>
    <recall2-button-primary
      [isDisabled]="
        (minDateObject && minDateObject > todayDate) ||
        (maxDateObject && maxDateObject < todayDate)
      "
      (click)="selectToday()"
    >
      {{ 'shared.date-picker.today-button' | translate }}
    </recall2-button-primary>
  </div>
</div>

<ng-template #yearSelector>
  <div
    *ngIf="showYearSelector"
    class="year-selector">
    <div
      class="year"
      *ngFor="let year of years"
      (click)="!disabledYears[year] && onYearSelected(year)"
      [attr.id]="'year_' + year"
      [ngClass]="{
        'year--current': year === currentYear,
        'year--selected': year === selectedYear,
        'year--disabled': disabledYears[year]
      }"
    >{{ year }}</div>
  </div>
</ng-template>
