import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Recall2TextareaComponent } from '@recall2/ui/form/components/recall2-textarea';
import type { InputTextProperty } from '@recall2/ui/form/model';

@Component({
  selector: 'recall2-notification-comment',
  templateUrl: './notification-comment.component.html',
  standalone: true,
  imports: [Recall2TextareaComponent],
})
export class NotificationCommentComponent implements OnInit {
  @Input() isRequired = false;
  @Input() isFormSubmitted = false;
  @Output() onValueChanged = new EventEmitter<string>();

  commentProperty: InputTextProperty;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.commentProperty = {
      name: 'comment',
      required: this.isRequired,
      translationKey: 'history.comment-input',
      htmlValidators: { maxLength: 1024 },
      hasTooltip: false,
      control: this.formBuilder.control('', [Validators.required, Validators.maxLength(1024)]),
      hasTitle: this.isRequired,
    };
  }

  onChanged(): void {
    this.onValueChanged.emit(this.commentProperty.control.valid ? this.commentProperty.control.value : undefined);
  }
}
