<recall2-input-text
  *ngIf="property?.name"
  #recallInputText
  id="recall-auto-suggest"
  data-cy="autosuggestInput"
  [property]="property"
  [isFormSubmitted]="isFormSubmitted"
  [isDisabled]="isDisabled"
  (onFocus)="onInputFocus()"
  (onKeyUp)="onInputKeyUp()"
>

  <recall2-icon-arrow-down
    *ngIf="isArrowIconDown"
    (click)="toggleArrowIcon()"
    [ngClass]="isDisabled ? 'rc2-color--grey-400-secondary' : 'rc2-color--grey-600'"
  >
  </recall2-icon-arrow-down>
  <recall2-icon-arrow-up
    *ngIf="!isArrowIconDown"
    (click)="toggleArrowIcon()"
    class="rc2-color--primary"
  >
  </recall2-icon-arrow-up>
</recall2-input-text>
