import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'recall2-loading-spinner',
  templateUrl: './recall2-loading-spinner.component.html',
  styleUrls: ['./recall2-loading-spinner.component.scss'],
  standalone: true,
  imports: [NgIf, MatProgressSpinnerModule],
})
export class Recall2LoadingSpinnerComponent {
  @Input() spinnerDiameter = 40;
  @Input() forceShowing = false;
  @Input() src: string;
  isAnimating = false;
}
