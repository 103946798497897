/**
 * Replaces the placeholders in a string
 * with the values in the object. The
 * placeholders correspond to the key names,
 *
 * A placeholder is defined with curly braces,
 *
 * @example
 * ```ts
 * interpolate('This is {interpolated}', { interpolated: 'cool!' })` // returns 'This is cool!'
 * ```
 *
 * @param value String with placeholders
 * @param object Object with keys to be interpolated
 *
 * @returns The input string with replaced placeholders
 */
export const interpolate = (value: string, object: object): string => {
  if (!object || typeof object !== 'object') {
    return value;
  }
  Object.keys(object).forEach(key => {
    value = value.replace(`{${key}}`, object[key]);
  });
  return value || '';
};

/**
 * Returns a new object with only the keys
 * that satisfy the predicate.
 *
 * @param o The object
 * @param predicate Predicate function
 */
export const filterObject = <T, K extends keyof T>(o: T, predicate: (key: K) => boolean): Partial<T> =>
  Object.keys(o)
    .filter(key => !!predicate(key as K))
    .reduce((obj, nextKey) => ({ ...obj, [nextKey]: o[nextKey] }), {} as Partial<T>);
