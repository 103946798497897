import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { Recall2IconStatusComponent, Recall2IconStatusUnfilledComponent } from '../../icons';
import { EStatus } from './model/status.model';

@Component({
  selector: 'recall2-status',
  templateUrl: './status.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgSwitch,
    NgSwitchCase,
    TranslateModule,
    Recall2IconStatusComponent,
    Recall2IconStatusUnfilledComponent,
  ],
  providers: [TranslateService],
})
export class Recall2StatusComponent {
  allStatus = EStatus;

  @Input() currentStatus: EStatus;
  @Input() isTopic: boolean;
  @Input() isCampaign: boolean;
}
