import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[recall2UpperCase]',
  standalone: true,
})
export class Recall2UpperCaseDirective {
  @Input('recall2UpperCase') isUpperCase: boolean;

  constructor(private readonly control: NgControl) {}

  @HostListener('input', ['$event.target'])
  onInput(input: HTMLInputElement): void {
    if (this.isUpperCase) {
      const caretPos = input.selectionStart;
      this.control.control.setValue(input.value.toUpperCase());
      input.setSelectionRange(caretPos, caretPos);
    }
  }
}
