import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'nestedProperty',
  standalone: true,
})
export class NestedPropertyPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/ban-types
  transform(object: object, path: string): string {
    const pathArr = path.split('.');
    return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined), object);
  }
}
