import { CommonModule } from '@angular/common';
import type { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';

import { BrandService } from './brand/brand.service';
import { NotificationService } from './notification/notification.service';
import { UserService } from './user/user.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class RestAPIModule {
  static forRoot(): ModuleWithProviders<RestAPIModule> {
    return {
      ngModule: RestAPIModule,
      providers: [NotificationService, BrandService, UserService],
    };
  }
}
