<div
  class="rc2-font--body--s rc2-color--grey-900 rc2-ellipsis"
  data-testid="object-type"
>
  {{ translationKey | translate | uppercase }}
</div>
<div
  class="rc2-font--body--xs rc2-color--grey-600 rc2-ellipsis"
  data-testid="object-id"
>
  {{ data[propertyName] }}
</div>
