import { Injectable } from '@angular/core';
import type { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { UserSelectors } from '../../user/store/user.selectors';
import type { UserData } from '../../user/store/user.state';
import { NotificationRoutingService } from '../routing/notification-routing.service';

@Injectable()
export class AuthGuard  {
  constructor(
    private store: Store<UserData>,
    private routingService: NotificationRoutingService,
  ) {}

  canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkRole(activatedRoute, state);
  }

  private checkRole(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(UserSelectors.getUser),
      filter(user => !!user),
      map(user => user && user.roles.includes(route.data.role)),
      tap(hasRole => {
        if (!hasRole) {
          const notificationId = +state.root.firstChild.params.notificationId;
          this.routingService.navigateToBaseRouteNotification(notificationId);
        }
      }),
    );
  }
}
