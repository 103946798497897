import { Inject, Injectable, InjectionToken } from '@angular/core';
import type { ActivatedRouteSnapshot } from '@angular/router';
import type { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import type { FullyQualifiedUrl } from '../../navigation/models/fully-qualified-url';
import { AppsService } from '../../navigation/services/apps.service';
import { KnownUserProvider } from '../services/known.user-provider/known-user-provider.service';

export const USER_FLOW_CONFIG = new InjectionToken<UserFlowConfig>('USER_FLOW_CONFIG');

export interface UserGuardOptions {
  allowInactiveUsers: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserFlowGuard  {
  static setOptions(options: UserGuardOptions): UserGuardOptions {
    return options;
  }

  constructor(
    @Inject(USER_FLOW_CONFIG) private config: UserFlowConfig,
    private knownUserProvider: KnownUserProvider,
    private appsService: AppsService,
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const { allowInactiveUsers } = next.data as UserGuardOptions;

    return this.knownUserProvider.isKnownUser().pipe(
      map(({ isKnown, hasActiveRoles }) => this.getRedirectUrl(isKnown, hasActiveRoles, allowInactiveUsers)),
      tap(redirectUrl => redirectUrl && this.appsService.navigate(redirectUrl)),
      map(redirectUrl => !redirectUrl),
    );
  }

  private getRedirectUrl(
    isKnown: boolean,
    hasActiveRoles: boolean,
    allowInactiveUsers: boolean | undefined,
  ): FullyQualifiedUrl | undefined {
    if (!isKnown) {
      return this.config.unknownUserLink;
    } else if (!hasActiveRoles && !allowInactiveUsers) {
      return this.config.inactiveRolesLink;
    } else {
      return undefined;
    }
  }
}

export interface UserFlowConfig {
  unknownUserLink: FullyQualifiedUrl;
  inactiveRolesLink: FullyQualifiedUrl;
}
