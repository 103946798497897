export const getComputedSizes = (element: Element): { top: number; left: number; width: number; height: number } => {
  const rect = element.getBoundingClientRect();
  const win = element.ownerDocument.defaultView;
  return {
    top: rect.top + win.scrollY,
    left: rect.left + win.scrollX,
    width: rect.width,
    height: rect.height,
  };
};
