export enum EObjectType {
  NOTIFICATION = 'NOTIFICATION',
  TOPIC = 'TOPIC',
  CLEARING = 'CLEARING',
  COMMITTEE = 'COMMITTEE',
  AGENDA_ITEM = 'AGENDA_ITEM',
  VERIFICATION_TASK = 'VERIFICATION_TASK',
  MEETING = 'MEETING',
  CAMPAIGN = 'CAMPAIGN',
}
