import type { BrandManufacturer } from '@recall2/ui/brand-manufacturer';
import { EThreeStepCheckboxStates } from '@recall2/ui/form/model';
import type { PageDTO } from '@recall2/ui/pagination';

import type { INotification, INotificationForList } from '../models/notification/notification';
import { NotificationStatus } from '../models/notification/notification-status.enum';
import { initialNotificationPageState } from '../models/pagination';

export const initialCurrentNotificationState: INotification = {
  title: '',
  issue: '',
  cause: '',
  effect: '',
  descriptionMeasureProd: '',
  measureImplemented: EThreeStepCheckboxStates.UNSELECTED,
  implementationDate: null,
  descriptionMeasureAfterSales: '',
  measureAvailable: EThreeStepCheckboxStates.UNSELECTED,
  availabilityDate: null,
  warehouseUpdated: EThreeStepCheckboxStates.UNSELECTED,
  warehouseUpdateNumber: '',
  vehiclesAffected: '',
  prodDateRangesAffected: [{ id: null, firstProdDateAffected: '', lastProdDateAffected: '' }],
  affectedVehiclesCount: '',
  marketsAndRegions: '',
  status: NotificationStatus.DRAFT,
  supplierError: EThreeStepCheckboxStates.UNSELECTED,
  supplier: '',
  commentVehicles: '',
  reviewers: [],
  parts: [{ id: null, name: '', partNumbers: [] }],
  brandManufacturers: [],
  vehicleRange: [{ id: null, firstVin: '', lastVin: '' }],
  involvedUsers: [],
  createdAt: null,
};

export interface INotificationState {
  currentNotification?: INotification;
  notificationList?: INotificationList;
  brandManufacturers?: BrandManufacturer[];
}

export interface INotificationList {
  notificationPage?: PageDTO<INotificationForList>;
}

export const initialNotificationListState: INotificationList = {
  notificationPage: initialNotificationPageState,
};

export const initialNotificationState: INotificationState = {
  currentNotification: undefined,
  notificationList: initialNotificationListState,
  brandManufacturers: undefined,
};
