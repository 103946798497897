<div class="title">
  <h3 *ngIf="!data.title">
    {{ 'cancelModal.title' | translate }}
  </h3>
  <h3
    id="ownTitle"
    *ngIf="data.title"
  >
    {{data.title | translate}}
  </h3>
</div>
<div class="content rc2-font--family--regular rc2-font--size--14">
  <p
    *ngIf="!data.content"
    [innerHTML]="'cancelModal.content' | translate"
  ></p>
  <p
    id="ownContent"
    *ngIf="data.content"
    [innerHTML]="data.content | translate"
  ></p>
</div>
<div class="buttons rc2-mar--top--l">
  <recall2-button-secondary
    *ngIf="!data.disableCancelAction"
    id="form-cancel-close-btn"
    data-cy="form-cancel-close-btn"
    class="rc2-mar--right--s"
    [iconClass]="'icon-i90-unselect'"
    (click)="onCancel()"
  >
    {{ "cancelModal.cancelButton" | translate }}
  </recall2-button-secondary>
  <recall2-button-primary
    id="form-cancel-confirm-btn"
    data-cy="form-cancel-confirm-btn"
    (click)="onConfirm($event);"
  >
    {{ "cancelModal.confirmButton" | translate }}
  </recall2-button-primary>
</div>
