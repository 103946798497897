import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import { AppsLinkDirective } from '../../../navigation/components/apps-link/apps-link.directive';
import { App } from '../../../navigation/models/app';
import { AbstractIconCell } from '../../../dynamic-content/models/dynamic-content.model';
import { EThreeStepCheckboxStates } from '../../../form/model';
import { Recall2IconOpenTabComponent } from '../../../icons';

@Component({
  selector: 'open-new-tab-cell',
  templateUrl: './open-new-tab.cell.html',
  standalone: true,
  imports: [Recall2IconOpenTabComponent, NgIf, AppsLinkDirective],
})
export class OpenNewTabCellComponent extends AbstractIconCell<any> implements IOpenNewTabBinding {
  public readonly threeStepCheckboxStateSelected = EThreeStepCheckboxStates.SELECTED;
  public propertyName: string;
  public targetApp: App;
  public urlPath: string;
  public affectedPropertyName = 'affected';
  public tab?: string;
  private linkedObjectUrl = 'campaign/';

  beforeRedirectFn: () => Observable<any> = () => of(null);

  getQueryParams(): {
    tab?: string;
    scroll: any;
    expand: any;
    clearfilter: boolean;
  } {
    if (this.targetApp === App.Campaigns) {
      return;
    }
    return {
      scroll: this.data[this.propertyName],
      expand: this.data[this.propertyName],
      clearfilter: true,
      ...(this.tab ? { tab: this.tab } : {}),
    };
  }

  get linkUrlPath(): string {
    return this.targetApp === App.Campaigns
      ? `${this.urlPath}${this.linkedObjectUrl}${this.data[this.propertyName]}`
      : this.urlPath;
  }
}

export class OpenNewTabCellBinding implements IOpenNewTabBinding {
  constructor(
    public propertyName: string,
    public targetApp: App,
    public urlPath: string,
    public affectedPropertyName: string = 'affected',
    public beforeRedirectFn: () => Observable<any> = (): Observable<any> => of(null),
    public tab?: string,
  ) {}
}

interface IOpenNewTabBinding {
  propertyName: string;
  targetApp: App;
  urlPath: string;
  affectedPropertyName?: string;
}
