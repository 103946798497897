import type { Brand } from '@recall2/ui/brand-manufacturer';
import { BrandName, BrandsId } from '@recall2/ui/brand-manufacturer';

export const mockBrandManufacturer: Brand[] = [
  {
    id: BrandsId.AUDI,
    name: BrandName.AUDI,
    code: 'Audi',
  },
  {
    id: BrandsId.VOLKSWAGEN_PKW,
    name: BrandName.VOLKSWAGEN_PKW,
    code: 'V',
  },
];

export const mockBrandManufacturerDesc: Brand[] = [
  {
    id: BrandsId.VOLKSWAGEN_PKW,
    name: BrandName.VOLKSWAGEN_PKW,
    code: 'V',
  },
  {
    id: BrandsId.AUDI,
    name: BrandName.AUDI,
    code: 'Audi',
  },
];

export const mockBrandLamborghini: Brand[] = [
  {
    id: BrandsId.LAMBORGHINI,
    name: BrandName.LAMBORGHINI,
    code: 'Lam',
  },
];
