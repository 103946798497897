<app-table-paginator
  *ngIf="length > 1"
  [length]="length"
  [pageIndex]="pageIndex"
  [pageSize]="pageSize"
  [arePageButtonsDisabled]="arePageButtonsDisabled"
  [iconColor]="'rc2-icon--color--black'"
  (page)="page.next($event)"
>
  <ng-container *ngIf="showPageSize">
    <app-table-paginator-pagesize-selector
      *appTablePaginatorLeftElement
      [pageSize]="pageSize"
      (elementsPerPage)="elementsPerPage.emit($event)"
    >
    </app-table-paginator-pagesize-selector>
  </ng-container>
  <app-table-paginator-summary
    *appTablePaginatorLeftElement
    class="rc2-color--grey-700"
    [length]="length"
    [pageIndex]="pageIndex"
    [pageSize]="pageSize"
  >
  </app-table-paginator-summary>
  <app-table-paginator-current-page
    *appTablePaginatorRightElement
    [pageIndex]="pageIndex"
    [totalPages]="totalPages"
  >
  </app-table-paginator-current-page>
</app-table-paginator>
