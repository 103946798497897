import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { EThreeStepCheckboxStates } from '@recall2/ui/form/model';
import { SpecialCharsValidationService } from '@recall2/ui/form/validators';
import { ValidationStatus } from '@recall2/ui/navigation-stepper';
import type { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { EFormType } from '../../../../models/form/formType';
import type { INotification } from '../../../../models/notification/notification';
import { DescriptionFormField } from '../../model/descriptionRequiredFields';
import { MeasureFormField } from '../../model/measureRequiredFields';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService implements OnDestroy {
  private errorHandlingDescription = new BehaviorSubject<Map<string, boolean>>(new Map([]));
  public currentErrorHandlingDescription$ = this.errorHandlingDescription.asObservable();

  private errorHandlingMeasure = new BehaviorSubject<Map<string, boolean>>(new Map([]));
  public currentErrorHandlingMeasure$ = this.errorHandlingMeasure.asObservable();

  private isValidatingFields = false;

  constructor(
    private navigationService: NavigationService,
    private specialCharsValidationService: SpecialCharsValidationService,
  ) {}

  ngOnDestroy(): void {
    this.isValidatingFields = false;
  }

  public isValidForSaving(currentNotification: INotification): boolean {
    if (!this.hasValue(DescriptionFormField.Title, currentNotification)) {
      this.navigationService.setStepStatus(EFormType.description, ValidationStatus.INVALID);

      this.errorHandlingDescription.next(new Map([[DescriptionFormField.Title, true]]));

      this.navigationService.routeToFirstErrorPage();

      return false;
    }

    if (!this.specialCharsValidationService.isValid(currentNotification.title)) {
      this.errorHandlingDescription.next(new Map([[DescriptionFormField.Title, true]]));
      return false;
    }

    this.errorHandlingDescription.next(new Map([]));
    return true;
  }

  public isValidForSending(currentNotification: INotification): boolean {
    const isValidDescription = this.validateDescription(currentNotification);
    const isValidMeasure = this.validateMeasure(currentNotification);

    this.navigationService.setStepStatus(EFormType.description, this.boolToValidationStatus(isValidDescription));
    this.navigationService.setStepStatus(EFormType.measure, this.boolToValidationStatus(isValidMeasure));
    this.navigationService.setStepStatus(EFormType.vehicles, ValidationStatus.VALID);
    this.navigationService.setStepStatus(EFormType.persons, ValidationStatus.VALID);

    this.navigationService.updateNavigationStepsColoring();
    this.navigationService.routeToFirstErrorPage();

    return isValidMeasure && isValidDescription;
  }

  public enableContinuousValidation(notificationStream$: Observable<INotification>): void {
    this.isValidatingFields = true;

    notificationStream$.pipe(takeWhile(() => this.isValidatingFields)).subscribe(this.validateOnUpdate.bind(this));
  }

  private validateOnUpdate(updatedNotification: INotification): void {
    const isValidDescription = this.validateDescription(updatedNotification);
    const isValidMeasure = this.validateMeasure(updatedNotification);

    this.navigationService.setStepStatus(EFormType.description, this.boolToValidationStatus(isValidDescription));
    this.navigationService.setStepStatus(EFormType.measure, this.boolToValidationStatus(isValidMeasure));

    this.navigationService.updateNavigationStepsColoring();
  }

  private boolToValidationStatus(value: boolean): ValidationStatus {
    return value ? ValidationStatus.VALID : ValidationStatus.INVALID;
  }

  private validateDescription(notification: INotification): boolean {
    const requiredFields: string[] = [
      DescriptionFormField.Title,
      DescriptionFormField.Issue,
      DescriptionFormField.Cause,
      DescriptionFormField.Effect,
      'parts',
    ];
    const errors = new Map<string, boolean>([]);
    let isValid = true;

    for (const requiredField of requiredFields) {
      if (!this.hasValue(requiredField, notification)) {
        isValid = this.markAsInvalid(errors, requiredField);
      }
    }

    this.errorHandlingDescription.next(errors);
    return isValid;
  }

  private validateMeasure(notification: INotification): boolean {
    const requiredCheckboxes = [
      MeasureFormField.MeasureImplemented,
      MeasureFormField.MeasureAvailable,
      MeasureFormField.WarehouseUpdated,
    ];
    const requiredFields = [
      MeasureFormField.DescriptionMeasureProd,
      MeasureFormField.DescriptionMeasureAfterSales,
      MeasureFormField.BrandManufacturers,
    ];
    const errors = new Map<string, boolean>([]);

    let isValid = true;

    for (const checkbox of requiredCheckboxes) {
      if (!this.hasSelection(checkbox, notification)) {
        isValid = this.markAsInvalid(errors, checkbox);
      }
    }

    for (const field of requiredFields) {
      if (!this.hasValue(field, notification)) {
        isValid = this.markAsInvalid(errors, field);
      }
    }

    if (notification.measureImplemented === EThreeStepCheckboxStates.SELECTED && !notification.implementationDate) {
      isValid = this.markAsInvalid(errors, MeasureFormField.ImplementationDate);
    }

    if (notification.measureAvailable === EThreeStepCheckboxStates.SELECTED && !notification.availabilityDate) {
      isValid = this.markAsInvalid(errors, MeasureFormField.AvailabilityDate);
    }

    if (notification.warehouseUpdated === EThreeStepCheckboxStates.SELECTED && !notification.warehouseUpdateNumber) {
      isValid = this.markAsInvalid(errors, MeasureFormField.WarehouseUpdateNumber);
    }

    this.errorHandlingMeasure.next(errors);
    return isValid;
  }

  private hasValue(formField: string, notification: INotification): boolean {
    let hasValue = notification[formField].length > 0;
    if (hasValue && formField === 'parts') {
      hasValue = !notification.parts.some(part => part.name === '' || part.partNumbers.length === 0);
    }
    return hasValue;
  }

  private hasSelection(formField: string, notification: INotification): boolean {
    return notification[formField] !== EThreeStepCheckboxStates.UNSELECTED;
  }

  private markAsInvalid(errors: Map<string, boolean>, formField: string): boolean {
    errors.set(formField, true);

    return false;
  }
}
