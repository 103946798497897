import { Component } from '@angular/core';

import { AbstractGenericCellViewWithData } from '../../../dynamic-content/models/dynamic-content.model';
import { ManufacturerViewComponent } from '../../../img-logo';
import { getValueFromObject } from '../../../utils/functions/functions';

@Component({
  selector: 'manufacturer-cell',
  templateUrl: './manufacturer.cell.html',
  standalone: true,
  imports: [ManufacturerViewComponent],
})
export class ManufacturerCellComponent extends AbstractGenericCellViewWithData implements IManufacturerCellBinding {
  propertyName: string;
  secondPropertyName: string;
  additionalCssClasses: string[];

  get propertyValue(): any {
    return getValueFromObject(this.data, this.propertyName, '');
  }
}

export class ManufacturerCellBinding implements IManufacturerCellBinding {
  constructor(
    public propertyName: string,
    public secondPropertyName?: string /* @deprecated : append the needed properties in the first argument like: manufacturer.name */,
    public additionalCssClasses?: string[],
  ) {}
}

export interface IManufacturerCellBinding {
  propertyName: string;
  secondPropertyName?: string;
  additionalCssClasses?: string[];
}
