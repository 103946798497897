<div
  class="rc2-icon--cursor--pointer btn--brand rc2-align-items--center"
  [ngClass]="{ 'rc2-icon--cursor--not-allowed': !brand.enabled }"
  (click)="downloadDocument()"
>
  <img
    class="manufacturer-logo"
    src="./assets/vwag/svg/logo/logo-{{ brand.name }}.svg"
    alt="{{brand.name}}"
  />
</div>
