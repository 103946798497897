import { CommonModule } from '@angular/common';
import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Recall2AutocompleteComponent } from '../../../../autocomplete';
import type { AutocompleteGroup, AutocompleteOption } from '../../../../form/model';
import { AutocompleteProperty } from '../../../../form/model';
import { compareObjects } from '../../../../utils';
import { IRecall2FilterAutocompleteParam, Recall2FilterAutocompleteParam } from '../../../models/filter.model';

@Component({
  selector: 'recall2-filter-autocomplete',
  templateUrl: './recall2-filter-autocomplete.component.html',
  styleUrls: ['./recall2-filter-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MAT_AUTOCOMPLETE_SCROLL_STRATEGY_FACTORY_PROVIDER],
  standalone: true,
  imports: [CommonModule, Recall2AutocompleteComponent],
})
export class Recall2FilterAutocompleteComponent implements OnChanges, OnInit, OnDestroy {
  @Input() currentFilterSelected: IRecall2FilterAutocompleteParam;
  @Output() filterSelected = new EventEmitter<IRecall2FilterAutocompleteParam>();

  dataSource: AutocompleteGroup[] | AutocompleteOption[] = [];
  autocompleteProperty: AutocompleteProperty;
  toHighlight = '';

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.initAutoComplete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.currentFilterSelected &&
      changes.currentFilterSelected.currentValue &&
      !compareObjects(changes.currentFilterSelected.currentValue, changes.currentFilterSelected.previousValue)
    ) {
      this.setAutoCompleteValue();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initAutoComplete(): void {
    this.dataSource = this.currentFilterSelected.items;
    this.autocompleteProperty = new AutocompleteProperty(
      'autocomplete-filter',
      '',
      new FormControl(null),
      this.dataSource,
      null,
      true, // showArrowIcon
      true, // validations
      false,
      false,
      false,
      '',
      this.currentFilterSelected.placeholderKey,
    );
    this.autocompleteProperty.control.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        filter(value => typeof value === 'object' || value.length === 0),
      )
      .subscribe(() => {
        this.filterSelected.emit(this.mapToIRecall2FilterAutocompleteParam());
      });
    this.setAutoCompleteValue();
  }

  private mapToIRecall2FilterAutocompleteParam(): IRecall2FilterAutocompleteParam {
    return new Recall2FilterAutocompleteParam(
      this.currentFilterSelected.identifier,
      this.currentFilterSelected.translationKey,
      this.currentFilterSelected.placeholderKey,
      this.dataSource,
      this.getValue(),
    );
  }

  private setAutoCompleteValue(): void {
    if (this.autocompleteProperty) {
      this.dataSource = this.currentFilterSelected.items;
      const value = this.currentFilterSelected.value[0];

      if (!value || this.dataSource.length === 0) {
        this.autocompleteProperty.control.setValue(null);
        this.autocompleteProperty.optionsList = this.dataSource;
        this.autocompleteProperty = { ...this.autocompleteProperty };
        return;
      }

      const option: AutocompleteOption = this.dataSource[0]['group']
        ? (this.dataSource as AutocompleteGroup[])
            .reduce((previousValue, currentValue) => {
              return [...previousValue, ...currentValue.options];
            }, [])
            .find(option => option.value === value)
        : (this.dataSource as AutocompleteOption[]).find(option => option.value === value);
      this.autocompleteProperty.control.setValue(option);
      this.autocompleteProperty = { ...this.autocompleteProperty };
    }
  }

  private getValue(): string[] {
    const option = this.autocompleteProperty.control.value;
    if (option && typeof option === 'object') {
      return [option.value];
    }

    return [];
  }
}
