import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-required',
  templateUrl: './recall2-icon-required.component.html',
  styleUrls: ['./recall2-icon-required.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class Recall2IconRequiredComponent extends Recall2IconBaseComponent {
  @Input() isDisabled = false;

  constructor() {
    super('form-category-icon i-form');
  }
}
