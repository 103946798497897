<nav class="custom-navbar">
  <ul class="display-menu-items">
    <li
      *ngFor="let link of mainMenuLinks; index as i; trackBy: trackLink"
      data-testid="main-menu-item"
      recall2MeasureMenuItem
      (widthChanged)="updateWidth($event, i)"
      [class.has-sub]="link.children"
    >
      <a
        recall2AppsLink
        [attr.data-testid]="link.name"
        [targetApp]="link.targetApp"
        [urlPath]="link.urlPath"
        [innerHTML]="link.innerHTML"
        [ignoreActive]="link.ignoreActive"
        [forceActive]="link.forceActive"
        [noNavigate]="!!link.children"
      >
      </a>
      <ul *ngIf="link.children">
        <li *ngFor="let child of link.children; trackBy: trackLink">
          <a
            recall2AppsLink
            [attr.data-testid]="child.name"
            [targetApp]="child.targetApp"
            [urlPath]="child.urlPath"
            [innerHTML]="child.innerHTML"
            [ignoreActive]="child.ignoreActive"
            [forceActive]="child.forceActive"
          ></a>
        </li>
      </ul>
    </li>
    <li
      *ngIf="abbreviatedMenuLinks.length"
      data-testid="abbreviation-menu-item"
      class="has-sub abbrev"
    >
      <a>
        <recall2-icon-more></recall2-icon-more>
      </a>
      <ul>
        <li
          *ngFor="let link of abbreviatedMenuLinks; index as i; trackBy: trackLink"
          data-testid="abbreviated-menu-item"
          [class.has-sub]="link.children"
        >
          <a
            recall2AppsLink
            [attr.data-testid]="link.name"
            [targetApp]="link.targetApp"
            [urlPath]="link.urlPath"
            [innerHTML]="link.innerHTML"
            [ignoreActive]="link.ignoreActive"
            [forceActive]="link.forceActive"
            [noNavigate]="!!link.children"
          >
          </a>
          <ul *ngIf="link.children">
            <li
              *ngFor="let child of link.children; trackBy: trackLink"
              data-testid="abbreviated-sub-menu-item"
            >
              <a
                recall2AppsLink
                [attr.data-testid]="child.name"
                [targetApp]="child.targetApp"
                [urlPath]="child.urlPath"
                [innerHTML]="child.innerHTML"
                [ignoreActive]="child.ignoreActive"
                [forceActive]="child.forceActive"
              ></a>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</nav>
