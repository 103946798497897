import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';

import { LanguageCode } from '../../../i18n/enums/language-code.enum';

@Injectable({
  providedIn: 'root',
})
export class AppTranslationService {
  static readonly USER_LANGUAGE_KEY: string = 'userLanguage';

  static readonly AVAILABLE_LANGUAGES: string[] = [LanguageCode.German, LanguageCode.EnglishUS];
  static readonly ENGLISH_FALLBACK_LANGUAGE: string = LanguageCode.EnglishUS;

  private _userLanguage = this.get();
  private readonly languages = [
    LanguageCode.German,
    LanguageCode.EnglishUS,
    LanguageCode.Spanish,
    LanguageCode.EnglishGB,
  ];

  currentLanguage$ = new BehaviorSubject(LanguageCode.German);

  constructor(private _translateService: TranslateService) {}

  init(language?: LanguageCode): void {
    if (language) {
      this.use(language);
      return;
    }
    const userLang = window.localStorage.getItem(AppTranslationService.USER_LANGUAGE_KEY);
    const langToUse = userLang ?? this.getFallbackLanguage();

    this.use(langToUse as LanguageCode);
  }

  async initAsync(language?: LanguageCode): Promise<void> {
    if (language) {
      await this.useAsync(language);
      return;
    }
    const userLang = window.localStorage.getItem(AppTranslationService.USER_LANGUAGE_KEY);
    const langToUse = userLang ?? this.getFallbackLanguage();

    await this.useAsync(langToUse as LanguageCode);
  }

  switchToGerman(): void {
    return this.use(LanguageCode.German);
  }

  switchToEnglishUS(): void {
    return this.use(LanguageCode.EnglishUS);
  }

  switchToSpanishES(): void {
    return this.use(LanguageCode.Spanish);
  }

  switchToBritishGB(): void {
    return this.use(LanguageCode.EnglishGB);
  }

  getLanguages(): LanguageCode[] {
    return this.languages;
  }

  getLanguageFromLocalStorage(): string {
    return window.localStorage.getItem(AppTranslationService.USER_LANGUAGE_KEY);
  }

  get(): string {
    if (this._userLanguage) {
      return this._userLanguage;
    }
    const lang = this.getLanguageFromLocalStorage();
    return lang ? lang : this.getFallbackLanguage();
  }

  private use(language: LanguageCode): void {
    this.currentLanguage$.next(language);
    this._translateService.use(language);
    this._translateService.setDefaultLang(language);
    // save the Locale into the localStorage to keep the language even on refreshes
    window.localStorage.setItem(AppTranslationService.USER_LANGUAGE_KEY, language);
  }

  private async useAsync(language: LanguageCode): Promise<void> {
    this.currentLanguage$.next(language);
    await lastValueFrom(this._translateService.use(language));
    this._translateService.setDefaultLang(language);
    window.localStorage.setItem(AppTranslationService.USER_LANGUAGE_KEY, language);
  }

  private getFallbackLanguage(): string {
    let fallbackLanguage = window.navigator.languages ? window.navigator.languages[0] : window.navigator.language;

    if (!AppTranslationService.AVAILABLE_LANGUAGES.includes(fallbackLanguage)) {
      fallbackLanguage = AppTranslationService.ENGLISH_FALLBACK_LANGUAGE;
    }
    return fallbackLanguage;
  }
}
