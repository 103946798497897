import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import type { FormControl } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { iconWarningMedium, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';
import dayjs from 'dayjs';
import { takeUntil } from 'rxjs/operators';

import { DateProperty } from '../form/model';
import { Recall2IconCalendarComponent, Recall2IconInvalidComponent, Recall2IconRequiredComponent } from '../icons';
import { toLocalYearMonthDayString } from '../utils';
import { GuicDatePickerComponent } from './components/guic-datepicker/guic-datepicker.component';
import { Recall2DatepickerCommonComponent } from './recall2-datepicker-common.component';

/**Matches string only including digits and dots */
const VALID_FORMAT_REGEX = /^(\d|\.)+$/g;
/**Char different than digits and dots */
const INVALID_CHARS_REGEX = /[^\d.]/g;

@Component({
  selector: 'recall2-datepicker',
  templateUrl: './recall2-datepicker.component.html',
  styleUrls: ['./recall2-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GuicDatePickerComponent,
    OverlayModule,
    TranslateModule,
    MatTooltipModule,
    SVGIconModule,
    DatePipe,
    NgClass,
    NgIf,
    Recall2IconCalendarComponent,
    Recall2IconInvalidComponent,
    Recall2IconRequiredComponent,
  ],
})
export class Recall2DatepickerComponent
  extends Recall2DatepickerCommonComponent<DateProperty>
  implements OnInit, OnChanges
{
  @Input() property: DateProperty;
  @Input() isDisabled: boolean;
  @Input() isFormSubmitted: boolean;
  @Input() isValidateDateDisabled = true;

  @Output() dateChange = new EventEmitter<FormControl>();
  @Output() dateChangeParsed = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('calendar', { read: ElementRef }) calendarIcon: ElementRef;
  @ViewChild('guicDatepicker', { read: ElementRef }) guicDatepicker: ElementRef;
  @ViewChild(CdkConnectedOverlay) attachedOverlay: CdkConnectedOverlay;

  isOpen = false;
  inputHasFocus = false;
  selectedDate: Date;
  readonly dateFormat = 'dd.MM.yyyy';

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    protected iconsRegistry: SVGIconsRegistry,
  ) {
    super(iconsRegistry);
    iconsRegistry.registerIcons([iconWarningMedium]);
  }

  ngOnInit(): void {
    this.selectedDate = this.property.control.value;

    this.watchEmptyDateChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.property?.previousValue?.minDate !== changes.property?.currentValue?.minDate ||
      changes.property?.previousValue?.maxDate !== changes.property?.currentValue?.maxDate
    ) {
      this.validateDate(this.property.control.value);
    }
  }

  onInputBlur(event: FocusEvent): void {
    this.inputHasFocus = false;
    this.setInputValueInFormControl(event);
  }

  onInputChange(event: Event): void {
    this.setControlAsDirty();

    const value = (event.target as HTMLInputElement).value;

    if (!value) {
      this.selectedDate = null;
      return;
    }

    const date = this.getDateFromString(value);
    this.selectedDate = date?.getTime && !Number.isNaN(date.getTime()) ? date : null;
    this.validateDate(this.selectedDate);
  }

  onDateSelected(date: Date): void {
    this.setControlAsDirty();

    this.selectedDate = date;
    this.property.control.setValue(date);
    this.validateDate(date);
    this.dateChange.emit(this.property.control);
    this.dateChangeParsed.emit(date ? toLocalYearMonthDayString(date) : '');
    this.isOpen = false;
  }

  checkValidKeyPressed(event: KeyboardEvent): void {
    const { key } = event;

    if (key !== '.' && (key === ' ' || Number.isNaN(+key))) {
      event.preventDefault();
    }
  }

  replaceInvalidChars(): void {
    let value = this.input.nativeElement.value;

    if (value !== '' && !VALID_FORMAT_REGEX.test(value)) {
      value = value.replace(INVALID_CHARS_REGEX, '');
      this.input.nativeElement.value = value;
    }
  }

  onEnterPressed(event: Event): void {
    if ((event as KeyboardEvent).key === 'Enter') {
      this.property.control.setValue(this.getDateFromString(this.input.nativeElement.value));
    }
  }

  closeDatepicker(): void {
    this.isOpen = false;
    this.input.nativeElement.blur();
  }

  get locale(): string {
    if (!this.translate.currentLang) {
      return 'en';
    }
    return this.translate.currentLang.slice(0, 2);
  }

  get showErrors(): boolean {
    if (typeof this.isFormSubmitted === 'undefined') {
      return this.property.control.invalid && (this.property.control.dirty || this.property.control.touched);
    }

    return this.isFormSubmitted && !!this.property.control.errors;
  }

  get errorType(): string {
    const errorTypes = Object.keys(this.property.control.errors);
    return this.showErrors ? errorTypes[errorTypes.length - 1] : undefined;
  }

  private getDateFromString(dateString: string): Date {
    const [day, month, year] = dateString.split('.');
    return new Date(`${year}-${month}-${day}`);
  }

  private setInputValueInFormControl(event: Event): void {
    if (this.property.control.value === this.selectedDate) {
      return;
    }

    this.onInputChange(event);
    this.property.control.setValue(this.selectedDate);
  }

  private validateDate(date: Date | null): void {
    if (this.isValidateDateDisabled) {
      return;
    }

    this.validateRequired(date);

    if (date) {
      const dateWithDayjs = dayjs(date);
      this.validateDateInvalid(dateWithDayjs);
      this.validateMinDate(dateWithDayjs);
      this.validateMaxDate(dateWithDayjs);
    }

    this.cdr.markForCheck();
  }

  private validateRequired(date: Date | null): void {
    if (this.property.required && !date) {
      const error = {
        required: true,
      };
      this.property.control.setErrors(error);
    } else if (this.property.control.errors?.required) {
      const error = {
        ...this.property.control.errors,
      };
      delete error.required;
      this.property.control.setErrors(Object.keys(error).length > 0 ? error : null);
    }
  }

  private validateDateInvalid(date: dayjs.Dayjs): void {
    if (!date.isValid()) {
      const error = {
        invalidDate: true,
      };
      this.property.control.setErrors(error);
    } else if (this.property.control.errors?.invalidDate) {
      const error = {
        ...this.property.control.errors,
      };
      delete error.invalidDate;
      this.property.control.setErrors(Object.keys(error).length > 0 ? error : null);
    }
  }

  private validateMinDate(date: dayjs.Dayjs): void {
    if (!this.property.minDate) {
      return;
    }

    const minDate = dayjs(this.property.minDate);

    if (minDate.isValid() && date.isBefore(minDate, 'day')) {
      const error = {
        minDate: minDate.format('DD.MM.YYYY'),
      };
      this.property.control.setErrors(error);
    } else if (this.property.control.errors?.minDate) {
      const error = {
        ...this.property.control.errors,
      };
      delete error.minDate;
      this.property.control.setErrors(Object.keys(error).length > 0 ? error : null);
    }
  }

  private validateMaxDate(date: dayjs.Dayjs): void {
    if (!this.property.maxDate) {
      return;
    }

    const maxDate = dayjs(this.property.maxDate);

    if (maxDate.isValid() && date.isAfter(maxDate)) {
      const error = {
        maxDate: maxDate.format('DD.MM.YYYY'),
      };
      this.property.control.setErrors(error);
    } else if (this.property.control.errors?.maxDate) {
      const error = {
        ...this.property.control.errors,
      };
      delete error.maxDate;
      this.property.control.setErrors(Object.keys(error).length > 0 ? error : null);
    }
  }

  private watchEmptyDateChanges(): void {
    this.property.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.selectedDate = value;
      this.validateDate(value);
      this.dateChange.emit(this.property.control);
      this.cdr.markForCheck();
    });
  }
}
