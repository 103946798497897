import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-sort-down-active',
  templateUrl: './recall2-icon-sort-down-active.component.html',
  styleUrls: ['./recall2-icon-sort-down-active.component.scss'],
  standalone: true,
})
export class Recall2IconSortDownActiveComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i09-sort-down-active');
  }
}
