import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-unlink',
  templateUrl: './recall2-icon-unlink.component.html',
  styleUrls: ['./recall2-icon-unlink.component.scss'],
  standalone: true,
})
export class Recall2IconUnlinkComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i84-unlink');
  }
}
