import { DatePipe, NgClass, NgFor, NgIf, NgTemplateOutlet, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import type { OnChanges, OnInit } from '@angular/core';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { WINDOW } from '@recall2/globals';
import {
  iconArrowDownMedium,
  iconArrowLeftMedium,
  iconArrowRightMedium,
  iconArrowUpMedium,
  SVGIconModule,
  SVGIconsRegistry,
} from '@recall2/icons';

import { Recall2ButtonPrimaryComponent, Recall2ButtonTertiaryComponent } from '../../../buttons';
import { ClickOutsideDirective } from '../../../click-outside';
import { DatePickerDay } from '../../models/datepicker-day';
import { DatePickerWeek } from '../../models/datepicker-week';

const DAYS_IN_6_WEEKS = 42;
registerLocaleData(localeDe);

export const guicWindowFactory = (): Window => window;

@Component({
  selector: 'guic-datepicker',
  templateUrl: './guic-datepicker.component.html',
  styleUrls: ['./guic-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    DatePipe,
    FormsModule,
    ReactiveFormsModule,
    SVGIconModule,
    TranslateModule,
    NgClass,
    NgIf,
    NgFor,
    NgTemplateOutlet,
    Recall2ButtonTertiaryComponent,
    Recall2ButtonPrimaryComponent,
    ClickOutsideDirective,
  ],
  providers: [
    DatePipe,
    {
      provide: WINDOW,
      useFactory: guicWindowFactory,
    },
  ],
})
export class GuicDatePickerComponent implements OnChanges, OnInit {
  hoverDate: DatePickerDay;
  showNextMonthButton = true;
  showYearSelector = false;
  years: number[] = [];
  disabledYears: { [year: string]: boolean } = {};

  currentYear: number;
  selectedYear: number;

  readonly columnHeaderTranslationKeyPrefix = 'shared.date-picker.column-header.';
  readonly columnHeadersTranslationKeys = [
    `${this.columnHeaderTranslationKeyPrefix}week-count`,
    `${this.columnHeaderTranslationKeyPrefix}monday`,
    `${this.columnHeaderTranslationKeyPrefix}tuesday`,
    `${this.columnHeaderTranslationKeyPrefix}wednesday`,
    `${this.columnHeaderTranslationKeyPrefix}thursday`,
    `${this.columnHeaderTranslationKeyPrefix}friday`,
    `${this.columnHeaderTranslationKeyPrefix}saturday`,
    `${this.columnHeaderTranslationKeyPrefix}sunday`,
  ];

  @Input() set currentDate(dateValue: Date | string) {
    this.currentDateObject = this.getDateObject(dateValue);
  }

  @Input() set max(dateValue: Date | string) {
    this.maxDateObject = this.getDateObject(dateValue);
  }

  @Input() set min(dateValue: Date | string) {
    this.minDateObject = this.getDateObject(dateValue);
  }

  @Input() locale: string;
  @Input() isRangedDate = false;
  @Output() dateSelected: EventEmitter<Date> = new EventEmitter();
  @Output() clickedElsewhere: EventEmitter<void> = new EventEmitter();
  @Output() cancelClicked: EventEmitter<void> = new EventEmitter();

  @HostListener('click', ['$event'])
  onClickComponent(event: Event): void {
    event.stopPropagation();
  }

  displayedWeeks: DatePickerWeek[];
  displayedDate: Date;
  minDateObject: Date;
  maxDateObject: Date;
  todayDate = this.getTodayDate();

  private currentDateObject: Date;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    iconsRegistry: SVGIconsRegistry,
  ) {
    iconsRegistry.registerIcons([iconArrowLeftMedium, iconArrowRightMedium, iconArrowDownMedium, iconArrowUpMedium]);
    this.changeDetectorRef.detach();
  }

  ngOnInit(): void {
    this.loadYears();
    this.updateDate();
    this.setSelectedYear();
    this.setDisabledYears();
  }

  ngOnChanges(): void {
    this.updateDate();
  }

  showPreviousMonth(): void {
    this.switchMonth(-1);
  }

  showNextMonth(): void {
    this.switchMonth(1);
  }

  clickOnNextMonthPossible(): boolean {
    if (!this.maxDateObject) {
      return true;
    }

    const firstOfNextMonth = GuicDatePickerComponent.buildDateForMonth(
      this.displayedDate.getFullYear(),
      this.displayedDate.getMonth() + 1,
    );
    return this.maxDateObject.getTime() >= firstOfNextMonth.getTime();
  }

  clickOnPreviousMonthPossible(): boolean {
    if (!this.minDateObject) {
      return true;
    }

    const lastOfPreviousMonth = new Date(this.displayedDate.getFullYear(), this.displayedDate.getMonth(), 0);
    return this.minDateObject.getTime() <= lastOfPreviousMonth.getTime();
  }

  onClickedElsewhere(): void {
    this.clickedElsewhere.emit();
    this.changeDetectorRef.detectChanges();
  }

  selectDay(day: DatePickerDay, clickEvent: MouseEvent): void {
    clickEvent.stopPropagation();

    if (day.isDisabled) {
      return;
    }
    this.currentDateObject = day.date;
    this.dateSelected.emit(day.date);
    this.changeDetectorRef.detectChanges();
  }

  selectToday(): void {
    this.dateSelected.emit(this.todayDate);
  }

  onClickCancel(): void {
    this.cancelClicked.emit();
  }

  hover(hoveredDate: DatePickerDay): void {
    this.hoverDate = hoveredDate;
    this.changeDetectorRef.detectChanges();
  }

  buildClasses(day: DatePickerDay): { [id: string]: boolean } {
    const startRangedDate = new Date();
    startRangedDate.setDate(this.minDateObject?.getDate() - 1);
    startRangedDate.setHours(0, 0, 0, 0);
    this.currentDateObject?.setHours(0, 0, 0, 0);

    return {
      'other-month-dates': day.isLastMonth,
      today: day.isToday,
      'disabled-date': day.isDisabled,
      'hover-date': this.hoverDate === day,
      'selected-date': this.isSameDateAsCurrentDate(day.date),
      'range-date': this.isRangedDate && day.date >= this.minDateObject && day.date < this.currentDateObject,
      'start-range-date': this.isRangedDate && day.date.getTime() === startRangedDate?.getTime(),
    };
  }

  getLocale(): string {
    return this.isLocaleGerman() ? 'de' : 'en';
  }

  toggleYearSelector(): void {
    this.showYearSelector = !this.showYearSelector;
    this.changeDetectorRef.detectChanges();
    if (this.showYearSelector) {
      const yearSelector = `#year_${this.selectedYear}`;
      document.querySelector(yearSelector).scrollIntoView({ block: 'center' });
    }
  }

  onYearSelected(year: number): void {
    this.switchYear(year);
    this.toggleYearSelector();
    this.setSelectedYear();
  }

  private updateDate(): void {
    this.displayedDate = this.currentDateObject || new Date();
    this.displayedWeeks = this.buildWeeks();
    this.showNextMonthButton = this.clickOnNextMonthPossible();

    this.changeDetectorRef.detectChanges();
  }

  private getLastMonthDays(date: Date): DatePickerDay[] {
    if (GuicDatePickerComponent.isMonday(date)) {
      return [];
    }

    const monday = GuicDatePickerComponent.getMonday(date);
    const lastMonthDays: DatePickerDay[] = [];
    const dayCounter = new Date(monday);

    while (monday.getMonth() === dayCounter.getMonth()) {
      lastMonthDays.push(new DatePickerDay(dayCounter, this.isDayDisabled(dayCounter), false, true));
      dayCounter.setDate(dayCounter.getDate() + 1);
    }
    return lastMonthDays;
  }

  private appendNextMonthDays(monthDays: DatePickerDay[]): void {
    if (monthDays.length === DAYS_IN_6_WEEKS) {
      return;
    }

    const monthLastDay = monthDays[monthDays.length - 1].date;

    const nextDay = new Date(monthLastDay.toISOString());
    nextDay.setDate(nextDay.getDate() + 1);

    while (monthDays.length < DAYS_IN_6_WEEKS) {
      monthDays.push(new DatePickerDay(nextDay, this.isDayDisabled(nextDay), false, true));
      nextDay.setDate(nextDay.getDate() + 1);
    }
  }

  private buildWeeks(): DatePickerWeek[] {
    const displayedDate = this.displayedDate;

    const newDate = new Date(displayedDate.getFullYear(), displayedDate.getMonth(), 1);
    const calendarData: DatePickerDay[] = this.getLastMonthDays(newDate);

    while (newDate.getMonth() === displayedDate.getMonth()) {
      calendarData.push(new DatePickerDay(newDate, this.isDayDisabled(newDate), false));
      newDate.setDate(newDate.getDate() + 1);
    }

    this.appendNextMonthDays(calendarData);

    return GuicDatePickerComponent.buildMonth(calendarData);
  }

  private isDayDisabled(newDate: Date): boolean {
    const currentDate = newDate;
    const min = this.minDateObject;
    const max = this.maxDateObject;
    currentDate?.setHours(0, 0, 0, 0);
    min?.setHours(0, 0, 0, 0);
    max?.setHours(0, 0, 0, 0);

    const exceedsMax = this.maxDateObject ? max.getTime() < currentDate.getTime() : false;
    const exceedsMin = this.minDateObject ? min.getTime() > currentDate.getTime() : false;
    return exceedsMax || exceedsMin;
  }

  private getDateObject(dateValue: Date | string): Date {
    if (!dateValue) {
      return null;
    }

    const dateObject = dateValue instanceof Date ? dateValue : new Date(dateValue);

    if (Number.isNaN(dateObject.getTime())) {
      console.warn(`Date "${dateValue}" is not a valid date`);
    }

    return dateObject;
  }

  private isSameDateAsCurrentDate(date: Date): boolean {
    return (
      this.currentDateObject &&
      this.currentDateObject.getFullYear() === date.getFullYear() &&
      this.currentDateObject.getMonth() === date.getMonth() &&
      this.currentDateObject.getDate() === date.getDate()
    );
  }

  private isLocaleGerman(): boolean {
    return this.locale && this.locale.toUpperCase().trim() === 'DE';
  }

  private static buildDateForMonth(year: number, month: number): Date {
    return new Date(year, month, 1);
  }

  private static isMonday(date: Date): boolean {
    return date.getDay() === 1;
  }

  private static getMonday(date: Date): Date {
    const result = new Date(date);
    while (result.getDay() !== 1) {
      result.setDate(result.getDate() - 1);
    }

    return result;
  }

  private getTodayDate(): Date {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    return todayDate;
  }

  private static buildMonth(days: DatePickerDay[]): DatePickerWeek[] {
    const weeks: DatePickerWeek[] = [];
    let index = 0;
    while (index < days.length) {
      weeks.push(new DatePickerWeek(days.slice(index, 7 + index)));
      index += 7;
    }
    return weeks;
  }

  private loadYears(): void {
    this.currentYear = new Date().getFullYear();
    const previousYears: number[] = Array.from({ length: 100 }, (_, i) =>
      Math.abs(i - (this.currentYear - 1)),
    ).reverse();
    const nextYears: number[] = Array.from({ length: 100 }, (_, i) => i + this.currentYear);
    this.years = [...previousYears, ...nextYears];
  }

  private setSelectedYear(): void {
    this.selectedYear = this.displayedDate.getFullYear();
    this.changeDetectorRef.detectChanges();
  }

  private setDisabledYears(): void {
    const maxDateYear = this.maxDateObject?.getFullYear();
    const minDateYear = this.minDateObject?.getFullYear();

    if (!maxDateYear && !minDateYear) {
      return;
    }

    this.years.forEach(year => (this.disabledYears[year] = year < minDateYear || year > maxDateYear));
  }

  private switchMonth(amount: number): void {
    this.displayedDate = GuicDatePickerComponent.buildDateForMonth(
      this.displayedDate.getFullYear(),
      this.displayedDate.getMonth() + amount,
    );
    this.displayedWeeks = this.buildWeeks();
    this.changeDetectorRef.detectChanges();
  }

  private switchYear(year: number): void {
    this.displayedDate = GuicDatePickerComponent.buildDateForMonth(year, this.displayedDate.getMonth());
    this.displayedWeeks = this.buildWeeks();
    this.changeDetectorRef.detectChanges();
  }
}
