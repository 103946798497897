<recall2-overlay
  [overlayTemplateType]="overlayNested"
  [itemsCount]="itemsCount"
  [position]="overlayPosition"
  [isSpinnerShowing]="loading"
  (closeTemplate)="closeSubscription()"
  (mouseenter)="onMouseEnter()"
>
  <div class="app-table-attachments-list-wrapper">
    <recall2-object-template [itemsList]="items"></recall2-object-template>
  </div>
</recall2-overlay>
