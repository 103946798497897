<div class="link-component">
  <recall2-svg-icon
    *ngIf="isHeaderBackNavigation"
    name="arrow-left"
    size="medium"
  >
  </recall2-svg-icon>
  <span [ngClass]="isHeaderBackNavigation ? 'rc2-font--size--16' : 'rc2-font--size--14'">
    {{ linkText | translate }}
  </span>
  <recall2-svg-icon
    *ngIf="!isHeaderBackNavigation"
    class="launch-icon"
    name="launch"
    size="small"
  ></recall2-svg-icon>
</div>
