import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ConcurrentNotificationComponent } from '../components/concurrent-notification/concurrent-notification.component';
import { UnauthorizedErrorModalComponent } from '../components/unauthorized-error-modal/unauthorized-error-modal.component';
import { Recall2ErrorHandlingModalComponent } from '../error-handling-modal.component';
import type { IErrorModalData } from '../models/error-modal-data';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  constructor(private _matDialog: MatDialog) {}

  public static generateFullSizeDialogConfig(data: any = {}): MatDialogConfig {
    const genericDialogConfig = new MatDialogConfig();
    genericDialogConfig.panelClass = 'error-modal-full-size';
    genericDialogConfig.backdropClass = 'modal-backdrop';
    genericDialogConfig.position = { top: '0', left: '0' };
    genericDialogConfig.maxWidth = '100%';
    genericDialogConfig.width = '100%';
    genericDialogConfig.disableClose = true;
    genericDialogConfig.data = data;
    genericDialogConfig.scrollStrategy = new NoopScrollStrategy();

    return genericDialogConfig;
  }

  public showGenericErrorDialog(errorMessageKey?: string, dynamicValues?: { [key: string]: any }[]): void {
    if (this._matDialog.openDialogs.length > 0) {
      const openedDialogConfig: MatDialogConfig<IErrorModalData> = {
        ...this._matDialog.openDialogs[0]._containerInstance._config,
        data: { errorMessageKey },
      };
      if (openedDialogConfig.panelClass !== 'error-modal-full-size') {
        this.openFullSizeModal(openedDialogConfig.data.errorMessageKey);
      }
    } else {
      this.openFullSizeModal(errorMessageKey, dynamicValues);
    }
  }

  private openFullSizeModal(errorMessageKey?: string, dynamicValues?: { [key: string]: any }[]): void {
    this._matDialog.open<Recall2ErrorHandlingModalComponent, IErrorModalData, any>(
      Recall2ErrorHandlingModalComponent,
      ErrorHandlingService.generateFullSizeDialogConfig({ errorMessageKey, dynamicValues }),
    );
  }

  public openUnauthorizedModal(): void {
    this._matDialog.open<UnauthorizedErrorModalComponent>(
      UnauthorizedErrorModalComponent,
      ErrorHandlingService.generateFullSizeDialogConfig(),
    );
  }

  public showConcurrentNotification(errorMessage?: string, objectType?: string): void {
    this._matDialog.open<ConcurrentNotificationComponent>(
      ConcurrentNotificationComponent,
      ErrorHandlingService.generateFullSizeDialogConfig({ errorMessage, objectType }),
    );
  }
}
