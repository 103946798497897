import type { Observable } from 'rxjs';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { debounceTime, map, takeUntil, tap } from 'rxjs/operators';

import type { Pagination } from '../../core/api/models/pagination.model';
import type { FieldSorting } from '../../core/api/models/sortable-request-options';
import type { IRecall2FilterParam } from '../../overlay/models/filter.model';
import { EFilterTemplates } from '../../overlay/models/filter.model';

export const DEFAULT_PAGE_SIZE = 10;
export const DEBOUNCE_TIME_EMIT_TABLE_CHANGES = 200;

export type InitialTableConfig = {
  filters?: IRecall2FilterParam[];
  sort?: FieldSorting;
  pageSize?: Pagination['pageSize'];
};

export class TableService {
  activeFilters$: BehaviorSubject<IRecall2FilterParam[]>;
  activeSort$: BehaviorSubject<FieldSorting>;
  pageSize$: BehaviorSubject<number>;
  page$: BehaviorSubject<number>;
  totalElements$: BehaviorSubject<number>;
  totalPages$: BehaviorSubject<number>;
  isTableRefreshing$ = new Subject<boolean>();
  tableHasChanges$: Observable<boolean>;

  readonly tableFilterKey: string;
  readonly tableSortKey: string;
  readonly tablePaginationKey: string;
  readonly initialTableConfig: InitialTableConfig;

  private destroy$ = new Subject<void>();

  constructor(key: string, initialTableConfig?: InitialTableConfig) {
    this.tableFilterKey = key;
    this.tableSortKey = `${key}_sort`;
    this.tablePaginationKey = `${key}_pagination`;
    this.initialTableConfig = initialTableConfig;

    this.initActiveFilters();
    this.initActiveSort();
    this.initPagination();

    this.tableHasChanges$ = combineLatest([this.activeSort$, this.activeFilters$, this.page$, this.pageSize$]).pipe(
      debounceTime(DEBOUNCE_TIME_EMIT_TABLE_CHANGES),
      map(() => true),
      tap(() => this.isTableRefreshing$.next(false)),
    );
  }

  onDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get activeFilters(): IRecall2FilterParam[] {
    return this.activeFilters$.getValue();
  }

  get activeSort(): FieldSorting {
    return this.activeSort$.getValue();
  }

  get pageSize(): number {
    return this.pageSize$.getValue();
  }

  get page(): number {
    return this.page$.getValue();
  }

  get totalElements(): number {
    return this.totalElements$.getValue();
  }

  get totalPages(): number {
    return this.totalPages$.getValue();
  }

  get pagination(): Pagination {
    return {
      pageSize: this.pageSize,
      page: this.page,
      totalElements: this.totalElements,
      totalPages: this.totalPages,
    };
  }

  updateSort(sort: FieldSorting, clearQueryParams = true): void {
    this.isTableRefreshing$.next(true);
    if (!sort) {
      this.clearSort();
      return;
    }

    this.activeSort$.next(sort);
    if (clearQueryParams) {
      this.clearQueryParams();
    }
  }

  clearFilters(): void {
    this.isTableRefreshing$.next(true);

    this.activeFilters$.next([]);
  }

  addFilter(filter: IRecall2FilterParam, shouldOverride = false): void {
    let updatedFilters: IRecall2FilterParam[];

    if (this.filterExists(filter)) {
      const activeFilters =
        filter.type === EFilterTemplates.TEXT && !shouldOverride
          ? this.addFilterToExisting(filter, this.activeFilters)
          : this.replaceExistingFilter(filter, this.activeFilters);
      updatedFilters = [...activeFilters];
    } else {
      if (!filter.value || filter.value.length === 0) {
        return;
      }

      updatedFilters = [...this.activeFilters, filter];
    }

    this.updateFilters(updatedFilters);
  }

  removeFilter(key: string): void {
    const updatedFilters = this.activeFilters.filter(filter => filter.identifier !== key);
    this.updateFilters(updatedFilters);
  }

  updateFilters(updatedFilters: IRecall2FilterParam[]): void {
    this.isTableRefreshing$.next(true);

    this.activeFilters$.next(updatedFilters);

    this.resetPage();
    this.clearQueryParams();
  }

  updatePageSize(pageSize: number): void {
    this.isTableRefreshing$.next(true);

    this.pageSize$.next(pageSize);
    this.resetPage();
  }

  updateCurrentPage(page: number, refresh = true): void {
    if (refresh) {
      this.isTableRefreshing$.next(true);
    }

    this.page$.next(page);
    this.clearQueryParams();
  }

  setDefaultPage(page: number): void {
    if (page > 0) {
      this.page$.next(page);
    }
  }

  updateTotalElements(totalElements: number): void {
    this.totalElements$.next(totalElements);
  }

  updateTotalPages(totalPages: number): void {
    this.totalPages$.next(totalPages);
  }

  private addFilterToExisting(
    filter: IRecall2FilterParam,
    activeFilters: IRecall2FilterParam[],
  ): IRecall2FilterParam[] {
    const previousFilter = activeFilters.find(activeFilter => activeFilter.identifier === filter.identifier);
    const filters = activeFilters.filter(activeFilter => activeFilter.identifier !== filter.identifier);
    previousFilter.value = [...(previousFilter.value as string[]), ...(filter.value as string[])];
    return [...filters, previousFilter];
  }

  private replaceExistingFilter(
    newFilter: IRecall2FilterParam,
    activeFilters: IRecall2FilterParam[],
  ): IRecall2FilterParam[] {
    const updatedFiltersList = [...activeFilters];

    const index = updatedFiltersList.findIndex(filter => filter.identifier === newFilter.identifier);

    if (newFilter.value.length === 0) {
      updatedFiltersList.splice(index, 1);
    } else {
      updatedFiltersList.splice(index, 1, newFilter);
    }

    return updatedFiltersList;
  }

  private filterExists(filter: IRecall2FilterParam): boolean {
    return this.activeFilters.some(item => item.identifier === filter.identifier);
  }

  private initPagination(): void {
    const pagination = this.getPaginationFromLocalStorage();
    this.page$ = new BehaviorSubject(pagination.page);
    this.pageSize$ = new BehaviorSubject(pagination.pageSize);
    this.totalElements$ = new BehaviorSubject(pagination.totalElements);
    this.totalPages$ = new BehaviorSubject(pagination.totalPages);

    combineLatest([this.page$, this.pageSize$, this.totalElements$, this.totalPages$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        window.localStorage.setItem(this.tablePaginationKey, JSON.stringify(this.pagination));
      });
  }

  private initActiveSort(): void {
    let sort = this.getActiveSortingFromLocalStorage();

    if (!sort && !!this.initialTableConfig?.sort) {
      sort = this.initialTableConfig?.sort;
    }

    this.activeSort$ = new BehaviorSubject(sort);

    this.activeSort$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!value) {
        window.localStorage.removeItem(this.tableSortKey);
      } else {
        window.localStorage.setItem(this.tableSortKey, JSON.stringify(value));
      }
    });
  }

  private initActiveFilters(): void {
    let filters = this.getActiveFiltersFromLocalStorage();

    if (filters.length === 0 && Array.isArray(this.initialTableConfig?.filters)) {
      filters = this.initialTableConfig.filters;
    }

    this.activeFilters$ = new BehaviorSubject(filters);

    this.activeFilters$.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (!Array.isArray(value) || value.length === 0) {
        window.localStorage.removeItem(this.tableFilterKey);
      } else {
        window.localStorage.setItem(this.tableFilterKey, JSON.stringify(value));
      }
    });
  }

  private getActiveSortingFromLocalStorage(): FieldSorting | null {
    const localStorageValue = window.localStorage.getItem(this.tableSortKey);

    if (!localStorageValue) {
      return null;
    }

    try {
      const { field, orderBy } = JSON.parse(localStorageValue);
      if (!field || !orderBy) {
        return null;
      }

      return {
        field,
        orderBy,
      };
    } catch (error) {
      console.error('Error parsing the JSON', error);
      return null;
    }
  }

  private getActiveFiltersFromLocalStorage(): IRecall2FilterParam[] {
    const localStorageValue = window.localStorage.getItem(this.tableFilterKey);
    if (!localStorageValue) {
      return [];
    }

    try {
      const filters = JSON.parse(localStorageValue);

      if (!Array.isArray(filters)) {
        return [];
      }

      return filters.map(filter => {
        if (filter.type !== EFilterTemplates.DATE) {
          return filter;
        }

        return {
          ...filter,
          value: filter.value.map(date => new Date(date)),
        };
      });
    } catch (error) {
      console.error('Error parsing the JSON', error);
      return [];
    }
  }

  private getPaginationFromLocalStorage(): Pagination {
    const localStorageValue = window.localStorage.getItem(this.tablePaginationKey);
    if (!localStorageValue) {
      return {
        page: 0,
        totalElements: null,
        pageSize: DEFAULT_PAGE_SIZE,
        totalPages: null,
      };
    }
    try {
      const parsedData = JSON.parse(localStorageValue);

      let pageSize = +parsedData.pageSize;

      if (Number.isNaN(pageSize) && !!this.initialTableConfig?.pageSize) {
        pageSize = this.initialTableConfig?.pageSize;
      } else if (Number.isNaN(pageSize)) {
        pageSize = DEFAULT_PAGE_SIZE;
      }

      return {
        page: +parsedData.page ?? 0,
        totalElements: +parsedData.totalElements ?? null,
        pageSize: pageSize,
        totalPages: +parsedData?.totalPages ?? null,
      };
    } catch (error) {
      console.error('Error parsing the JSON', error);
      return {
        page: 0,
        totalElements: null,
        pageSize: DEFAULT_PAGE_SIZE,
        totalPages: null,
      };
    }
  }

  private clearSort(): void {
    this.activeSort$.next(null);
  }

  private resetPage(): void {
    this.updateCurrentPage(0, false);
  }

  private clearQueryParams(): void {
    if (this.isHighlightUrl()) {
      window.history.replaceState(null, null, location.href.split('?')[0]);
    }
  }

  private isHighlightUrl(): boolean {
    return (
      location.search.includes('highlight') || location.search.includes('expand') || location.search.includes('scroll')
    );
  }
}
