import { createFeatureSelector, createSelector } from '@ngrx/store';

import type { INotificationState } from './notification.state';

const notificationStateSelector = createFeatureSelector<INotificationState>('notification');

export const NotificationSelectors = {
  root: notificationStateSelector,
  getBrandManufacturers: createSelector(notificationStateSelector, state => state.brandManufacturers),
  selectPageableDataForOverview: createSelector(
    notificationStateSelector,
    state => state.notificationList.notificationPage,
  ),
  getCurrentNotification: createSelector(notificationStateSelector, state => state.currentNotification),
};
