export enum LanguageCode {
  Arabic = 'ar-SA',
  Bulgarian = 'bg-BG',
  Czech = 'cs-CZ',
  Danish = 'da-DK',
  German = 'de-DE',
  Greek = 'el-GR',
  EnglishGB = 'en-GB',
  EnglishUS = 'en-US',
  Spanish = 'es-ES',
  Estonian = 'et-EE',
  Finnish = 'fi-FI',
  FrenchCA = 'fr-CA',
  FrenchFR = 'fr-FR',
  Hebrew = 'he-IL',
  Croatian = 'hr-HR',
  Hungarian = 'hu-HU',
  Italian = 'it-IT',
  Japanese = 'ja-JP',
  Korean = 'ko-KR',
  Lithuanian = 'lt-LT',
  Latvian = 'lv-LV',
  Macedonian = 'mk-MK',
  Norwegian = 'nb-NO',
  Polish = 'pl-PL',
  PortugueseBR = 'pt-BR',
  PortuguesePT = 'pt-PT',
  Romanian = 'ro-RO',
  Russian = 'ru-RU',
  Slovak = 'sl-SL',
  Serbian = 'sr-YU',
  Swedish = 'sv-SE',
  Turkish = 'tr-TR',
  Ukranian = 'uk-UA',
  Chinese = 'zh-CN',
}
