import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2IconLogoRecallComponent } from '@recall2/ui/icons';

import { CreateNotificationButtonComponent } from '../components/create-notification-button/create-notification-button.component';

@Component({
  selector: 'myserve-page',
  templateUrl: './my-serve-page.component.html',
  styleUrls: ['./my-serve-page.component.scss'],
  standalone: true,
  imports: [TranslateModule, CreateNotificationButtonComponent, Recall2IconLogoRecallComponent],
})
export class MyServePageComponent {}
