import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-warning',
  templateUrl: './recall2-icon-warning.component.html',
  standalone: true,
})
export class Recall2IconWarningComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i33-warning');
  }
}
