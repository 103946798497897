<ng-container *ngIf="attachmentFiles">
  <div class="attachments-list-wrapper">
    <div
      *ngFor="let file of attachmentFiles"
      class="row attachment-row rc2-align-items--center"
      (click)="onSingleFileClicked(file)"
    >
      <recall2-svg-icon
        name="download"
        size="medium"
        class="col-md-1 rc2-cursor--pointer rc2-color--primary"
      >
      </recall2-svg-icon>

      <div class="col-md-8 rc2-ellipsis">
        {{ file.filename }}
      </div>

      <div class="col-md-3 rc2-display--flex rc2-justify--flex-end">
        <span>{{ file.size | fileSize }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="attachmentFiles?.length > 1">
  <div class="total-attachments-row rc2-pad--top--0 rc2-pad--bottom--0">
    <div
      class="row rc2-align-items--center"
      (click)="onAllFilesOptionClicked()"
    >

      <recall2-svg-icon
        name="download"
        size="medium"
        class="col-md-1 rc2-cursor--pointer rc2-color--primary"
      >
      </recall2-svg-icon>

      <div class="col-md-8 rc2-ellipsis">
        {{ 'shared.table-attachments-list.all-files' | translate }}
      </div>

      <div class="col-md-3 rc2-display--flex rc2-justify--flex-end">
        <span>{{ totalSize | fileSize }}</span>
      </div>
    </div>
  </div>
</ng-container>
