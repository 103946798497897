import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-arrow-down',
  templateUrl: './recall2-icon-arrow-down.component.html',
  styleUrls: ['./recall2-icon-arrow-down.component.scss'],
  standalone: true,
})
export class Recall2IconArrowDownComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i01-arrow-1-down');
  }
}
