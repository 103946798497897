<recall2-attachment-wrapper
  *ngIf="display === displayedComponent.Attachment"
  [objectId]="objectId"
>
</recall2-attachment-wrapper>
<recall2-comments-and-history
  *ngIf="display === displayedComponent.History"
  [objectType]="objectType"
  [objectId]="objectId"
>
</recall2-comments-and-history>
