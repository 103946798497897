export enum GhostSkeletonSchema {
  Activity = 'activity',
  Button = 'button',
  Form = 'form',
  FormInline = 'formInline',
  Options = 'options',
  Overview = 'overview',
  OverviewWithMainFilters = 'overviewWithMainFilters',
  Table = 'table',
  TableWithFilters = 'tableWithFilters',
}
