import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-table-paginator-current-page',
  templateUrl: './table-paginator-current-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class TablePaginatorCurrentPageComponent {
  @Input() pageIndex: number;
  @Input() totalPages: number;
}
