<recall2-select
  class="rc2-display--block rc2-mar--bottom--s"
  data-cy="filter-user-roles-status"
  [property]="roleStatusProperty"
  (onChange)="onSelectChange()"
></recall2-select>
<recall2-select
  class="rc2-display--block rc2-mar--bottom--s"
  data-cy="filter-user-roles-level"
  [property]="levelProperty"
  (onChange)="onLevelChange($event)"
></recall2-select>
<recall2-select
  *ngIf="showManufacturer"
  class="rc2-display--block rc2-mar--bottom--s"
  data-cy="filter-user-roles-manufacturer"
  [property]="manufacturerProperty"
  (onChange)="onSelectChange()"
></recall2-select>
<recall2-select
  class="rc2-display--block rc2-mar--bottom--s"
  data-cy="filter-user-roles-roles"
  [property]="roleProperty"
  (onChange)="onSelectChange()"
></recall2-select>

