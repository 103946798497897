<mat-expansion-panel
  id="recall2-accordion-{{ accordionBaseProperties?.name }}"
  class="accordion"
  [expanded]="accordionBaseProperties?.isExpanded"
  [hideToggle]="true"
  (opened)="open.emit()"
  (afterExpand)="onOpenCompleted.emit()"
  (afterCollapse)="onCloseCompleted.emit()"
  #accordion
>
  <mat-expansion-panel-header
    class="accordion__header-container"
    [expandedHeight]="'auto'"
    [collapsedHeight]="'auto'"
    (click)="preventToggle(accordion, $event)"
    (keydown.Space)="$event.stopImmediatePropagation()"
    (keydown.enter)="$event.stopImmediatePropagation()"
  >
    <mat-panel-title [ngClass]="['header', headerBaseStyling,  getHeaderStateStyling(accordion.expanded)]">
      <div
        class="header__toggle-icon"
        id="accordion-toggle-icon-container"
      >
        <recall2-accordion-toggle
          id="accordion-{{ accordionBaseProperties?.name }}-toggle-icon"
          [isExpanded]="accordion.expanded"
          (click)="onToggle(accordion)"
        ></recall2-accordion-toggle>
      </div>

      <div class="header__content">
        <ng-content select="[header]"></ng-content>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div [ngClass]="'accordion__body--' + accordionClassification">
    <ng-content select="[body]"></ng-content>
  </div>
</mat-expansion-panel>
