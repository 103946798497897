import { AsyncPipe, NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import type { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { iconClearMedium, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';
import type { Subscription } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, startWith, takeUntil } from 'rxjs/operators';

import type { AutocompleteGroup } from '../form/model';
import { AutocompleteOption, AutocompleteProperty } from '../form/model';
import { GhostSkeletonSchema } from '../ghost-skeleton';
import { GhostSkeletonComponent } from '../ghost-skeleton/ghost-skeleton.component';
import { Recall2IconArrowUpComponent, Recall2IconHelpComponent, Recall2IconInvalidComponent } from '../icons';
import { compareObjects } from '../utils/functions/functions';
import { Recall2AutocompleteOptionDirective } from './directives/recall2-autocomplete.directive';
import { Recall2AutocompleteHighlightPipe } from './pipes/autocomplete-highlight.pipe';

const TYPING_DEBOUNCE_TYPE = 300;

@Component({
  selector: 'recall2-autocomplete',
  templateUrl: './recall2-autocomplete.component.html',
  styleUrls: ['./recall2-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatFormFieldModule,
    NgIf,
    NgClass,
    Recall2IconHelpComponent,
    Recall2IconInvalidComponent,
    Recall2IconArrowUpComponent,
    MatTooltipModule,
    MatInputModule,
    FormsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    SVGIconModule,
    MatOptionModule,
    GhostSkeletonComponent,
    NgFor,
    NgTemplateOutlet,
    AsyncPipe,
    TranslateModule,
    Recall2AutocompleteHighlightPipe,
  ],
})
export class Recall2AutocompleteComponent implements OnInit, OnDestroy, OnChanges {
  @Input() property: AutocompleteProperty;
  @Input() isStaticFilter = true;
  @Input() isClearable = true;
  @Input() displayHelper(item: AutocompleteOption): string {
    return item?.key;
  }

  @Output() searchChanged = new EventEmitter<string>();
  @ContentChild(Recall2AutocompleteOptionDirective, { read: TemplateRef })
  itemTemplate: TemplateRef<Recall2AutocompleteOptionDirective>;
  @Output() optionSelected = new EventEmitter<AutocompleteOption>();

  autocompleteGroups$ = new Subject<AutocompleteGroup[]>();
  autocompleteOptions$ = new Subject<AutocompleteOption[]>();
  isGroupedData = false;
  isOpened = false;
  toHighlight = '';
  autocompletePlaceholder = '';
  GhostSkeletonSchema = GhostSkeletonSchema;

  readonly loading$ = new BehaviorSubject<boolean>(false);
  private autocompleteOptionsSubscription: Subscription;
  private autocompleteGroupsSubscription: Subscription;
  private destroyed$ = new Subject<void>();

  constructor(iconsRegistry: SVGIconsRegistry) {
    iconsRegistry.registerIcons([iconClearMedium]);
  }

  ngOnInit(): void {
    this.initFlags();
    this.initHighlight();
    this.watchControlValueChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !this.isStaticFilter &&
      !changes?.property?.firstChange &&
      !compareObjects(changes?.property?.currentValue?.optionsList, changes?.property?.previousValue?.optionsList)
    ) {
      this.watchControlValueChanges();
    }
  }

  ngOnDestroy(): void {
    this.autocompleteGroupsSubscription?.unsubscribe();
    this.autocompleteOptionsSubscription?.unsubscribe();

    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onAutocompleteOpened(): void {
    this.isOpened = true;
  }

  onAutocompleteClosed(): void {
    this.isOpened = false;
  }

  onDelete(): void {
    this.property.control.patchValue('');
    this.property.control.markAsDirty();
    this.property.control.updateValueAndValidity();
  }

  trackOption(_index: number, item: AutocompleteOption): string {
    return item.value;
  }

  openOrClosePanel(event: Event, trigger: MatAutocompleteTrigger): void {
    event.stopPropagation();
    if (this.property.disabled) {
      return;
    }

    if (trigger.panelOpen) {
      trigger.closePanel();
      this.onAutocompleteClosed();
    } else {
      trigger.openPanel();
      this.onAutocompleteOpened();
    }
  }

  onOptionSelected(option: AutocompleteOption): void {
    this.optionSelected.emit(option);
  }

  private watchControlValueChanges(): void {
    if (this.property.control) {
      this.initAutocompleteGroups();
      this.initAutocompleteOptions();
    }
  }

  private getFilteredGroups(searchTerm: string | AutocompleteOption): AutocompleteGroup[] {
    const currentValue = typeof searchTerm === 'string' ? searchTerm : searchTerm?.value;
    if (currentValue && this.isStaticFilter) {
      return this.getAutocompleteGroups(currentValue);
    }

    return this.property.optionsList as AutocompleteGroup[];
  }

  private getFilteredOptions(options: AutocompleteOption[], value = ''): AutocompleteOption[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : '';
    if (this.isStaticFilter && options?.length) {
      return options.filter(item => item.key !== undefined && item.key.toLowerCase().includes(filterValue));
    }
    return this.property.optionsList as AutocompleteOption[];
  }

  private getAutocompleteGroups(currentValue: string): AutocompleteGroup[] {
    return (this.property.optionsList as AutocompleteGroup[])
      .map((item: AutocompleteGroup) => ({
        group: item.group,
        options: this.getFilteredOptions(item.options, currentValue),
      }))
      .filter(item => item.options.length > 0);
  }

  private initFlags(): void {
    this.isGroupedData = this.property.optionsList?.some(item => !!item['group']);
  }

  private initAutocompleteGroups(): void {
    this.autocompleteGroupsSubscription?.unsubscribe();
    this.autocompleteGroupsSubscription = this.property.control.valueChanges
      .pipe(
        startWith(() => this.loading$.next(true)),
        debounceTime(TYPING_DEBOUNCE_TYPE),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        filter(() => this.isGroupedData),
      )
      .subscribe(value => {
        this.autocompleteGroups$.next(this.getFilteredGroups(value));
        this.loading$.next(false);
      });
  }

  private initAutocompleteOptions(): void {
    this.autocompleteOptionsSubscription?.unsubscribe();
    this.autocompleteOptionsSubscription = this.property.control.valueChanges
      .pipe(
        startWith(() => this.loading$.next(true)),
        debounceTime(TYPING_DEBOUNCE_TYPE),
        distinctUntilChanged(),
        takeUntil(this.destroyed$),
        filter(() => !this.isGroupedData),
      )
      .subscribe(value => {
        this.autocompleteOptions$.next(
          this.getFilteredOptions(this.property.optionsList as AutocompleteOption[], value),
        );
        this.loading$.next(false);
      });
  }

  private initHighlight(): void {
    this.property.control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (typeof value === 'string') {
        this.toHighlight = value;
        this.searchChanged.emit(this.property.upperCase ? value.toUpperCase() : value);
      } else if (value === '') {
        this.toHighlight = '';
        this.searchChanged.emit('');
      }
    });
  }
}
