import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TableColumnFilterHeaderDirective } from '../../directives/table-column-filter-header/table-column-filter-header.directive';
import { TableSortHeaderDirective } from '../../directives/table-sort/table-sort-header.directive';
import { TableSortingOrder } from '../../models/table-sorting-order.enum';
import { TableColumnFilterComponent } from '../table-column-filter/table-column-filter.component';
import { TableSortComponent } from '../table-sort/table-sort.component';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    TableSortComponent,
    TableColumnFilterComponent,
    TableColumnFilterHeaderDirective,
    TableSortHeaderDirective,
  ],
})
export class TableHeaderComponent {
  @Input() column: string;
  @Input() isSortVisible = true;
  @Input() isFilterVisible = true;
  @Input() sortOrder = TableSortingOrder.None;
}
