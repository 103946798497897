export enum ERole {
  REPORTER = 'REPORTER',
  REVIEWER = 'REVIEWER',
  EXPERT = 'EXPERT',
  VIEWER = 'VIEWER',
  COORDINATOR_TOPIC = 'COORDINATOR_TOPIC',
  COORDINATOR_CLEARING = 'COORDINATOR_CLEARING',
  COMMITTEE_MEMBER = 'COMMITTEE_MEMBER',
  COMMITTEE_TRANSCRIBER = 'COMMITTEE_TRANSCRIBER',
  COORDINATOR_BRAND_OFFICE = 'COORDINATOR_BRAND_OFFICE',
  VERIFICATION_TASK_EDITOR = 'VERIFICATION_TASK_EDITOR',
  CAMPAIGN_AUTHOR = 'CAMPAIGN_AUTHOR',
  CAMPAIGN_COORDINATOR_BRAND = 'CAMPAIGN_COORDINATOR_BRAND',
  ADMIN = 'ADMIN',
  COORDINATOR_BRAND_OFFICE_VIEWER = 'COORDINATOR_BRAND_OFFICE_VIEWER',
  COORDINATOR_TOPIC_VIEWER = 'COORDINATOR_TOPIC_VIEWER',
  COORDINATOR_CLEARING_VIEWER = 'COORDINATOR_CLEARING_VIEWER',
  COMMITTEE_MEMBER_VIEWER = 'COMMITTEE_MEMBER_VIEWER',
  CAMPAIGN_AUTHOR_VIEWER = 'CAMPAIGN_AUTHOR_VIEWER',
  CAMPAIGN_COORDINATOR_BRAND_VIEWER = 'CAMPAIGN_COORDINATOR_BRAND_VIEWER',
  ADMIN_VIEWER = 'ADMIN_VIEWER',
  ADMIN_MANUFACTURER = 'ADMIN_MANUFACTURER',
  ADMIN_CLEARING = 'ADMIN_CLEARING',
  ADMIN_ROOT = 'ADMIN_ROOT',
  CAMPAIGN_COORDINATOR_IMPORTER = 'CAMPAIGN_COORDINATOR_IMPORTER',
  CAMPAIGN_COORDINATOR_IMPORTER_VIEWER = 'CAMPAIGN_COORDINATOR_IMPORTER_VIEWER',
  CAMPAIGN_COORDINATOR_DEALER = 'CAMPAIGN_COORDINATOR_DEALER',
  CAMPAIGN_COORDINATOR_DEALER_VIEWER = 'CAMPAIGN_COORDINATOR_DEALER_VIEWER',
}
