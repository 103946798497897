import { Component } from '@angular/core';

import { Recall2StatusComponent } from '../../../../layout/status/status.component';
import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';

@Component({
  selector: 'state-cell',
  templateUrl: './status-cell.component.html',
  standalone: true,
  imports: [Recall2StatusComponent],
})
export class StatusCellComponent extends AbstractGenericCellViewWithData {
  propertyName = 'status';
  isTopic = false;
  isCampaign = false;
}

export class StatusCellBinding implements IStatusCellBinding {
  constructor(
    public propertyName: string,
    public isTopic?: boolean,
    public isCampaign?: boolean,
  ) {}
}

interface IStatusCellBinding {
  propertyName: string;
  isCampaign?: boolean;
  isTopic?: boolean;
}
