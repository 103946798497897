import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import type { AttachmentFile } from '../../models/attachment-file.model';
import { ErrorMessageListComponent } from '../error-message-list/error-message-list.component';
import { FileListComponent } from '../file-list/file-list.component';
import { UploadAreaComponent } from '../upload-area/upload-area.component';
import type { FileErrorMessage } from './file-validation';
import {
  getErrorMessages,
  getMaxFileAmountError,
  getValidFiles,
  MAX_FILE_AMOUNT,
  toVirusDetectedError,
} from './file-validation';

@Component({
  selector: 'recall2-attachment',
  templateUrl: './attachment.component.html',
  standalone: true,
  imports: [UploadAreaComponent, FileListComponent, ErrorMessageListComponent],
})
export class AttachmentComponent implements OnChanges {
  @Input() files: AttachmentFile[];
  @Input() loading = false;

  @Output() newFiles = new EventEmitter<File[]>();
  @Output() downloadAll = new EventEmitter();
  @Output() download = new EventEmitter<AttachmentFile>();
  @Output() delete = new EventEmitter<AttachmentFile>();

  private uploadedFiles: AttachmentFile[] = [];

  messages: FileErrorMessage[] = [];
  validFiles: AttachmentFile[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['files']?.currentValue) {
      if (changes['files']?.currentValue.length >= this.uploadedFiles.length) {
        this.addVirusErrorMessages();
      }
      this.uploadedFiles = [...changes['files'].currentValue];
      this.validFiles = this.uploadedFiles.filter((file: AttachmentFile) => !file.infected);
    }
  }

  onNewFiles(newFiles: File[]): void {
    if ([...this.uploadedFiles, ...newFiles].length > MAX_FILE_AMOUNT) {
      this.messages = [getMaxFileAmountError()];
      return;
    }

    this.messages = getErrorMessages(newFiles);
    newFiles = getValidFiles(newFiles);

    if (newFiles.length > 0) {
      this.newFiles.emit(newFiles);
    }
  }

  onDownloadAll(): void {
    this.downloadAll.emit();
  }

  onDownload(file: AttachmentFile): void {
    this.download.emit(file);
  }

  onDelete(file: AttachmentFile): void {
    this.delete.emit(file);
  }

  private addVirusErrorMessages(): void {
    const virusErrorMessages = this.files
      .filter(x => x.infected)
      .map(x => x.filename)
      .map(filename => toVirusDetectedError(filename));

    this.messages = [...this.messages, ...virusErrorMessages];
  }
}
