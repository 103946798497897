import { NgForOf } from '@angular/common';
import type { OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import type { Subscription } from 'rxjs';

import { Recall2ButtonsToggleGroupComponent, Recall2ToggleElementComponent } from '../../../../buttons';
import { Recall2InputNumberComponent } from '../../../../form/components/recall2-input-number';
import { InputNumberProperty } from '../../../../form/model';
import { ENumberFilterComparator, IRecall2FilterNumberParam } from '../../../models/filter.model';
import { RECALL2_FILTER_NUMBER_OPTIONS } from './constants/recall2-filter-number-comparator-option.constant';
import type { Recall2FilterNumberOption } from './models/recall2-filter-number-option.model';

@Component({
  selector: 'recall2-filter-number',
  templateUrl: './recall2-filter-number.component.html',
  styleUrls: ['./recall2-filter-number.component.scss'],
  standalone: true,
  imports: [
    NgForOf,
    Recall2ButtonsToggleGroupComponent,
    Recall2ToggleElementComponent,
    Recall2InputNumberComponent,
    TranslateModule,
  ],
})
export class Recall2FilterNumberComponent implements OnDestroy, OnChanges {
  @Input() currentSelectedFilter: IRecall2FilterNumberParam;
  @Input() emitFilterChangeOnDestroy = true; // For compatibility between phases 1 and 2
  @Output() selectedFilter = new EventEmitter<IRecall2FilterNumberParam>();

  selectedFilterComparator: ENumberFilterComparator;
  ENumberFilterComparator = ENumberFilterComparator;

  filterInputNumberProperty: InputNumberProperty;

  filterNumberOptions: Recall2FilterNumberOption[] = RECALL2_FILTER_NUMBER_OPTIONS;

  private filterInputNumberPropertySubs: Subscription;

  ngOnDestroy(): void {
    if (this.emitFilterChangeOnDestroy) {
      this.emitChanges();
    }
    this.unsubscribeFilterInputNumber();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentSelectedFilter && changes.currentSelectedFilter.currentValue) {
      this.buildForm();
    }
  }

  onFilterComparatorChange(selectedOption: string): void {
    this.selectedFilterComparator = selectedOption as ENumberFilterComparator;

    if (!this.emitFilterChangeOnDestroy) {
      this.emitChanges();
    }
  }

  private buildForm(): void {
    this.unsubscribeFilterInputNumber();

    this.selectedFilterComparator = this.currentSelectedFilter.comparator ?? ENumberFilterComparator.EQUALS;

    this.filterNumberOptions = this.currentSelectedFilter.allowedComparators
      ? this.filterOptionsByAllowedComparators()
      : RECALL2_FILTER_NUMBER_OPTIONS;

    this.filterInputNumberProperty = new InputNumberProperty(
      'name',
      true,
      'recall2-filter.number.input',
      { maxLength: 128 },
      false,
      new FormControl(this.currentSelectedFilter.value[0], Validators.required),
      false,
    );

    this.watchFilterInputNumber();
  }

  private watchFilterInputNumber(): void {
    this.filterInputNumberPropertySubs = this.filterInputNumberProperty.control.valueChanges.subscribe(() => {
      if (!this.emitFilterChangeOnDestroy) {
        this.emitChanges();
      }
    });
  }

  private emitChanges(): void {
    const selectedFilter: IRecall2FilterNumberParam = this.mapToIRecall2FilterNumberParam();
    this.selectedFilter.emit(selectedFilter);
  }

  private mapToIRecall2FilterNumberParam(): IRecall2FilterNumberParam {
    const filterParam: IRecall2FilterNumberParam = {
      identifier: this.currentSelectedFilter?.identifier ?? null,
      translationKey: this.currentSelectedFilter?.translationKey ?? null,
      type: this.currentSelectedFilter?.type ?? null,
      comparator: this.selectedFilterComparator ?? null,
      value: this.filterInputNumberProperty?.control.value
        ? [Number(this.filterInputNumberProperty.control.value)]
        : [],
    };
    if (this.currentSelectedFilter?.allowedComparators) {
      filterParam.allowedComparators = this.currentSelectedFilter.allowedComparators;
    }
    return filterParam;
  }

  private unsubscribeFilterInputNumber(): void {
    if (this.filterInputNumberPropertySubs) {
      this.filterInputNumberPropertySubs.unsubscribe();
    }
  }

  private filterOptionsByAllowedComparators(): Recall2FilterNumberOption[] {
    return RECALL2_FILTER_NUMBER_OPTIONS.filter(option => {
      return this.currentSelectedFilter.allowedComparators.includes(option.value);
    });
  }
}
