<div (click)="onClick($event)">
  <button
    #primaryButton
    class="rc2-font--body--l rc2-display--flex rc2-align-items--center btn btn--primary {{rc2ButtonStyles}}"
    [ngClass]="{
    'spinner spinner-box':isAnimating || forceAnimation,
    'disabled': isDisabled,
    'button--svg-width': !!svgIcon || iconClass
  }"
    [disabled]="isDisabled"
  >
    <div [style.visibility]="(isAnimating || forceAnimation) ? 'hidden' : 'visible'" class="button-content">
      <em
        *ngIf="iconClass"
        class="icon {{iconClass}}"
      ></em>
      <ng-container *ngTemplateOutlet="svgIcon"></ng-container>
      <ng-content></ng-content>
    </div>
  </button>
</div>
