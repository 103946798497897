import { InjectionToken } from '@angular/core';

import type { FeaturePhase } from '../models/feature-phase.model';

export const RECALL2_FEATURE_FLAG_FEATURE_PHASES_TOKEN = new InjectionToken<FeaturePhase[]>(
  'RECALL2_FEATURE_FLAG_FEATURE_PHASES_TOKEN',
  {
    factory: (): FeaturePhase[] => [],
  },
);
