import { NgClass, NgIf } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { Recall2IconHelpComponent } from '../../../icons/recall2-icon-help/recall2-icon-help.component';
import { Recall2IconPartSelectComponent } from '../../../icons/recall2-icon-part-select/recall2-icon-part-select.component';
import { Recall2IconRequiredComponent } from '../../../icons/recall2-icon-required/recall2-icon-required.component';
import { Recall2IconSelectComponent } from '../../../icons/recall2-icon-select/recall2-icon-select.component';
import { Recall2IconUnselectComponent } from '../../../icons/recall2-icon-unselect/recall2-icon-unselect.component';
import { Recall2IconWarningComponent } from '../../../icons/recall2-icon-warning/recall2-icon-warning.component';
import { ELabelAlignment, EThreeStepCheckboxStates, ThreeStepCheckboxProperty } from '../../model';
import { ThreeStepCheckboxController } from './three-step-checkbox-controller';

@Component({
  selector: 'recall2-three-step-checkbox',
  standalone: true,
  templateUrl: './recall2-three-step-checkbox.component.html',
  styleUrls: ['./recall2-three-step-checkbox.component.scss'],
  imports: [
    NgIf,
    NgClass,
    TranslateModule,
    MatTooltipModule,
    Recall2IconHelpComponent,
    Recall2IconSelectComponent,
    Recall2IconPartSelectComponent,
    Recall2IconUnselectComponent,
    Recall2IconWarningComponent,
    Recall2IconRequiredComponent,
  ],
})
export class Recall2ThreeStepCheckboxComponent implements OnInit, OnDestroy {
  @Input() property: ThreeStepCheckboxProperty;
  @Input() isFormSubmitted: boolean;
  @Input() labelAlignment: ELabelAlignment = ELabelAlignment.RIGHT;
  @Input() slaveStepCheckboxProperties: Observable<ThreeStepCheckboxProperty[]>;

  @Output() onChange = new EventEmitter<EThreeStepCheckboxStates>();

  public checkboxStates = EThreeStepCheckboxStates;

  readonly alignment = ELabelAlignment;

  private slaveController: ThreeStepCheckboxController;

  ngOnInit(): void {
    if (!(this.property.control.value in this.checkboxStates)) {
      this.property.control.setErrors({ incorrect: true });
    }

    if (this.slaveStepCheckboxProperties) {
      this.initializeController();
    }
  }

  ngOnDestroy(): void {
    if (this.slaveController) {
      this.slaveController.cleanUp();
    }
  }

  private initializeController(): void {
    this.slaveController = new ThreeStepCheckboxController(this.property, this.slaveStepCheckboxProperties);
  }

  public onClick(newState: EThreeStepCheckboxStates): void {
    if (!this.property.control.disabled) {
      this.property.control.setValue(newState);
      this.property.control.markAsDirty();
      this.onChange.emit(newState);
    }
  }
}
