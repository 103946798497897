import { NgFor, NgIf, NgSwitch, NgSwitchCase, UpperCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { AppsLinkDirective } from '../../../../navigation/components/apps-link/apps-link.directive';
import { App } from '../../../../navigation/models/app';
import { EAccessLevel } from '../../../../dynamic-content/models/access.model';
import { EObjectType } from '../../../../dynamic-content/models/object-type.model';
import { EUrgencyLevel } from '../../../../dynamic-content/models/urgency.model';
import {
  Recall2IconObjektAgendapunktComponent,
  Recall2IconObjektAktionComponent,
  Recall2IconObjektClearingComponent,
  Recall2IconObjektMeldungComponent,
  Recall2IconObjektSitzungComponent,
  Recall2IconObjektThemaComponent,
  Recall2IconOpenTabComponent,
  Recall2IconTopicUrgencyFourComponent,
  Recall2IconTopicUrgencyOneComponent,
  Recall2IconTopicUrgencyThreeComponent,
  Recall2IconTopicUrgencyTwoComponent,
  Recall2IconUrgencyFourComponent,
  Recall2IconUrgencyOneComponent,
  Recall2IconUrgencyThreeComponent,
  Recall2IconUrgencyTwoComponent,
} from '../../../../icons';
import { URL_PATHS } from '../../../../navbar/url-paths';
import { getValueFromObject } from '../../../../utils/functions/functions';
import type { LinkedObject, LinkedObjectStringId } from '../../../models/linked-object.model';
import { EOverlayIconType } from '../../../models/overlay-icon-type.model';

@Component({
  selector: 'recall2-object-template',
  templateUrl: './recall2-object-template.component.html',
  styleUrls: ['./recall2-object-template.component.scss'],
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    NgFor,
    NgIf,
    Recall2IconObjektAktionComponent,
    Recall2IconObjektMeldungComponent,
    Recall2IconTopicUrgencyFourComponent,
    Recall2IconTopicUrgencyThreeComponent,
    Recall2IconTopicUrgencyTwoComponent,
    Recall2IconTopicUrgencyOneComponent,
    Recall2IconObjektThemaComponent,
    Recall2IconObjektClearingComponent,
    Recall2IconUrgencyOneComponent,
    Recall2IconUrgencyTwoComponent,
    Recall2IconUrgencyThreeComponent,
    Recall2IconUrgencyFourComponent,
    Recall2IconObjektAgendapunktComponent,
    Recall2IconObjektSitzungComponent,
    Recall2IconOpenTabComponent,
    TranslateModule,
    AppsLinkDirective,
    UpperCasePipe,
  ],
})
export class Recall2ObjectTemplateComponent {
  readonly iconTypes = EOverlayIconType;
  CAMPAIGN_APP_OVERVIEW = '/campaign/';
  CAMPAIGNS = 'campaigns';
  VIEW_ONLY = '/view-only';
  level = EUrgencyLevel;
  EAccessLevel = EAccessLevel;
  openInNewTab = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() set itemsList(list: any | LinkedObject) {
    this.mapItemsList(list);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get itemsList(): any[] {
    return this._itemsList;
  }

  // targetApp will be deprecated. Because it is now determined based on the type of the objects in itemsList
  @Input() targetApp: App;
  // iconType will be deprecated. Because it is now determined based on the type of the objects in itemsList
  @Input() iconType: EOverlayIconType;
  @Input() urlPath: string;
  _itemsList = [];

  getOr = getValueFromObject;

  mapItemsList(list = []): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this._itemsList = list.map((element: any | LinkedObject) => {
      if (!element['targetApp']) {
        element = { ...element, targetApp: this.mapToTargetApp(element) };
      }
      if (!element['urlPath']) {
        element = { ...element, urlPath: this.mapToUrlPath(element) };
      }
      const objectId: string = this.getId(element);
      element = { ...element, objectId };
      return element;
    });
  }

  getUrgencyLevel(urgencyLevel: EUrgencyLevel): EUrgencyLevel {
    return urgencyLevel ? urgencyLevel : EUrgencyLevel.DEFAULT;
  }

  private getId(element: LinkedObject | LinkedObjectStringId): string {
    return element.targetApp === App.Campaigns ? element.code : element.id?.toString();
  }

  private mapToTargetApp(element: LinkedObject): App {
    switch (element.type) {
      case EObjectType.AGENDA_ITEM:
        return App.Committee;
      case EObjectType.CLEARING:
      case EObjectType.TOPIC:
        return App.Topics;
      case EObjectType.NOTIFICATION:
        return App.Preliminary;
      case EObjectType.MEETING:
        return App.Committee;
      case EObjectType.CAMPAIGN:
        return App.Campaigns;
      default:
        return this.targetApp;
    }
  }

  private mapToUrlPath(element: LinkedObject): string {
    switch (element.type) {
      case EObjectType.AGENDA_ITEM:
        return URL_PATHS.COMMITTEE_APP_AGENDA_ITEM_OVERVIEW;
      case EObjectType.CLEARING:
        return URL_PATHS.TOPIC_APP_CLEARING_OVERVIEW;
      case EObjectType.TOPIC:
        return URL_PATHS.TOPIC_APP_OVERVIEW;
      case EObjectType.NOTIFICATION:
        return URL_PATHS.NOTIFICATION_APP_OVERVIEW;
      case EObjectType.CAMPAIGN:
        return this.CAMPAIGN_APP_OVERVIEW;
      case EObjectType.MEETING:
        return URL_PATHS.COMMITTEE_APP_MEETING_OVERVIEW;
      default:
        return this.urlPath;
    }
  }
}
