import { NgClass, NgStyle } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'recall2-button-outline',
  templateUrl: './recall2-button-outline.component.html',
  styleUrls: ['./recall2-button-outline.component.scss'],
  standalone: true,
  imports: [NgStyle, NgClass],
})
export class Recall2ButtonOutlineComponent {
  @Input() cssStyles: { [cssProperty: string]: string } = { klass: '' };
  @Input() rc2ButtonStyles = '';
  @Input() isDisabled = false;
}
