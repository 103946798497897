<div class="rc2-overview-wrapper pad-bottom-erase">
  <recall2-notification-confirm
    #confirmComponent
    [title]="'notification.send.forward'"
    [message]="'notification.send.forward.caption'"
    [currentObjectId]="notificationId"
    (onCommentAdded)="setComment($event)"
    [commentRequired]="true"
    [isFormSubmitted]="showErrors"
    [showSelectUser]="true"
    (onSelectedUser)="setSelectedUser($event)"
  ></recall2-notification-confirm>
  <recall2-floating-bar>
    <div class="action-bar">
      <recall2-button-tertiary
        data-testid="notification-send-button-cancel"
        class="action-bar__button"
        [iconClass]="'icon-i90-unselect'"
        (click)="cancelSend()"
      >
        {{ 'notifications.send.button.cancel' | translate }}
      </recall2-button-tertiary>
      <recall2-button-primary
        data-testid="notification-send-button-forward"
        class="action-bar__button"
        [forceAnimation]="isSending"
        [iconClass]="'icon-i46-mail-summary'"
        (click)="forwardNotificationToReviewer()"
      >
        {{ 'notifications.send.button.forward' | translate }}
      </recall2-button-primary>
    </div>
  </recall2-floating-bar>
</div>
