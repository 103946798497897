<!-- Pagination definition -->
<div
  data-cy="pagination"
  class="row rc2-pagination mar-no rc2-pad--bottom--3xs rc2-pad--top--3xsz"
>
  <div class="col-md-6 rc2-pad--no rc2-pad--left--xs">
    <div
      data-cy="elements-per-page"
      class="pagination__elements-per-page rc2-justify--left rc2-pad--no rc2- rc2-font--body--m"
    >
      <span class="rc2-color--grey-600">{{ "paginator.selectedElementsPerSite" | translate}}</span>
      <div class="wrapper rc2-mar--right--xs">
        <select
          data-cy="select-page-size"
          class="pad-top-erase pad-bottom-erase rc2-pad--left--xl rc2-pad--right--xs"
          (change)="updateItemsPerPage()"
          [(ngModel)]="paginationModel.paginationSelectedPageSize"
        >
          <option *ngFor="let itemsPerPage of itemsPerPageList">{{ itemsPerPage }}</option>
        </select>
      </div>
      <div class="rc2-color--grey-600">
        <span>{{ getElementSumFromFirst()}} - </span>
        <span data-cy="current-page-elements">{{ paginationModel.paginationPageElements}}</span>
        <span> {{ "paginator.from" | translate }} </span>
        <span data-cy="total-elements">{{ paginationModel.paginationTotalElements }}</span>
        <span> {{ "paginator.maximumElements" | translate }}</span>
      </div>
    </div>
  </div>
  <div
    class="col-md-6 pad-no"
    *ngIf="paginationModel.paginationTotalPages !== 1"
  >
    <div class="pagination__steps rc2-justify--flex-end rc2-pad--left--xxl">
      <div class="pagination__pages rc2-color--grey-600">
        <span>{{ "paginator.site" | translate }} </span>
        <span data-cy="current-page">{{ paginationModel.currentSelectedPage }}</span>
        <span> {{ "paginator.from" | translate }} </span>
        <span data-cy="total-pages">{{ paginationModel.paginationTotalPages }}</span>
      </div>
      <div class="pagination__divider"></div>
      <div *ngIf="isPaginationPageFirst()">
        <div
          class="pagination__prev-page"
          data-cy="prev-page"
          disabled
        >
        </div>
      </div>
      <div
        (click)="navigateToPreviousPage()"
        *ngIf="!isPaginationPageFirst()"
      >
        <div
          class="pagination__prev-page"
          data-cy="prev-page"
        >
        </div>
      </div>
      <div class="pagination__divider rc2-border--grey--200-tertiary"></div>
      <input
        data-cy="input-page-number"
        (keyup.enter)="updateCurrentPage()"
        [(ngModel)]="paginationModel.paginationPageNumber"
        class="pagination_pageNumber pad-bottom-erase pad-top-erase "
        type="text"
        [recall2AllowedCharacters]="pageInputAllowedCharacters"
      />
      <div class="pagination__divider"></div>
      <div *ngIf="isPaginationPageLast()">
        <div
          class="pagination__next-page"
          data-cy="next-page"
          disabled
        >
        </div>
      </div>
      <div
        (click)="navigateToNextPage()"
        *ngIf="!isPaginationPageLast()"
      >
        <div
          class="pagination__next-page"
          data-cy="next-page"
        >
        </div>
      </div>
    </div>
  </div>
</div>
