import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class WidthExtractor {
  getClientWidth(element: HTMLElement): number {
    if (!element?.clientWidth) {
      return 0;
    }

    return element.clientWidth;
  }

  getInnerWidth(element: Window): number {
    if (!element?.innerWidth) {
      return 0;
    }

    return element.innerWidth;
  }
}
