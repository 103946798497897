<div class="col-md-8 rc2-pad--left--0">
  <recall2-summary-text
    [label]="'notification.vehiclesAffected' | translate"
    [content]="notification.vehiclesAffected"
  ></recall2-summary-text>
</div>

<div class="rc2-mar--left--0 rc2-mar--bottom--xl">
  <h4 class="rc2-mar--bottom--s">
    {{ 'notifications.form.summary.vehicles.productiondate' | translate }}
  </h4>

  <div class="rc2-separator"></div>
  <div class="row">
    <div
      class="col-md-5 rc2-color--grey-600 rc2-pad--bottom--3xs rc2-pad--top--3xs rc2-pad--left--s rc2-font--body--s"
      id="first-affected-date-label"
    >
      {{ 'notification.firstProdDateAffected' | translate }}
    </div>
    <div
      class="col-md-5 rc2-color--grey-600 rc2-pad--bottom--3xs rc2-pad--top--3xs rc2-font--body--s"
      id="last-affected-date-label"
    >
      {{ 'notification.lastProdDateAffected' | translate }}
    </div>
    <div class="rc2-separator"></div>
    <ng-container *ngFor="let prodDatePeriod of notification.prodDateRangesAffected">
      <div
        class="col-md-5 rc2-pad--bottom--xxs rc2-pad--top--xxs rc2-pad--left--s rc2-font--body--m"
        id="first-production-date"
      >
        {{ prodDatePeriod.firstProdDateAffected }}
      </div>
      <div
        class="col-md-5 rc2-pad--bottom--xxs rc2-pad--top--xxs rc2-font--body--m"
        id="last-production-date"
      >
        {{ prodDatePeriod.lastProdDateAffected }}
      </div>
    </ng-container>

    <div class="rc2-separator"></div>
  </div>
</div>

<div class="rc2-mar--left--0">
  <h4 class="rc2-mar--bottom--s">{{ 'notification.vehicleRange' | translate }}</h4>

  <div class="row">
    <div class="rc2-separator"></div>
    <div class="col-md-5 rc2-color--grey-600 rc2-pad--bottom--3xs rc2-pad--top--3xs rc2-pad--left--s rc2-font--body--s">
      {{ 'notifications.form.vehicles.vin.firstVin' | translate }}
    </div>
    <div class="col-md-5 rc2-color--grey-600 rc2-pad--bottom--xxs rc2-pad--top--3xs rc2-font--body--s">
      {{ 'notifications.form.vehicles.vin.lastVin' | translate }}
    </div>
    <div class="rc2-separator"></div>
    <ng-container *ngFor="let vin of notification.vehicleRange">
      <div class="col-md-5 rc2-pad--bottom--xxs rc2-pad--top--xxs rc2-pad--left--s rc2-font--body--m">
        {{ vin.firstVin }}
      </div>
      <div class="col-md-5 rc2-pad--bottom--xxs rc2-pad--top--xxs rc2-font--body--m">
        {{ vin.lastVin }}
      </div>
      <div class="rc2-separator"></div>
    </ng-container>
  </div>
</div>

<div class="col-md-8 rc2-pad--left--0">
  <recall2-summary-text
    [label]="'notification.affectedVehiclesCount' | translate"
    [content]="notification.affectedVehiclesCount"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notification.marketsAndRegions' | translate"
    [content]="notification.marketsAndRegions"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notifications.form.vehicles.commentVehicles.label' | translate"
    [content]="notification.commentVehicles"
  ></recall2-summary-text>
</div>
