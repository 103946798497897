import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import type { APSDownload } from './aps-download.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadApsService {
  private _downloadApsBaseApiUrl = '/download-aps';

  constructor(private _http: HttpClient) {}

  getDownloadUrl(apsName: string, countryCode: string): Observable<string> {
    return this._http.get<string>(
      `${environment.apiUrlDownloadAps}${this._downloadApsBaseApiUrl}/?brandName=${apsName}&country=${countryCode}`,
    );
  }

  getAvailableAPS(apsList: string[], countryCodes: string[]): Observable<APSDownload[]> {
    return this._http.get<APSDownload[]>(
      `${environment.apiUrlDownloadAps}${this._downloadApsBaseApiUrl}/available?brandNames=${apsList}&countryCodes=${countryCodes}`,
    );
  }
}
