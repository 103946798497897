import type { IRecall2FilterParam } from '../../overlay/models/filter.model';
import { EDateFilterComparator } from '../../overlay/models/filter.model';

export const isDateFilterButtonEnabled = (filterParam: IRecall2FilterParam): boolean => {
  switch (filterParam.comparator) {
    case EDateFilterComparator.BETWEEN:
      return !!filterParam.value[0] && !!filterParam.value[1] && filterParam.value[0] <= filterParam.value[1];
    case EDateFilterComparator.OLDER:
    case EDateFilterComparator.NEWER:
      return !!filterParam.value[0];
  }
};
