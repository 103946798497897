<div (click)="onClick($event)">
  <button
    #tertiaryButton
    class="rc2-font--body--l rc2-display--flex rc2-align-items--center btn btn--tertiary {{rc2ButtonStyles}}"
    [ngClass]="{
    'spinner spinner-box': isAnimating || forceAnimation,
    'disabled': isDisabled,
    'button--svg-text-width': (!!svgIcon || iconClass) && isTextPresent,
    'button--svg-only-width': !!svgIcon && !iconClass && !isTextPresent
  }"
    [disabled]="isDisabled"
  >
    <div
      class="button-content"
      [style.visibility]="(isAnimating || forceAnimation) ? 'hidden' : 'visible'"
    >
      <em
        *ngIf="iconClass"
        class="icon {{iconClass}}"
      ></em>
      <ng-container *ngTemplateOutlet="svgIcon"></ng-container>
      <span #textContent>
        <ng-content></ng-content>
      </span>
    </div>
  </button>
</div>
