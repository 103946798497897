<div class="directory-search-component">
  <div class="close-icon" (click)="onCloseModal()">
    <recall2-svg-icon class="rc2-icon--color--primary" name="close" size="small"> </recall2-svg-icon>
  </div>
  <div class="search-section">
    <h5 class="mar-bottom-erase">{{ 'directory_search.title' | translate }}</h5>
    <label class="mar-bottom-erase rc2-font--family--regular">{{ 'directory_search.subtitle' | translate }}</label>

    <div class="search-fields">
      <div class="splitted-fields">
        <recall2-input-text data-cy="directoryFamilyNameField" [property]="familyNameField"></recall2-input-text>

        <recall2-input-text data-cy="directoryGivenNameField" [property]="givenNameField"></recall2-input-text>
      </div>

      <div class="splitted-fields">
        <recall2-input-text data-cy="directoryUserIdField" [property]="userIdField"></recall2-input-text>

        <recall2-input-text data-cy="directorDepartmentField" [property]="departmentField"></recall2-input-text>
      </div>

      <div class="single-field">
        <recall2-input-text data-cy="directoryCorporationField" [property]="corporationField"></recall2-input-text>
      </div>

      <div class="buttons">
        <recall2-button-tertiary
          *ngIf="isClearButtonVisible"
          class="mar-right-medium"
          data-cy="directoryClearButton"
          (click)="onClear()"
        >
          {{ 'directory_search.action.clear' | translate }}
        </recall2-button-tertiary>
        <recall2-button-primary
          [isDisabled]="!isClearButtonVisible"
          [forceAnimation]="isSearching"
          data-cy="directorySearchButton"
          (click)="onSearch()"
        >
          {{ 'directory_search.action.search' | translate }}
        </recall2-button-primary>
      </div>
    </div>

    <div *ngIf="isResultVisible" class="results">
      <div *ngIf="matches !== null && matches >= 100" class="exceed-results">
        {{ 'directory_search.exceed_results' | translate }}
      </div>

      <div *ngIf="matches !== null && matches !== 1" class="results-count">
        {{ 'directory_search.showing_matches' | translate: { matches: matches } }}
      </div>
      <div *ngIf="matches !== null && matches === 1" class="results-count">
        {{ 'directory_search.showing_match' | translate }}
      </div>

      <ng-container *ngIf="matches && matches > 0">
        <div class="result-list">
          <recall2-auto-suggest-list
            #autoSuggestResult
            id="autoSuggestResult"
            [isCheckSelectedItems]="true"
            [entries]="entries"
            [searchTerm]="''"
            [objectType]="0"
            [showButtons]="false"
            (selectEntry)="onEntrySelected($event)"
          ></recall2-auto-suggest-list>
        </div>

        <hr />
      </ng-container>

      <div class="bottom-actions">
        <div class="select-user-button" *ngIf="matches">
          <recall2-button-primary
            [isDisabled]="isSelectUserDisabled"
            data-cy="directorySelectUserButton"
            (click)="onSelectUser()"
          >
            {{ 'directory_search.action.select_user' | translate }}
          </recall2-button-primary>
        </div>
      </div>
    </div>
  </div>
</div>
