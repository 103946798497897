import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { iconDeleteTagSmall, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';

@Component({
  selector: 'recall2-chip',
  standalone: true,
  templateUrl: './recall2-chip.component.html',
  styleUrls: ['./recall2-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, NgIf, SVGIconModule],
})
export class Recall2ChipComponent {
  @Input() isReadOnly = false;
  @Input() isMoreFilters: boolean;
  @Output() remove = new EventEmitter();

  constructor(private iconsRegistry: SVGIconsRegistry) {
    this.iconsRegistry.registerIcons([iconDeleteTagSmall]);
  }

  removeItem(event: Event): void {
    if (this.isReadOnly) {
      return;
    }

    event.stopPropagation();

    this.remove.emit();
  }
}
