import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import type { IRecall2SortItem } from '../../overlay/models/filter.model';
import type { IRecall2Sort } from '../models/recall2-sort';
import { Recall2SortingOrder } from '../models/recall2-sort';

const KEY_TO_AVOID_SORTING = 'Linkable-Object_sort';

export class Recall2TableSortService {
  sortingList: IRecall2Sort[] = [];
  tableId: string;
  sortKey = '';

  private _sortingList: BehaviorSubject<IRecall2Sort[]> = new BehaviorSubject(null);
  private _sortHttpParams: BehaviorSubject<HttpParams> = new BehaviorSubject(null);
  private _sortBody: BehaviorSubject<IRecall2SortItem[]> = new BehaviorSubject(null);
  private sortInTable: boolean;

  setTableId(value: string): void {
    this.tableId = value;
    this.sortKey = this.tableId + '_sort';
  }

  setSortInTable(sortInTable: boolean): void {
    this.sortInTable = sortInTable;
  }

  getSortInTable(): boolean {
    return this.sortInTable;
  }

  getSortingList(): BehaviorSubject<IRecall2Sort[]> {
    return this._sortingList;
  }

  setSortingList(sortingList: IRecall2Sort[]): void {
    return this._sortingList.next(sortingList);
  }

  getSortingHttpParams(): BehaviorSubject<HttpParams> {
    return this._sortHttpParams;
  }

  getSortingBody(): BehaviorSubject<IRecall2SortItem[]> {
    return this._sortBody;
  }

  initializeSorting(defaultSorting: IRecall2Sort[]): void {
    const sortJsonString = sessionStorage.getItem(this.sortKey);
    if (sortJsonString && this.sortKey !== KEY_TO_AVOID_SORTING) {
      this.sortingList = JSON.parse(sortJsonString);
    } else {
      this.sortingList = defaultSorting;
      if (!this.sortInTable) {
        sessionStorage.setItem(this.sortKey, JSON.stringify(this.sortingList));
      }
    }
    this.emitSortValue();
  }

  buildHttpSortParams(sortingList: IRecall2Sort[]): HttpParams {
    let httpParams = new HttpParams();
    for (const item of sortingList) {
      if (item.sortingOrder !== undefined) {
        httpParams = httpParams.append('sort', item.identifier + ',' + item.sortingOrder);
      }
    }
    return httpParams;
  }

  buildSortBody(): IRecall2SortItem[] {
    const sortBody: IRecall2SortItem[] = [];
    for (const item of this.sortingList) {
      sortBody.push({
        identifier: item.identifier,
        value: item.sortingOrder,
      });
    }

    return sortBody;
  }

  applySorting(identifier: string, sortingState: Recall2SortingOrder, saveInSessionStorage = true): void {
    this.addSort(identifier, sortingState);
    if (saveInSessionStorage) {
      sessionStorage.setItem(this.sortKey, JSON.stringify(this.sortingList));
    }
    this.emitSortValue();
  }

  getSortingOrder(identifier: string): Recall2SortingOrder {
    const columnSortingState = this.sortingList.find(sort => sort.identifier === identifier);
    return columnSortingState
      ? this.sortingList.find(sort => sort.identifier === identifier).sortingOrder
      : Recall2SortingOrder.DESC;
  }

  isPrimarySorting(identifier: string): boolean {
    return this.sortingList.length > 0 ? this.sortingList[0].identifier === identifier : false;
  }

  private emitSortValue(): void {
    this._sortingList.next(this.sortingList);
    const params = this.buildHttpSortParams(this.sortingList);
    this._sortHttpParams.next(params);
    const body = this.buildSortBody();
    this._sortBody.next(body);
  }

  private addSort(identifier: string, sortingState: Recall2SortingOrder): void {
    const sort = this.sortingList.find(sortElement => sortElement.identifier === identifier);
    if (sort) {
      this.sortingList.splice(this.sortingList.indexOf(sort), 1);
    }
    this.sortingList.unshift({ identifier: identifier, sortingOrder: sortingState });
  }
}
