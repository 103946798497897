import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TabTitleService {
  constructor(
    private titleService: Title,
    private translate: TranslateService,
  ) {}

  setTitleByKey(titleKey: string): void {
    this.translate.stream(titleKey).subscribe(val => {
      this.titleService.setTitle(val);
    });
  }

  setTitleByKeyWithIdName(titleKey: string, id: string, name: string): void {
    this.translate.stream(titleKey).subscribe(val => {
      this.titleService.setTitle(`[${val} - ${id}] ${name}`);
    });
  }

  setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  getTitle(): string {
    return this.titleService.getTitle();
  }
}
