export enum NotificationStatus {
  DRAFT = 'DRAFT',
  DISCARDED = 'DISCARDED',
  TO_REVISE_REVIEWER = 'TO_REVISE_REVIEWER',
  TO_REVISE_BRAND_OFFICE = 'TO_REVISE_BRAND_OFFICE',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  ACCEPTED = 'ACCEPTED',
  LINKED = 'LINKED',
  TRANSFERRED = 'TRANSFERRED',
}
