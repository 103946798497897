import type { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '../../rest-api/notification/notification.service';
import { DataService } from '../components/notification-form/service/data/data.service';
import type { GetPageableDataForOverviewTableAction, SaveCurrentNotificationAction } from './notification.actions';
import {
  ENotificationActions,
  GetPageableDataForOverviewTableFailedAction,
  GetPageableDataForOverviewTableSuccessAction,
  SaveCurrentNotificationFailedAction,
  SaveCurrentNotificationSuccessAction,
} from './notification.actions';

@Injectable()
export class NotificationEffects {
  constructor(
    private _actions$: Actions,
    private _notificationService: NotificationService,
    private dataService: DataService,
  ) {}

  getPageableDataForOverviewTableAction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ENotificationActions.GetPageableDataForOverviewTable),
      switchMap((action: GetPageableDataForOverviewTableAction) => {
        return this._notificationService
          .getNotificationsForList(action.page, action.pageSize, action.currentObjectId, action.filter, action.sort)
          .pipe(
            map(response => {
              if (response) {
                return new GetPageableDataForOverviewTableSuccessAction(response, action.src);
              }
            }),
            catchError((error: HttpErrorResponse) => {
              return of(new GetPageableDataForOverviewTableFailedAction(error.error, action.src));
            }),
          );
      }),
    ),
  );

  saveCurrentNotificationAction$ = createEffect(() =>
    this._actions$.pipe(
      ofType(ENotificationActions.SaveCurrentNotification),
      switchMap((action: SaveCurrentNotificationAction) =>
        this.dataService.saveCurrentNotification(action.notification).pipe(
          map(notification => new SaveCurrentNotificationSuccessAction(notification)),
          catchError((error: HttpErrorResponse) => of(new SaveCurrentNotificationFailedAction(error.error))),
        ),
      ),
    ),
  );
}
