<div
  [ngClass]="{
  'rc2-object-table--ellipsis': type==tableTypes.Object,
  'rc2-word-break--all': type==tableTypes.Context
  }"
  class="rc2-font--body--m rc2-color--grey-900 {{additionalCssClasses}}"
  [title]="type==tableTypes.Object ? data[propertyName] : null"
>
  {{data[propertyName] | markEmpty}}
</div>
