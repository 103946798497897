<ul>
  <li
    *ngFor="let step of _steps"
    [routerLink]="[step.route]"
    queryParamsHandling="preserve"
    routerLinkActive="is-active"
    class="rc2-icon--cursor--pointer"
    [attr.data-cy]="step.id"
    [ngClass]="{
      'step-valid': step.validationStatus === validationStatus.VALID,
      'step-invalid': step.validationStatus === validationStatus.INVALID,
      'step-partly-valid':
        step.validationStatus === validationStatus.PARTLY_VALID,
      'step-initial': step.validationStatus === validationStatus.INITIAL
    }"
  >
    {{ step.translationKey | translate }}
  </li>
</ul>
