import type { HttpEvent, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { HttpEventType } from '@angular/common/http';
import type { Observable } from 'rxjs';
import { distinctUntilChanged, scan } from 'rxjs/operators';

const isHttpResponse = <T>(event: HttpEvent<T>): event is HttpResponse<T> => event.type === HttpEventType.Response;

const isHttpProgressEvent = <T>(event: HttpEvent<T>): event is HttpProgressEvent =>
  event.type === HttpEventType.DownloadProgress || event.type === HttpEventType.UploadProgress;

export enum UploadState {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Done = 'DONE',
}

export interface Upload {
  progress: number;
  loaded: number;
  total: number;
  state: UploadState;
}

const initialState: Upload = { state: UploadState.Pending, progress: 0, loaded: 0, total: 0 } as const;

const reduceState = <T>(upload: Upload, event: HttpEvent<T>): Upload => {
  if (isHttpProgressEvent(event)) {
    return {
      progress: event.total ? Math.round((100 * event.loaded) / event.total) : upload.progress,
      loaded: event.loaded || upload.loaded,
      total: event.total || upload.total,
      state: UploadState.InProgress,
    };
  }

  if (isHttpResponse(event)) {
    return {
      progress: 100,
      state: UploadState.Done,
      loaded: upload.loaded,
      total: upload.total,
    };
  }

  return upload;
};

export const uploadPipe = <T>(): ((source: Observable<HttpEvent<T>>) => Observable<Upload>) => {
  return (source): Observable<Upload> =>
    source.pipe(
      scan(reduceState, initialState),
      distinctUntilChanged((a, b) => a.state === b.state && a.progress === b.progress),
    );
};
