import { Component } from '@angular/core';

import { CustomDatePipe } from '../../../../pipes';
import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';

@Component({
  selector: 'date-cell',
  templateUrl: './date-cell.component.html',
  standalone: true,
  imports: [CustomDatePipe],
})
export class DateCellComponent extends AbstractGenericCellViewWithData implements IDateCellBinding {
  propertyName: string;
  additionalCssClasses: string[];
}

export class DateCellBinding implements IDateCellBinding {
  constructor(
    public propertyName: string,
    public additionalCssClasses?: string[],
  ) {}
}

export interface IDateCellBinding {
  propertyName: string;
  additionalCssClasses?: string[];
}
