<recall2-accordion-base
  #accordionBase
  [accordionType]="accordionType"
  [accordionBaseProperties]="accordionBaseProperties"
  (open)="open.emit()"
  (close)="close.emit()"
  (onOpenCompleted)="onOpenCompleted.emit()"
  (onCloseCompleted)="onCloseCompleted.emit()"
>
  <div
    [ngSwitch]="accordionType"
    header
  >
    <h5
      *ngSwitchCase="typeDefinition.H3"
      class="accordion-header-title"
    >
      {{ accordionBaseProperties?.translationKey | translate }}
    </h5>

    <h6
      *ngSwitchCase="typeDefinition.H4"
      class="accordion-header-title"
    >
      {{ accordionBaseProperties?.translationKey | translate }}
    </h6>

    <span
      *ngSwitchCase="typeDefinition.H5"
      class="accordion-header-title"
    >
      {{ accordionBaseProperties?.translationKey | translate }}
    </span>
  </div>

  <div body>
    <ng-content></ng-content>
  </div>
</recall2-accordion-base>
