<div
  class="history-container"
  [ngClass]="{'history-container-self': item.actor.userType === 'CURRENT'}"
>
  <recall2-description [item]="item"></recall2-description>

  <recall2-comment
    *ngIf="item.comment"
    [comment]="item.comment"
    [userType]="item.actor.userType"
    [params]="item.paramsJson"
  >
  </recall2-comment>

  <recall2-object-status
    *ngIf="item.paramsJson.status"
    [objectType]="objectType"
    [status]="item.paramsJson.status"
  >
  </recall2-object-status>

  <div class="owner-and-time rc2-font--size--12">
    {{ item.actor.lastName + ', ' + item.actor.firstName }}
    {{ '(' + item.actor.vwUserId + '),' }}
    {{ isToday(item.dateTime) ? ('history.item.today' | translate) + ',' : (item.dateTime | customDate) + ',' }}
    {{ item.dateTime | customTime }}
    {{ 'history.item.hour' | translate }}
  </div>
</div>
