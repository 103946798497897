import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import type { AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import type { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { InputTextProperty } from '../form/model';
import type { DirectorySearchActions } from './components/directory-search/recall2-directory-search.component';
import { Recall2DirectorySearchComponent } from './components/directory-search/recall2-directory-search.component';
import { Recall2AutoSuggestInputComponent } from './components/recall2-auto-suggest-input/recall2-auto-suggest-input.component';
import { Recall2AutoSuggestListComponent } from './components/recall2-auto-suggest-list/recall2-auto-suggest-list.component';
import type { IAutoSuggestCommon } from './models/auto-suggest-common';
import { IAutoSuggestConfiguration } from './models/auto-suggest-configuration';
import type { IAutoSuggestCountry } from './models/auto-suggest-country';
import type { IAutoSuggestCountryCode } from './models/auto-suggest-country-code';
import type { AutoSuggestManufacturer } from './models/auto-suggest-manufacturer';
import { EAutoSuggestObjectType } from './models/auto-suggest-object-type.enum';
import type { IAutoSuggestUser } from './models/auto-suggest-user';

export type SuggestTypes = IAutoSuggestUser | IAutoSuggestCountry | IAutoSuggestCommon | IAutoSuggestCountryCode;

@Component({
  selector: 'recall2-auto-suggest',
  templateUrl: './recall2-auto-suggest.component.html',
  styleUrls: ['./recall2-auto-suggest.component.scss'],
  standalone: true,
  imports: [Recall2AutoSuggestListComponent, OverlayModule, Recall2AutoSuggestInputComponent],
})
export class Recall2AutoSuggestComponent implements AfterViewInit, OnChanges {
  @ViewChild('autoSuggestResult') autoSuggestResult: Recall2AutoSuggestListComponent;
  @ViewChild('autoSuggestInput') autoSuggestInput: Recall2AutoSuggestInputComponent;
  @ViewChild(CdkConnectedOverlay) attachedOverlay: CdkConnectedOverlay;
  @Input() set entries(newEntries: SuggestTypes[]) {
    if (!newEntries) {
      return;
    }
    this.removeSelectedEntries(newEntries);
  }
  get entries(): SuggestTypes[] {
    return this._entries;
  }

  @Input() dataToSkip: SuggestTypes[] = [];
  @Input() objectType: EAutoSuggestObjectType;
  @Input() configuration: IAutoSuggestConfiguration = this.getDefaultAutoSuggestConfiguration();
  @Input() isDisabled = false;
  @Input() isFormSubmitted: boolean;
  @Input() defaultValue;
  @Input() isSpinnerShowing = false;

  @Output() selectedEntry = new EventEmitter<SuggestTypes | any>();
  @Output() updateSearchTerm = new EventEmitter<string>();

  isArrowDown = true;
  searchTerm = '';
  overlayWidth = 100;

  private _selectedEntry: SuggestTypes | null = null;
  private _entries: any[];

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private modalService: MatDialog,
    private translateService: TranslateService,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.overlayWidth = this.elementRef.nativeElement.offsetWidth;
    });
  }

  ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.defaultValue?.currentValue) {
      this.updateControlValue(simpleChanges.defaultValue.currentValue);
    }
  }

  onChangeSearchTerm(searchTerm: string): void {
    this._selectedEntry = null;
    this.searchTerm = searchTerm;
    this.updateSearchTerm.emit(searchTerm);
  }

  onDirectorySearch(): void {
    this.closeModal();
    this.openDirectorySearchModal();
  }

  selectEntry(entry: SuggestTypes, toggleArrowIcon = true, skipEmitEvent = false): void {
    this.searchTerm = '';
    this._selectedEntry = entry;
    this.updateControlValue(entry);
    if (toggleArrowIcon) {
      this.autoSuggestInput.toggleArrowIcon();
    }
    if (!skipEmitEvent && !this.autoSuggestInput.property.control.errors) {
      this.selectedEntry.emit(entry);
    }
  }

  onArrowDown(isArrowDown: boolean): void {
    this.isArrowDown = isArrowDown;
    this.validateInputOnArrowDown();
  }

  onKeyDown(event?: KeyboardEvent, forceEmitKeyDown = false): void {
    if (!this.autoSuggestResult || this.isArrowDown) {
      return;
    }
    if ((event && event.code === 'ArrowDown') || forceEmitKeyDown) {
      this.preventDefaultEvent(event);
      this.cdr.detectChanges();
      this.dispatchKeyEvent(new KeyboardEvent('keydown', { bubbles: false }));
    }
  }

  setFocus(): void {
    this.autoSuggestInput.setInputTextFocus();
  }

  closeModal(): void {
    this.attachedOverlay.overlayRef.detach();
    this.autoSuggestInput.toggleArrowIcon();
  }

  private validateInputOnArrowDown(): void {
    if (
      this.isArrowDown &&
      !this._selectedEntry &&
      !this.configuration.property.control.value &&
      this.configuration.property.required
    ) {
      this.autoSuggestInput.property.control.setErrors({
        invalidValue: true,
      });
    }
  }
  private updateControlValue(entry: SuggestTypes): void {
    const value = this.getControlValueByObjectType(entry);
    this.configuration.property.control.setValue(value);
  }

  private preventDefaultEvent(event?: KeyboardEvent): void {
    if (event) {
      event.preventDefault();
    }
  }

  private dispatchKeyEvent(event: KeyboardEvent): void {
    this.autoSuggestResult.autoSuggestList.nativeElement.dispatchEvent(event);
  }

  private openDirectorySearchModal(): void {
    const dialogRef: MatDialogRef<Recall2DirectorySearchComponent> = this.modalService.open(
      Recall2DirectorySearchComponent,
      {
        data: this.configuration.property.control.value,
        maxHeight: '95vh',
        panelClass: 'directory-panel',
        backdropClass: 'modal-backdrop',
      },
    );

    dialogRef.afterClosed().subscribe((result: { action: DirectorySearchActions; data: IAutoSuggestUser }) => {
      if (result?.action === 'select') {
        this.selectEntry(result.data, false);
      }
    });
  }

  private getControlValueByObjectType(entry: SuggestTypes): string {
    switch (this.objectType) {
      case EAutoSuggestObjectType.USER: {
        const user = entry as IAutoSuggestUser;
        return `${user.vwUserId}, ${user.lastName}, ${user.firstName}`;
      }
      case EAutoSuggestObjectType.COUNTRY: {
        const country = entry as IAutoSuggestCountry;
        return `${country.code} - ${country.name}`;
      }
      case EAutoSuggestObjectType.COUNTRY_CODE: {
        return this.getFullCountryString(entry as IAutoSuggestCountryCode);
      }
      case EAutoSuggestObjectType.MANUFACTURER: {
        return (entry as AutoSuggestManufacturer).name;
      }
      case EAutoSuggestObjectType.DEPARTMENT: {
        return (entry as IAutoSuggestCommon).id;
      }
      default:
        return '';
    }
  }

  private getFullCountryString(entry: IAutoSuggestCountryCode): string {
    const translationKey = `general.country.code.${entry.code}`;
    return `${entry.code} - ${this.translateService.instant(translationKey)}`;
  }
  private removeSelectedEntries(entries: SuggestTypes[]): void {
    this._entries = this.dataToSkip.length === 0 ? entries : entries?.filter(entry => !this.dataToSkip.includes(entry));
    this.cdr.detectChanges();
  }

  private getDefaultAutoSuggestConfiguration(): IAutoSuggestConfiguration {
    return {
      property: new InputTextProperty(
        'autoSuggest',
        false,
        'autoSuggest',
        { maxLength: 50 },
        true,
        new FormControl('', []),
        true,
        null,
        null,
        false,
      ),
      searchDirectoryEnabled: false,
    };
  }
}
