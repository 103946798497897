import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { iconArrowDownSmall, SVGIconsRegistry } from '@recall2/icons';

import { TextSelectComponent, TextSelectOptionComponent } from '../../../text-select';

@Component({
  selector: 'app-table-paginator-pagesize-selector',
  templateUrl: './table-paginator-pagesize-selector.component.html',
  styleUrls: ['./table-paginator-pagesize-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TextSelectComponent, NgFor, TextSelectOptionComponent, TranslateModule],
})
export class TablePaginatorPagesizeSelectorComponent {
  @Input() pageSize = 10;
  @Output() elementsPerPage = new EventEmitter<number>();
  itemsPerPageList = [10, 20, 40, 60, 80, 100];

  constructor(iconsRegistry: SVGIconsRegistry) {
    iconsRegistry.registerIcons([iconArrowDownSmall]);
  }

  onChangeElementPerPage(itemsPerPage: number): void {
    this.elementsPerPage.emit(itemsPerPage);
  }
}
