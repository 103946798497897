import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2AccordionBaseComponent } from '../accordion-base/recall2-accordion-base.component';
import { AccordionType } from '../model/recall2-accordion-type';
import { Recall2AccordionSimpleBaseComponent } from './accordion-simple-base/recall2-accordion-simple-base.component';

@Component({
  selector: 'recall2-accordion-simple-h3',
  templateUrl: './accordion-simple-base/recall2-accordion-simple-base.component.html',
  styleUrls: ['./accordion-simple-base/recall2-accordion-simple-base.component.scss'],
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, TranslateModule, Recall2AccordionBaseComponent],
})
export class Recall2AccordionSimpleH3Component extends Recall2AccordionSimpleBaseComponent {
  accordionType: AccordionType = AccordionType.H3;
}
