import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'customDate',
  standalone: true,
})
export class CustomDatePipe implements PipeTransform {
  transform(dateTime: string | Date): string {
    if (!dateTime) {
      return '-';
    }

    const date = new Date(dateTime);
    date.setHours(0, 0, 0, 0);

    return dayjs(date).format('DD.MM.YYYY');
  }
}
