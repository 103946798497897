import { NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';

import { DueDateStatus } from '../../../../core/api/enums/due-date-status.enum';
import { type DueDate, Recall2DeadlineComponent } from '../../../../deadline';
import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';

export class DueDateCellBinding {
  constructor(
    public propertyName: string,
    public options?: {
      additionalCssClasses?: string[];
      hideByStatus?: {
        status: string;
        statusPropertyName: string;
        exceptionProperties?: {
          propertyName: string;
          value: string;
        }[];
      };

      forceNoStyleOnStatus?: {
        status: string;
        statusPropertyName: string;
      };
    },
  ) {}
}

@Component({
  selector: 'due-date-cell',
  templateUrl: './due-date-cell.component.html',
  standalone: true,
  imports: [Recall2DeadlineComponent, NgIf],
})
export class DueDateCellComponent extends AbstractGenericCellViewWithData implements OnInit {
  propertyName: string;
  options?: {
    additionalCssClasses?: string[];
    hideByStatus?: {
      status: string;
      statusPropertyName: string;
      exceptionProperties?: {
        propertyName: string;
        value: string;
      }[];
    };
    forceNoStyleOnStatus?: {
      status: string;
      statusPropertyName: string;
    };
  };

  dueDate: DueDate;

  ngOnInit(): void {
    this.dueDate = {
      ...this.data[this.propertyName],
    };

    if (this.options && this.options.forceNoStyleOnStatus) {
      const dataStatus = this.data[this.options.forceNoStyleOnStatus.statusPropertyName];

      if (dataStatus === this.options.forceNoStyleOnStatus.status) {
        this.dueDate.status = DueDateStatus.NONE;
      }
    }
  }

  get showDeadline(): boolean {
    if (!this.options || !this.options.hideByStatus || this.isHidingByStatusException()) {
      return true;
    }

    const dataStatus = this.data[this.options.hideByStatus.statusPropertyName];

    if (dataStatus === this.options.hideByStatus.status) {
      return false;
    }

    return true;
  }

  private isHidingByStatusException(): boolean {
    const { exceptionProperties } = this.options.hideByStatus;

    if (!exceptionProperties) {
      return false;
    }

    return exceptionProperties.every(property => this.data[property.propertyName] === property.value);
  }
}
