import { HttpClientModule } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2ButtonSecondaryComponent } from '../buttons';
import { Recall2IconUnselectComponent, Recall2IconWarningComponent } from '../icons';

const DEFAULT_ERROR_MESSAGE = 'errorhandling.generic-error-message';

@Component({
  selector: 'error-handling-modal',
  templateUrl: './error-handling-modal.component.html',
  styleUrls: ['./error-handling-modal.component.scss'],
  standalone: true,
  imports: [
    HttpClientModule,
    MatDialogModule,
    TranslateModule,
    Recall2ButtonSecondaryComponent,
    Recall2IconWarningComponent,
    Recall2IconUnselectComponent,
  ],
})
export class Recall2ErrorHandlingModalComponent {
  errorMessageKey = DEFAULT_ERROR_MESSAGE;
  dynamicValues = {};

  constructor(
    public dialogRef: MatDialogRef<Recall2ErrorHandlingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (dialogRef && data && data.errorMessageKey) {
      this.errorMessageKey = data.errorMessageKey;
      this.mapDynamicValues(data);
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  private mapDynamicValues(data): void {
    if (!data.dynamicValues) {
      this.dynamicValues = null;
      return;
    }

    if (Array.isArray(data.dynamicValues)) {
      this.dynamicValues = data.dynamicValues.reduce((result, item) => {
        const key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
      }, {});
      return;
    }

    this.dynamicValues = data.dynamicValues;
  }
}
