export class App {
  static Campaigns = new App('campaigns', 'campaigns');
  static Committee = new App('committee', 'committee');
  static Consent = new App('consent', 'consent');
  static Preliminary = new App('preliminary', 'preliminary');
  static Topics = new App('topics', 'topic');
  static UserManagement = new App('user-management', 'user-management');
  static PartnerData = new App('partner-data', 'partner-data');
  static Coordination = new App('coordination', 'coordination');
  static ConsumerManagement = new App('consumer-management', 'consumer-management');

  private constructor(
    public readonly id: string,
    public readonly urlPrefix: string,
  ) {}
}
