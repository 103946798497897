import { NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractGenericCellViewWithData } from '@recall2/ui/dynamic-content';

import type { InvolvedUser } from '../../../model/involved-user.model';
import { getTranslationKeyForSystemUser } from '../../global/global-functions/global-functions.component';

@Component({
  selector: 'subject-area-cell',
  templateUrl: './subject-area-cell.component.html',
  standalone: true,
  imports: [NgIf, TranslateModule],
})
export class SubjectAreaCell extends AbstractGenericCellViewWithData<InvolvedUser> implements OnInit {
  public subjectArea: string;
  public role: string;

  ngOnInit(): void {
    if (this.data[this.role] !== 'EXPERT') {
      this.data[this.subjectArea] = this.data[this.role];
    }
  }

  getTranslationKey(role: string): string {
    return getTranslationKeyForSystemUser(role);
  }
}
