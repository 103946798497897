<div class="filter-date-wrapper">
  <recall2-buttons-toggle-group
    [isRangeSwitcherMode]="true"
    [selectedValue]="selectedFilterOption"
    (selectedToggleButton)="onFilterOptionChange($event)"
  >
    <recall2-toggle-element
      *ngIf="isPeriodOptionVisible"
      [value]="EDateFilterComparator.BETWEEN"
      [label]=" 'recall2-filter.date.comparator.between' | translate"
      data-cy="date-filter-option-period"
    ></recall2-toggle-element>

    <recall2-toggle-element
      [value]="EDateFilterComparator.NEWER"
      [label]=" 'recall2-filter.date.comparator.newerThan' | translate"
      data-cy="date-filter-option-newer-than"
    ></recall2-toggle-element>

    <recall2-toggle-element
      [value]="EDateFilterComparator.OLDER"
      [label]=" 'recall2-filter.date.comparator.olderThan' | translate"
      data-cy="date-filter-option-older-than"
    ></recall2-toggle-element>
  </recall2-buttons-toggle-group>

  <div class="dates-container">

    <recall2-filter-date-range
      *ngIf="selectedFilterOption === EDateFilterComparator.BETWEEN; else singleDatePicker"
      [fromDateProperty]="fromDateProperty"
      [toDateProperty]="toDateProperty"
      (dateRangeSelected)="updateSelectedFilterWithRange($event)"
    >
    </recall2-filter-date-range>

    <ng-template #singleDatePicker>
      <recall2-datepicker
        [property]="singleDateProperty"
        (dateChange)="updateSelectedFilter($event)"
      >
      </recall2-datepicker>
    </ng-template>

  </div>

</div>
