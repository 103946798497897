import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppTranslationService } from '@recall2/ui/i18n';
import { TabTitleService } from '@recall2/ui/tab-title';

import { TranslateModule } from '@ngx-translate/core';
import { Recall2ButtonPrimaryComponent, Recall2ButtonTertiaryComponent } from '@recall2/ui/buttons';
import { Recall2FloatingBarComponent } from '@recall2/ui/floating-bar';
import { NotificationService } from '../../../../rest-api/notification/notification.service';
import type { IAppState } from '../../../../store/app/app.state';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';
import { NotificationBaseComponent } from '../notification-base/notification-base.component';
import { NotificationConfirmComponent } from '../notification-confirm/notification-confirm.component';

@Component({
  selector: 'recall2-notification-send',
  templateUrl: './notification-send.component.html',
  standalone: true,
  imports: [
    NotificationConfirmComponent,
    Recall2FloatingBarComponent,
    Recall2ButtonPrimaryComponent,
    Recall2ButtonTertiaryComponent,
    TranslateModule,
  ],
})
export class NotificationSendComponent extends NotificationBaseComponent implements OnInit, OnDestroy {
  @ViewChild('confirmComponent') confirmComponent: NotificationConfirmComponent;

  constructor(
    modal: MatDialog,
    store: Store<IAppState>,
    notificationService: NotificationService,
    routingService: NotificationRoutingService,
    activatedRoute: ActivatedRoute,
    tabTitleService: TabTitleService,
    translationService: AppTranslationService,
  ) {
    super(modal, store, notificationService, routingService, activatedRoute, tabTitleService, translationService);
  }

  cancelSend(): void {
    this.confirmResetAction('notification.send.review', () =>
      this.routingService.navigateToSummary(this.notificationId),
    );
  }

  isSubmitOk(): boolean {
    return this.selectedUser !== undefined;
  }

  async sendNotificationToReview() {
    return super.performAction(
      () =>
        this.notificationService.sendToReviewer(
          this.notificationId,
          this.selectedUser,
          this.currentLocale,
          this.comment,
        ),
      _ => this.routingService.navigateToBaseRouteNotification(this.notificationId),
    );
  }
}
