<div class="rc2-border--bottom--grey--200-tertiary rc2-pad--bottom--m rc2-pad--left--l">
  <notification-summary
    data-testid="details-summary"
    [notification]="notificationDetails"
  >
  </notification-summary>
  <recall2-floating-bar
    data-testid="action-bar"
    *ngIf="showActions(notification) && notificationDetails && notificationDetails.id"
  >
    <div class="action-bar">
      <recall2-button-secondary
        *ngIf="notification.actionPermissions?.canSendForReviseReviewer"
        (click)="sendForReviseReviewer()"
        data-testid="revise-reviewer-button"
        class="action-bar__button"
      >
        <recall2-icon-warning [rc2IconStyles]="'rc2-icon--color--grey rc2-icon--size--22'"></recall2-icon-warning>
        {{ 'notifications.task.buttons.rework' | translate }}
      </recall2-button-secondary>

      <recall2-button-secondary
        *ngIf="notification.actionPermissions?.canForwardToReviewer"
        (click)="forwardToReviewer()"
        data-testid="forward-reviewer-button"
        class="action-bar__button"
      >
        <recall2-icon-forward [rc2IconStyles]="'rc2-icon--size--22 rc2-icon--color--grey'"></recall2-icon-forward>
        {{ 'notifications.task.buttons.forward' | translate }}
      </recall2-button-secondary>

      <recall2-button-primary
        *ngIf="notification.actionPermissions?.canApprove"
        [routerLink]="['/notifications', notification.id, 'approve']"
        data-testid="approve-button"
        [iconClass]="'icon icon-i29-accept rc2-icon--size--24'"
        class="action-bar__button"
      >
        {{ 'notifications.task.buttons.approve' | translate }}
      </recall2-button-primary>

      <recall2-button-secondary
        *ngIf="notification.actionPermissions?.canTransfer"
        [iconClass]="'icon icon-i30-forward rc2-icon--size--24'"
        data-testid="transfer-button"
        (click)="navigateToTransferNotification()"
        class="action-bar__button"
      >
        {{ 'notifications.task.buttons.transfer' | translate }}
      </recall2-button-secondary>

      <recall2-button-secondary
        *ngIf="notification.actionPermissions?.canSendForReviseManufacturer"
        (click)="sendForReviseManufacturer()"
        data-testid="revise-manufacturer-button"
        class="action-bar__button"
      >
        <recall2-icon-warning [rc2IconStyles]="'rc2-icon--color--grey rc2-icon--size--22'"></recall2-icon-warning>
        {{ 'notifications.task.buttons.rework' | translate }}
      </recall2-button-secondary>

      <recall2-button-primary
        *ngIf="notification.actionPermissions?.canAccept"
        (click)="onAcceptNotification()"
        data-testid="accept-button"
        [iconClass]="'icon icon-i29-accept rc2-icon--size--24'"
        [isAnimating]="isNotificationBeingAccepted"
        class="action-bar__button"
      >
        {{ 'notifications.task.buttons.accept' | translate }}
      </recall2-button-primary>

      <recall2-button-secondary
        *ngIf="notification.actionPermissions?.canForwardToManufacturer"
        (click)="forwardToManufacturer()"
        data-testid="forward-manufacturer-button"
        class="action-bar__button"
      >
        <recall2-icon-forward [rc2IconStyles]="'rc2-icon--size--22 rc2-icon--color--grey'"></recall2-icon-forward>
        {{ 'notifications.task.buttons.forward' | translate }}
      </recall2-button-secondary>

      <recall2-button-secondary
        *ngIf="notification.actionPermissions?.canLinkToTopic"
        (click)="linkTopic()"
        data-testid="link-topic-button"
        [iconClass]="'icon i-link rc2-icon--color--grey--300'"
        class="action-bar__button"
      >
        {{ 'notifications.task.buttons.linkTopic' | translate }}
      </recall2-button-secondary>

      <recall2-button-primary
        *ngIf="notification.actionPermissions?.canCreateAsTopic"
        (click)="createTopic()"
        data-testid="create-topic-button"
        [iconClass]="'icon i-folder-new rc2-icon--color--white'"
        class="action-bar__button"
      >
        {{ 'notifications.task.buttons.createTopic' | translate }}
      </recall2-button-primary>
    </div>
  </recall2-floating-bar>
</div>
