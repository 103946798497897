<div
  mat-raised-button
  aria-label="Button that displays a tooltip when focused or hovered over"
  data-cy="items-count-wrapper"
  class="rc2-overlay--header"
  customOverlay
  [overlayTemplate]="template"
  [position]="position"
  [overlayTriggerAction]="overlayTriggerAction"
  [overlayPositionWithPush]="false"
  (mouseleave)="onMouseLeftParent()"
>
  <div
    class="rc2-overlay--icon"
    data-cy="items-count-value"
  >{{itemsCount | markEmpty }}</div>
</div>

<ng-template
  #template
  id="overlay-template"
  class="rc2-bg--green"
>
  <div
    class="rc2-overlay--template"
    [ngClass]="{
      'rc2-overlay--hide':itemsCount <= 0 ,
      'rc2-overlay--template--linked-object': overlayTemplateType === overlayTemplateTypes.LINKEDOBJECT
    }"
    (mouseleave)="onMouseLeftTemplate()"
    (mouseenter)="onMouseEnteredTemplate()"
  >
    <recall2-loading-spinner
      *ngIf="isSpinnerShowing; else overlayContent"
      id="spinner"
      [forceShowing]="isSpinnerShowing"
      [spinnerDiameter]="20"
    >
    </recall2-loading-spinner>

    <ng-template #overlayContent>
      <ng-container [ngSwitch]="overlayTemplateType">
        <recall2-object-template
          *ngSwitchCase="overlayTemplateTypes.OBJECT"
          [targetApp]="targetApp"
          [urlPath]="urlPath"
          [itemsList]="itemsList"
          [iconType]="iconType"
        >
        </recall2-object-template>

        <recall2-attachment-template
          *ngSwitchCase="overlayTemplateTypes.ATTACHMENT"
          [downloadAllAttachments]="downloadAllAttachments"
          [attachmentList]="itemsList"
        >
        </recall2-attachment-template>

        <ng-content *ngSwitchDefault></ng-content>
      </ng-container>
    </ng-template>
  </div>
</ng-template>
