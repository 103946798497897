<div class="choosing-manufacturer">
  <div
    *ngFor="let entry of manufacturerEntries"
    class="manufacturer-card"
    matTooltipPosition="above"
    matTooltipClass="rc2-tooltip"
    [matTooltip]="(forwardedManufacturerIds.includes(entry.id)) ?
      ('brandManufacturerSelector.brand-disabled' | translate) : ''"
    [ngClass]="{
      'selectedEntry' : isSelected(entry.id),
      'selectedDefaultEntry' : isDefaultEntry(entry.id),
      'disabled-manufacturer': forwardedManufacturerIds.includes(entry.id)
    }"
    (click)="selectManufacturer(entry.id)"
  >
    <p class="manufacturer-entry">{{entry.name}}</p>
  </div>
</div>
