export enum NotificationOverviewTableColumns {
  Icon = 'icon',
  ID = 'id',
  Title = 'title',
  Reporter = 'reporter',
  Editor = 'activeEditor',
  ReceivedDate = 'receivedDate',
  DueTo = 'dueTo',
  Status = 'status',
  Link = 'linkCount',
  Attachment = 'attachmentCount',
  Edit = 'edit',
  Expand = 'expand',
}
