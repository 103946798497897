import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import type { Language } from '../../models/language.model';

export interface LanguagesStoreState {
  languages: Language[];
}

export const createInitialState = (): LanguagesStoreState => {
  return {
    languages: [],
  };
};

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'languages' })
export class LanguagesStore extends Store<LanguagesStoreState> {
  constructor() {
    super(createInitialState());
  }
}
