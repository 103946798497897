import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { IUser } from '../models/user';

const USERS_API_URL = '/api-usermgmt-user/users';

@Injectable({ providedIn: 'root' })
export class LanguageSelectorService {
  constructor(private _http: HttpClient) {}

  getUser$(): Observable<IUser> {
    return this._http.get<IUser>(`${USERS_API_URL}/me`);
  }

  setUser$(id: number, user: IUser): Observable<void> {
    return this._http.put<void>(`${USERS_API_URL}/${id}`, user);
  }
}
