import { NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import { AppsLinkDirective } from '../../../navigation/components/apps-link/apps-link.directive';
import type { App } from '../../../navigation/models/app';
import { AbstractGenericCellViewWithData } from '../../../dynamic-content/models/dynamic-content.model';
import { EThreeStepCheckboxStates } from '../../../form/model';
import { Recall2IconLinkComponent } from '../../../icons/';

@Component({
  selector: 'link-overview-cell',
  templateUrl: './link-overview.cell.html',
  standalone: true,
  imports: [NgIf, Recall2IconLinkComponent, AppsLinkDirective],
})
export class LinkOverviewCellComponent extends AbstractGenericCellViewWithData implements ILinkOverviewBinding, OnInit {
  public propertyName: string;
  public targetApp: App;
  public urlPath: string;
  public currentClearingId: number;
  public currentManufacturerId: number;
  public propertyState = EThreeStepCheckboxStates.SELECTED;

  public beforeRedirectFn = (): Observable<any> => of(null);

  ngOnInit(): void {
    this.currentManufacturerId = this.data.manufacturer.id;
  }
}

export class LinkOverviewCellBinding implements ILinkOverviewBinding {
  constructor(
    public propertyName: string,
    public targetApp: App,
    public urlPath: string,
    public currentClearingId: number,
    public beforeRedirectFn?: Function,
  ) {}
}

interface ILinkOverviewBinding {
  propertyName: string;
  targetApp: App;
  urlPath: string;
  currentClearingId: number;
  beforeRedirectFn?: Function;
}
