import type { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import type { ThreeStepCheckboxProperty } from '../../model';
import { EThreeStepCheckboxStates } from '../../model';

export class ThreeStepCheckboxController {
  private master: ThreeStepCheckboxProperty;
  private slavesSource: Observable<ThreeStepCheckboxProperty[]>;
  private slaves: ThreeStepCheckboxProperty[];

  private masterSubscription: Subscription;
  private slaveSubscriptions: Subscription;

  private readonly suppressChangeEvent = { emitEvent: false };

  constructor(masterProp: ThreeStepCheckboxProperty, slaveProps: Observable<ThreeStepCheckboxProperty[]>) {
    this.master = masterProp;
    this.slavesSource = slaveProps;
    this.masterSubscription = new Subscription();
    this.slaveSubscriptions = new Subscription();
    this.init();
  }

  private init(): void {
    this.masterSubscription.add(
      this.master.control.valueChanges.subscribe(newValue => {
        this.updateChildren(newValue);
      }),
    );

    this.masterSubscription.add(
      this.slavesSource.subscribe(newSlaves => {
        this.resetSlaveSubscriptions();
        this.slaves = newSlaves;
        this.master.control.setValue(this.evaluateChildren(), this.suppressChangeEvent);
        newSlaves.forEach((slave: ThreeStepCheckboxProperty) => {
          this.slaveSubscriptions.add(
            slave.control.valueChanges.subscribe(() => {
              this.master.control.setValue(this.evaluateChildren(), this.suppressChangeEvent);
            }),
          );
        });
      }),
    );
  }

  private resetSlaveSubscriptions(): void {
    this.slaveSubscriptions.unsubscribe();
    this.slaveSubscriptions = new Subscription();
  }

  private updateChildren(newValue: EThreeStepCheckboxStates): void {
    this.slaves.forEach(slave => {
      slave.control.setValue(newValue, this.suppressChangeEvent);
    });
  }

  private evaluateChildren(): EThreeStepCheckboxStates {
    if (!this.slaves || this.slaves.length === 0) {
      return EThreeStepCheckboxStates.UNSELECTED;
    } else {
      const refState = this.slaves[0].control.value;
      const allEqual = this.slaves.every(slave => slave.control.value === refState);
      return allEqual ? refState : EThreeStepCheckboxStates.MIXED;
    }
  }

  public cleanUp(): void {
    this.masterSubscription.unsubscribe();
    this.slaveSubscriptions.unsubscribe();
  }
}
