import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import type { IUser } from '@recall2/user';
import { UserService } from '@recall2/user';
import type { Observable } from 'rxjs';

import { pathParamNotificationId } from '../../notification/routing/notification-routes.constants';
import type { InvolvedUserPreliminary } from '../involved-user/involved-user.model';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserServiceImpl extends UserService {
  constructor(
    private _http: HttpClient,
    private router: Router,
  ) {
    super(['notifications']);
  }

  createUser$(user: InvolvedUserPreliminary): Observable<never> {
    const notificationId = this.extractNotificationIdFromPath();
    return this._http.post<never>(`${environment.apiUrlUser}/users/${notificationId}`, user);
  }

  updateUser$(_vwUser: IUser): Observable<never> {
    throw new Error('not implemented');
  }

  createUserEditor$(_user: InvolvedUserPreliminary): Observable<never> {
    throw new Error('not implemented');
  }

  private extractNotificationIdFromPath(): number {
    const notificationId = +this.router.routerState.snapshot.root.firstChild.paramMap.get(pathParamNotificationId);
    return notificationId ? notificationId : null;
  }
}
