import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component } from '@angular/core';

import {
  Recall2IconObjektClearingComponent,
  Recall2IconUrgencyFourComponent,
  Recall2IconUrgencyOneComponent,
  Recall2IconUrgencyThreeComponent,
  Recall2IconUrgencyTwoComponent,
} from '../../../../icons';
import type { IRecall2FilterSelectableValue } from '../../../../overlay/models/filter.model';
import { Recall2FilterSelectableValue, Recall2FilterSelectParam } from '../../../../overlay/models/filter.model';
import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';
import { ETableHeader } from '../../../models/link-table-header.model';
import { EUrgencyLevel } from '../../../models/urgency.model';

@Component({
  selector: 'urgency-clearing-cell',
  templateUrl: './urgency-clearing-cell.component.html',
  standalone: true,
  imports: [
    Recall2IconUrgencyOneComponent,
    Recall2IconUrgencyTwoComponent,
    Recall2IconUrgencyThreeComponent,
    Recall2IconUrgencyFourComponent,
    Recall2IconObjektClearingComponent,
    NgSwitch,
    NgSwitchCase,
  ],
})
export class UrgencyClearingCellComponent extends AbstractGenericCellViewWithData {
  propertyName: string;
  level = EUrgencyLevel;
}

const i18nUrgency = 'link.dialog.table.column.urgency';

const urgencyLevelClearingSelectableValues: IRecall2FilterSelectableValue[] = [
  new Recall2FilterSelectableValue(
    'icon-i72-objekt-clearing rc2-color--danger rc2-icon--size--20 rc2-pad--right--xs',
    'DEFAULT',
    i18nUrgency + '.select.default',
  ),
  new Recall2FilterSelectableValue(
    'icon-i85-urgency-1 rc2-color--danger rc2-icon--size--20 rc2-pad--right--xs',
    'LEVEL1',
    i18nUrgency + '.select.level1',
  ),
  new Recall2FilterSelectableValue(
    'icon-i86-urgency-2 rc2-color--attention rc2-icon--size--20 rc2-pad--right--xs',
    'LEVEL2',
    i18nUrgency + '.select.level2',
  ),
  new Recall2FilterSelectableValue(
    'icon-i87-urgency-3 rc2-color--grey-900 rc2-icon--size--20 rc2-pad--right--xs',
    'LEVEL3',
    i18nUrgency + '.select.level3',
  ),
  new Recall2FilterSelectableValue(
    'icon-i88-urgency-4 rc2-color--grey-400-secondary rc2-icon--size--20 rc2-pad--right--xs',
    'LEVEL4',
    i18nUrgency + '.select.level4',
  ),
];

export const urgencyFilterClearing = new Recall2FilterSelectParam(
  ETableHeader.URGENCY_LEVEL,
  i18nUrgency,
  urgencyLevelClearingSelectableValues,
);
