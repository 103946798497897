import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { EXTERNAL_URL } from './external-url';
import { ExternalUrlGuard } from './external-url.guard';

const routes: Routes = [
  {
    path: EXTERNAL_URL,
    canActivate: [ExternalUrlGuard],
    // Dummy component, it's never rendered
    component: class {},
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExternalRoutingModule {}
