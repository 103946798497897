import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { BrandManufacturer } from '@recall2/ui/brand-manufacturer';
import type { ListResponse } from '@recall2/ui/utils';
import { mapListResponse } from '@recall2/ui/utils';
import dayjs from 'dayjs';
import type { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private _brandsBaseApiUrl = '/manufacturers';

  constructor(private _http: HttpClient) {}

  getBrandManufacturerForList(notificationDate?: Date): Observable<BrandManufacturer[]> {
    const url = `${environment.apiUrlBrand}${this._brandsBaseApiUrl}`;
    let params: HttpParams = new HttpParams();
    if (notificationDate) {
      params = params.append('date', dayjs(notificationDate).format('YYYY-MM-DD'));
    }
    return this._http.get<ListResponse<BrandManufacturer>>(url, { params }).pipe(mapListResponse());
  }
}
