import { NgIf } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { AttachmentFile } from '@recall2/ui/attachment';
import { AttachmentService, FileListComponent } from '@recall2/ui/attachment';
import { Recall2CommentsAndHistoryComponent } from '@recall2/ui/comments-history';
import { EObjectType } from '@recall2/ui/dynamic-content';
import { Recall2AccordionBaseComponent, Recall2AccordionSimpleH3Component } from '@recall2/ui/layout/accordion';
import type { ReCall2AccordionProperties } from '@recall2/ui/layout/accordion/model/recall2-accordion.properties';
import { Recall2LoadingSpinnerComponent } from '@recall2/ui/loading-spinner';
import { Recall2InvolvedUsersTableComponent } from '@recall2/user';
import { filter } from 'rxjs/operators';

import { INotification } from '../../models/notification/notification';
import { NotificationSummaryHeaderComponent } from './sub-components/notification-summary-header/notification-summary-header.component';
import { NotificationSummaryMeasurementsComponent } from './sub-components/notification-summary-measurements/notification-summary-measurements.component';
import { NotificationSummaryVehiclesComponent } from './sub-components/notification-summary-vehicles/notification-summary-vehicles.component';

@Component({
  selector: 'notification-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  standalone: true,
  imports: [
    Recall2LoadingSpinnerComponent,
    Recall2CommentsAndHistoryComponent,
    Recall2AccordionBaseComponent,
    NotificationSummaryMeasurementsComponent,
    NotificationSummaryVehiclesComponent,
    NotificationSummaryHeaderComponent,
    NgIf,
    NotificationSummaryVehiclesComponent,
    Recall2AccordionSimpleH3Component,
    FileListComponent,
    Recall2InvolvedUsersTableComponent,
  ],
})
export class SummaryComponent implements OnInit, OnChanges {
  readonly objectType = EObjectType.NOTIFICATION;
  @Input() notification: INotification;

  readonly headerSectionAccordionProperties: ReCall2AccordionProperties = {
    name: 'notification-summary-description-accordion',
    translationKey: 'notifications.form.category.header',
    isExpanded: true,
  };

  readonly measurementsSectionAccordionProperties: ReCall2AccordionProperties = {
    name: 'notification-summary-measurements-accordion',
    translationKey: 'notifications.form.category.measure',
    isExpanded: true,
  };

  readonly vehiclesSectionAccordionProperties: ReCall2AccordionProperties = {
    name: 'notification-summary-vehicles-accordion',
    translationKey: 'notifications.form.category.vehicles',
    isExpanded: true,
  };

  readonly personsSectionAccordionProperties: ReCall2AccordionProperties = {
    name: 'notification-summary-persons-accordion',
    translationKey: 'notifications.form.category.persons',
    isExpanded: true,
  };

  readonly attachmentsSectionAccordionProperties: ReCall2AccordionProperties = {
    name: 'notification-summary-attachments-accordion',
    translationKey: 'notifications.form.category.attachments',
    isExpanded: true,
  };

  readonly commentsSectionAccordionProperties: ReCall2AccordionProperties = {
    name: 'notification-summary-comments-accordion',
    translationKey: 'notifications.form.category.comments',
    isExpanded: true,
  };

  files: AttachmentFile[] = [];
  isAttachmentsInitialised = false;

  constructor(public attachmentService: AttachmentService) {}

  ngOnInit(): void {
    this.attachmentService.files$
      .pipe(filter(() => this.notification?.id === this.attachmentService.objectId))
      .subscribe(files => (this.files = files));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.isAttachmentsInitialised && changes.notification.currentValue?.id) {
      this.attachmentService.selectObject(changes.notification.currentValue.id);
      this.isAttachmentsInitialised = true;
    }
  }
}
