export enum FeaturePhase {
  Shared = 'shared',
  Campaigns = 'campaigns',
  Coordination = 'coordination',
  PartnerData = 'partner-data',
  ConsumerManagement = 'consumer-management',
  Preliminary = 'preliminary',
  Topic = 'topic',
  Committee = 'committee',
  UserManagement = 'user-management',
  Consent = 'consent',
}
