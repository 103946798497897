<ng-container [ngSwitch]="eventType">
  <recall2-icon-double-arrows
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'CHANGE_STATUS'"
  >
  </recall2-icon-double-arrows>

  <ng-container
    *ngSwitchCase="'FORWARD_TO_MANUFACTURER'"
    [ngTemplateOutlet]="forwardIcon"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'FORWARD_TO_USER'"
    [ngTemplateOutlet]="forwardIcon"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'TRANSFERRED_TO_MANUFACTURER'"
    [ngTemplateOutlet]="forwardIcon"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'CLONE_FROM_MANUFACTURER'"
    [ngTemplateOutlet]="forwardIcon"
  >
  </ng-container>
  <ng-container
    *ngSwitchCase="'CLONE_TO_MANUFACTURER'"
    [ngTemplateOutlet]="forwardIcon"
  >
  </ng-container>
  <recall2-icon-mail-summary
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'SEND'"
  >
  </recall2-icon-mail-summary>
  <recall2-icon-return
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'RETURN'"
  >
  </recall2-icon-return>
  <recall2-icon-link
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'LINK'"
  >
  </recall2-icon-link>
  <recall2-icon-unlink
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'UNLINK'"
  >
  </recall2-icon-unlink>
  <recall2-icon-warning
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_LABEL'"
  >
  </recall2-icon-warning>
  <recall2-icon-info
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_COMMENT'"
  >
  </recall2-icon-info>
  <recall2-icon-edit
    [rc2IconStyles]="'rc2-icon--size--20'"
    *ngSwitchCase="'CHANGE_EDITOR'"
  >
  </recall2-icon-edit>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_USER'"
  >
  </recall2-icon-add>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'REMOVE_USER'"
  >
  </recall2-icon-part-select>
  <recall2-icon-mail-summary
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'SEND_UPDATE_INVITE'"
  >
  </recall2-icon-mail-summary>
  <recall2-icon-edit
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'CHANGE_LEVEL'"
  >
  </recall2-icon-edit>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_COMMITTEE_MEMBER'"
  >
  </recall2-icon-add>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_COMMITTEE_MEMBER_VIEWER'"
  >
  </recall2-icon-add>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_BRAND_MANUFACTURER_TO_COMMITTEE'"
  >
  </recall2-icon-add>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'REMOVE_COMMITTEE_MEMBER'"
  >
  </recall2-icon-part-select>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'REMOVE_COMMITTEE_MEMBER_VIEWER'"
  >
  </recall2-icon-part-select>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'REMOVE_BRAND_MANUFACTURER_FROM_COMMITTEE'"
  >
  </recall2-icon-part-select>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_ENTITLED_TO_VOTE'"
  >
  </recall2-icon-add>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'REMOVE_ENTITLED_TO_VOTE'"
  >
  </recall2-icon-part-select>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_COMMITTEE_TRANSCRIBER'"
  >
  </recall2-icon-add>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'REMOVE_COMMITTEE_TRANSCRIBER'"
  >
  </recall2-icon-part-select>
  <recall2-icon-move
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'MOVE_MEETING'"
  >
  </recall2-icon-move>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_PERSON'"
  >
  </recall2-icon-add>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'DELETE_PERSON'"
  >
  </recall2-icon-part-select>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'SELECTED_PERSON'"
  >
  </recall2-icon-add>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'UNSELECTED_PERSON'"
  >
  </recall2-icon-part-select>
  <recall2-icon-add
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'ADD_PERSON_FROM_TOPIC'"
  >
  </recall2-icon-add>
  <recall2-icon-part-select
    [rc2IconStyles]="'rc2-icon--size--24'"
    *ngSwitchCase="'DELETE_PERSON_FROM_TOPIC'"
  >
  </recall2-icon-part-select>
</ng-container>

<ng-template #forwardIcon>
  <recall2-icon-forward [rc2IconStyles]="'rc2-icon--size--24'"></recall2-icon-forward>
</ng-template>
