<div class="slider-page">
  <ng-content select="[outer-page]"></ng-content>
  <div
    *ngIf="show"
    class="slider-content"
    [ngClass]="isOpen ? 'slide-in' : 'slide-out'"
  >
    <div
      class="slider-content-container"
      *ngIf="isOpen"
    >
      <div class="heading-container">
        <h4 class="heading">{{ heading }}</h4>
        <recall2-icon-arrow-right
          class="rc2-font--size--20 rc2-icon--cursor--pointer right"
          (click)="close()"
        >
        </recall2-icon-arrow-right>
      </div>
      <ng-content select="[slider-content]"></ng-content>
    </div>
  </div>
</div>
