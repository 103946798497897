<recall2-upload-area
  (newFiles)="onNewFiles($event)"
  [loading]="loading"
>
</recall2-upload-area>
<recall2-error-message-list [messages]="messages"></recall2-error-message-list>
<recall2-file-list
  [files]="validFiles"
  (download)="onDownload($event)"
  (downloadAll)="onDownloadAll()"
  (delete)="onDelete($event)"
>
</recall2-file-list>
