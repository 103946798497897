export enum ENavbarItem {
  TOPIC,
  CLEARING,
  VERIFICATION_TASK,
  COMMITTEE,
  AGENDA_ITEM,
  MEETING,
  CAMPAIGN,
  USER_MANAGEMENT,
  PARTNER_DATA,
  COORDINATION,
  COORDINATION_OVERVIEW,
  COORDINATION_IMPORT_VEHICLES,
  PRELIMINARY,
  USER_MANAGEMENT_SUB_ADMIN,
  USER_MANAGEMENT_SUB_ADMIN_ROOT,
  PENDING_COMPLETION_CODES,
  CONSUMER_MANAGEMENT,
  ADMIN_PANEL,
}
