import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import type { FormControl, ValidationErrors } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { iconClearMedium, iconWarningMedium, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';

import { Recall2IconHelpComponent } from '../../../icons/recall2-icon-help/recall2-icon-help.component';
import { Recall2IconInvalidComponent } from '../../../icons/recall2-icon-invalid/recall2-icon-invalid.component';
import { Recall2IconRequiredComponent } from '../../../icons/recall2-icon-required/recall2-icon-required.component';
import { Recall2AllowedCharactersDirective } from '../../directives/recall2-allowed-characters';
import { Recall2UpperCaseDirective } from '../../directives/recall2-uppercase';
import type { InputNumberProperty, InputTextProperty } from '../../model';

@Component({
  selector: 'recall2-input-text',
  standalone: true,
  templateUrl: './recall2-input-text.component.html',
  styleUrls: ['./recall2-input-text.component.scss'],
  host: {
    class: 'recall2-input-text',
  },
  imports: [
    NgIf,
    NgClass,
    ReactiveFormsModule,
    MatTooltipModule,
    MatFormFieldModule,
    TranslateModule,
    Recall2IconHelpComponent,
    Recall2IconRequiredComponent,
    Recall2IconInvalidComponent,
    Recall2AllowedCharactersDirective,
    Recall2UpperCaseDirective,
    SVGIconModule,
  ],
})
export class Recall2InputTextComponent {
  @Input() property: InputNumberProperty | InputTextProperty;
  @Input() isFormSubmitted: boolean;
  @Input() isUpperCase = false;
  @Input() isDisabled = false;
  @Input() isClearable = false;
  @Input() tooltipClass = 'rc2-tooltip';
  @Output() onChange = new EventEmitter<FormControl>();
  @Output() onKeyUp = new EventEmitter<KeyboardEvent>();
  @Output() onKeyDown = new EventEmitter<KeyboardEvent>();
  @Output() onKeyPress = new EventEmitter<KeyboardEvent>();
  @Output() onFocus = new EventEmitter<FocusEvent>();
  @Output() onFocusOut = new EventEmitter<FocusEvent>();
  @Output() onBlur = new EventEmitter<FocusEvent>();
  @Output() onTextChange = new EventEmitter<FormControl>();

  @ViewChild('inputText') inputText: ElementRef;

  constructor(protected iconsRegistry: SVGIconsRegistry) {
    iconsRegistry.registerIcons([iconWarningMedium, iconClearMedium]);
  }

  get errors(): ValidationErrors {
    return this.property.control.errors;
  }

  get showErrors(): boolean {
    if (typeof this.isFormSubmitted === 'undefined') {
      return this.property.control.invalid && (this.property.control.dirty || this.property.control.touched);
    }

    return this.isFormSubmitted && !!this.errors;
  }

  get firstErrorType(): string {
    return this.showErrors ? Object.keys(this.errors)[0] : undefined;
  }

  change(): void {
    this.onChange.emit(this.property.control);
  }

  keyup(event: KeyboardEvent): void {
    this.onKeyUp.emit(event);
  }

  keydown(event: KeyboardEvent): void {
    this.onKeyDown.emit(event);
  }

  keypress(event: KeyboardEvent): void {
    this.onKeyPress.emit(event);
  }

  focus(event: FocusEvent): void {
    this.onFocus.emit(event);
  }

  focusout(event: FocusEvent): void {
    this.onFocusOut.emit(event);
  }

  blur(event: FocusEvent): void {
    this.onBlur.emit(event);
  }

  setFocus(): void {
    this.inputText.nativeElement.focus();
  }

  onDelete(event: MouseEvent): void {
    event.stopPropagation();
    this.property.control.setValue('');
  }
}
