import type { ActionReducerMap } from '@ngrx/store';
import { errorReducer } from '@recall2/ui/errorhandling';

import { notificationReducer } from '../../notification/store/notification.reducer';
import { userReducer } from '../../user/store/user.reducer';
import type { IAppState } from './app.state';

export const appReducer: ActionReducerMap<IAppState> = {
  user: userReducer,
  notification: notificationReducer,
  error: errorReducer,
};
