<div class="rc2-overview-wrapper pad-bottom-erase">
  <recall2-notification-confirm
    #confirmComponent
    title="notification.discard"
    message="notification.discard.caption"
    [currentObjectId]="notificationId"
    [commentRequired]="true"
    [isFormSubmitted]="showErrors"
    [showSelectUser]="false"
    (onCommentAdded)="setComment($event)"
  ></recall2-notification-confirm>
  <recall2-floating-bar>
    <div class="action-bar">
      <recall2-button-tertiary
        data-testid="notification-discard-button-cancel"
        class="action-bar__button"
        iconClass="icon-i90-unselect"
        (click)="cancelDiscard()"
      >
        {{'notifications.discard.button.cancel' | translate}}
      </recall2-button-tertiary>
      <recall2-button-primary
        data-testid="notification-discard-button"
        class="action-bar__button"
        [forceAnimation]="isSending"
        iconClass="icon-i62-incorrect"
        (click)="discardNotification()"
      >
        {{'notifications.discard.button' | translate}}
      </recall2-button-primary>
    </div>
  </recall2-floating-bar>
</div>
