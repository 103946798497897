import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';

import type { ValidationStatus } from '../../enums/validation-status.enum';
import type { StepIndex } from '../../models/step-index.model';
import type { ValidityChangeEvent } from '../../models/validity-change-event.model';

@Injectable({ providedIn: 'root' })
export class ReCall2NavigationStepperValidationService {
  private subject = new Subject<ValidityChangeEvent>();

  get validationStatusChange$(): Observable<ValidityChangeEvent> {
    return this.subject.asObservable();
  }

  /**
   * Sets validity of step. If validity was set before, it will be overwritten.
   *
   * @param index - Index of the step. Beginning with 0
   * @param validationStatus - Validity of the step. For options see StepValidity enum
   */
  setValidityOfStep(index: StepIndex, validationStatus: ValidationStatus): void {
    this.subject.next({ index, validationStatus });
  }
}
