import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';

import { apiCampaignUrl, apiCommitteeMeetingUrl, apiNotificationsUrl, apiTopicsUrl } from '../../core/api/config';
import type { Page } from '../../core/api/models';
import { EObjectType } from '../../dynamic-content/models/object-type.model';
import { App } from '../../navigation/models/app';
import { AppsService } from '../../navigation/services/apps.service';
import type { LinkedObject } from '../../overlay/models/linked-object.model';

@Injectable({
  providedIn: 'root',
})
export class OverlayLinkedObjectService {
  constructor(
    private appsService: AppsService,
    private http: HttpClient,
  ) {}

  navigateToAgendaItemsOverviewFromTable(agendaItemId: string): Promise<boolean> {
    return this.appsService.navigate({
      app: App.Committee,
      path: '/agenda-items',
      queryParams: {
        highlight: agendaItemId,
        expand: agendaItemId,
        scroll: agendaItemId,
      },
      openNewTab: true,
    });
  }

  getAgendaItemLinkedToCampaign(campaignId: string): Observable<Page<LinkedObject>> {
    return this.http.get<Page<LinkedObject>>(`${apiCommitteeMeetingUrl}/agenda-item/campaigns/${campaignId}/linked`);
  }

  getTopicsLinkedToNotification(notificationId: number): Observable<LinkedObject[]> {
    return this.http.get<LinkedObject[]>(`${apiNotificationsUrl}/notifications/${notificationId}/linked`);
  }

  getObjectsLinkedToTopic(topicId: number): Observable<LinkedObject[]> {
    return this.http.get<LinkedObject[]>(`${apiTopicsUrl}/topics/${topicId}/linked-objects`);
  }

  getObjectsLinkedToClearing(clearingId: number): Observable<LinkedObject[]> {
    return this.http.get<LinkedObject[]>(`${apiTopicsUrl}/clearings/${clearingId}/linked-objects`);
  }

  getObjectLinkedToClearingTasks(clearingId: number): Observable<LinkedObject> {
    return this.http.get<LinkedObject>(`${apiTopicsUrl}/clearings/${clearingId}`).pipe(
      map(object => {
        return {
          id: object.id,
          code: object.code,
          type: EObjectType.CLEARING,
          title: object.title,
          accessible: object.accessible,
          targetApp: App.Topics,
          urgencyLevelobject: object.urgencyLevel,
        };
      }),
    );
  }

  getObjectsLinkedToAgendaItem(agendaItemId: number): Observable<LinkedObject[]> {
    return this.http.get<LinkedObject[]>(`${apiCommitteeMeetingUrl}/agenda-item/${agendaItemId}/linked`);
  }

  getObjectLinkedToTopicTasks(topicId: number): Observable<LinkedObject> {
    return this.http.get<LinkedObject>(`${apiTopicsUrl}/topics/${topicId}/badge-summary`).pipe(
      map(object => {
        return {
          id: object.id,
          type: EObjectType.TOPIC,
          title: object.title,
          targetApp: App.Topics,
          urgencyLevelobject: object.urgencyLevel,
        };
      }),
    );
  }

  getObjectLinkedToAgendaItemTasks(agendaItemId: number): Observable<LinkedObject> {
    return this.http.get<LinkedObject>(`${apiCommitteeMeetingUrl}/agenda-item/${agendaItemId}/badge-summary`).pipe(
      map(object => {
        return {
          id: object.id,
          type: EObjectType.AGENDA_ITEM,
          title: object.title,
          targetApp: App.Committee,
        };
      }),
    );
  }

  getObjectLinkedToCampaignTasks(campaignId: number): Observable<LinkedObject> {
    return this.http.get<LinkedObject>(`${apiCampaignUrl}/campaigns/${campaignId}/badge-summary`).pipe(
      map(object => {
        return {
          id: object.id,
          code: object.code,
          type: EObjectType.CAMPAIGN,
          title: object.title,
          targetApp: App.Campaigns,
          urgencyLevelobject: object.urgencyLevel,
          accessLevel: object.accessLevel,
        };
      }),
    );
  }
}
