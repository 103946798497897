import { NgClass, NgFor } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import type { Subscription } from 'rxjs';

import { ValidationStatus } from './enums/validation-status.enum';
import type { Step } from './models/step.model';
import { ReCall2NavigationStepperValidationService } from './services/stepper-validation/navigation-stepper-validation.service';

@Component({
  selector: 'recall2-navigation-stepper',
  templateUrl: './navigation-stepper.component.html',
  styleUrls: ['./navigation-stepper.component.scss'],
  standalone: true,
  imports: [RouterModule, TranslateModule, NgFor, NgClass],
})
export class Recall2NavigationStepperComponent implements OnInit, OnDestroy {
  @Input() set steps(value: Step[]) {
    this._steps = this.addSummaryValidity(value);
    this.initStepsId();
  }

  _steps: Step[];
  validationStatus = ValidationStatus;
  validationStatusChange$: Subscription;

  constructor(public validityService: ReCall2NavigationStepperValidationService) {}

  ngOnInit(): void {
    this.validationStatusChange$ = this.validityService.validationStatusChange$.subscribe(event => {
      this._steps[event.index].validationStatus = event.validationStatus;
      this._steps = this.addSummaryValidity(this._steps);
    });
  }

  ngOnDestroy(): void {
    if (this.validationStatusChange$) {
      this.validationStatusChange$.unsubscribe();
    }
  }

  addSummaryValidity(steps: Step[]): Step[] {
    const allStepsExceptLast = steps.filter((_, i) => i < steps.length - 1);
    const lastStepValidity = this.getSummaryValidity(allStepsExceptLast.map(x => x.validationStatus));
    const lastStep: Step = { ...steps[steps.length - 1], validationStatus: lastStepValidity };
    return [...allStepsExceptLast, lastStep];
  }

  getSummaryValidity(otherStepsValidity: ValidationStatus[]): ValidationStatus {
    if (otherStepsValidity.length === 0) {
      return ValidationStatus.INITIAL;
    }
    const invalid = otherStepsValidity.some(
      step => step !== ValidationStatus.VALID && step !== ValidationStatus.PARTLY_VALID,
    );

    return invalid ? ValidationStatus.INITIAL : ValidationStatus.VALID;
  }

  private initStepsId(): void {
    this._steps = this._steps.map(step => {
      return {
        ...step,
        id: this.getLastTranslationSlice(step.translationKey),
      };
    });
  }

  private getLastTranslationSlice(translationKey: string): string {
    return translationKey ? `${translationKey.split('.')?.pop()}-step` : '';
  }
}
