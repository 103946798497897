<div class="transfer__wrapper">

  <div class="section-spacing rc2-pad--right--xl">
    <div class="row">
      <div class="col-md-12">
        <h2 class="transfer__header--title">
          {{'notifications.transfer.title'| translate}}
        </h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="transfer__header--subtitle">
          {{'notifications.transfer.subtitle' | translate}}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <div class="transfer__manufacturers">
          <recall2-select
            data-cy="notification-transfer-manufacturer-list"
            [isFormSubmitted]="isFormSubmitted"
            [property]="selectManufacturerProperty"
            (onChange)="selectManufacturer($event)"
          >
          </recall2-select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <h4 class="transfer__comments--title">
          {{'notifications.form.category.comments' | translate}}
        </h4>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <recall2-notification-comment
          data-cy="notification-transfer-comment-textarea"
          [isRequired]="false"
          [isFormSubmitted]="false"
          (onValueChanged)="setComment($event)"
        ></recall2-notification-comment>

        <recall2-comments-and-history
          data-cy="notification-transfer-comment-and-history"
          [objectType]="'NOTIFICATION'"
          [objectId]="notificationId"
          [commentsDisabled]="true"
        >
        </recall2-comments-and-history>
      </div>

      <div class="col-md-0-5"></div>
      <div class="col-md-3-5">
        <div class="transfer__warning">
          <recall2-notification-warning [showIcon]="false">
          </recall2-notification-warning>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr class="rc2-mar--bottom--m rc2-mar--top--m">
        <recall2-footnote-required class="rc2-display--block rc2-mar--bottom--4xl"></recall2-footnote-required>
      </div>
    </div>
  </div>

  <recall2-floating-bar>

    <div class="transfer__actions">
      <recall2-button-tertiary
        data-cy="notification-transfer-cancel-button"
        class="rc2-mar--right--s"
        (click)="close()"
      >
        {{'notifications.form.button.close' | translate}}
      </recall2-button-tertiary>

      <recall2-button-primary
        data-cy="notification-transfer-submit-button"
        (click)="submit()"
      >
        {{'notifications.transfer.button.submit' | translate}}
      </recall2-button-primary>
    </div>

  </recall2-floating-bar>

</div>
