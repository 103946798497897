import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import type { AttachmentFile } from '../../models';

export interface AttachmentStoreState {
  attachments: AttachmentFile[];
  rowItemAttachments: AttachmentFile[];
}

export const attachmentInitialState = (): AttachmentStoreState => {
  return {
    attachments: [],
    rowItemAttachments: [],
  };
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'attachments' })
export class AttachmentStore extends Store<AttachmentStoreState> {
  constructor() {
    super(attachmentInitialState());
  }
}
