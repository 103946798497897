import type { ConnectedPosition, FlexibleConnectedPositionStrategyOrigin, OverlayRef } from '@angular/cdk/overlay';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import type { ElementRef } from '@angular/core';
import { Injectable } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import type { IRecall2FilterParam, Recall2FilterV2Config } from '../../overlay/models/filter.model';
import {
  doesEventContainCDKOverlayContainerAsParent,
  hasClickedOverlayTrigger,
} from '../../utils/cdk-overlay/cdk-overlay.utils';
import { TableFilterOverlayLayoutComponent } from '../components/table-filter-overlay/table-filter-overlay-layout.component';

type PositionOffset = Pick<ConnectedPosition, 'offsetX' | 'offsetY'>;

@Injectable({
  providedIn: 'root',
})
export class Recall2TableFilterOverlayService {
  overlayRef: OverlayRef;

  private outsidePointerEventsSubscription: Subscription;

  constructor(private overlay: Overlay) {}

  openFilter(
    filterConfig: Recall2FilterV2Config,
    activeFilter: IRecall2FilterParam,
    overlayPositionOrigin: FlexibleConnectedPositionStrategyOrigin,
    triggerElementRef: ElementRef,
    positionOffset: PositionOffset,
  ): Observable<IRecall2FilterParam> {
    this.setOverlayConfiguration(overlayPositionOrigin, triggerElementRef, positionOffset, filterConfig);
    return this.setFilterComponentInOverlay(filterConfig, activeFilter);
  }

  private setOverlayConfiguration(
    overlayPositionOrigin: FlexibleConnectedPositionStrategyOrigin,
    triggerElementRef: ElementRef,
    positionOffset: PositionOffset,
    filterConfig: Recall2FilterV2Config,
  ): void {
    const { offsetX, offsetY } = positionOffset;

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(overlayPositionOrigin)
      .withPush(false)
      .withPositions([
        {
          originX: filterConfig.position ?? 'start',
          originY: 'bottom',
          overlayX: filterConfig.position ?? 'start',
          overlayY: 'top',
          offsetX,
          offsetY,
        },
      ]);

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    this.overlayRef = this.overlay.create({ positionStrategy, scrollStrategy });

    this.closeFilterOnClickOutside(triggerElementRef);
  }

  private setFilterComponentInOverlay(
    filterConfig: Recall2FilterV2Config,
    activeFilter: IRecall2FilterParam,
  ): Observable<IRecall2FilterParam> {
    const componentRef = this.overlayRef.attach(new ComponentPortal(TableFilterOverlayLayoutComponent));

    const componentInstance = componentRef.instance;

    componentInstance.setFilterConfigurationAndActiveFilter(filterConfig, activeFilter);

    const filterChanges$ = new Subject<IRecall2FilterParam>();

    componentInstance.filterUpdate.pipe(take(1)).subscribe((selectedParam: IRecall2FilterParam) => {
      this.closeFilter();
      filterChanges$.next(selectedParam);
    });

    return filterChanges$;
  }

  private closeFilterOnClickOutside(triggerElementRef: ElementRef): void {
    this.outsidePointerEventsUnsubscribe();

    this.outsidePointerEventsSubscription = this.overlayRef.outsidePointerEvents().subscribe(event => {
      if (doesEventContainCDKOverlayContainerAsParent(event)) {
        return;
      }

      if (hasClickedOverlayTrigger(triggerElementRef, event)) {
        event.stopPropagation();
      }

      this.closeFilter();

      this.outsidePointerEventsUnsubscribe();
    });
  }

  private outsidePointerEventsUnsubscribe(): void {
    if (this.outsidePointerEventsSubscription) {
      this.outsidePointerEventsSubscription.unsubscribe();
    }
  }

  private closeFilter(): void {
    this.overlayRef.detach();
  }
}
