import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { KnownUserProviderModule } from '../routing/known-user-provider/known-user-provider.module';
import { UnknownUserGuard } from './guards/unknown-user-guard.service';
import { notificationRoutes } from './routing/notification-routes';
import { NotificationRoutingService } from './routing/notification-routing.service';

@NgModule({
  imports: [RouterModule.forChild(notificationRoutes), KnownUserProviderModule],
  providers: [NotificationRoutingService, UnknownUserGuard],
})
export class NotificationRoutingModule {}
