import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-objekt-aktion',
  templateUrl: './recall2-icon-objekt-aktion.component.html',
  styleUrls: ['./recall2-icon-objekt-aktion.component.scss'],
  standalone: true,
})
export class Recall2IconObjektAktionComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i71-objekt-aktion');
  }
}
