import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import type { ErrorType, FileErrorMessage } from '../attachment/file-validation';

@Component({
  selector: 'recall2-error-message-list',
  templateUrl: './error-message-list.component.html',
  styleUrls: ['./error-message-list.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, TranslateModule],
})
export class ErrorMessageListComponent {
  readonly ERROR_TYPE_TO_TRANSLATE_KEY: Record<ErrorType, string> = {
    'max-file-amount-exceeded': 'attachments.error.maxAmountFiles',
    'max-file-size-exceeded': 'attachments.error.maxFileSize',
    'non-supported-type': 'attachments.error.wrongExtension',
    'virus-detected': 'attachments.error.virusDetected',
    'file-empty': 'attachments.error.fileEmpty',
  };

  @Input() messages: FileErrorMessage[];
}
