<div class="description-component">
  <div class="event-icon">
    <recall2-event-icon [eventType]="item.type"></recall2-event-icon>
  </div>
  <div
    class="event-description rc2-font--size--14"
    [innerHTML]="item.type | eventDescription: item.paramsJson | async"
  >
  </div>
</div>
