import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, TemplateRef } from '@angular/core';
import { SVGIconComponent } from '@recall2/icons';

import { Recall2SVGIconButtonDirective } from '../../directives/recall2-svg-icon-button/recall2-svg-icon-button.directive';
import { Recall2ButtonBaseComponent } from '../../recall2-button-base.component';

@Component({
  selector: 'recall2-button-secondary',
  templateUrl: './recall2-button-secondary.component.html',
  styleUrls: ['./recall2-button-secondary.component.scss', '../../recall2-button-base.component.scss'],
  standalone: true,
  imports: [Recall2ButtonBaseComponent, Recall2SVGIconButtonDirective, NgClass, NgIf, NgTemplateOutlet],
})
export class Recall2ButtonSecondaryComponent extends Recall2ButtonBaseComponent {
  SVGIconComponent = SVGIconComponent;
  @ContentChild(Recall2SVGIconButtonDirective, { read: TemplateRef }) svgIcon: TemplateRef<SVGIconComponent>;
}
