<div class="logo rc2-font--family--regular" data-testid="logo-and-title">
  <recall2-icon-logo-recall [attr.id]="'iconLogo'" [rc2IconStyles]="'rc2-icon--size--52'"> </recall2-icon-logo-recall>
  ReCall 2
</div>

<h1 class="rc2-font--family--bold rc2-font--size--20" data-testid="my-serve-title">
  {{ 'my-serve-page.title' | translate }}
</h1>

<div class="content rc2-font--size--16" data-testid="my-serve-content">
  <div>
    {{ 'my-serve-page.content1' | translate }}
    <span class="rc2-font--bold">{{ 'my-serve-page.content2' | translate }}</span>
  </div>
  <div>{{ 'my-serve-page.content3' | translate }}</div>
</div>

<div class="url rc2-color--primary rc2-font--bold" data-testid="my-serve-url">
  <a href="https://iserve.vw.vwg/myserve">https://iserve.vw.vwg/myserve</a>
</div>

<div class="button-container rc2-mar--top--3xl">
  <create-notification-button></create-notification-button>
</div>
