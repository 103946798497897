import type { Action } from '@ngrx/store';

export enum EErrorActions {
  ShowErrorPopup = '[Error] Show Popup',
  HideErrorPopup = '[Error] Hide Popup',
  ShowUnauthorizedErrorPopup = '[Unauthorized Error] Show Popup',
  ShowConcurrentNotification = '[Concurrent Error] Show Notification',
}

export class ShowErrorPopupAction implements Action {
  readonly type = EErrorActions.ShowErrorPopup;

  constructor(
    public errorMessageKey?: string,
    public dynamicValues?: { [key: string]: any }[],
  ) {}
}

export class HideErrorPopupAction implements Action {
  readonly type = EErrorActions.HideErrorPopup;
}

export class ShowUnauthorizedErrorPopupAction implements Action {
  readonly type = EErrorActions.ShowUnauthorizedErrorPopup;
}

export class ShowConcurrentNotificationAction implements Action {
  readonly type = EErrorActions.ShowConcurrentNotification;

  constructor(
    public errorMessage?: string,
    public objectType?: string,
  ) {}
}

export type ErrorActions = ShowErrorPopupAction | HideErrorPopupAction;
