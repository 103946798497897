import type { UserActions } from './user.actions';
import { UserActionTypes } from './user.actions';
import type { IUserState } from './user.state';
import { initialUserState } from './user.state';

export const userReducer = (state: IUserState, action: UserActions): IUserState => {
  if (state === undefined) {
    state = initialUserState;
  }

  switch (action.type) {
    case UserActionTypes.GetUserSuccess: {
      return {
        ...state,
        user: action.user,
      };
    }

    case UserActionTypes.GetUserFailed: {
      return {
        ...state,
        user: null,
      };
    }

    default: {
      return state;
    }
  }
};
