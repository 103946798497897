import { NgIf } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2ButtonPrimaryComponent, Recall2ButtonSecondaryComponent } from '../../../../buttons';
import { CancelModalData } from '../../../models/cancel-modal-data.model';

@Component({
  selector: 'recall2-form-cancel-modal',
  templateUrl: './form-cancel-modal.component.html',
  styleUrls: ['./form-cancel-modal.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf, Recall2ButtonSecondaryComponent, Recall2ButtonPrimaryComponent],
})
export class ReCall2FormCancelModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ReCall2FormCancelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CancelModalData,
  ) {}

  onConfirm($event: Event): void {
    $event.stopPropagation();
    if (this.data.submitCallback) {
      this.data.submitCallback();
    }
    this.dialogRef.close(true);
  }

  onCancel(): void {
    if (this.data.closeCallback) {
      this.data.closeCallback();
    }
    this.dialogRef.close(false);
  }
}
