export enum ActivityType {
  AuthorityNumberCreated = 'AUTHORITY_NUMBER_CREATED',
  AuthorityNumberDeleted = 'AUTHORITY_NUMBER_DELETED',
  CampaignManufacturerAdded = 'CAMPAIGN_MANUFACTURER_ADDED_TO_CAMPAIGN_EVENT',
  CampaignManufacturerRemoved = 'CAMPAIGN_MANUFACTURER_REMOVED_FROM_CAMPAIGN_EVENT',
  CampaignAuthorChanged = 'CAMPAIGN_AUTHOR_CHANGED_EVENT',
  AgendaItemUnlinked = 'AGENDA_ITEM_UNLINKED_TO_CAMPAIGN_EVENT',
  AgendaItemLinked = 'AGENDA_ITEM_LINKED_TO_CAMPAIGN_EVENT',
  CampaignCodeChanged = 'CAMPAIGN_CODE_CHANGED_EVENT',
  CampaignCreated = 'CAMPAIGN_CREATED_EVENT',
  CampaignStatusChanged = 'CAMPAIGN_STATUS_CHANGED_EVENT',
  CampaignDataLabelCreated = 'CAMPAIGN_DATA_LABEL_CREATED',
  CampaignDataLabelDeleted = 'CAMPAIGN_DATA_LABEL_DELETED',
  CampaignTitlesAddedEvent = 'CAMPAIGN_TITLES_ADDED_EVENT',
  CampaignTitlesChangedEvent = 'CAMPAIGN_TITLES_CHANGED_EVENT',
  CampaignTitlesDeletedEvent = 'CAMPAIGN_TITLES_DELETED_EVENT',
  CriterionCreated = 'CRITERION_CREATED_EVENT',
  CriterionCodeChanged = 'CRITERION_CODE_CHANGE_EVENT',
  CriterionTitlesChanged = 'CRITERION_TITLES_CHANGED_EVENT',
  CriterionTitlesAdded = 'CRITERION_TITLES_ADDED_EVENT',
  CriterionTitlesDeleted = 'CRITERION_TITLES_DELETED_EVENT',
  CriterionTypeChanged = 'CRITERION_TYPE_CHANGE_EVENT',
  CriterionRemoved = 'CRITERION_REMOVED_EVENT',
  CriterionOruChanged = 'CRITERION_ORU_CHANGED_EVENT',
  CampaignGlobalConfigurationEnabledChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_ENABLED_CHANGED_EVENT',
  CampaignGlobalConfigurationTypeChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_TYPE_CHANGED_EVENT',
  CampaignGlobalConfigurationStartDateChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_START_DATE_CHANGED_EVENT',
  CampaignGlobalConfigurationEndDateChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_END_DATE_CHANGED_EVENT',
  CampaignGlobalConfigurationClaimDateChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_CLAIM_DATE_CHANGED_EVENT',
  CampaignGlobalConfigurationMarkRelevantChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_MARK_RELEVANT_CHANGED_EVENT',
  CampaignGlobalConfigurationOruChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_ORU_CHANGED_EVENT',
  CampaignGlobalConfigurationKpiChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_KPI_CHANGED_EVENT',
  CampaignGlobalConfigurationIndChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_IND_CHANGED_EVENT',
  CampaignGlobalConfigurationCusChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_CUS_CHANGED_EVENT',
  CampaignGlobalConfigurationOemChanged = 'CAMPAIGN_GLOBAL_CONFIGURATION_OEM_CHANGED_EVENT',
  CampaignCountryExceptionRuleCreated = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_CREATED_EVENT',
  CampaignCountryExceptionRuleEnabledChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_ENABLED_CHANGED_EVENT',
  CampaignCountryExceptionRuleTypeChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_TYPE_CHANGED_EVENT',
  CampaignCountryExceptionRuleStartDateChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_START_DATE_CHANGED_EVENT',
  CampaignCountryExceptionRuleEndDateChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_END_DATE_CHANGED_EVENT',
  CampaignCountryExceptionRuleClaimEndDateChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_CLAIM_END_DATE_CHANGED_EVENT',
  CampaignCountryExceptionRuleOruChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_ORU_CHANGED_EVENT',
  CampaignCountryExceptionRuleKpiChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_KPI_CHANGED_EVENT',
  CampaignCountryExceptionRuleIndChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_IND_CHANGED_EVENT',
  CampaignCountryExceptionRuleCusChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_CUS_CHANGED_EVENT',
  CampaignCountryExceptionRuleOemChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_OEM_CHANGED_EVENT',
  CampaignCountryExceptionRuleRemoved = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_REMOVED_EVENT',
  CampaignCountryExceptionRuleStatusChanged = 'CAMPAIGN_COUNTRY_EXCEPTION_RULE_STATUS_CHANGED_EVENT',
  CampaignCriterionExceptionRuleCreated = 'CAMPAIGN_CRITERION_EXCEPTION_RULE_CREATED_EVENT',
  CampaignCriterionExceptionRuleEnabledChanged = 'CAMPAIGN_CRITERION_EXCEPTION_RULE_ENABLED_CHANGED_EVENT',
  CampaignCriterionExceptionRuleRemoved = 'CAMPAIGN_CRITERION_EXCEPTION_RULE_REMOVED_EVENT',
  CampaignCriterionExceptionRuleStartDateChanged = 'CAMPAIGN_CRITERION_EXCEPTION_RULE_START_DATE_CHANGED_EVENT',
  CampaingMappingCreated = 'CAMPAIGN_MAPPING_CREATED_EVENT',
  CampaingMappingTargetCriteriaAdded = 'CAMPAIGN_MAPPING_ADD_TARGET_CRITERIA_EVENT',
  CampaignVehicleExclusionExcludedChanged = 'CAMPAIGN_VEHICLE_EXCLUSION_EXCLUDED_CHANGED_EVENT',
  VerificationTaskStatusUpdatedEvent = 'VERIFICATION_TASK_STATUS_UPDATED_EVENT',
  VerificationTaskDueToUpdatedEvent = 'VERIFICATION_TASK_DUE_TO_UPDATED_EVENT',
  VerificationTaskEditorUpdatedEvent = 'VERIFICATION_TASK_EDITOR_UPDATED_EVENT',
  CampaignMappingActivationChanged = 'CAMPAIGN_MAPPING_ACTIVATION_CHANGED_EVENT',
}
