import type { EnvironmentProviders } from '@angular/core';
import { ErrorHandler, makeEnvironmentProviders } from '@angular/core';

import { RECALL2_NEW_RELIC_INITIALIZER_PROVIDER } from './initializers/recall2-new-relic.initializer';
import { RECALL2_NEW_RELIC_ENABLED } from './injection-tokens/recall2-new-relic-enabled.token';
import { RECALL2_NEW_RELIC_SETTING_TOKEN } from './injection-tokens/recall2-new-relic-setting.token';
import type { Recall2NewRelicSetting } from './models/recall2-new-relic-setting';
import { Recall2NewRelicErrorHandler } from './services/recall2-new-relic-error-handler';

export interface ProvideNewRelicOptions {
  enabled: boolean;
  setting?: Recall2NewRelicSetting;
}

export function provideNewRelic({ enabled, setting }: ProvideNewRelicOptions): EnvironmentProviders[] {
  const providers: EnvironmentProviders[] = [
    makeEnvironmentProviders([
      {
        provide: RECALL2_NEW_RELIC_ENABLED,
        useValue: enabled,
      },

      {
        provide: RECALL2_NEW_RELIC_SETTING_TOKEN,
        useValue: setting,
      },
      RECALL2_NEW_RELIC_INITIALIZER_PROVIDER,
      {
        provide: ErrorHandler,
        useClass: Recall2NewRelicErrorHandler,
      },
    ]),
  ];

  return providers;
}
