import type { AfterViewInit, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AbstractGenericCellViewWithData } from '@recall2/ui/dynamic-content';
import type { CheckboxProperty } from '@recall2/ui/form/model';
import type { Recall2TableService, WithTableService } from '@recall2/ui/tables';

import type { InvolvedUser } from '../../../model/involved-user.model';
import { Recall2CheckboxComponent } from '../../../../../../frontend-core/src/lib/form/components/recall2-checkbox/recall2-checkbox.component';

@Component({
    selector: 'inherit-to-agenda-item-cell',
    templateUrl: './inherit-to-agenda-item.cell.html',
    styleUrls: ['./inherit-to-agenda-item.cell.scss'],
    standalone: true,
    imports: [Recall2CheckboxComponent],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class InheritToAgendaItemCell
  extends AbstractGenericCellViewWithData<InvolvedUser>
  implements WithTableService, AfterViewInit, OnInit
{
  public static readonly selector = 'recall2-involved-user-inherit-to-agenda-item-cell';
  public static readonly changeEvent = 'onChange';
  private disableCheckBox: boolean;

  private tableService: Recall2TableService;

  public checkboxProperty: CheckboxProperty = {
    name: 'syncAgendaItemCheckbox',
    required: false,
    translationKey: undefined,
    isChecked: false,
    hasTooltip: false,
    control: new FormControl(),
  };

  ngOnInit(): void {
    if (this.disableCheckBox) {
      this.checkboxProperty.control.disable();
    }
    if (this.data.syncAgendaItem) {
      this.updateCheckboxValue();
    }
  }

  ngAfterViewInit(): void {
    if (this.data.syncAgendaItem) {
      this.updateCheckboxValue();
    }
  }

  setTableService(tableService: Recall2TableService): void {
    this.tableService = tableService;
    this.tableService.registerCellEvent<InvolvedUser>(
      InheritToAgendaItemCell.selector,
      InheritToAgendaItemCell.changeEvent,
    );
  }

  updateCheckboxValue(): void {
    this.checkboxProperty.isChecked = this.data.syncAgendaItem;
    this.checkboxProperty.control.setValue(this.data.syncAgendaItem);
    this.checkboxProperty.control.updateValueAndValidity();
  }

  onChange(control: FormControl): void {
    this.data.syncAgendaItem = control.value;

    this.tableService.triggerCellEvent<InvolvedUser>(
      InheritToAgendaItemCell.selector,
      InheritToAgendaItemCell.changeEvent,
      this.data,
    );
  }
}

export class InheritToAgendaItemCellDataBinding implements InheritToAgendaItemCellBinding {
  constructor(
    public propertyName?: string,
    public disableCheckBox?: boolean,
  ) {}
}

interface InheritToAgendaItemCellBinding {
  propertyName?: string;
  disableCheckBox?: boolean;
}
