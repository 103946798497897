import type { OnDestroy, OnInit } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2ButtonPrimaryComponent, Recall2ButtonTertiaryComponent } from '@recall2/ui/buttons';
import { Recall2FloatingBarComponent } from '@recall2/ui/floating-bar';
import { AppTranslationService } from '@recall2/ui/i18n';
import type { CommonModalData } from '@recall2/ui/modals';
import { CommonModalComponent, CommonModalService } from '@recall2/ui/modals';
import { TabTitleService } from '@recall2/ui/tab-title';
import { filter, first, takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../../../rest-api/notification/notification.service';
import type { IAppState } from '../../../../store/app/app.state';
import { NotificationRoutingService } from '../../../routing/notification-routing.service';
import { NotificationBaseComponent } from '../notification-base/notification-base.component';
import { NotificationConfirmComponent } from '../notification-confirm/notification-confirm.component';

@Component({
  selector: 'recall2-notification-discard',
  templateUrl: './notification-discard.component.html',
  standalone: true,
  imports: [
    NotificationConfirmComponent,
    Recall2FloatingBarComponent,
    Recall2ButtonPrimaryComponent,
    Recall2ButtonTertiaryComponent,
    TranslateModule,
  ],
})
export class NotificationDiscardComponent extends NotificationBaseComponent implements OnInit, OnDestroy {
  @ViewChild('confirmComponent') confirmComponent: NotificationConfirmComponent;

  private cancelModalData: CommonModalData = {
    title: 'shared.modal.leave-page',
    content: 'shared.modal.leave-page-content',
    cancelButtonKey: 'common.no',
    submitButtonKey: 'common.yes',
  };

  constructor(
    modal: MatDialog,
    store: Store<IAppState>,
    notificationService: NotificationService,
    routingService: NotificationRoutingService,
    activatedRoute: ActivatedRoute,
    tabTitleService: TabTitleService,
    translationService: AppTranslationService,
    private modalService: CommonModalService,
  ) {
    super(modal, store, notificationService, routingService, activatedRoute, tabTitleService, translationService);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  cancelDiscard(): void {
    if (!this.isSubmitOk()) {
      this.routingService.navigateToSummary(this.notificationId);
      return;
    }

    this.openCancelModal();
  }

  isSubmitOk(): boolean {
    return !!this.comment;
  }

  discardNotification(): void {
    this.performAction(
      () => this.notificationService.discardNotification(this.notificationId, this.currentLocale, this.comment),
      _ => this.routingService.navigateToBaseRouteNotification(this.notificationId),
    );
  }

  private openCancelModal(): void {
    const dialogRef = this.modalService.open(CommonModalComponent, { data: this.cancelModalData });
    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroyed$),
        first(),
        filter(data => !!data),
      )
      .subscribe(() => this.routingService.navigateToSummary(this.notificationId));
  }
}
