import type { AfterViewInit, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { of } from 'rxjs';

import {
  StatusCellBinding,
  StatusCellComponent,
} from '../dynamic-content/components/cells/status-cell/status-cell.component';
import type { IColumnDefinition } from '../dynamic-content/models/dynamic-content.model';
import { ColumnDefinition } from '../dynamic-content/models/dynamic-content.model';
import { ThreeStepCheckboxValidators } from '../form/validators/recall2-three-step-checkbox.validator';
import { App } from '../navigation/models/app';
import { Recall2BasicTableComponent } from '../tables/components/recall2-basic-table/recall2-basic-table.component';
import { ETableType } from '../tables/models/recall2-table';
import { Recall2TableFacadeBuilder } from '../tables/services/recall2-table-facade.builder';
import { CommentCellComponent, CommentCellDataBinding } from './cells/comment-cell/comment.cell';
import { LinkOverviewCellBinding, LinkOverviewCellComponent } from './cells/link-overview-cell/link-overview.cell';
import { ManufacturerCellBinding, ManufacturerCellComponent } from './cells/manufacturer.cell/manufacturer.cell';
import {
  ManufacturerTopicIdCellComponent,
  TopicIdCellBinding,
} from './cells/manufacturer-topic-id-cell/manufacturer-topic-id.cell';
import { OpenNewTabCellBinding, OpenNewTabCellComponent } from './cells/open-new-tab-cell/open-new-tab.cell';
import { ThreeStepCellComponent, ThreeStepCellDataBinding } from './cells/three-step-cell/three-step.cell';
import type { IClearingTopicManufacturers } from './models/manufacturer.model';
import { EManufacturerAffectedTableHeader } from './models/manufacturer-table.model';

@Component({
  selector: 'recall2-manufacturer-table',
  templateUrl: './recall2-manufacturer-table.component.html',
  standalone: true,
  imports: [Recall2BasicTableComponent],
})
export class Recall2ManufacturerTableComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() clearingTopicManufacturers: IClearingTopicManufacturers[];
  @Input() currentClearingId: number;
  @Input() readonly = false;
  @Input() showQuorumColumn = false;
  @Output() getColumnDefinitions: EventEmitter<IColumnDefinition[]> = new EventEmitter<IColumnDefinition[]>();
  @Input() type = ETableType.Context;
  // URL to open the linking dialog
  @Input() linkingDialogAPP = App.Topics;
  @Input() urlToLinkingDialog = '/clearings/topic-link-overview';
  @Input() beforeRedirectFn: () => void = () => of();

  public tableFacade: Recall2TableFacadeBuilder;

  // URL to open the given topic
  public readonly topicRedirecttargetApp = App.Topics;
  public readonly topicRedirectUrlPathOverview = '/topics';

  private columnDefinitions: ColumnDefinition[];
  private affectedColumn: ColumnDefinition;
  private manufacturerColumn: ColumnDefinition;
  private quorumColumn: ColumnDefinition;
  private commentColumn: ColumnDefinition;
  private topicIdColumn: ColumnDefinition;
  private stateColumn: ColumnDefinition;
  private linkTopicColumn: ColumnDefinition;
  private routeToTopicColumn: ColumnDefinition;

  ngOnInit(): void {
    this.affectedColumn = new ColumnDefinition(
      EManufacturerAffectedTableHeader.AFFECTED,
      'col-md-1',
      ThreeStepCellComponent,
      new ThreeStepCellDataBinding('affected', this.readonly, 'manufacturerTopicId'),
      'manufacturerTable.affected',
    );
    this.manufacturerColumn = new ColumnDefinition(
      EManufacturerAffectedTableHeader.MANUFACTURER_NAME,
      'col-md-2',
      ManufacturerCellComponent,
      new ManufacturerCellBinding('manufacturer.name'),
      'manufacturerTable.manufacturer',
    );
    this.quorumColumn = new ColumnDefinition(
      EManufacturerAffectedTableHeader.QUORUM,
      'col-md-1',
      ThreeStepCellComponent,
      new ThreeStepCellDataBinding(
        'quorum',
        this.readonly,
        null,
        !this.readonly ? ThreeStepCheckboxValidators.requiredSelectedOrDeselected() : null,
      ),
      'manufacturerTable.quorum',
    );
    this.commentColumn = new ColumnDefinition(
      EManufacturerAffectedTableHeader.COMMENT,
      'col-md-4',
      CommentCellComponent,
      new CommentCellDataBinding('comment', this.readonly),
      'manufacturerTable.comment',
    );
    this.topicIdColumn = new ColumnDefinition(
      EManufacturerAffectedTableHeader.TOPIC_ID,
      'col-md-1-5',
      ManufacturerTopicIdCellComponent,
      new TopicIdCellBinding('manufacturerTopicId'),
      'manufacturerTable.manufacturerTopicId',
    );
    this.stateColumn = new ColumnDefinition(
      EManufacturerAffectedTableHeader.STATE,
      'col-md-2',
      StatusCellComponent,
      new StatusCellBinding('manufacturerTopicStatus'),
      'manufacturerTable.manufacturerTopicState',
    );
    this.routeToTopicColumn = new ColumnDefinition(
      EManufacturerAffectedTableHeader.ROUTE_TO_TOPIC,
      'col-md-0-5',
      OpenNewTabCellComponent,
      new OpenNewTabCellBinding('manufacturerTopicId', this.topicRedirecttargetApp, this.topicRedirectUrlPathOverview),
    );
    this.linkTopicColumn = new ColumnDefinition(
      EManufacturerAffectedTableHeader.LINK_TOPIC,
      'col-md-1',
      LinkOverviewCellComponent,
      new LinkOverviewCellBinding(
        'affected',
        this.linkingDialogAPP,
        this.urlToLinkingDialog,
        this.currentClearingId,
        this.beforeRedirectFn,
      ),
      'manufacturerTable.linkTopic',
    );

    this.columnDefinitions = this.showQuorumColumn
      ? this.getQuorumColumnDefinition()
      : this.getDefaultColumnDefinition();

    this.tableFacade = new Recall2TableFacadeBuilder('Manufacturer-Table', '')
      .withColumnDefinition(this.columnDefinitions)
      .build();

    this.tableFacade.getTableService().setTableData(this.clearingTopicManufacturers);
  }

  ngAfterViewInit(): void {
    this.getColumnDefinitions.next(this.columnDefinitions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.tableFacade && changes.clearingTopicManufacturers.currentValue.length > 0) {
      this.tableFacade.getTableService().setTableData(this.clearingTopicManufacturers);
    }
  }
  private getDefaultColumnDefinition(): ColumnDefinition[] {
    return [
      { ...this.affectedColumn },
      { ...this.manufacturerColumn },
      { ...this.commentColumn },
      { ...this.topicIdColumn },
      { ...this.stateColumn },
      { ...this.linkTopicColumn },
      { ...this.routeToTopicColumn },
    ];
  }

  private getQuorumColumnDefinition(): ColumnDefinition[] {
    return [
      { ...this.affectedColumn, columnSize: 'col-md-1-5' },
      { ...this.manufacturerColumn, columnSize: 'col-md-2-5' },
      { ...this.quorumColumn, columnSize: 'col-md-1-5' },
      { ...this.commentColumn, columnSize: 'col-md-3' },
      { ...this.topicIdColumn, columnSize: 'col-md-1' },
      { ...this.stateColumn, columnSize: 'col-md-1' },
      { ...this.linkTopicColumn, columnSize: 'col-md-1' },
      { ...this.routeToTopicColumn, columnSize: 'col-md-0-5' },
    ];
  }
}
