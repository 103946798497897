import { ENumberFilterComparator } from '../../../../models/filter.model';
import type { Recall2FilterNumberOption } from '../models/recall2-filter-number-option.model';

export const RECALL2_FILTER_NUMBER_OPTIONS: Recall2FilterNumberOption[] = [
  {
    value: ENumberFilterComparator.EQUALS,
    id: 'number-filter-option-equals',
    label: 'recall2-filter.number.comparator.equals',
  },
  {
    value: ENumberFilterComparator.LESS,
    id: 'number-filter-option-less',
    label: 'recall2-filter.number.comparator.less',
  },
  {
    value: ENumberFilterComparator.GREATER,
    id: 'number-filter-option-greater',
    label: 'recall2-filter.number.comparator.greater',
  },
];
