import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { ERole } from '@recall2/ui/navbar';
import { mapUserRoleToERoles } from '@recall2/ui/utils';
import type { Observable } from 'rxjs';
import { BehaviorSubject, Subject } from 'rxjs';

import type { IAppState } from '../../store/app/app.state';
import { UserSelectors } from '../../user/store/user.selectors';
import type { UserData } from '../../user/store/user.state';

@Injectable({
  providedIn: 'root',
})
export class PermissionService implements OnDestroy {
  private rolesSubject: BehaviorSubject<ERole[]> = new BehaviorSubject<ERole[]>(null);
  private destroyed$ = new Subject<void>();

  constructor(private _store: Store<IAppState>) {
    this._store.select(UserSelectors.getUser).subscribe((user: UserData) => {
      if (user) {
        this.rolesSubject.next(mapUserRoleToERoles(user.roles));
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getUserRoles(): Observable<ERole[]> {
    return this.rolesSubject.asObservable();
  }
}
