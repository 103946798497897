import { Component, Input } from '@angular/core';

import { Recall2IconAddComponent } from '../../../icons';
import { Recall2ButtonOutlineComponent } from '../recall2-button-outline/recall2-button-outline.component';

@Component({
  selector: 'recall2-button-outline-add',
  templateUrl: './recall2-button-outline-add.component.html',
  styleUrls: [
    './recall2-button-outline-add.component.scss',
    '../recall2-button-outline/recall2-button-outline.component.scss',
  ],
  standalone: true,
  imports: [Recall2IconAddComponent, Recall2ButtonOutlineComponent],
})
export class Recall2ButtonOutlineAddComponent {
  @Input() rc2ButtonStyles = '';
  @Input() isDisabled = false;
}
