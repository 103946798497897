import dayjs from 'dayjs';

import type { FieldSorting } from '../../core/api/models/sortable-request-options';
import type {
  FilterComparator,
  IRecall2FilterItem,
  IRecall2FilterParam,
  IRecall2SortItem,
} from '../../overlay/models/filter.model';
import { EDateFilterComparator, EFilterTemplates, ENumberFilterComparator } from '../../overlay/models/filter.model';
import type { Recall2SortingOrder } from '../../tables/models/recall2-sort';

const DATE_TYPE = 0;
const NUMBER_TYPE = 1;

export const getMappedSort = (sorting: FieldSorting): IRecall2SortItem[] => {
  return sorting ? [{ identifier: sorting.field, value: sorting.orderBy as unknown as Recall2SortingOrder }] : [];
};

export const getQuerySort = (sorting: FieldSorting): string => {
  return sorting ? `${sorting.field},${sorting.orderBy}` : '';
};

export const getQueryFilters = (activeFilters: IRecall2FilterParam[], allowDateEqualComparator: boolean): string => {
  let filtersString = '';

  activeFilters.forEach(filter => {
    if (filter.type === DATE_TYPE) {
      filtersString = mapDateFilter(filter, filtersString, allowDateEqualComparator);
    } else if (filter.type === NUMBER_TYPE && filter.comparator !== ENumberFilterComparator.EQUALS) {
      filtersString +=
        filter.comparator === ENumberFilterComparator.LESS
          ? `${filter.identifier}<${filter.value};`
          : `${filter.identifier}>${filter.value};`;
    } else {
      filter.value.forEach(value => {
        const isSelectType: boolean =
          filter.type.toString() === EFilterTemplates.SELECT.toString() ||
          filter.type.toString() === EFilterTemplates.CUSTOM_SELECT.toString();

        if (isSelectType && filtersString.includes(filter.identifier)) {
          const substr = filtersString.slice(filtersString.indexOf(filter.identifier));
          const mappedSubstr = substr.replace(';', `,${value};`);
          filtersString = filtersString.replace(substr, mappedSubstr);
        } else {
          filtersString += `${filter.identifier}:${value};`;
        }
      });
    }
  });

  return filtersString;
};

export const getMappedFilters = (activeFilters: IRecall2FilterParam[]): IRecall2FilterItem[] => {
  const filters = [];

  activeFilters.forEach(filter => {
    let mappedFilter;

    if (filter.type === DATE_TYPE) {
      if (filter.comparator === 'BETWEEN') {
        const fromFilter = {
          identifier: filter.identifier,
          value: [getMinDate(filter.value[0] as string)],
          comparator: getMappedComparator('GREATER' as FilterComparator),
        };
        const toFilter = {
          identifier: filter.identifier,
          value: [getToDate(filter.value[1] as string)],
          comparator: getMappedComparator('OLDER' as FilterComparator),
        };

        filters.push(fromFilter, toFilter);
      } else {
        mappedFilter = {
          identifier: filter.identifier,
          value: [castDateToYearMonthDay(filter.value[0].toString())],
          comparator: getMappedComparator(filter.comparator),
        };
        filters.push(mappedFilter);
      }
    } else {
      mappedFilter = {
        identifier: filter.identifier,
        value: filter.value,
        comparator: getMappedComparator(filter.comparator),
      };
      filters.push(mappedFilter);
    }
  });

  return (filters as unknown as IRecall2FilterItem[]) ?? [];
};

const mapDateFilter = (
  filter: IRecall2FilterParam,
  filtersString: string,
  allowDateEqualComparator: boolean,
): string => {
  if (filter.comparator === EDateFilterComparator.BETWEEN) {
    const fromComparator = allowDateEqualComparator ? '>=' : '>';
    const from = `${filter.identifier}${fromComparator}${getMinDate(filter.value[0] as string)};`;
    const to = `${filter.identifier}<${getToDate(filter.value[1] as string)};`;

    filtersString += from;
    filtersString += to;
  } else if (filter.comparator === EDateFilterComparator.NEWER) {
    filtersString += `${filter.identifier}>${getMinDate(filter.value[0] as string)};`;
  } else {
    filtersString += `${filter.identifier}<${getMinDate(filter.value[0] as string)};`;
  }

  return filtersString;
};

const castDateToYearMonthDay = (value: string): string => {
  return dayjs(new Date(value)).format('YYYY-MM-DD').toString();
};

export const getMappedComparator = (comparator: FilterComparator): string => {
  switch (comparator) {
    case 'EQUALS': {
      return 'EQUAL';
    }
    case 'LESS':
    case 'OLDER': {
      return 'LOWER';
    }
    case 'GREATER':
    case 'NEWER': {
      return 'GREATER';
    }
  }
};

const getMinDate = (stringDate: string): string => {
  return dayjs(new Date(stringDate)).format('YYYY-MM-DD').toString();
};

const getToDate = (stringDate: string): string => {
  return dayjs(new Date(stringDate)).add(1, 'day').format('YYYY-MM-DD').toString();
};
