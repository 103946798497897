import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'functionToBoolean',
  standalone: true,
})
export class FunctionToBooleanPipe<T> implements PipeTransform {
  transform(fn: (element: T) => boolean, param: T): boolean {
    return fn(param);
  }
}
