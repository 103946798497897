<recall2-icon-arrow-down
  id="toggle-icon-down"
  class="toggle-icon rc2-icon--size--20"
  *ngIf="!isExpanded"
></recall2-icon-arrow-down>

<recall2-icon-arrow-up
  id="toggle-icon-up"
  class="toggle-icon rc2-icon--size--20"
  *ngIf="isExpanded"
></recall2-icon-arrow-up>
