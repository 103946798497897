import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { iconCloseSmall, SVGIconsRegistry } from '@recall2/icons';

import type { NotificationContent, NotificationType } from '../notification';
import { NotificationComponent } from '../notification';
import type { ToastClose } from './models/toast.model';
import { Toast } from './models/toast.model';
import { ToastService } from './services/toast/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NotificationComponent],
})
export class ToastComponent {
  @Input() set toast(toast: Toast) {
    this._toast = toast;
    this.notification = {
      title: toast.title,
      message: toast.message,
      type: toast.type as NotificationType,
    };
  }
  @Output() afterClosed = new EventEmitter<ToastClose>();

  notification: NotificationContent;
  private _toast: Toast;

  constructor(
    private toastService: ToastService,
    private iconsRegistry: SVGIconsRegistry,
  ) {
    this.iconsRegistry.registerIcons([iconCloseSmall]);
  }

  onClose(): void {
    this.toastService.close(this._toast.id);

    this.afterClosed.next({
      dismissedByAction: true,
    });
  }
}
