import { Component, Input } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-link',
  templateUrl: './recall2-icon-link.component.html',
  styleUrls: ['./recall2-icon-link.component.scss'],
  standalone: true,
})
export class Recall2IconLinkComponent extends Recall2IconBaseComponent {
  @Input() rotate: number;

  constructor() {
    super('icon-i44-link');
  }
}
