<div *ngIf="isSpinnerShowing" data-cy="recall2-auto-suggest-list-loading" class="rc2-mar--top--xs rc2-mar--bottom--xs">
  <recall2-loading-spinner [spinnerDiameter]="20" [forceShowing]="true"></recall2-loading-spinner>
</div>

<div *ngIf="!isArrowDown && !isSpinnerShowing">
  <!-- List of entries -->
  <div
    #autoSuggestList
    id="auto-suggest-users"
    data-cy="autosuggestList"
    [ngClass]="{ 'auto-suggest-overflow': entries?.length > 3 }"
    (keydown)="onKeyDownForList()"
  >
    <div
      *ngFor="let entry of entries; let i = index"
      #element
      tabindex="1"
      data-cy="recall2-auto-suggest-list-item"
      class="auto-suggest-entry rc2-font--body--m"
      [ngClass]="{ 'auto-suggest-entry--disabled': objectType === userTypeValue && !entry.email }"
      matTooltip="{{ 'autoSuggest.directory.disabled.entry.user' | translate }}"
      matTooltipPosition="below"
      matTooltipClass="rc2-tooltip"
      [matTooltipDisabled]="!!entry.email || objectType !== userTypeValue"
      (keydown)="onKeyDownForElement($event, element, i)"
      (click)="onClick($event, entry, i)"
    >
      <div *ngIf="isCheckSelectedItems" #checkSelectedItems class="checkmark-hidden">
        <recall2-svg-icon
          class="rc2-petrol-800-primary"
          data-cy="recall2-auto-suggest-list-item-check-icon"
          name="check"
          size="medium"
        >
        </recall2-svg-icon>
      </div>

      <div
        #highlighter
        class="main-info"
        recall2Highlight
        [highlightObject]="entry"
        [highlightText]="searchTerm"
        [highlightObjectType]="objectType"
      ></div>
    </div>
    <!-- Messages -->
    <div class="rc2-font--body--m">
      <div
        *ngIf="!entries?.length"
        id="auto-suggest-no-users"
        class="auto-suggest-message"
        data-cy="recall2-auto-suggest-list-item-message"
      >
        {{ 'autoSuggest.noEntriesFound' | translate }}
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="showButtons && searchDirectoryEnabled">
  <hr />
  <div class="autosuggest-buttons">
    <div class="directory-search">
      <recall2-button-primary
        data-cy="autoSuggestDirectorySearchBtn"
        class="directorySearchButton"
        (click)="onDirectorySearch()"
      >
        {{ 'autoSuggest.directory.search' | translate }}
      </recall2-button-primary>
    </div>
  </div>
</ng-container>
