import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import type { AutoSuggestTag } from '../../../model';

@Pipe({ name: 'filterAutoSuggest', standalone: true })
export class FilterAutoSuggestPipe implements PipeTransform {
  transform(arrayOne: Array<AutoSuggestTag>, arrayTwo: Array<string>): Array<AutoSuggestTag> {
    return arrayOne.filter(el => {
      return !arrayTwo.includes(el.text);
    });
  }
}
