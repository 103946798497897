<div
  class="icon-wrapper rc2-display--flex rc2-align-items--center rc2-justify--center"
  (click)="changeSortOrder()"
>
  <recall2-svg-icon
    *ngIf="currentSortOrder === SortValues.None; else sortingEnabled"
    name="navigation-direction-up-down"
    size="small"
    data-cy="table-sort-none"
  ></recall2-svg-icon>

  <ng-template #sortingEnabled>
    <recall2-svg-icon
      *ngIf="currentSortOrder === SortValues.Asc"
      class="coloured-icon"
      name="navigation-direction-up"
      size="small"
      data-cy="table-sort-asc"
    ></recall2-svg-icon>

    <recall2-svg-icon
      *ngIf="currentSortOrder === SortValues.Desc"
      class="coloured-icon"
      name="navigation-direction-down"
      size="small"
      data-cy="table-sort-desc"
    ></recall2-svg-icon>
  </ng-template>
</div>
