import { ActivityType } from '../enums';
import type { CoordinationDetailsActivityItemMapped } from '../models';

const createActivityMappedItem = (
  extraMappedData: CoordinationDetailsActivityItemMapped,
): CoordinationDetailsActivityItemMapped => {
  return {
    author: 'EZMZIMR',
    happenedOn: '07.11.2022 - 12:55',
    ...extraMappedData,
  };
};

export const coordinationDetailsActivityItemLabelMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CampaignDataLabelCreated,
    label: 'test label',
  });

export const coordinationDetailsActivityItemAuthorityNumberMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.AuthorityNumberCreated,
    authorityNumber: '124231SDS3',
    countryCode: 'Germany',
  });

export const coordinationDetailsActivityItemCountryExceptionMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CampaignCountryExceptionRuleRemoved,
    countryCode: 'DEU',
  });

export const coordinationDetailsActivityItemCriterionTitlenMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CriterionTitlesChanged,
    criterionId: 'T2',
    titles: [
      {
        language: 'el-GR',
        title: 'hfghfg',
      },
      {
        language: 'en-US',
        title: 'test',
      },
    ],
  });

export const coordinationDetailsActivityItemCriterionTitlenDeletedMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CriterionTitlesDeleted,
    criterionId: 'T2',
    titles: [
      {
        language: 'el-GR',
        title: 'hfghfg',
      },
      {
        language: 'en-US',
        title: 'test',
      },
    ],
  });

export const coordinationDetailsActivityItemCampaignTitlenMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CampaignTitlesChangedEvent,
    titles: [
      {
        language: 'el-GR',
        title: 'hfghfg',
      },
      {
        language: 'en-US',
        title: 'test',
      },
    ],
  });

export const coordinationDetailsActivityItemCampaignTitlenDeletedMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CampaignTitlesDeletedEvent,
    titles: [
      {
        language: 'el-GR',
        title: 'hfghfg',
      },
      {
        language: 'en-US',
        title: 'test',
      },
    ],
  });

export const coordinationDetailsActivityItemGlobalDateMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CampaignGlobalConfigurationStartDateChanged,
    oldValue: '2021-12-24',
    newValue: '2021-12-23',
    countryCode: 'WW',
  });

export const coordinationDetailsActivityItemCountryExceptionDateMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CampaignCountryExceptionRuleClaimEndDateChanged,
    oldValue: '2021-12-24',
    newValue: '2021-12-23',
    countryCode: 'DEU',
  });

export const coordinationDetailsActivityItemCountryExceptionCommentMappedMock: CoordinationDetailsActivityItemMapped =
  createActivityMappedItem({
    event: ActivityType.CampaignCountryExceptionRuleStartDateChanged,
    oldValue: '2021-12-24',
    newValue: '2021-12-23',
    countryCode: 'DEU',
    comment: 'test comment',
  });
