import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AbstractGenericCellViewWithData } from '@recall2/ui/dynamic-content';
import { InputTextProperty } from '@recall2/ui/form/model';
import type { Recall2TableService, WithTableService } from '@recall2/ui/tables';

import type { InvolvedUser } from '../../../model/involved-user.model';
import { getTranslationKeyForSystemUser } from '../../global/global-functions/global-functions.component';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2InputTextComponent } from '../../../../../../frontend-core/src/lib/form/components/recall2-input-text/recall2-input-text.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'subject-area-edit-cell',
    templateUrl: './subject-area-edit-cell.component.html',
    styleUrls: ['./subject-area-edit-cell.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        Recall2InputTextComponent,
        TranslateModule,
    ],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SubjectAreaEditCell
  extends AbstractGenericCellViewWithData<InvolvedUser>
  implements WithTableService, OnInit
{
  static readonly selector = 'recall2-involved-user-subject-area-editable-cell';
  static readonly updateSubjectAreaEvent = 'onUpdateSubjectArea';

  subjectAreaInput = new InputTextProperty(
    'subjectAreaInput',
    false,
    'involvedUserTable.subjectArea',
    { maxLength: 100 },
    false,
    new FormControl(''),
    false,
  );

  subjectArea: string;
  role: string = undefined;
  title: string;

  private tableService: Recall2TableService;

  ngOnInit(): void {
    this.setSubjectAreaForSystemUser();
    this.subjectAreaInput.control.setValue(this.data[this.subjectArea]);
  }

  updateSubjectArea(): void {
    this.tableService.triggerCellEvent<InputTextProperty>(
      SubjectAreaEditCell.selector,
      SubjectAreaEditCell.updateSubjectAreaEvent,
      this.subjectAreaInput,
    );
  }

  setTableService(tableService: Recall2TableService): void {
    this.tableService = tableService;
    this.tableService.registerCellEvent<InputTextProperty>(
      SubjectAreaEditCell.selector,
      SubjectAreaEditCell.updateSubjectAreaEvent,
    );
  }

  getTranslationKey(role: string): string {
    return getTranslationKeyForSystemUser(role);
  }

  showExpertUser(): boolean {
    return !(this.data[this.role] && this.data[this.role] !== 'EXPERT');
  }

  private setSubjectAreaForSystemUser(): void {
    if (this.data[this.role] && this.data[this.role] !== 'EXPERT') {
      this.data[this.subjectArea] = this.data[this.role];
    }
  }
}

export class SubjectAreaCellDataBinding implements ISubjectAreaBinding {
  constructor(
    public subjectArea: string,
    public role?: string,
  ) {}
}

interface ISubjectAreaBinding {
  subjectArea: string;
  role?: string;
}
