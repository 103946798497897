import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

import { Recall2DirectorySearchComponent } from './auto-suggest';
import { Recall2DynamicContentComponent } from './dynamic-content/recall2-dynamic-content.component';
import { ReCall2LinkComponent } from './link';
import { Recall2LoadingSpinnerComponent } from './loading-spinner';
import { Recall2NavigationStepperComponent } from './navigation-stepper';
import {
  CurrencyFormatterPipe,
  CustomDatePipe,
  CustomTimePipe,
  FileSizePipe,
  IncludesPipe,
  MarkEmptyPipe,
} from './pipes';

const MODULES = [
  Recall2DynamicContentComponent,
  Recall2LoadingSpinnerComponent,
  Recall2NavigationStepperComponent,
  ReCall2LinkComponent,
  Recall2DirectorySearchComponent,
  CustomDatePipe,
  CustomTimePipe,
  FileSizePipe,
  MarkEmptyPipe,
  CurrencyFormatterPipe,
  IncludesPipe,
];

@NgModule({
  imports: [FormsModule, ReactiveFormsModule, ...MODULES],
  exports: [...MODULES],
  providers: [
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
})
export class ReCall2CoreModule {}
