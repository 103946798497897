<div
  class="elements-container"
  data-cy="table-paginator-elements"
>
  <div>
    <ng-container *ngFor="let template of paginatorLeftElementTemplates">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </ng-container>
  </div>
  <div>
    <ng-container *ngFor="let template of paginatorRightElementTemplates">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </ng-container>
  </div>
</div>

<div class="previous-next-buttons">
  <recall2-button-tertiary
    class="paginator-button"
    (click)="onPreviousPageClick()"
    data-cy="table-paginator-previous-button"
    [isDisabled]="isPreviousClickDisabled || arePageButtonsDisabled"
  >
    <recall2-svg-icon
      name="arrow-left-filled"
      size="small"
      [ngClass]="!isPreviousClickDisabled && !arePageButtonsDisabled ? iconColor : null"
      *recall2SvgIconButton
    >
    </recall2-svg-icon>
  </recall2-button-tertiary>
  <recall2-button-tertiary
    class="paginator-button"
    (click)="onNextPageClick()"
    data-cy="table-paginator-next-button"
    [isDisabled]="isNextClickDisabled || arePageButtonsDisabled"
  >
    <recall2-svg-icon
      name="arrow-right-filled"
      size="small"
      [ngClass]="!isNextClickDisabled && !arePageButtonsDisabled ? iconColor : null"
      *recall2SvgIconButton
    >
    </recall2-svg-icon>
  </recall2-button-tertiary>
</div>
