import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { INotification } from '../../../../models/notification/notification';
import { SummaryTextComponent } from '../summary-text/summary-text.component';
import { ThreeStateTranslatePipe } from './../../../../../ui/pipes/three-state-to-translation/three-state-to-translation.pipe';

@Component({
  selector: 'notification-summary-vehicles',
  templateUrl: './notification-summary-vehicles.component.html',
  providers: [ThreeStateTranslatePipe],
  standalone: true,
  imports: [TranslateModule, NgIf, NgClass, NgFor, SummaryTextComponent],
})
export class NotificationSummaryVehiclesComponent {
  @Input() notification: INotification;
}
