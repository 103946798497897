import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from './recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-topic-urgency-four',
  templateUrl: './recall2-icon-base/recall2-icon-base.component.html',
  standalone: true,
})
export class Recall2IconTopicUrgencyFourComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i109-topic-urgency-4');
  }
}
