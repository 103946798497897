import type { OnDestroy, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import type { LangChangeEvent } from '@ngx-translate/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { LanguageCode } from '../i18n/enums/language-code.enum';

@Component({
  selector: 'recall2-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class FooterComponent implements OnInit, OnDestroy {
  supportLink: string;
  deLink = '/consent/help-site/de/';
  enLink = '/consent/help-site/en/';
  private destroyed$ = new Subject<void>();

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.supportLink = this.translateService.currentLang === LanguageCode.German ? this.deLink : this.enLink;
    this.changeSupportLink();
  }

  private changeSupportLink(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe((change: LangChangeEvent) => {
      this.supportLink = change.lang === LanguageCode.German ? this.deLink : this.enLink;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
