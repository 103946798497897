import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { ErrorHandlingService } from '../services/error-handling.service';
import type { ShowConcurrentNotificationAction, ShowErrorPopupAction } from './errorhandling.actions';
import { EErrorActions } from './errorhandling.actions';

@Injectable()
export class ErrorHandlingEffects {
  constructor(
    private _actions$: Actions,
    private _errorHandlingService: ErrorHandlingService,
  ) {}

  showErrorPopupAction$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EErrorActions.ShowErrorPopup),
        tap((action: ShowErrorPopupAction) => {
          if (action.dynamicValues) {
            this._errorHandlingService.showGenericErrorDialog(action.errorMessageKey, action.dynamicValues);
          } else {
            this._errorHandlingService.showGenericErrorDialog(action.errorMessageKey);
          }
        }),
      ),
    { dispatch: false },
  );

  showUnauthorizedErrorPopupAction$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EErrorActions.ShowUnauthorizedErrorPopup),
        tap(() => this._errorHandlingService.openUnauthorizedModal()),
      ),
    { dispatch: false },
  );

  showConcurrentNotificationAction$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(EErrorActions.ShowConcurrentNotification),
        tap((action: ShowConcurrentNotificationAction) =>
          this._errorHandlingService.showConcurrentNotification(action.errorMessage, action.objectType),
        ),
      ),
    { dispatch: false },
  );
}
