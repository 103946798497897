import { NgClass } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import { AbstractGenericCellViewWithData } from '../../../dynamic-content/models/dynamic-content.model';
import { Recall2TextareaComponent } from '../../../form/components/recall2-textarea';
import type { TextareaProperty } from '../../../form/model';
import { InputTextProperty } from '../../../form/model';
import type { WithTableService } from '../../../tables/models/recall2-table';
import type { Recall2TableService } from '../../../tables/services/recall2-table.service';

@Component({
  selector: 'comment-cell',
  templateUrl: './comment.cell.html',
  styleUrls: ['./comment.cell.scss'],
  standalone: true,
  imports: [Recall2TextareaComponent, NgClass],
})
export class CommentCellComponent
  extends AbstractGenericCellViewWithData
  implements OnInit, WithTableService, ICommentBinding
{
  public static readonly selector = 'recall2-manufacturer-comment-cell';
  public static readonly updateCommentEvent = 'onUpdateComment';

  public property: InputTextProperty;
  public comment: string;
  public readonly: boolean;
  private tableService: Recall2TableService;

  ngOnInit(): void {
    this.property = new InputTextProperty(
      'CommentTextarea',
      false,
      'manufacturerTable.comment',
      { maxLength: 2048 },
      false,
      new FormControl(this.data[this.comment], []),
      false,
    );
  }

  public updateComment(event: FormControl): void {
    this.data[this.comment] = event.value;
    this.tableService.triggerCellEvent<TextareaProperty>(
      CommentCellComponent.selector,
      CommentCellComponent.updateCommentEvent,
      this.property,
    );
  }

  public setTableService(tableService: Recall2TableService): any {
    this.tableService = tableService;
    this.tableService.registerCellEvent<TextareaProperty>(
      CommentCellComponent.selector,
      CommentCellComponent.updateCommentEvent,
    );
  }
}

export class CommentCellDataBinding implements ICommentBinding {
  constructor(
    public comment: string,
    public readonly: boolean,
  ) {}
}

interface ICommentBinding {
  comment: string;
  readonly: boolean;
}
