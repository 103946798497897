import { BehaviorSubject } from 'rxjs';

import type { DownloadButtonConfig } from '../../buttons';
import type {
  IColumnDefinition,
  IExpandableContentDefinitionV2,
} from '../../dynamic-content/models/dynamic-content.model';
import type { PageDTO, PaginationModel } from '../../pagination/model/pagination.model';

enum ETableType {
  Object = 'Object',
  Context = 'Context',
}

interface SelectorEventMap<T> {
  [selector: string]: {
    [event: string]: BehaviorSubject<T>;
  };
}

export class Recall2TableService<ContentType = unknown> {
  private expandedObjectIdSet = new BehaviorSubject(new Set<number>());
  private highlightedObjectIdSet = new BehaviorSubject<Set<number>>(new Set<number>());
  private highlightingQueryParamIdentifier = new BehaviorSubject<string>(null);
  private expansionQueryParamIdentifier = new BehaviorSubject<string>(null);
  private scrollId = new BehaviorSubject<number>(null);
  private tableType = new BehaviorSubject<ETableType>(null);
  private highlightingClass = new BehaviorSubject<string>(null);
  private paginationModel = new BehaviorSubject<PaginationModel>(null);
  private persistedPaginationModel = new BehaviorSubject<PaginationModel>(null);
  private columnDefinitions = new BehaviorSubject<IColumnDefinition[]>(undefined);
  private tableData = new BehaviorSubject<ContentType[]>(null);
  private expandableContentDefinition = new BehaviorSubject<IExpandableContentDefinitionV2>(null);
  private spinnerSrc = new BehaviorSubject<string>(null);
  private spinnerForceShowing = new BehaviorSubject<boolean>(null);
  private spinnerStartAnimationOn = new BehaviorSubject<string[]>(null);
  private spinnerStopAnimationOn = new BehaviorSubject<string[]>(null);
  private editCell = new BehaviorSubject<string | number>(null);
  private downloadConfig = new BehaviorSubject<DownloadButtonConfig>(null);

  private cellEvents: SelectorEventMap<unknown> = {};
  private _isRowExpandable = false;

  onEdit(): BehaviorSubject<string | number> {
    return this.editCell;
  }

  triggerEdit(objectId: string | number): void {
    this.editCell.next(objectId);
  }

  getExpandedObjectIdSet(): BehaviorSubject<Set<number>> {
    return this.expandedObjectIdSet;
  }

  setExpandedObjectIdSet(set: Set<number>): void {
    this.expandedObjectIdSet.next(set);
  }

  getHighlightedObjectIdSet(): BehaviorSubject<Set<number>> {
    return this.highlightedObjectIdSet;
  }

  setHighlightedObjectIdSet(set: Set<number>): void {
    this.highlightedObjectIdSet.next(set);
  }

  updatePagination(page: PageDTO): void {
    const paginationModel = {
      paginationTotalElements: page.totalElements,
      paginationTotalPages: page.totalPages > 0 ? page.totalPages : 1,
      currentSelectedPage: page.pageable.pageNumber + 1,
      paginationPageNumber: page.pageable.pageNumber + 1,
      paginationSelectedPageSize: page.pageable.pageSize,
      paginationPageElements:
        page.size * (page.pageable.pageNumber + 1) < page.totalElements
          ? page.size * (page.pageable.pageNumber + 1)
          : page.totalElements,
    };
    this.setPersistedPaginationModel(paginationModel);
  }

  clearPagination(): void {
    const paginationModel = {
      paginationTotalElements: 0,
      paginationTotalPages: 0,
      currentSelectedPage: 0,
      paginationPageNumber: 0,
      paginationSelectedPageSize: 0,
      paginationPageElements: 0,
    };
    this.setPersistedPaginationModel(paginationModel);
  }

  setFirstPage(): void {
    const currentPaginationModelValue = this.paginationModel.value;

    if (!currentPaginationModelValue) {
      return;
    }

    this.setPaginationModel({ ...currentPaginationModelValue, currentSelectedPage: 1, paginationPageNumber: 1 });
  }

  getPaginationModel(): BehaviorSubject<PaginationModel> {
    return this.paginationModel;
  }

  setPaginationModel(paginationModel: PaginationModel): void {
    this.paginationModel.next(paginationModel);
  }

  getPersistedPaginationModel(): BehaviorSubject<PaginationModel> {
    return this.persistedPaginationModel;
  }

  setPersistedPaginationModel(persistedPaginationValues: PaginationModel): void {
    this.persistedPaginationModel.next(persistedPaginationValues);
  }

  getColumnDefinitions(): BehaviorSubject<IColumnDefinition[]> {
    return this.columnDefinitions;
  }

  setColumnDefinitions(columnDefinitions: IColumnDefinition[]): void {
    this.columnDefinitions.next(columnDefinitions);
  }

  getTableData(): BehaviorSubject<ContentType[]> {
    return this.tableData;
  }

  setTableData(tableData: ContentType[]): void {
    this.tableData.next(tableData);
  }

  getExpandableContentDefinition(): BehaviorSubject<IExpandableContentDefinitionV2> {
    return this.expandableContentDefinition;
  }

  isRowExpandable(): boolean {
    return this._isRowExpandable;
  }

  setExpandableContentDefinition(
    expandableColumnDefinition: IExpandableContentDefinitionV2,
    isRowExpandable = false,
  ): void {
    this.expandableContentDefinition.next(expandableColumnDefinition);
    this._isRowExpandable = isRowExpandable;
  }

  getHighlightingClass(): BehaviorSubject<string> {
    return this.highlightingClass;
  }

  setHighlightingClass(highlightingClass: string): void {
    this.highlightingClass.next(highlightingClass);
  }

  getHighlightingQueryParamId(): BehaviorSubject<string> {
    return this.highlightingQueryParamIdentifier;
  }

  setHighlightingQueryParamId(queryParam: string): void {
    this.highlightingQueryParamIdentifier.next(queryParam);
  }

  getExpansionQueryParamId(): BehaviorSubject<string> {
    return this.expansionQueryParamIdentifier;
  }

  setExpansionQueryParamId(queryParam: string): void {
    this.expansionQueryParamIdentifier.next(queryParam);
  }

  getScrollId(): BehaviorSubject<number> {
    return this.scrollId;
  }

  setScrollId(scrollId: number): void {
    this.scrollId.next(scrollId);
  }

  getTableType(): BehaviorSubject<ETableType> {
    return this.tableType;
  }

  setTableType(type: ETableType): void {
    this.tableType.next(type);
  }

  getSpinnerSrc(): BehaviorSubject<string> {
    return this.spinnerSrc;
  }

  setSpinnerSrc(spinnerSrc: string): void {
    this.spinnerSrc.next(spinnerSrc);
  }

  getSpinnerForceShowing(): BehaviorSubject<boolean> {
    return this.spinnerForceShowing;
  }

  setSpinnerForceShowing(spinnerForceShowing: boolean): void {
    this.spinnerForceShowing.next(spinnerForceShowing);
  }

  getSpinnerStartAnimationOn(): BehaviorSubject<string[]> {
    return this.spinnerStartAnimationOn;
  }

  setSpinnerStartAnimationOn(startAnimationOn: string[]): void {
    this.spinnerStartAnimationOn.next(startAnimationOn);
  }

  getSpinnerStopAnimationOn(): BehaviorSubject<string[]> {
    return this.spinnerStopAnimationOn;
  }

  setSpinnerStopAnimationOn(stopAnimationOn: string[]): void {
    this.spinnerStopAnimationOn.next(stopAnimationOn);
  }

  setDownloadButtonConfig(downloadButtonConfig: DownloadButtonConfig): void {
    this.downloadConfig.next(downloadButtonConfig);
  }

  getDownloadButtonConfig(): BehaviorSubject<DownloadButtonConfig> {
    return this.downloadConfig;
  }

  registerCellEvent<T>(selector: string, event: string): void {
    this.createSubjectIfNotExists<T>(selector, event);
  }

  getCellEvent<T>(selector: string, event: string): BehaviorSubject<T> {
    this.createSubjectIfNotExists<T>(selector, event);
    return this.cellEvents[selector][event] as BehaviorSubject<T>;
  }

  triggerCellEvent<T>(selector: string, event: string, value: T): void {
    this.cellEvents[selector][event]?.next(value);
  }
  private createSubjectIfNotExists<T>(selector: string, event: string): void {
    if (!this.cellEvents[selector]) {
      this.cellEvents = {
        ...this.cellEvents,
        [selector]: { [event]: new BehaviorSubject<T>(null) },
      };
    }
    if (!this.cellEvents[selector][event]) {
      this.cellEvents[selector] = {
        ...this.cellEvents[selector],
        [event]: new BehaviorSubject<T>(null),
      };
    }
  }
}
