import { NgClass, NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SVGIconModule } from '@recall2/icons';

import { Recall2IconHelpComponent } from '../../../icons/recall2-icon-help/recall2-icon-help.component';
import { Recall2IconRequiredComponent } from '../../../icons/recall2-icon-required/recall2-icon-required.component';
import { Recall2AllowedCharactersDirective } from '../../directives/recall2-allowed-characters';
import { Recall2InputTextComponent } from '../recall2-input-text/recall2-input-text.component';

@Component({
  selector: 'recall2-input-number',
  standalone: true,
  templateUrl: './recall2-input-number.component.html',
  styleUrls: ['../recall2-input-text/recall2-input-text.component.scss', './recall2-input-number.component.scss'],
  imports: [
    NgIf,
    NgClass,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule,
    Recall2IconHelpComponent,
    Recall2IconRequiredComponent,
    Recall2AllowedCharactersDirective,
    SVGIconModule,
  ],
})
export class Recall2InputNumberComponent extends Recall2InputTextComponent implements OnInit {
  @ViewChild('inputNumber') inputNumber: ElementRef;

  private readonly pattern = /^[\d-]*$/;

  private readonly specialNavigationKeys = [
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Backspace',
    'Del',
    'Delete',
  ];

  ngOnInit(): void {
    this.setValidators();
  }

  onInput(): void {
    if (this.property.htmlValidators?.maxLength) {
      this.inputNumber.nativeElement.value = this.inputNumber.nativeElement.value.slice(
        0,
        this.property.htmlValidators.maxLength,
      );
    }
    this.property.control.patchValue(this.inputNumber.nativeElement.value);
  }

  onKeyDownForNumbers(event: KeyboardEvent): boolean {
    const { key, ctrlKey } = event;
    // Emit event, because some component may subscribed to this
    this.onKeyDown.emit(event);

    // Not sure if those special key handling is really needed:
    // Escape some special keys without further validation
    if (ctrlKey || this.specialNavigationKeys.includes(key)) {
      return true;
    }

    if (!this.pattern.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  setFocus(): void {
    this.inputNumber.nativeElement.focus();
  }

  private setValidators(): void {
    if (!this.property.htmlValidators) {
      return;
    }

    const { min, max } = this.property.htmlValidators;
    const validatorList = [];

    if (this.property.control.validator) {
      validatorList.push(this.property.control.validator);
    }

    if (min !== undefined) {
      validatorList.push(Validators.min(+min));
    }

    if (max !== undefined) {
      validatorList.push(Validators.max(+max));
    }

    this.property.control.setValidators(validatorList);
  }
}
