<recall2-comment-input
  *ngIf="!commentsDisabled"
  #commentInput
  [isRequired]="commentRequired"
  [showLabel]="commentRequired"
  [isFormSubmitted]="isFormSubmitted"
  (addComment)="onAddComment($event)"
>
</recall2-comment-input>
<div class="item-container">
  <recall2-object-event
    *ngFor="let item of items"
    [objectType]="objectType"
    [item]="item"
  ></recall2-object-event>
</div>
<recall2-loading-spinner [forceShowing]="showSpinner"></recall2-loading-spinner>
