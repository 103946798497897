export const affectedManufacturers = [
  {
    translationKey: 'Audi AG',
    value: 'Audi AG',
    notTranslate: true,
  },
  {
    translationKey: 'Bentley Motors Ltd.',
    value: 'Bentley Motors Ltd.',
    notTranslate: true,
  },
  {
    translationKey: 'Bugatti',
    value: 'Bugatti',
    notTranslate: true,
  },
  {
    translationKey: 'FAW Audi',
    value: 'FAW Audi',
    notTranslate: true,
  },
  {
    translationKey: 'Lamborghini',
    value: 'Lamborghini',
    notTranslate: true,
  },
  {
    translationKey: 'MAN',
    value: 'MAN',
    notTranslate: true,
  },
  {
    translationKey: 'MAN Latin America',
    value: 'MAN Latin America',
    notTranslate: true,
  },
  {
    translationKey: 'Porsche AG',
    value: 'Porsche AG',
    notTranslate: true,
  },
  {
    translationKey: 'Seat S.A.',
    value: 'Seat S.A.',
    notTranslate: true,
  },
  {
    translationKey: 'VW AG Nutzfahrzeuge',
    value: 'VW AG Nutzfahrzeuge',
    notTranslate: true,
  },
  {
    translationKey: 'VW Argentinia',
    value: 'VW Argentinia',
    notTranslate: true,
  },
  {
    translationKey: 'VW FAW China',
    value: 'VW FAW China',
    notTranslate: true,
  },
  {
    translationKey: 'VW Group Rus',
    value: 'VW Group Rus',
    notTranslate: true,
  },
  {
    translationKey: 'VW India',
    value: 'VW India',
    notTranslate: true,
  },
  {
    translationKey: 'VW SVW China',
    value: 'VW SVW China',
    notTranslate: true,
  },
  {
    translationKey: 'VW South Africa',
    value: 'VW South Africa',
    notTranslate: true,
  },
  {
    translationKey: 'VW NAR',
    value: 'VW NAR',
    notTranslate: true,
  },
  {
    translationKey: 'VW do Brasil',
    value: 'VW do Brasil',
    notTranslate: true,
  },
  {
    translationKey: 'Volkswagen AG',
    value: 'Volkswagen AG',
    notTranslate: true,
  },
  {
    translationKey: 'Volkswagen MSK A&V',
    value: 'Volkswagen MSK A&V',
    notTranslate: true,
  },
  {
    translationKey: 'Škoda Auto a.s.',
    value: 'Škoda Auto a.s.',
    notTranslate: true,
  },
];
