<div
  *ngIf="!loading; else loadingCase"
  class="upload-box"
>
  <div class="text-wrapper">
    <recall2-icon-add-attachment class="rc2-icon--size--52 rc2-color--primary"></recall2-icon-add-attachment>
    <h4 class="rc2-color--primary rc2-pad--bottom--xs">{{ 'attachments.title' | translate }}</h4>
    <p class="rc2-font--body--m rc2-color--grey-600">{{ 'attachments.description' | translate }}</p>
  </div>
  <input
    (change)="onChange($event)"
    type="file"
    [accept]="allowedExtensions"
    multiple
  />
</div>

<ng-template #loadingCase>
  <div
    *ngIf="loading"
    class="loading-box"
  >
    <div class="text-wrapper">
      <div class="rc2-pad--top--l rc2-pad--bottom--s">
        <recall2-loading-spinner
          [forceShowing]="true"
          [spinnerDiameter]="30"
        ></recall2-loading-spinner>
      </div>
      <h4 class="rc2-color--secondary rc2-pad--bottom--xs">{{ 'attachments.title.loading' | translate }}</h4>
      <p class="rc2-font--body--m rc2-color--grey-600">{{ 'attachments.description.loading' | translate }}</p>
    </div>
  </div>
</ng-template>

<p class="rc2-font--body--s rc2-color--grey-400-secondary rc2-pad--top--xs">{{ 'attachments.subtitle' | translate }}</p>
