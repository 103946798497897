<div class="rc2-mar--bottom--4xl">
  <h2 data-testid="review-header" class="rc2-mar--bottom--3xl">{{ title | translate }}</h2>
  <div class="rc2-display--flex rc2-mar--bottom--m">
    <div class="col-md-7 rc2-pad--no">
      <div class="rc2-mar--bottom--m rc2-font--body--l rc2-pad--right--m" data-testid="forward-subheader">
        {{ message | translate }}
      </div>
      <div class="md-7 rc2-pad--no" *ngIf="showSelectUser">
        <recall2-auto-suggest
          #autoSuggest
          [entries]="users"
          [isSpinnerShowing]="isSpinnerShowing"
          [objectType]="autoSuggestType.USER"
          [configuration]="autoSuggestConfiguration"
          [isFormSubmitted]="isFormSubmitted"
          (updateSearchTerm)="onSearchTermChanged($event)"
          (selectedEntry)="selectedUser($event)"
        ></recall2-auto-suggest>
      </div>
    </div>
    <div class="col-md-5 rc2-pad--no">
      <recall2-notification-warning></recall2-notification-warning>
    </div>
  </div>
  <div class="rc2-border--bottom--grey--200-tertiary rc2-pad--bottom--s">
    <h4>{{ 'notifications.form.category.comments' | translate }}</h4>
  </div>
  <div class="rc2-border--bottom--grey--200-tertiary rc2-pad--bottom--s rc2-mar--bottom--m">
    <recall2-notification-comment
      [isRequired]="commentRequired"
      [isFormSubmitted]="isFormSubmitted && commentRequired"
      (onValueChanged)="commentChanged($event)"
    ></recall2-notification-comment>
    <recall2-comments-and-history
      #commmentHistory
      [commentsDisabled]="true"
      [objectType]="objectType"
      [objectId]="currentObjectId"
    ></recall2-comments-and-history>
  </div>
  <div class="rc2-font--body--s rc2-color--grey-900">*) {{ 'notifications.form.requiredField.hint' | translate }}</div>
</div>
