import { NgIf } from '@angular/common';
import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs';

import { Recall2ButtonsToggleGroupComponent, Recall2ToggleElementComponent } from '../../../../buttons';
import { Recall2DatepickerComponent } from '../../../../datepicker/recall2-datepicker.component';
import type { DateProperty } from '../../../../form/model';
import { MoreFiltersScrollService } from '../../../../table/components/more-filters/services/scroll/more-filters-scroll.service';
import { compareObjects } from '../../../../utils';
import type { IRecall2FilterParam } from '../../../models/filter.model';
import { EDateFilterComparator, EFilterTemplates, IRecall2FilterDateParam } from '../../../models/filter.model';
import type { FilterDateRangeV2 } from '../recall2-filter-date-v2/recall2-filter-date-range-v2/recall2-filter-date-range-v2.component';
import { Recall2FilterDateRangeComponent } from './recall2-filter-date-range/recall2-filter-date-range.component';

@Component({
  selector: 'recall2-filter-date',
  templateUrl: './recall2-filter-date.component.html',
  styleUrls: ['./recall2-filter-date.component.scss'],
  standalone: true,
  imports: [
    Recall2ButtonsToggleGroupComponent,
    Recall2ToggleElementComponent,
    Recall2FilterDateRangeComponent,
    Recall2DatepickerComponent,
    TranslateModule,
    NgIf,
  ],
})
export class Recall2FilterDateComponent implements OnChanges, OnDestroy, OnInit {
  @Input() currentSelectedFilter: IRecall2FilterDateParam;
  @Input() isPeriodOptionVisible = true;

  @Output() filterChanged = new EventEmitter<IRecall2FilterDateParam>();
  @Output() componentDestroyed = new EventEmitter<IRecall2FilterParam>();

  @ViewChild(Recall2DatepickerComponent) datePickerInput: Recall2DatepickerComponent;

  selectedFilterOption: EDateFilterComparator;
  EDateFilterComparator = EDateFilterComparator;

  fromDateProperty: DateProperty = Recall2FilterDateComponent.buildDateProperty(
    'filterDateFrom',
    null,
    'recall2-filter.dateFrom',
    null,
    null,
    true,
  );
  toDateProperty: DateProperty = Recall2FilterDateComponent.buildDateProperty(
    'filterDateTo',
    null,
    'recall2-filter.dateTo',
    null,
    null,
    true,
  );

  singleDateProperty: DateProperty = Recall2FilterDateComponent.buildDateProperty(
    'filterDate',
    null,
    null,
    null,
    null,
    true,
  );

  private selectedFilterParams = {} as IRecall2FilterDateParam;

  private static buildDateProperty(
    name: string,
    date: Date,
    translationKey: string,
    minDate: Date,
    maxDate: Date,
    hasTitle: boolean,
  ): DateProperty {
    return {
      name: name,
      required: false,
      hasTooltip: false,
      control: new FormControl({ value: date, disabled: false }),
      translationKey: translationKey,
      minDate: minDate,
      maxDate: maxDate,
      hasTitle: hasTitle,
    } as DateProperty;
  }

  constructor(private moreFiltersScrollService: MoreFiltersScrollService) {}

  ngOnInit(): void {
    this.watchMoreFiltersScroll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentSelectedFilterChanges = changes.currentSelectedFilter;

    const hasFilterChanged = !compareObjects(
      currentSelectedFilterChanges.previousValue,
      currentSelectedFilterChanges.currentValue,
    );

    if (!hasFilterChanged) {
      return;
    }

    const selectedFilter = currentSelectedFilterChanges.currentValue;
    const dateValues = selectedFilter?.value ?? [];
    const fromDate = dateValues[0] ?? null;
    const toDate = dateValues[1] ?? null;
    const comparator = selectedFilter?.comparator ?? EDateFilterComparator.BETWEEN;

    this.update(fromDate, toDate, comparator, dateValues);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.emit(this.selectedFilterParams);
  }

  updateSelectedFilterWithRange(filter: FilterDateRangeV2): void {
    const dateRange = filter ? [filter.from, filter.to] : [];
    this.selectedFilterParams = this.createSelectedFilterParams(dateRange);

    this.filterChanged.emit(this.selectedFilterParams);
  }

  updateSelectedFilter(control: FormControl): void {
    const date = control.value ? [control.value] : [];
    this.selectedFilterParams = this.createSelectedFilterParams(date);

    this.filterChanged.emit(this.selectedFilterParams);
  }

  onFilterOptionChange(selectedOption: string): void {
    this.selectedFilterOption = selectedOption as EDateFilterComparator;
    this.resetFilterProperties();
    this.filterChanged.emit(this.selectedFilterParams);
  }

  private watchMoreFiltersScroll(): void {
    this.moreFiltersScrollService.scroll$
      .pipe(
        takeUntil(this.componentDestroyed),
        filter(() => this.datePickerInput?.isOpen),
      )
      .subscribe(() => {
        this.datePickerInput.attachedOverlay.overlayRef.detach();
      });
  }

  private resetFilterProperties(): void {
    this.update(null, null, this.selectedFilterOption, []);
  }

  private createSelectedFilterParams(dateValues: Date[]): IRecall2FilterDateParam {
    return {
      identifier: this.currentSelectedFilter.identifier,
      translationKey: this.currentSelectedFilter.translationKey,
      type: EFilterTemplates.DATE,
      comparator: this.selectedFilterOption,
      value: dateValues,
    };
  }

  private update(fromDate: Date, toDate: Date, comparator: EDateFilterComparator, dateValues: Date[]): void {
    this.selectedFilterOption = comparator;

    this.updateFromDateProperty(fromDate, toDate);
    this.updateToDateProperty(toDate, fromDate);
    this.updateSingleDateProperty(fromDate, comparator);

    this.selectedFilterParams = this.createSelectedFilterParams(dateValues);
  }

  private updateFromDateProperty(dateValue: Date, maxDate: Date): void {
    this.fromDateProperty.control.setValue(dateValue);
    this.fromDateProperty.maxDate = maxDate;
  }

  private updateToDateProperty(dateValue: Date, minDate: Date): void {
    this.toDateProperty.control.setValue(dateValue);
    this.toDateProperty.minDate = minDate;
  }

  private updateSingleDateProperty(dateValue: Date, comparator: EDateFilterComparator): void {
    this.singleDateProperty.control.setValue(dateValue);
    this.singleDateProperty.translationKey =
      comparator === EDateFilterComparator.OLDER ? 'recall2-filter.dateTo' : 'recall2-filter.dateFrom';
  }
}
