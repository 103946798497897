import type { HttpErrorResponse } from '@angular/common/http';
import type { Action } from '@ngrx/store';
import type { IRecall2FilterItem, IRecall2SortItem } from '@recall2/ui/overlay';
import type { PageDTO } from '@recall2/ui/pagination';

import type { INotification, INotificationForList } from '../models/notification/notification';

export enum ENotificationActions {
  GetPageableDataForOverviewTable = '[Notification] Get Pageable Data For Overview Table',
  GetPageableDataForOverviewTableFailed = '[Notification] Get Pageable Data For Overview Table Failed',
  GetPageableDataForOverviewTableSuccess = '[Notification] Get Pageable Data For Overview Table Success',
  SaveCurrentNotification = '[Notification] Save Current Notification',
  SaveCurrentNotificationFailed = '[Notification] Save Current Notification Failed',
  SaveCurrentNotificationSuccess = '[Notification] Save Current Notification Success',
  UpdateCurrentNotification = '[Update Current Notification] Update Current Notification',
}

export class GetPageableDataForOverviewTableAction implements Action {
  readonly type = ENotificationActions.GetPageableDataForOverviewTable;

  constructor(
    public page: number,
    public pageSize: number,
    public filter: IRecall2FilterItem[],
    public sort: IRecall2SortItem[],
    public currentObjectId: number,
    public src?: string,
  ) {}
}

export class GetPageableDataForOverviewTableFailedAction implements Action {
  readonly type = ENotificationActions.GetPageableDataForOverviewTableFailed;

  constructor(
    public errorResponse: HttpErrorResponse,
    public src?: string,
  ) {}
}

export class GetPageableDataForOverviewTableSuccessAction implements Action {
  readonly type = ENotificationActions.GetPageableDataForOverviewTableSuccess;

  constructor(
    public notificationPage: PageDTO<INotificationForList>,
    public src?: string,
  ) {}
}

export class SaveCurrentNotificationAction implements Action {
  readonly type = ENotificationActions.SaveCurrentNotification;

  constructor(public notification: INotification) {}
}

export class SaveCurrentNotificationFailedAction implements Action {
  readonly type = ENotificationActions.SaveCurrentNotificationFailed;

  constructor(public errorResponse: HttpErrorResponse) {}
}

export class SaveCurrentNotificationSuccessAction implements Action {
  readonly type = ENotificationActions.SaveCurrentNotificationSuccess;

  constructor(public notification: INotification) {}
}
export class UpdateCurrentNotificationAction implements Action {
  readonly type = ENotificationActions.UpdateCurrentNotification;

  constructor(public notification: INotification) {}
}

// action types
export type NotificationActions =
  | GetPageableDataForOverviewTableAction
  | GetPageableDataForOverviewTableFailedAction
  | GetPageableDataForOverviewTableSuccessAction
  | SaveCurrentNotificationAction
  | SaveCurrentNotificationFailedAction
  | SaveCurrentNotificationSuccessAction
  | UpdateCurrentNotificationAction;
