<div
  *ngFor="let item of attachmentList"
  class="row mar-no rc2-align-items--center rc2-overlay--items rc2-font--size--14 rc2-font--family--regular rc2-i-attachment"
>
  <div class="col-md-1 pad-no">
    <recall2-icon-download
      class="rc2-font--size--30 rc2-cursor--pointer"
      [rc2IconStyles]="'rc2-font--size--30 rc2-overflow--hidden rc2-icon--color--blue'"
      (click)="onClickAttachment(item)"
    ></recall2-icon-download>
  </div>
  <div class="div col-md-6 rc2-ellipsis pad-bottom-erase pad-right-erase pad-left-small">
    <a
      #a
      class="rc2-color--grey-900 rc2-font--family--regular rc2-cursor--pointer"
      rel="noopener noreferrer"
      matTooltipPosition="above"
      matTooltipClass="rc2-tooltip"
      [matTooltip]="item['fileName'] ? item['fileName'] : item['filename']"
      (click)="onClickAttachment(item)"
    >
      {{ item['fileName'] ? item['fileName'] : item['filename'] }}
    </a>
  </div>
  <div class="div col-md-2 pad-no pad-left-medium rc2-text-align--right">
    {{ (item['type'] ? item['type'] : item['extension']) | uppercase }}
  </div>
  <div class="div col-md-3 pad-bottom-erase rc2-pad--left--xs pad-right-small rc2-text-align--right">
    {{ item.size | fileSize }}
  </div>
</div>

<div *ngIf="attachmentList.length > 1">
  <hr />

  <div
    class="download-all-row row mar-no rc2-align-items--center rc2-overlay--items rc2-font--size--14 rc2-font--family--regular"
  >
    <div class="col-md-1 pad-no">
      <recall2-icon-download
        class="rc2-font--size--30 rc2-cursor--pointer"
        [rc2IconStyles]="'rc2-font--size--30 rc2-overflow--hidden rc2-icon--color--blue'"
        (click)="downloadAll()"
      ></recall2-icon-download>
    </div>
    <div
      class="div col-md-8 pad-bottom-erase pad-right-erase pad-left-small rc2-ellipsis tag--bold rc2-icon--color--blue rc2-cursor--pointer"
    >
      <a (click)="downloadAll()">{{ 'overlay.attachment.allFiles' | translate }}</a>
    </div>
    <div class="div col-md-3 pad-bottom-erase pad-left-erase pad-right-small rc2-text-align--right">
      {{ getTotalFileSize() | fileSize }}
    </div>
  </div>
</div>
