<form *ngIf="!property.readOnly else readonly">
  <label
    *ngIf="property.hasTitle"
    id="{{ property.name }}_label"
    class="title-label mar-bottom-erase"
    data-testid="title-label"
    [class.title-label--disabled]="property.control.disabled"
  >{{ property.translationKey + '.label' | translate }}
    <recall2-icon-required *ngIf="property.required"></recall2-icon-required>
  </label>
  <recall2-icon-help
    *ngIf="property.hasTooltip"
    id="{{ property.name }}_help_icon"
    matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
    matTooltipPosition="above"
    matTooltipClass="rc2-tooltip"
  >
  </recall2-icon-help>
  <div class="rc2-position--relative">
    <textarea
      #inputText
      recall2MinRowSize
      [minRows]="minRows"
      rows="1"
      id="{{ property.name }}"
      data-cy="main-input"
      [formControl]="property.control"
      placeholder="{{ property.translationKey + '.placeholder' | translate }}"
      type="text"
      maxlength="{{ property?.htmlValidators?.maxLength }}"
      [ngClass]="{
      'error-input': showErrors
    }"
      (change)="change()"
      (keydown)="keydown($event)"
      (keyup)="keyup($event)"
      (focus)="focus($event)"
      (blur)="blur($event)"
      (focusout)="focusout($event)"
      [recall2AllowedCharacters]="property.allowedCharacters"
    ></textarea>
    <div *ngIf="showCounter" class="counter">{{ counter }}</div>
  </div>
  <div class="rc2-position--relative">
    <div
      class="overlay-icon-right"
      *ngIf="!showErrors"
    >
      <ng-content></ng-content>
    </div>
  </div>
  <p
    id="error_feedback_message"
    class="error-message"
    *ngIf="showErrors"
  >
    <recall2-icon-invalid></recall2-icon-invalid>
    {{ 'form.error.generic.' + firstErrorType | translate }}
  </p>
</form>

<ng-template #readonly>
  <label
    *ngIf="property.hasTitle"
    for="{{ property.name }}"
    class=" title-label mar-bottom-erase"
    data-testid="title-label-readonly"
  >{{ property.translationKey + '.label' | translate }}
  </label>
  <label
    class="readonly-label-multiline"
    data-testid="main-content-readonly"
  >
    {{property.control.value}}
  </label>
</ng-template>
