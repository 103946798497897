import type { Manufacturer } from '../../brand-manufacturer/models/manufacturer';
import {
  AuthorsFullNameCellBinding,
  AuthorsFullNameCellComponent,
} from '../../dynamic-content/components/cells/authors-full-name-cell/authors-full-name-cell.component';
import {
  DateCellBinding,
  DateCellComponent,
} from '../../dynamic-content/components/cells/date-cell/date-cell.component';
import {
  DecisionTextCellBinding,
  DecisionTextCellComponent,
} from '../../dynamic-content/components/cells/decision-cell/decision-cell.component';
import {
  DueDateCellBinding,
  DueDateCellComponent,
} from '../../dynamic-content/components/cells/due-date/due-date-cell.component';
import {
  IconCellBinding,
  IconCellComponent,
} from '../../dynamic-content/components/cells/icon-cell/icon-cell.component';
import { IdCellBinding, IdCellComponent } from '../../dynamic-content/components/cells/id-cell/id-cell.component';
import {
  StatusCellBinding,
  StatusCellComponent,
} from '../../dynamic-content/components/cells/status-cell/status-cell.component';
import {
  TextCellBinding,
  TextCellComponent,
} from '../../dynamic-content/components/cells/text-cell/text-cell.component';
import {
  TimeCellBinding,
  TimeCellComponent,
} from '../../dynamic-content/components/cells/time-cell/time-cell.component';
import {
  UrgencyClearingCellComponent,
  urgencyFilterClearing,
} from '../../dynamic-content/components/cells/urgency-cell/urgency-clearing-cell.component';
import {
  urgencyFilterTopic,
  UrgencyTopicCellComponent,
} from '../../dynamic-content/components/cells/urgency-cell/urgency-topic-cell.component';
import type { IColumnDefinition } from '../../dynamic-content/models/dynamic-content.model';
import { CellDataBinding, ColumnDefinition } from '../../dynamic-content/models/dynamic-content.model';
import { ETableHeader } from '../../dynamic-content/models/link-table-header.model';
import { EObjectType } from '../../dynamic-content/models/object-type.model';
import { EStatus } from '../../layout/status/model/status.model';
import {
  ManufacturerCellBinding,
  ManufacturerCellComponent,
} from '../../manufacturer-table/cells/manufacturer.cell/manufacturer.cell';
import {
  OpenNewTabCellBinding,
  OpenNewTabCellComponent,
} from '../../manufacturer-table/cells/open-new-tab-cell/open-new-tab.cell';
import { App } from '../../navigation/models/app';
import { Recall2FilterOverlayComponent } from '../../overlay/components/filter/recall2-filter-overlay/recall2-filter-overlay.component';
import type { IRecall2FilterParam, IRecall2FilterSelectableValue } from '../../overlay/models/filter.model';
import {
  FilterDataBinding,
  Recall2FilterDateParam,
  Recall2FilterNumberParam,
  Recall2FilterSelectableValue,
  Recall2FilterSelectParam,
  Recall2FilterTextParam,
} from '../../overlay/models/filter.model';
import { createManufacturerSelectableValues } from '../../utils/functions/functions';

const i18nPrefix = 'link.dialog.table.column';
const i18nUrgency = i18nPrefix + '.urgency';
const i18nId = i18nPrefix + '.id';
const i18nTitle = i18nPrefix + '.title';
const i18nManufacturer = i18nPrefix + '.manufacturer';
const i18nCustomer = i18nPrefix + '.customer';
const i18nEditor = i18nPrefix + '.editor';
const i18nAuthor = i18nPrefix + '.author';
const i18nCreatedAt = i18nPrefix + '.createdAt';
const i18nStartDate = i18nPrefix + '.start-date';
const i18nDueTo = i18nPrefix + '.dueTo';
const i18nStatus = i18nPrefix + '.status';
const i18nStartTimeColumn = i18nPrefix + '.start-time';
const i18nEndTimeColumn = i18nPrefix + '.end-time';
const i18nParticipants = i18nPrefix + '.participants';
const i18nCode = i18nPrefix + '.code';
const i18nCommittee = i18nPrefix + '.committee';
const i18nSessionDate = i18nPrefix + '.sessiondate';
const i18nDueDate = i18nPrefix + '.dueDate';
const i18nDecision = i18nPrefix + '.decision';

const fixRouteColumnSize = 'col-md-0-5';

const iconColumn = new ColumnDefinition(
  ETableHeader.ICON,
  'col-md-1',
  IconCellComponent,
  new IconCellBinding(['icon-i74-objekt-meldung', 'rc2-icon--data-table--notification']),
);

const idFilter = new Recall2FilterNumberParam(ETableHeader.ID, i18nId);
const idColumn = new ColumnDefinition(
  ETableHeader.ID,
  'col-md-1',
  IdCellComponent,
  new IdCellBinding('notification.id.type'),
  i18nId,
);

const startTimeColumn = new ColumnDefinition(
  ETableHeader.START_TIME,
  'col-md-1',
  TimeCellComponent,
  new TimeCellBinding('startTime'),
  i18nStartTimeColumn,
);
const endTimeColumn = new ColumnDefinition(
  ETableHeader.END_TIME,
  'col-md-1',
  TimeCellComponent,
  new TimeCellBinding('endTime'),
  i18nEndTimeColumn,
);

const titleFilter = new Recall2FilterTextParam(ETableHeader.TITLE, i18nTitle);
const titleColumn = new ColumnDefinition(
  ETableHeader.TITLE,
  'col-md-1',
  TextCellComponent,
  new TextCellBinding('title'),
  i18nTitle,
);

const editorFilter = new Recall2FilterTextParam(ETableHeader.EDITOR, i18nEditor);
const editorColumn = new ColumnDefinition(
  ETableHeader.EDITOR,
  'col-md-1',
  TextCellComponent,
  new TextCellBinding('editorName'),
  i18nEditor,
);

const authorFilter = new Recall2FilterTextParam(ETableHeader.AUTHOR, i18nAuthor);
const campaignAuthorColumn = new ColumnDefinition(
  ETableHeader.AUTHOR,
  'col-md-2',
  AuthorsFullNameCellComponent,
  new AuthorsFullNameCellBinding('authors'),
  i18nAuthor,
);

const manufacturerFilter = new Recall2FilterSelectParam(ETableHeader.MANUFACTURER, i18nManufacturer, []);
const manufacturerColumn = new ColumnDefinition(
  ETableHeader.MANUFACTURER,
  'col-md-1',
  ManufacturerCellComponent,
  new ManufacturerCellBinding('manufacturerName'),
  i18nManufacturer,
);

const customerIdFilter = new Recall2FilterTextParam(ETableHeader.CUSTOMER_ID, i18nCustomer);
const customerIdColumn = new ColumnDefinition(
  ETableHeader.CUSTOMER_ID,
  'col-md-1',
  TextCellComponent,
  new TextCellBinding('customerId'),
  i18nCustomer,
);

const participantsColumn = new ColumnDefinition(
  ETableHeader.PARTICIPANTS,
  'col-md-1',
  TextCellComponent,
  new TextCellBinding('numOfParticipants'),
  i18nParticipants,
);

const statusColumn = new ColumnDefinition(
  ETableHeader.STATUS,
  'col-md-1',
  StatusCellComponent,
  new StatusCellBinding('status'),
  i18nStatus,
);
const statusCampaignColumn = new ColumnDefinition(
  ETableHeader.STATUS,
  'col-md-1',
  StatusCellComponent,
  new StatusCellBinding('status', false, true),
  i18nStatus,
);

const createdAtFilter = new Recall2FilterDateParam(ETableHeader.CREATED_AT, i18nCreatedAt);
const createdAtColumn = new ColumnDefinition(
  ETableHeader.CREATED_AT,
  'col-md-1',
  DateCellComponent,
  new DateCellBinding('createdAt'),
  i18nCreatedAt,
);

const campaignStartDateFilter = new Recall2FilterDateParam(ETableHeader.START_DATE, i18nStartDate);
const campaignStartDateColumn = new ColumnDefinition(
  ETableHeader.START_DATE,
  'col-md-1',
  DateCellComponent,
  new DateCellBinding('startDate'),
  i18nStartDate,
);

const dueToFilter = new Recall2FilterDateParam(ETableHeader.DUE_TO, i18nDueTo);
const dueToColumn = new ColumnDefinition(
  ETableHeader.DUE_TO,
  'col-md-1',
  DueDateCellComponent,
  new DueDateCellBinding('dueTo'),
  i18nDueTo,
);

const committeeColumn = new ColumnDefinition(
  ETableHeader.COMMITTEE,
  'col-md-1-5',
  TextCellComponent,
  new TextCellBinding('committeeName'),
  i18nCommittee,
);

const codeFilter = new Recall2FilterTextParam(ETableHeader.CODE, i18nCode);
const codeColumn = new ColumnDefinition(
  ETableHeader.CODE,
  'col-md-1',
  TextCellComponent,
  new TextCellBinding('code'),
  i18nCode,
);

const sessionDateColumn = new ColumnDefinition(
  ETableHeader.SESSION_DATE,
  'col-md-1-5',
  DateCellComponent,
  new DateCellBinding('sessionDate'),
  i18nSessionDate,
);

const decisionColum = new ColumnDefinition(
  ETableHeader.DECISION,
  'col-md-1-5',
  DecisionTextCellComponent,
  new DecisionTextCellBinding('decision'),
  i18nDecision,
);

const dueDateFilter = new Recall2FilterDateParam(ETableHeader.DUE_DATE, i18nDueDate);

const routeColumn = new ColumnDefinition(
  ETableHeader.ROUTE,
  fixRouteColumnSize,
  OpenNewTabCellComponent,
  new OpenNewTabCellBinding('id', null, '', ''),
);

const agendaItemUrlPath = '/agenda-items/';

export class ColumnDefinitionHelper {
  getColumnDefinition(type: EObjectType, linkedType: EObjectType = null): IColumnDefinition[] {
    if (type === EObjectType.TOPIC || type === EObjectType.CLEARING) {
      const urlPath = type === EObjectType.TOPIC ? '/topics/' : '/clearings/';
      const urgencyCell = type === EObjectType.TOPIC ? UrgencyTopicCellComponent : UrgencyClearingCellComponent;
      return [
        new ColumnDefinition(
          ETableHeader.URGENCY_LEVEL,
          'col-md-0-5',
          urgencyCell,
          new CellDataBinding('urgencyLevel'),
          i18nUrgency,
        ),
        {
          ...idColumn,
          dataBinding: { translationKey: i18nPrefix + '.' + type.toLowerCase() + '.id' },
        },
        { ...titleColumn, columnSize: 'col-md-1-5' },
        { ...manufacturerColumn, columnSize: 'col-md-1-5' },
        { ...customerIdColumn },
        { ...editorColumn, columnSize: 'col-md-1-5' },
        { ...createdAtColumn },
        { ...dueToColumn, columnSize: 'col-md-1-5' },
        { ...statusColumn, columnSize: 'col-md-1-5' },
        {
          ...routeColumn,
          dataBinding: { ...routeColumn.dataBinding, targetApp: App.Topics, urlPath },
        },
      ];
    }
    if (type === EObjectType.CAMPAIGN) {
      return [
        {
          ...iconColumn,
          columnSize: 'col-md-0-5',
          dataBinding: {
            iconCssClasses: [
              'icon-i71-objekt-aktion',
              'rc2-icon--data-table--campaign',
              'rc2-icon--size--30',
              'rc2-color--attention',
            ],
          },
        },
        { ...codeColumn },
        { ...titleColumn },
        {
          ...manufacturerColumn,
          columnSize: 'col-md-2',
          dataBinding: { propertyName: 'manufacturers' },
        },
        { ...campaignAuthorColumn },
        { ...campaignStartDateColumn, isSortable: true },
        { ...statusCampaignColumn, columnSize: 'col-md-2', isSortable: true },
        {
          ...routeColumn,
          dataBinding: {
            ...routeColumn.dataBinding,
            targetApp: App.Campaigns,
            urlPath: '/',
          },
        },
      ];
    }

    if (type === EObjectType.AGENDA_ITEM && linkedType === EObjectType.CAMPAIGN) {
      return [
        {
          ...iconColumn,
          columnSize: 'col-md-0-5',
          dataBinding: {
            ...routeColumn.dataBinding,
            iconCssClasses: ['icon-i70-objekt-agendapunkt', ' rc2-icon--size--30', 'rc2-color--attention'],
          },
        },
        {
          ...idColumn,
          columnSize: 'col-md-1-5',
          dataBinding: { translationKey: i18nPrefix + '.' + type.toLowerCase() + '.id' },
        },
        { ...titleColumn, columnSize: 'col-md-3' },
        { ...committeeColumn },
        { ...sessionDateColumn },
        { ...decisionColum },
        { ...statusColumn, columnSize: 'col-md-1-5' },
        {
          ...routeColumn,
          dataBinding: {
            ...routeColumn.dataBinding,
            targetApp: App.Committee,
            urlPath: agendaItemUrlPath,
          },
        },
      ];
    }

    if (type === EObjectType.AGENDA_ITEM && linkedType !== EObjectType.CAMPAIGN) {
      return [
        {
          ...iconColumn,
          columnSize: 'col-md-0-5',
          dataBinding: {
            ...routeColumn.dataBinding,
            iconCssClasses: ['icon-i70-objekt-agendapunkt', ' rc2-icon--size--30', 'rc2-color--attention'],
          },
        },
        {
          ...idColumn,
          columnSize: 'col-md-1-5',
          dataBinding: { translationKey: i18nPrefix + '.' + type.toLowerCase() + '.id' },
        },
        { ...titleColumn, columnSize: 'col-md-3' },
        { ...startTimeColumn, columnSize: 'col-md-1-5' },
        { ...endTimeColumn, columnSize: 'col-md-1-5' },
        { ...participantsColumn, columnSize: 'col-md-1-5' },
        { ...statusColumn, columnSize: 'col-md-1-5' },
        {
          ...routeColumn,
          dataBinding: {
            ...routeColumn.dataBinding,
            targetApp: App.Committee,
            urlPath: agendaItemUrlPath,
          },
        },
      ];
    }
    if (type === EObjectType.NOTIFICATION) {
      return [
        { ...iconColumn, columnSize: 'col-md-0-5' },
        {
          ...idColumn,
          columnSize: 'col-md-1-5',
          dataBinding: { translationKey: i18nPrefix + '.' + type.toLowerCase() + '.id' },
        },
        { ...titleColumn, columnSize: 'col-md-3' },
        { ...editorColumn, columnSize: 'col-md-2' },
        { ...createdAtColumn, columnSize: 'col-md-1' },
        {
          ...dueToColumn,
          columnSize: 'col-md-1-5',
          dataBinding: new DueDateCellBinding('dueTo', {
            hideByStatus: { status: EStatus.LINKED, statusPropertyName: 'status' },
          }),
        },
        { ...statusColumn, columnSize: 'col-md-1-5' },
        {
          ...routeColumn,
          dataBinding: {
            ...routeColumn.dataBinding,
            targetApp: App.Preliminary,
            urlPath: '/notifications/',
          },
        },
      ];
    }
  }

  getColumnDefinitionsWithFilter(
    baseType: EObjectType = null,
    type: EObjectType,
    columnDefinitions: IColumnDefinition[] = this.getColumnDefinition(type),
  ): { columnDefinitions: IColumnDefinition[]; activeFilters: IRecall2FilterParam[] } {
    const activeFilters: IRecall2FilterParam[] = [];
    columnDefinitions = columnDefinitions.map((column: IColumnDefinition) => {
      let baseFilterProperty;
      const filter = this.getFilterDataBindingForSpecificColumn(type, column.id as ETableHeader, baseType);
      if (filter) {
        baseFilterProperty = { dynamicCellHeaderComponent: Recall2FilterOverlayComponent };
        activeFilters.push(filter);
      }
      return {
        ...column,
        ...baseFilterProperty,
        isSortable: !!filter,
        headerDataBinding: filter ? new FilterDataBinding(filter) : {},
      };
    });
    return { columnDefinitions, activeFilters };
  }

  private getFilterDataBindingForSpecificColumn(
    type: EObjectType,
    id: ETableHeader,
    baseType: EObjectType,
  ): Recall2FilterNumberParam | Recall2FilterTextParam | Recall2FilterSelectParam | Recall2FilterDateParam {
    switch (id) {
      case ETableHeader.COMMITTEE:
        return null;
      case ETableHeader.CREATED_AT:
        return createdAtFilter;
      case ETableHeader.CUSTOMER_ID:
        return customerIdFilter;
      case ETableHeader.DUE_TO:
        return dueToFilter;
      case ETableHeader.DURATION:
        return null;
      case ETableHeader.EDITOR:
        return editorFilter;
      case ETableHeader.ID:
        return idFilter;
      case ETableHeader.MANUFACTURER:
        return manufacturerFilter;
      case ETableHeader.PARTICIPANTS:
        return null;
      case ETableHeader.AUTHOR:
        return authorFilter;
      case ETableHeader.START_DATE:
        return campaignStartDateFilter;
      case ETableHeader.REPORTER:
        return null;
      case ETableHeader.STATUS:
        return new Recall2FilterSelectParam(
          ETableHeader.STATUS,
          i18nStatus,
          this.getStateSelectableValuesForSpecificObject(type, baseType),
        );
      case ETableHeader.TITLE:
        return titleFilter;
      case ETableHeader.URGENCY_LEVEL:
        return type === EObjectType.TOPIC ? urgencyFilterTopic : urgencyFilterClearing;
      case ETableHeader.DUE_DATE:
        return dueDateFilter;
      case ETableHeader.CODE:
        return codeFilter;
      default:
        return null;
    }
  }

  private getStateSelectableValuesForSpecificObject(
    type: EObjectType,
    baseType: EObjectType,
  ): Recall2FilterSelectableValue[] {
    const i18nStatusSelect = 'general.state';
    switch (type) {
      case EObjectType.CLEARING:
        return [
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.RED),
            'DRAFT',
            i18nStatusSelect + '.draft',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(false, EStateIconColor.ORANGE),
            'PROCESSING',
            i18nStatusSelect + '.processing',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(false, EStateIconColor.GREEN),
            'AWAIT_DECISION',
            i18nStatusSelect + '.await_decision',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.GREEN),
            'COMPLETED',
            i18nStatusSelect + '.completed',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.ORANGE),
            'PLANNING',
            i18nStatusSelect + '.planning',
          ),
        ];
      case EObjectType.TOPIC:
        return baseType === EObjectType.AGENDA_ITEM
          ? [
              new Recall2FilterSelectableValue(
                this.appendStateClass(true, EStateIconColor.ORANGE),
                'PLANNING',
                i18nStatusSelect + '.planning',
              ),
              new Recall2FilterSelectableValue(
                this.appendStateClass(false, EStateIconColor.GREEN),
                'AWAIT_DECISION',
                i18nStatusSelect + '.await_decision',
              ),
            ]
          : [
              new Recall2FilterSelectableValue(
                this.appendStateClass(true, EStateIconColor.RED),
                'DRAFT',
                i18nStatusSelect + '.draft',
              ),
              new Recall2FilterSelectableValue(
                this.appendStateClass(false, EStateIconColor.ORANGE),
                'PROCESSING',
                i18nStatusSelect + '.processing',
              ),
              new Recall2FilterSelectableValue(
                this.appendStateClass(false, EStateIconColor.GREEN),
                'AWAIT_DECISION',
                i18nStatusSelect + '.await_decision',
              ),
              new Recall2FilterSelectableValue(
                this.appendStateClass(true, EStateIconColor.GREEN),
                'COMPLETED',
                i18nStatusSelect + '.completed',
              ),
              new Recall2FilterSelectableValue(
                this.appendStateClass(true, EStateIconColor.ORANGE),
                'PLANNING',
                i18nStatusSelect + '.planning',
              ),
            ];
      case EObjectType.CAMPAIGN:
        return [
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.RED),
            'IN_PREPARATION',
            i18nStatusSelect + '.in_preparation',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.ORANGE),
            'RELEASED',
            i18nStatusSelect + '.released',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(false, EStateIconColor.GREEN),
            'ACTIVE',
            i18nStatusSelect + '.active',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.GREEN),
            'COMPLETED',
            i18nStatusSelect + '.completed',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(false, EStateIconColor.RED),
            'STOPPED',
            i18nStatusSelect + '.stopped',
          ),
        ];
      case EObjectType.AGENDA_ITEM:
        return [
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.RED),
            'DRAFT',
            i18nStatusSelect + '.draft',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.YELLOW),
            'IN_PROGRESS',
            i18nStatusSelect + '.in_progress',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.GREEN),
            'COMPLETED',
            i18nStatusSelect + '.completed',
          ),
        ];
      default:
        return [
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.RED),
            'DRAFT',
            i18nStatusSelect + '.draft',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.YELLOW),
            'REVIEW',
            i18nStatusSelect + '.review',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(false, EStateIconColor.YELLOW),
            'TO_REVISE_REVIEWER',
            i18nStatusSelect + '.rework.reviewer',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(false, EStateIconColor.YELLOW),
            'TO_REVISE_BRAND_OFFICE',
            i18nStatusSelect + '.rework.brandOffice',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(false, EStateIconColor.GREEN),
            'APPROVED',
            i18nStatusSelect + '.approved',
          ),
          new Recall2FilterSelectableValue(
            this.appendStateClass(true, EStateIconColor.GREEN),
            'ACCEPTED',
            i18nStatusSelect + '.accepted',
          ),
        ];
    }
  }

  public getManufacturerSelectableValues(manufacturerList: Manufacturer[]): IRecall2FilterSelectableValue[] {
    const manufacturerSelectableValues: IRecall2FilterSelectableValue[] = [];
    createManufacturerSelectableValues(manufacturerList, manufacturerSelectableValues);
    return manufacturerSelectableValues;
  }

  private appendStateClass(fill: boolean, color: EStateIconColor): string {
    const space = ' ';
    let prefix = 'icon rc2-icon--size--24' + space;
    prefix += (fill ? 'icon-i42-status' : 'icon-i43-status-2') + space;
    return prefix + 'rc2-icon--color--' + color;
  }
}

enum EStateIconColor {
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  GREY = 'grey--200-tertiary',
}
