<ng-template #table>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m"></div>
</ng-template>

<ng-template #tableWithFilters>
  <div class="ghost-skeleton ghost-skeleton--s col-md-2 rc2-mar--bottom--xl"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m"></div>
</ng-template>

<ng-template #form>
  <div class="ghost-skeleton ghost-skeleton--s col-md-4 rc2-mar--bottom--m"></div>
  <div class="rc2-mar--bottom--m rc2-display--flex">
    <div class="ghost-skeleton ghost-skeleton--s rc2-mar--right--m col-md-5"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-5"></div>
  </div>
  <div class="ghost-skeleton ghost-skeleton--s rc2-mar--bottom--m"></div>
  <div class="ghost-skeleton ghost-skeleton--s col-md-6"></div>
</ng-template>

<ng-template #formInline>
  <div class="rc2-display--flex rc2-justify--space-between">
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-1"></div>
  </div>
</ng-template>

<ng-template #overview>
  <div class="ghost-skeleton ghost-skeleton--s col-md-1 rc2-mar--bottom--s"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
</ng-template>

<ng-template #overviewWithMainFilters>
  <div class="rc2-mar--bottom--xl rc2-display--flex rc2-justify--space-between">
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-2"></div>
    <div class="ghost-skeleton ghost-skeleton--s col-md-1"></div>
  </div>
  <div class="ghost-skeleton ghost-skeleton--s col-md-1 rc2-mar--bottom--s"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
  <div class="ghost-skeleton ghost-skeleton--m rc2-mar--bottom--xxs"></div>
</ng-template>

<ng-template #activity>
  <div class="rc2-mar--bottom--xl rc2-display--flex rc2-justify--space-between">
    <div class="ghost-skeleton ghost-skeleton--xs col-md-3"></div>
    <div class="rc2-display--flex col-md-6">
      <div class="ghost-skeleton ghost-skeleton--xs col-md-6"></div>
      <div class="ghost-skeleton ghost-skeleton--xs col-md-6 rc2-mar--left--xs"></div>
    </div>
  </div>
  <div class="ghost-skeleton ghost-skeleton--xs rc2-mar--bottom--m"></div>
  <div class="ghost-skeleton ghost-skeleton--xs col-md-6 rc2-mar--bottom--m"></div>
  <div class="ghost-skeleton-separator rc2-mar--bottom--m rc2-mar--top--m"></div>
  <div class="ghost-skeleton ghost-skeleton--xs rc2-mar--bottom--m"></div>
  <div class="ghost-skeleton ghost-skeleton--xs col-md-6 rc2-mar--bottom--m"></div>
  <div class="ghost-skeleton-separator rc2-mar--bottom--m rc2-mar--top--m"></div>
  <div class="ghost-skeleton ghost-skeleton--xs rc2-mar--bottom--m"></div>
  <div class="ghost-skeleton ghost-skeleton--xs col-md-6"></div>
</ng-template>

<ng-template #options>
  <div class="rc2-mar--left--s rc2-mar--right--xxl ghost-skeleton ghost-skeleton--xs rc2-mar--bottom--xxs"></div>
  <div class="col-md-6 rc2-mar--left--s rc2-mar--right--xxl ghost-skeleton ghost-skeleton--xs rc2-mar--bottom--xxs"></div>
  <div class="rc2-mar--left--s rc2-mar--right--xxl ghost-skeleton ghost-skeleton--xs rc2-mar--bottom--xxs"></div>
  <div class="col-md-6 rc2-mar--left--s rc2-mar--right--xxl ghost-skeleton ghost-skeleton--xs rc2-mar--bottom--xxs"></div>
</ng-template>

<ng-template #button>
  <div class="ghost-skeleton ghost-skeleton--s col-md-12"></div>
</ng-template>

<ng-container [ngSwitch]="schema">
  <div *ngSwitchCase="GhostSkeletonSchema.Table">
    <ng-container *ngTemplateOutlet="table"></ng-container>
  </div>
  <div *ngSwitchCase="GhostSkeletonSchema.TableWithFilters">
    <ng-container *ngTemplateOutlet="tableWithFilters"></ng-container>
  </div>
  <div *ngSwitchCase="GhostSkeletonSchema.Form">
    <ng-container *ngTemplateOutlet="form"></ng-container>
  </div>
  <div *ngSwitchCase="GhostSkeletonSchema.FormInline">
    <ng-container *ngTemplateOutlet="formInline"></ng-container>
  </div>
  <div *ngSwitchCase="GhostSkeletonSchema.Overview">
    <ng-container *ngTemplateOutlet="overview"></ng-container>
  </div>
  <div *ngSwitchCase="GhostSkeletonSchema.OverviewWithMainFilters">
    <ng-container *ngTemplateOutlet="overviewWithMainFilters"></ng-container>
  </div>
  <div *ngSwitchCase="GhostSkeletonSchema.Activity">
    <ng-container *ngTemplateOutlet="activity"></ng-container>
  </div>
  <div *ngSwitchCase="GhostSkeletonSchema.Options">
    <ng-container *ngTemplateOutlet="options"></ng-container>
  </div>
  <div *ngSwitchCase="GhostSkeletonSchema.Button">
    <ng-container *ngTemplateOutlet="button"></ng-container>
  </div>
</ng-container>
