import { Inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import type { InvolvedUser } from './model/involved-user.model';
import type { IUser } from './model/user.model';

@Injectable({
  providedIn: 'root',
})
export abstract class UserService {
  public latestSavedUserInstance: IUser;

  get previousUrl(): string[] {
    const previousUrlFromSessionStorage = sessionStorage.getItem('previousUrl');
    if (previousUrlFromSessionStorage) {
      this._previousUrl = JSON.parse(previousUrlFromSessionStorage);
    }
    return this._previousUrl;
  }

  set previousUrl(value: string[]) {
    sessionStorage.setItem('previousUrl', JSON.stringify(value));
    this._previousUrl = value;
  }

  protected constructor(@Inject('_previousUrl') private _previousUrl: string[]) {
    this._previousUrl = _previousUrl;
  }

  abstract createUser$(vwUser: IUser): Observable<any>;
  abstract updateUser$(vwUser: IUser): Observable<any>;
  abstract createUserEditor$(vwUser: InvolvedUser): Observable<any>;
}
