import { Inject, LOCALE_ID } from '@angular/core';
import type { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import type { DownloadButtonConfig } from '../../buttons/components/recall2-button-download/models/download-button-config.model';
import type {
  ExpandableContentDefinition,
  IColumnDefinition,
  IExpandableContentDefinitionV2,
} from '../../dynamic-content/models/dynamic-content.model';
import type { IRecall2FilterParam, Recall2FilterV2Config } from '../../overlay/models/filter.model';
import type { PaginationModel } from '../../pagination/model/pagination.model';
import type { IRecall2Sort } from '../models/recall2-sort';
import { Recall2TableService } from './recall2-table.service';
import { Recall2TableFilterService } from './recall2-table-filter.service';
import { Recall2TableSortService } from './recall2-table-sort.service';

export class Recall2TableFacade<T = unknown> {
  public route: ActivatedRoute;
  public highlightQueryParamIdentifier: string;
  public highlightingClass: string;
  public startAnimationOn: string[];
  public stopAnimationOn: string[];
  public src: string;
  public paginationModel: PaginationModel;

  public expandableContentDefinition: IExpandableContentDefinitionV2 | ExpandableContentDefinition;
  public expansionQueryParamIdentifier: string;

  public activeFilterResults: IRecall2FilterParam[];
  public defaultSorting: IRecall2Sort[];
  public columnDefinitions: IColumnDefinition[];
  public moreFilterConfig: Recall2FilterV2Config[];
  public downloadConfig: DownloadButtonConfig;
  public scrollQueryParamIdentifier: string;

  protected tableService: Recall2TableService<T>;
  protected filterService: Recall2TableFilterService;
  protected sortService: Recall2TableSortService;
  protected _tableId: BehaviorSubject<string> = new BehaviorSubject('');
  sortInTable: boolean;

  public isRowExpandable = false;
  public rowExpandableOnClick = true;

  protected constructor(
    protected tableId: string,
    @Inject(LOCALE_ID) protected locale: string,
    sortInTable = false,
  ) {
    this.tableService = new Recall2TableService();
    this.filterService = new Recall2TableFilterService(this.locale);
    this.sortService = new Recall2TableSortService();
    this._tableId.next(this.tableId);
    this.sortInTable = sortInTable;
  }

  getTableService(): Recall2TableService<T> {
    return this.tableService;
  }

  isSortInTable(): boolean {
    return this.sortInTable;
  }

  getFilterService(): Recall2TableFilterService {
    return this.filterService;
  }

  getSortService(): Recall2TableSortService {
    return this.sortService;
  }

  getTableId(): BehaviorSubject<string> {
    return this._tableId;
  }

  /**
   * Check if the parameter clearfilter has been added to the url,
   * It is used to clear the filter of the table in a special cases
   * @param route
   */
  isClearFilter(route: ActivatedRoute): boolean {
    return (
      route.snapshot.queryParamMap.has('clearfilter') && route.snapshot.queryParamMap.get('clearfilter') === 'true'
    );
  }

  hasScroll(): boolean {
    return this.route?.snapshot.queryParamMap.has(this.scrollQueryParamIdentifier);
  }

  hasHighlight(): boolean {
    return this.route?.snapshot.queryParamMap.has(this.highlightQueryParamIdentifier);
  }

  hasExpansion(): boolean {
    return this.route?.snapshot.queryParamMap.has(this.expansionQueryParamIdentifier);
  }

  initHighlighting(): void {
    if (this.route.snapshot.queryParamMap.has(this.highlightQueryParamIdentifier)) {
      const highlightedObjectId = +this.route.snapshot.queryParamMap.get(this.highlightQueryParamIdentifier);
      this.setHighlightedObjectId(highlightedObjectId);
    }
    this.tableService.setHighlightingClass(this.highlightingClass);
    this.tableService.setHighlightingQueryParamId(this.highlightQueryParamIdentifier);
  }

  initExpansion(): void {
    if (this.route.snapshot.queryParamMap.has(this.expansionQueryParamIdentifier)) {
      const expandedTopicId = +this.route.snapshot.queryParamMap.get(this.expansionQueryParamIdentifier);
      this.tableService.setExpansionQueryParamId(this.expansionQueryParamIdentifier);
      this.setExpandableObjectId(expandedTopicId);
    }
  }

  initScrolling(): void {
    if (this.route.snapshot.queryParamMap.has(this.scrollQueryParamIdentifier)) {
      const scrollId = +this.route.snapshot.queryParamMap.get(this.scrollQueryParamIdentifier);

      const interval = setInterval(() => {
        const element = document.querySelector(`[scrollId="${scrollId}"]`);
        if (element) {
          element.scrollIntoView();
          clearInterval(interval);
        }
      }, 300);
      this.tableService.setScrollId(scrollId);
    }
  }

  initSpinner(): void {
    this.tableService.setSpinnerSrc(this.src);
    this.tableService.setSpinnerStartAnimationOn(this.startAnimationOn);
    this.tableService.setSpinnerStopAnimationOn(this.stopAnimationOn);
  }

  initPagination(): void {
    this.tableService.setPersistedPaginationModel(this.paginationModel);
  }

  initExpandableContendDefinition(): void {
    this.tableService.setExpandableContentDefinition(this.expandableContentDefinition, this.isRowExpandable);
  }

  initFilter(): void {
    this.filterService.setTableId(this.tableId);
    this.filterService.setActiveFilterResults(this.activeFilterResults);
  }

  initSort(): void {
    this.sortService.setTableId(this.tableId);
    this.sortService.setSortInTable(this.sortInTable);
    this.sortService.initializeSorting(this.defaultSorting);
  }

  initColumnDefinition(): void {
    this.tableService.setColumnDefinitions(this.columnDefinitions);
  }

  initDownloadConfig(): void {
    this.tableService.setDownloadButtonConfig(this.downloadConfig);
  }

  initMoreFilters(): void {
    this.filterService.setMoreFiltersConfig(this.moreFilterConfig);
  }

  private setHighlightedObjectId(highlightedId: number): void {
    const highlightedObjectIdSet = new Set<number>().add(highlightedId);
    this.tableService.setHighlightedObjectIdSet(highlightedObjectIdSet);
  }

  private setExpandableObjectId(expandedId: number): void {
    const expandedObjectIdSet = new Set<number>().add(expandedId);
    this.tableService.setExpandedObjectIdSet(expandedObjectIdSet);
  }

  getInstance(clazzName): any {
    if (clazzName.toString() === 'Recall2TableService') {
      return this.getTableService();
    }
    if (clazzName.toString() === 'Recall2TableFilterService') {
      return this.getFilterService();
    }
    if (clazzName.toString() === 'Recall2TableSortService') {
      return this.getSortService();
    }
  }
}
