<div [class.is-opened]="isOpened">
  <input [name]="property.name" [value]="property.control.value" type="hidden" />
  <div class="select-label-container" *ngIf="property.translationKey">
    <label
      [id]="property.name + '_label'"
      class="mar-bottom-erase title-label left rc2-ellipsis"
      [ngClass]="{
        'rc2-color--error': isFormSubmitted && property.control.errors,
        'title-label--readonly': property.readOnly
      }"
    >
      {{ property.translationKey + '.label' | translate }}
      <recall2-icon-required
        *ngIf="property.required"
        class="rc2-mar--left--3xs"
        [id]="property.name + '_icon_required'"
        [isDisabled]="property.readOnly"
      ></recall2-icon-required>
      <recall2-icon-help
        *ngIf="property.hasTooltip"
        [id]="property.name + '_help_icon'"
        [matTooltip]="property.translationKey + '.tooltip' | translate"
        matTooltipPosition="above"
      >
      </recall2-icon-help>
    </label>
  </div>

  <div class="wrapper wrapper--select">
    <mat-label
      class="select--arrow"
      [ngClass]="{
        'rc2-cursor--pointer': !property.control.disabled
      }"
      (click)="matSelectComponent.toggle()"
    >
      <recall2-icon-arrow-down
        *ngIf="!isOpened"
        [ngClass]="{
          'rc2-color--grey-600': !property.control.disabled,
          'rc2-color--grey-400-secondary': property.control.disabled
        }"
        class="rc2-font--size--18"
      >
      </recall2-icon-arrow-down>
      <recall2-icon-arrow-up
        *ngIf="isOpened"
        [ngClass]="{
          'rc2-color--primary': !property.control.disabled,
          'rc2-color--grey-400-secondary': property.control.disabled
        }"
        class="rc2-font--size--18"
      >
      </recall2-icon-arrow-up>
    </mat-label>
    <mat-select
      #matSelectComponent
      [ngClass]="{
        'error-input': showErrors && !property.showErrorOnTooltip,
        'show-error-input-on-tooltip': showErrors && property.showErrorOnTooltip,
        'is-opened': isOpened
      }"
      [formControl]="property.control"
      [id]="property.name"
      disableRipple
      disableOptionCentering
      [multiple]="isMultiple"
      [placeholder]="
        property.placeholderKey ?? (property.translationKey ? property.translationKey + '.placeholder' : null)
          | translate
      "
      panelClass="select-panel-overlay rc2-font--regular rc2-font--size--14 rc2-color--grey-1000"
      (selectionChange)="onSelectionChange($event)"
      (openedChange)="onOpenedChange($event)"
    >
      <mat-select-trigger *ngIf="itemTemplate">
        <ng-template
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{ $implicit: { key: property.control.value, value: property.control.value } }"
        >
        </ng-template>
      </mat-select-trigger>

      <mat-select-trigger *ngIf="!itemTemplate">
        <div class="default-trigger">
          <span [class.title-label--readonly]="property.readOnly" class="trigger-text">
            {{ readOnlyValue }}
          </span>
        </div>
      </mat-select-trigger>
      <mat-option
        *ngIf="isSearchable && property.optionsList.length > 0"
        class="searchable"
        [attr.data-cy]="'option-searchable'"
      >
        <input
          [attr.data-cy]="'search-field'"
          type="text"
          #searchInput
          [placeholder]="'common.search' | translate"
          (keydown)="onKeyDown($event)"
          (keyup)="onSearchChange($event)"
          (click)="$event.stopPropagation()"
        />
      </mat-option>

      <mat-option *ngIf="showOptionSelectAll" class="option-select-all" [attr.data-cy]="'option-select-all'">
        <mat-checkbox
          data-cy="recall2-select-check-all"
          class="mat-option select-panel-option recall2-multi-select-option-all {{ customOverlayOptionClass }}"
          [indeterminate]="isAllSelectionIndeterminate"
          [checked]="isAllSelectionChecked"
          (click)="$event.stopPropagation()"
          (change)="onToggleSelectAll($event)"
        >
          {{ translationKeySelectAll | translate }}
        </mat-checkbox>
      </mat-option>

      <mat-option
        *ngFor="let option of property.optionsList; trackBy: trackByFn"
        [attr.data-cy]="'option-' + option.value"
        [value]="option.value"
        [matTooltip]="option?.tooltipKey ? (option.tooltipKey | translate | markEmpty) : null"
        matTooltipPosition="right"
        matTooltipClass="rc2-tooltip"
        [class.recall2-multi-select-option]="isMultiple"
        [class.option-display--none]="isSearchable && !filteredOptionList.includes(option)"
        class="select-panel-option rc2-font--body--m {{ customOverlayOptionClass }}"
      >
        <ng-container *ngIf="itemTemplate; else plainOption">
          <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{ $implicit: option }">
          </ng-template>

          <recall2-svg-icon
            *ngIf="!isMultiple && option.value === property.control.value && !includedValues?.length"
            name="check-bold"
            size="medium"
            class="rc2-icon--color--primary"
          >
          </recall2-svg-icon>

          <recall2-svg-icon
            *ngIf="!isMultiple && (includedValues | includes: option.value)"
            name="check-bold"
            size="medium"
            class="rc2-icon--color--grey--500"
          >
          </recall2-svg-icon>
        </ng-container>
        <ng-template #plainOption>
          <span>
            {{ option?.key | translate | markEmpty }}
          </span>
          <recall2-svg-icon
            *ngIf="!isMultiple && option.value === property.control.value"
            name="check-bold"
            size="medium"
            class="rc2-icon--color--primary"
          >
          </recall2-svg-icon>
        </ng-template>
      </mat-option>

      <mat-option
        *ngIf="filteredOptionList.length === 0"
        [attr.data-cy]="'option-no-entries-found'"
        class="select-no-entries-found no-pointer-events"
      >
        <span class="rc2-color--grey-500">
          {{ 'common.no-entries-found' | translate }}
        </span>
      </mat-option>
    </mat-select>
    <div matSuffix class="clear-wrapper" *ngIf="showClearIcon">
      <recall2-svg-icon
        class="rc2-icon--cursor--pointer clear-icon"
        data-cy="autocomplete-clear-icon"
        name="clear"
        size="medium"
        (click)="onDelete($event)"
      >
      </recall2-svg-icon>
    </div>
  </div>

  <ng-container *ngIf="showErrors">
    <div *ngIf="!property.showErrorOnTooltip" [id]="property.name + '_error_feedback'" class="error-message-with-label">
      <div *ngIf="property.control.errors?.required" [id]="property.name + '_error_feedback_message'">
        <recall2-icon-invalid></recall2-icon-invalid>
        {{ 'form.error.generic.required' | translate }}
      </div>
    </div>

    <div *ngIf="property.showErrorOnTooltip" class="show-error-input-on-tooltip-warning-icon">
      <recall2-svg-icon
        matTooltipPosition="above"
        matTooltip="{{ 'form.error.generic.required' | translate }}"
        matTooltipClass="rc2-tooltip-error"
        name="warning"
        size="medium"
        class="rc2-color--danger"
      ></recall2-svg-icon>
    </div>
  </ng-container>
</div>
