<recall2-overlay
  [overlayTemplateType]="overlayNested"
  [itemsCount]="itemsCount"
  [position]="attachmentsOverlayPosition"
  [isSpinnerShowing]="attachmentsQuery.selectLoading() | async"
  (mouseenter)="onAttachmentCountEnter()"
>
  <app-table-attachments-list
    [files]="files || (attachmentsQuery.rowItemAttachments$ | async)"
    (fileClicked)="downloadSingleFile($event)"
    (allFilesOptionClicked)="downloadAllFiles()"
  >
  </app-table-attachments-list>

</recall2-overlay>
