<div
  *ngIf="data[optionPropertyName] && data[propertyName]"
  class="rc2-font--body--m rc2-color--grey-900"
  [ngClass]="{
            'rc2-object-table--ellipsis': type === tableTypes.Object,
            'rc2-word-break--all': type === tableTypes.Context
            }"
  [title]="type === tableTypes.Object ? data[propertyName]:''"
>
  {{'agendaItem.decision.' + data[propertyName] | translate}}:
  {{'agendaItem.decisionOption.' + data[optionPropertyName] | translate}}
</div>
<div
  *ngIf="data[propertyName] && !data[optionPropertyName]"
  class="rc2-font--body--m rc2-color--grey-900"
  [ngClass]="{
            'rc2-object-table--ellipsis': type === tableTypes.Object,
            'rc2-word-break--all': type === tableTypes.Context
            }"
  [title]="type === tableTypes.Object ? data[propertyName]:''"
>
  {{'agendaItem.decision.' + data[propertyName] | translate}}
</div>
