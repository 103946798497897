import type { DoCheck } from '@angular/core';
import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Params } from '@angular/router';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';

import type { MenuUrl } from '../../../navbar/models/menu-url.model';
import { App } from '../../models/app';
import { AppsService } from '../../services/apps.service';

@Directive({
  selector: '[recall2AppsLink]',
  standalone: true,
})
export class AppsLinkDirective implements DoCheck {
  @Input() targetApp: App;
  @Input() urlPath: string;
  @Input() queryParams: Params;
  @Input() openInNewTab = false;
  @Input() ignoreActive?: MenuUrl[];
  @Input() forceActive?: MenuUrl[];
  @Input() noNavigate?: boolean;
  @Input() beforeRedirectFn: () => Observable<unknown>;

  private defaultOnBeforeRedirect = (): Observable<void> => of(null);

  constructor(
    private element: ElementRef,
    private appsService: AppsService,
  ) {}

  @HostListener('click', ['$event'])
  public async onClick(event: MouseEvent): Promise<boolean> {
    if (this.noNavigate) {
      return;
    }
    await (this.beforeRedirectFn || this.defaultOnBeforeRedirect)().toPromise();

    let openInNewTab = this.openInNewTab;

    if (event.metaKey || event.ctrlKey) {
      openInNewTab = true;
    }

    return this.appsService.navigate({
      app: this.targetApp,
      path: this.urlPath,
      queryParams: this.queryParams,
      openNewTab: openInNewTab,
      navigationExtras: {
        skipLocationChange: true,
      },
    });
  }

  ngDoCheck(): void {
    if (this.checkIgnoreActive()) {
      this.setActiveClass(false);
      return;
    }

    if (this.checkForceActive()) {
      this.setActiveClass(true);
      return;
    }

    this.targetApp
      ? this.setActiveClass(this.appsService.isCurrentUrlAppChildRoute(this.targetApp, this.urlPath))
      : this.setActiveClass(true);
  }

  private setActiveClass(active: boolean): void {
    active ? this.element.nativeElement.classList.add('active') : this.element.nativeElement.classList.remove('active');
  }

  private checkIgnoreActive(): boolean {
    return (
      Array.isArray(this.ignoreActive) &&
      this.ignoreActive.some(url => this.appsService.isCurrentUrlAppChildRoute(url.targetApp, url.urlPath))
    );
  }

  private checkForceActive(): boolean {
    return (
      Array.isArray(this.forceActive) &&
      this.forceActive.some(url => this.appsService.isCurrentUrlAppChildRoute(url.targetApp, url.urlPath))
    );
  }
}
