import { BrandsId } from '../models/brands-id.model';

const BrandMappings = {
  NUTZFAHRZEUGE: BrandsId.VOLKSWAGEN_NUTZFAHRZEUGE,
  DUCATI: BrandsId.DUCATI,
  SCANIA: BrandsId.SCANIA,
  BUGATTI: BrandsId.BUGATTI,
  LAMBORGHINI: BrandsId.LAMBORGHINI,
  VW: BrandsId.VOLKSWAGEN_PKW,
  VOLKSWAGEN: BrandsId.VOLKSWAGEN_PKW,
  AUDI: BrandsId.AUDI,
  BENTLEY: BrandsId.BENTLEY,
  PORSCHE: BrandsId.PORSCHE,
  SEAT: BrandsId.SEAT,
  SKODA: BrandsId.SKODA,
  ŠKODA: BrandsId.SKODA,
  MAN: BrandsId.MAN,
} as const;

export const findBrandIdByManufacturerName = (manufacturerName: string): BrandsId => {
  if (!manufacturerName) {
    return;
  }

  const manufacturerNameToCheck = manufacturerName.toUpperCase();

  for (const keyword of Object.keys(BrandMappings)) {
    if (manufacturerNameToCheck.includes(keyword)) {
      return BrandMappings[keyword];
    }
  }
};
