import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2IconStatusComponent, Recall2IconStatusUnfilledComponent } from '../../../icons';
import { STATUS_SPECS } from '../../injection-tokens';
import { ObjectType } from '../../models/object-event';
import type { StatusColor, StatusComponentSpec } from '../../models/object-status';
import { ObjectTypeStatusSpec } from '../../models/object-status';

@Component({
  selector: 'recall2-object-status',
  templateUrl: './object-status.component.html',
  styleUrls: ['./object-status.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    NgSwitch,
    NgSwitchDefault,
    NgSwitchCase,
    TranslateModule,
    Recall2IconStatusComponent,
    Recall2IconStatusUnfilledComponent,
  ],
})
export class ObjectStatusComponent {
  colorToClass: Record<StatusColor, string> = {
    GREEN: 'status-green',
    GREY: 'status-grey',
    RED: 'status-red',
    YELLOW: 'status-yellow',
  };

  @Input() status: string;
  @Input() objectType: ObjectType;

  constructor(@Inject(STATUS_SPECS) public spec: ObjectTypeStatusSpec<ObjectType>) {}

  get statusSpec(): StatusComponentSpec {
    return this.spec[this.objectType] || {};
  }

  getColorClass(color: StatusColor): string {
    return this.colorToClass[color];
  }
}
