import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from './recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-topic-urgency-one',
  templateUrl: './recall2-icon-base/recall2-icon-base.component.html',
  standalone: true,
})
export class Recall2IconTopicUrgencyOneComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i106-topic-urgency-1');
  }
}
