import { Injectable } from '@angular/core';
import type { UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { KnownUserProvider } from '@recall2/ui/user-flow';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable()
export class UnknownUserGuard  {
  constructor(
    private knownUserProvider: KnownUserProvider,
    private router: Router,
  ) {}

  canActivate = (): Observable<boolean | UrlTree> =>
    this.knownUserProvider.isKnownUser().pipe(
      map(user => user.isKnown),
      map(isKnown => (isKnown ? true : this.router.parseUrl(environment.unknownUserUrl.path))),
    );
}
