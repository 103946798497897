import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import type { AfterContentInit } from '@angular/core';
import { Component, ContentChild, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { SVGIconComponent } from '@recall2/icons';

import { Recall2SVGIconButtonDirective } from '../../directives/recall2-svg-icon-button/recall2-svg-icon-button.directive';
import { Recall2ButtonBaseComponent } from '../../recall2-button-base.component';

@Component({
  selector: 'recall2-button-tertiary',
  templateUrl: './recall2-button-tertiary.component.html',
  styleUrls: ['./recall2-button-tertiary.component.scss', '../../recall2-button-base.component.scss'],
  standalone: true,
  imports: [Recall2ButtonBaseComponent, Recall2SVGIconButtonDirective, NgClass, NgIf, NgTemplateOutlet],
})
export class Recall2ButtonTertiaryComponent extends Recall2ButtonBaseComponent implements AfterContentInit {
  SVGIconComponent = SVGIconComponent;
  isTextPresent = false;
  @ContentChild(Recall2SVGIconButtonDirective, { read: TemplateRef }) svgIcon: TemplateRef<SVGIconComponent>;
  @ViewChild('textContent', { read: ElementRef, static: true }) textContent: ElementRef;

  ngAfterContentInit(): void {
    this.isTextPresent = !!this.textContent.nativeElement.textContent;
  }
}
