<div class="error-background">
  <recall2-icon-warning class="rc2-font--size--30">
  </recall2-icon-warning>
  <span class="generic-error-message">
    {{ errorMessageKey | translate: dynamicValues }}
  </span>
  <recall2-icon-unselect
    class="rc2-font--size--30"
    (click)="closeModal()"
  >
  </recall2-icon-unselect>
</div>
