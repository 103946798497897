import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-calendar',
  templateUrl: './recall2-icon-calendar.component.html',
  standalone: true,
})
export class Recall2IconCalendarComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i97-calendar');
  }
}
