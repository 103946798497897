export const MAX_FILE_AMOUNT = 10;
export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes
export const ALLOWED_EXTENSIONS = [
  '.txt',
  '.doc',
  '.docx',
  '.csv',
  '.xls',
  '.xlsx',
  '.prn',
  '.pdf',
  '.ppt',
  '.pptx',
  '.jpg',
  '.jpeg',
  '.gif',
  '.png',
  '.tiff',
  '.bmp',
];

export interface FileErrorMessage {
  type: ErrorType;
  filename?: string;
}

export type ErrorType =
  | 'max-file-amount-exceeded'
  | 'virus-detected'
  | 'non-supported-type'
  | 'max-file-size-exceeded'
  | 'file-empty';

export const getValidFiles = (files: File[], allowedExtensions: string[] = ALLOWED_EXTENSIONS): File[] =>
  files.filter(file => !getErrorForFile(file, allowedExtensions));

export const getErrorMessages = (files: File[], allowedExtensions: string[] = ALLOWED_EXTENSIONS): FileErrorMessage[] =>
  files.map(file => getErrorForFile(file, allowedExtensions)).filter(Boolean);

export const getErrorForFile = (file: File, allowedExtensions: string[]): FileErrorMessage | undefined => {
  const extension = fileExtension(file);
  if (!allowedExtensions.some(currentFileExtension => currentFileExtension.includes(extension))) {
    return getExtensionError(file);
  }
  if (file.size === 0) {
    return { type: 'file-empty', filename: file.name };
  }
  if (file.size > MAX_FILE_SIZE) {
    return { type: 'max-file-size-exceeded', filename: file.name };
  }
  return undefined;
};

export const getMaxFileAmountError = (): FileErrorMessage => ({ type: 'max-file-amount-exceeded' });

export const getExtensionError = (file: File): FileErrorMessage => ({
  type: 'non-supported-type',
  filename: file.name,
});

export const toVirusDetectedError = (name: string): FileErrorMessage =>
  ({ type: 'virus-detected', filename: name }) as FileErrorMessage;

export const fileExtension = (file: File): string => file.name.split('.').pop().toLowerCase();
