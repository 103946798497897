import { AsyncPipe } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs';

import type { AttachmentFile } from '../../models/attachment-file.model';
import { AttachmentService } from '../../services/attachment.service';
import { AttachmentComponent } from '../attachment/attachment.component';

@Component({
  selector: 'recall2-attachment-wrapper',
  templateUrl: './attachment-wrapper.component.html',
  standalone: true,
  imports: [AsyncPipe, AttachmentComponent],
})
export class AttachmentWrapperComponent implements OnInit {
  files$: Observable<AttachmentFile[]>;
  uploadInProgress$: Observable<boolean>;

  @Input()
  set objectId(value: number) {
    if (value) {
      this.service.selectObject(value);
    }
  }

  constructor(private service: AttachmentService) {}

  ngOnInit(): void {
    this.files$ = this.service.files$;
    this.uploadInProgress$ = this.service.uploadInProgress$;
  }

  onNewFiles(files: File[]): void {
    this.service.uploadTemporary(files);
    this.uploadInProgress$
      .pipe(
        filter(uploading => !uploading),
        first(),
        switchMap(() => this.service.save()),
        first(),
      )
      .subscribe();
  }

  onDelete(file: AttachmentFile): void {
    this.service.delete(file);
  }

  onDownload(file: AttachmentFile): void {
    this.service.download(file);
  }

  onDownloadAll(): void {
    this.service.downloadAll();
  }
}
