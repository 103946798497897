import type { ErrorActions } from './errorhandling.actions';
import { EErrorActions } from './errorhandling.actions';
import type { IErrorState } from './errorhandling.state';
import { initialErrorState } from './errorhandling.state';

export function errorReducer(state = initialErrorState, action: ErrorActions): IErrorState {
  switch (action.type) {
    case EErrorActions.ShowErrorPopup:
      return {
        ...state,
        errorMessageKey: action.errorMessageKey,
      };
    case EErrorActions.HideErrorPopup:
      return {
        ...state,
        errorMessageKey: undefined,
        errorResponse: undefined,
      };
    default:
      return state;
  }
}
