<form [formGroup]="formGroup">
  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-8">
      <recall2-textarea
        id="notification-form-vehicle-affected"
        [property]="fieldProperties[VehicleFields.VehiclesAffected]"
        [isFormSubmitted]=""
        [minRows]="minRows"
      ></recall2-textarea>
    </div>
  </div>

  <editable-input-table
    [initialData]="currentNotification.prodDateRangesAffected"
    [keys]="prodDateRangesAffectedKeys"
    (tableDataChange)="onProductionPeriodChange($event)"
    [translations]="tableTranslationsProductionPeriod"
    id="productionPeriodTable"
  >
  </editable-input-table>

  <editable-input-table
    [initialData]="currentNotification.vehicleRange"
    [keys]="vehicleRangeKeys"
    (tableDataChange)="onVehicleNumberRangeChange($event)"
    [translations]="tableTranslationsVehicleNumberRange"
    id="vehicleNumberRangeTable"
  >
  </editable-input-table>

  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-8">
      <recall2-input-text
        id="notification-form-vehicle-affected-count"
        [property]="fieldProperties[VehicleFields.AffectedVehiclesCount]"
        [isFormSubmitted]=""
      ></recall2-input-text>
    </div>
  </div>
  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-8">
      <recall2-textarea
        id="notification-form-vehicle-markets-and-regions"
        [property]="fieldProperties[VehicleFields.MarketsAndRegions]"
        [isFormSubmitted]=""
        [minRows]="minRows"
      ></recall2-textarea>
    </div>
  </div>
  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-8 rc2-pad--bottom--4xl">
      <recall2-textarea
        id="notification-form-vehicle-comment"
        [property]="fieldProperties[VehicleFields.CommentVehicles]"
        [isFormSubmitted]=""
        [minRows]="minRows"
      ></recall2-textarea>
    </div>
  </div>
</form>
