<div
  *ngFor="let item of itemsList"
  class="object-overlay rc2-mar--no rc2-align-items--center rc2-overlay--items"
>
  <div
    class="object-overlay__icon rc2-pad--no"
    [ngSwitch]="getOr(item,'type',iconType)"
  >
    <recall2-icon-objekt-meldung
      *ngSwitchCase="iconTypes.NOTIFICATION"
      id="iconNotification"
      [rc2IconStyles]="'rc2-icon--data-table--notification rc2-overflow--hidden'"
    ></recall2-icon-objekt-meldung>

    <recall2-icon-objekt-aktion
      data-cy="campaign-action-icon"
      *ngSwitchCase="iconTypes.CAMPAIGN"
      id="iconCampaing"
      [rc2IconStyles]="'rc2-icon--size--26 rc2-icon--data-table--campaigns rc2-color--attention'"
    ></recall2-icon-objekt-aktion>
    <ng-container *ngSwitchCase="iconTypes.TOPIC">
      <ng-container [ngSwitch]="getUrgencyLevel(item.urgencyLevel)">
        <recall2-icon-objekt-thema
          class="rc2-icon--size--26 rc2-color--danger"
          *ngSwitchCase="level.DEFAULT"
        ></recall2-icon-objekt-thema>
        <recall2-icon-topic-urgency-one
          class="rc2-icon--size--26 rc2-color--danger"
          *ngSwitchCase="level.SECURITY"
        ></recall2-icon-topic-urgency-one>
        <recall2-icon-topic-urgency-two
          class="rc2-icon--size--26 rc2-color--attention"
          *ngSwitchCase="level.IMAGE"
        ></recall2-icon-topic-urgency-two>
        <recall2-icon-topic-urgency-three
          class="rc2-icon--size--26 rc2-color--grey-900"
          *ngSwitchCase="level.QUALITY"
        ></recall2-icon-topic-urgency-three>
        <recall2-icon-topic-urgency-four
          class="rc2-icon--size--26 rc2-color--grey-400-secondary"
          *ngSwitchCase="level.LAW"
        ></recall2-icon-topic-urgency-four>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="iconTypes.CLEARING">
      <ng-container [ngSwitch]="getUrgencyLevel(item.urgencyLevel)">
        <recall2-icon-objekt-clearing
          class="rc2-icon--size--26 rc2-color--danger"
          *ngSwitchCase="level.DEFAULT"
        ></recall2-icon-objekt-clearing>
        <recall2-icon-urgency-one
          class="rc2-icon--size--26 rc2-color--danger"
          *ngSwitchCase="level.SECURITY"
        ></recall2-icon-urgency-one>
        <recall2-icon-urgency-two
          class="rc2-icon--size--26 rc2-color--attention"
          *ngSwitchCase="level.IMAGE"
        ></recall2-icon-urgency-two>
        <recall2-icon-urgency-three
          class="rc2-icon--size--26 rc2-color--grey-900"
          *ngSwitchCase="level.QUALITY"
        ></recall2-icon-urgency-three>
        <recall2-icon-urgency-four
          class="rc2-icon--size--26 rc2-color--grey-400-secondary"
          *ngSwitchCase="level.LAW"
        ></recall2-icon-urgency-four>
      </ng-container>
    </ng-container>

    <recall2-icon-objekt-agendapunkt
      *ngSwitchCase="iconTypes.AGENDA_ITEM"
      id="iconAgenda"
      [rc2IconStyles]="'rc2-icon--data-table--agendaitem rc2-overflow--hidden rc2-color--attention'"
    ></recall2-icon-objekt-agendapunkt>

    <recall2-icon-objekt-sitzung
      *ngSwitchCase="iconTypes.MEETING"
      id="iconAgenda"
      [rc2IconStyles]="'rc2-icon--size--26 rc2-icon--data-table--meeting rc2-overflow--hidden rc2-color--attention'"
    ></recall2-icon-objekt-sitzung>
  </div>
  <div
    class="object-overlay__identifier rc2-pad--no rc2-mar--left--xxs rc2-font--body--s"
    [ngSwitch]="getOr(item,'type',iconType)"
  >
    <div
      id="objectNotification"
      *ngSwitchCase="iconTypes.NOTIFICATION"
    >
      {{ 'overlay.notification' | translate | uppercase }}
    </div>
    <div
      id="objectTopic"
      *ngSwitchCase="iconTypes.TOPIC"
    >
      {{ 'overlay.topic' | translate | uppercase }}
    </div>
    <div
      id="objectCampaign"
      *ngSwitchCase="iconTypes.CAMPAIGN"
    >
      {{ 'overlay.campaign' | translate | uppercase }}
    </div>
    <div
      id="objectClearing"
      *ngSwitchCase="iconTypes.CLEARING"
    >
      {{ 'overlay.clearing' | translate | uppercase }}
    </div>
    <div
      id="objectAgenda"
      *ngSwitchCase="iconTypes.AGENDA_ITEM"
    >
      {{ 'overlay.agendaitem' | translate | uppercase }}
    </div>
    <div
      id="objectMeeting"
      *ngSwitchCase="iconTypes.MEETING"
    >
      {{ 'overlay.meeting' | translate | uppercase }}
    </div>
    <div
      class="rc2-pad--no rc2-font--body--xs rc2-color--grey-600 rc2-ellipsis"
      data-testid="linked-object-id"
    >
      {{ item?.objectId }}
    </div>
  </div>
  <div
    class="object-overlay__title rc2-pad--no  rc2-color--grey-900 rc2-text-align--left rc2-ellipsis rc2-font--body--xs"
    data-testid="linked-object-title"
  >
    {{ item?.title }}
  </div>
  <div *ngIf="item.targetApp?.id !== CAMPAIGNS; else linkCampaigns"
    recall2AppsLink
    [targetApp]="getOr(item,'targetApp',targetApp)"
    [urlPath]="getOr(item,'urlPath',urlPath)"
    [queryParams]="{ expand: item?.id, scroll: item?.id, clearfilter: true }"
    [openInNewTab]="openInNewTab"
    data-testid="link-to-object-container"
    >
    <recall2-icon-open-tab
      [rc2IconStyles]="'rc2-color--primary rc2-overflow--hidden rc2-icon--data-table'"
      class="object-overlay__link rc2-pad--no rc2-mar--left--xs rc2-overflow--hidden rc2-cursor--pointer"
    ></recall2-icon-open-tab>
  </div>
  <ng-template #linkCampaigns>
    <ng-container>
      <div
        *ngIf="item.accessLevel !== EAccessLevel.NONE; else noLink"
        recall2AppsLink
        [targetApp]="getOr(item,'targetApp',targetApp)"
        [urlPath]="item.accessLevel === EAccessLevel.READ ?  item.urlPath + item.id + VIEW_ONLY : item.urlPath + item.id"
        [openInNewTab]="openInNewTab"
        data-testid="link-to-object-container"
      >
        <recall2-icon-open-tab
          [rc2IconStyles]="'rc2-color--primary rc2-overflow--hidden rc2-icon--data-table'"
          class="object-overlay__link rc2-pad--no rc2-mar--left--xs rc2-overflow--hidden rc2-cursor--pointer"
        ></recall2-icon-open-tab>
      </div>
    </ng-container>
  </ng-template>
  <ng-template #noLink>
    <ng-container>
      <recall2-icon-open-tab
        [rc2IconStyles]="'rc2-color--grey-400-secondary rc2-overflow--hidden rc2-icon--data-table'"
        class="object-overlay__link rc2-pad--no rc2-mar--left--xs rc2-overflow--hidden"
      ></recall2-icon-open-tab>
    </ng-container>
  </ng-template>
</div>
