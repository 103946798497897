<label class="mar-bottom-erase left checkbox-input-label">
  <div *ngIf="showIconStatus" class="icon-status">
    <recall2-icon-status
      *ngIf="!property.iconUnfilled"
      data-cy="filter-checkbox-filled-icon"
      [rc2IconStyles]="property.iconStyles"
    ></recall2-icon-status>
    <recall2-icon-status-unfilled
      *ngIf="property.iconUnfilled"
      data-cy="filter-checkbox-unfilled-icon"
      [rc2IconStyles]="property.iconStyles"
    ></recall2-icon-status-unfilled>
  </div>

  <div #content>
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="!content.children.length">
    <div class="property rc2-font--regular" *ngIf="property.translationKey; else noTranslationKey">
      {{ property.translationKey | translate }}
    </div>
    <ng-template #noTranslationKey>
      <div class="property rc2-font--regular">{{ property.name }}</div>
    </ng-template>
  </ng-container>

  <recall2-icon-select [rc2IconStyles]="'rc2-icon--color--white rc2-icon--size--24 rc2-pad--no'"></recall2-icon-select>

  <input
    type="checkbox"
    name="{{ property.name }}"
    [formControl]="property.control"
    data-cy="filter-checkbox-input"
    (change)="change()"
  />

  <div
    class="checkmark"
    data-cy="filter-checkbox-checkmark"
    [ngClass]="{ 'checkmark--checked': property.control.value }"
  ></div>
</label>
