import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AttachmentWrapperComponent } from '@recall2/ui/attachment';
import { Recall2CommentsAndHistoryComponent } from '@recall2/ui/comments-history';

export enum DisplayedComponent {
  History = 'HISTORY',
  Attachment = 'ATTACHMENT',
}
export type ObjectType = 'NOTIFICATION';

@Component({
  selector: 'slider-content',
  templateUrl: './slider-content.component.html',
  styleUrls: ['./slider-content.component.scss'],
  standalone: true,
  imports: [AttachmentWrapperComponent, Recall2CommentsAndHistoryComponent, NgIf],
})
export class SliderContentComponent {
  @Input() objectId: number;
  @Input() display: DisplayedComponent;

  readonly displayedComponent = DisplayedComponent;
  readonly objectType: ObjectType = 'NOTIFICATION';
}
