import type { EnvironmentProviders } from '@angular/core';
import { makeEnvironmentProviders } from '@angular/core';

import { COMMENTS_URL_BUILDER, HISTORY_URL_BUILDER, STATUS_SPECS } from './injection-tokens';
import type { ObjectType } from './models/object-event';
import type { ObjectTypeStatusSpec } from './models/object-status';
import type { CommentsUrlBuilder, HistoryUrlBuilder } from './models/url-builders';

export interface ProvideCommentsHistoryOptions<K extends ObjectType> {
  /**
   * __Specification for the status component.__
   *
   * For each used ObjectType, and for each corresponding
   * status, specify the way the status component will
   * look.
   *
   * @example
   *
   * ```
   * {
   *  TOPIC: {
   *    DRAFT: {
   *      iconType: 'FILLED',
   *      translationKey: 'history.status-change.DRAFT',
   *      color: 'GREY'
   *    },
   *    PENDING: {
   *      iconType: 'HOLLOW',
   *      translationKey: 'history.status-change.PENDING',
   *      color: 'YELLOW'
   *    },
   *  }
   * }
   * ```
   */
  statusComponentSpec: ObjectTypeStatusSpec<K>;
  /**
   * __Builder function for the history API URL__
   *
   * @example
   * ```
   * (objectType, objectId) => `/campaigns-core/api/${objectType}/${objectId}/history`
   * ```
   */
  historyUrlBuilder: HistoryUrlBuilder;
  /**
   * __Builder function for the comments API URL__
   *
   * @example
   * ```
   * (objectType, objectId) => `/campaigns-core/api/${objectType}/${objectId}/comments`
   * ```
   */
  commentsUrlBuilder: CommentsUrlBuilder;
}

export function provideRecall2CommentsHistory(options: ProvideCommentsHistoryOptions<string>): EnvironmentProviders[] {
  const providers: EnvironmentProviders[] = [
    makeEnvironmentProviders([
      { provide: STATUS_SPECS, useValue: options.statusComponentSpec },
      { provide: HISTORY_URL_BUILDER, useValue: options.historyUrlBuilder },
      { provide: COMMENTS_URL_BUILDER, useValue: options.commentsUrlBuilder },
    ]),
  ];

  return providers;
}
