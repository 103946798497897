import type { IRecall2FilterSelectableValue } from '@recall2/ui/overlay';
import {
  Recall2FilterDateParam,
  Recall2FilterNumberParam,
  Recall2FilterSelectableValue,
  Recall2FilterSelectParam,
  Recall2FilterTextParam,
} from '@recall2/ui/overlay';

import { ETableHeader } from './table-header.enum';

const iconSize24 = 'rc2-icon--size--24';

const iconStatus = 'icon icon-i42-status';
const iconStatus2 = 'icon icon-i43-status-2';

const iconColorOrange = 'rc2-icon--color--orange';
const iconColorGreen = 'rc2-icon--color--green';
const iconColorGray = 'rc2-icon--color--grey';

export const selectableValues: IRecall2FilterSelectableValue[] = [
  new Recall2FilterSelectableValue(
    `${iconStatus} rc2-icon--color--red ${iconSize24}`,
    'DRAFT',
    'notification.status.draft',
  ),
  new Recall2FilterSelectableValue(
    `${iconStatus} ${iconColorOrange} ${iconSize24}`,
    'REVIEW',
    'notification.status.review',
  ),
  new Recall2FilterSelectableValue(
    `${iconStatus2} ${iconColorOrange} ${iconSize24}`,
    'TO_REVISE_REVIEWER',
    'notification.status.rework.reviewer',
  ),
  new Recall2FilterSelectableValue(
    `${iconStatus2} ${iconColorGreen} ${iconSize24}`,
    'APPROVED',
    'notification.status.approved',
  ),
  new Recall2FilterSelectableValue(
    `${iconStatus2} ${iconColorOrange} ${iconSize24}`,
    'TO_REVISE_BRAND_OFFICE',
    'notification.status.rework.brandOffice',
  ),
  new Recall2FilterSelectableValue(
    `${iconStatus} ${iconColorGreen} ${iconSize24}`,
    'ACCEPTED',
    'notification.status.accepted',
  ),
  new Recall2FilterSelectableValue(
    `${iconStatus} ${iconColorGreen} ${iconSize24}`,
    'LINKED',
    'notification.status.linked',
  ),
  new Recall2FilterSelectableValue(
    `${iconStatus} ${iconColorGray} ${iconSize24}`,
    'DISCARDED',
    'notification.status.discarded',
  ),
];

export const idFilterResult = new Recall2FilterNumberParam(ETableHeader.ID, 'notifications.id');
export const titleFilterResult = new Recall2FilterTextParam(ETableHeader.TITLE, 'notification.title');
export const reporterFilterResult = new Recall2FilterTextParam(ETableHeader.REPORTER, 'notification.reporter');
export const editorFilterResult = new Recall2FilterTextParam(ETableHeader.EDITOR, 'notification.activeEditor');
export const receivedDateFilterResult = new Recall2FilterDateParam(
  ETableHeader.RECEIVED_DATE,
  'notifications.received',
);
export const dueToFilterResult = new Recall2FilterDateParam(ETableHeader.DUE_TO, 'notifications.due');
export const statusFilterResult = new Recall2FilterSelectParam(
  ETableHeader.STATUS,
  'notifications.status',
  selectableValues,
);

export const activeFilterResults = [
  idFilterResult,
  titleFilterResult,
  reporterFilterResult,
  editorFilterResult,
  receivedDateFilterResult,
  dueToFilterResult,
  statusFilterResult,
];
