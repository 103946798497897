<div class="rc2-overview-wrapper overview">
  <div #headerRow class="overview__header row">
    <div class="overview__header__main-container col-md-8">
      <h2 class="overview__header__main-container--title" data-cy="overview-title">
        {{ 'notifications.overview-header' | translate }}
      </h2>
    </div>

    <div class="overview__header-container col-md-4 rc2-justify--flex-end">
      <recall2-button-outline-add
        [title]="'notifications.createButton' | translate"
        (click)="createNotification()"
        data-cy="add-button"
      ></recall2-button-outline-add>
    </div>
  </div>

  <div class="rc2-mar--top--xxl">
    <app-ghost-skeleton *ngIf="isLoading" [schema]="GhostSkeletonSchema.Overview"></app-ghost-skeleton>
    <recall2-table
      [ngClass]="{ 'rc2-display--none': isLoading, 'rc2-display--flex': !isLoading }"
      class="rc2-mar--top--xxs"
      [tableId]="tableId"
      [config]="tableConfig"
      [filterConfig]="filterConfiguration"
      [data]="notifications"
      [showMoreFilters]="true"
      [isPageable]="true"
      [isHighlightEnabled]="true"
      [expandedTemplate]="expandedDetails"
      [emptyDataTranslationKey]="'overview.table.empty'"
    >
      <recall2-button-download
        class="rc2-mar--right--xxs"
        download-button
        [isDisabled]="false"
        [url]="downloadButtonConfig.url"
        [downloadType]="downloadButtonConfig.downloadType"
        [title]="downloadButtonConfig.title | translate"
        [isPrimary]="true"
        [showIcon]="false"
        [filter]="filters"
        [sort]="sort"
        data-cy="download-button"
      ></recall2-button-download>
    </recall2-table>
  </div>
</div>

<ng-template #icon>
  <div class="rc2-display--flex rc2-justify--center">
    <recall2-svg-icon name="meldung" size="medium"></recall2-svg-icon>
  </div>
</ng-template>

<ng-template #headerLink>
  <div class="rc2-display--flex rc2-justify--center">
    <recall2-svg-icon name="link" size="medium"></recall2-svg-icon>
  </div>
</ng-template>

<ng-template #headerAttachment>
  <div class="rc2-display--flex rc2-justify--center">
    <recall2-svg-icon name="attach" size="medium"></recall2-svg-icon>
  </div>
</ng-template>

<ng-template #reporter let-row="row">
  <div>
    <div class="shorten-text" *ngIf="currentUser?.vwUserId === row?.users?.reporter?.vwUserId">
      <recall2-icon-user [rc2IconStyles]="'rc2-icon--color--orange rc2-icon--size--24'"></recall2-icon-user>
    </div>
    <div
      class="shorten-text"
      *ngIf="currentUser?.vwUserId !== row?.users?.reporter?.vwUserId"
      class="reporter-text rc2-object-table--ellipsis"
    >
      {{ row?.users?.reporter?.lastName }}, {{ row?.users?.reporter?.firstName }}
    </div>
  </div>
</ng-template>

<ng-template #editor let-row="row">
  <div>
    <div
      class="shorten-text"
      *ngIf="
        currentUser?.vwUserId === row?.users?.activeEditor?.vwUserId &&
        row?.status !== EStatus.APPROVED &&
        row?.status !== EStatus.ACCEPTED
      "
    >
      <recall2-icon-user [rc2IconStyles]="'rc2-icon--color--orange rc2-icon--size--24'"></recall2-icon-user>
    </div>
    <div
      class="reporter-text rc2-object-table--ellipsis"
      *ngIf="
        currentUser?.vwUserId !== row?.users?.activeEditor?.vwUserId &&
        row?.status !== EStatus.APPROVED &&
        row?.status !== EStatus.ACCEPTED &&
        row?.status !== EStatus.LINKED
      "
      title="{{ row?.users?.activeEditor?.lastName }}, {{ row?.users?.activeEditor?.firstName }}"
    >
      {{ row?.users?.activeEditor?.lastName }}, {{ row?.users?.activeEditor?.firstName }}
    </div>
    <div
      class="reporter-text rc2-object-table--ellipsis"
      *ngIf="row?.status === EStatus.APPROVED || row?.status === EStatus.ACCEPTED || row?.status === EStatus.LINKED"
      title="{{ row?.users?.activeEditor?.manufacturer }}"
    >
      {{ row?.users?.activeEditor?.manufacturer }}
    </div>
  </div>
</ng-template>

<ng-template #date let-element>
  {{ element | customDate }}
</ng-template>

<ng-template #dueTo let-row="row">
  <span class="rc2-font--body--m {{ row?.dueTo?.status?.toLowerCase() }}">
    <recall2-deadline *ngIf="row.status !== EStatus.LINKED" [dueDate]="row?.dueTo"> </recall2-deadline>
    <ng-container *ngIf="row.status === EStatus.LINKED">-</ng-container>
  </span>
</ng-template>

<ng-template #expandedDetails let-element>
  <notification-details [notification]="element" (refreshTableData)="onRefreshTable($event)"></notification-details>
</ng-template>

<ng-template #status let-element>
  <recall2-status class="rc2-display--flex" [currentStatus]="element" [isTopic]="false" [isCampaign]="false">
  </recall2-status>
</ng-template>

<ng-template #statusFilter let-element>
  <recall2-status class="rc2-display--flex" [currentStatus]="element.id" [isTopic]="false" [isCampaign]="false">
  </recall2-status>
</ng-template>

<ng-template #edit let-row="row">
  <div class="rc2-display--flex rc2-justify--flex-end" *ngIf="row.actionPermissions?.canEdit">
    <recall2-button-tertiary data-cy="committee-overview-table-edit" (click)="openNotificationById(row?.id)">
      <recall2-svg-icon *recall2SvgIconButton name="edit" size="medium"></recall2-svg-icon>
    </recall2-button-tertiary>
  </div>
</ng-template>

<ng-template #attachments let-row="row">
  <app-table-attachments
    [itemsCount]="row.attachmentCount"
    [itemId]="row.id"
    [apiUrl]="attachmentsApi"
  ></app-table-attachments>
</ng-template>

<ng-template #linkedObjects let-row="row">
  <app-overlay-linked-objects [id]="row.id" [itemsCount]="row.linkCount" [originApp]="EObjectType.NOTIFICATION">
  </app-overlay-linked-objects>
</ng-template>
