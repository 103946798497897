<div class="is-full-width">

  <div
    class="mar-bottom-erase left checkbox-input-label"
    [ngClass]="{
    'is-danger': isFormSubmitted && property.control.errors ,
    'checkbox-input-label--disabled': property.control.disabled && !property.control.value
    }"
  >

    <p
      class="rc2-mar---top--3xs checkbox-label-text"
      *ngIf="property.translationKey"
    >
      {{ property.translationKey + (isTranslateSuffixOmitted ? '' : '.label') | translate }}
    </p>

    <recall2-icon-required *ngIf="property.required"></recall2-icon-required>

    <recall2-icon-select
      *ngIf="property.control.value"
      (click)="toggleCheck($event)"
      [ngClass]="{'hide': !property.control.value}"
      [rc2IconStyles]="'rc2-icon--color--white rc2-icon--size--24 rc2-pad--no'"
    ></recall2-icon-select>

    <input
      type="checkbox"
      name="{{ property.name }}"
      [formControl]="property.control"
    />

    <div
      (click)="toggleCheck($event)"
      class="checkmark"
      [ngClass]="{'checkmark--checked': property.control.value ,
    'checkmark--disabled': property.control.disabled}"
    ></div>

  </div>

  <recall2-icon-help
    *ngIf="property.hasTooltip"
    matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
    matTooltipClass="rc2-tooltip"
    matTooltipPosition="above"
  >
  </recall2-icon-help>
</div>
