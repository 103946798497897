import type { TemplateRef } from '@angular/core';
import type { TableColumnConfig } from '@recall2/ui/table';

import { NotificationOverviewTableColumns } from './notification-overview-table-columns.enum';

const headerTranslationKeyPrefix = 'table.header.';

interface NotificationOverviewTemplatesConfig {
  iconTemplate: TemplateRef<unknown>;
  editTemplate: TemplateRef<unknown>;
  reporterTemplate: TemplateRef<unknown>;
  editorTemplate: TemplateRef<unknown>;
  dateTemplate: TemplateRef<unknown>;
  dueToTemplate: TemplateRef<unknown>;
  statusTemplate: TemplateRef<unknown>;
  attachmentsTemplate: TemplateRef<unknown>;
  linkedObjectsTemplate: TemplateRef<unknown>;
  headerLinkTemplate: TemplateRef<unknown>;
  headerAttachmentTemplate: TemplateRef<unknown>;
}

export const notificationOverviewTableConfig = (
  templatesConfig: NotificationOverviewTemplatesConfig,
): TableColumnConfig[] => {
  const tableConfig = [
    {
      id: NotificationOverviewTableColumns.Icon,
      columnSize: 'col-md-0-5',
      headerTranslationKey: '',
      customTemplate: templatesConfig.iconTemplate,
    },
    {
      id: NotificationOverviewTableColumns.ID,
      columnSize: 'col-md-1',
      headerTranslationKey: `${headerTranslationKeyPrefix}id`,
      isSortable: true,
      isFilterable: true,
    },
    {
      id: NotificationOverviewTableColumns.Title,
      columnSize: 'col-md-2',
      headerTranslationKey: `${headerTranslationKeyPrefix}title`,
      isSortable: true,
      isFilterable: true,
    },
    {
      id: NotificationOverviewTableColumns.Reporter,
      columnSize: 'col-md-1',
      headerTranslationKey: 'role.reporter',
      customTemplate: templatesConfig.reporterTemplate,
      isSortable: true,
      isFilterable: true,
    },
    {
      id: NotificationOverviewTableColumns.Editor,
      columnSize: 'col-md-1',
      headerTranslationKey: 'notifications.processor',
      customTemplate: templatesConfig.editorTemplate,
      isSortable: true,
      isFilterable: true,
    },
    {
      id: NotificationOverviewTableColumns.ReceivedDate,
      columnSize: 'col-md-1',
      headerTranslationKey: 'notifications.received',
      customTemplate: templatesConfig.dateTemplate,
      isSortable: true,
      isFilterable: true,
    },
    {
      id: NotificationOverviewTableColumns.DueTo,
      columnSize: 'col-md-1-5',
      headerTranslationKey: `${headerTranslationKeyPrefix}dueTo`,
      customTemplate: templatesConfig.dueToTemplate,
      isSortable: true,
      isFilterable: true,
    },
    {
      id: NotificationOverviewTableColumns.Status,
      columnSize: 'col-md-2',
      headerTranslationKey: `${headerTranslationKeyPrefix}state`,
      customTemplate: templatesConfig.statusTemplate,
      isSortable: true,
      isFilterable: true,
    },
    {
      id: NotificationOverviewTableColumns.Attachment,
      columnSize: 'col-md-0-5',
      headerTranslationKey: '',
      customTemplate: templatesConfig.attachmentsTemplate,
      customHeader: templatesConfig.headerAttachmentTemplate,
    },
    {
      id: NotificationOverviewTableColumns.Link,
      columnSize: 'col-md-0-5',
      headerTranslationKey: '',
      customTemplate: templatesConfig.linkedObjectsTemplate,
      customHeader: templatesConfig.headerLinkTemplate,
    },
    {
      id: NotificationOverviewTableColumns.Edit,
      columnSize: 'col-md-0-5',
      headerTranslationKey: '',
      customTemplate: templatesConfig.editTemplate,
    },
    {
      id: NotificationOverviewTableColumns.Expand,
      columnSize: 'col-md-0-5',
      headerTranslationKey: '',
    },
  ];

  return tableConfig;
};
