{{ "paginator.selectedElementsPerSite" | translate }}:
<app-text-select
  [value]="pageSize"
  (valueChange)="onChangeElementPerPage($event)"
  [iconName]="'arrow-down'"
  data-cy="paginator-pagesize-selector"
>
  <app-text-select-option
    *ngFor="let itemsPerPage of itemsPerPageList"
    data-cy="paginator-pagesize-selector-option"
    [value]="itemsPerPage"
  >{{ itemsPerPage }}</app-text-select-option>
</app-text-select>
