export enum ETableHeader {
  URGENCY_LEVEL = 'urgencyLevel',
  ICON = 'icon',
  ID = 'id',
  COMMITTEE = 'committee',
  TITLE = 'title',
  EDITOR = 'editor',
  AUTHOR = 'author',
  MANUFACTURER = 'manufacturer',
  CUSTOMER_ID = 'customerId',
  DURATION = 'duration',
  PARTICIPANTS = 'participants',
  REPORTER = 'reporter',
  CREATED_AT = 'createdAt',
  CREATION_AT = 'createdDate',
  START_DATE = 'start_date',
  DUE_TO = 'dueTo',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  STATUS = 'status',
  LINK_ICON = 'linkIcon',
  UNLINK_ICON = 'unlinkIcon',
  ROUTE = 'route',
  DUE_DATE = 'due',
  CODE = 'code',
  SESSION_DATE = 'sessionDate',
  DECISION = 'decision',
}
