import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import type { Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import type { EventParams, EventType, SystemNotificationEventType } from '../models/object-event';
import { DescriptionTranslateService } from '../services/description-translate.service';
import { ParamsTranslateService } from '../services/params-translate.service';
import { interpolate } from '../services/util';

@Pipe({
  name: 'eventDescription',
  standalone: true,
})
export class EventDescriptionPipe implements PipeTransform {
  constructor(
    private descriptionService: DescriptionTranslateService,
    private paramsService: ParamsTranslateService,
  ) {}

  transform(eventType: EventType | SystemNotificationEventType, params: EventParams): Observable<string> {
    return combineLatest([this.descriptionService.translate(eventType), this.paramsService.translate(params)]).pipe(
      map(([description, translatedParams]) => interpolate(description, translatedParams)),
    );
  }
}
