import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import type { Observable } from 'rxjs';
import { of, zip } from 'rxjs';
import { map } from 'rxjs/operators';

import type {
  EventParams,
  NonTranslateableParams,
  TranslateableParams,
  TranslatedEventParams,
} from '../models/object-event';
import { filterObject } from '../services/util';

const possibleToTranslate: Record<keyof TranslateableParams, true> = {
  manufacturerCode: true,
  label: true,
  destinationObjectType: true,
  objectType: true,
  sourceObjectType: true,
  status: true,
  userRole: true,
};

const not = fn => args => !fn(args);
const isKeyTranslated = (key: keyof EventParams): boolean => !!possibleToTranslate[key];

const getKey = <K extends keyof TranslateableParams>(key: K, value: TranslateableParams[K]): string =>
  `history.event-params.${key}.${value}`;

@Injectable({
  providedIn: 'root',
})
export class ParamsTranslateService {
  constructor(private translateService: TranslateService) {}
  /**
   * Returns an Observable of the translated EventParams
   * that emits every on every language change. The params
   * are translated based on the prop name (key).
   *
   * @param params EventParams object
   * @returns Observable of the translated EventParams
   */
  translate(params: EventParams): Observable<TranslatedEventParams> {
    const { nonTranslateable, translateable } = this.divide(params);
    const translateableKeys = Object.keys(translateable) as (keyof TranslateableParams)[];

    if (translateableKeys.length === 0) {
      return of(params as TranslatedEventParams);
    }

    return zip(
      ...translateableKeys.map(paramKey => {
        const translationKey = getKey(paramKey, translateable[paramKey]);
        return this.translateService.stream(translationKey).pipe(
          map(translatedParam => ({
            [paramKey]: translatedParam,
          })),
        );
      }),
    ).pipe(
      map(translatedObjects => translatedObjects.reduce((acc, next) => ({ ...acc, ...next }), {})),
      map(translatedParams => ({ ...translatedParams, ...nonTranslateable }) as TranslatedEventParams),
    );
  }

  private getTranslateable(params: EventParams): TranslateableParams {
    return filterObject(params, isKeyTranslated);
  }

  private getNonTranslateable(params: EventParams): NonTranslateableParams {
    return filterObject(params, not(isKeyTranslated));
  }

  private divide(params: EventParams): {
    translateable: TranslateableParams;
    nonTranslateable: NonTranslateableParams;
  } {
    return {
      translateable: this.getTranslateable(params),
      nonTranslateable: this.getNonTranslateable(params),
    };
  }
}
