<div cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <recall2-auto-suggest-input
    #autoSuggestInput
    data-cy="recall2-auto-suggest-input"
    (keydown)="onKeyDown($event)"
    [isFormSubmitted]="isFormSubmitted"
    [property]="configuration.property"
    (changeSearchTerm)="onChangeSearchTerm($event)"
    (isArrowDown)="onArrowDown($event)"
    [isDisabled]="isDisabled"
  ></recall2-auto-suggest-input>
</div>
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayMinWidth]="overlayWidth"
  [cdkConnectedOverlayOpen]="!isArrowDown"
  [cdkConnectedOverlayBackdropClass]="'autosuggest-list-backdrop'"
  (backdropClick)="closeModal()"
>
  <div class="auto-suggest-box">
    <recall2-auto-suggest-list
      #autoSuggestResult
      id="autoSuggestResult"
      data-cy="recall2-auto-suggest-list"
      [entries]="entries"
      [isSpinnerShowing]="isSpinnerShowing"
      [isArrowDown]="isArrowDown"
      [searchTerm]="searchTerm"
      [objectType]="objectType"
      [searchDirectoryEnabled]="configuration.searchDirectoryEnabled"
      (selectEntry)="selectEntry($event)"
      (directorySearch)="onDirectorySearch()"
    ></recall2-auto-suggest-list>
  </div>
</ng-template>
