import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@recall2/globals';

import { RECALL2_NEW_RELIC_ENABLED } from '../injection-tokens/recall2-new-relic-enabled.token';
import type { NewRelic } from '../models/new-relic';
import { NewRelicWindow } from '../models/new-relic-window';

@Injectable({
  providedIn: 'root',
})
export class Recall2NewRelicService {
  constructor(
    @Inject(RECALL2_NEW_RELIC_ENABLED) private enabled: boolean,
    @Inject(WINDOW) private window: NewRelicWindow,
  ) {}

  isEnabled(): boolean {
    return this.enabled;
  }

  instance(): NewRelic {
    return this.window.newrelic;
  }

  noticeError(error: unknown): void {
    if (!this.isEnabled() || !this.instance()) {
      return;
    }

    this.instance().noticeError(error);
  }
}
