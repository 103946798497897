import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { TablePaginatorLeftElementDirective } from '../../directives/table-paginator-left-element/table-paginator-left-element.directive';
import { TablePaginatorRightElementDirective } from '../../directives/table-paginator-right-element/table-paginator-right-element.directive';
import type { TablePageEvent } from '../../models/table-page-event.model';
import { TablePaginatorComponent } from '../table-paginator/table-paginator.component';
import { TablePaginatorCurrentPageComponent } from '../table-paginator-current-page/table-paginator-current-page.component';
import { TablePaginatorPagesizeSelectorComponent } from '../table-paginator-pagesize-selector/table-paginator-pagesize-selector.component';
import { TablePaginatorSummaryComponent } from '../table-paginator-summary/table-paginator-summary.component';

@Component({
  selector: 'app-overview-table-paginator',
  templateUrl: './overview-table-paginator.component.html',
  styleUrls: ['./overview-table-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    TablePaginatorComponent,
    TablePaginatorLeftElementDirective,
    TablePaginatorRightElementDirective,
    TablePaginatorPagesizeSelectorComponent,
    TablePaginatorSummaryComponent,
    TablePaginatorCurrentPageComponent,
  ],
})
export class OverviewTablePaginatorComponent {
  @Input() length: number;
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() arePageButtonsDisabled: boolean;
  @Input() totalPages: number;
  @Input() showPageSize = true;
  @Output() page = new EventEmitter<TablePageEvent>();
  @Output() elementsPerPage = new EventEmitter<number>();
}
