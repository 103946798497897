import type { OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import type { FormControl } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';

import { Recall2TextareaComponent } from '../../../form/components/recall2-textarea/recall2-textarea.component';
import type { InputTextProperty } from '../../../form/model';

const REQUIRED_AND_MAX_LENGTH = [Validators.required, Validators.maxLength(1024)];

@Component({
  selector: 'recall2-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss'],
  standalone: true,
  imports: [Recall2TextareaComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentInputComponent implements OnInit {
  @Input() isRequired = false;
  @Input() isFormSubmitted = false;
  @Input() showLabel = false;

  @ViewChild('commentInput')
  commentInput: Recall2TextareaComponent;

  @Output() addComment = new EventEmitter<string>();

  commentInputForm: FormControl;
  commentProperty: InputTextProperty;

  constructor(
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.commentInputForm = this.formBuilder.control('', REQUIRED_AND_MAX_LENGTH);
  }

  ngOnInit(): void {
    this.commentProperty = {
      name: 'comment',
      required: this.isRequired,
      translationKey: 'history.comment-input',
      htmlValidators: { maxLength: 1024 },
      hasTooltip: false,
      control: this.commentInputForm,
      hasTitle: this.showLabel,
    };
    this.cdr.markForCheck();
  }

  onEnterKey(event: Event): void {
    event.preventDefault();
    this.emitValue();
    this.cdr.markForCheck();
  }

  isInputValid(): boolean {
    this.commentInputForm.updateValueAndValidity();
    return this.commentInputForm.valid;
  }

  getInputValue(): string {
    return this.commentInputForm.value;
  }

  emitValue(): void {
    this.isFormSubmitted = true;
    if (this.isInputValid()) {
      this.addComment.emit(this.getInputValue());
      this.clearInput();
      this.cdr.markForCheck();
    }
  }

  clearInput(): void {
    this.commentInputForm.clearValidators();
    this.commentInputForm.reset();
    this.commentInputForm.setValidators(REQUIRED_AND_MAX_LENGTH);
    this.cdr.markForCheck();
  }

  setFocus(): void {
    this.commentInput.setFocus();
    this.cdr.markForCheck();
  }
}
