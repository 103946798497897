import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

@Injectable()
export abstract class KnownUserProvider {
  abstract isKnownUser(): Observable<UserState>;
}

/* istanbul ignore next */
export class UserState {
  constructor(
    public isKnown: boolean,
    public hasActiveRoles: boolean,
  ) {}
}
