import { type FormControl } from '@angular/forms';
import { type MatSelectChange } from '@angular/material/select';

export interface IReCall2FormProperty {
  name: string;
  translationKey: string;
  control: FormControl;
  required?: boolean;
  hasTooltip?: boolean;
  readOnly?: boolean;
  allowedCharacters?: AllowedCharactersOption;
}

export interface IHtmlValidators {
  maxLength?: number;
  min?: number | Date | string;
  max?: number | Date | string;
  step?: number | string;
}

export interface IReCall2TextEditor {
  id: string;
  title?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  maxLength?: number;
}

export interface UrlLink {
  id: string;
  url: string;
  objectId: string;
  objectType: string;
}

export enum EReCall2TextviewFormat {
  HTML = 'html',
  OBJECT = 'object',
  TEXT = 'text',
  JSON = 'json',
}

export enum EReCall2TextviewTheme {
  SNOW = 'snow',
  BUBBLE = 'bubble',
}

export enum LinkType {
  Campaign = 'CAMPAIGN',
  Clearing = 'CLEARING',
  Committee = 'COMMITTEE',
  Notification = 'NOTIFICATION',
  Topic = 'TOPIC',
  VerificationTask = 'VERIFICATION_TASK',
}

export class InputTextProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public htmlValidators: IHtmlValidators,
    public hasTooltip: boolean,
    public control: FormControl,
    public hasTitle = true,
    public readOnly?: boolean,
    public allowedCharacters?: AllowedCharactersOption,
    public showErrorOnTooltip?: boolean,
  ) {}
}

export class InputNumberProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public htmlValidators: IHtmlValidators,
    public hasTooltip: boolean,
    public control: FormControl,
    public hasTitle = true,
    public readOnly?: boolean,
    public isDecimal?: boolean,
    public allowedCharacters?: AllowedCharactersOption,
    public showErrorOnTooltip?: boolean,
  ) {}
}

export class TextareaProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public htmlValidators: IHtmlValidators,
    public hasTooltip: boolean,
    public control: FormControl,
    public allowedCharacters?: AllowedCharactersOption,
  ) {}
}

export class CheckboxProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public isChecked: boolean,
    public hasTooltip: boolean,
    public control: FormControl,
    public id?: string,
    public iconStyles?: string,
    public iconUnfilled?: boolean,
  ) {}
}

export class ToggleProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public control: FormControl,
    public translationKey: string,
    public translationKeyTooltip?: string,
    public translationKeyOff?: string,
  ) {}
}

export class SelectOption {
  constructor(
    public key: string,
    public value: string,
    public tooltipKey?: string,
  ) {}
}
export interface AutocompleteGroup {
  group: string;
  options: AutocompleteOption[];
}
export interface AutocompleteOption {
  key: string;
  value: string;
}
export class AutocompleteProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public translationKey: string,
    public control: FormControl,
    public optionsList: AutocompleteGroup[] | AutocompleteOption[],
    public htmlValidators: IHtmlValidators,
    public arrowIcon: boolean = true,
    public showValidations: boolean = false,
    public required?: boolean,
    public disabled?: boolean,
    public upperCase?: boolean,
    public tooltipKey?: string,
    public placeholderKey?: string,
    public overlayItemsKey?: string,
    public overlayNoItemsKey?: string,
  ) {}
}
export class SelectProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public optionsList: SelectOption[],
    public hasTooltip: boolean,
    public control: FormControl,
    public readOnly?: boolean,
    public placeholderKey?: string,
    public showErrorOnTooltip?: boolean,
  ) {}
}

export class DateProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public minDate: Date,
    public maxDate: Date,
    public hasTooltip: boolean,
    public control: FormControl,
    public hasTitle: boolean = true,
    public isRangedDate: boolean = false,
    public showErrorOnTooltip?: boolean,
  ) {}
}

export class RadioButtonV2GroupProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public translationKey: string,
    public control: FormControl,
    public options: RadioButtonV2Property[],
    public hasTooltip?: boolean,
    public required?: boolean,
    public disabled?: boolean,
    public showHorizontal?: boolean,
  ) {}
}

export interface RadioButtonV2Property {
  translationKey?: string;
  rawText?: string;
  value: string | boolean;
  disabled?: boolean;
  dataCy?: string;
}

export class TimeProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public hasTooltip: boolean,
    public control: FormControl,
    public hasTitle: boolean = true,
  ) {}
}

export enum EThreeStepCheckboxStates {
  UNSELECTED = 'UNSELECTED',
  SELECTED = 'SELECTED',
  DESELECTED = 'DESELECTED',
  MIXED = 'MIXED',
}

export enum ELabelAlignment {
  TOP,
  LEFT,
  RIGHT,
}

export class ThreeStepCheckboxProperty implements IReCall2FormProperty {
  constructor(
    public name: string,
    public required: boolean,
    public translationKey: string,
    public hasTooltip: boolean,
    public control: FormControl,
    public hasLabel: boolean,
  ) {}
}

export type IRecall2SelectChange = MatSelectChange;

export type IRecall2MultiSelectChange = IRecall2SelectChange;

export enum AllowedCharactersOption {
  Integers = '([0-9])',
}

export interface AutoSuggestTag {
  text: string;
}
