<div class="row">
  <div class="col-md-11 rc2-pad--bottom--xl rc2-pad--top--xs">
    <h3>{{ translations?.titleTranslationKey | translate}}</h3>
  </div>
  <div class="col-md-1 rc2-display--flex">
    <recall2-button-outline-add
      class="right-align"
      id="add-button"
      (click)="addNewRow()"
      [isDisabled]="disabled"
    >
    </recall2-button-outline-add>
  </div>
</div>

<div class="row overflow-visible">
  <div class="col-md-12 ">
    <table
      mat-table
      [dataSource]="tableData"
      class="mat-elevation-z0 is-full-width"
      id="editable-input-table"
      multiTemplateDataRows
      [ngClass]="{ 'border-bottom rc2-border--error rc2-color--error': showTableError() || showSecondColumnError()}"
    >

      <caption></caption>

      <ng-container matColumnDef="firstColumn">
        <th
          mat-header-cell
          *matHeaderCellDef
          id="first-column-title"
          class="rc2-font--body--s rc2-color--grey-600 col-md-4"
        >
          {{ isPartsTable ? (translations?.firstColumnTitle | translate) + '*' : (translations?.firstColumnTitle | translate)}}
        </th>
        <td
          mat-cell
          *matCellDef="let data; let i = dataIndex;"
          class="col-md-4"
        >
          <recall2-input-text
            class="is-full-width rc2-pad--top--s rc2-pad--bottom--s rc2-pad--right--xl"
            id="{{'firstColumnInput' + (i + 1)}}"
            [property]="data.firstColumnInputProp"
            (onChange)="updateFirstColumn()"
            [isFormSubmitted]="showFirstColumnError(i)"
          >
          </recall2-input-text>

        </td>
      </ng-container>

      <ng-container matColumnDef="secondColumn">
        <th
          mat-header-cell
          *matHeaderCellDef
          id="second-column-title"
          class="rc2-font--body--s rc2-color--grey-600 col-md-4"
        >
          {{ isPartsTable ? (translations?.secondColumnTitle | translate) + '*' : (translations?.secondColumnTitle | translate)}}
        </th>
        <td
          mat-cell
          *matCellDef="let data; let i = dataIndex;"
          class="col-md-4"
        >
          <recall2-input-text
            *ngIf="!isPartsTable"
            class="is-full-width rc2-pad--top--s rc2-pad--bottom--s rc2-pad--right--xl"
            id="{{'secondColumnInput' + (i + 1)}}"
            [property]="data.secondColumnInputProp"
            (onChange)="updateSecondColumn()"
            [isFormSubmitted]="showSecondColumnError()"
          >
          </recall2-input-text>
          <recall2-tags
            *ngIf="isPartsTable"
            class="is-full-width rc2-pad--top--s rc2-pad--bottom--s rc2-pad--right--xl"
            id="{{'tagsInput' + (i +1)}}"
            [title]="''"
            [showBorder]="true"
            [currentTags]="getPartsStringList(data.tuple.partNumbers)"
            (removedTagId)="onRemoveTag(i,$event)"
            (updateTags)="updateTags(i,$event)"
          >
          </recall2-tags>
        </td>
      </ng-container>

      <!-- deleteIcon Column -->
      <ng-container matColumnDef="deleteIcon">
        <th
          mat-header-cell
          *matHeaderCellDef
          id="third-column-delete"
          class="col-md-4"
        >
        </th>
        <td
          mat-cell
          *matCellDef="let data; let i = dataIndex;"
          class="col-md-4"
        >
          <recall2-icon-delete
            *ngIf="showDeleteIcon()"
            class="rc2-pad--top--s rc2-pad--bottom--s"
            id="{{'deleteColumn' + (i + 1)}}"
            [rc2IconStyles]="'rc2-cursor--pointer rc2-icon--size--24 rc2-icon--color--blue'"
            (click)="removeTableRow(i)"
          >
          </recall2-icon-delete>
        </td>
      </ng-container>

      <!-- Show the contents: mat-header-row and the rows with the data -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="table-row row"
      ></tr>
      <tr
        mat-row
        *matRowDef="let data; columns: displayedColumns"
        class="table-row row"
        [formGroup]="data.formGroup"
      >
      </tr>

    </table>

    <div
      id="error_feedback_message_table"
      *ngIf="showTableError() || showSecondColumnError()"
      class="rc2-font--family--regular rc2-font--size--12 rc2-color--error"
    >
      {{ "form.error.generic.required" | translate }}
    </div>

  </div>
</div>
