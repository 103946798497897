import type { OnInit } from '@angular/core';
import { Directive, Input } from '@angular/core';

@Directive()
export abstract class Recall2ImgBaseComponent implements OnInit {
  @Input() rc2ImgStyles: string;
  // these are the default stylings that were originally applied to the img tag element directly.
  rc2ImgClassUnion = 'rc2-full--width rc2-full--height rc2-logo--center ';

  ngOnInit(): void {
    this.rc2ImgClassUnion += this.rc2ImgStyles ? ' ' + this.rc2ImgStyles : '';
  }
}
