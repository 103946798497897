import { NgClass, NgFor, NgIf } from '@angular/common';
import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef, Component, HostListener, inject, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { WINDOW } from '@recall2/globals';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { Recall2IconMoreComponent } from '../icons/recall2-icon-more/recall2-icon-more.component';
import { AppsLinkDirective } from '../navigation/components/apps-link/apps-link.directive';
import { compareObjects } from '../utils/functions/functions';
import { Recall2MeasureMenuItemDirective } from './directives/recall2-measure-menu-item.directive';
import type { MeasureableMenuItem } from './models/measureable-menu-item.model';
import type { ERole } from './models/role.model';
import { ResponsiveMenuService } from './service/responsive-menu.service';
import { WidthExtractor } from './service/width-extractor.service';

@Component({
  selector: 'recall2-navbar',
  standalone: true,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  imports: [
    NgIf,
    NgClass,
    NgFor,
    TranslateModule,
    Recall2MeasureMenuItemDirective,
    AppsLinkDirective,
    Recall2IconMoreComponent,
  ],
})
export class NavbarComponent implements OnInit, OnChanges, OnDestroy {
  private responsiveMenuService = inject(ResponsiveMenuService);
  private translateService = inject(TranslateService);
  private widthExtractor = inject(WidthExtractor);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private window = inject(WINDOW);

  @Input() roles: ERole[];
  @Input() isHomeVisible = true;

  mainMenuLinks: MeasureableMenuItem[] = [];
  abbreviatedMenuLinks: MeasureableMenuItem[] = [];

  private destroyed$ = new Subject<void>();

  ngOnInit(): void {
    this.initMenu(true);
    this.initSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.roles?.isFirstChange() && !changes.isHomeVisible?.isFirstChange()) {
      this.initMenu(true);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  updateWidth(newWidth: number, index: number): void {
    this.responsiveMenuService.setWidth(newWidth, index);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: number } }): void {
    this.responsiveMenuService.setWindowWidth(event.target.innerWidth);
  }

  trackLink(_index: number, link: MeasureableMenuItem): string | undefined {
    return link ? `${link.name}-${link.width ?? 0}` : undefined;
  }

  private initMenu(setRoles = false): void {
    setRoles
      ? this.responsiveMenuService.setRoles(this.roles, this.isHomeVisible)
      : this.responsiveMenuService.resetItemWidths(this.isHomeVisible);
    this.responsiveMenuService.setWindowWidth(this.widthExtractor.getInnerWidth(this.window));
  }

  private initSubscriptions(): void {
    this.translateService.onLangChange.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.initMenu();
    });

    this.responsiveMenuService.splitSubject$
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(
          (previous, current) =>
            this.areMenuItemsListsEqual(previous.visibleMenuItems, current.visibleMenuItems) &&
            this.areMenuItemsListsEqual(previous.hiddenMenuItems, current.hiddenMenuItems),
        ),
      )
      .subscribe(({ visibleMenuItems, hiddenMenuItems }) => {
        this.mainMenuLinks = visibleMenuItems;
        this.abbreviatedMenuLinks = hiddenMenuItems;
        this.changeDetectorRef.detectChanges();
      });
  }

  private areMenuItemsListsEqual(previousList: MeasureableMenuItem[], currentList: MeasureableMenuItem[]): boolean {
    return (
      previousList.length === currentList.length &&
      previousList.every((item, index) => this.areItemsEqual(item, currentList[index]))
    );
  }

  /**
   * Method used to compare menu items without taking into account their 'width' property
   */
  private areItemsEqual(elementA: MeasureableMenuItem, elementB: MeasureableMenuItem): boolean {
    return compareObjects({ ...elementA, width: undefined }, { ...elementB, width: undefined });
  }
}
