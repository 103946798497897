import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import type { ERole } from '../../../navbar/models/role.model';
import type { AuthUser } from '../../models/auth-user.model';

export interface AuthUserStoreState {
  user: AuthUser;
  roles: ERole[];
}

export const createInitialState = (): AuthUserStoreState => {
  // The user is set to undefined but since an APP_INITIALIZER is used to load the user before the
  // AppComponent is initialized, the select('user) query will never return undefined, it will
  // always return the user
  return {
    user: undefined,
    roles: [],
  };
};

@Injectable({
  providedIn: 'root',
})
@StoreConfig({ name: 'user' })
export class AuthUserStore extends Store<AuthUserStoreState> {
  constructor() {
    super(createInitialState());
  }
}
