import type { IColumnDefinition } from '@recall2/ui/dynamic-content';
import { ColumnDefinition, EObjectType, TextCellBinding, TextCellComponent } from '@recall2/ui/dynamic-content';

import {
  AutoSuggestCell,
  UsernameCellDataBinding,
} from '../cells/involved-user-table/auto-suggest-cell/auto-suggest-cell.component';
import {
  CommitteeTranscriberCell,
  CommitteeTranscriberCellDataBinding,
} from '../cells/involved-user-table/committee-transcriber-cell/committee-transcriber.cell';
import {
  DeleteIconCell,
  DeleteIconCellDataBinding,
} from '../cells/involved-user-table/delete-icon-cell/delete-icon.cell';
import {
  EntitledToVoteCell,
  EntitledToVoteCellDataBinding,
} from '../cells/involved-user-table/entitled-to-vote-cell/entitled-to-vote.cell';
import {
  InheritToAgendaItemCell,
  InheritToAgendaItemCellDataBinding,
} from '../cells/involved-user-table/inherit-to-agenda-item-cell/inherit-to-agenda-item.cell';
import {
  SubjectAreaCellDataBinding,
  SubjectAreaEditCell,
} from '../cells/involved-user-table/subject-area-edit-cell/subject-area-edit-cell.component';
import { DepartmentCell } from '../cells/involved-users-summary-table/department-cell/department-cell.component';
import { EmailCell } from '../cells/involved-users-summary-table/email-cell/email-cell.component';
import { IdNameCell } from '../cells/involved-users-summary-table/id-name-cell/id-name-cell.component';
import { PhoneCell } from '../cells/involved-users-summary-table/phone-cell/phone-cell.component';
import { SubjectAreaCell } from '../cells/involved-users-summary-table/subject-area-cell/subject-area-cell.component';
import type { IInvolvedUserAutoSuggestProperties } from './involved-user.model';

export const involvedUsersSummaryTableColumnsDefinition: IColumnDefinition[] = [
  {
    id: 'involved-user-id-name',
    columnSize: 'col-md-3',
    dynamicCellComponent: IdNameCell,
    headerTranslationKey: 'involvedUserTable.id.header',
  },
  {
    id: 'involved-user-subject-area',
    columnSize: 'col-md-2',
    dynamicCellComponent: SubjectAreaCell,
    headerTranslationKey: 'involvedUserTable.subjectArea.header',
  },
  {
    id: 'involved-user-department',
    columnSize: 'col-md-2',
    dynamicCellComponent: DepartmentCell,
    headerTranslationKey: 'involvedUserTable.department.header',
  },
  {
    id: 'involved-user-phone',
    columnSize: 'col-md-2',
    dynamicCellComponent: PhoneCell,
    headerTranslationKey: 'involvedUserTable.tel.header',
  },
  {
    id: 'involved-user-email',
    columnSize: 'col-md-3',
    dynamicCellComponent: EmailCell,
    headerTranslationKey: 'involvedUserTable.email.header',
  },
];

export interface CommonUserTableColumns {
  name?: ColumnDefinition;
  department?: ColumnDefinition;
  subjectArea?: ColumnDefinition;
  telephone?: ColumnDefinition;
  email?: ColumnDefinition;
}

export interface GridColumnDefinition {
  name: string;
  department: string;
  subjectArea: string;
  telephone: string;
  email: string;
}

export enum EInvolvedUserTableHeader {
  NAME = 'Name',
  SUBJECT_AREA = 'Subject_Area',
  DEPARTMENT = 'Department',
  TELEPHONE = 'Telephone',
  EMAIL = 'Email',
  ATTENDANCE = 'Attendance',
  ENTITLED_TO_VOTE = 'Entitled_To_Vote',
  COMMITTEE_TRANSCRIBER = 'Committee_Transcriber',
  DELETE = 'Delete',
  INHERIT_TO_AGENDA_ITEM = 'Inherit_To_Agenda_Item',
}

export const defaultGridColumnDefinition: GridColumnDefinition = {
  name: 'col-md-3',
  subjectArea: 'col-md-2',
  department: 'col-md-2',
  telephone: 'col-md-2',
  email: 'col-md-2',
};

export const defaultColumnsTable = (
  autoSuggestProperties?: IInvolvedUserAutoSuggestProperties,
  columns: GridColumnDefinition = defaultGridColumnDefinition,
): CommonUserTableColumns => {
  return {
    name: new ColumnDefinition(
      EInvolvedUserTableHeader.NAME,
      columns.name,
      AutoSuggestCell,
      new UsernameCellDataBinding('vwUserId', 'firstName', 'lastName', autoSuggestProperties),
      'involvedUserTable.id.header',
      undefined,
      undefined,
      true,
      false,
      'lastName',
    ),
    subjectArea: new ColumnDefinition(
      EInvolvedUserTableHeader.SUBJECT_AREA,
      columns.subjectArea,
      SubjectAreaEditCell,
      new SubjectAreaCellDataBinding('subjectArea', 'role'),
      'involvedUserTable.subjectArea.header',
      undefined,
      undefined,
      true,
      false,
      'subjectArea',
    ),
    department: new ColumnDefinition(
      EInvolvedUserTableHeader.DEPARTMENT,
      columns.department,
      TextCellComponent,
      new TextCellBinding('department'),
      'involvedUserTable.department.header',
      undefined,
      undefined,
      true,
      false,
      'department',
    ),
    telephone: new ColumnDefinition(
      EInvolvedUserTableHeader.TELEPHONE,
      columns.telephone,
      TextCellComponent,
      new TextCellBinding('tel', ['normal-ellipsis']),
      'involvedUserTable.tel.header',
      undefined,
      undefined,
      true,
      false,
      'tel',
    ),
    email: new ColumnDefinition(
      EInvolvedUserTableHeader.EMAIL,
      columns.email,
      TextCellComponent,
      new TextCellBinding('email', ['normal-ellipsis']),
      'involvedUserTable.email.header',
      undefined,
      undefined,
      true,
      false,
      'email',
    ),
  };
};

// default table column definitions
export const getDefaultColumnDefinitions = (
  autoSuggestProperties?: IInvolvedUserAutoSuggestProperties,
  showDeleteButton = true,
  deleteCol = 'col-md-1',
  overrideColumns?: CommonUserTableColumns,
  overrideExtendedCSSColumns?: GridColumnDefinition,
): ColumnDefinition[] => {
  return [
    // ID, name
    ...Object.values({
      ...defaultColumnsTable(autoSuggestProperties, overrideExtendedCSSColumns),
      ...overrideColumns,
    }),
    // Delete icon
    ...(showDeleteButton
      ? [
          new ColumnDefinition(
            EInvolvedUserTableHeader.DELETE,
            `${deleteCol} rc2-text-align--right`,
            DeleteIconCell,
            new DeleteIconCellDataBinding('role', ['rc2-mar--right--2xs rc2-color--secondary']),
          ),
        ]
      : []),
  ];
};

// extended table column definitions with entitle to vote and committee transcriber cells
export const getExtendedColumnDefinitions = (
  autoSuggestProperties?: IInvolvedUserAutoSuggestProperties,
  disableTranscriberCheckBox = false,
  disableVoteCheckBox = false,
  showTranscriberCheckbox = true,
  showVoteCheckBox = true,
  showInheritToAgendaItem = false,
  disableInheritToAgendaItem = false,
  showDeleteButton = true,
  objectType?: string,
  overrideColumns?: CommonUserTableColumns,
  overrideExtendedCSSColumns?: GridColumnDefinition,
): ColumnDefinition[] => {
  let floatRight: string;
  const extendedCSSColumns: GridColumnDefinition = {
    name: 'col-md-2-5',
    subjectArea: 'col-md-2',
    department: 'col-md-2',
    telephone: 'col-md-1-5',
    email: 'col-md-2',
    ...overrideExtendedCSSColumns,
  };

  const columnDefinitions = Object.values({
    ...defaultColumnsTable(autoSuggestProperties, extendedCSSColumns),
    ...overrideColumns,
  });

  if (showVoteCheckBox) {
    columnDefinitions.push(
      new ColumnDefinition(
        EInvolvedUserTableHeader.ENTITLED_TO_VOTE,
        'col-md-0-5',
        EntitledToVoteCell,
        new EntitledToVoteCellDataBinding('entitledToVote', undefined, disableVoteCheckBox),
        'involvedUserTable.entitledToVote.header',
      ),
    );
  }

  if (showTranscriberCheckbox) {
    columnDefinitions.push(
      new ColumnDefinition(
        EInvolvedUserTableHeader.COMMITTEE_TRANSCRIBER,
        'col-md-0-5',
        CommitteeTranscriberCell,
        new CommitteeTranscriberCellDataBinding('committeeTranscriber', undefined, disableTranscriberCheckBox),
        'involvedUserTable.committeeTranscriber.header',
      ),
    );
  }
  if (objectType === EObjectType.COMMITTEE) {
    floatRight = 'float-right';
  }

  if (showInheritToAgendaItem) {
    columnDefinitions.find(d => d.id === EInvolvedUserTableHeader.DEPARTMENT).columnSize = 'col-md-1-5';
    columnDefinitions.push(
      new ColumnDefinition(
        EInvolvedUserTableHeader.INHERIT_TO_AGENDA_ITEM,
        'col-md-2',
        InheritToAgendaItemCell,
        new InheritToAgendaItemCellDataBinding('syncAgendaItem', disableInheritToAgendaItem),
        'involvedUserTable.inheritToAgendaItem.header',
      ),
    );
  }

  if (showDeleteButton) {
    columnDefinitions.push(
      // Delete icon
      new ColumnDefinition(
        EInvolvedUserTableHeader.DELETE,
        `col-md-0-5 rc2-text-align--right ${floatRight}`,
        DeleteIconCell,
        new DeleteIconCellDataBinding('role', ['rc2-mar--right--2xs rc2-color--secondary']),
      ),
    );
  }

  return columnDefinitions;
};
