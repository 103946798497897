import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-text-select-option',
  templateUrl: './text-select-option.component.html',
  styleUrls: ['./text-select-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TextSelectOptionComponent<T = unknown> {
  @Input() value: T;
  @Output() readonly selectionChange = new EventEmitter<T>();
  @HostBinding('class.selected') selected = false;

  @HostListener('click', ['$event'])
  onClick($event: Event): void {
    $event.stopPropagation();
    this.selectionChange.next(this.value);
  }

  constructor(private elementRef: ElementRef) {}

  get viewValue(): string {
    return this.geTranscludedContentContainer().textContent.trim();
  }

  private geTranscludedContentContainer(): HTMLElement {
    return this.elementRef.nativeElement.children[0];
  }
}
