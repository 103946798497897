import type { Type } from '@angular/core';
import { Directive, HostBinding, Input } from '@angular/core';

import type { Recall2TableService } from '../../tables/services/recall2-table.service';
import type { Recall2TableFilterService } from '../../tables/services/recall2-table-filter.service';
import type { Recall2TableSortService } from '../../tables/services/recall2-table-sort.service';
import type { ETableHeader } from './link-table-header.model';

/**
 * @deprecated Will be deprecated soon. Please Use IColumnDefinition
 */
export interface IColumnsDefinition {
  id: string;
  columnSize: string;
  dynamicCellComponent: Type<AbstractGenericCellViewWithData>;
  dynamicCellHeaderComponent?: any;
  headerTranslationKey?: string;
  additionalDataObject?: {};
  isSortable?: boolean;
  emit?: {};
}

export interface IColumnDefinition {
  id: string | ETableHeader;
  columnSize: string;
  dynamicCellComponent: Type<AbstractGenericCellViewWithData>;
  dataBinding?: {};
  headerTranslationKey?: string;
  dynamicCellHeaderComponent?: any;
  headerDataBinding?: {};
  isSortable?: boolean;
  replaceData?: boolean;
  sortProperty?: string;
}

export class ColumnDefinition implements IColumnDefinition {
  constructor(
    public id: string,
    public columnSize: string,
    public dynamicCellComponent: Type<AbstractGenericCellViewWithData>,
    public dataBinding?: {},
    public headerTranslationKey: string = '',
    public dynamicCellHeaderComponent?: any,
    public headerDataBinding?: {},
    public isSortable: boolean = false,
    public replaceData: boolean = false,
    public sortProperty?: string,
  ) {}
}

/**
 * @deprecated Will be deprecated soon. Please Use IExpandableContentDefinitionV2
 */
export interface IExpandableContentDefinition {
  expandableContentComponent: Type<AbstractGenericCellViewWithData>;
  expandedIdSet: Set<number>;
  additionalDataObject?: {};
}

export interface IExpandableContentDefinitionV2 {
  expandableContentComponent: Type<AbstractGenericCellViewWithData>;
  dataBinding?: {};
}

export class ExpandableContentDefinition {
  constructor(
    public expandableContentComponent: Type<AbstractGenericCellViewWithData>,
    public dataBinding?: {},
  ) {}
}

@Directive()
export abstract class AbstractGenericCellViewWithData<T = any> {
  @Input()
  private _data: T;

  public get data(): T {
    return this._data;
  }
  public set data(value: T) {
    this._data = value;
  }
  @HostBinding('class.rc2-table--cell') _applyCssClassForDataCell = true;
}

@Directive()
export abstract class AbstractIconCell<T> extends AbstractGenericCellViewWithData<T> {
  @HostBinding('class.rc2-table--icon') _applyCssClassForIcon = true;
}

export interface ICell {
  propertyName?: string;
  translationKey?: string;
}

export class CellDataBinding implements ICell {
  constructor(
    public propertyName?: string,
    public translationKey?: string,
  ) {}
}

export type InjectedServiceParams = {
  [key: string]: typeof Recall2TableService | typeof Recall2TableFilterService | typeof Recall2TableSortService;
};

export interface IResponsiveCell {
  injectedServices: InjectedServiceParams;
}

export class ResponsiveCellDataBinding implements IResponsiveCell {
  constructor(public injectedServices: InjectedServiceParams) {}
}

export class ExpansionCellDataBinding implements IResponsiveCell {
  constructor(
    public injectedServices: InjectedServiceParams,
    public expandOnlyOneItem: boolean = true,
  ) {}
}

export interface ILinkableObject {
  isLinking: boolean;
}
