import type { ComponentRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input, ViewContainerRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import type { WithFilterService, WithSortService, WithTableService } from '../tables/models/recall2-table';
import { Recall2TableFacade } from '../tables/services/recall2-table-facade';
import type { AbstractGenericCellViewWithData } from './models/dynamic-content.model';

@Component({
  selector: 'recall2-dynamic-content',
  templateUrl: './recall2-dynamic-content.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class Recall2DynamicContentComponent implements OnInit, OnChanges {
  @Input() component: any;
  @Input() data: any;
  @Input() dataBinding: any;
  @Input() tableFacade: Recall2TableFacade;
  @Input() index: number;
  @Input() replaceData = false;
  componentRef: ComponentRef<any>;

  constructor(private viewContainerRef: ViewContainerRef) {}

  private updateDataBinding(dataBinding: object): void {
    for (const propertyName of Object.keys(dataBinding)) {
      if (propertyName === 'data') {
        throw new Error("field 'data' must not be overridden");
      }
      // Beware if the name not match the variable will not set
      this.componentRef.instance[propertyName] = this.dataBinding[propertyName];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataBindingChange = changes['dataBinding'];
    if (dataBindingChange && !dataBindingChange.isFirstChange()) {
      this.updateDataBinding(dataBindingChange.currentValue);
    }
  }

  ngOnInit(): void {
    this.componentRef = this.viewContainerRef.createComponent(this.component);
    if (this.data && this.dataBinding && this.replaceData) {
      const replaceDataFunction = Object.values(this.dataBinding).find(value => typeof value === 'function');
      if (typeof replaceDataFunction === 'function') {
        this.data = replaceDataFunction(this.data);
        this.data.position = this.index + 1;
      }
    }

    if (this.data) {
      (this.componentRef.instance as AbstractGenericCellViewWithData).data = this.data;
    }
    if (this.dataBinding) {
      this.updateDataBinding(this.dataBinding);
    }
    if (this.withTableService(this.componentRef.instance)) {
      this.componentRef.instance.setTableService(this.tableFacade.getTableService());
    }
    if (this.withFilterService(this.componentRef.instance)) {
      this.componentRef.instance.setFilterService(this.tableFacade.getFilterService());
    }
    if (this.withSortService(this.componentRef.instance)) {
      this.componentRef.instance.setTSortService(this.tableFacade.getSortService());
    }
  }

  withTableService(object: unknown): object is WithTableService {
    const myInterface = object as WithTableService;
    return myInterface.setTableService !== undefined;
  }

  withFilterService(object: unknown): object is WithFilterService {
    const myInterface = object as WithFilterService;
    return myInterface.setFilterService !== undefined;
  }

  withSortService(object: unknown): object is WithSortService {
    const myInterface = object as WithSortService;
    return myInterface.setTSortService !== undefined;
  }
}
