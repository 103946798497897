<div
  data-testid="chip"
  class="chip-wrapper"
  [ngClass]="{'chip-wrapper--readonly': isReadOnly, 'is-more-filter': isMoreFilters}"
>
  <span
    class="chip-text"
    data-cy="chip-text"
  >
    <ng-content></ng-content>
  </span>
  <button
    *ngIf="!isReadOnly"
    data-testid="remove-chip"
    data-cy="chip-remove-button"
    (click)="removeItem($event)"
  >
    <recall2-svg-icon
      name="delete-tag"
      size="small"
      class="recall2-chip-remove"
    >
    </recall2-svg-icon>
  </button>
</div>
