import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@recall2/globals';
import type { AttachmentFileForDownloadList } from '@recall2/ui/attachment';
import { apiCommitteeMeetingUrl } from '@recall2/ui/core/api/config';
import type { Page } from '@recall2/ui/core/api/models';
import { VerificationTaskTab } from '@recall2/ui/navbar';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { apiCampaignUrl, apiPreliminaryAttachmentUrl, apiTopicAttachmentUrl } from '../../config';
import type { AttachmentType } from '../../enums';
import { AttachmentLocationPaths } from '../../enums';
import type { AttachmentStatus } from '../../enums/attachment-status.enum';
import type {
  AttachmentFile,
  AttachmentFileDTO,
  AttachmentUploadInformation,
  CampaignId,
  VerificationTaskId,
} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  readonly apiVersion = 'v1';
  private apiUrl: string;

  constructor(
    @Inject(WINDOW) private window: Window,
    private http: HttpClient,
  ) {
    this.setApiUrlByLocationBasePath();
  }

  getAttachments(
    attachmentType: AttachmentType,
    objectId: CampaignId | VerificationTaskId,
  ): Observable<Page<AttachmentFile>> {
    this.setApiUrlByLocationBasePath();
    return this.http.get<Page<AttachmentFile>>(
      `${this.apiUrl}/${this.apiVersion}/attachment/${attachmentType}/${objectId}`,
    );
  }

  getAttachmentsForPhase1(
    objectId: string | number,
    apiUrl: string,
    attachmentType?: AttachmentType,
  ): Observable<{ attachments: AttachmentFile[] }> {
    const url = attachmentType
      ? `${apiUrl}/attachments/${attachmentType}/${objectId}`
      : `${apiUrl}/attachments/${objectId}`;
    return this.http.get<{ attachments: AttachmentFile[] }>(url);
  }

  deletePersistedAttachment(
    attachmentObjectType: AttachmentType,
    objectId: CampaignId | VerificationTaskId,
    attachmentId: string,
  ): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/${this.apiVersion}/attachment/${attachmentObjectType}/${objectId}/${attachmentId}/delete`,
    );
  }

  deletePersistedOrphanAttachment(attachmentObjectType: AttachmentType, attachmentId: string): Observable<void> {
    return this.http.delete<void>(
      `${this.apiUrl}/${this.apiVersion}/attachment/orphan/${attachmentObjectType}/${attachmentId}/delete`,
    );
  }

  saveAttachments(
    attachmentObjectType: AttachmentType,
    attachments: AttachmentFileDTO[],
    objectId?: CampaignId | VerificationTaskId,
  ): Observable<AttachmentFile[]> {
    const oldestFirstAttachments = attachments.reverse();
    const endpoint = this.getSaveEndpoint(attachmentObjectType, objectId);
    return this.http.post<AttachmentFile[]>(endpoint, oldestFirstAttachments).pipe(
      map(res => {
        const newestFirstAttachments = res.reverse();
        return newestFirstAttachments;
      }),
    );
  }

  downloadAttachment(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadAttachmentForPhase1(url: string, file: AttachmentFile): Observable<AttachmentFileForDownloadList> {
    return this.http.post<AttachmentFileForDownloadList>(url, { attachments: [file] });
  }

  deleteTemporalAttachment(key: string): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        key: key,
      },
    };

    return this.http.delete<void>(`${this.apiUrl}/${this.apiVersion}/attachment/delete`, options);
  }

  getDownloadInformation(key: string): Observable<{ url: string; status: AttachmentStatus }> {
    return this.http.get<{ url: string; status: AttachmentStatus }>(
      `${this.apiUrl}/${this.apiVersion}/attachment/downloadInformation?key=${key}`,
    );
  }

  getUploadInformation(): Observable<AttachmentUploadInformation> {
    return this.http.get<AttachmentUploadInformation>(`${this.apiUrl}/${this.apiVersion}/attachment/uploadInformation`);
  }

  private setApiUrlByLocationBasePath(): void {
    const locationBasePath = this.getLocationBasePath();
    const locationTab = this.getLocationTab();
    switch (locationBasePath) {
      case AttachmentLocationPaths.CAMPAIGN:
      case AttachmentLocationPaths.COORDINATION:
        this.apiUrl = apiCampaignUrl;
        break;
      case AttachmentLocationPaths.PRELIMINARY:
        this.apiUrl = apiPreliminaryAttachmentUrl;
        break;
      case AttachmentLocationPaths.TOPIC:
        if (this.window.location.pathname.includes('verification-tasks')) {
          this.apiUrl = locationTab === VerificationTaskTab.CAMPAIGN ? apiCampaignUrl : apiTopicAttachmentUrl;
        } else {
          this.apiUrl = apiTopicAttachmentUrl;
        }
        break;
      case AttachmentLocationPaths.COMMITTEE:
        this.apiUrl = apiCommitteeMeetingUrl;
        break;
      default:
        throw new Error('[AttachmentsService] Unkown location path');
    }
  }

  private getLocationBasePath(): string {
    return this.window.location.pathname.split('/')[1];
  }

  private getLocationTab(): string {
    const params = new URLSearchParams(this.window.location.search);
    return params.has('tab') ? params.get('tab') : null;
  }

  private getSaveEndpoint(attachmentObjectType: AttachmentType, objectId: CampaignId | VerificationTaskId): string {
    return objectId
      ? `${this.apiUrl}/${this.apiVersion}/attachment/${attachmentObjectType}/${objectId}/save`
      : `${this.apiUrl}/${this.apiVersion}/attachment/${attachmentObjectType}/save-orphan`;
  }
}
