export interface IRecall2Sort {
  identifier: string;
  sortingOrder: Recall2SortingOrder;
}

export class Recall2Sort {
  constructor(
    public identifier: string,
    public sortingOrder: Recall2SortingOrder,
  ) {}
}

export enum Recall2SortingOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}
