import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import type { Observable } from 'rxjs';

import { EObjectType } from '../../dynamic-content/models/object-type.model';
import { VerificationTaskTab } from '../../navbar/models/verification-task-tab.model';
import type { ListResponse } from '../../utils';
import { mapListResponse } from '../../utils';
import { COMMENTS_URL_BUILDER, HISTORY_URL_BUILDER } from '../injection-tokens';
import type { ObjectEvent, ObjectType } from '../models/object-event';
import { CommentsUrlBuilder, HistoryUrlBuilder } from '../models/url-builders';

@Injectable({
  providedIn: 'root',
})
export class ObjectHistoryService {
  readonly ITEMS_PER_PAGE = 10;

  constructor(
    private http: HttpClient,
    @Inject(HISTORY_URL_BUILDER) private buildHistoryUrl: HistoryUrlBuilder,
    @Inject(COMMENTS_URL_BUILDER) private buildCommentsUrl: CommentsUrlBuilder,
    private activatedRoute: ActivatedRoute,
  ) {}

  getItems(objectType: ObjectType, objectId: number, verificationTaskType?: ObjectType): Observable<ObjectEvent[]> {
    let url = this.buildHistoryUrl(objectType, objectId, verificationTaskType);
    const activeTab = this.activatedRoute.snapshot.queryParams['tab'];
    // It only will be done if it's a VT and also if user is in Overview VTs. Otherwise, the objectType won't match and also activeTab sould be undefined.
    if (objectType === EObjectType.VERIFICATION_TASK && activeTab === VerificationTaskTab.AGENDA_ITEM) {
      url = `/api-committee-meeting/verification-tasks/${objectId}/agenda-items/events`;
    }

    return this.http.get<ListResponse<ObjectEvent>>(url).pipe(mapListResponse());
  }

  addComment(
    objectType: ObjectType,
    objectId: number,
    comment: string,
    verificationTaskType?: ObjectType,
  ): Observable<ObjectEvent> {
    const url = this.buildCommentsUrl(objectType, objectId, verificationTaskType);
    return this.http.post<ObjectEvent>(url, { comment });
  }
}
