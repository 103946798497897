import type { HttpErrorResponse } from '@angular/common/http';
import type { Action } from '@ngrx/store';

import type { UserData } from './user.state';

export enum UserActionTypes {
  GetUser = '[Users] get user',
  GetUserSuccess = '[Users] get user success',
  GetUserFailed = '[Users] get user failed',
}

export class GetUserAction implements Action {
  readonly type = UserActionTypes.GetUser;
}

export class GetUserSuccessAction implements Action {
  readonly type = UserActionTypes.GetUserSuccess;

  constructor(public user: UserData) {}
}

export class GetUserFailedAction implements Action {
  readonly type = UserActionTypes.GetUserFailed;

  constructor(public errorResponse?: HttpErrorResponse) {}
}

// action types
export type UserActions = GetUserAction | GetUserSuccessAction | GetUserFailedAction;
