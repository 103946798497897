<div class="rc2-display--flex rc2-display--flex-column">
  <recall2-buttons-toggle-group
    [isRangeSwitcherMode]="true"
    [selectedValue]="selectedFilterComparator"
    (selectedToggleButton)="onFilterComparatorChange($event)"
    data-cy="number-filter-button-toggle-group"
  >
    <recall2-toggle-element
      *ngFor="let option of filterNumberOptions"
      [value]="option.value"
      [label]="option.label | translate"
      [attr.data-cy]="option.id"
    ></recall2-toggle-element>
  </recall2-buttons-toggle-group>

  <recall2-input-number data-cy="number-filter-input" [property]="filterInputNumberProperty"></recall2-input-number>
</div>
