import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-invalid',
  templateUrl: './recall2-icon-invalid.component.html',
  standalone: true,
})
export class Recall2IconInvalidComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i116-invalid');
  }
}
