<ng-container [ngSwitch]="currentStatus">
  <div *ngSwitchCase="allStatus.PROCESSING" class="rc2-ellipsis">
    <recall2-icon-status-unfilled
      *ngIf="isTopic; else filledIcon"
      rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--progress"
    >
    </recall2-icon-status-unfilled>
    <ng-template #filledIcon>
      <recall2-icon-status #filledIcon rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--progress">
      </recall2-icon-status>
    </ng-template>
    <span class="rc2-font--size--14">{{ 'general.state.processing' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.DRAFT" class="rc2-ellipsis">
    <recall2-icon-status
      rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--draft rc2-icon--state--campaign--draft"
    >
    </recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.draft' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.RESOLVED" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--completed">
    </recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.resolved' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.REVIEW" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--notification--review"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.review' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.TO_REVISE_REVIEWER" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--notification--rework-p">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.rework.reviewer' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.TO_REVISE_BRAND_OFFICE" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--notification--rework-gs">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.rework.brandOffice' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.ACCEPTED" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--notification--accepted">
    </recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.accepted' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.LINKED" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--notification--linked">
    </recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.linked' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.APPROVED" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--notification--approved">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.approved' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.AWAIT_DECISION" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--topic--decision">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.await_decision' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.COMPLETED" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--topic--completed"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.completed' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.IN_PROGRESS" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--topic--progress"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.in_progress' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.DRAFT_PROGRESS" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--topic--progress"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.draft_progress' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.ACTIVE" class="rc2-ellipsis">
    <recall2-icon-status *ngIf="!isCampaign" rc2IconStyles="rc2-icon--size--24 rc2-icon--state--committee--active">
    </recall2-icon-status>
    <recall2-icon-status-unfilled
      *ngIf="isCampaign"
      rc2IconStyles="rc2-icon--size--24 rc2-icon--state--campaign--active"
    >
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.active' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.INACTIVE" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--committee--inactive"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.inactive' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.FINAL" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-color--success"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.final' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.DISCARDED" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--notification--discarded">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.discarded' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.STOPPED" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--campaign--stopped">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.stopped' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.IN_PREPARATION" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--campaign--in-preparation">
    </recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.in_preparation' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.READY" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--campaign--ready"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.ready' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.CLOSED" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--campaign--closed"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.closed' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.UNANSWERED" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--unanswered">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.unanswered' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.DECLINED" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--declined">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.declined' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.RELEASED" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--campaign--released"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.released' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.INVITED" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-color--attention"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'meeting.overview.table.status.INVITED' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.PLANNING" class="rc2-ellipsis">
    <recall2-icon-status rc2IconStyles="rc2-icon--size--24 rc2-icon--state--topic--planning"></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.planning' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.PENDING" class="rc2-ellipsis">
    <recall2-icon-status
      rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--progress"
    ></recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.pending' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.CANCELLED" class="rc2-ellipsis">
    <recall2-icon-status-unfilled rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--declined">
    </recall2-icon-status-unfilled>
    <span class="rc2-font--size--14">{{ 'general.state.cancelled' | translate }}</span>
  </div>
  <div *ngSwitchCase="allStatus.DENIED" class="rc2-ellipsis">
    <recall2-icon-status
      rc2IconStyles="rc2-icon--size--24 rc2-icon--state--verification--draft rc2-icon--state--campaign--draft"
    >
    </recall2-icon-status>
    <span class="rc2-font--size--14">{{ 'general.state.declined' | translate }}</span>
  </div>
</ng-container>
