import { Injectable } from '@angular/core';
import type { UrlSegment } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RouterHistory {
  private static backJumpStorageAttribute = 'rc2-back-jump-internal';

  public static setPrecedingAddress(url: string): void {
    if (!url) {
      throw new Error('no url provided');
    }

    localStorage.setItem(this.backJumpStorageAttribute, url);
  }

  public static popPrecedingAddress(): string {
    const precedingAddress = localStorage.getItem(this.backJumpStorageAttribute);

    localStorage.removeItem(this.backJumpStorageAttribute);

    return precedingAddress;
  }

  public static setBackJumpAddress(url: UrlSegment[]) {
    const isWelcome = url.find(urlSegment => {
      return urlSegment.path === 'welcome';
    });
    localStorage.setItem(this.backJumpStorageAttribute, isWelcome ? 'welcome' : 'notifications');
  }

  public static getBackJumpAddress() {
    return localStorage.getItem(this.backJumpStorageAttribute);
  }
}
