import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-logo-scania',
  templateUrl: './recall2-icon-logo-scania.component.html',
  styleUrls: ['./recall2-icon-logo-scania.component.scss'],
  standalone: true,
})
export class Recall2IconLogoScaniaComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i115-logo-scania');
  }
}
