import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as uuid from 'uuid';

import type { Toast, ToastContent, ToastType } from '../../models/toast.model';

const duration = 7000;
const maxToasts = 4;

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private toastsSubject = new Subject<Toast[]>();
  toasts$ = this.toastsSubject.asObservable();

  private toasts: Toast[] = [];

  info({ title, content }: ToastContent): Toast {
    return this.createToast(title, content, 'info');
  }

  error({ title, content }: ToastContent): Toast {
    return this.createToast(title, content, 'error');
  }

  success({ title, content }: ToastContent): Toast {
    return this.createToast(title, content, 'success');
  }

  warning({ title, content }: ToastContent): Toast {
    return this.createToast(title, content, 'warning');
  }

  message(type: ToastType, { title, content }: ToastContent): Toast {
    return this.createToast(title, content, type);
  }

  close(id: string): void {
    this.toasts = this.toasts.filter(toast => toast.id !== id);
    this.toastsSubject.next(this.toasts);
  }

  private createToast(title: string, message: string, type: ToastType): Toast {
    const toast: Toast = {
      type,
      title,
      message,
      duration,
      id: uuid.v4(),
    };

    const length = this.toasts.unshift(toast);
    if (length > maxToasts) {
      this.toasts.pop();
    }

    setTimeout(() => {
      this.close(toast.id);
    }, duration);

    this.toastsSubject.next(this.toasts);

    return toast;
  }
}
