<div class="row">
  <div
    class="col-md-3 h3 rc2-display--inline--block mar-no"
    *ngIf="showTitle"
  >
    <span id="users-table-title">{{ titleI18nKey | translate}}</span>
  </div>
  <div
    class="col-md-9"
    *ngIf="!isSummaryTable"
  >
    <div class="row justify-content-end">
      <div
        class="col-md-9 pad-bottom-erase rc2-pad--right--l"
        *ngIf="attendanceColumn"
      >
        <recall2-three-step-checkbox
          class="float--right"
          [property]="allPresentProperty"
          [labelAlignment]="alignmentTypes.LEFT"
          (onChange)="handleMaster($event)"
        ></recall2-three-step-checkbox>
      </div>
      <div
        class="col-md-3 pad-bottom-erase"
        id="add-button-container"
        *ngIf="showAddButton"
      >
        <recall2-button-outline-add
          class="float--right"
          [ngClass]="{'rc2-color--grey-200-tertiary rc2-icon--cursor--not-allowed':!isAddAllowed}"
          (click)="addNewEditRow()"
        ></recall2-button-outline-add>
      </div>
    </div>
  </div>
</div>

<!-- Involved Users Table-->
<div class="row overflow-visible mar-no">
  <div class="col-md-12 pad-left-erase pad-right-erase pad-bottom-erase">
    <recall2-basic-table
      id="users-table"
      [tableFacade]="tableFacade"
    ></recall2-basic-table>
  </div>
</div>
