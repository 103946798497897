import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FullNamePipe, MarkEmptyPipe } from '../../../../pipes';
import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';

@Component({
  selector: 'authors-ful-name-cell',
  templateUrl: './authors-full-name-cell.component.html',
  standalone: true,
  imports: [MarkEmptyPipe, MatTooltipModule],
})
export class AuthorsFullNameCellComponent
  extends AbstractGenericCellViewWithData
  implements IAuthorsFullNameCelllBinding, OnInit
{
  propertyName: string;

  authorsString: string;

  ngOnInit(): void {
    const authorsList: {
      firstName: string;
      lastName: string;
    }[] = this.data[this.propertyName];

    if (!authorsList) {
      return;
    }

    this.authorsString = authorsList.map(author => new FullNamePipe().transform(author)).join(' / ');
  }
}

export class AuthorsFullNameCellBinding implements IAuthorsFullNameCelllBinding {
  constructor(public propertyName: string) {}
}

interface IAuthorsFullNameCelllBinding {
  propertyName: string;
}
