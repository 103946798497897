import type { ERole } from '@recall2/ui/navbar';

export interface UserData {
  id: number;
  uuid: string;
  created: string;
  updated: string;
  roles: ERole[];
  lastLogin: string;
  tel: string;
  email: string;
  salutation: string;
  title: string;
  language: string;
  country: string;
  vwUserId: string;
  firstName: string;
  lastName: string;
  department: string;
  dsgvoRead: boolean;
  active: boolean;
  unknownUser?: boolean;
}

/**
 * Defines the authentication store state.
 * @type {{user: UserData}}
 */
export interface IUserState {
  user: UserData;
}

/**
 * Defines the initial authentication state for the store.
 */
export const initialUserState: IUserState = {
  user: undefined,
};
