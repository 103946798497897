import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

import { InputNumberProperty } from '../../../../form/model';
import { Recall2YearPickerComponent } from '../../../../year-picker';

@Component({
  selector: 'recall2-filter-year',
  templateUrl: './recall2-filter-year.component.html',
  standalone: true,
  imports: [Recall2YearPickerComponent],
})
export class Recall2FilterYearComponent {
  @Input() selectedYear: InputNumberProperty = Recall2FilterYearComponent.defaultYearProperty();

  private static defaultYearProperty(): InputNumberProperty {
    return new InputNumberProperty(
      'default',
      false,
      'shared.table-filters.year-property',
      { min: 1970, max: new Date().getFullYear(), maxLength: 4 },
      false,
      new FormControl({ value: null, disabled: false }),
      false,
    );
  }
}
