<div [class.is-opened]="isOpen">
  <label
    *ngIf="property.hasTitle"
    id="{{ property.name }}_label"
    class="title-label"
    [class.title-label--disabled]="isDisabled || property.control.disabled"
  >
    {{ property.translationKey + '.label' | translate }}
    <recall2-icon-required *ngIf="property.required"></recall2-icon-required>
  </label>
  <recall2-icon-help-label-form
    *ngIf="property.hasTooltip"
    id="{{ property.name }}_help_icon"
    matTooltip="{{ property.translationKey + '.tooltip' | translate }}"
    matTooltipPosition="above"
    matTooltipClass="rc2-tooltip"
  >
  </recall2-icon-help-label-form>
  <div class="rc2-position--relative rc2-mar--top--3xs">
    <recall2-icon-calendar
      #calendar
      data-testid="calendar-icon"
      [class.active]="inputHasFocus"
      [class.disabled]="isDisabled || property.control.disabled"
      [class.error]="showErrors"
      [rc2IconStyles]="'rc2-icon--size--24'"
      (click)="onClickCalendarIcon()"
    >
    </recall2-icon-calendar>
  </div>

  <input
    #input
    [ngClass]="{
      'show-error-on-tooltip': showErrors && property.showErrorOnTooltip,
      'ng-invalid': property.control.invalid
    }"
    [value]="property.control.value | date: dateFormat"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    id="{{ property.name }}"
    type="text"
    autocomplete="off"
    placeholder="{{ isDisabled || property.control.disabled ? '-' : ('shared.datepicker.placeholder' | translate) }}"
    maxlength="10"
    [disabled]="isDisabled || property.control.disabled"
    (input)="onInputChange($event)"
    (keypress)="checkValidKeyPressed($event)"
    (keyup)="replaceInvalidChars()"
    (keydown.Enter)="onEnterPressed($event)"
    (focus)="onInputFocus()"
    (blur)="onInputBlur($event)"
    data-cy="datepicker-input"
  />

  <ng-container *ngIf="showErrors && errorType && !isDisabled">
    <div *ngIf="!property.showErrorOnTooltip; else showErrorOnTooltip" class="error-wrapper" data-cy="datepicker-error">
      <recall2-icon-invalid></recall2-icon-invalid>
      <span class="error-message">{{ 'form.error.generic.' + errorType | translate: property.control.errors }}</span>
    </div>
  </ng-container>

  <ng-template #showErrorOnTooltip>
    <p class="show-error-on-tooltip-warning-icon">
      <recall2-svg-icon
        matTooltipPosition="above"
        matTooltip="{{ 'form.error.generic.' + errorType | translate: property.control.errors }}"
        matTooltipClass="rc2-tooltip-error"
        name="warning"
        size="medium"
      ></recall2-svg-icon>
    </p>
  </ng-template>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  (overlayOutsideClick)="onClickOutside($event)"
  (detach)="closeDatepicker()"
>
  <guic-datepicker
    #guicDatepicker
    *ngIf="isOpen"
    [locale]="locale"
    [currentDate]="selectedDate"
    [min]="property?.minDate"
    [max]="property?.maxDate"
    [isRangedDate]="property?.isRangedDate"
    (dateSelected)="onDateSelected($event)"
    (cancelClicked)="closeDatepicker()"
  ></guic-datepicker>
</ng-template>
