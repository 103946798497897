import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-check',
  templateUrl: './recall2-icon-check.component.html',
  standalone: true,
})
export class Recall2IconCheckComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i18-state-check');
  }
}
