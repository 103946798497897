<div class="rc2-overview-wrapper">
  <div class="row pad-bottom-medium rc2-mar--bottom--xl">
    <div class="col-md-12 text-center">
      <h1
        id="landing-page-title"
        class="rc2-color--grey-600"
      >
        {{ 'landing.title' | translate }}
      </h1>
    </div>
  </div>
  <div class="row pad-bottom-medium rc2-mar--bottom--xl">
    <div class="col-md-12 text-center">
      <h2>{{ 'landing.subtitle' | translate }}</h2>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <h3 class="rc2-pad--bottom--3xl">
        {{ 'landing.apsReason.heading' | translate }}
      </h3>
      <p class="pad-bottom-medium">
        {{ 'landing.apsReason.body' | translate }}
      </p>
      <p class="pad-bottom-medium">
        {{ 'landing.apsReason.appendix' | translate }}
      </p>
      <h3 class="pad-bottom-medium">
        {{ 'landing.apsProcess.heading' | translate }}
      </h3>
      <p class="pad-bottom-medium">
        {{ 'landing.apsProcess.body' | translate }}
        <span class="column-link">{{ 'landing.apsProcess.appendix' | translate }}</span>
        {{ 'landing.apsProcess.appendix02' | translate }}
      </p>
    </div>

    <div class="col-md-4">
      <h3 class="pad-bottom-medium">
        {{ 'landing.apsPermissionSend.heading' | translate }}
      </h3>
      <p class="pad-bottom-medium">
        {{ 'landing.apsPermissionSend.body' | translate }}
      </p>
      <ul class="pad-bottom-medium">
        <li>
          <span>{{ 'landing.apsPermissionSend.productionSubjectInfo' | translate }}</span>
        </li>
        <li>
          <span>{{ 'landing.apsPermissionSend.developmentSubjectInfo' | translate }}</span>
        </li>
        <li>
          <span>{{ 'landing.apsPermissionSend.qualitySubjectInfo' | translate }}</span>
        </li>
      </ul>
      <p class="pad-bottom-medium">
        {{ 'landing.apsPermissionSend.appendix' | translate }}
      </p>
    </div>

    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
          <h3 class="rc2-pad--bottom--3xl">{{ 'landing.nextSteps.heading' | translate }}</h3>
          <p class="pad-bottom-medium">{{ 'landing.nextSteps.body' | translate }}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3 class="pad-bottom-medium">{{ 'landing.messageTracking.heading' | translate }}</h3>
          <p class="pad-bottom-medium">{{ 'landing.messageTracking.body' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <h3 class="rc2-pad--top--s">
    {{ 'landing.aps.download.title' | translate }}
  </h3>
  <div class="rc2-separator rc2-pad--top--l"></div>
  <div
    class="row rc2-pad--top--s"
    id="brandManufacturerContainer"
  >
    <div class="col-md-4">
      {{ 'landing.aps.download.manufacturer-description' | translate }}
    </div>
    <div class="col-md-8">
      <div class="row">
        <recall2-button-brand-manufacturer
          *ngFor="let brand of brandsAPS"
          [attr.data-cy]="'brand-' + brand.name"
          [brand]="brand"
          (downloadEventEmitter)="onSelectBrand($event)"
        ></recall2-button-brand-manufacturer>
      </div>

      <div
        class="row rc2-mar--top--xxl"
        *ngIf="showSelectorLang || showSelectorManufacturers"
      >
        <div
          class="col-md-4"
          *ngIf="showSelectorManufacturers"
        >
          <recall2-radio-button-group-v2
            class="rc2-full--width"
            [attr.data-cy]="'brand-manufacturer-selector'"
            [property]="selectorManufacturersRadioButtonV2GroupProperty"
            (radioChanged)="onChangeManufacturer($event)"
          ></recall2-radio-button-group-v2>
        </div>
        <div
          class="col-md-5"
          *ngIf="showSelectorLang"
        >
          <recall2-radio-button-group-v2
            class="rc2-full--width"
            [attr.data-cy]="'language-selector'"
            [property]="selectorLanguagesRadioButtonV2GroupProperty"
            (radioChanged)="onChangeLanguage($event)"
          ></recall2-radio-button-group-v2>
        </div>
      </div>
    </div>
  </div>

  <div class="row rc2-pad--top--4xl rc2-mar--bottom--xl">
    <div class="col-md-12 text-center">
      <button
        id="create-button"
        class="create-button"
        type="submit"
        (click)="onCreateNewNotification()"
      >
        <recall2-icon-add-circle class="rc2-font--size--52"></recall2-icon-add-circle>
        <span class="button-text"> {{ 'landing.button-create' | translate | uppercase }}</span>
      </button>
    </div>
  </div>
  <div class="row rc2-mar--bottom--xl">
    <div class="col-md-12 text-center">
      <recall2-icon-help></recall2-icon-help>
      <span class="rc2-font--family--bold">
        {{ 'landing.help.heading' | translate }}
      </span>
      <span>
        {{ 'landing.help.body.site' | translate }}
      </span>
      <span class="rc2-font--family--bold rc2-icon--color--blue">
        <a
          [href]="supportLink()"
          rel="noopener"
          target="_blank"
        >
          {{ 'landing.help.body.support' | translate }}
        </a>
      </span>
      <p>
        {{ 'landing.help.body.contact' | translate }}
      </p>
    </div>
  </div>
</div>
