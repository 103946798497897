import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2IconAddAttachmentComponent } from '../../../icons/recall2-icon-add-attachment/recall2-icon-add-attachment.component';
import { Recall2LoadingSpinnerComponent } from '../../../loading-spinner/recall2-loading-spinner.component';
import { ALLOWED_EXTENSIONS } from '../attachment/file-validation';

@Component({
  selector: 'recall2-upload-area',
  templateUrl: './upload-area.component.html',
  styleUrls: ['./upload-area.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule, Recall2IconAddAttachmentComponent, Recall2LoadingSpinnerComponent],
})
export class UploadAreaComponent {
  allowedExtensions = ALLOWED_EXTENSIONS.join(',');
  @Output() newFiles = new EventEmitter<File[]>();
  @Input() loading: boolean;

  onChange(event): void {
    this.newFiles.emit([...event.target.files]);
  }
}
