import { NgIf } from '@angular/common';
import { Component } from '@angular/core';

import { AbstractGenericCellViewWithData } from '../../../dynamic-content/models/dynamic-content.model';
import { EThreeStepCheckboxStates } from '../../../form/model';

@Component({
  selector: 'topic-id-cell',
  templateUrl: './manufacturer-topic-id.cell.html',
  standalone: true,
  imports: [NgIf],
})
export class ManufacturerTopicIdCellComponent extends AbstractGenericCellViewWithData implements ITopicIdCellBinding {
  propertyName: string;
  public readonly threeStepCheckboxStateSelected = EThreeStepCheckboxStates.SELECTED;
}

export class TopicIdCellBinding implements ITopicIdCellBinding {
  constructor(public propertyName: string) {}
}

interface ITopicIdCellBinding {
  propertyName: string;
}
