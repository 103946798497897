<div class="rc2-display--flex rc2-align-items--center">
  <span class="rc2-align-self-center rc2-pad--right--xs">
    <recall2-icon-objekt-benutzer [rc2IconStyles]="'rc2-icon--size--24 rc2-color--secondary'">
    </recall2-icon-objekt-benutzer>
  </span>
  <div class="rc2-ellipsis auto-sugest-cell-icon" *ngIf="!data?.isEditable">
    {{ data.vwUserId | userName: data.lastName : data.firstName }}
  </div>
  <div class="rc2-table-cell input-width" *ngIf="data?.isEditable">
    <recall2-auto-suggest
      class="userAutoSuggest"
      [entries]="entries"
      [isSpinnerShowing]="isSpinnerShowing"
      [objectType]="autoSuggestObjectType.USER"
      [configuration]="configuration"
      [dataToSkip]="dataToSkip"
      (updateSearchTerm)="onSearchTermChanged($event)"
      (selectedEntry)="updateEditableRow($event)"
    >
    </recall2-auto-suggest>
  </div>
</div>
