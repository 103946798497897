<label
  data-cy="tags-title"
  data-testid="tags-title"
  *ngIf="title"
  [ngClass]="{ 'mar-bottom-erase': true }"
><span>{{ title }}</span>
  <recall2-icon-help
    *ngIf="tooltip"
    data-cy="tags-help-icon"
    [matTooltip]="tooltip"
    matTooltipClass="rc2-tooltip"
    matTooltipPosition="above"
  >
  </recall2-icon-help>
</label>
<div
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <div
    *ngIf="useInput && !isReadOnly; else onlyChips"
    data-cy="tags-form-field"
    data-testid="tags-form-field"
    class="form-field-chips"
    [ngClass]="{
    'focused': inputTextFocused,
    'show-border': showBorder
  }"
    #formFieldChips
    (click)="autoFocus()"
  >
    <ng-container [ngTemplateOutlet]="chips"></ng-container>
    <input
      #input
      data-cy="tags-input"
      data-testid="tags-input"
      [ngClass]="{ 'without-tags': !currentTags?.length, 'hide-input': !inputTextFocused && currentTags?.length }"
      [placeholder]="!currentTags?.length && placeholder ? placeholder : ''"
      (focusout)="isInputFocused(false)"
      (focusin)="isInputFocused(true)"
      (keydown)="addTagWithEnter($event)"
      (keypress)="onInputKeyPress()"
      [(ngModel)]="inputText"
      (ngModelChange)="search()"
      [maxlength]="maxLength"
    >
    <recall2-svg-icon
      *ngIf="isAutoSuggestOpen && currentTags?.length"
      data-cy="tags-clear-icon"
      name="clear"
      size="medium"
      data-testid="tags-clear-icon"
      class="recall2-tags-clear"
      (click)="removeAll($event)"
    >
    </recall2-svg-icon>
  </div>
</div>

<ng-template #onlyChips>
  <div
    class="rc2-pad--top--xs"
    *ngIf="currentTags?.length === 0"
  >-</div>
  <div
    data-cy="tags-chips"
    class="chips-container"
    [class.has-more-filters]="isMoreFilters"
  >
    <ng-container [ngTemplateOutlet]="chips"></ng-container>
  </div>
</ng-template>
<ng-template #chips>
  <recall2-chip
    [isReadOnly]="isReadOnly"
    [isMoreFilters]="isMoreFilters"
    data-cy="tags-chip"
    *ngFor="let currentTag of currentTags; index as i"
    (remove)="remove(currentTag)"
  >
    <span
      matTooltipClass="rc2-tooltip"
      matTooltipPosition="above"
      [matTooltip]="currentTagsObjects[i]?.label ?? currentTag"
    >
      {{currentTagsObjects[i]?.label ?? currentTag}}
    </span>
  </recall2-chip>
</ng-template>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="showAutoSuggest && isAutoSuggestOpen"
  [cdkConnectedOverlayMinWidth]="formFieldChipsWidth"
  [cdkConnectedOverlayBackdropClass]="'tags-autosuggest-backdrop'"
  (backdropClick)="onBackdropClick()"
>
  <ul
    *ngIf="inputText?.length"
    data-cy="tags-list"
    data-testid="tags-list"
    class="tags-list"
  >
    <li
      *ngIf="inputText?.length"
      data-cy="tags-item"
      data-testid="tags-item"
    >
      <div (click)="addTagFromInput()">{{inputText}} ({{ 'tags.new.label' | translate }})</div>
    </li>
    <li
      *ngIf="autoSuggestTags === null"
      data-cy="tags-list-loading"
      class="spinner spinner-box"
    ></li>
    <ng-container *ngIf="autoSuggestTags !== null">
      <li *ngFor="let item of (autoSuggestTags | filterAutoSuggest: currentTags)">
        <div (click)="addTag(item.text)">{{item.text}}</div>
      </li>
    </ng-container>
  </ul>
</ng-template>
