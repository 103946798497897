import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[clickOutside]',
  standalone: true,
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<MouseEvent>();

  editActionWasJustToggled: boolean;

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    if (this.editActionWasJustToggled) {
      this.editActionWasJustToggled = false;
    } else {
      const targetElement = event.target as HTMLElement;

      if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
        this.clickOutside.emit(event);
      }
    }
  }
}
