import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, EMPTY, of, ReplaySubject } from 'rxjs';
import { catchError, finalize, map, scan, shareReplay, startWith, tap } from 'rxjs/operators';

import type { AttachmentFile, AttachmentFileForDownloadList, AttachmentList } from '../models/attachment-file.model';
import { download, toBlob } from '../utils/download-utils';
import { APP_BASE_URL, FILE_INFORMATION_URL } from './injection-tokens';

type TemporaryAttachmentFile = AttachmentFile;

/**
 * Id of the object for which attachments are
 * uploaded or fetched. The object can be a
 * campaign, agenda item etc.
 */
type ObjectId = number;

type UploadStarted = 1;
type UploadFinshed = -1;
type UploadEvent = UploadStarted | UploadFinshed;
type NumberOfStillUploadingFiles = number;

const UPLOAD_IN_PROGRESS: UploadStarted = 1;
const UPLOAD_FINISHED: UploadFinshed = -1;

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  objectId: ObjectId;
  private files: AttachmentFile[] = [];
  private tempFiles: TemporaryAttachmentFile[] = [];
  private saveInProcess = false;
  private filesSubject$ = new BehaviorSubject<AttachmentFile[]>([]);
  private uploadEventSubject$ = new ReplaySubject<UploadEvent>();

  constructor(
    private client: HttpClient,
    @Inject(APP_BASE_URL) private baseUrl: string,
    @Optional() @Inject(FILE_INFORMATION_URL) private fileInformationUrl?: string,
  ) {}

  /**
   * Observable that emits the list of files.
   * This list is updated (a new value is emitted)
   * as the files are uploaded/saved/deleted/fetched.
   */
  get files$(): Observable<AttachmentFile[]> {
    return this.filesSubject$.asObservable();
  }

  /**
   * Observable that emits a boolean flag.
   * When the flag is true, that means
   * files are currently being uploaded.
   */
  get uploadInProgress$(): Observable<boolean> {
    return this.uploadEventSubject$.pipe(
      scan((acc: NumberOfStillUploadingFiles, next) => acc + next, 0),
      map(numberOfFilesUploading => numberOfFilesUploading > 0),
      startWith(false),
      shareReplay(1),
    );
  }

  /**
   * Validate if it has a temporary attachment
   */
  public hasTemporaryAttachmentFiles(): boolean {
    return this.tempFiles.length > 0;
  }

  /**
   * Fetches the files linked to the object Id.
   * All subsequent actions (upload temporary, save)
   * are done related to this object id.
   *
   * @param id Id of the selected object, undefined if the object wasn't yet created
   */
  public selectObject(id?: ObjectId): void {
    this.objectId = id;
    this.tempFiles = [];
    if (this.objectId) {
      this.getUploadedFiles$(id).subscribe(files => {
        this.files = [...files];
        this.emitFiles();
      });
    } else {
      this.files = [];
      this.emitFiles();
    }
  }
  /**
   * In case the object is not yet created use 'undefined' as initial value
   * and after save update the objectId to the id of the persisted object
   */
  public updateObjectIdAfterSave(newObjectId: ObjectId): void {
    if (this.objectId) {
      throw new Error(
        `Attachment service is already initialized for object id ${this.objectId}. Use 'selectObject' method instead`,
      );
    }
    this.objectId = newObjectId;
  }

  /**
   * Uploads the file temporarily
   *
   * @param files A list of native File objects for upload
   */
  public uploadTemporary(files: File[]): void {
    this.uploadEventSubject$.next(UPLOAD_IN_PROGRESS);

    this.tempFiles = this.tempFiles.filter(file => !file.infected);

    const formData = this.addFilesAsFormData(files);
    this.client
      .post<AttachmentList>(`${this.baseUrl}${this.objectId ? '/' + this.objectId : ''}`, formData)
      .pipe(catchError(_ => of({ attachments: [] } as AttachmentList)))
      .subscribe(response => {
        this.tempFiles = [...this.tempFiles, ...response.attachments];
        this.emitFiles();
        this.uploadEventSubject$.next(UPLOAD_FINISHED);
      });
  }

  /**
   * Persists the current temporary attachments
   */
  public save(): Observable<AttachmentList> {
    this.tempFiles = this.tempFiles.filter(file => !file.infected);

    if (this.tempFiles.length === 0 || !this.objectId || this.saveInProcess) {
      return EMPTY;
    }

    this.saveInProcess = true;

    return this.client
      .post<AttachmentList>(`${this.baseUrl}/${this.objectId}/save`, { attachments: this.tempFiles } as AttachmentList)
      .pipe(
        tap(response => {
          this.files = [...this.files, ...response.attachments];
          this.tempFiles = [];
          this.emitFiles();
        }),
        finalize(() => {
          this.saveInProcess = false;
        }),
      );
  }

  /**
   * Deletes a file
   *
   * @param file The file that should be deleted
   */
  public delete(file: AttachmentFile): void {
    this.client
      .request<void>('DELETE', `${this.baseUrl}/${this.objectId ? this.objectId : ''}`, {
        body: { attachments: [file] } as AttachmentList,
      })
      .subscribe(() => {
        this.files = this.files.filter(x => x.key !== file.key);
        this.tempFiles = this.tempFiles.filter(x => x.key !== file.key);
        this.emitFiles();
      });
  }

  /**
   * Downloads all files
   */
  public downloadAll(): void {
    if (this.fileInformationUrl) {
      this.downloadAllWithInformationUrl();
      return;
    }

    this.downloadFiles(this.files);
  }

  /**
   * Downloads the specified file
   *
   * @param file File to download
   */
  public download(file: AttachmentFile): void {
    if (this.fileInformationUrl) {
      this.downloadWithInformationUrl(file);
      return;
    }

    this.downloadFiles([file]);
  }

  private downloadAllWithInformationUrl(): void {
    this.files.forEach(file => this.downloadWithInformationUrl(file));
  }

  private downloadWithInformationUrl(file: AttachmentFile): void {
    this.client
      .get<{ url: string }>(`${this.fileInformationUrl}?key=${file.key}`)
      .subscribe(response => this.downloadFromURL(response.url, file.filename));
  }

  /**
   * Downloads from the specified URL a specific fileName
   *
   * @param url URL to download
   * @param fileName File name
   */
  public downloadFromURL(url: string, fileName: string): void {
    this.client.get(url, { responseType: 'blob' }).subscribe((file: Blob) => {
      download(file, fileName);
    });
  }

  /**
   * Return base url
   */
  getUrl(): string {
    return this.baseUrl;
  }

  private downloadFiles(files: AttachmentFile[]): void {
    this.client
      .post<AttachmentFileForDownloadList>(`${this.baseUrl}${this.objectId ? '/' + this.objectId : ''}/download`, {
        attachments: files,
      } as AttachmentList)
      .pipe(
        map(response => {
          return response.attachments.map(attachment => this.dataToBlob(attachment));
        }),
      )
      .subscribe(filesToDownload => filesToDownload.forEach(({ data, filename }) => download(data, filename)));
  }

  private dataToBlob({ data, filename }): { data: Blob; filename: string } {
    return { data: toBlob(data), filename };
  }

  private addFilesAsFormData(files: File[]): FormData {
    const formData = new FormData();
    for (const file of files) {
      formData.append('attachments', file, file.name);
    }
    return formData;
  }

  private getUploadedFiles$(objectId: ObjectId): Observable<AttachmentFile[]> {
    const pluckResponse = this.baseUrl.includes('api-campaign-campaign') ? 'content' : 'attachments';
    return this.client
      .get<AttachmentList>(`${this.baseUrl}/${objectId}`)
      .pipe(map(res => res[pluckResponse])) as unknown as Observable<AttachmentFile[]>;
  }

  private emitFiles(): void {
    this.filesSubject$.next([...this.files, ...this.tempFiles]);
  }
}
