import { animate, state, style, transition, trigger } from '@angular/animations';
import type { CdkDragDrop } from '@angular/cdk/drag-drop';
import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location, NgClass, NgFor, NgIf } from '@angular/common';
import type { AfterViewInit, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { MatTable, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type {
  ColumnDefinition,
  IColumnDefinition,
  IExpandableContentDefinitionV2,
} from '../../../dynamic-content/models/dynamic-content.model';
import { Recall2DynamicContentComponent } from '../../../dynamic-content/recall2-dynamic-content.component';
import { EThreeStepCheckboxStates } from '../../../form/model';
import { Recall2IconSortDownActiveComponent } from '../../../icons/recall2-icon-sort-down-active/recall2-icon-sort-down-active.component';
import { Recall2IconSortDownInactiveComponent } from '../../../icons/recall2-icon-sort-down-inactive/recall2-icon-sort-down-inactive.component';
import { Recall2IconSortUpActiveComponent } from '../../../icons/recall2-icon-sort-up-active/recall2-icon-sort-up-active.component';
import { Recall2IconSortUpInactiveComponent } from '../../../icons/recall2-icon-sort-up-inactive/recall2-icon-sort-up-inactive.component';
import type { IRecall2FilterParam } from '../../../overlay/models/filter.model';
import { getValueFromObject } from '../../../utils';
import type { IRecall2Sort } from '../../models/recall2-sort';
import { Recall2SortingOrder } from '../../models/recall2-sort';
import { ETableType } from '../../models/recall2-table';
import { Recall2TableFacadeBuilder } from '../../services/recall2-table-facade.builder';

const INDEX_INCREMENT = 20;

@Component({
  selector: 'recall2-basic-table',
  templateUrl: './recall2-basic-table.component.html',
  styleUrls: ['./recall2-basic-table.component.scss'],
  standalone: true,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  imports: [
    NgIf,
    NgFor,
    NgClass,
    TranslateModule,
    MatTableModule,
    DragDropModule,
    Recall2DynamicContentComponent,
    Recall2IconSortDownActiveComponent,
    Recall2IconSortDownInactiveComponent,
    Recall2IconSortUpActiveComponent,
    Recall2IconSortUpInactiveComponent,
  ],
})
export class Recall2BasicTableComponent<T = unknown> implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  @ViewChild('table', { static: false }) matTable: MatTable<T[]>;

  @Input() tableFacade: Recall2TableFacadeBuilder<T>;
  @Input() selectionEnable: boolean;
  @Input() type = ETableType.Object;
  @Input() expandOnlyOneItem = true;
  @Input() hasDragAndDrop = false;

  /**Property of the row object that is used to track changes. It can be a property of a nested object
   * (e.g. 'country.id' would access rowObject.country.id)
   */
  @Input() trackBy: string;

  /**
   * In case it is provided, this method handles if a row
   * itself can be dragged to another position or not
   */
  @Input() isElementDraggable?: (rowData: T) => boolean;

  /**
   * Emitted when there is an order change in the table data, given a drag&drop event
   */
  @Output() listOrderChanged? = new EventEmitter<T[]>();
  tableId;
  expandedObjectIdSet: Set<number> = new Set();
  highlightedObjectIdSet;
  highlightingClass;
  highlightingQueryParamIdentifier;
  expansionQueryParamIdentifier;
  columnDefinitions: IColumnDefinition[] = [];
  tableData: T[] = [];
  expandableContentDefinition: IExpandableContentDefinitionV2;
  displayedColumns: { [key: string]: any } = [];
  /* sorting */
  isSortingEnabled = false;
  sortingState = Recall2SortingOrder;
  sortInTable = false;

  activeFilters: IRecall2FilterParam[];
  destroyed$ = new Subject<void>();
  threeStepCheckboxStates = EThreeStepCheckboxStates;
  tableTypes = ETableType;
  timeoutRenderer: ReturnType<typeof setTimeout>[] = [];

  constructor(
    protected _router: Router,
    protected _route: ActivatedRoute,
    protected _cdr: ChangeDetectorRef,
    protected _ngZone: NgZone,
    protected _location: Location,
  ) {}

  documentClick(): void {
    const highlightedRows = document.querySelector(`.${this.highlightingClass}`);

    if (highlightedRows && highlightedRows.classList.contains(this.highlightingClass)) {
      highlightedRows.classList.remove(this.highlightingClass);
    }
    if (this.highlightedObjectIdSet && this.highlightedObjectIdSet.size > 0 && this.highlightingQueryParamIdentifier) {
      this.tableFacade.getTableService().setHighlightedObjectIdSet(new Set<number>());
      this.clearRouterParam();
    }
  }

  onListDrop(event: CdkDragDrop<any>): void {
    const previousIndex = this.tableData.indexOf(event.item.data);

    if (previousIndex !== event.currentIndex) {
      moveItemInArray(this.tableData, previousIndex, event.currentIndex);
      this.listOrderChanged.emit(this.tableData);
      this.matTable.renderRows();
    }
  }

  trackByMethod(_index: number, item): string {
    return getValueFromObject(item, this.trackBy);
  }

  /*
   * Optimizing Angular Change Detection Triggered by DOM Events.
   * Run this event outside angular to prevent running detection lifecycle to all of the parent components
   * */
  subscribeToClicksOutsideAngularZone(): void {
    this._ngZone.runOutsideAngular(() => {
      fromEvent(document, 'click')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(_ => {
          this.documentClick();
          this._cdr.detectChanges();
        });
    });
  }

  ngOnInit(): void {
    this.sortInTable = this.tableFacade.isSortInTable();
    this.subscribeToDate();
    this.subscribeToClicksOutsideAngularZone();
  }

  ngAfterViewInit(): void {
    this.tableFacade.getTableService().setTableType(this.type);
  }

  subscribeToDate(): void {
    this.tableFacade
      .getTableService()
      .getColumnDefinitions()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((columnDefinitions: IColumnDefinition[]) => {
        if (columnDefinitions) {
          this.columnDefinitions = columnDefinitions;
          this.displayedColumns = this.columnDefinitions.map(item => item.id);
        }
      });

    this.tableFacade
      .getSortService()
      .getSortingList()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sortList: IRecall2Sort[]) => {
        if ((sortList && sortList.length > 0) || this.sortInTable) {
          this.isSortingEnabled = true;
        }
      });

    this.tableFacade
      .getTableService()
      .getTableData()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(tableData => {
        this.renderTableRows(tableData);
        this._cdr.detectChanges();
      });

    this.tableFacade
      .getTableService()
      .getExpandableContentDefinition()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((expandableColumnDefinition: IExpandableContentDefinitionV2) => {
        if (expandableColumnDefinition) {
          this.expandableContentDefinition = expandableColumnDefinition;
        }
      });

    this.tableFacade
      .getTableService()
      .getExpandedObjectIdSet()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((set: Set<number>) => {
        if (set.size > 0) {
          this.expandedObjectIdSet = set;
          setTimeout(() => {
            const element = document.querySelector(`[scrollId="${this.expandedObjectIdSet.values().next().value}"]`);
            if (element && !this.isInViewport(element)) {
              element.scrollIntoView({ behavior: 'smooth' });
            }
          });
        }
        if (this.expandedObjectIdSet.size > 0 && this.expansionQueryParamIdentifier) {
          this.clearRouterParam();
        }
      });

    this.tableFacade
      .getTableService()
      .getHighlightedObjectIdSet()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((set: Set<number>) => {
        if (set) {
          this.highlightedObjectIdSet = set;
        }
      });

    this.tableFacade
      .getTableService()
      .getHighlightingClass()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((highlightingClass: string) => {
        if (highlightingClass) {
          this.highlightingClass = highlightingClass;
        }
      });

    this.tableFacade
      .getTableService()
      .getHighlightingQueryParamId()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queryParam: string) => {
        if (queryParam) {
          this.highlightingQueryParamIdentifier = queryParam;
        }
      });

    this.tableFacade
      .getTableService()
      .getExpansionQueryParamId()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((queryParam: string) => {
        if (queryParam) {
          this.expansionQueryParamIdentifier = queryParam;
        }
      });
  }

  private clearRouterParam(): void {
    let redirectUrl = '';
    for (let i = 0; i < this._route.snapshot.url.length; i++) {
      redirectUrl += `${this._route.snapshot.url[i].path}/`;
    }
    this._location.go(redirectUrl);
  }

  getSortingOrder(columnId: string): Recall2SortingOrder {
    return this.tableFacade.getSortService().getSortingOrder(columnId);
  }

  isPrimarySorting(columnId: string): boolean {
    return this.tableFacade.getSortService().isPrimarySorting(columnId);
  }

  applySorting(columnId: string, sortingState: Recall2SortingOrder): void {
    if (this.sortInTable) {
      this.deleteNotSavedItem();
      this.tableData = this.applySort(this.getPropertyBinding(columnId), sortingState);
      this.renderTableRows(this.tableData);
    }
    this.tableFacade.getSortService().applySorting(columnId, sortingState, !this.sortInTable);
  }

  triggerExpansion(element): void {
    if (!this.tableFacade.isRowExpandable || !this.tableFacade.rowExpandableOnClick) {
      return;
    }
    const elementId = element.id;
    if (this.expandedObjectIdSet.has(elementId)) {
      this.closeExpansion(elementId);
    } else {
      this.openExpansion(elementId);
    }
  }

  isInViewport(element: Element): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private deleteNotSavedItem(): void {
    if (this.tableData?.[0]?.['isEditable']) {
      this.tableData.shift();
    }
  }

  private applySort(propertyBinding: string, sortingState: Recall2SortingOrder): T[] {
    return this.tableData.sort((a, b) =>
      this.getAlphabeticallyOrder(a[propertyBinding], b[propertyBinding], sortingState === Recall2SortingOrder.ASC),
    );
  }

  private getAlphabeticallyOrder(a: unknown, b: unknown, ascending: boolean): number {
    if (!a) {
      return 1;
    } else if (!b) {
      return -1;
    }

    return ascending ? (a > b ? 1 : a < b ? -1 : 0) : a > b ? -1 : a < b ? 1 : 0;
  }

  private getPropertyBinding(columnId: string): string {
    return this.columnDefinitions.find((column: ColumnDefinition) => column.id === columnId).sortProperty;
  }

  private openExpansion(expandId: number): void {
    if (this.expandOnlyOneItem) {
      this.expandedObjectIdSet.clear();
    }
    this.expandedObjectIdSet.add(expandId);
    this.tableFacade.getTableService().setExpandedObjectIdSet(this.expandedObjectIdSet);
  }

  private closeExpansion(expandId: number): void {
    this.expandedObjectIdSet.delete(expandId);
    this.tableFacade.getTableService().setExpandedObjectIdSet(this.expandedObjectIdSet);
  }

  private asyncRowRender(index: number, data: T[]): void {
    const timeoutRenderer = setTimeout(() => {
      this.tableData = data.slice(0, index);
      this.matTable.renderRows();
    }, 100 + index);

    this.timeoutRenderer.push(timeoutRenderer);
  }

  private renderTableRows(tableData: T[]): void {
    if (tableData) {
      if (tableData.length > INDEX_INCREMENT && this.matTable) {
        this.tableData = tableData.slice(0, INDEX_INCREMENT);
        let index = INDEX_INCREMENT;
        let asyncRender = true;
        while (asyncRender) {
          this.asyncRowRender(index, tableData);

          if (index === tableData.length) {
            asyncRender = false;
          } else {
            index += INDEX_INCREMENT;
            if (index > tableData.length) {
              index = tableData.length;
            }
          }
        }
      } else {
        this.tableData = tableData;
        if (this.matTable) {
          this.matTable.renderRows();
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type'] && !changes['type'].isFirstChange()) {
      this.tableFacade.getTableService().setTableType(changes['type'].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    for (const time of this.timeoutRenderer) {
      clearTimeout(time);
    }
  }
}
