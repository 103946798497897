import { CommonModule, NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { iconCloseSmall, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';
import { combineLatest } from 'rxjs';
import { startWith } from 'rxjs/operators';

import { Recall2ButtonPrimaryComponent, Recall2ButtonTertiaryComponent } from '../../../buttons/';
import { Recall2InputTextComponent } from '../../../form/components/recall2-input-text/recall2-input-text.component';
import { InputTextProperty } from '../../../form/model';
import type { IAutoSuggestUser } from '../../models/auto-suggest-user';
import { Recall2AutoSuggestListComponent } from '../recall2-auto-suggest-list/recall2-auto-suggest-list.component';
import type { DirectoryUser, DirectoryUserRequest } from './models/users';
import { DirectorySearchService } from './services/recall2-directory-search.service';

export type DirectorySearchActions = 'select' | 'close';

@Component({
  selector: 'recall2-directory-search',
  templateUrl: './recall2-directory-search.component.html',
  styleUrls: ['./recall2-directory-search.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SVGIconModule,
    Recall2ButtonPrimaryComponent,
    Recall2InputTextComponent,
    Recall2ButtonTertiaryComponent,
    NgIf,
    MatDialogModule,
    TranslateModule,
    Recall2AutoSuggestListComponent,
  ],
})
export class Recall2DirectorySearchComponent implements OnInit {
  entries: DirectoryUser[] = [];
  matches: number;
  familyNameField: InputTextProperty;
  givenNameField: InputTextProperty;
  departmentField: InputTextProperty;
  corporationField: InputTextProperty;
  userIdField: InputTextProperty;
  isClearButtonVisible = false;
  isResultVisible = false;
  isSelectUserDisabled = true;
  isSearching = false;
  private entrySelected: IAutoSuggestUser;

  constructor(
    public dialogRef: MatDialogRef<Recall2DirectorySearchComponent>,
    public directoryService: DirectorySearchService,
    svgIconRegistry: SVGIconsRegistry,
    @Inject(MAT_DIALOG_DATA) public data: string,
  ) {
    svgIconRegistry.registerIcons([iconCloseSmall]);
  }

  ngOnInit(): void {
    this.createFieldProperties();
    this.watchFormChanges();
  }

  onSearch(): void {
    if (this.isClearButtonVisible) {
      this.clearResults();
      this.isSearching = true;
      const familyName: string = this.familyNameField.control.value;
      const givenName: string = this.givenNameField.control.value;
      const department: string = this.departmentField.control.value;
      const corporation: string = this.corporationField.control.value;
      const userId: string = this.userIdField.control.value;

      const request: DirectoryUserRequest = {
        familyName,
        givenName,
        department,
        corporation,
        userId,
      };

      this.directoryService.getUsers(request).subscribe({
        next: (users: DirectoryUser[]) => {
          this.entries = users;
          this.matches = users.length;
          this.isResultVisible = true;
          this.isSearching = false;
        },
        error: () => {
          this.isSearching = false;
        },
      });
    }
  }

  onClear(): void {
    this.familyNameField.control.reset();
    this.givenNameField.control.reset();
    this.departmentField.control.reset();
    this.corporationField.control.reset();
    this.userIdField.control.reset();
    this.isClearButtonVisible = false;
    this.clearResults();
  }

  onEntrySelected(entry: IAutoSuggestUser): void {
    this.isSelectUserDisabled = false;
    this.entrySelected = entry;
  }

  onSelectUser(): void {
    if (!this.isSelectUserDisabled) {
      this.dialogRef.close({ action: 'select', data: this.entrySelected });
    }
  }

  onCloseModal(): void {
    this.dialogRef.close({ action: 'close', data: null });
  }

  private createFieldProperties(): void {
    this.familyNameField = this.getTextInputProperty(
      'directory_search.family_name',
      'directory_search.family_name',
      this.data,
    );
    this.givenNameField = this.getTextInputProperty('directory_search.given_name', 'directory_search.given_name');
    this.departmentField = this.getTextInputProperty('directory_search.department', 'directory_search.department');
    this.corporationField = this.getTextInputProperty('directory_search.corporation', 'directory_search.corporation');
    this.userIdField = this.getTextInputProperty('directory_search.user_id', 'directory_search.user_id');
  }

  private getTextInputProperty(name: string, translationKey: string, defaultValue = ''): InputTextProperty {
    return new InputTextProperty(name, false, translationKey, null, false, new FormControl(defaultValue), true);
  }

  private watchFormChanges(): void {
    combineLatest([
      this.familyNameField.control.valueChanges.pipe(startWith(this.data)),
      this.givenNameField.control.valueChanges.pipe(startWith('')),
      this.departmentField.control.valueChanges.pipe(startWith('')),
      this.corporationField.control.valueChanges.pipe(startWith('')),
      this.userIdField.control.valueChanges.pipe(startWith('')),
    ]).subscribe(([familyName, givenName, department, corporation, userId]) => {
      this.isClearButtonVisible = !!familyName || !!givenName || !!department || !!corporation || !!userId;
    });
  }

  private clearResults(): void {
    this.entries = [];
    this.matches = null;
    this.isResultVisible = false;
  }
}
