import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { WINDOW } from '@recall2/globals';

export const ExternalUrlGuard: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
): boolean => {
  const router = inject(Router);
  const window = inject(WINDOW);

  const externalUrl = router.getCurrentNavigation()?.extras.state?.externalUrl;

  if (externalUrl) {
    window.open(externalUrl, '_self');
  }

  return false;
};
