import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CustomDatePipe, CustomTimePipe } from '../../../pipes';
import { ObjectEvent, ObjectType } from '../../models/object-event';
import { CommentComponent } from '../comment/comment.component';
import { DescriptionComponent } from '../description/description.component';
import { ObjectStatusComponent } from '../object-status/object-status.component';

@Component({
  selector: 'recall2-object-event',
  templateUrl: './object-event.component.html',
  styleUrls: ['./object-event.component.scss'],
  standalone: true,
  imports: [
    CommentComponent,
    DescriptionComponent,
    ObjectStatusComponent,
    NgClass,
    NgIf,
    TranslateModule,
    CustomDatePipe,
    CustomTimePipe,
  ],
})
export class ObjectEventComponent {
  @Input() item: ObjectEvent;
  @Input() objectType: ObjectType;

  isToday(dateTime: string): boolean {
    const date = new Date(dateTime);
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  }
}
