import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

import type { AlreadyForwardedManufacturer, IBrandManufacturers } from '../models/brand-manufacturer-selector.models';

@Pipe({ name: 'isBrandDisabled', standalone: true })
export class IsBrandDisabledPipe implements PipeTransform {
  transform(
    brandManufacturer: IBrandManufacturers,
    forwardedManufacturers: AlreadyForwardedManufacturer[] = [],
  ): boolean {
    const forwardedManufacturer = forwardedManufacturers.find(
      manufacturer => manufacturer.brandId === brandManufacturer.brand.id,
    );

    return forwardedManufacturer?.areAllBrandManufacturerForwarded ?? false;
  }
}
