<div (click)="onClick($event)">
  <button
    #secondaryButton
    class="rc2-font--body--l rc2-align-items--center btn btn--secondary {{rc2ButtonStyles}}"
    [ngClass]="{
      'spinner spinner-box':isAnimating || forceAnimation,
      'disabled': isDisabled,
      'button--svg-width': !!svgIcon || iconClass
    }"
    [disabled]="isDisabled"
  >
    <span
      [style.visibility]="(isAnimating || forceAnimation) ? 'hidden' : 'visible'"
    >
      <em
        *ngIf="iconClass"
        class="icon {{iconClass}}"
      ></em>
      <ng-container *ngTemplateOutlet="svgIcon"></ng-container>
      <ng-content></ng-content>
    </span>
  </button>
</div>
