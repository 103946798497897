import type { FeatureFlag } from './models/feature-flag.model';
import { FeaturePhase } from './models/feature-phase.model';

export const CONSUMER_MANAGEMENT_APP_FEATURE_FLAG: FeatureFlag = {
  feature: 'consumerManagementApp',
  featurePhase: FeaturePhase.Shared,
};
export const EXPORT_CONSUMER_INFORMATION_RVS_REPORT_FEATURE_FLAG: FeatureFlag = {
  feature: 'exportCustomerInformationRVSReport',
  featurePhase: FeaturePhase.Coordination,
};
