<div class="col-md-8 rc2-pad--left--0">
  <recall2-summary-text
    [label]="'notification.descriptionMeasureProd' | translate"
    [content]="notification.descriptionMeasureProd"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notification.measureImplemented' | translate"
    [content]="getMeasureImplementedAsString() | translate"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notification.descriptionMeasureAfterSales' | translate"
    [content]="notification.descriptionMeasureAfterSales"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notification.serviceAvailable' | translate"
    [content]="getServiceAvailableAsString()"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notification.warehouseUpdated' | translate"
    [content]="getWarehouseNumberAsString()"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notification.brandManufacturers' | translate"
    [content]="getAffectedManufacturersAsString()"
  ></recall2-summary-text>

  <div class="supplier-section">
    <div class="supplier-section--left">
      <recall2-summary-text
        [label]="'notification.supplierError' | translate"
        [content]="notification.supplierError | threeStateTranslation"
      ></recall2-summary-text>
    </div>

    <recall2-summary-text
      [label]="'notification.supplier' | translate"
      [content]="notification.supplier ? notification.supplier : '-'"
    ></recall2-summary-text>
  </div>
</div>
