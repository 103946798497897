<div
  *ngIf="!notification"
  class="summary-loading"
>
  <recall2-loading-spinner [forceShowing]="!notification"></recall2-loading-spinner>
</div>

<div *ngIf="notification">
  <recall2-accordion-simple-h3 [accordionBaseProperties]="headerSectionAccordionProperties">
    <notification-summary-header [notification]="notification"></notification-summary-header>
  </recall2-accordion-simple-h3>

  <recall2-accordion-simple-h3 [accordionBaseProperties]="measurementsSectionAccordionProperties">
    <notification-summary-measurements [notification]="notification"></notification-summary-measurements>
  </recall2-accordion-simple-h3>

  <recall2-accordion-simple-h3 [accordionBaseProperties]="vehiclesSectionAccordionProperties">
    <notification-summary-vehicles [notification]="notification"></notification-summary-vehicles>
  </recall2-accordion-simple-h3>

  <recall2-accordion-simple-h3 [accordionBaseProperties]="personsSectionAccordionProperties">
    <recall2-involved-users-table
      [userList]="notification.involvedUsers"
      [showDeleteButton]="false"
      [showAddButton]="false"
      [showTitle]="false"
    >
    </recall2-involved-users-table>
  </recall2-accordion-simple-h3>

  <recall2-accordion-simple-h3 [accordionBaseProperties]="attachmentsSectionAccordionProperties">
    <recall2-file-list
      (download)="attachmentService.download($event)"
      (downloadAll)="attachmentService.downloadAll()"
      [files]="files"
      [hideDeleteButtons]="true"
    >
    </recall2-file-list>
  </recall2-accordion-simple-h3>

  <recall2-accordion-simple-h3 [accordionBaseProperties]="commentsSectionAccordionProperties">
    <recall2-comments-and-history
      [objectId]="notification.id"
      [objectType]="objectType"
      [commentsDisabled]="true"
    >
    </recall2-comments-and-history>
  </recall2-accordion-simple-h3>
</div>
