import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2IconWarningComponent } from '@recall2/ui/icons';

@Component({
  selector: 'recall2-notification-warning',
  templateUrl: './notification-warning.component.html',
  standalone: true,
  imports: [NgIf, TranslateModule, Recall2IconWarningComponent],
})
export class NotificationWarningComponent {
  @Input() showIcon = true;
}
