<div [class.is-opened]="isOpen">
  <label
    *ngIf="property.hasTitle"
    id="{{ property.name }}_label"
    class="title-label"
    [class.title-label--disabled]="isDisabled || property.control.disabled"
  >
    {{ property.translationKey + ".label" | translate }}
    <recall2-icon-required *ngIf="property.required"></recall2-icon-required>
  </label>
  <recall2-icon-help-label-form
    *ngIf="property.hasTooltip"
    id="{{property.name}}_help_icon"
    matTooltip="{{ property.translationKey + '.tooltip' | translate}}"
    matTooltipPosition="above"
    matTooltipClass="rc2-tooltip"
  >
  </recall2-icon-help-label-form>
  <div class="rc2-position--relative rc2-mar--top--3xs">
    <recall2-icon-calendar
      #calendar
      data-testid="calendar-icon"
      [class.active]="inputHasFocus"
      [class.disabled]="isDisabled || property.control.disabled"
      [rc2IconStyles]="'rc2-icon--size--24'"
      (click)="onClickCalendarIcon()"
    >
    </recall2-icon-calendar>
  </div>

  <input
    [value]="property.control.value"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    id="{{property.name}}"
    #input
    type="number"
    autocomplete="off"
    placeholder="{{ (isDisabled || property.control.disabled) ? '-' : (property.translationKey + '.placeholder' | translate)}}"
    [disabled]="isDisabled || property.control.disabled"
    (focus)="onInputFocus()"
    data-cy="year-picker-input"
    [formControl]="property.control"
    [class.error]="showErrors"
  >
  <ng-container *ngIf="showErrors">
    <div class="error-wrapper">
      <recall2-icon-invalid></recall2-icon-invalid>
      <span class="error-message">
        {{ 'form.error.generic.invalidDate' | translate }}
      </span>
    </div>
  </ng-container>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayPositions]="position"
  [cdkConnectedOverlayHeight]="overlayHeight"
  [cdkConnectedOverlayWidth]="overlayWidth"
  (overlayOutsideClick)="onClickOutside($event)"
  (detach)="isOpen = false; input.blur()"
>
  <div class="year-selector rc2-pad--m"
       data-cy="more-filters-year-selector"
  >
    <div class="year-selected">
      {{selectedYear}}
    </div>
    <ul class="year-list">
      <li *ngFor="let year of years, let index=index"
          class="rc2-mar--bottom--s rc2-mar--right--m year"
          id="year-{{year}}"
          [ngClass]="{'selected': year === selectedYear}"
          (click)="onSelectYear(year, $event)"
      >
        {{year}}
      </li>
    </ul>
    <div class="rc2-display--flex rc2-justify--right">
      <recall2-button-tertiary
        data-testid="more-filters-year-cancel"
        class="rc2-mar--right--s"
        (click)="onClickOutside($event)"
      >
        {{'shared.button.cancel' | translate}}
      </recall2-button-tertiary>
      <recall2-button-primary
        data-testid="more-filters-year-confirm"
        (click)="onCurrentYearSelection($event)"
      >
        {{'shared.button.current' | translate}}
      </recall2-button-primary>
    </div>
  </div>
</ng-template>
