import { NgIf } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2ButtonPrimaryComponent, Recall2ButtonSecondaryComponent } from '@recall2/ui/buttons';
import { AbstractGenericCellViewWithData } from '@recall2/ui/dynamic-content';
import { Recall2FloatingBarComponent } from '@recall2/ui/floating-bar';
import { Recall2IconForwardComponent, Recall2IconWarningComponent } from '@recall2/ui/icons';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

import type { INotification, IUpdateOverview } from '../../../models/notification/notification';
import { EOpenOrHighlight, INotificationForList } from '../../../models/notification/notification';
import { NotificationStatus } from '../../../models/notification/notification-status.enum';
import { SummaryComponent } from '../../summary/summary.component';
import { NotificationService } from './../../../../rest-api/notification/notification.service';
import { NotificationRoutingService } from './../../../routing/notification-routing.service';

@Component({
  selector: 'notification-details',
  templateUrl: './notification-details.component.html',
  standalone: true,
  imports: [
    SummaryComponent,
    RouterModule,
    NgIf,
    Recall2IconWarningComponent,
    Recall2IconForwardComponent,
    TranslateModule,
    Recall2FloatingBarComponent,
    Recall2ButtonSecondaryComponent,
    Recall2ButtonPrimaryComponent,
  ],
})
export class NotificationDetailsComponent
  extends AbstractGenericCellViewWithData<INotificationForList>
  implements OnInit, OnDestroy
{
  @Output() refreshTableData = new EventEmitter<IUpdateOverview>();
  @Input() notification: INotificationForList;

  readonly NotificationStatus = NotificationStatus;

  notificationDetails: INotification;
  isNotificationBeingAccepted: boolean;

  private destroyed$ = new Subject<void>();

  sendForReviseReviewer = (): Promise<boolean> => this.notificationRouting.navigateToReworkForm(this.notification.id);
  forwardToManufacturer = (): void =>
    this.notificationRouting.navigateToForwardToManufacturerForm(this.notification.id);
  forwardToReviewer = (): Promise<boolean> =>
    this.notificationRouting.navigateToForwardToReviewerForm(this.notification.id);
  sendForReviseManufacturer = (): Promise<boolean> =>
    this.notificationRouting.navigateToReworkAPSForm(this.notification.id);
  createTopic = (): void => this.notificationRouting.navigateToCreateTopic(this.notification.id);
  linkTopic = (): void => this.notificationRouting.navigateToLinkTopic(this.notification.id);

  constructor(
    private notificationRouting: NotificationRoutingService,
    private notificationService: NotificationService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.notificationService
      .getNotificationForId(this.notification.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((notification: INotification) => {
        this.notificationDetails = notification;
      });
  }

  navigateToTransferNotification(): void {
    this.notificationRouting.navigateToTransfer(this.notification.id);
  }

  onAcceptNotification(): void {
    if (this.isNotificationBeingAccepted) {
      return;
    }

    this.isNotificationBeingAccepted = true;

    this.notificationService
      .acceptNotification(this.notification.id)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: notification => {
          this.refreshTableData.emit({
            notification,
            action: EOpenOrHighlight.OPEN,
          });

          this.isNotificationBeingAccepted = false;
        },
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  showActions(notification: INotificationForList): boolean {
    return !!notification && this.hasAtLeastOnePermission(notification);
  }

  private hasAtLeastOnePermission(notification: INotificationForList): boolean {
    const permissions = notification.actionPermissions;

    return (
      permissions &&
      (permissions.canApprove ||
        permissions.canForwardToReviewer ||
        permissions.canForwardToManufacturer ||
        permissions.canSendForReviseManufacturer ||
        permissions.canSendForReviseReviewer ||
        permissions.canAccept ||
        permissions.canCreateAsTopic ||
        permissions.canTransfer ||
        permissions.canLinkToTopic)
    );
  }
}
