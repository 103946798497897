import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpecialCharsValidationService {
  public regExpBlockSpecialChars = /^((?!(["*/:<>?|])).)*$/;
  public regExpWhiteSpaceChars = /^(?!\s*$)/;
  private alphaNumeric = /[^\dA-Za-z]/g;

  isValid(value: string): boolean {
    return this.regExpBlockSpecialChars.test(value);
  }

  replaceSpecialChars(value: string): string {
    return value.replace(this.alphaNumeric, '');
  }
}
