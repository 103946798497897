import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';

import type { BrandsId } from '../../brand-manufacturer/models/brands-id.model';
import { findBrandIdByManufacturerName } from '../../brand-manufacturer/utils/find-brand-id-by-manufacturer-name.util';
import { ManufacturerPropertyName } from '../../manufacturer-table/models/manufacturer.model';
import { MarkEmptyPipe } from '../../pipes/mark-empty/mark-empty.pipe';
import { Recall2BrandLogoComponent } from '../brand-logo/recall2-brand-logo.component';

@Component({
  selector: 'manufacturer-view',
  templateUrl: './manufacturer-view.component.html',
  standalone: true,
  imports: [NgIf, Recall2BrandLogoComponent, MarkEmptyPipe],
})
export class ManufacturerViewComponent {
  brandId: BrandsId;
  nameForToolTip = '';
  readonly logoSize = 'rc2-icon--size--30';
  private _name = '';

  @Input() additionalCssClasses: string[];
  @Input() set name(value: ManufacturerPropertyName) {
    // fail-safe: check if type of value is string, because in the old api an object is passed
    if (value && typeof value === 'string') {
      this.setManufacturer(value);
    }
    if (value && value.length > 0 && Array.isArray(value)) {
      this.setManufacturerList(value);
    }
  }

  get name(): string {
    return this._name;
  }

  private setManufacturer(value: string): void {
    this._name = value;
    this.nameForToolTip = value;

    this.setManufacturerIconEnum(value);
  }

  private setManufacturerList(value: string[] | any[]): void {
    this._name = this.getNameForManufacturerList(value);
    if (typeof value[0] === 'object') {
      value = (value as any[]).map(item => {
        return item.name;
      });
    }
    this.nameForToolTip = value.join(' \n ');
    this.setManufacturerIconEnum(this.getFirstName(value));
  }

  private setManufacturerIconEnum(value: string): void {
    this.brandId = findBrandIdByManufacturerName(value);
  }

  private getNameForManufacturerList(value: string[] | any[]): string {
    const name = this.getFirstName(value);
    if (value.length > 1) {
      return `${name}, ...(${value.length})`;
    }
    this._name = name;

    return this._name;
  }

  private getFirstName(value: string[] | any[]): string {
    let name = '';
    if (typeof value[0] === 'object') {
      name = value[0].name;
    }
    if (typeof value[0] === 'string') {
      name = value[0];
    }
    return name;
  }
}
