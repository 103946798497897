import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-urgency-two',
  templateUrl: './recall2-icon-urgency-two.component.html',
  styleUrls: ['./recall2-icon-urgency-two.component.scss'],
  standalone: true,
})
export class Recall2IconUrgencyTwoComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i86-urgency-2');
  }
}
