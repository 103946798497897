import { mapToCanActivate, type Routes } from '@angular/router';
import { destructiveActionGuard } from '@recall2/ui/destructive-action';
import { ERole } from '@recall2/ui/navbar';

import { NotificationApproveComponent } from '../components/notification-flow/notification-approve/notification-approve.component';
import { NotificationDiscardComponent } from '../components/notification-flow/notification-discard/notification-discard.component';
import { NotificationForwardComponent } from '../components/notification-flow/notification-forward/notification-forward.component';
import { NotificationManufacturerComponent } from '../components/notification-flow/notification-manufacturer/notification-manufacturer.component';
import {
  NotificationReworkAPSComponent,
  NotificationReworkReviewerComponent,
} from '../components/notification-flow/notification-rework/notification-rework.component';
import { NotificationSendComponent } from '../components/notification-flow/notification-send/notification-send.component';
import { NotificationTransferComponent } from '../components/notification-flow/notification-transfer/notification-transfer.component';
import { NotificationDescriptionComponent } from '../components/notification-form/notification-description/notification-description.component';
import { NotificationMeasureComponent } from '../components/notification-form/notification-measure/notification-measure.component';
import { NotificationParentComponent } from '../components/notification-form/notification-parent/notification-parent.component';
import { NotificationPersonsComponent } from '../components/notification-form/notification-persons/notification-persons.component';
import { NotificationSummaryComponent } from '../components/notification-form/notification-summary/notification-summary.component';
import { NotificationVehicleComponent } from '../components/notification-form/notification-vehicle/notification-vehicle.component';
import { NotificationOverviewComponent } from '../components/notification-overview/notification-overview.component';
import { AuthGuard } from '../guards/auth-guard.service';
import { UnknownUserGuard } from '../guards/unknown-user-guard.service';
import { EFormType } from '../models/form/formType';
import { ENotificationSendAction } from '../models/notification/send-action.enum';
import type { FormRoute } from '../models/routes/form-route';
import { baseRouteNotification, pathParamNotificationId, routeCreate } from './notification-routes.constants';

export const formRoutes: FormRoute[] = [
  { path: '', redirectTo: EFormType.description, pathMatch: 'full', data: { page: EFormType.description } },
  { path: EFormType.description, component: NotificationDescriptionComponent, data: { page: EFormType.description } },
  { path: EFormType.measure, component: NotificationMeasureComponent, data: { page: EFormType.measure } },
  { path: EFormType.vehicles, component: NotificationVehicleComponent, data: { page: EFormType.vehicles } },
  { path: EFormType.persons, component: NotificationPersonsComponent, data: { page: EFormType.persons } },
  { path: EFormType.summary, component: NotificationSummaryComponent, data: { page: EFormType.summary } },
];

export const notificationRoutes: Routes = [
  {
    path: baseRouteNotification,
    component: NotificationOverviewComponent,
    canActivate: mapToCanActivate([UnknownUserGuard]),
  },
  {
    path: `${baseRouteNotification}/${routeCreate}`,
    component: NotificationParentComponent,
    canDeactivate: [destructiveActionGuard],
    children: formRoutes,
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}`,
    component: NotificationParentComponent,
    canDeactivate: [destructiveActionGuard],
    children: formRoutes,
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}/${ENotificationSendAction.SEND}`,
    component: NotificationSendComponent,
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}/${ENotificationSendAction.DISCARD}`,
    component: NotificationDiscardComponent,
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}/${ENotificationSendAction.FORWARD}`,
    component: NotificationForwardComponent,
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}/${ENotificationSendAction.REWORK_APS}`,
    component: NotificationReworkAPSComponent,
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}/${ENotificationSendAction.REWORK}`,
    component: NotificationReworkReviewerComponent,
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}/${ENotificationSendAction.MANUFACTURER}`,
    component: NotificationManufacturerComponent,
    canActivate: mapToCanActivate([AuthGuard]),
    canDeactivate: [destructiveActionGuard],
    data: {
      role: ERole.COORDINATOR_BRAND_OFFICE,
    },
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}/approve`,
    component: NotificationApproveComponent,
  },
  {
    path: `${baseRouteNotification}/:${pathParamNotificationId}/transfer`,
    component: NotificationTransferComponent,
  },
];
