import { Component } from '@angular/core';

import { CustomTimePipe } from '../../../../pipes';
import { AbstractGenericCellViewWithData } from '../../../models/dynamic-content.model';

@Component({
  selector: 'time.cell',
  templateUrl: './time-cell.component.html',
  standalone: true,
  imports: [CustomTimePipe],
})
export class TimeCellComponent extends AbstractGenericCellViewWithData implements ITimeCellBinding {
  propertyName: string;
  additionalCssClasses: string[];
}

export class TimeCellBinding implements ITimeCellBinding {
  constructor(
    public propertyName: string,
    public additionalCssClasses?: string[],
  ) {}
}

export interface ITimeCellBinding {
  propertyName: string;
  additionalCssClasses?: string[];
}
