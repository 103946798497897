import type { PageDTO } from '@recall2/ui/pagination';

import type { INotificationForList } from './notification/notification';

export interface IPageableSettings {
  sort: IPaginationSorting;
  pageSize: number;
  pageNumber: number;
  offset: number;
  paged: boolean;
  unpaged: boolean;
}

export interface IPaginationSorting {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

export const initialPaginationSortingState: IPaginationSorting = {
  unsorted: undefined,
  sorted: undefined,
  empty: undefined,
};

export const initialPageableSettingsState: IPageableSettings = {
  sort: initialPaginationSortingState,
  pageSize: undefined,
  pageNumber: undefined,
  offset: undefined,
  paged: undefined,
  unpaged: undefined,
};

export const initialNotificationPageState: PageDTO<INotificationForList> = {
  content: [],
  pageable: initialPageableSettingsState,
  totalElements: undefined,
  totalPages: undefined,
  last: undefined,
  numberOfElements: undefined,
  sort: initialPaginationSortingState,
  first: undefined,
  size: undefined,
  number: undefined,
  empty: undefined,
};
