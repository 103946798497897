import { NgFor, NgIf, UpperCasePipe } from '@angular/common';
import type { ElementRef } from '@angular/core';
import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import type { AttachmentFile } from '../../../../attachment/models/attachment-file.model';
import { Recall2IconDownloadComponent } from '../../../../icons';
import { FileSizePipe } from '../../../../pipes';
import type { IAttachment } from '../../../models/attachment.model';
import { AttachmentService } from './../../../../attachment/services/attachment.service';

@Component({
  selector: 'recall2-attachment-template',
  templateUrl: './recall2-attachment-template.component.html',
  standalone: true,
  imports: [TranslateModule, UpperCasePipe, NgIf, NgFor, FileSizePipe, MatTooltipModule, Recall2IconDownloadComponent],
})
export class Recall2AttachmentTemplateComponent {
  @Input() public attachmentList: Array<IAttachment | AttachmentFile> = [];
  @Input() public downloadAllAttachments?: () => void;
  @ViewChildren('a') public anchorElementQueryList!: QueryList<ElementRef>;

  constructor(public attachmentService: AttachmentService) {}

  getTotalFileSize(): number {
    let totalFileSize = 0;
    if (this.attachmentList) {
      for (const attachment of this.attachmentList) {
        totalFileSize += attachment.size;
      }
    }
    return totalFileSize;
  }

  isLegacyAttachment(): boolean {
    return (
      this.attachmentList &&
      this.attachmentList.length > 0 &&
      Boolean((this.attachmentList[0] as IAttachment).url || (this.attachmentList[0] as IAttachment).fileName)
    );
  }

  downloadAll(): void {
    if (this.downloadAllAttachments) {
      this.downloadAllAttachments();
    } else if (this.isLegacyAttachment()) {
      for (const attachment of this.attachmentList as IAttachment[]) {
        this.downloadFromLegacyAttachment(attachment);
      }
    } else {
      this.attachmentService.downloadAll();
    }
  }

  onClickAttachment(attachment: IAttachment | AttachmentFile): void {
    if (this.isLegacyAttachment()) {
      this.downloadFromLegacyAttachment(attachment);
    } else {
      this.attachmentService.download(attachment as AttachmentFile);
    }
  }

  private downloadFromLegacyAttachment(attachment: IAttachment): void {
    this.attachmentService.downloadFromURL(attachment.url, attachment.fileName);
  }
}
