import type { IErrorState } from '@recall2/ui/errorhandling';
import { initialErrorState } from '@recall2/ui/errorhandling';

import type { INotificationState } from '../../notification/store/notification.state';
import type { IUserState } from '../../user/store/user.state';
import { initialUserState } from '../../user/store/user.state';

export interface IAppState {
  user: IUserState;
  notification?: INotificationState;
  error?: IErrorState;
}

/**
 * Definition of the initial app state.
 * @type {{router: undefined; data: IDataState; ui: IUIState}}
 */
export const initialAppState: IAppState = {
  user: initialUserState,
  notification: undefined,
  error: initialErrorState,
};
