import type { AfterViewInit, OnDestroy } from '@angular/core';
import { Component, ElementRef, HostListener, Input, Renderer2, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Expandable } from '../layout/accordion/model/recall2-expandable.interface';

@Component({
  selector: 'recall2-floating-bar',
  templateUrl: './recall2-floating-bar.component.html',
  styleUrls: ['./recall2-floating-bar.component.scss'],
  standalone: true,
})
export class Recall2FloatingBarComponent implements AfterViewInit, OnDestroy {
  @Input()
  public expandableRef: Expandable;

  @ViewChild('stickyBar', { static: true })
  public stickyBar: ElementRef;

  private readonly intersectionBottomClass = 'intersection-helper-bottom';
  private readonly intersectionTopClass = 'intersection-helper-top';
  private readonly stickyPlaceholderClass = 'sticky-placeholder';
  private readonly shadowClass = 'shadow';
  private readonly slideInClass = 'slide-in';
  private readonly pixelUnitIdentifier = 'px';

  private readonly observerOptions: IntersectionObserverInit = { threshold: [0] };

  private intersectionObserver: IntersectionObserver;

  private isFloating = false;
  private intersectionBottomHelper: Element;
  private intersectionTopHelper: Element;

  private isTopVisible = false;
  private isBottomVisible = false;
  private isTopAboveViewPort = false;
  private isBottomAboveViewPort = false;
  private stickyPlaceholder: Element;

  private isFloatingDetectionEnabled = true;
  private screenHeight: number;

  private destroyed$ = new Subject<void>();

  // escape some scrolling speed, should be resolution depending
  private readonly animationTriggerMaxPixelDistance = 50;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {
    this.getScreenSize();
  }
  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.initializeFloatingBar();
    });
  }

  private initializeFloatingBar(): void {
    this.setupLayout();

    this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), this.observerOptions);
    this.intersectionObserver.observe(this.intersectionBottomHelper);
    this.intersectionObserver.observe(this.intersectionTopHelper);

    if (this.expandableRef) {
      this.expandableRef.onOpenCompleted.pipe(takeUntil(this.destroyed$)).subscribe(() => this.onOpenCompleted());
      this.expandableRef.close.pipe(takeUntil(this.destroyed$)).subscribe(() => this.onClose());
    }

    this.triggerIntersectionWithCurrentHelperPositions();
  }

  public ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }

    this.renderer.removeChild(this.elementRef.nativeElement.parentElement, this.elementRef.nativeElement);
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  @HostListener('window:resize', ['$event'])
  public getScreenSize(): void {
    this.screenHeight = window.innerHeight;
  }

  private setupLayout(): void {
    const parentElement = this.elementRef.nativeElement.parentElement;
    this.changePositionTypeToRelative(parentElement);

    this.intersectionTopHelper = this.createHelperDivAndAppendToParent(this.intersectionTopClass);
    this.intersectionBottomHelper = this.createHelperDivAndAppendToParent(this.intersectionBottomClass);

    const parentStyling = window.getComputedStyle(parentElement);
    const parentPaddingBottom = parentStyling.paddingBottom;
    const parentPaddingLeft = parentStyling.paddingLeft;

    this.renderer.setStyle(this.intersectionBottomHelper, 'bottom', parentPaddingBottom);
    this.renderer.setStyle(this.stickyBar.nativeElement, 'margin-left', `-${parentPaddingLeft}`);
  }

  private changePositionTypeToRelative(element: ElementRef): void {
    this.renderer.setStyle(element, 'position', 'relative');
  }

  private createHelperDivAndAppendToParent(classStyle: string): Element {
    const newDiv = this.renderer.createElement('div');
    this.renderer.addClass(newDiv, classStyle);
    this.renderer.appendChild(this.elementRef.nativeElement.parentElement, newDiv);
    return newDiv;
  }

  private onIntersection(entries: IntersectionObserverEntry[]): void {
    if (entries[0].target.className === this.intersectionTopClass) {
      this.isTopVisible = this.isVisible(entries[0].boundingClientRect);
      this.isTopAboveViewPort = this.isAboveViewPort(entries[0].boundingClientRect);
    } else if (entries[0].target.className === this.intersectionBottomClass) {
      this.isBottomVisible = this.isVisible(entries[0].boundingClientRect);
      this.isBottomAboveViewPort = this.isAboveViewPort(entries[0].boundingClientRect);
    }

    this.evaluateFloating();
  }

  private isVisible(bounds: DOMRect): boolean {
    return bounds.bottom >= 0 && bounds.top <= this.screenHeight;
  }

  private evaluateFloating(): void {
    if (!this.isFloatingDetectionEnabled) {
      return;
    }

    if (this.isBottomVisible) {
      // if bottom visible always disable
      this.disableFloating();
    } else {
      // bottom not visible
      if (this.isTopVisible) {
        this.enableFloating();
      } else {
        const isInScrollContainer = this.isTopAboveViewPort && !this.isBottomAboveViewPort;
        if (isInScrollContainer) {
          this.enableFloating();
        } else {
          this.disableFloating();
        }
      }
    }
  }

  private computeInnerWidth(): string {
    const currentParentOffsetWidth = this.elementRef.nativeElement.parentElement.offsetWidth;
    return currentParentOffsetWidth + this.pixelUnitIdentifier;
  }

  private enableFloating(): void {
    if (this.isFloating) {
      return;
    }

    this.renderer.addClass(this.stickyBar.nativeElement, this.shadowClass);

    if (this.shouldPlayAnimation()) {
      this.renderer.addClass(this.stickyBar.nativeElement, this.slideInClass);
    }

    this.renderer.setStyle(this.stickyBar.nativeElement, 'width', this.computeInnerWidth());

    this.stickyPlaceholder = this.createHelperDivAndAppendToParent(this.stickyPlaceholderClass);

    this.isFloating = true;
  }

  private disableFloating(): void {
    if (!this.isFloating) {
      return;
    }

    this.renderer.removeClass(this.stickyBar.nativeElement, this.shadowClass);
    this.renderer.removeClass(this.stickyBar.nativeElement, this.slideInClass);

    if (this.stickyPlaceholder) {
      this.renderer.removeChild(this.elementRef.nativeElement.parentElement, this.stickyPlaceholder);
    }

    this.isFloating = false;
  }

  private isAboveViewPort(bounds: ClientRect | DOMRect): boolean {
    return bounds.top < 0;
  }

  private onClose(): void {
    this.disableFloating();
    this.isFloatingDetectionEnabled = false;
  }

  private onOpenCompleted(): void {
    this.isFloatingDetectionEnabled = true;
    this.triggerIntersectionWithCurrentHelperPositions();
  }

  private triggerIntersectionWithCurrentHelperPositions(): void {
    // check current positions of helpers
    this.onIntersection([
      {
        target: this.intersectionTopHelper,
        boundingClientRect: this.intersectionTopHelper.getBoundingClientRect(),
      },
    ] as IntersectionObserverEntry[]);

    this.onIntersection([
      {
        target: this.intersectionBottomHelper,
        boundingClientRect: this.intersectionBottomHelper.getBoundingClientRect(),
      },
    ] as IntersectionObserverEntry[]);
  }

  private shouldPlayAnimation(): boolean {
    const helperPos = Number(this.intersectionBottomHelper.getBoundingClientRect().top);
    const stickyPos = Number(this.stickyBar.nativeElement.getBoundingClientRect().top);
    return Math.abs(helperPos - stickyPos) > this.animationTriggerMaxPixelDistance;
  }
}
