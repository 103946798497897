import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Recall2ButtonOutlineComponent } from '@recall2/ui/buttons/components/recall2-button-outline/recall2-button-outline.component';
import { Recall2IconAddComponent } from '@recall2/ui/icons/recall2-icon-add/recall2-icon-add.component';

@Component({
  selector: 'create-notification-button',
  templateUrl: './create-notification-button.component.html',
  standalone: true,
  imports: [Recall2ButtonOutlineComponent, RouterLink, Recall2IconAddComponent, TranslateModule],
})
export class CreateNotificationButtonComponent {}
