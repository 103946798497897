import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-urgency-three',
  templateUrl: './recall2-icon-urgency-three.component.html',
  styleUrls: ['./recall2-icon-urgency-three.component.scss'],
  standalone: true,
})
export class Recall2IconUrgencyThreeComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i87-urgency-3');
  }
}
