import type { CdkOverlayOrigin } from '@angular/cdk/overlay';
import type { ElementRef } from '@angular/core';

const CDK_OVERLAY_CONTAINER = 'cdk-overlay-container';
const BLACK_LISTED_BODY_CLASSNAMES = ['shepherd-ui-blocked'];

/**
 * This method will throw another method if you click outside of the overlay position
 * @param cdkOverlayOrigin: position of the overlay due
 * @param toggleOverlayMethod: method that you will throw
 * @param event: mouse event to read the position of the click
 */
export const onClickOverlayOutside = (
  cdkOverlayOrigin: CdkOverlayOrigin,
  toggleOverlayMethod: () => void,
  event: MouseEvent,
): void => {
  if (hasClickedOverlayTrigger(cdkOverlayOrigin.elementRef, event)) {
    return;
  }

  if (doesEventContainCDKOverlayContainerAsParent(event)) {
    return;
  }

  if (hasBodyBlockingClass()) {
    return;
  }

  toggleOverlayMethod();
};

export const hasClickedOverlayTrigger = (elementRef: ElementRef, event: MouseEvent): boolean => {
  return elementRef.nativeElement.contains(event.target);
};

export const doesEventContainCDKOverlayContainerAsParent = (event: MouseEvent): boolean => {
  return event.composedPath().some(node => (node as Element).classList?.contains(CDK_OVERLAY_CONTAINER));
};

export const hasBodyBlockingClass = (): boolean => {
  return BLACK_LISTED_BODY_CLASSNAMES.some(className => document.querySelector('body').classList.contains(className));
};
