import type { OnInit } from '@angular/core';
import { Directive, Input } from '@angular/core';

@Directive()
export abstract class Recall2IconBaseComponent implements OnInit {
  @Input() rc2IconStyles: string;

  rc2IconClassUnion = 'icon ';

  protected constructor(_iconFontClass: string) {
    this.rc2IconClassUnion += _iconFontClass;
  }

  ngOnInit(): void {
    this.rc2IconClassUnion += this.rc2IconStyles ? ' ' + this.rc2IconStyles : '';
  }
}
