<div class="col-md-12 rc2-pad--top--xl">
  <recall2-involved-users-table
    [previousUrl]="getCurrentRouteCommands()"
    [userList]="currentNotification?.involvedUsers"
    [isFormValid$]="isFormValid$"
    [autoSuggestProperties]="autoSuggestProperties"
    [autoSuggestEntries]="users"
    [autoSuggestSpinnerShowing]="isSpinnerShowing"
    (addUserEvent)="checkIsValid()"
    (updateUsers)="updateInvolvedUser($event)"
    (updateSearchTerm)="onSearchTermChanged($event)"
  >
  </recall2-involved-users-table>
</div>
