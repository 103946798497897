import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import type { IRecall2FilterParam, Recall2FilterV2Config } from '../../../overlay/models/filter.model';
import type { TableColumnFilterComponent } from '../../components/table-column-filter/table-column-filter.component';
import type { TableColumnFilterHeaderDirective } from '../table-column-filter-header/table-column-filter-header.directive';

@Directive({
  selector: '[appTableColumnFilters]',
  standalone: true,
})
export class TableColumnFiltersDirective implements OnInit, OnDestroy, OnChanges {
  @Input() activeFilters$: Observable<IRecall2FilterParam[]>;

  @Input() filterConfigurationList: Recall2FilterV2Config[] = [];

  @Output() filterChange = new EventEmitter<IRecall2FilterParam>();

  private filterHeaderDirectivesList: TableColumnFilterHeaderDirective[] = [];
  private activeFilters: IRecall2FilterParam[] = [];
  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.activeFilters$.pipe(takeUntil(this.destroy$)).subscribe(activeFilters => {
      this.activeFilters = activeFilters;
      this.refreshCurrentActiveFilters();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.filterConfigurationList &&
      !changes.filterConfigurationList.isFirstChange() &&
      changes.filterConfigurationList.currentValue
    ) {
      for (const filterHeader of this.filterHeaderDirectivesList) {
        this.setFilterHeader(filterHeader);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onFilterChange(filterChange: IRecall2FilterParam): void {
    this.filterChange.emit(filterChange);
  }

  registerFilterColumn(filterHeader: TableColumnFilterHeaderDirective): void {
    this.filterHeaderDirectivesList.push(filterHeader);
    this.setFilterHeader(filterHeader);
  }

  private setFilterHeader(filterHeader: TableColumnFilterHeaderDirective): void {
    const columnName = filterHeader.tableColumnFilterComponent.column;

    if (columnName) {
      this.setFilterConfigurationOnComponent(filterHeader.tableColumnFilterComponent, columnName);
      this.refreshCurrentActiveFilters();
    }
  }

  private refreshCurrentActiveFilters(): void {
    this.filterHeaderDirectivesList.forEach((filterHeader: TableColumnFilterHeaderDirective) => {
      const activeFilter = this.activeFilters.find(
        filter => filter.identifier === filterHeader.tableColumnFilterComponent.column,
      );

      filterHeader.tableColumnFilterComponent.setActiveFilter(activeFilter);
    });
  }

  private setFilterConfigurationOnComponent(component: TableColumnFilterComponent, columnName: string): void {
    if (!Array.isArray(this.filterConfigurationList) || this.filterConfigurationList.length === 0) {
      return;
    }

    const filterConfig = this.filterConfigurationList.find(config => config.identifier === columnName);
    component.setFilterConfiguration(filterConfig);
  }
}
