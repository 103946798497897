import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import type { UserData } from '../../user/store/user.state';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  public getCurrentUser$(): Observable<UserData> {
    return this.http.get<UserData>(`${environment.apiUrlUser}/users/me`);
  }
}
