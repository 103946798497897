<div class="col-md-8 rc2-pad--left--0">
  <recall2-summary-text
    [label]="'notification.title' | translate"
    [content]="notification.title"
  ></recall2-summary-text>

  <recall2-summary-text [label]="'notification.parts' | translate"></recall2-summary-text>

  <ng-container *ngFor="let aPart of notification.parts">
    <recall2-summary-text [content]="getPartEntryAsString(aPart)"></recall2-summary-text>
  </ng-container>

  <recall2-summary-text
    [label]="'notification.issue' | translate"
    [content]="notification.issue"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notification.cause' | translate"
    [content]="notification.cause"
  ></recall2-summary-text>

  <recall2-summary-text
    [label]="'notification.effect' | translate"
    [content]="notification.effect"
  ></recall2-summary-text>
</div>
