import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'recall2-footnote-required',
  templateUrl: './footnote-required.component.html',
  styleUrls: ['./footnote-required.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class Recall2FootnoteRequiredComponent {}
