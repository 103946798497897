import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-objekt-agendapunkt',
  templateUrl: './recall2-icon-objekt-agendapunkt.component.html',
  styleUrls: ['./recall2-icon-objekt-agendapunkt.component.scss'],
  standalone: true,
})
export class Recall2IconObjektAgendapunktComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i70-objekt-agendapunkt');
  }
}
