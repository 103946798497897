import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-more',
  templateUrl: './recall2-icon-more.component.html',
  styleUrls: ['./recall2-icon-more.component.scss'],
  standalone: true,
})
export class Recall2IconMoreComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i64-more');
  }
}
