import { NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { Component, Input } from '@angular/core';

import { BrandsId } from '../../brand-manufacturer/models/brands-id.model';
import { Recall2IconLogoAudiComponent } from '../../icons/recall2-icon-logo-audi/recall2-icon-logo-audi.component';
import { Recall2IconLogoBentleyComponent } from '../../icons/recall2-icon-logo-bentley/recall2-icon-logo-bentley.component';
import { Recall2IconLogoBugattiComponent } from '../../icons/recall2-icon-logo-bugatti/recall2-icon-logo-bugatti.component';
import { Recall2IconLogoDucatiComponent } from '../../icons/recall2-icon-logo-ducati/recall2-icon-logo-ducati.component';
import { Recall2IconLogoLamborghiniComponent } from '../../icons/recall2-icon-logo-lamborghini/recall2-icon-logo-lamborghini.component';
import { Recall2IconLogoManComponent } from '../../icons/recall2-icon-logo-man/recall2-icon-logo-man.component';
import { Recall2IconLogoPorscheComponent } from '../../icons/recall2-icon-logo-porsche/recall2-icon-logo-porsche.component';
import { Recall2IconLogoRecallComponent } from '../../icons/recall2-icon-logo-recall/recall2-icon-logo-recall.component';
import { Recall2IconLogoScaniaComponent } from '../../icons/recall2-icon-logo-scania/recall2-icon-logo-scania.component';
import { Recall2IconLogoSeatComponent } from '../../icons/recall2-icon-logo-seat/recall2-icon-logo-seat.component';
import { Recall2IconLogoSkodaComponent } from '../../icons/recall2-icon-logo-skoda/recall2-icon-logo-skoda.component';
import { Recall2IconLogoVwComponent } from '../../icons/recall2-icon-logo-vw/recall2-icon-logo-vw.component';

@Component({
  selector: 'recall2-brand-logo',
  templateUrl: './recall2-brand-logo.component.html',
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    Recall2IconLogoAudiComponent,
    Recall2IconLogoBentleyComponent,
    Recall2IconLogoManComponent,
    Recall2IconLogoPorscheComponent,
    Recall2IconLogoSeatComponent,
    Recall2IconLogoSkodaComponent,
    Recall2IconLogoVwComponent,
    Recall2IconLogoScaniaComponent,
    Recall2IconLogoBugattiComponent,
    Recall2IconLogoLamborghiniComponent,
    Recall2IconLogoDucatiComponent,
    NgSwitchDefault,
    Recall2IconLogoRecallComponent,
  ],
})
export class Recall2BrandLogoComponent {
  public BrandsId = BrandsId;

  /** @deprecated */
  @Input() rc2IconStyles: string;
  @Input() brandId: BrandsId;
}
