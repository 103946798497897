<div class="rc2-overview-wrapper pad-bottom-erase">
  <recall2-notification-confirm
    #confirmComponent
    [title]="'notifications.form.reworkModal.title'"
    [message]="'notifications.form.reworkModal.subtitle'"
    [currentObjectId]="notificationId"
    [commentRequired]="true"
    [isFormSubmitted]="showErrors"
    [showSelectUser]="false"
    (onCommentAdded)="setComment($event)"
  ></recall2-notification-confirm>
  <recall2-floating-bar>
    <div class="action-bar">
      <recall2-button-tertiary
        data-testid="notification-rework-button-cancel"
        class="action-bar__button"
        [iconClass]="'icon-i90-unselect'"
        (click)="cancelRework()"
      >
        {{ 'notifications.form.sendModal.cancelButton' | translate}}
      </recall2-button-tertiary>
      <recall2-button-primary
        data-testid="notification-rework-button-send"
        class="action-bar__button"
        [forceAnimation]="isSending"
        [iconClass]="'icon-i102-return'"
        (click)="sendNotificationToRework()"
      >
        {{'notifications.form.reworkModal.sendButton' | translate}}
      </recall2-button-primary>
    </div>
  </recall2-floating-bar>
</div>
