import type { ConnectedPosition } from '@angular/cdk/overlay';
import type { OnDestroy } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import type { Observable, Subscription } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { EObjectType } from '../dynamic-content/models/object-type.model';
import { Recall2ObjectTemplateComponent } from '../overlay/components/object/recall2-object-template/recall2-object-template.component';
import type { LinkedObject } from '../overlay/models/linked-object.model';
import { EOverlayTemplates } from '../overlay/models/overlay-template.model';
import { Recall2OverlayComponent } from '../overlay/recall2-overlay.component';
import { OverlayLinkedObjectService } from './services/overlay-linked-object.service';

@Component({
  selector: 'app-overlay-linked-objects',
  templateUrl: './overlay-linked-objects.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [Recall2OverlayComponent, Recall2ObjectTemplateComponent],
})
export class OverlayLinkedObjectsComponent implements OnDestroy {
  @Input() id: number;
  @Input() itemsCount: number;
  @Input() originApp: EObjectType;
  @Input() appLinked?: EObjectType;
  loading = false;
  items: LinkedObject[] = [];

  private subscription: Subscription;
  readonly overlayNested: EOverlayTemplates = EOverlayTemplates.LINKEDOBJECT;
  readonly overlayPosition: ConnectedPosition = {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetY: 10,
    offsetX: 16,
  };

  constructor(
    private cdr: ChangeDetectorRef,
    private overlayLinkedObjectService: OverlayLinkedObjectService,
  ) {}

  ngOnDestroy(): void {
    this.closeSubscription();
  }

  onMouseEnter(): void {
    if (!this.id || !this.itemsCount) {
      return;
    }

    let observable: Observable<LinkedObject[]>;

    switch (this.originApp) {
      case EObjectType.NOTIFICATION:
        observable = this.overlayLinkedObjectService.getTopicsLinkedToNotification(this.id).pipe(
          first(),
          map(page => (page as any).items),
        );
        break;
      case EObjectType.TOPIC:
        observable = this.overlayLinkedObjectService.getObjectsLinkedToTopic(this.id).pipe(
          first(),
          map(page => (page as any).items),
        );
        break;
      case EObjectType.CLEARING:
        observable = this.overlayLinkedObjectService.getObjectsLinkedToClearing(this.id).pipe(
          first(),
          map(page => (page as any).items),
        );
        break;
      case EObjectType.CAMPAIGN:
        observable = this.overlayLinkedObjectService.getAgendaItemLinkedToCampaign(String(this.id)).pipe(
          first(),
          tap(page => page.content.map(linkedObject => (linkedObject.type = EObjectType.AGENDA_ITEM))),
          map(page => page.content),
        );
        break;
      case EObjectType.VERIFICATION_TASK:
        switch (this.appLinked) {
          case EObjectType.CLEARING: {
            observable = this.overlayLinkedObjectService.getObjectLinkedToClearingTasks(this.id).pipe(
              first(),
              map(page => [page]),
            );
            break;
          }
          case EObjectType.TOPIC: {
            observable = this.overlayLinkedObjectService.getObjectLinkedToTopicTasks(this.id).pipe(
              first(),
              map(page => [page]),
            );
            break;
          }
          case EObjectType.CAMPAIGN: {
            observable = this.overlayLinkedObjectService.getObjectLinkedToCampaignTasks(this.id).pipe(
              first(),
              map(page => [page]),
            );
            break;
          }
          case EObjectType.AGENDA_ITEM: {
            observable = this.overlayLinkedObjectService.getObjectLinkedToAgendaItemTasks(this.id).pipe(
              first(),
              map(page => [page]),
            );
            break;
          }
        }
        break;
      case EObjectType.COMMITTEE:
        observable = this.overlayLinkedObjectService.getObjectsLinkedToAgendaItem(this.id).pipe(
          first(),
          map(page => (page as any).items),
        );
        break;
    }

    this.initSubscription(observable);
  }

  closeSubscription(): void {
    this.subscription?.unsubscribe();
  }

  private initSubscription(observable: Observable<LinkedObject[]>): void {
    this.subscription = observable.subscribe({
      next: items => {
        this.items = items;
        this.loading = false;
        this.cdr.markForCheck();
      },
      error: _ => {
        this.items = [];
        this.loading = false;
        this.cdr.markForCheck();
      },
    });
  }
}
