import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgClass, NgIf } from '@angular/common';
import type { AfterContentInit, OnDestroy } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { iconArrowDownFilledSmall, iconArrowDownSmall, SVGIconModule, SVGIconsRegistry } from '@recall2/icons';
import { defer, merge, Subject } from 'rxjs';
import { distinctUntilChanged, filter, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';

import { TextSelectOptionComponent } from './components/text-select-option/text-select-option.component';

@Component({
  selector: 'app-text-select',
  exportAs: 'appTextSelect',
  templateUrl: './text-select.component.html',
  styleUrls: ['./text-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'data-cy': 'text-select',
  },
  standalone: true,
  imports: [CdkOverlayOrigin, NgClass, NgIf, SVGIconModule, CdkConnectedOverlay],
})
export class TextSelectComponent<T = unknown> implements AfterContentInit, OnDestroy {
  @ContentChildren(TextSelectOptionComponent, { descendants: true }) options: QueryList<TextSelectOptionComponent>;
  @ViewChild('optionsContainer') optionsContainer: ElementRef;
  @ViewChild('trigger') trigger: ElementRef;
  @Input() overlayWidth?: number;
  @Input() value: T;
  @Input() iconName = 'arrow-down-filled';
  @Input() titleToShow?: string;
  @Input() optionClear?: string;
  @Output() readonly valueChange = new EventEmitter<T>();

  isOpen = false;
  selectedOption: TextSelectOptionComponent;

  private readonly optionSelectionChanges = defer(() => {
    const options = this.options;
    return options.changes.pipe(
      startWith(options),
      switchMap(() => merge(...options.map(option => option.selectionChange))),
      distinctUntilChanged(),
      filter(value => (this.lastValue === undefined ? value !== this.value : true)),
      tap((value: T) => {
        this.lastValue = value;
        this.valueChange.next(value);
      }),
    );
  });

  private destroy$ = new Subject<void>();
  private lastValue: T;

  constructor(iconsRegistry: SVGIconsRegistry) {
    iconsRegistry.registerIcons([iconArrowDownFilledSmall, iconArrowDownSmall]);
  }

  @HostListener('document:click', ['$event'])
  clickOut(event: Event): void {
    if (
      this.isOpen &&
      !this.optionsContainer.nativeElement.contains(event.target) &&
      !this.trigger.nativeElement.contains(event.target)
    ) {
      this.toggle();
    }
  }

  ngAfterContentInit(): void {
    this.setDefaultLabelValue();

    this.optionSelectionChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.updateSelectedOption(value);

      // Close overlay on select option
      this.toggle();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  private setDefaultLabelValue(): void {
    if (this.optionClear) {
      this.clearSelection();
      return;
    }

    this.selectedOption = this.options.find(option => option.value === this.value);
    if (this.selectedOption) {
      this.selectedOption.selected = true;
    }
  }

  private updateSelectedOption(value: T): void {
    if (this.optionClear && this.optionClear === (value as unknown as string)) {
      this.clearSelection();
      return;
    }

    // Find option with given value and mark it as selected and deselect the others
    this.options.forEach(option => {
      option.selected = option.value === value;
      if (option.value === value) {
        this.selectedOption = option;
      }
    });
  }

  private clearSelection(): void {
    this.selectedOption = null;
    this.options.forEach(option => {
      option.selected = false;
    });
  }
}
