import { Directive, HostListener, Input } from '@angular/core';

import { AllowedCharactersOption } from '../../model';

@Directive({
  selector: '[recall2AllowedCharacters]',
  standalone: true,
})
export class Recall2AllowedCharactersDirective {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('recall2AllowedCharacters') allowedCharactersOption: AllowedCharactersOption;

  @HostListener('textInput', ['$event'])
  onChange(event: InputEvent): void {
    if (!this.allowedCharactersOption) {
      return;
    }

    const regexObject = new RegExp(this.allowedCharactersOption);

    if (!regexObject.test(event.data)) {
      event.preventDefault();
    }
  }
}
