/* eslint-disable no-case-declarations */
import type { OnChanges } from '@angular/core';
import { Directive, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import type { IAutoSuggestCommon } from '../models/auto-suggest-common';
import type { IAutoSuggestCountry } from '../models/auto-suggest-country';
import type { IAutoSuggestCountryCode } from '../models/auto-suggest-country-code';
import type { AutoSuggestManufacturer } from '../models/auto-suggest-manufacturer';
import { EAutoSuggestObjectType } from '../models/auto-suggest-object-type.enum';
import type { IAutoSuggestUser } from '../models/auto-suggest-user';

@Directive({
  selector: '[recall2Highlight]',
  standalone: true,
})
export class Recall2AutoSuggestHighlightDirective implements OnChanges {
  @Input() highlightText: string;
  @Input() highlightObject: any;
  @Input() highlightObjectType: EAutoSuggestObjectType;

  private highlightObjectLower: any;
  private highlightMatrix: any;
  private highlightObjectResult: any;

  private _highlighterTag_open = '<span class="auto-suggest-entry-found">';
  private _highlighterTag_close = '</span>';

  constructor(
    private el: ElementRef,
    private translateService: TranslateService,
  ) {}

  private highlight(): void {
    this.searchForOccurrences();
    this.highlightObjectResult = { ...this.highlightObject };
    this.replaceHighlighted();

    let highlightedString = '';
    const element: HTMLElement = this.el.nativeElement;
    switch (this.highlightObjectType) {
      case EAutoSuggestObjectType.USER:
        const highlightedUser = <IAutoSuggestUser>this.highlightObjectResult;
        highlightedString = `${highlightedUser.lastName}, ${highlightedUser.firstName}`;
        element.insertAdjacentHTML('afterend', this.getStringHTML(highlightedUser));
        break;

      case EAutoSuggestObjectType.COUNTRY:
        const highlightedCountry = <IAutoSuggestCountry>this.highlightObjectResult;
        highlightedString = `${highlightedCountry.code} - ${highlightedCountry.name}`;
        break;

      case EAutoSuggestObjectType.COUNTRY_CODE:
        const highlightedCountryCode = <IAutoSuggestCountryCode>this.highlightObjectResult;
        highlightedString = `${highlightedCountryCode.code} - ${this.translateService.instant(
          `general.country.code.${(this.highlightObject as IAutoSuggestCountryCode).code}`,
        )}`;
        break;

      case EAutoSuggestObjectType.MANUFACTURER:
        const highlightedManufacturer = <AutoSuggestManufacturer>this.highlightObjectResult;
        highlightedString = `${highlightedManufacturer.name}`.toUpperCase();
        break;
      case EAutoSuggestObjectType.DEPARTMENT:
        const highlightedCommon = <IAutoSuggestCommon>this.highlightObjectResult;
        highlightedString = `${highlightedCommon.id}`;
        break;

      default:
        Object.keys(this.highlightObject).forEach(aKey => (highlightedString += this.highlightObject[aKey] + ' '));
    }
    element.innerHTML = highlightedString;
  }

  private searchForOccurrences(): void {
    this.highlightObjectLower = {};
    this.highlightMatrix = {};
    const objectKeys = Object.keys(this.highlightObject);
    const searchTerms = this.highlightText?.split(' ') ?? [];

    objectKeys.forEach((aKey: string) => {
      const highlightObjectElement = this.highlightObject[aKey];
      const highlightObjectElementString = highlightObjectElement ? highlightObjectElement.toString() : '';
      this.highlightObjectLower[aKey] = highlightObjectElementString.toLowerCase();

      this.highlightMatrix[aKey] = Array.from({ length: highlightObjectElementString.length });

      searchTerms.forEach((aSearchTerm: string) => {
        const searchTermLength = aSearchTerm.length;
        if (searchTermLength > 0) {
          let startIndex = 0;
          let itemFoundIndex = -1;
          while (
            (itemFoundIndex = this.highlightObjectLower[aKey].indexOf(aSearchTerm.toLowerCase(), startIndex)) > -1
          ) {
            for (let i = 0; i < searchTermLength; i++) {
              this.highlightMatrix[aKey][itemFoundIndex + i] = 1;
            }
            startIndex = itemFoundIndex + searchTermLength;
          }
        }
      });
    });
  }

  private replaceHighlighted(): void {
    const objectKeys = Object.keys(this.highlightObject);
    objectKeys.forEach((aKey: string) => {
      let open_close;

      const highlightObjectElement = this.highlightObject[aKey];
      if (!highlightObjectElement) {
        return;
      }

      for (let i = highlightObjectElement.length - 1; i >= 0; i--) {
        if (this.highlightMatrix[aKey][i] !== open_close) {
          if (open_close === undefined) {
            this.highlightObjectResult[aKey] = [
              this.highlightObjectResult[aKey].slice(0, i + 1),
              this._highlighterTag_close,
              this.highlightObjectResult[aKey].slice(i + 1),
            ].join('');
            open_close = 1;
          } else {
            this.highlightObjectResult[aKey] = [
              this.highlightObjectResult[aKey].slice(0, i + 1),
              this._highlighterTag_open,
              this.highlightObjectResult[aKey].slice(i + 1),
            ].join('');
            open_close = undefined;
          }
        }
        if (i === 0 && this.highlightMatrix[aKey][i] === 1) {
          this.highlightObjectResult[aKey] = [
            this._highlighterTag_open,
            // eslint-disable-next-line unicorn/prefer-spread
            this.highlightObjectResult[aKey].slice(0),
          ].join('');
        }
      }
    });
  }

  private getStringHTML(userData: IAutoSuggestUser): string {
    const corporation = userData.corporation || '';
    return `
    <div class="secondary-info">
      <div class="entry-column">${userData.vwUserId}</div>
      <div class="entry-column">${userData.department}</div>
      <div class="entry-column">${corporation}</div>
    </div>`;
  }

  ngOnChanges(): void {
    this.highlight();
  }
}
