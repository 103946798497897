import { Component } from '@angular/core';

import { Recall2IconBaseComponent } from '../recall2-icon-base/recall2-icon-base.component';

@Component({
  selector: 'recall2-icon-sort-up-inactive',
  templateUrl: './recall2-icon-sort-up-inactive.component.html',
  standalone: true,
})
export class Recall2IconSortUpInactiveComponent extends Recall2IconBaseComponent {
  constructor() {
    super('icon-i12-sort-up-inactive');
  }
}
