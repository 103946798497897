<div class="active-filters-wrapper">
  <div
    #activeFilterElement
    *ngFor="let activeFilter of activeFilters$ | async; let i = index"
    class="active-filter"
    [ngClass]="{ 'filter-open': filterOpenIndex === i }"
    (click)="onOpenFilter(activeFilter, activeFilterElement, i)"
    (mouseenter)="propagateHoverThroughElements(activeFilter, i, 'mouseenter')"
    (mouseleave)="propagateHoverThroughElements(activeFilter, i, 'mouseleave')"
    data-cy="active-filter"
    matTooltipPosition="above"
    matTooltipClass="rc2-tooltip"
    [matTooltip]="
      activeFilter.type === EFilterTemplates.TEXT ||
      activeFilter.type === EFilterTemplates.NUMBER ||
      activeFilter.type === EFilterTemplates.YEAR
        ? (activeFilter.value | commaList)
        : activeFilter.type === EFilterTemplates.DATE
        ? (activeFilter | filterOptionDateKey | async)
        : activeFilter.type === EFilterTemplates.AUTOCOMPLETE
        ? (activeFilter.value | filterAutocompleteOptionTranslationKey: activeFilter.identifier : filtersConfig | async)
        : activeFilter.type === EFilterTemplates.USER_ROLES
        ? ('' + activeFilter.value[0] | userRoles | async)
        : (activeFilter.value | filterOptionTranslationKey: activeFilter.identifier : filtersConfig | async)
    "
  >
    {{ activeFilter.translationKey | translate }}:
    <span class="rc2-font--bold rc2-pad--left--3xs">
      <ng-container [ngSwitch]="activeFilter.type">
        <ng-container *ngSwitchCase="EFilterTemplates.TEXT">
          <div #activeFiltersTextContainer [id]="'activeFiltersTextContainer-' + i" class="rc2-display--flex">
            <span>{{ activeFilter.value | commaList }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="EFilterTemplates.NUMBER">
          <div #activeFiltersNumberContainer [id]="'activeFiltersNumberContainer-' + i" class="rc2-display--flex">
            <span>
              {{ $any(activeFilter.comparator) | numberFilterComparatorTranslateKey | translate }}
              {{ activeFilter.value | commaList }}
            </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="EFilterTemplates.DATE">
          <div #activeFiltersDateContainer [id]="'activeFiltersDateContainer-' + i" class="rc2-display--flex">
            {{ activeFilter | filterOptionDateKey | async }}
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="EFilterTemplates.YEAR">
          <div #activeFiltersYearContainer [id]="'activeFiltersYearContainer-' + i" class="rc2-display--flex">
            {{ activeFilter.value | commaList }}
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="EFilterTemplates.AUTOCOMPLETE">
          <div
            #activeFiltersAutocompleteContainer
            [id]="'activeFiltersAutocompleteContainer-' + i"
            class="rc2-display--flex"
          >
            {{
              activeFilter.value
                | filterAutocompleteOptionTranslationKey: activeFilter.identifier : filtersConfig
                | async
            }}
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="EFilterTemplates.USER_ROLES">
          <div #activeFiltersUserRolesContainer [id]="'activeFiltersUserRolesContainer-' + i" class="rc2-ellipsis">
            {{ '' + activeFilter.value[0] | userRoles | async }}
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div #activeFiltersDefaultContainer [id]="'activeFiltersDefaultContainer-' + i" class="rc2-display--flex">
            <span>
              {{ activeFilter.value | filterOptionTranslationKey: activeFilter.identifier : filtersConfig | async }}
            </span>
          </div>
        </ng-container>
      </ng-container>
    </span>
    <recall2-svg-icon
      name="close-chip"
      size="small"
      class="rc2-icon--color--black rc2-pad--left--xxs rc2-pad--right--xxs"
      (click)="onRemoveFilter($event, activeFilter.identifier)"
      data-cy="remove-filter"
    >
    </recall2-svg-icon>
  </div>
  <recall2-svg-icon
    *ngIf="(activeFilters$ | async)?.length > 1"
    name="delete"
    size="medium"
    class="rc2-cursor--pointer rc2-color--primary"
    (click)="onClearFilters()"
    data-cy="clear-filters"
  >
  </recall2-svg-icon>
</div>
