export enum AttachmentType {
  Campaign = 'CAMPAIGN',
  Clearing = 'CLEARING',
  Committee = 'COMMITTEE',
  Notification = 'NOTIFICATION',
  Topic = 'TOPIC',
  VerificationTask = 'VERIFICATION_TASK',
  VehicleCampaignCode = 'VEHICLE_CAMPAIGN_CODE',
  VehicleImportCampaignCode = 'IMPORT_VEHICLE_CAMPAIGN_CODES',
}
