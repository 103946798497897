import { ERole } from '../../../navbar/models/role.model';

export const vinErrorListRoles: ERole[] = [
  ERole.CAMPAIGN_COORDINATOR_IMPORTER,
  ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER,
  ERole.CAMPAIGN_COORDINATOR_BRAND,
  ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
  ERole.CAMPAIGN_AUTHOR,
  ERole.CAMPAIGN_AUTHOR_VIEWER,
];

export const vinSearchCSVRoles: ERole[] = [
  ERole.CAMPAIGN_COORDINATOR_DEALER,
  ERole.CAMPAIGN_COORDINATOR_DEALER_VIEWER,
  ERole.CAMPAIGN_COORDINATOR_IMPORTER,
  ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER,
  ERole.CAMPAIGN_COORDINATOR_BRAND,
  ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
  ERole.CAMPAIGN_AUTHOR,
  ERole.CAMPAIGN_AUTHOR_VIEWER,
];
