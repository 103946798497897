import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { AlreadyForwardedManufacturer } from '@recall2/ui/brand-manufacturer';
import type {
  IRecall2DynamicFilterGroup,
  IRecall2FilterItem,
  IRecall2FilterRequest,
  IRecall2SortItem,
} from '@recall2/ui/overlay';
import type { PageDTO } from '@recall2/ui/pagination';
import type { ListResponse } from '@recall2/ui/utils';
import { mapListResponse } from '@recall2/ui/utils';
import type { IUser } from '@recall2/user';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import type { ILinkedObject } from '../../notification/models/linked-object';
import type {
  ICreatedAt,
  INotification,
  INotificationForList,
} from '../../notification/models/notification/notification';
import type { NotificationStatus } from '../../notification/models/notification/notification-status.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notificationBaseApiUrl = '/notifications';

  private contentHeader = 'Content-Type';

  private jsonApplicationContent = 'application/json';

  constructor(private httpClient: HttpClient) {}
  public getNotificationsForList(
    page: number,
    pageSize: number,
    currentObjectId: number,
    filter: IRecall2FilterItem[] = [],
    sort: IRecall2SortItem[] = [],
  ): Observable<PageDTO<INotificationForList>> {
    let params = new HttpParams();
    if (currentObjectId) {
      params = params.append('containing-entry', currentObjectId.toString());
    }
    const request: IRecall2FilterRequest = {
      filter,
      sort,
      page,
      pageSize,
    };
    return this.httpClient.post<PageDTO<INotificationForList>>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/search`,
      request,
      { params },
    );
  }
  public getNotificationsForListByType(
    type: string,
    page: number,
    pageSize: number,
    currentObjectId: number,
  ): Observable<PageDTO<INotificationForList>> {
    let httpParams = new HttpParams()
      .append('type', type)
      .append('page', page.toString())
      .append('page-size', pageSize.toString());
    if (currentObjectId) {
      httpParams = httpParams.append('containing-entry', currentObjectId.toString());
    }
    return this.httpClient.get<PageDTO<INotificationForList>>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}`,
      {
        params: httpParams,
      },
    );
  }

  public getNotificationForId(id: number): Observable<INotification> {
    return this.httpClient.get<INotification>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${id}`,
      {},
    );
  }

  public getNotificationDate(id: number): Observable<ICreatedAt> {
    return this.httpClient.get<ICreatedAt>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${id}/created-at`,
      {},
    );
  }

  public getAlreadyForwardedManufacturers(id: number): Observable<AlreadyForwardedManufacturer[]> {
    return this.httpClient.get<AlreadyForwardedManufacturer[]>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${id}/already-forwarded-manufacturers`,
      {},
    );
  }

  public saveNotification(notification: INotification): Observable<INotification> {
    return this.httpClient.post<INotification>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}`,
      notification,
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
      },
    );
  }

  public sendToReviewer(
    notificationId: number,
    user: IUser,
    locale: string,
    comment?: string,
  ): Observable<NotificationStatus> {
    return this.httpClient.post<NotificationStatus>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/review`,
      this.getRequest(comment, user),
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
        params: new HttpParams().set('locale', locale),
      },
    );
  }

  public forwardToReviewer(
    notificationId: number,
    user: IUser,
    locale: string,
    comment: string,
  ): Observable<NotificationStatus> {
    return this.httpClient.post<NotificationStatus>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/forward`,
      this.getRequest(comment, user),
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
        params: new HttpParams().set('locale', locale),
      },
    );
  }

  public discardNotification(notificationId: number, locale: string, comment: string): Observable<NotificationStatus> {
    return this.httpClient.post<NotificationStatus>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/discard`,
      { comment },
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
        params: new HttpParams().set('locale', locale),
      },
    );
  }

  public reactivateNotification(notificationId: number): Observable<NotificationStatus> {
    return this.httpClient.post<NotificationStatus>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/reactivate`,
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
      },
    );
  }

  public sendForReviseReviewer(
    notificationId: number,
    locale: string,
    comment: string,
  ): Observable<NotificationStatus> {
    return this.sendForRevise('revise', notificationId, locale, comment);
  }
  public sendForReviseAPS(notificationId: number, locale: string, comment: string): Observable<NotificationStatus> {
    return this.sendForRevise('revise-aps', notificationId, locale, comment);
  }
  sendForRevise(path: string, notificationId: number, locale: string, comment: string): Observable<NotificationStatus> {
    return this.httpClient.post<NotificationStatus>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/${path}`,
      this.getCommentDto(comment),
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
        params: new HttpParams().set('locale', locale),
      },
    );
  }

  public transferNotification(
    notificationId: number,
    manufacturerId: string | number,
    comment?: string,
  ): Observable<NotificationStatus> {
    return this.httpClient.post<NotificationStatus>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/transfer`,
      this.getCommentDto(comment),
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
        params: new HttpParams().set('manufacturer-id', manufacturerId + ''),
      },
    );
  }

  public approveNotification(
    notificationId: number,
    manufacturerId: string | number,
    comment?: string,
  ): Observable<NotificationStatus> {
    return this.httpClient.post<NotificationStatus>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/approve`,
      this.getCommentDto(comment),
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
        params: new HttpParams().set('manufacturer-id', manufacturerId + ''),
      },
    );
  }

  public forwardNotificationToManufacturer(
    notificationId: number,
    manufacturerIds: number[],
    comment?: string,
  ): Observable<NotificationStatus> {
    return this.httpClient.post<NotificationStatus>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/forward-manufacturer`,
      this.getCommentDto(comment),
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
        params: new HttpParams().set('manufacturer-ids', manufacturerIds.toString()),
      },
    );
  }

  public acceptNotification(notificationId: number): Observable<INotificationForList> {
    return this.httpClient.post<INotificationForList>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/accept`,
      {},
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
      },
    );
  }

  public updateNotification(id: number, notification: INotification): Observable<INotification> {
    return this.httpClient.put<INotification>(
      `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${id}`,
      notification,
      {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
      },
    );
  }

  public deleteCurrentNotification(id: number): Observable<boolean> {
    return this.httpClient
      .delete<boolean>(`${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${id}`, {
        headers: new HttpHeaders().set(this.contentHeader, this.jsonApplicationContent),
        observe: 'response',
      })
      .pipe(map(response => response.status === 202));
  }

  public getLinkedObjects(notificationId: number): Observable<ILinkedObject[]> {
    return this.httpClient
      .get<ListResponse<ILinkedObject>>(
        `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/${notificationId}/linked`,
      )
      .pipe(mapListResponse());
  }

  getCommentDto(comment?: string): { comment: string } {
    return comment ? { comment } : undefined;
  }

  getOverlayExtraFilters(): Observable<IRecall2DynamicFilterGroup[]> {
    return this.httpClient
      .get<ListResponse<IRecall2DynamicFilterGroup>>(
        `${environment.apiUrlNotification}${this.notificationBaseApiUrl}/filter-groups`,
      )
      .pipe(mapListResponse());
  }

  private getRequest(comment: string, user: IUser) {
    return {
      comment,
      involvedUser: user,
    };
  }
}
