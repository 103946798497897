import { NgTemplateOutlet } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'recall2-custom-overlay',
  templateUrl: './recall2-overlay-container.component.html',
  standalone: true,
  imports: [NgTemplateOutlet],
})
export class Recall2OverlayContainerComponent {
  /**
   * This provides finer control on the content to be visible on the tooltip
   * This template will be injected in Recall2OverlayRenderer directive in the consumer template
   * <ng-template #template>
   *  content.....
   * </ng-template>
   */
  @Input() overlayTemplate: TemplateRef<any>;
}
