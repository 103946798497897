/**
 * will be @deprecated. Use EObjectType instead, because it matches the same model in backend
 */
export enum EOverlayIconType {
  NOTIFICATION = 'NOTIFICATION',
  TOPIC = 'TOPIC',
  CLEARING = 'CLEARING',
  // this "AGENDA" will be deprecated and replaced with "AGENDA_ITEM"
  AGENDA = 'AGENDA',
  VERIFICATION_TASK = 'VERIFICATION_TASK',
  AGENDA_ITEM = 'AGENDA_ITEM',
  COMMITTEE = 'COMMITTEE',
  CAMPAIGN = 'CAMPAIGN',
  MEETING = 'MEETING',
}
