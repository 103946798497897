import { NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { GhostSkeletonSchema } from './model/ghost-skeleton-schema.model';

@Component({
  selector: 'app-ghost-skeleton',
  templateUrl: './ghost-skeleton.component.html',
  styleUrls: ['./ghost-skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgSwitch, NgSwitchCase, NgTemplateOutlet],
})
export class GhostSkeletonComponent {
  @Input() schema: GhostSkeletonSchema = GhostSkeletonSchema.Table;
  GhostSkeletonSchema = GhostSkeletonSchema;
}
