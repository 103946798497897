/* eslint-disable unicorn/consistent-function-scoping */
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import type { Observable } from 'rxjs';

import type { AttachmentFile } from '../../models';
import type { AttachmentStoreState } from './attachment.store';
import { AttachmentStore } from './attachment.store';

@Injectable({
  providedIn: 'root',
})
export class AttachmentQuery extends Query<AttachmentStoreState> {
  constructor(protected store: AttachmentStore) {
    super(store);
  }
  attachments$: Observable<AttachmentFile[]> = this.select(state => state.attachments);
  rowItemAttachments$: Observable<AttachmentFile[]> = this.select(state => state.rowItemAttachments);
  attachmentsCount$: Observable<number> = this.select(state => state.attachments.length);

  get attachments(): AttachmentFile[] {
    return this.store.getValue().attachments;
  }

  get rowItemAttachments(): AttachmentFile[] {
    return this.store.getValue().rowItemAttachments;
  }
}
