import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ShowErrorPopupAction } from '../store/errorhandling.actions';
import type { IErrorState } from '../store/errorhandling.state';

const ERROR_HEADER_KEY = 'recall2.error';
const CONTAINING_ENTRY = 'containing-entry';

@Injectable()
export class IdNotFoundInterceptor implements HttpInterceptor {
  constructor(private _store: Store<IErrorState>) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      map((event: HttpResponse<unknown>) => {
        if (this.shouldBeIntercepted(event)) {
          const dynamicValues: { [key: string]: unknown }[] = this.getDynamicValues(req);
          this._store.dispatch(new ShowErrorPopupAction(event.headers.get(ERROR_HEADER_KEY), dynamicValues));
        }
        return event;
      }),
    );
  }

  private shouldBeIntercepted(response: HttpResponse<unknown>): boolean {
    return response instanceof HttpResponse && response.headers.has(ERROR_HEADER_KEY);
  }

  private getItemId(req: HttpRequest<unknown>): string {
    const urlParams = new URLSearchParams(req.urlWithParams.slice(req.urlWithParams.indexOf('?')));
    return urlParams.has(CONTAINING_ENTRY) ? urlParams.get(CONTAINING_ENTRY) : undefined;
  }

  private getDynamicValues(req: HttpRequest<unknown>): { [key: string]: unknown }[] {
    const id: string = this.getItemId(req);
    return id ? [{ id }] : undefined;
  }
}
