<div
  *ngIf="isAnimating || forceShowing"
  class="spinner-center "
  id="spinner-div-{{src}}"
>
  <mat-spinner
    [diameter]="spinnerDiameter"
    id="mat-spinner-{{src}}"
  ></mat-spinner>
</div>
