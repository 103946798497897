import { Component, Input } from '@angular/core';

@Component({
  selector: 'recall2-icon-confidential',
  templateUrl: './recall2-icon-confidential.component.html',
  standalone: true,
})
export class Recall2IconConfidentialComponent {
  @Input() readonly rc2IconStyles: string;
}
