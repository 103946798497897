<recall2-card
  [attr.data-cy]="'navigation-box-container-' + item.key" class="rc2-display--flex rc2-mar--bottom--l"
  recall2AppsLink
  [attr.data-testid]="item.menuItem.name"
  [targetApp]="item.menuItem.targetApp"
  [urlPath]="item.menuItem.urlPath"
  [ignoreActive]="item.menuItem.ignoreActive"
  [forceActive]="item.menuItem.forceActive">
  <ng-container body>
    <div class="row">
      <div class="col-md-12">
        <div class="icon-container rc2-mar--bottom--l">
          <recall2-icon-user [rc2IconStyles]="'rc2-icon--color--white rc2-icon--size--52'"></recall2-icon-user>
        </div>
        <div class="rc2-mar--bottom--l">
          <app-title-section
            data-cy="`navigation-box-title`"
            titleClass="h6"
            [title]="item.menuItem.innerHTML"
          >
          </app-title-section>
        </div>

        <div class="navigation-box-footer rc2-display--flex rc2-justify--space-between">
          <div class="quick-actions">
            <recall2-button-primary
              recall2AppsLink
              *ngIf="item.quickAction"
              [targetApp]="item.quickAction.targetApp"
              [urlPath]="item.quickAction.urlPath"
              (click)="$event.stopPropagation()"
            >
              {{ item.quickAction.innerHTML }}
            </recall2-button-primary>
          </div>
          <div class="main-path-link">
            <recall2-icon-arrow-right [rc2IconStyles]="'rc2-icon--size--30'"></recall2-icon-arrow-right>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</recall2-card>
