<div
  class="text-select-label"
  [ngClass]="{
    'open': isOpen
  }"
  cdkOverlayOrigin
  #cdkOverlayOrigin="cdkOverlayOrigin"
  #trigger
  (click)="toggle()"
>
  <span *ngIf="titleToShow">{{ titleToShow }}{{ selectedOption?.viewValue ? ':' : '' }}</span>&nbsp;
  <span>{{ selectedOption?.viewValue }}</span>
  <recall2-svg-icon
    [name]="iconName"
    size="small"
  ></recall2-svg-icon>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
  [cdkConnectedOverlayOrigin]="cdkOverlayOrigin"
  [cdkConnectedOverlayBackdropClass]="'text-select-backdrop'"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOffsetY]="4"
>
  <div
    class="text-select-options-container"
    data-cy="text-select-options"
    #optionsContainer
  >
    <ng-content></ng-content>
  </div>
</ng-template>
