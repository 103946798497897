import { NgClass, NgIf } from '@angular/common';
import type { AfterViewChecked, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import type { FormControl } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2IconHelpComponent } from '../../../icons/recall2-icon-help/recall2-icon-help.component';
import { Recall2IconRequiredComponent } from '../../../icons/recall2-icon-required/recall2-icon-required.component';
import { Recall2IconSelectComponent } from '../../../icons/recall2-icon-select/recall2-icon-select.component';
import { CheckboxProperty } from '../../model';

@Component({
  selector: 'recall2-checkbox',
  standalone: true,
  templateUrl: './recall2-checkbox.component.html',
  styleUrls: ['./recall2-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    NgIf,
    ReactiveFormsModule,
    MatTooltipModule,
    TranslateModule,
    Recall2IconRequiredComponent,
    Recall2IconSelectComponent,
    Recall2IconHelpComponent,
  ],
})
export class Recall2CheckboxComponent implements OnInit, AfterViewChecked {
  @Input() property: CheckboxProperty;
  @Input() isFormSubmitted = false;
  @Input() isTranslateSuffixOmitted = false;
  @Output() onChange = new EventEmitter<FormControl>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    if (this.property.isChecked) {
      this.property.control.setValue(this.property.isChecked);
    }
  }

  toggleCheck(event: Event): void {
    if (!this.property.control.disabled) {
      event.stopPropagation();
      this.property.control.setValue(!this.property.control.value);
      this.property.control.markAsDirty();
      this.property.isChecked = this.property.control.value;
      this.onChange.emit(this.property.control);
    }
  }
}
