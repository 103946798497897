import { DatePipe } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'customTime',
  standalone: true,
})
export class CustomTimePipe implements PipeTransform {
  transform(dateTime: string | Date): string {
    if (!dateTime) {
      return '-';
    }

    const datePipe = new DatePipe('en-US');
    return datePipe.transform(dateTime, 'HH:mm');
  }
}
